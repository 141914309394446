import React from "react";
import PlayerDashboard from "../views/player/dashboard";
import Footer from "../views/manage/footer";
import { CssBaseline } from "@material-ui/core";
import Header from "../views/player/header";
import AdminHeader from "../views/admin/header";
import AgentHeader from "../views/agent/header";
import Button from "./button";
import { withStyles } from "@material-ui/core/styles";
import Styles from "../styles/agent/agenrtPlayerView";
import { withStore } from "./hooks/store";
// import { Link } from "react-router-dom";
import Config from "../helper/config";
import BackArrow from "../images/backArrow.png";
import { toast } from "react-toastify";

class PlayerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user1detail: null,
      user2detail: null,
      searchUserDetail: null,
    };
  }

  componentDidMount() {
    const state = this.props.location.state;
    if (state && state.playerSearchDetail) {
      this.setState({
        searchUserDetail: state.playerSearchDetail,
      });
    }
  }

  handleCompareStrategy = async (user1detail, user2detail) => {
    this.setState({
      user1detail: user1detail,
      user2detail: user2detail,
    });
  };

  addToComparePath = () => {
    const getRouterStateDetail = this.state.searchUserDetail
      ? { ...this.state.searchUserDetail }
      : {};
    if (
      getRouterStateDetail &&
      getRouterStateDetail.compareUser &&
      this.state.user1detail &&
      getRouterStateDetail.compareUser.length !== 2 &&
      getRouterStateDetail.compareUser.length
    ) {
      let routerStateDetail = { ...getRouterStateDetail };
      let user1detail = {
        ...this.state.user1detail,
        overall_percentage: Number(this.state.user1detail.overall_total)
          ? Number(this.state.user1detail.overall_total)
          : 0,
      };
      let compareUser = [...getRouterStateDetail.compareUser];
      if (compareUser[0].id === user1detail.id) {
        return toast.error("User Existed for compare");
      }
      compareUser.push(user1detail);
      routerStateDetail = { ...routerStateDetail, compareUser: compareUser };
      this.setState({
        searchUserDetail: routerStateDetail,
      });
    } else if (
      getRouterStateDetail &&
      getRouterStateDetail.compareUser &&
      getRouterStateDetail.compareUser.length === 0
    ) {
      let routerStateDetail = { ...getRouterStateDetail };
      let user1detail = {
        ...this.state.user1detail,
        player_position: this.state.user1detail.position,
        overall_percentage: Number(this.state.user1detail.overall_total)
          ? Number(this.state.user1detail.overall_total)
          : 0,
      };
      routerStateDetail = { ...routerStateDetail, compareUser: [user1detail] };
      this.setState({
        searchUserDetail: routerStateDetail,
      });
    }

    // this.props.history.push(`${Config.reactUrl}/agent/agentcompareplayer/${this.state.user1detail.id}/${this.state.user2detail.id}`)
  };

  getButtonDetail(classes, state) {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.userId
    ) {
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.type &&
        this.props.match.params.type === "admin"
      ) {
        return (
          <>
            <a
              href="#/"
              onClick={((e) => { e.preventDefault(); this.props.history.goBack() })}
              style={{ color: "inherit" }}
            >
              <img src={BackArrow} style={{ width: 31, height: 31 }} alt="" />
              <span
                href="#/"
                style={{
                  font: "normal normal normal 12px/18px Poppins",
                  color: "#959595",
                  paddingLeft: 10,
                  opacity: 1,
                }}
                className={"commonBackClass"}
              >
                Back
              </span>
            </a>
          </>
        );
      } else {
        return (
          <>
            <a
              href="#/"
              onClick={
                ((e) => {
                  e.preventDefault();
                  this.props.history.push({
                    pathname: "/agent/agentplayersearch",
                    state: {
                      routeSearchUserDetail: this.state.searchUserDetail
                        ? { ...this.state.searchUserDetail }
                        : null,
                    },
                  })
                }
                )
              }
              style={{ color: "inherit" }}
            >
              <img src={BackArrow} style={{ width: 31, height: 31 }} alt="" />
              <span
                href="#/"
                style={{
                  font: "normal normal normal 12px/18px Poppins",
                  color: "#959595",
                  paddingLeft: 10,
                  opacity: 1,
                }}
                className={"commonBackClass"}
              >
                Back to Search
              </span>
            </a>
            <Button
              buttonStyle={classes.buttonStyle}
              buttonName={"MEssage PLAYEr"}
              fullWidth={false}
              onClick={() =>
                this.props.history.push(
                  Config.reactUrl +
                  "/agent/message/" +
                  this.props.match.params.userId
                )
              }
            />
            {state && (
              <Button
                buttonStyle={classes.buttonStyle1}
                buttonName={"Add to compare"}
                fullWidth={false}
                onClick={() => this.addToComparePath()}
              />
            )}
          </>
        );
      }
    } else {
      return (
        <Button
          buttonStyle={classes.buttonStyle1}
          buttonName={"Search Agents"}
          fullWidth={false}
          onClick={() =>
            this.props.history.push(
              Config.reactUrl + "/player/playeragentsearch"
            )
          }
        />
      );
    }
  }

  clearUsers = (user) => {
    let routerCompareUserDetails = this.state.searchUserDetail
      ? { ...this.state.searchUserDetail }
      : {};
    if (
      routerCompareUserDetails &&
      routerCompareUserDetails.compareUser &&
      routerCompareUserDetails.compareUser.length
    ) {
      const usercomparisonsdetails = [...routerCompareUserDetails.compareUser];
      let updatedcompareUser;
      if (user instanceof Array) {
        updatedcompareUser = [];
      } else {
        updatedcompareUser = usercomparisonsdetails.filter(
          (e) => e.id !== user.id
        );
      }
      routerCompareUserDetails = {
        ...routerCompareUserDetails,
        compareUser: updatedcompareUser,
      };
      this.setState({
        searchUserDetail: routerCompareUserDetails,
      });
    }
  };

  makeComparisonOfSelectedUser = () => {
    const routerSearchData = this.state.searchUserDetail
      ? { ...this.state.searchUserDetail }
      : {};
    if (
      routerSearchData &&
      routerSearchData.compareUser &&
      routerSearchData.compareUser.length
    ) {
      const compareUsers = [...routerSearchData.compareUser];
      if (compareUsers.length !== 2) {
        toast.error("Comparison wants two users");
        return;
      }
      this.props.history.push({
        pathname:
          Config.reactUrl +
          `/agent/agentcompareplayer/${compareUsers[0].id}/${compareUsers[1].id}`,
        state: {
          playerSearchDetail: this.state.searchUserDetail
            ? { ...this.state.searchUserDetail }
            : null,
        },
      });
    } else {
      toast.error("Error while comparing player details");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        {this.props.match &&
          this.props.match.params &&
          this.props.match.params.type &&
          this.props.match.params.type === "admin" ? (
          <AdminHeader from={"Players"} />
        ) : this.props.match &&
          this.props.match.params &&
          this.props.match.params.type &&
          this.props.match.params.type === "agent" ? (
          <AgentHeader
            compareUserDetails={
              this.state.searchUserDetail &&
                this.state.searchUserDetail.compareUser
                ? [...this.state.searchUserDetail.compareUser]
                : []
            }
            clearUsers={(user) => this.clearUsers(user)}
            makeComparisonOfSelectedUser={() =>
              this.makeComparisonOfSelectedUser()
            }
          />
        ) : (
          <Header />
        )}
        <PlayerDashboard
          {...this.props}
          handleCompareStrategy={this.handleCompareStrategy}
          playerSearchDetail={this.state.searchUserDetail}
        >
          {(state) => this.getButtonDetail(classes, state)}
        </PlayerDashboard>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(withStore(PlayerDetail));
