import React, { Component } from "react";
import Footer from "../manage/footer";
import { withStore } from "../../component/hooks/store";
import { withStyles } from "@material-ui/core/styles";
import {
  checkNullAndReturnString,
  doGetConnectSecurity,
  doGetConnect,
  doPutConnectSecurity,
} from "../../helper/common";
import Header from "./header";
// import { Link } from "react-router-dom";
import Config from "../../helper/config";
import Styles from "../../styles/admin/viewAgentsDetails";
import BackOption from "../../component/back";
import {
  // InputBase, Select, MenuItem,
  Container,
  CssBaseline,
} from "@material-ui/core";
import LabelInput from "../../component/labelInput";
import Services from "../../component/services";
import Button from "../../component/button";
import { toast } from "react-toastify";
import PdfImage from "../../images/pdf.png";
import axios from "axios";
import fileDownload from "js-file-download";
import Tooltip from "@material-ui/core/Tooltip";
import { Modal } from "react-responsive-modal";
import PhoneInput from 'react-phone-input-2';

class ViewAgentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userJson: {},
      servicesList: [],
      userId: "",
      modal: false,
      modalId: "",
    };
  }

  onOpenModal = () => {
    this.setState({ modal: true });
  };

  onCloseModal = () => {
    this.setState({ modal: false });
  };

  componentDidMount = async () => {
    var params = this.props.match.params;
    if (checkNullAndReturnString(params)) {
      if (checkNullAndReturnString(params.id)) {
        this.getPlayerProfile(params.id);
        this.setState({ userId: params.id });
      }
    }
    var responseData = await doGetConnect("master-data/agent/services");
    if (checkNullAndReturnString(responseData)) {
      this.setState({ servicesList: responseData });
    }
  };

  getPlayerProfile = async (id) => {
    var accessToken = localStorage.getItem("accessToken");
    var responseData = await doGetConnectSecurity(
      "users?id=" +
      id +
      "&status=&roleId=3&userAccessStatus&name=&city&state&skip=&take=&order=&sortby=",
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.data)) {
        if (checkNullAndReturnString(responseData.data[0])) {
          var userJson = responseData.data[0];
          this.setState({ userJson });
        }
      }
    }
  };

  changeStatus = async (id, val) => {
    var accessToken = localStorage.getItem("accessToken");
    var postJson = {
      status: val,
      roleId: "3",
      playerProfileId: "",
    };
    var responseData = await doPutConnectSecurity(
      "users/access/status/" + id,
      postJson,
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.message)) {
        toast.success(responseData.message);
        this.props.history.push(Config.reactUrl + "/admin/agents");
      } else {
        if (checkNullAndReturnString(responseData.error)) {
          if (checkNullAndReturnString(responseData.error.message)) {
            toast.error(responseData.error.message);
          }
        }
      }
    }
  };

  refillData = (key) => {
    var userJson = this.state.userJson;
    if (checkNullAndReturnString(userJson)) {
      if (checkNullAndReturnString(userJson[key])) {
        return userJson[key];
      }
    }
    return "";
  };

  savePdfFile = (file) => {
    axios
      .get(`${Config.dataUrl}aws-files/${file.id}`, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, file.file_name);
      });
    // window.open(`${Config.dataUrl}aws-files/${file.id}`, '_blank')
  };

  render() {
    var { classes } = this.props;
    var { userId, servicesList } = this.state;
    var serviceData = [];
    if (checkNullAndReturnString(servicesList)) {
      var service = this.refillData("service_offered");
      if (checkNullAndReturnString(service)) {
        Object.keys(service).forEach((key) => {
          var serviceArray = servicesList.filter(
            (servicesList) => (servicesList.id && servicesList.id.toString()) === (service[key] && service[key].toString())
          );
          if (checkNullAndReturnString(serviceArray)) {
            if (checkNullAndReturnString(serviceArray[0])) {
              serviceData.push(
                <div
                  class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12"
                  style={{ paddingTop: 15 }}
                >
                  <Services
                    name={serviceArray[0].service_name}
                    from={"admin"}
                  />
                </div>
              );
            }
          }
        });
      }
    }
    var certificate = this.refillData("agentCertificates");
    var certificateData = [];
    if (checkNullAndReturnString(certificate)) {
      Object.keys(certificate).forEach((key) => {
        if (certificate[key].extension !== "pdf") {
          certificateData.push(
            <a
              href="#/"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  modalId: Config.dataUrl + "aws-files/" + certificate[key].id,
                });
                this.onOpenModal();
              }}
              class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12"
              style={{ paddingTop: 15 }}
            >
              <img
                src={Config.dataUrl + "aws-files/" + certificate[key].id}
                alt=""
                style={{
                  width: "100%",
                  height: 150,
                  borderRadius: 10,
                  boxShadow: "0px 3px 6px #00000029",
                  border: "1px solid #3B3E50"
                }}
              />
            </a>
          );
        } else {
          certificateData.push(
            <div
              class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12"
              style={{
                paddingTop: 15,
                cursor: "pointer",
                display: "flex",
              }}
            >
              <Tooltip title={certificate[key].file_name} placement="top">
                <div
                  onClick={() => this.savePdfFile(certificate[key])}
                  style={{
                    width: "100%",
                    height: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 3px 6px #00000029",
                    border: "1px solid #3B3E50",
                    borderRadius: 10,
                  }}
                >
                  <p></p>
                  <img
                    src={PdfImage}
                    alt=""
                    style={{ width: 100, height: 80 }}
                  />
                </div>
              </Tooltip>
            </div>
          );
        }
      });
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <Header from={"Agents"} />
        <Container
          maxWidth="xl"
          component="main"
          className={classes.heroContent}
        >
          <div
            className="container-fluid"
            style={{ paddingBottom: 120, paddingTop: 50 }}
          >
            <div class="row rowView">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <BackOption history={this.props.history} />
              </div>
            </div>
            <div class="row rowView">
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                style={{ paddingTop: 15 }}
              >
                <span className={classes.viewAgentText}>Agent Details</span>
              </div>
              <div
                class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6"
                style={{ paddingTop: 15 }}
              >
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"REJECT"}
                  fullWidth={false}
                  onClick={() => this.changeStatus(userId, "rejected")}
                />
              </div>
              <div
                class="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-6"
                style={{ paddingTop: 15 }}
              >
                <Button
                  buttonStyle={classes.buttonStyle}
                  buttonName={"Approve"}
                  fullWidth={false}
                  onClick={() => this.changeStatus(userId, "approved")}
                />
              </div>
            </div>
            <div class="row rowView">
              <div
                class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12"
                style={{ paddingTop: 15 }}
              >
                <span className={classes.viewAgentInnerText}>
                  Basic Information
                </span>
                <div className={"row"}>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style={{ paddingTop: 15 }}
                  >
                    <LabelInput
                      disabled={true}
                      label={"First Name"}
                      value={this.refillData("first_name")}
                    />
                  </div>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style={{ paddingTop: 15 }}
                  >
                    <LabelInput
                      disabled={true}
                      label={"Last Name"}
                      value={this.refillData("last_name")}
                    />
                  </div>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style={{ paddingTop: 15 }}
                  >
                    <LabelInput
                      disabled={true}
                      label={"Agency"}
                      value={this.refillData("agency_name")}
                    />
                  </div>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style={{ paddingTop: 15 }}
                  >
                    <LabelInput
                      disabled={true}
                      label={"Years Active"}
                      value={this.refillData("experience") + " Years"}
                    />
                  </div>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style={{ paddingTop: 15 }}
                  >
                    <LabelInput
                      disabled={true}
                      label={"Primary Email ID"}
                      value={this.refillData("email")}
                    />
                  </div>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style={{ paddingTop: 15 }}
                  >
                    {/* <LabelInput
                      disabled={true}
                      label={"Phone Number"}
                      value={this.refillData("phoneNumber")}
                    /> */}
                    <PhoneInput
                        country={'us'}
                        inputStyle={{ width: "100%",
                        background: "rgb(55, 57, 68)",
                        border: "rgb(55, 57, 68)",
                        color: "white",
                        height: 45,
                        opacity: 0.5
                      }}
                      containerStyle={{
                        background: "transparent",
                        border: "transparent",

                      }}
                      // buttonStyle={{
                      //     display: "none"
                      // }}
                      disabled
                        value={this.refillData("phoneNumber")}
                        />
                  </div>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style={{ paddingTop: 15 }}
                  >
                    <LabelInput
                      disabled={true}
                      label={"Location"}
                      value={
                        this.refillData("city") +
                        ", " +
                        this.refillData("state")
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                class="col-xl-9 col-lg-9 col-md-9 col-sm-6 col-12"
                style={{ paddingTop: 15 }}
              >
                <span className={classes.viewAgentInnerText}>
                  Services Offered
                </span>
                <div className={"row"}>{serviceData}</div>
                <div className={"row"}>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style={{ paddingTop: 15 }}
                  >
                    <span className={classes.viewAgentInnerText}>
                      Certificate Uploaded
                    </span>
                  </div>
                </div>
                <div className={"row"}>{certificateData}</div>
              </div>
            </div>
          </div>
          <Modal
            open={this.state.modal}
            onClose={() => this.onCloseModal()}
            center
            showCloseIcon={false}
          >
            <div style={{ padding: 10 }}>
              <img alt="modalId"
                src={this.state.modalId}
                className={"img-responsive"}
                style={{ width: 580, height: 387 }}
              />
            </div>
            <p style={{ float: "right", paddingRight: 10, paddingLeft: 10 }}>
              <Button
                extraStyle={{ color: "#000" }}
                buttonStyle={classes.buttonStyle1}
                buttonName={"Close"}
                fullWidth={false}
                onClick={() => {
                  this.onCloseModal();
                }}
              />
            </p>
          </Modal>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(
  withStore(ViewAgentDetails)
);
