import React, { Component } from "react";
import { withStore } from "../../component/hooks/store";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  /*InputBase, Select,*/ MenuItem,
  Container,
  CssBaseline,
} from "@material-ui/core";
import Styles from "../../styles/player/playerAgentSearch";
// import Input from "../../component/input";
import Button from "../../component/button";
import SelectBox from "../../component/selectBox";
import Config from "../../helper/config";
import {
  doGetConnectSecurity,
  checkNullAndReturnString,
  doGetConnect,
} from "../../helper/common";
import TablePagination from "@material-ui/core/TablePagination";
import Footer from "../manage/footer";
import Header from "./header";
// import BackArrow from "../../images/backArrow.png";
import AgentCard from "../../component/agentCard";
// import TextInput from "react-textinput-field";
import BackOption from "../../component/back";
import LabelInput from "../../component/labelInput";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Multiselect } from 'multiselect-react-dropdown';

class AgenrtPlayerSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      agentName: "",
      agencyName: "",
      experience: "",
      city: "",
      state: "",
      serviceOffered: 0,
      total: 0,
      list: [],
      servicesList: [{ label: "Service Offered", value: 0 }],
      stateList: [],
      selectState: [],
      sortToggler: false,
      sortBy: "first_name",

      paginations: {
        paginatonpageindex: 0,
        paginationrowsPerPage: 50,
        paginationcountdata: 0,
      },
    };
    this.anchorRef = React.createRef();
  }

  clearFunction = () => {
    this.setState({
      agentName: "",
      agencyName: "",
      experience: "",
      city: "",
      state: "",
      serviceOffered: 0,
      total: 0,
      list: [],
    });
    setTimeout(() => this.searchAgentList(), 500);
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value }, () =>
      key === "sortBy" ? this.searchAgentList() : {}
    );
  };

  componentDidMount = async () => {
    const { location } = this.props;
    if (location.state && location.state.paginations) {
      this.setState(
        (pre) => ({
          ...pre,
          ...location.state,
        }),
        this.searchAgentList
      );
    }
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.searchAgentList();
    var responseData = await doGetConnect("master-data/agent/services");
    if (checkNullAndReturnString(responseData)) {
      var servicesList = [{ label: "Service Offered", value: 0 }];
      Object.keys(responseData).forEach((key) => {
        servicesList.push({
          value: responseData[key].id,
          label: responseData[key].service_name,
        });
      });
      this.setState({ servicesList });
    }
    var responseData1 = await doGetConnect("master-data/states")
    if (checkNullAndReturnString(responseData1)) {
        var responseStateData = JSON.parse(JSON.stringify(responseData1).split('"id":').join('"id":'));
        responseStateData = JSON.parse(JSON.stringify(responseStateData).split('"state":').join('"name":'));
        var stateList = responseStateData;
        this.setState({ stateList })
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions);
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  searchAgentList = async () => {
    this.setState({ total: 0, list: [] });
    var accessToken = localStorage.getItem("accessToken");
    var {
      agencyName,
      agentName,
      experience,
      city,
      state,
      serviceOffered,
      sortBy,
      paginations,
    } = this.state;
    if (Number(serviceOffered) === 0) {
      serviceOffered = "";
    }

    const { paginatonpageindex, paginationrowsPerPage } = paginations;
    const pagefrom = paginationrowsPerPage * paginatonpageindex;
    const pageto = paginationrowsPerPage;

    var responseData = await doGetConnectSecurity(
      "users?id=&status=&roleId=3&userAccessStatus=&name=" +
      agentName +
      "&city=" +
      city +
      "&state=" +
      state +
      "&agencyName=" +
      agencyName +
      "&experience=" +
      experience +
      "&agentServices=" +
      serviceOffered +
      "&skip=" +
      pagefrom +
      "&take=" +
      pageto +
      "&order=ASC&sortby=" +
      sortBy,
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      let paginationssetdata = {
        paginatonpageindex,
        paginationrowsPerPage,
        paginationcountdata: Number(responseData.total),
      };
      // this.setState({ total: responseData.total })
      if (checkNullAndReturnString(responseData.data)) {
        this.setState({
          list: responseData.data,
          total: responseData.total,
          paginations: paginationssetdata,
        });
      }
    }
  };

  handleToggle = () => {
    this.setState((pre) => ({ sortToggler: !pre.sortToggler }));
  };

  handleClose = (event) => {
    if (
      this.anchorRef.current &&
      this.anchorRef.current.contains(event.target)
    ) {
      return;
    }
    this.setState({ sortToggler: false });
  };

  sortByName() {
    let sortBy = this.state.sortBy;
    let sortByName = "Name";
    if (sortBy === "id") {
      sortByName = "Overall Rating";
    } else if (sortBy === "first_name") {
      sortByName = "Name";
    } else if (sortBy === "agency_name") {
      sortByName = "Agency Name";
    } else if (sortBy === "experience") {
      sortByName = "Experience";
    } else if (sortBy === "city") {
      sortByName = "City";
    } else if (sortBy === "state") {
      sortByName = "Stare";
    }
    return sortByName;
  }

  handleChangePage = async (event, newPage) => {
    const paginationvalue = {
      ...this.state.paginations,
      paginatonpageindex: newPage,
    };
    this.setState(
      {
        paginations: { ...paginationvalue },
      },
      this.searchAgentList
    );
  };

  handleChangeRowsPerPage = async (event) => {
    const paginationvalue = {
      ...this.state.paginations,
      paginationrowsPerPage: event.target.value,
      paginatonpageindex: 0,
    };
    this.setState(
      {
        paginations: { ...paginationvalue },
      },
      this.searchAgentList
    );
  };

  getStateData = () => {
    const data = { ...this.state };
    delete data.width;
    delete data.height;
    delete data.total;
    delete data.list;
    delete data.servicesList;
    return data;
  };

  render() {
    const { classes } = this.props;
    var {
      agentName,
      agencyName,
      experience,
      city,
      serviceOffered,
      total,
      list,
      servicesList,
      paginations,
    } = this.state;
    var content = [];
    if (checkNullAndReturnString(list)) {
      Object.keys(list).forEach((key) => {
        content.push(
          <div
            key={key}
            className={"col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"}
            style={{
              paddingTop: 5,
              paddingLeft: 5,
              paddingBottom: 5,
              paddingRight: 5,
            }}
          >
            <Link
              to={{
                pathname:
                  Config.reactUrl +
                  "/player/playeragentview/player/" +
                  list[key].id,
                state: { ...this.getStateData() },
              }}
            >
              <AgentCard item={list[key]} />
            </Link>
          </div>
        );
      });
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <Header />
        <Container
          maxWidth="xl"
          component="main"
          className={classes.heroContent}
        >
          <div
            className="container-fluid"
            style={{ paddingBottom: 120, paddingTop: 50 }}
          >
            <div class="row rowView">
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding"
                style={{ paddingTop: 30 }}
              >
                <BackOption
                  history={this.props.history}
                  linkTo={Config.reactUrl + "/player/dashboard"}
                />
              </div>
            </div>
            <div class="row rowView">
              <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 noPadding">
                <div style={{ paddingTop: 20 }}></div>
                <span className={classes.searchPlayerText}>Search Agents</span>
                <div style={{ paddingTop: 20 }}></div>
                <LabelInput
                  updateInputValue={this.handleChange}
                  value={agentName}
                  name={"agentName"}
                  label={"Agent Name"}
                  extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                />
                <div style={{ paddingTop: 10 }}></div>
                <LabelInput
                  updateInputValue={this.handleChange}
                  value={agencyName}
                  name={"agencyName"}
                  label={"Agency Name"}
                  extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                />
                <div style={{ paddingTop: 10 }}></div>
                <LabelInput
                  updateInputValue={this.handleChange}
                  value={experience}
                  name={"experience"}
                  label={"Years Active"}
                  extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "inline-flex",
                    paddingTop: 10,
                  }}
                >
                  <div style={{ width: "48%" }}>
                    <LabelInput
                      updateInputValue={this.handleChange}
                      value={city}
                      name={"city"}
                      label={"City"}
                      extraStyle={{
                        font: "italic normal 300 12px/18px Poppins",
                      }}
                    />
                  </div>
                  <div style={{ width: "4%" }}></div>
                  <div style={{ width: "48%" }} className={"selectsingleselect singleSelectAgentSerachColor"}>
                    {/* <LabelInput
                      updateInputValue={this.handleChange}
                      value={state}
                      name={"state"}
                      label={"State"}
                      extraStyle={{
                        font: "italic normal 300 12px/18px Poppins",
                      }}
                    /> */}
                    <Multiselect
                        style={{display: "flex"}}
                        // singleSelect={true}
                        options={this.state.stateList} // Options to display in the dropdown
                        selectedValues={this.state.selectState} // Preselected value to persist in dropdown
                        onSelect={(e) => {
                            this.setState({ selectState : [e[e.length - 1]], state: e[e.length - 1].name })
                        }} // Function will trigger on select event
                        placeholder={this.state.state ? "" : "State"}
                        onRemove={(e) => {
                            this.setState({ selectState : [], state: '' })
                        }} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                    />
                  </div>
                </div>
                <div style={{ paddingTop: 10 }}></div>
                <SelectBox
                  onChange={this.handleChange}
                  keyName={"serviceOffered"}
                  options={servicesList}
                  value={serviceOffered}
                />
                {/* <div
                  style={{
                    width: "100%",
                    display: "inline-flex",
                    paddingTop: 10,
                  }}
                >
                  <span className={classes.mediaText}>Media Requirement</span>
                </div> */}
                <div style={{ textAlign: "center", paddingTop: 20 }}>
                  <Button
                    buttonStyle={classes.buttonStyle1}
                    buttonName={"Clear"}
                    fullWidth={false}
                    onClick={this.clearFunction}
                  />
                  <span style={{ paddingRight: 20 }}></span>
                  <Button
                    buttonStyle={classes.buttonStyle}
                    buttonName={"Search"}
                    fullWidth={false}
                    onClick={this.searchAgentList}
                  />
                </div>
              </div>
              <div
                class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 "
                style={{ padding: 10, paddingTop: 30 }}
              >
                {this.state.width > 992 ? (
                  <span
                    style={{
                      position: "absolute",
                      left: 15,
                      marginTop: 30,
                      width: 3,
                      height: this.state.height / 1.3,
                      background: "#3B3E50 0% 0% no-repeat padding-box",
                    }}
                  >
                    {" "}
                  </span>
                ) : (
                  ""
                )}
                <div className={"searchPlayerResponseClass1"}>
                  <span className={classes.resultText}>
                    Showing {total} Results
                  </span>
                  <span className={classes.sortByText}>
                    Sort by
                    <span
                      ref={this.anchorRef}
                      onClick={this.handleToggle}
                      style={{
                        color: "#1891BD",
                        paddingLeft: 10,
                        cursor: "pointer",
                      }}
                    >
                      {this.sortByName()}
                    </span>
                    <Popper
                      open={this.state.sortToggler}
                      anchorEl={this.anchorRef.current}
                      className={classes.Popper}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener
                              onClickAway={(e) => this.handleClose(e)}
                            >
                              <MenuList
                                autoFocusItem={this.state.sortToggler}
                                className={classes.PopColor}
                                id="menu-list-grow"
                                onKeyDown={this.handleListKeyDown}
                              >                                
                                <MenuItem
                                  onClick={(e) => {
                                    this.handleChange("sortBy", "first_name");
                                    this.handleClose(e);
                                  }}
                                >
                                  Name
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    this.handleChange("sortBy", "agency_name");
                                    this.handleClose(e);
                                  }}
                                >
                                  Agency Name
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    this.handleChange("sortBy", "experience");
                                    this.handleClose(e);
                                  }}
                                >
                                  Years Active
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    this.handleChange("sortBy", "city");
                                    this.handleClose(e);
                                  }}
                                >
                                  City
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    this.handleChange("sortBy", "state");
                                    this.handleClose(e);
                                  }}
                                >
                                  State
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </span>
                </div>
                {list.length ? (
                  <div
                    className="row searchAgentResponseClass1"
                    style={{
                      paddingTop: 10,
                      height:
                        Object.keys(list).length > 9
                          ? this.state.height / 1.3
                          : "",
                      overflow: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    {content}
                  </div>
                ) : (
                  <div class="norecordfound">
                    <div>No records found</div>
                  </div>
                )}

                <div>
                  <TablePagination
                    style={{ color: "white" }}
                    component="div"
                    count={paginations.paginationcountdata}
                    page={paginations.paginatonpageindex}
                    onChangePage={this.handleChangePage}
                    rowsPerPage={paginations.paginationrowsPerPage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    rowsPerPageOptions={[50, 100, 150, 200]}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(
  withStore(AgenrtPlayerSearch)
);
