// import LoginBg from "../images/login-bg.png";
const styles = theme => ({
    sectionStyle: {
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "hidden",
        minWidth: "100%",
        minHeight: "100%",
        background: "#fff",
    },
    paper: {
        marginTop: 160,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: "#262834 0% 0% no-repeat padding-box",
        padding: 20
    },
    avatar: {
        opacity: 1,
        width: 175,
        height: 81,
        // opacity: 1
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        background: "#fff",
        padding: 20,
    },
    buttonStyle: {
        background: '#1891BD',
        borderRadius: 5,
        border: 0,
        color: 'white',
        height: 46,
        padding: '0 30px',
        opacity: 1,
        "&:hover": {
            background: '#1891BD',
        }
    },
    emailText: {
        textAlign: "center",
        font: "normal normal 600 16px/25px Poppins",
        color: "#262834",
        opacity: 1
    },
    successText: {
        font: "normal normal normal 20px/30px Poppins",
        color: "#FFFFFF",
        opacity: 1
    },
    labelText: {
        font: "normal normal normal 14px/21px Poppins",
        color: "#262834",
        opacity: 1,
    },
    footerLabel: {
        font: "normal normal normal 12px/18px Poppins",
        color: "#262834",
        opacity: 1,
    }
});
export default styles;