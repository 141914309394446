const styles = theme => ({
    messageText: {
        font: "normal normal 600 18px/27px Poppins",
        color: "#FFFFFF",
        opacity: 1
    },
    allMessageText: {
        font: "normal normal normal 12px/18px Poppins",
        color: "#FFFFFF",
        opacity: 1,
        paddingLeft: 15
    },
    name: {
        font: "normal normal normal 12px/18px Poppins",
        color: "#FFFFFF",
        opacity: 1,
        paddingLeft: 20
    },
    player: {
        font: "normal normal normal 10px/16px Poppins",
        color: "#959595",
        opacity: 1,
        paddingLeft: 20
    },
    menu: {
        width: 32,
        height: 32,
    },
    viewProfile: {
        font: "normal normal normal 14px/21px Poppins",
        color: "#1891BD",
        opacity: 1,
    },
    rightMsgWidth: {
        width: "60%",
        padding: 8,
        paddingBottom: 0,
        float: "left"
    },
    rightMsgBox: {
        background: "#5D627D 0% 0% no-repeat padding-box",
        padding: 10,
        paddingBottom: 5,
        paddingTop: 5,
        borderRadius: 10,
        float: "left",
        borderTopLeftRadius: 0
    },
    rightMsgText: {
        font: "normal normal normal 12px/18px Poppins",
        color: "#FFFFFF",
        opacity: 1,
        margin: 0
    },
    leftMsgWidth: {
        width: "60%",
        padding: 8,
        paddingBottom: 0,
        float: "right"
    },
    leftMsgBox: {
        background: "#3B3E50 0% 0% no-repeat padding-box",
        padding: 10,
        paddingBottom: 5,
        paddingTop: 5,
        borderRadius: 10,
        float: "right",
        borderBottomRightRadius: 0
    },
    leftMsgText: {
        font: "normal normal normal 12px/18px Poppins",
        color: "#FFFFFF",
        opacity: 1,
        margin: 0
    },
    textAreaStyle: {
        background: "transparent", color: "#FFFFFF",
        font: "italic normal 300 12px/18px Poppins",
        height: 60,
        opacity: 0.7,
        // border: "none",
        width: "100%",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        border: "1px solid #3B3E50",
        borderRadius: 5,
        outline: "none",
        "&:hover": {
            outline: "none"
        },
        "&:focus": {
            outline: "none"
        },
        // ...extraStyle
    },
    inputStyle: {
        background: "transparent", color: "#FFFFFF",
        font: "italic normal 300 12px/18px Poppins",
        height: 40,
        opacity: 0.7,
        // border: "none",
        width: "100%",
        paddingLeft: 20,
        paddingRight: 40,
        border: "1px solid #3B3E50",
        borderRadius: 22,
        outline: "none",
        "&:hover": {
            outline: "none"
        },
        "&:focus": {
            outline: "none"
        },
        // ...extraStyle
    },
    sendText: {
        font: "normal normal normal 14px/21px Poppins",
        color: "#FFFFFF",
        opacity: 1
    },
    timeText: {
        font: "normal normal normal 8px/12px Poppins",
        color: "#959595",
        opacity: 1,
        margin: 0,
        paddingTop: 5
    }
});
export default styles;