import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, Container, CssBaseline } from '@material-ui/core';
import Styles from '../../styles/uaAdmin/managerUsers';
import { withStore } from '../../component/hooks/store';
import Header from './header';
import CreateUser from './createUsers';
import { checkNullAndReturnString, doGetConnectSecurity, doPutConnectSecurity } from '../../helper/common';
import Config from '../../helper/config';
import { toast } from 'react-toastify';
import Button from "../../component/button";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import BackOption from "../../component/back";
import TablePagination from "@material-ui/core/TablePagination";
import { Modal } from 'react-responsive-modal';

const SubAdmin = (props) => {
    const [user, setuser] = useState([]);
    const [filter, setfilter] = useState({
        sortByKey: 'first_name',
        sortByOrder: true,
        paginations: {
            paginatonpageindex: 0,
            paginationrowsPerPage: 50,
            paginationcountdata: 100
        }
    });

    const [assignfilter, setassignfilter] = useState({
        sortByKey: 'first_name',
        sortByOrder: true,
        paginations: {
            paginatonpageindex: 0,
            paginationrowsPerPage: 50,
            paginationcountdata: 100
        }
    });

    const [dialogdata, setdialogdata] = useState({
        open: false,
        data: null
    });
    // const [trigger, settrigger] = useState(true);
    const [sortToggler, setsortToggler] = useState(false);

    const { classes } = props;
    // const [
    // loginUser, 
    // setLoginUser] = useState({});
    let anchorRef = useRef();

    useEffect(() => {
        // setLoginUser(props.location.state);
    }, [props]);

    const getUAList = useCallback(async () => {
        const { sortByKey, sortByOrder, paginations } = filter;
        const { paginatonpageindex, paginationrowsPerPage } = paginations;
        const pagefrom = paginationrowsPerPage * paginatonpageindex;
        const pageto = paginationrowsPerPage;

        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doGetConnectSecurity(
            `users?id=&status=&roleId=1&name=&skip=${pagefrom}&take=${pageto}&order=${(sortByOrder ? 'ASC' : 'DESC')}&sortby=${sortByKey}&userAccessStatus=`, accessToken);
        if (responseData.data && responseData.total) {
            let paginationssetdata = { paginatonpageindex, paginationrowsPerPage, paginationcountdata: Number(responseData.total) };
            let filterdata = { ...filter };
            filterdata.paginations = paginationssetdata
            setuser(responseData.data);
            // setfilter(filterdata);
            setassignfilter(filterdata);
        } else {
        }
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data)) {
            }
        }
    }, [filter])

    useEffect(() => {
        getUAList();
    }, [getUAList, filter]);

    const setsorttogglers = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setsortToggler(pre => !pre)
    }

    const handleClose = () => {
        setdialogdata({
            open: false,
            data: null
        })
    }

    const changeStatus = async (id, val) => {
        var accessToken = localStorage.getItem("accessToken");
        var postJson = {
            status: val
        }
        var responseData = await doPutConnectSecurity("users/status/" + id, postJson, accessToken)
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.message)) {
                toast.success(responseData.message)
                getUAList();
                handleClose()
            }
            else {
                if (checkNullAndReturnString(responseData.error)) {
                    if (checkNullAndReturnString(responseData.error.message)) {
                        toast.error(responseData.error.message);
                    }
                }
            }
        }
    }


    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setsortToggler(pre => !pre)
        }
    }

    const onSortData = (column) => {
        setfilter((pre) => ({
            ...pre,
            sortByKey: column
        }));
        // settrigger(pre => !pre);
        setsortToggler(false);
    }


    const handleChangePage = async (event, newPage) => {
        // const paginationvalue = { ...filter.paginations, paginatonpageindex: newPage };
        let paginationvalue = { ...filter };
        paginationvalue.paginations.paginatonpageindex = newPage;
        setfilter({ ...paginationvalue });
        // settrigger(pre => !pre);
    }

    const handleChangeRowsPerPage = async (event) => {
        // const paginationvalue = { ...this.state.paginations, paginationrowsPerPage: event.target.value, paginatonpageindex: 0 };
        let paginationvalue = { ...filter };
        filter.paginations.paginationrowsPerPage = event.target.value;
        filter.paginations.paginatonpageindex = 0;
        setfilter({ ...paginationvalue });
        // settrigger(pre => !pre);
    }

    const userDetail = () => {
        var pendingData = [];
        if (checkNullAndReturnString(user)) {
            Object.keys(user).forEach((key, index) => {
                var item = user[key];
                pendingData.push(
                    <div key={index}>
                        <div style={{ paddingTop: 10 }}></div>
                        <div className={classes.border} ></div>
                        <div class="row" >
                            <div class="col" style={{ maxWidth: 100, paddingTop: 12, alignSelf: "center", textAlign: "center" }}>
                                {
                                    checkNullAndReturnString(item.profile_image_hash) ?
                                        <img className={classes.personImage} src={Config.dataUrl + "aws-files/" + item.profile_image_hash} alt="awsfileimage" />
                                        :
                                        <img className={classes.personImage} src={Config.emptyUser} alt="definiteimage" />
                                }
                            </div>
                            <div class="col" style={{ paddingTop: 12 }}>
                                <span className={classes.player}> Full Name </span>
                                <br />
                                <span className={classes.playerName}> {item.first_name} {item.last_name} </span>
                            </div>
                            {/* <div class="col" style={{ paddingTop: 12 }}>
                                <span className={classes.player}> Experience </span>
                                <br />
                                <span className={classes.playerName}> {(item.experience ? item.experience : '0')} Years</span>
                            </div> */}
                            <div class="col" style={{ paddingTop: 12 }}>
                                <span className={classes.player}> Email </span>
                                <br />
                                <span className={classes.playerName}> {item.email} </span>
                            </div>                            
                            <div class="col" style={{ paddingTop: 12 }}>
                                <span className={classes.player}> Status </span>
                                <br />
                                <span className={classes.playerName}> {(
                                    item.status ?
                                        <span style={{
                                            color: "#73B86F",
                                            font: "normal normal normal 14px/21px Poppins",
                                            opacity: 1,
                                        }}>Active</span> :
                                        <span style={{
                                            color: 'orangered',
                                            font: "normal normal normal 14px/21px Poppins",
                                            opacity: 1,
                                        }}>Inactive</span>
                                )} </span>
                            </div>
                            <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                                <Button buttonStyle={classes.buttonStyle1} buttonName={(
                                    item.status ? 'De-Activate Account' : 'Activate Account'
                                )} fullWidth={false} extraStyle={{ whiteSpace: 'nowrap' }} onClick={() => setdialogdata({
                                    open: true,
                                    data: item
                                })} />
                            </div>
                            {/* <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                                <Button buttonStyle={classes.buttonStyle1} buttonName={"REJECT"} fullWidth={false} onClick={() => this.changeStatus(item.id, "rejected")} />
                            </div> */}
                        </div>
                    </div>
                )
            })
        }
        return pendingData
    }


    const sortByName = () => {
        let sortBy = filter.sortByKey;
        let sortByName = 'Overall Rating';
        if (sortBy === 'id') {
            sortByName = 'Overall Rating'
        } else if (sortBy === 'first_name') {
            sortByName = 'Name'
        } else if (sortBy === 'experience') {
            sortByName = 'Experience'
        } else if (sortBy === 'city') {
            sortByName = 'Location'
        } else if (sortBy === 'status') {
            sortByName = 'Status'
        };
        return sortByName;
    }

    return (
        <React.Fragment>
            <CssBaseline>
                <Header history={props.history} />
                <Container maxWidth="xl" component="main" className={classes.heroContent}>
                    <div className="container-fluid" style={{ paddingBottom: 120, paddingTop: 30 }}>
                        <div class="row rowView" >
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding" style={{ paddingTop: 30 }}>
                                <BackOption history={props.history} />
                            </div>
                        </div>
                        <div class="row rowView" >
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" >
                                <CreateUser
                                    resetselectedSchool={(newUser) => getUAList()}
                                    userFromLogin={true}
                                    from={'admin'}
                                />
                            </div>
                            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 " style={{ padding: 10 }}>
                                <div style={{ display: 'inline' }}>
                                    <p className={classes.Header} style={{ margin: 0 }}>Registered Admins</p>
                                    {/* <span style={{ position: "absolute", border: "1px solid #3B3E50", width: 0, height: window.innerHeight / 1.3, whiteSpace: "nowrap" }}></span> */}
                                    <span className={classes.agentCountText}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span className={classes.sortByText}>
                                        Sort by
                                        <span
                                            ref={anchorRef}
                                            onClick={() => setsortToggler(pre => !pre)}
                                            style={{ color: "#1891BD", paddingLeft: 10, cursor: 'pointer' }}>
                                            {sortByName()}
                                        </span>
                                        <Popper open={sortToggler}
                                            anchorEl={anchorRef.current}
                                            className={classes.Popper}
                                            role={undefined} transition disablePortal>
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin: placement === 'bottom' ?
                                                            'center top' : 'center bottom'
                                                    }}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener
                                                            onClickAway={(e) => setsorttogglers(e)}>
                                                            <MenuList
                                                                autoFocusItem={sortToggler}
                                                                className={classes.PopColor}
                                                                id="menu-list-grow"
                                                                onKeyDown={handleListKeyDown}
                                                            >                                                               
                                                                <MenuItem onClick={(e) => {
                                                                    onSortData('first_name')
                                                                }}>Name</MenuItem>                                                                                                                                
                                                                <MenuItem onClick={(e) => {
                                                                    onSortData('status')
                                                                }}>Status</MenuItem>
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </span>
                                </div>
                                <div>
                                    {
                                        userDetail()
                                    }
                                    <div style={{ paddingTop: 10 }}></div>
                                    <div className={classes.border} ></div>
                                </div>
                                <TablePagination
                                    style={{ color: 'white' }}
                                    component="div"
                                    count={assignfilter.paginations.paginationcountdata}
                                    page={assignfilter.paginations.paginatonpageindex}
                                    onChangePage={handleChangePage}
                                    rowsPerPage={assignfilter.paginations.paginationrowsPerPage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[50, 100, 150, 200]}
                                // rowsPerPageOptions={[2, 4, 6, 8]}
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </CssBaseline>
            <Modal open={dialogdata.open} onClose={handleClose} center showCloseIcon={false}>
                {
                    dialogdata.data &&
                    <div style={{ padding: 20 }}>
                        {
                            dialogdata.data.status ?
                                <p style={{ font: "normal normal 600 18px/27px Poppins", opacity: 1 }}>Disable Account</p>
                                :
                                <p style={{ font: "normal normal 600 18px/27px Poppins", opacity: 1 }}>Enable Account</p>
                        }
                        <p style={{ font: "normal normal normal 14px/21px Poppins", opacity: 1 }}>
                            Are you sure want to {dialogdata.data.status ? 'disable' : 'enable'} {dialogdata.data.first_name} {dialogdata.data.last_name}'s account ? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div>
                                <Button buttonStyle={classes.buttonStyle2} buttonName={"Cancel"} fullWidth={false}
                                    onClick={handleClose} />
                                <span style={{ paddingRight: 20 }}></span>
                                <Button buttonStyle={classes.buttonStyle3} buttonName={"Confirm"} fullWidth={false}
                                    onClick={() => changeStatus(dialogdata.data.id, !dialogdata.data.status)} />
                            </div>
                        </div>
                    </div>
                }
            </Modal>
        </React.Fragment>
    )
};

export default withStyles(Styles, { withTheme: true })(withStore(SubAdmin))