import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import { doGetConnectSecurity } from '../../helper/common';
import { withStyles } from '@material-ui/core/styles';
import { withStore } from '../../component/hooks/store';
import Styles from "../../styles/login.js";
import { toast } from "react-toastify";

class Footer extends Component {

    state = {
        versiondetails: null,
        accessToken: ""
    }

    componentDidMount = async () => {
        this.getVersionDetail();
        var accessToken = localStorage.getItem("accessToken");
        this.setState({ accessToken })
    }

    getVersionDetail = async () => {
        try {
            var accessToken = localStorage.getItem("accessToken");
            var responseData1 = await doGetConnectSecurity(
              `version-history?skip=0&take=1&sortby=id&order=DESC&type=web`,
              accessToken
            );

            if (responseData1 && responseData1.data && responseData1.data.length) {
                const { data: [dataone] } = responseData1;
                if (dataone) {
                    this.setState({
                        versiondetails: dataone
                    })
                }
            }
        } catch (err) {
            toast.error(err.message ? err.message : 'Error while getting version details')
        }
    }

    render() {
        var { from } = this.props;
        var { versiondetails } = this.state;
        var footerLinkText = {
            paddingRight: 10,
            paddingLeft: 10,
            color: "#ffffff",
            font: "normal normal normal 12px/18px Poppins",
        }
        var date = new Date()
        return (
            <div >
                <footer class="page-footer font-small mdb-color" style={{ zIndex: from === "login" || from === "extra" ? 1 : 10000, width: "100%", position: "fixed", bottom: 5, background: from === "login" || from === "extra" ? "" : "#262834 0% 0% no-repeat padding-box" }}>
                    <div class="container-fluid text-center text-md-left">
                        <div class="row d-flex align-items-center">
                            <div class="col-xl-6 col-lg-5 col-md-4 col-sm-12 col-12">
                                <span class="text-center text-md-left" style={footerLinkText}>© {date.getFullYear()} NXTPICK, All rights reserved.
                                </span>
                            </div>
                            <div class="col-xl-6 col-lg-7 col-md-8 col-sm-12 col-12">
                                <div class="text-center text-md-right">
                                    <ul class="list-unstyled list-inline">

                                        {
                                            (from === "login" && versiondetails && versiondetails.version_id) &&
                                            (
                                                <li class="list-inline-item" style={{ padding: 0, paddingTop: 10 }}>
                                                    <span style={footerLinkText}>
                                                        <a href="/version-history/details" target="_blank" class="navbar-brand"
                                                            // className={classes.vname}
                                                            style={{
                                                                color: '#ffffff', paddingTop: '0.51rem',
                                                                fontWeight: '400', fontSize: '13px', cursor: 'pointer'
                                                            }}
                                                        >
                                                            {'Version - '.concat(versiondetails.version_id)}
                                                        </a>
                                                    </span>
                                                </li>
                                            )
                                        }

                                        {
                                            !this.state.accessToken &&
                                            <li class="list-inline-item" style={{ padding: 0, paddingTop: 10 }} onClick={() => {
                                                window.location.href = "/"
                                            }}>
                                                <span style={footerLinkText}> Home </span>
                                            </li>
                                        }
                                        <li class="list-inline-item" style={{ padding: 0, paddingTop: 10 }} onClick={() => {
                                            window.location.href = "/frequently-asked-questions"
                                        }}>
                                            <span style={footerLinkText}> FAQ </span>
                                        </li>
                                        <li class="list-inline-item" style={{ padding: 0, paddingTop: 10 }} onClick={() => {
                                            window.location.href = "/aboutnxtpick"
                                        }}>
                                            <span style={footerLinkText}> About Nxtpick </span>
                                        </li>
                                        <li class="list-inline-item" style={{ padding: 0, paddingTop: 10 }} onClick={() => {
                                                    window.location.href = "/support"
                                                }}>
                                            <span style={footerLinkText}> Support </span>
                                        </li>
                                        <li class="list-inline-item" style={{ padding: 0, paddingTop: 10 }} onClick={() => {
                                            window.location.href = "/privacy-policy"
                                        }}>
                                            <span style={footerLinkText}> Privacy Policy </span>
                                        </li>
                                        {
                                            from === "login" ?
                                                <li class="list-inline-item" style={{ padding: 0, paddingTop: 10 }} onClick={() => {
                                                    window.location.href = "/signup"
                                                }}>
                                                    <span style={footerLinkText}> Sign up </span>
                                                </li>
                                                :
                                                ""
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {
                    from === "login" ? ""
                        :
                        from === "extra" ? ""
                            :
                            <footer class="navbar fixed-bottom content container-fluid footer footer-light footer-shadow" style={{ height: 5, background: "#262834 0% 0% no-repeat padding-box" }}>
                            </footer>
                }
            </div>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(Footer))