import React, { Component } from 'react'
// import { Link } from "react-router-dom";
import { withStore } from '../../component/hooks/store';
import { withStyles } from '@material-ui/core/styles';
import { Container, CssBaseline } from '@material-ui/core';
import Footer from "../manage/footer";
import Header from "./header";
import Styles from "../../styles/player/playerPerformence";
import BackOption from "../../component/back";

class PlayerPerformence extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { classes } = this.props;        
        return (
            <React.Fragment>
                <CssBaseline />
                <Header />
                <Container maxWidth="xl" component="main" className={classes.heroContent}>
                    <div className="container-fluid" style={{ paddingBottom: 110 }}>
                        <div class="row rowView">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 40 }}>
                            <BackOption history={this.props.history} linkTo={""} />
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <span className={classes.playerCompareText}>Player Performance divided by Combine Average</span>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <span className={classes.playerName}>A player’s workout performance is measured against the average performances of players that participated in the most recent NFL combine. These measurements are averaged by position. This means that Running Backs are measured against Running Backs, Defensive Ends against Defensive Ends and so on. A player’s performance is submitted by their respective university’s sports administrator (Athletic Director, coach, etc.) and cannot be adjust by players themselves.</span>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <span className={classes.playerCompareText}>Scoring</span>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <span className={classes.playerName}>The averaged NFL combine results are set as “goals to achieve” per test (exercise). A player’s performance is then set as a percent to goal. This is much like grades for an exam, where the averaged results from the combine equal 100% or a perfect score for that test. A player’s results are the grade they receive compared to the combine averages.</span>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <span className={classes.playerCompareText}>The Overall % score can be considered as a player’s GPA. Meaning, this is all of a player’s individual drill averages averaged together.</span>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <span className={classes.playerCompareText}>Example</span>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <span className={classes.playerName}>Player A is a Wide Receiver. The average 225 Bench Reps for Wide Receivers from the latest NFL combine was 20 reps. Player A posted 15 reps during their last testing session and was submitted by their strength and conditioning coach. Player A’s % to goal is 75%...15 divided by 20.</span>
                            </div>
                            
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <div class="row rowView">
                                <div style={{ width: "90px", height: "30px", backgroundColor: "lightblue", borderWidth: "5px", borderColor: "black" }}>
                                
                                    </div>
                                    <p className={classes.playerName} style={{paddingLeft:"10px"}}>Over 100% to goal</p>
                                </div>
                                
                            </div>
                            
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <div class="row rowView">
                                <div style={{ width: "90px", height: "30px", backgroundColor: "lightgreen", borderWidth: "5px", borderColor: "black" }}>
                                
                                    </div>
                                    <p className={classes.playerName} style={{paddingLeft:"10px"}}>90%-100% to goal</p>
                                </div>
                                
                            </div>
                            
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <div class="row rowView">
                                <div style={{ width: "90px", height: "30px", backgroundColor: "yellow", borderWidth: "5px", borderColor: "black" }}>
                                
                                    </div>
                                    <p className={classes.playerName} style={{paddingLeft:"10px"}}>80%-89% to goal</p>
                                </div>
                                
                            </div>
                            
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <div class="row rowView">
                                <div style={{ width: "90px", height: "30px", backgroundColor: "darkgoldenrod", borderWidth: "5px", borderColor: "black" }}>
                                
                                    </div>
                                    <p className={classes.playerName} style={{paddingLeft:"10px"}}>70%-79% to goal</p>
                                </div>
                                
                            </div>

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <div class="row rowView">
                                <div style={{ width: "90px", height: "30px", backgroundColor: "red", borderWidth: "5px", borderColor: "black" }}>
                                
                                    </div>
                                    <p className={classes.playerName} style={{paddingLeft:"10px"}}>Less than 70% to goal</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </Container>
                <Footer />
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(PlayerPerformence));