import { Component } from "react";
import './version-history.css';
import Button from "../../component/button";
import Styles from "../../styles/versionHistory/versionHistory";
import { withStyles } from '@material-ui/core/styles';
import { withStore } from '../../component/hooks/store';
import LabelInput from "../../component/labelInput";
import Tables from "../../component/table";
import { doGetConnectSecurity, doPostConnectSecurity, doPutConnectSecurity } from "../../helper/common";
import CKEditor from "react-ckeditor-component";
import EditPng from "../../images/edit.png";
import logoImg from "../../images/nxtpick-logo.png";
import { toast } from "react-toastify";
const moment = require("moment");


class VersionHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            versionCode: '',
            versionDescription: '<p><strong>New Features</strong></p> <ul> <li>&nbsp;</li> </ul> <p><strong>BugsSquashed</strong></p> <ul> <li>&nbsp;</li> </ul> <p><strong>Announcements</strong>​​​​​​​</p> <ol> <li>​​​​​​​</li> </ol> ',
            type:'web',
            dataobj: {
                total: 0,
                data: []
            },
            editstate: {
                data: {},
                status: false
            },
            sortByKey: 'version_id',
            sortByOrder: false,
            modalpaginations: {
                paginatonpageindex: 0,
                paginationrowsPerPage: 50,
                paginationcountdata: 200
            },
        }
    }


    componentDidMount() {
        this.getVersionHistoryDetail()
    }

    getVersionHistoryDetail = async () => {
        const { sortByKey, sortByOrder, modalpaginations } = this.state;
        const { paginatonpageindex, paginationrowsPerPage } = modalpaginations;
        const pagefrom = paginationrowsPerPage * paginatonpageindex;
        const pageto = paginationrowsPerPage;

        try {
            var accessToken = localStorage.getItem("accessToken");
            var responseData = await doGetConnectSecurity(
                `version-history?skip=${pagefrom}&take=${pageto}&sortby=${sortByKey}&order=${sortByOrder ? 'ASC' : 'DESC'}&type=web`,
                accessToken);
            let paginationssetdata = {
                paginatonpageindex,
                paginationrowsPerPage,
                paginationcountdata: Number(responseData.total)
            };

            this.setState({
                modalpaginations: { ...paginationssetdata },
                dataobj: {
                    ...responseData
                }
            })

        } catch ({ error: err }) {
            toast.error(err && err.message ? err.message : 'Error while receiving school details');
        }
    };


    updateInputValue = (id, value) => {
        if (!value) {
            value = ''
        } else {
            value = value.replace(/[^.0-9]+/gm, '')
        };


        this.setState((pre) => {
            return {
                ...pre,
                [id]: value
            }
        }

        )
    }

    onChange = (evt) => {
        var newContent = evt.editor.getData();
        this.setState({
            versionDescription: newContent
        })
    }


    onSortModalData = (column) => {
        let key = 'version_id';
        if (column === "Version Code") { key = 'version_id' } else
            if (column === "Updated on") { key = 'created_on' }
        this.setState((pre) => ({
            sortByKey: key,
            sortByOrder: (pre.sortByKey === key ? !pre.sortByOrder : true)
        }), this.getVersionHistoryDetail);
    }


    handleChangePageModal = async (event, newPage) => {
        const paginationvalue = { ...this.state.modalpaginations, paginatonpageindex: newPage };
        this.setState({
            modalpaginations: { ...paginationvalue }
        }, this.getVersionHistoryDetail);
    }

    handleChangeRowsPerPageModal = async (event) => {
        const paginationvalue = { ...this.state.modalpaginations, paginationrowsPerPage: event.target.value, paginatonpageindex: 0 };
        this.setState({
            modalpaginations: { ...paginationvalue }
        }, this.getVersionHistoryDetail);
    }

    checkVersionDetail = () => {
        const { versionCode, versionDescription,type } = this.state;
        if (!versionCode && !versionDescription) {
            toast.error('Enter version code and description');
            return false;
        } else if (!versionCode || !versionDescription) {
            let error = '';
            if (!versionCode) {
                error = 'Version code is required'
            } else if (!versionDescription) {
                error = 'Version description is required'
            }
            toast.error(error);
            return false;
        } else {
            return {
                versionCode,
                releaseNote: versionDescription,
                type
            }
        }
    }


    saveVersionHistory = async () => {
        const statusdata = this.checkVersionDetail();
        if (statusdata) {
            // try {
            var accessToken = localStorage.getItem("accessToken");
            if (this.state.editstate.status) {
                try {
                    const { id } = this.state.editstate.data;
                    let responseData = await doPutConnectSecurity(`version-history/${id}`, statusdata, accessToken);
                    if (responseData) {
                        this.getVersionHistoryDetail();
                        this.resetForm();
                        toast.success('Version history updated successfully');
                    }
                } catch ({ error: err }) {
                    toast.error(err && err.message ? err.message : 'Error while inserting version history details');
                }
            } else {
                try {
                    let responseData = await doPostConnectSecurity("version-history", statusdata, accessToken);
                    if (responseData) {
                        this.getVersionHistoryDetail();
                        this.resetForm();
                        toast.success('Version history added successfully');
                    }
                } catch ({ error: err }) {
                    toast.error(err && err.message ? err.message : 'Error while updating version history details');
                }

            }
            // } catch ({ error: err }) {
            //     toast.error(err && err.message ? err.message : 'Error updating version history details');
            //     // this.setState({
            //     //     dataobj: {
            //     //         data: [],
            //     //         total: 0
            //     //     }
            //     // })
            // }
        }
    }


    resetForm = () => {
        this.setState((pre) => ({
            ...pre,
            editstate: {
                status: false,
                data: {}
            },
            versionCode: '',
            versionDescription: '<p><strong>New Features</strong></p> <ul> <li>&nbsp;</li> </ul> <p><strong>BugsSquashed</strong></p> <ul> <li>&nbsp;</li> </ul> <p><strong>Announcements</strong>​​​​​​​</p> <ol> <li>​​​​​​​</li> </ol> ',
        }));

    }



    render() {
        const { versionCode, versionDescription } = this.state;
        const { classes } = this.props;


        let UALists = [];
        if (this.state.dataobj.data.length) {
            const datas = [...this.state.dataobj.data];
            UALists = datas.map((ele) => {
                return {
                    version_code: ele.version_id,
                    updated_on: ele.created_on ? moment(ele.created_on).format('DD MMM YYYY') : '',
                    action: (
                        <>
                            <img src={EditPng} alt="EditPng" style={{ width: 20, height: 20, cursor: 'pointer' }} onClick={() => {
                                this.setState((pre) => ({
                                    ...pre,
                                    editstate: {
                                        ...pre.editstate,
                                        data: ele,
                                        status: true
                                    },
                                    versionCode: ele.version_id,
                                    versionDescription: ele.release_notes
                                }))
                            }} />
                        </>
                    )
                }
            })
            // UALists.push({
            //     version_code:
            // })
        }
        return (
            <div class="container-fluid">
                <div class="row logo">
                    <span>
                        <img src={logoImg} class="image" alt="the user state" id="eles" />
                    </span>
                    <div class="text-right">
                        {/* {
                            this.state.editstate.status &&
                            ( */}
                        <Button buttonStyle={classes.buttonStyle1} buttonName={"Cancel"}
                            extraStyle={{ color: 'white', paddingLeft: 20, paddingRight: 20, marginRight: '1rem' }} fullWidth={false} onClick={this.resetForm} />
                        {/* )
                        } */}
                        <Button buttonStyle={classes.buttonStyle}
                            buttonName={(this.state.editstate.status ? 'Edit' : 'Save')}
                            extraStyle={{ paddingLeft: 20, paddingRight: 20, marginRight: '1.5rem' }} fullWidth={false} onClick={this.saveVersionHistory} />
                    </div>
                </div>



                <div class="row" style={{ padding: '2% 2% 2% 2%' }}>
                    <div class="col-md-8 p-0 ">
                        {/* <div class="card cardhighlight p-3"> */}

                        <div class="row" >
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " style={{ paddingLeft: 20, paddingTop: 20 }}>
                                <span style={{ font: "normal normal normal 10px/16px Poppins", color: "#959595" }}>Version Code</span>
                                <div style={{ paddingTop: 10 }}></div>
                                <LabelInput updateInputValue={this.updateInputValue}
                                    name={"versionCode"}
                                    label={"Version Code"} value={versionCode} />
                            </div>
                        </div>

                        <div class="row" >
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " style={{ paddingLeft: 20, paddingTop: 20 }}>
                                <span style={{ font: "normal normal normal 10px/16px Poppins", color: "#959595" }}>Version Description</span>
                                <div style={{ paddingTop: 10 }}></div>
                                <CKEditor
                                    activeClass="p10"
                                    content={versionDescription}
                                    events={{
                                        "change": this.onChange
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 pl-4">
                        {/* <div class="card cardhighlight"> */}
                        <div class="table-responsive" style={{ borderRadius: '10px' }}>
                            <Tables thJson={["Version Code", "Updated on", ""]}
                                tdJson={UALists}
                                sortHeader={true} onSortData={this.onSortModalData}
                                paginationFooter={this.state.modalpaginations}
                                handleChangePage={this.handleChangePageModal}
                                handleChangeRowsPerPage={this.handleChangeRowsPerPageModal}
                                extraStyle={{}}
                                fixed={true}
                            />
                        </div>
                        {/* </div> */}
                    </div>
                </div>




            </div>
        )
    }
}

export default withStyles(Styles, { withTheme: true })(withStore(VersionHistory));
