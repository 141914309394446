import React, { Component } from 'react'
// import { Link } from "react-router-dom";
import { withStore } from '../../component/hooks/store';
import ProgressBar from '../../component/progressBar';
import { withStyles } from '@material-ui/core/styles';
import Button from "../../component/button";
import { Container, CssBaseline } from '@material-ui/core';
import Styles from "../../styles/agent/agentComparePlayer";
import Footer from "../manage/footer";
import Header from "./header";
import { doGetConnectSecurity, positionData, positionAverageData, positionAverageData1, numberConverter } from '../../helper/common';
import Config from '../../helper/config';
import BackArrow from "../../images/backArrow.png";
import { Link } from "react-router-dom";
import InfoPng from "../../images/info.png";

class AgentComparePlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user1: {},
            user2: {},
            searchplayerdetail: null
        }
    }

    checkUserIdvalidations = ({ user1id, user2id }) => {
        const userid1status = (/\d+/gi).test(user1id);
        const userid2status = (/\d+/gi).test(user2id);
        if (userid1status && userid2status) {
            return `${user1id},${user2id}`;
        }
        return false
    }
    async componentDidMount() {
        const ids = this.checkUserIdvalidations(this.props.match.params);
        const userpattern = this.props.match.params;
        var accessToken = localStorage.getItem("accessToken");
        var { data = [] } = await doGetConnectSecurity(`player/comparison?id=${ids}`, accessToken);
        let state = this.props.location.state;
        let searchplayerdetail = null;
        if (state && state.playerSearchDetail) {
            searchplayerdetail = state.playerSearchDetail
        }
        if (data) {
            this.setState({
                user1: data.find(e => (e.id && e.id.toString()) === (userpattern.user1id && userpattern.user1id.toString())),
                user2: data.find(e => (e.id && e.id.toString()) === (userpattern.user2id && userpattern.user2id.toString())),
                searchplayerdetail: searchplayerdetail
            });
        }
    }


    backToSearchPage = async () => {
        this.props.history.push({
            pathname: '/agent/agentplayersearch',
            state: {
                routeSearchUserDetail: (this.state.searchplayerdetail ? { ...this.state.searchplayerdetail } : null)
            }
        })
    }

    setBroadJumpVal = (val) => {
        let value = val.split('.');
        if (value.length === 1) {
            val = val.toString().concat('.0');
        }
        return val.replace(/(\d*)\.(\d*)/gi, `$1'$2"`)
    }
    render() {
        const { classes } = this.props;

        let playerPositionData = [];
        var positionArray = positionData(this.state.user1.position);
        if (positionArray.length) {
            positionArray.map((item, index) => {
                let maxPositionValue = Math.max(this.state.user1[item.value] ? this.state.user1[item.value] : 0, this.state.user2[item.value] ? this.state.user2[item.value] : 0);
                playerPositionData.push(
                    <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ padding: 0, paddingLeft: 20, paddingTop: 10 }}>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="row">
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                    <div class="float-right align-items-center">
                                        <span class={maxPositionValue === numberConverter(this.state.user1[item.value]) && numberConverter(this.state.user1[item.value]) !== 0 ? classes.numberTextHiglight : classes.numberText}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;                                            
                                            {(
                                                this.state.user1[item.value] ?
                                                    this.state.user1[item.value] :
                                                    0
                                            )}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" style={{ padding: 0 }}>
                                    <div class="text-center">
                                        <span class={classes.centerText}>{item.name}</span>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                    <span class={maxPositionValue === numberConverter(this.state.user2[item.value]) && numberConverter(this.state.user2[item.value]) !== 0 ? classes.numberTextHiglight : classes.numberText}>
                                        &nbsp;&nbsp;&nbsp;&nbsp; 
                                        {(  
                                            this.state.user2[item.value] ?
                                                this.state.user2[item.value] :
                                        0)}             
                                        &nbsp;&nbsp;&nbsp;&nbsp;                                                                
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                    </div>
                )
                return true;
            })
        }


        var user1_benchPress = positionAverageData(this.state.user1.bench_press,this.state.user1.bench_press_target);
        var user1_vertical_jump = positionAverageData(this.state.user1.vertical_jump,this.state.user1.vertical_jump_target);
        var user1_three_core_drill = positionAverageData1(this.state.user1.three_core_drill,this.state.user1.three_cone_target);
        var user1_broad_jump = positionAverageData(this.state.user1.broad_jump,this.state.user1.broad_jump_target);
        // var user1_sixty_yard_shuttle = positionAverageData1(this.state.user1.sixty_yard_shuttle,this.state.user1.sixty_yard_shuttle_target);
        var user1_twenty_yard_shuttle = positionAverageData1(this.state.user1.twenty_yard_shuttle,this.state.user1.twenty_yard_shuttle_target);
        var user1_fourty_yard_dash = positionAverageData1(this.state.user1.fourty_yard_dash,this.state.user1.fourty_yard_dash_target);

        var user2_benchPress = positionAverageData(this.state.user2.bench_press,this.state.user2.bench_press_target);
        var user2_vertical_jump = positionAverageData(this.state.user2.vertical_jump,this.state.user2.vertical_jump_target);
        var user2_three_core_drill = positionAverageData1(this.state.user2.three_core_drill,this.state.user2.three_cone_target);
        var user2_broad_jump = positionAverageData(this.state.user2.broad_jump,this.state.user2.broad_jump_target);
        // var user2_sixty_yard_shuttle = positionAverageData1(this.state.user2.sixty_yard_shuttle,this.state.user2.sixty_yard_shuttle_target);
        var user2_twenty_yard_shuttle = positionAverageData1(this.state.user2.twenty_yard_shuttle,this.state.user2.twenty_yard_shuttle_target);
        var user2_fourty_yard_dash = positionAverageData1(this.state.user2.fourty_yard_dash,this.state.user2.fourty_yard_dash_target);


        var overallCount = user1_benchPress + user1_vertical_jump + user1_three_core_drill + user1_broad_jump +
        //  user1_sixty_yard_shuttle +
          user1_twenty_yard_shuttle + user1_fourty_yard_dash;
        var oveallPercentage = 0;
        if (overallCount !== 'Infinity') {
            oveallPercentage = parseFloat(overallCount / 6).toFixed(2)
        }

        var overallCount1 = user2_benchPress + user2_vertical_jump + user2_three_core_drill + user2_broad_jump + 
        // user2_sixty_yard_shuttle +
         user2_twenty_yard_shuttle + user2_fourty_yard_dash;
        var oveallPercentage1 = 0;
        if (overallCount1 !== 'Infinity') {
            oveallPercentage1 = parseFloat(overallCount1 / 6).toFixed(2)
        }

        return (
            <React.Fragment>
                <CssBaseline />
                <Header />
                <Container maxWidth="xl" component="main" className={classes.heroContent}>
                    <div className="container-fluid" style={{ paddingBottom: 110 }}>
                        <div class="row rowView">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 40 }}>
                                <span
                                    className={classes.backOption}
                                    style={{ cursor: 'pointer' }}
                                    onClick={this.backToSearchPage}>
                                    <img src={BackArrow} alt="BackArrow" style={{ width: 31, height: 31 }} />
                                    Back to Results
                                </span>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <span className={classes.playerCompareText}>Player Comparison</span>
                                <Link to={Config.reactUrl + "/agent/playerperformence"} >
                                    <img style={{width:"24px",height:"16px",paddingLeft:"5px"}} src={InfoPng} alt="" />
                                </Link>
                            </div>
                            <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ padding: 0, paddingLeft: 20, paddingTop: 20 }}>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <div class="float-right align-items-center" style={{ display: "inline-flex" }}>
                                                <span class={classes.playerName}>{this.state.user1.full_name}
                                                    <br />
                                                    <span class={classes.playerDescription}>{this.state.user1.position}</span>
                                                </span>
                                                {
                                                    this.state.user1.profile_image_hash
                                                        ? <img style={{ width: 62, height: 62, borderRadius: 10 }} alt="awsfileconfig" src={(Config.dataUrl + 'aws-files/' + this.state.user1.profile_image_hash)} />
                                                        : <img style={{ width: 62, height: 62, borderRadius: 10 }} alt="emptyuserconfig" src={Config.emptyUser} />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" style={{ padding: 0 }}>
                                            <div class="text-center">
                                                <span class={classes.slash}>|</span>
                                                <br />
                                                <span class={classes.verses}>vs</span>
                                                <br />
                                                <span class={classes.slash}>|</span>
                                            </div>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <div class="align-items-center" style={{ display: "inline-flex" }}>
                                                {
                                                    this.state.user2.profile_image_hash
                                                        ? <img style={{ width: 62, height: 62, borderRadius: 10 }} alt="usersdimage" src={(Config.dataUrl + 'aws-files/' + this.state.user2.profile_image_hash)} />
                                                        : <img style={{ width: 62, height: 62, borderRadius: 10 }} alt="emptyusersdimage" src={Config.emptyUser} />
                                                }
                                                <span class={classes.playerName1}>{this.state.user2.full_name}
                                                    <br />
                                                    <span class={classes.playerDescription1}>{this.state.user2.position}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                            </div>                            
                            <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center" style={{ paddingTop: 10 }}>
                                <span class={classes.startComparitionText}>Performance Comparison</span>
                            </div>
                            <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ padding: 0, paddingLeft: 20, paddingTop: 10 }}>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <div class="float-right align-items-center" style={{ width: "100%" }}>
                                                <ProgressBar left={true} width={user1_fourty_yard_dash} />
                                                <span class={Math.min(this.state.user1.fourty_yard_dash ? this.state.user1.fourty_yard_dash : 0, this.state.user2.fourty_yard_dash ? this.state.user2.fourty_yard_dash : 0) === numberConverter(this.state.user1.fourty_yard_dash) && numberConverter(this.state.user1.fourty_yard_dash) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText } style={{ float: "right" }}>
                                                    {(
                                                        this.state.user1.fourty_yard_dash ?
                                                            this.state.user1.fourty_yard_dash :
                                                            0)}s
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" style={{ padding: 0 }}>
                                            <div class="text-center">
                                                <span class={classes.centerText}>40 YD Dash</span>
                                            </div>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <ProgressBar width={user2_fourty_yard_dash} />
                                            <span class={Math.min(this.state.user1.fourty_yard_dash ? this.state.user1.fourty_yard_dash : 0, this.state.user2.fourty_yard_dash ? this.state.user2.fourty_yard_dash : 0) === numberConverter(this.state.user2.fourty_yard_dash) && numberConverter(this.state.user2.fourty_yard_dash) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText } style={{ position: "absolute" }}>
                                                {(
                                                    this.state.user2.fourty_yard_dash ?
                                                        this.state.user2.fourty_yard_dash :
                                                        0)}s
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                            </div>
                            <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ padding: 0, paddingLeft: 20, paddingTop: 10 }}>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <div class="float-right align-items-center" style={{ width: "100%" }}>
                                                <ProgressBar left={true} width={user1_twenty_yard_shuttle} />
                                                <span class={Math.min(this.state.user1.twenty_yard_shuttle ? this.state.user1.twenty_yard_shuttle : 0, this.state.user2.twenty_yard_shuttle ? this.state.user2.twenty_yard_shuttle : 0) === numberConverter(this.state.user1.twenty_yard_shuttle) && numberConverter(this.state.user1.twenty_yard_shuttle) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ float: "right" }}>
                                                    {(
                                                        this.state.user1.twenty_yard_shuttle ?
                                                            this.state.user1.twenty_yard_shuttle :
                                                            0)}s
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" style={{ padding: 0 }}>
                                            <div class="text-center">
                                                <span class={classes.centerText}>20 YD Shuttle</span>
                                            </div>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <ProgressBar width={user2_twenty_yard_shuttle} />
                                            <span class={Math.min(this.state.user1.twenty_yard_shuttle ? this.state.user1.twenty_yard_shuttle : 0, this.state.user2.twenty_yard_shuttle ? this.state.user2.twenty_yard_shuttle : 0) === numberConverter(this.state.user2.twenty_yard_shuttle) && numberConverter(this.state.user2.twenty_yard_shuttle) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ position: "absolute" }}>
                                                {(
                                                    this.state.user2.twenty_yard_shuttle ?
                                                        this.state.user2.twenty_yard_shuttle :
                                                        0)}s
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                            </div>
                            {/* <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ padding: 0, paddingLeft: 20, paddingTop: 10 }}>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <div class="float-right align-items-center" style={{ width: "100%" }}>
                                                <ProgressBar left={true} width={user1_sixty_yard_shuttle} />
                                                <span class={Math.min(this.state.user1.sixty_yard_shuttle ? this.state.user1.sixty_yard_shuttle : 0, this.state.user2.sixty_yard_shuttle ? this.state.user2.sixty_yard_shuttle : 0) === numberConverter(this.state.user1.sixty_yard_shuttle) && numberConverter(this.state.user1.sixty_yard_shuttle) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ float: "right" }}>
                                                    {(
                                                        this.state.user1.sixty_yard_shuttle ?
                                                            this.state.user1.sixty_yard_shuttle :
                                                            0)}s
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" style={{ padding: 0 }}>
                                            <div class="text-center">
                                                <span class={classes.centerText}>60 YD Shuttle</span>
                                            </div>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <ProgressBar width={user2_sixty_yard_shuttle} />
                                            <span class={Math.min(this.state.user1.sixty_yard_shuttle ? this.state.user1.sixty_yard_shuttle : 0, this.state.user2.sixty_yard_shuttle ? this.state.user2.sixty_yard_shuttle : 0) === numberConverter(this.state.user2.sixty_yard_shuttle) && numberConverter(this.state.user2.sixty_yard_shuttle) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ position: "absolute" }}>
                                                {(
                                                    this.state.user2.sixty_yard_shuttle ?
                                                        this.state.user2.sixty_yard_shuttle :
                                                        0)}s
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                            </div> */}
                            <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ padding: 0, paddingLeft: 20, paddingTop: 10 }}>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <div class="float-right align-items-center" style={{ width: "100%" }}>
                                                <ProgressBar left={true} width={user1_broad_jump} />
                                                <span class={Math.max(this.state.user1.broad_jump ? this.state.user1.broad_jump : 0, this.state.user2.broad_jump ? this.state.user2.broad_jump : 0) === numberConverter(this.state.user1.broad_jump) && numberConverter(this.state.user1.broad_jump) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ float: "right" }}>                                                    
                                                    {(
                                                        this.state.user1.broad_jump
                                                            ? this.setBroadJumpVal(this.state.user1.broad_jump)
                                                            : 0
                                                    )}
                                                    {/* 10' 11" */}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" style={{ padding: 0 }}>
                                            <div class="text-center">
                                                <span class={classes.centerText}>Broad Jump</span>
                                            </div>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <ProgressBar width={user2_broad_jump} />
                                            <span class={Math.max(this.state.user1.broad_jump ? this.state.user1.broad_jump : 0, this.state.user2.broad_jump ? this.state.user2.broad_jump : 0) === numberConverter(this.state.user2.broad_jump) && numberConverter(this.state.user2.broad_jump) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ position: "absolute" }}>
                                                {(
                                                    this.state.user2.broad_jump ?
                                                        this.setBroadJumpVal(this.state.user2.broad_jump) :
                                                        0
                                                )}                                                
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                            </div>
                            <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ padding: 0, paddingLeft: 20, paddingTop: 10 }}>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <div class="float-right align-items-center" style={{ width: "100%" }}>
                                                <ProgressBar left={true} width={user1_three_core_drill} />
                                                <span class={Math.min(this.state.user1.three_core_drill ? this.state.user1.three_core_drill : 0, this.state.user2.three_core_drill ? this.state.user2.three_core_drill : 0) === numberConverter(this.state.user1.three_core_drill) && numberConverter(this.state.user1.three_core_drill) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ float: "right" }}>
                                                    {(
                                                        this.state.user1.three_core_drill ?
                                                            this.state.user1.three_core_drill :
                                                            0)}s
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" style={{ padding: 0 }}>
                                            <div class="text-center">
                                                <span class={classes.centerText}>3 Cone Drill</span>
                                            </div>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <ProgressBar width={user2_three_core_drill} />
                                            <span class={Math.min(this.state.user1.three_core_drill ? this.state.user1.three_core_drill : 0, this.state.user2.three_core_drill ? this.state.user2.three_core_drill : 0) === numberConverter(this.state.user2.three_core_drill) && numberConverter(this.state.user2.three_core_drill) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ position: "absolute" }}>
                                                {(
                                                    this.state.user2.three_core_drill ?
                                                        this.state.user2.three_core_drill :
                                                        0)}s
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                            </div>
                            <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ padding: 0, paddingLeft: 20, paddingTop: 10 }}>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <div class="float-right align-items-center" style={{ width: "100%" }}>
                                                <ProgressBar left={true} width={user1_vertical_jump} />
                                                <span class={Math.max(this.state.user1.vertical_jump ? this.state.user1.vertical_jump : 0, this.state.user2.vertical_jump ? this.state.user2.vertical_jump : 0) === numberConverter(this.state.user1.vertical_jump) && numberConverter(this.state.user1.vertical_jump) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ float: "right" }}>
                                                    {(
                                                        this.state.user1.vertical_jump ?
                                                            `${this.state.user1.vertical_jump}"` :
                                                            `${0}"`)}
                                                    {/* 23.3" */}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" style={{ padding: 0 }}>
                                            <div class="text-center">
                                                <span class={classes.centerText}>Vertical Jump</span>
                                            </div>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <ProgressBar width={user2_vertical_jump} />
                                            <span class={Math.max(this.state.user1.vertical_jump ? this.state.user1.vertical_jump : 0, this.state.user2.vertical_jump ? this.state.user2.vertical_jump : 0) === numberConverter(this.state.user2.vertical_jump) && numberConverter(this.state.user2.vertical_jump) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ position: "absolute" }}>
                                                {(
                                                    this.state.user2.vertical_jump ?
                                                        `${this.state.user2.vertical_jump}"` :
                                                        `${0}"`)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                            </div>
                            <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ padding: 0, paddingLeft: 20, paddingTop: 10 }}>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <div class="float-right align-items-center" style={{ width: "100%" }}>
                                                <ProgressBar left={true} width={user1_benchPress} />
                                                <span class={Math.max(this.state.user1.bench_press ? this.state.user1.bench_press : 0, this.state.user2.bench_press ? this.state.user2.bench_press : 0) === numberConverter(this.state.user1.bench_press) && numberConverter(this.state.user1.bench_press) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ float: "right" }}>
                                                    {(
                                                        this.state.user1.bench_press ?
                                                            this.state.user1.bench_press :
                                                            0)} Reps
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" style={{ padding: 0 }}>
                                            <div class="text-center">
                                                <span class={classes.centerText}>Bench Press</span>
                                            </div>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <ProgressBar
                                                width={user2_benchPress}
                                            />
                                            <span class={Math.max(this.state.user1.bench_press ? this.state.user1.bench_press : 0, this.state.user2.bench_press ? this.state.user2.bench_press : 0) === numberConverter(this.state.user2.bench_press) && numberConverter(this.state.user2.bench_press) !== 0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ position: "absolute" }}>
                                                {(
                                                    this.state.user2.bench_press ?
                                                        this.state.user2.bench_press :
                                                        0)} Reps
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                            </div>
                            <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center">
                                <span class={classes.startComparitionText}>Stats Comparison</span>
                            </div>                            
                            {playerPositionData}
                            <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center" style={{ paddingTop: 10 }}>
                                <span class={classes.startComparitionText}>Overall Comparison</span>
                            </div>
                            <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ padding: 0, paddingLeft: 20, paddingTop: 10 }}>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <div class="float-right align-items-center" style={{ width: "100%" }}>
                                                <ProgressBar left={true} width={(
                                                    oveallPercentage ?
                                                        `${oveallPercentage}` :
                                                        '0')} />
                                                <span class={Math.max(oveallPercentage ? oveallPercentage : 0, oveallPercentage1 ? oveallPercentage1 : 0) === numberConverter(oveallPercentage) && oveallPercentage !==0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ float: "right" }}>
                                                    {(
                                                        oveallPercentage ?
                                                            oveallPercentage :
                                                            0)}%
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" style={{ padding: 0 }}>
                                            <div class="text-center">
                                                <span class={classes.centerText}>Total Comparison</span>
                                            </div>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5" style={{ padding: 0 }}>
                                            <ProgressBar width={(
                                                oveallPercentage1 ?
                                                    `${oveallPercentage1}` :
                                                    '0'
                                            )} />
                                            <span class={Math.max(oveallPercentage ? oveallPercentage : 0, oveallPercentage1 ? oveallPercentage1 : 0) === numberConverter(oveallPercentage1) && oveallPercentage1 !==0 ? classes.smallNumberTextHiglight : classes.smallNumberText} style={{ position: "absolute" }}>
                                                {(
                                                    oveallPercentage1 ?
                                                        oveallPercentage1 :
                                                        0
                                                )}%
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"></div>
                            </div>
                            <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center" style={{ paddingTop: 10 }}>
                                <Button buttonStyle={classes.buttonStyle} buttonName={"Back to search"}
                                    fullWidth={false} onClick={this.backToSearchPage} />
                            </div>
                        </div>
                    </div>
                </Container>
                <Footer />
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(AgentComparePlayer));