import LoginBg from "../images/login-bg.png";
const styles = theme => ({    
    root : {
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "hidden",
        minWidth: "100%",
        minHeight: "100%",
        backgroundImage: "url(" + LoginBg + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    leftCard: {
        width: "100%",
        height: "100vh",
        // background: "#0B0C10 0% 0% no-repeat padding-box",
        background: "rgba(0,0,0,0.40)",
        // opacity: 0.52,
    },
    avatar: {
        width: 175,
        height: 81,
        opacity: 1,  
    },
    leftText: {
        font: "normal normal normal 15px/25px Poppins",
        color: "#FFFFFF",
        opacity: 0.6,
    },
    paper: {
        // margin: theme.spacing(8, 4),
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    copyRightText: {
        font: "normal normal normal 12px/18px Poppins",
        color: "#FFFFFF",
        opacity: 1,
        letterSpacing: 0.5,
    },
    createAccountText: {
        paddingTop: 20,
        font: "normal normal normal 20px/30px Poppins",
        color: "#FFFFFF",
        opacity: 1,
    },
    createAccountDesc: {
        paddingTop: 10,
        font: "normal normal normal 13px/20px Poppins",
        color: "#FFFFFF",
        opacity: 0.5,
    },
    label: {
        font: "normal normal normal 14px/21px Poppins",
        color: "#FFFFFF",
        opacity: 1,
    },
    blueLabel: {
        font: "normal normal normal 14px/21px Poppins",
        color: "#1891BD",
        opacity: 1,
    },
    mediumLabel: {
        font: "normal normal normal 12px/18px Poppins",
        color: "#959595",
    },
    smallLabel: {
        font: "italic normal 300 10px/16px Poppins",
        color: "#959595",
    },
    buttonStyle:{
        background: '#1891BD',
        borderRadius: 5,
        color: 'white',
        height: 40,
        padding: '0 15px',
        opacity: 1,
        fontSize: 14,
        textTransform: "uppercase",
        "&:hover": {
          border: "1px solid #1891BD",
        },
      },
      buttonStyle1: {
        border: "1px solid #1891BD",
        borderRadius: 5,
        color: 'white',
        height: 40,
        padding: '0 15px',
        opacity: 1,
        fontSize: 14,
        textTransform: "uppercase",
        outline: "none",
        "&:hover": {
          background: "#1891BD"
        },
    },
    buttonStyle2: {
        border: "1px solid #1891BD",
        borderRadius: 5,
        color: 'white',
        height: 40,
        padding: '0 80px',
        opacity: 1,
        fontSize: 14,
        textTransform: "uppercase",
        outline: "none",
        "&:hover": {
          background: "#1891BD"
        },
    },
});
export default styles;