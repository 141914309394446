import React, { Component } from 'react'
import { withStore } from '../../component/hooks/store';
import { withStyles } from '@material-ui/core/styles';
import Styles from "../../styles/admin/dashboard";
import Footer from "../manage/footer";
import Header from "./header";
import { Container } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import Notification from "../../images/notification.png";
import Notification1 from "../../images/notification1.png";
import HomeUp from "../../images/home-up-arrow.png";
import Button from "../../component/button";
import CancelPng from "../../images/cancel.png";
import { checkNullAndReturnString, doGetConnectSecurity, formatAMPM, doPutConnectSecurity, doDeleteConnect } from '../../helper/common';
import {
    Menu,
    MenuItem,
    // MenuButton,
    // SubMenu
} from '@szhsin/react-menu';
import { Modal } from 'react-responsive-modal';
import SelectBox from "../../component/selectBoxDefaut";
import DatePicker from 'react-date-picker';
import CalenderPng from "../../images/calender.png";

import DashBoardSeeDetailComponent from "../../component/dashboardSeeDetail";
import '../../cssstyle/admin/dashboard.css';
import Tab from "../../component/tab";
import Tables from "../../component/table";
const moment = require("moment");

class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetails: [],
            chartType: "month",
            chartUserData: [],
            chartData: [],
            chartUser: "Player",
            notifications: [],
            roleId: "",
            togglefilter: false,
            filtervalue: 'current_month',
            startdate: null,
            enddate: null,
            height: 0,
            width: 0,
            serviceOfferedList: [],
            seeDetailData: {
                id: 0,
                registeredList: [],
                approvedList: [],
                rejectedList: [],
                pendingList: [],
                show: false
            },
            modal: false,
            selected: 0,

        };
    }

    resizeHandler() {
        if (this.divElement) {
            const width = this.divElement.clientWidth;
            const height = this.divElement.clientHeight;
            this.setState({ width, height });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeHandler);
    }

    componentDidMount = async () => {
        this.getAdminProfile();
        this.getServiceOffered();
        let datestart = moment().startOf('month').toDate();
        let dateend = moment().endOf('month').toDate();

        this.setState({
            startdate: datestart,
            enddate: dateend
        }, () => this.getChartData(this.state.chartType, this.state.chartUser))

        this.resizeHandler();
        window.addEventListener('resize', this.resizeHandler);
    }


    getServiceOffered = async () => {
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doGetConnectSecurity("master-data/agent/services", accessToken);
        if (checkNullAndReturnString(responseData)) {
            this.setState({
                serviceOfferedList: responseData
            })
        }
    }

    clearNotification = async () => {
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doDeleteConnect("notifications/clear?role_id=1", accessToken)
        if (responseData) {
            this.child.getMessageCount()
            this.getAdminProfile()
        }
    }

    refillJsonData = (roleName, key) => {
        var { userDetails=[] } = this.state;
        if (checkNullAndReturnString(userDetails) && Object.keys(userDetails).length > 0) {
            var filterJson = userDetails.filter((item) => item.role_name === roleName)
            if (checkNullAndReturnString(filterJson) && Object.keys(filterJson).length > 0) {
                return filterJson[0][key];
            }
        }
        return 0;
    }

    refillJsonChartData = (roleName, key) => {
        var { chartData } = this.state;
        if (checkNullAndReturnString(chartData) && Object.keys(chartData).length > 0) {
            var filterJson = chartData.filter((item) => item.Name === roleName)
            if (checkNullAndReturnString(filterJson) && Object.keys(filterJson).length > 0) {
                return filterJson[0][key];
            }
        }
        return 0;
    }

    getAdminProfile = async () => {
        this.setState({ notifications: [] })
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doGetConnectSecurity("dashboard", accessToken);
        if (checkNullAndReturnString(responseData)) {
            this.setState({ userDetails: responseData })
        }
        var responseData1 = await doGetConnectSecurity("dashboard/notifications", accessToken);
        if (checkNullAndReturnString(responseData1)) {
            if (checkNullAndReturnString(responseData1.data)) {
                this.setState({ notifications: responseData1.data })
            }
        }
    }

    getChartData = async (chartType = this.state.chartType, chartUser = this.state.chartUser) => {
        const { startdate, enddate, filtervalue } = this.state;
        const formatstartdate = moment(startdate).format("MM-DD-YYYY");
        const formatenddate = moment(enddate).format("MM-DD-YYYY");

        var accessToken = localStorage.getItem("accessToken");
        var roleId = 2;
        if (chartUser === "Player") {
            roleId = 4;
        }
        if (chartUser === "Agent") {
            roleId = 3;
        } else if (chartUser === "University Administrator") {
            roleId = 2;
        }

        this.setState({ chartData: [] })
        var responseData = await doGetConnectSecurity("dashboard/playersFilterCounts?role_id=" + roleId + "&filter_status=" + chartType + "&from_date=" + formatstartdate + "&to_date=" + formatenddate + "&key=" + filtervalue, accessToken);
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data)) {
                const userDetails = responseData.data.reduce(
                    (acc, val) => {
                        let acc1 = [...acc];
                        acc1 = [...acc1, { id: val.id, role_name: val.role_name, ...val.overalldata }]
                        return acc1;
                    }, []);


                this.setState({
                    chartUserData: responseData.data,
                    userDetails: userDetails,
                    chartData: [...userSelectedChartData()]
                });



                function userSelectedChartData() {
                    const setdata = [...responseData.data];
                    if (chartUser === "University Administrator" || chartUser === "Player" || chartUser === "Agent") {
                        const senddata = setdata.find(e => e.role_name === chartUser).data;
                        return senddata
                    }
                }
            }
        }
    }

    setdatevalue = () => {
        const { filtervalue } = this.state;

        if (filtervalue === "current_week" || filtervalue === "previous_week") {
            let datestart = moment().startOf('week').toDate();
            let dateend = moment().endOf('week').toDate();

            if (filtervalue === "previous_week") {
                const datesample = datestart;
                datestart = moment(datesample).subtract(1, 'week').toDate();
                dateend = moment(datesample).subtract(1, 'day').toDate();
            }

            this.setState({
                startdate: datestart,
                enddate: dateend
            }
                // , () => this.getChartData()
            )
        }



        if (filtervalue === "current_month" || filtervalue === "previous_month") {
            let datestart = moment().startOf('month').toDate();
            let dateend = moment().endOf('month').toDate();

            if (filtervalue === "previous_month") {
                const datesample = datestart;
                datestart = moment(datesample).subtract(1, 'month').startOf('month').toDate();
                dateend = moment(datesample).subtract(1, 'day').endOf('month').toDate();
            }

            this.setState({
                startdate: datestart,
                enddate: dateend
            }
                // , () => this.getChartData()
            )
        }


        if (filtervalue === "previous_quarter" || filtervalue === "current_quarter") {
            let datestart = moment().subtract(1, 'month').startOf('month').toDate();
            let dateend = moment().add(1, 'month').endOf('month').toDate();

            if (filtervalue === "previous_quarter") {
                const datesample = datestart;
                datestart = moment(datesample).subtract(3, 'month').startOf('month').toDate();
                dateend = moment(datesample).subtract(1, 'day').endOf('month').toDate();
            }

            this.setState({
                startdate: datestart,
                enddate: dateend
            }
                // , () => this.getChartData()
            )
        }

        if (filtervalue === "current_year" || filtervalue === "previous_year") {
            let datestart = moment().startOf('year').toDate();
            let dateend = moment().endOf('year').toDate();

            if (filtervalue === "previous_year") {
                const samplesdata = datestart;
                datestart = moment(samplesdata).subtract(1, 'month').startOf('year').toDate();
                dateend = moment(samplesdata).subtract(1, 'month').endOf('year').toDate();
            }
            this.setState({
                startdate: datestart,
                enddate: dateend
            }
                // , () => this.getChartData()
            )
        }

        if (filtervalue === "custom") {
            let datestart = moment().startOf('month').toDate();
            let dateend = moment().toDate();

            this.setState({
                startdate: datestart,
                enddate: dateend
            }
                // , () => this.getChartData()
            )
        }
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        }, this.setdatevalue)
    }

    onChangeDatePicker = (keyname, value) => {
        this.setState({
            [keyname]: value
        }
            // , this.getChartData
        )
    }

    changeToggleFilter = () => {
        this.setState(pre => ({ ...pre, togglefilter: !pre.togglefilter }));
    }

    onClickCountRemove = async (id) => {
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doPutConnectSecurity("notifications/flag/" + id, {}, accessToken);
        if (checkNullAndReturnString(responseData)) {
            this.child.getMessageCount()
            this.getAdminProfile()
        }
    }

    getUserDetails = async (id) => {
        this.setState({ roleId: id })
        this.onOpenModal()
    }

    onOpenModal = () => {
        this.setState({ modal: true })
    };

    onCloseModal = () => {
        this.setState({ modal: false })
    };

    tabOnClick = (index) => {
        this.setState({ selected: index })
    }

    seeDetails = async (id) => {
        let registeredData = [], approvedData = [],
            pendingData = [],
            rejectedData = [];
        this.seeDetailToggler(id, registeredData, approvedData, pendingData, rejectedData);


        // const { startdate, enddate, filtervalue } = this.state;
        // const formatstartdate = moment(startdate).format("MM-DD-YYYY");
        // const formatenddate = moment(enddate).format("MM-DD-YYYY");
        // var accessToken = localStorage.getItem("accessToken");
        // var responseData = await doGetConnectSecurity(`dashboard/seeDetails?fromdate=${formatstartdate}&enddate=${formatenddate}&filterdata=${filtervalue}&roleid=${id}`, accessToken);
        // if (checkNullAndReturnString(responseData)) {
        //     if (checkNullAndReturnString(responseData.data)) {
        //         console.log("responseData");
        //         console.log(responseData.data);
        //         console.log("responseData");
        //         const { data: { registeredData, approvedData, pendingData, rejectedData } } = responseData;
        //         this.seeDetailToggler(id, registeredData, approvedData, pendingData, rejectedData);
        //     }
        // }
    }

    seeDetailToggler = (id = 0, registeredList = [], approvedList = [], rejectedList = [], pendingList = []) => {
        this.setState(pre => ({
            ...pre,
            seeDetailData: {
                ...pre.seeDetailData,
                id, registeredList, approvedList, rejectedList, pendingList,
                show: !pre.seeDetailData.show
            }
        }))
    }


    render() {
        var border = <div className={"row"} style={{ paddingRight: 15, paddingLeft: 15, paddingTop: 10, paddingBottom: 10 }}>
            <div style={{ paddingLeft: 15, paddingRight: 15, border: "1px solid #3B3E50", width: "100%" }}></div>
        </div>;
        const { classes } = this.props;
        const { filtervalue, startdate, enddate, chartData, seeDetailData,
            serviceOfferedList, userDetails } = this.state;

        const getLabelData = (val) => {
            const data = [];
            for (let i = 0; i < chartData.length; i++) {
                if (chartData[i][val]) {
                    data.push(chartData[i][val])
                }
            }
            // console.group(val);
            // console.log('data');
            // console.log(data);
            // console.log('chartData');
            // console.log(chartData);
            // console.groupEnd();
            return data
        }
        var data = {
            // labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: [...getLabelData('Name')],
            datasets: [
                {
                    label: 'Registered',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: '#1891BD',
                    borderColor: '#1891BD',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#1891BD',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#1891BD',
                    pointHoverBorderColor: '#1891BD',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: [
                        ...getLabelData('registered_count')
                        // (parseInt(this.refillJsonChartData("January", "approved_count")) + parseInt(this.refillJsonChartData("January", "pending_count")) + parseInt(this.refillJsonChartData("January", "rejected_count"))),
                        // (parseInt(this.refillJsonChartData("February", "approved_count")) + parseInt(this.refillJsonChartData("February", "pending_count")) + parseInt(this.refillJsonChartData("February", "rejected_count"))),
                        // (parseInt(this.refillJsonChartData("March", "approved_count")) + parseInt(this.refillJsonChartData("March", "pending_count")) + parseInt(this.refillJsonChartData("March", "rejected_count"))),
                        // (parseInt(this.refillJsonChartData("April", "approved_count")) + parseInt(this.refillJsonChartData("April", "pending_count")) + parseInt(this.refillJsonChartData("April", "rejected_count"))),
                        // (parseInt(this.refillJsonChartData("May", "approved_count")) + parseInt(this.refillJsonChartData("May", "pending_count")) + parseInt(this.refillJsonChartData("May", "rejected_count"))),
                        // (parseInt(this.refillJsonChartData("June", "approved_count")) + parseInt(this.refillJsonChartData("June", "pending_count")) + parseInt(this.refillJsonChartData("June", "rejected_count"))),
                        // (parseInt(this.refillJsonChartData("July", "approved_count")) + parseInt(this.refillJsonChartData("July", "pending_count")) + parseInt(this.refillJsonChartData("July", "rejected_count"))),
                        // (parseInt(this.refillJsonChartData("August", "approved_count")) + parseInt(this.refillJsonChartData("August", "pending_count")) + parseInt(this.refillJsonChartData("August", "rejected_count"))),
                        // (parseInt(this.refillJsonChartData("September", "approved_count")) + parseInt(this.refillJsonChartData("September", "pending_count")) + parseInt(this.refillJsonChartData("September", "rejected_count"))),
                        // (parseInt(this.refillJsonChartData("October", "approved_count")) + parseInt(this.refillJsonChartData("October", "pending_count")) + parseInt(this.refillJsonChartData("October", "rejected_count"))),
                        // (parseInt(this.refillJsonChartData("November", "approved_count")) + parseInt(this.refillJsonChartData("November", "pending_count")) + parseInt(this.refillJsonChartData("November", "rejected_count"))),
                        // (parseInt(this.refillJsonChartData("December", "approved_count")) + parseInt(this.refillJsonChartData("December", "pending_count")) + parseInt(this.refillJsonChartData("December", "rejected_count")))
                    ]
                },
                {
                    label: 'Approved',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: '#3CCF50',
                    borderColor: '#3CCF50',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#3CCF50',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#3CCF50',
                    pointHoverBorderColor: '#3CCF50',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: [
                        ...getLabelData('approved_count')
                        // parseInt(this.refillJsonChartData("January", "approved_count")),
                        // parseInt(this.refillJsonChartData("February", "approved_count")),
                        // parseInt(this.refillJsonChartData("March", "approved_count")),
                        // parseInt(this.refillJsonChartData("April", "approved_count")),
                        // parseInt(this.refillJsonChartData("May", "approved_count")),
                        // parseInt(this.refillJsonChartData("June", "approved_count")),
                        // parseInt(this.refillJsonChartData("July", "approved_count")),
                        // parseInt(this.refillJsonChartData("August", "approved_count")),
                        // parseInt(this.refillJsonChartData("September", "approved_count")),
                        // parseInt(this.refillJsonChartData("October", "approved_count")),
                        // parseInt(this.refillJsonChartData("November", "approved_count")),
                        // parseInt(this.refillJsonChartData("December", "approved_count"))
                    ]
                },
                {
                    label: 'Pending',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: '#9F8813',
                    borderColor: '#9F8813',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#9F8813',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#9F8813',
                    pointHoverBorderColor: '#9F8813',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: [
                        ...getLabelData('pending_count')
                        // parseInt(this.refillJsonChartData("January", "pending_count")),
                        // parseInt(this.refillJsonChartData("February", "pending_count")),
                        // parseInt(this.refillJsonChartData("March", "pending_count")),
                        // parseInt(this.refillJsonChartData("April", "pending_count")),
                        // parseInt(this.refillJsonChartData("May", "pending_count")),
                        // parseInt(this.refillJsonChartData("June", "pending_count")),
                        // parseInt(this.refillJsonChartData("July", "pending_count")),
                        // parseInt(this.refillJsonChartData("August", "pending_count")),
                        // parseInt(this.refillJsonChartData("September", "pending_count")),
                        // parseInt(this.refillJsonChartData("October", "pending_count")),
                        // parseInt(this.refillJsonChartData("November", "pending_count")),
                        // parseInt(this.refillJsonChartData("December", "pending_count"))
                    ]
                },
                {
                    label: 'Rejected',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: '#F61616',
                    borderColor: '#F61616',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#F61616',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#F61616',
                    pointHoverBorderColor: '#F61616',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: [
                        ...getLabelData('rejected_count')
                        // parseInt(this.refillJsonChartData("January", "rejected_count")),
                        // parseInt(this.refillJsonChartData("February", "rejected_count")),
                        // parseInt(this.refillJsonChartData("March", "rejected_count")),
                        // parseInt(this.refillJsonChartData("April", "rejected_count")),
                        // parseInt(this.refillJsonChartData("May", "rejected_count")),
                        // parseInt(this.refillJsonChartData("June", "rejected_count")),
                        // parseInt(this.refillJsonChartData("July", "rejected_count")),
                        // parseInt(this.refillJsonChartData("August", "rejected_count")),
                        // parseInt(this.refillJsonChartData("September", "rejected_count")),
                        // parseInt(this.refillJsonChartData("October", "rejected_count")),
                        // parseInt(this.refillJsonChartData("November", "rejected_count")),
                        // parseInt(this.refillJsonChartData("December", "rejected_count"))
                    ]
                }
            ]
        };
        var notifications = this.state.notifications;
        var notificationData = [];

        const filteroptions = [
            { label: 'Current Week', value: 'current_week' },
            { label: 'Previous Week', value: 'previous_week' },
            { label: 'Current Month', value: 'current_month' },
            { label: 'Previous Month', value: 'previous_month' },
            { label: 'Current Quarter', value: 'current_quarter' },
            { label: 'Previous Quarter', value: 'previous_quarter' },
            { label: 'Current Year', value: 'current_year' },
            { label: 'Previous Year', value: 'previous_year' },
            { label: 'Custom', value: 'custom' },
        ]
        if (checkNullAndReturnString(notifications)) {
            Object.keys(notifications).forEach(key => {
                var item = notifications[key];
                notificationData.push(
                    <div>
                        <a href="#/" onClick={(e) => { e.preventDefault(); this.onClickCountRemove(item.id) }} className={"row"} style={{ alignItems: "center", padding: 10, paddingTop: 0, paddingBottom: 0 }}>
                            <div className={"col-xl-2 col-lg-2 col-md-3 col-sm-1 col-2"} style={{ textAlign: "center" }}>
                                <a href="#/" className={classes.circle} style={{ textAlign: "center" }} onClick={e => e.preventDefault()} >
                                    {
                                        item.flag === "unread" ?
                                            <img src={Notification1} alt="Notification1" className={classes.notificationImage} />
                                            :
                                            <img src={Notification} alt="Notification" className={classes.notificationImage} />
                                    }
                                </a>
                            </div>
                            <div className={"col-xl-10 col-lg-10 col-md-9 col-sm-11 col-10"}>
                                <span className={classes.notificationText} >
                                    {item.notification}
                                </span>
                                <div className={"row"} >
                                    <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}>
                                        <span className={classes.statistics} style={{ paddingLeft: 0 }}>
                                            {formatAMPM(item.created_on)}
                                        </span>
                                    </div>
                                    {/* <div className={"col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8"}>
                                        <span className={classes.statistics} style={{ float: "right", paddingRight: 5 }}>
                                            Show Notification
                                        </span>
                                    </div> */}
                                </div>
                            </div>
                        </a>
                        {border}
                    </div>
                )
            })
        }
        return (
            <React.Fragment>
                <Header from={"Dashboard"} changeToggleFilter={this.changeToggleFilter} onRef={ref => (this.child = ref)} />
                <Container maxWidth="xl" component="main" className={classes.heroContent}>
                    <div className="container-fluid" style={{ paddingBottom: 100, paddingTop: 50 }}>
                        <div className={"row rowView"} >
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 " style={{ paddingTop: 20 }}>
                                <div style={{ width: "100%", border: "1px solid #3B3E50", padding: 15, borderRadius: 10, boxShadow: "0px 3px 6px #0000004D" }}>
                                    <div className={"row"} style={{ alignItems: "center" }}>
                                        <div class="col-7" >
                                            <span className={classes.statistics}>
                                                Player Statistics
                                            </span>
                                        </div>
                                        <div class="col-5" >
                                            {/* <span className={classes.seeDetails}
                                                onClick={() => this.seeDetails(4)}>
                                                See Details
                                            </span> */}
                                            <a href="#/" onClick={(e) => { e.preventDefault(); this.seeDetails("4") }}>
                                                <span className={classes.seeDetails}>
                                                    See Details
                                                </span>
                                            </a>

                                        </div>
                                    </div>
                                    <div className={"row"} >
                                        <div class="col-4" >
                                            <p className={classes.count}>
                                                {
                                                    parseInt(this.refillJsonData("Player", "approved_total")) + parseInt(this.refillJsonData("Player", "pending_total")) + parseInt(this.refillJsonData("Player", "rejected_total"))
                                                }
                                            </p>
                                            <p className={classes.countText}>
                                                Registered
                                            </p>
                                        </div>
                                        <div class="col-4" >
                                            <p className={classes.count}>
                                                {this.refillJsonData("Player", "approved_total")}
                                            </p>
                                            <p className={classes.countText}>
                                                Appproved
                                            </p>
                                        </div>
                                        <div class="col-4" >
                                            <p className={classes.count}>
                                                {this.refillJsonData("Player", "pending_total")}
                                            </p>
                                            <p className={classes.countText}>
                                                Request Pending
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 " style={{ paddingTop: 20 }}>
                                <div style={{ width: "100%", border: "1px solid #3B3E50", padding: 15, borderRadius: 10, boxShadow: "0px 3px 6px #0000004D" }}>
                                    <div className={"row"} style={{ alignItems: "center" }}>
                                        <div class="col-7" >
                                            <span className={classes.statistics}>
                                                Agent Statistics
                                            </span>
                                        </div>
                                        <div class="col-5" >
                                            {/* <span className={classes.seeDetails}
                                                onClick={() => this.seeDetails(3)}>
                                                See Details
                                            </span> */}
                                            <a href="#/" onClick={(e) => { e.preventDefault(); this.seeDetails("3") }}>
                                                <span className={classes.seeDetails}>
                                                    See Details
                                                </span>
                                            </a>

                                        </div>
                                    </div>
                                    <div className={"row"} >
                                        <div class="col-4" >
                                            <p className={classes.count}>
                                                {
                                                    parseInt(this.refillJsonData("Agent", "approved_total")) + parseInt(this.refillJsonData("Agent", "pending_total")) + parseInt(this.refillJsonData("Agent", "rejected_total"))
                                                }
                                            </p>
                                            <p className={classes.countText}>
                                                Registered
                                            </p>
                                        </div>
                                        <div class="col-4" >
                                            <p className={classes.count}>
                                                {this.refillJsonData("Agent", "approved_total")}
                                            </p>
                                            <p className={classes.countText}>
                                                Appproved
                                            </p>
                                        </div>
                                        <div class="col-4" >
                                            <p className={classes.count}>
                                                {this.refillJsonData("Agent", "pending_total")}
                                            </p>
                                            <p className={classes.countText}>
                                                Request Pending
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 " style={{ paddingTop: 20 }}>
                                <div style={{ width: "100%", border: "1px solid #3B3E50", padding: 15, borderRadius: 10, boxShadow: "0px 3px 6px #0000004D" }}>
                                    <div className={"row"} style={{ alignItems: "center" }}>
                                        <div class="col-7" >
                                            <span className={classes.statistics}>
                                                University Admin Statistics
                                            </span>
                                        </div>
                                        <div class="col-5" >
                                            {/* <span className={classes.seeDetails}
                                                onClick={() => this.seeDetails(2)}>
                                                See Details
                                            </span> */}
                                            <a href="#/" onClick={(e) => { e.preventDefault(); this.seeDetails("2") }}>
                                                <span className={classes.seeDetails}>
                                                    See Details
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className={"row"} >
                                        <div class="col-4" >
                                            <p className={classes.count}>
                                                {
                                                    parseInt(this.refillJsonData("University Administrator", "approved_total")) + parseInt(this.refillJsonData("University Administrator", "pending_total")) + parseInt(this.refillJsonData("University Administrator", "rejected_total"))
                                                }
                                            </p>
                                            <p className={classes.countText}>
                                                Registered
                                            </p>
                                        </div>
                                        <div class="col-4" >
                                            <p className={classes.count}>
                                                {this.refillJsonData("University Administrator", "approved_total")}
                                            </p>
                                            <p className={classes.countText}>
                                                Approved
                                            </p>
                                        </div>
                                        <div class="col-4" >
                                            <p className={classes.count}>
                                                {this.refillJsonData("University Administrator", "pending_total")}
                                            </p>
                                            <p className={classes.countText}>
                                                Request Pending
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row rowView"} >
                            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 " style={{ paddingTop: 50 }}>
                                <div ref={(divElement) => { this.divElement = divElement }} style={{ width: "100%", border: "1px solid #3B3E50", padding: 15, borderRadius: 10, boxShadow: "0px 3px 6px #0000004D" }}>
                                    <div className={"row"} style={{ alignItems: "center", paddingBottom: 15 }}>
                                        <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}>
                                            <span className={classes.statistics}>
                                                Show &nbsp;&nbsp;
                                                <Menu styles={{
                                                    background: "#3B3E50 0% 0% no-repeat padding-box",
                                                    padding: 10,
                                                    borderRadius: 10
                                                }} menuButton={
                                                    <a href="#/" style={{ color: "#1891BD", background: "#3B3E50 0% 0% no-repeat padding-box", padding: 5, borderRadius: 15 }} onClick={e => e.preventDefault()}>
                                                        &nbsp;&nbsp; {this.state.chartUser} &nbsp;&nbsp;
                                                        <img src={HomeUp} alt="HomeUp" style={{ width: 8, height: 8 }} />
                                                        &nbsp;&nbsp;
                                                    </a>
                                                }>
                                                    <MenuItem onClick={() => {
                                                        this.getChartData(this.state.chartType, "Player")
                                                        this.setState({ chartUser: "Player" })
                                                    }}>
                                                        <span className={classes.countText} style={{ padding: 5 }}>
                                                            <span className={"headerMenuHover"}>
                                                                Player
                                                            </span>
                                                        </span>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => {
                                                        this.getChartData(this.state.chartType, "Agent")
                                                        this.setState({ chartUser: "Agent" })
                                                    }}>
                                                        <span className={classes.countText} style={{ padding: 5 }}>
                                                            <span className={"headerMenuHover"}>
                                                                Agent
                                                            </span>
                                                        </span>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => {
                                                        this.getChartData(this.state.chartType, "University Administrator")
                                                        this.setState({ chartUser: "University Administrator" })
                                                    }}>
                                                        <span className={classes.countText} style={{ padding: 5 }}>
                                                            <span className={"headerMenuHover"}>
                                                                University Administrator
                                                            </span>
                                                        </span>
                                                    </MenuItem>
                                                </Menu>
                                                &nbsp;&nbsp;data
                                            </span>
                                        </div>
                                        {/* <div className={"col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5"}>
                                            <span className={classes.statistics} style={{ float: "right", color: "#1891BD" }}>
                                                Month View
                                            </span>
                                        </div> */}
                                    </div>
                                    <Line ref="chart" data={data} height={"122px"} />
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 " style={{ paddingTop: 50 }}>
                                <div style={{ width: "100%", height: this.state.height, border: "1px solid #3B3E50", padding: 15, borderRadius: 10, boxShadow: "0px 3px 6px #0000004D" }}>
                                    <div className={"row"} style={{ alignItems: "center" }}>
                                        <div className={"col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8"}>
                                            <span className={classes.notification}>
                                                Notifications
                                            </span>
                                        </div>
                                        <div className={"col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4"}>
                                            <a href="#/" className={classes.statistics} style={{ float: "right", color: "#1891BD" }}
                                                onClick={(e) => { e.preventDefault(); this.clearNotification() }}
                                            >
                                                Clear All
                                            </a>
                                        </div>
                                    </div>
                                    {border}
                                    {
                                        Object.keys(notificationData).length > 0 ?
                                            <div style={{ height: this.state.height / 1.2, overflow: "hidden", overflowY: "scroll" }}>
                                                {notificationData}
                                            </div>
                                            :
                                            <div className={"row"} >
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " style={{ textAlign: "center" }}>
                                                    <span className={classes.statistics} > No results found </span>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal
                        open={this.state.togglefilter}
                        onClose={this.changeToggleFilter}
                        center
                        closeIcon={<img src={CancelPng} alt="CancelPng" style={{ height: 15, width: 15 }} />}
                        showCloseIcon={false}
                    >
                        <div class="dialogue">
                            <div class="filterdatepicker">
                                <div style={{ font: '600 18px / 27px Poppins', opacity: '1', color: 'white', width: '100%' }}>
                                    Select period
                                </div>
                                <div>
                                    <span className={classes.label}> Period <span style={{ color: "#1891BD" }}>*</span> </span>
                                    <div style={{ paddingTop: 8 }}></div>
                                    <SelectBox
                                        onChange={this.handleChange}
                                        placeholder=""
                                        keyName={"filtervalue"}
                                        options={filteroptions}
                                        value={filtervalue} />
                                </div>

                                <div>
                                    <span className={classes.label}> Start Date <span style={{ color: "#1891BD" }}>*</span> </span>
                                    <div style={{ paddingTop: 8 }}></div>
                                    <DatePicker
                                        clearIcon={null}
                                        dayPlaceholder={"dd"}
                                        monthPlaceholder={"mm"}
                                        yearPlaceholder={"yyyy"}
                                        format={"MM/dd/y"}
                                        calendarClassName={"react-calender"}
                                        onChange={(e) => this.onChangeDatePicker('startdate', e)}
                                        // maxDate={new Date()}
                                        disabled={(filtervalue !== "custom")}
                                        value={startdate}
                                        calendarIcon={
                                            <img src={CalenderPng} alt="CalenderPng" style={{ width: 16, height: 18 }} />
                                        }
                                    />
                                </div>

                                <div>
                                    <span className={classes.label}> End Date <span style={{ color: "#1891BD" }}>*</span> </span>
                                    <div style={{ paddingTop: 8 }}></div>
                                    <DatePicker
                                        clearIcon={null}
                                        dayPlaceholder={"dd"}
                                        monthPlaceholder={"mm"}
                                        yearPlaceholder={"yyyy"}
                                        format={"MM/dd/y"}
                                        calendarClassName={"react-calender"}
                                        onChange={(e) => this.onChangeDatePicker('enddate', e)}
                                        // maxDate={new Date()}
                                        disabled={(filtervalue !== "custom")}
                                        value={enddate}
                                        calendarIcon={
                                            <img src={CalenderPng} alt="CalenderPngicon" style={{ width: 16, height: 18 }} />
                                        }
                                    />
                                </div>

                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <div>
                                        <Button buttonStyle={classes.buttonStyle} buttonName={"Cancel"}
                                            fullWidth={false}
                                            onClick={this.changeToggleFilter}
                                        />
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>
                                        <Button buttonStyle={classes.buttonStyle} buttonName={"Apply"}
                                            fullWidth={false}
                                            onClick={() => { this.getChartData(); this.changeToggleFilter() }}
                                        />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </Modal>

                    <Modal
                        open={seeDetailData.show}
                        onClose={this.seeDetailToggler}
                        showCloseIcon={true}
                        closeIcon={<img src={CancelPng} alt="CalenderPngscreen" style={{ height: 15, width: 15 }} />}
                        center>
                        <DashBoardSeeDetailComponent data={seeDetailData} height={this.state.height}
                            startdate={startdate} enddate={enddate} filtervalue={filtervalue}
                            serviceOfferedList={serviceOfferedList}
                            userDetails={userDetails} />
                    </Modal>

                    <Modal open={this.state.modal} onClose={() => this.onCloseModal()} center showCloseIcon={false}>
                        <div class="" style={{ padding: 20, background: "#2d303a" }}>
                            <div className={"row"}>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                    <Tab json={["Registered", "Appproved", "Request Pending"]} selected={this.state.selected} tabOnClick={this.tabOnClick} />
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " style={{ paddingTop: 20 }}>
                                    {
                                        Number(this.state.roleId) === 4 ?
                                            <Tables fixed={true} extraStyle={{ height: this.state.height / 1.6 }}
                                                thJson={["Player Name", "Player School", "position", "class", "sport"]}
                                                tdJson={[]}
                                            />
                                            :
                                            Number(this.state.roleId) === 3 ?
                                                <Tables fixed={true} extraStyle={{ height: this.state.height / 1.6 }}
                                                    thJson={["Agent Name", "Agency Name", "Services Offered", "City", "State"]}
                                                    tdJson={[]}
                                                />
                                                :
                                                <Tables fixed={true} extraStyle={{ height: this.state.height / 1.6 }}
                                                    thJson={["University admin name", "school", "City", "State"]}
                                                    tdJson={[]}
                                                />
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal>

                </Container>
                <Footer />
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(AdminDashboard));