import React, { useRef, useState } from 'react';
import Popper from '@material-ui/core/Popper';
import Styles from '../styles/uaAdmin/managerUsers';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { MenuItem } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { withStore } from './hooks/store';

const PopOver = (props) => {
    const { classes } = props;
    let anchorRef = useRef();
    const [sortToggler, setsortToggler] = useState(false);
    const [sortby, setsortby] = useState("");

    const setsorttogglers = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setsortToggler(pre => !pre)
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setsortToggler(pre => !pre)
        }
    }

    return (
        <>
            <div style={{ display: 'inline' }}>
                <span className={classes.sortByText}>
                    Sort by
                    <span
                        ref={anchorRef}
                        onClick={() => setsortToggler(pre => !pre)}
                        style={{ color: "#1891BD", paddingLeft: 10, cursor: 'pointer' }}>
                        {sortby ? sortby : props.sortby}
                    </span>
                    <Popper open={sortToggler}
                        anchorEl={anchorRef.current}
                        className={classes.Popper}
                        role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ?
                                        'center top' : 'center bottom'
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener
                                        onClickAway={(e) => setsorttogglers(e)}>                                            
                                        <MenuList
                                            autoFocusItem={sortToggler}
                                            className={classes.PopColor}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            {
                                                props.arrayList.map(item => {
                                                    return (
                                                        <MenuItem onClick={(e) => {
                                                            setsortby(item.label)
                                                            props.onchange(item.value);
                                                            setsortToggler(false);
                                                        }}>{item.label}</MenuItem>
                                                    )
                                                })
                                            }
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </span>
            </div>      
        </>
    );
}

export default withStyles(Styles, { withTheme: true })(withStore(PopOver))
