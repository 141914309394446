import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withStore } from "../../component/hooks/store";
import { CssBaseline, Container } from "@material-ui/core";
import Styles from "../../styles/agentDisclaimer.js";
import BackArrow from "../../images/backArrow.png";
import NxtLogo from "../../images/nxtpick-logo-header.png";
import Footer from "./footer.js";
// import Config from "../../helper/config";

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sectionStyle} >
        <nav
          class="navbar navbar-expand-md fixed-top"
          style={{
            borderBottom: "1px solid #3B3E50",
            background: "#262834"
          }}
        >
          <a
            href="#/"
            className="navbar-brand"
            style={{ paddingLeft: 20 }}
            onClick={e => e.preventDefault()}
          >
            <img
              src={NxtLogo}
              alt="logopng"
              style={{ width: 210, height: 22 }}
            />
          </a>
        </nav>
        <Container
          component="main"
          maxWidth="xl"
          style={{ position: "relative", zIndex: 5 }}
        >
          <CssBaseline />
          <div className={classes.paper1}>
            <a
              className={"col-md-12"}
              href="#/"
              onClick={e => {
                e.preventDefault();
                this.props.history.goBack();
              }}
              style={{ color: "inherit" }}
            >
              <img src={BackArrow} style={{ width: 31, height: 31 }} alt="" />
              <span
                href="#"
                style={{
                  font: "normal normal normal 12px/18px Poppins",
                  color: "#959595",
                  paddingLeft: 10,
                  opacity: 1
                }}
                className={"commonBackClass"}
              >
                Back
              </span>
            </a>
            <form className={classes.form} style={{marginTop:0,paddingTop:0}} noValidate>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    Thanks for using NXTPICK®. This privacy policy (this
                    <span style={{ textDecorationLine: "underline" }}>
                      “Privacy Policy”
                    </span>
                    ) applies to you (
                    <span style={{ textDecorationLine: "underline" }}>
                      “you”, “your”
                    </span>
                    , or{" "}
                    <span style={{ textDecorationLine: "underline" }}>
                      “user”
                    </span>
                    ) use of <a href="#/"> www.NXTPICK.com</a> and any associated
                    digital platforms, including mobile or tablet applications
                    downloaded through the App Store or Google Play Store,
                    pages, sub-domains, and sub-sites (collectively, the
                    "Site"). Gladiator Athletics, LLC (
                    <span style={{ textDecorationLine: "underline" }}>
                      “NXTPICK”, “we”, “our”,
                    </span>{" "}
                    or
                    <span style={{ textDecorationLine: "underline" }}>
                      “us”
                    </span>
                    ), is committed to the protection of your personal
                    information, and this Privacy Policy exists in order to help
                    you understand what information NXTPICK collects, how
                    NXTPICK collects and uses it, and, in light of this, make an
                    informed decision about your use of the Site.{" "}
                    <b>
                      IF YOU DO NOT AGREE TO ANY PORTION OF THIS PRIVACY POLICY,
                      DO NOT USE THE SITE. If you have any questions, please
                      contact us at
                    </b>
                    <a href="#/#/"> support@nxtpick.com.</a>
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}>I. USERS UNDER 18.</b> The Site is not intended for
                    individuals under the age of 18{" "}
                    <span style={{ textDecorationLine: "underline" }}>
                      (“Minors”)
                    </span>
                    , and such use is prohibited by our Terms of Use. NXTPICK
                    does not knowingly collect Personal Information from any
                    Minors. If you become aware that a Minor has provided us
                    with Personal Information, please contact us in accordance
                    with this Privacy Policy.
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}> II. USERS OUTSIDE OF THE US.</b> As of the Effective
                    Date of this Privacy Policy (as set forth at the bottom of
                    this Privacy Policy), the Site is not intended for use by
                    individuals who are not residents of the United States. Your
                    Personal Information may be processed in the country in
                    which it was collected and in other countries, including the
                    United States, where laws regarding processing of Personal
                    Information may be less stringent than the laws in your
                    country. By providing information to us, you acknowledge
                    that NXTPICK operates solely in the United States and agree
                    to (i) permit us to transmit and use your Personal
                    Information anywhere necessary, and (ii) that such use by us
                    shall be subject to the terms and conditions stated in this
                    Privacy Policy.
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}>III. COLLECTION OF PERSONAL INFORMATION.</b>
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 12, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}> A. Personal Information.</b>{" "}
                    <span style={{ textDecorationLine: "underline" }}>
                      "Personal Information"
                    </span>{" "}
                    means data relating to a person who is or can be identified
                    directly (or indirectly) from that data. NXTPICK collects
                    and stores Personal Information that you provide to us when
                    accessing or using the Site, which includes your name,
                    address, telephone number, email address, credit card or
                    other account number. Personal Information does not include:
                    (a) aggregated or anonymized information; (b) any publicly
                    available information about athletes and/or agents; or (c)
                    information that is posted to publicly accessible areas of
                    the Site.{" "}
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 12, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}> B. Athlete Profiles.</b> The profile pages of registered
                    athletes (each, an{" "}
                    <span style={{ textDecorationLine: "underline" }}>
                      “Athlete”
                    </span>
                    ) may contain and therefore collect: name, .edu email
                    addresses, phone number, school (name, city, and state),
                    graduation year, age, birth date, height, weight, position,
                    performance metrics (40-yard dash, 225-lb bench press
                    repetitions, vertical jump, broad jump, 20-yard shuttle,
                    3-cone drill, 60-yard shuttle) jersey number, social media
                    accounts (Facebook, Twitter, Instagram, etc.), awards,
                    photographs/videos featuring the athlete, and other
                    information, as may be updated from time to time
                    (collectively, the{" "}
                    <span style={{ textDecorationLine: "underline" }}>
                      “Athlete Information”
                    </span>
                    ). This information can be accessed by certified agents,
                    university administrators, coaching staff, and other
                    athletes. Athlete Information contained on the Site is not
                    available to the general public by default.
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 12, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}> C. Agent Profiles.</b> The profile pages for certified
                    agents (each, an{" "}
                    <span style={{ textDecorationLine: "underline" }}>
                      “Agent”
                    </span>
                    ) may contain and therefore collect: name, email addresses,
                    phone number, agency, agency contact information, services
                    offered, social media accounts (Facebook, Twitter,
                    Instagram, etc.), awards/certification(s), photographs, and
                    other information, as may be updated from time to time
                    (collectively, the
                    <span style={{ textDecorationLine: "underline" }}>
                      “Agent Information”
                    </span>
                    ). This information can be accessed by athletes, university
                    administrators, coaching staff, and other certified agents.
                    Agent Information contained on the Site is not available to
                    the general public by default.
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 12, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}>
                      D. Linking Social Media Pages to Athlete & Agent Profiles.
                    </b>{" "}
                    As described above, both Athletes and Agents may link their
                    profile pages to various social media accounts. NXTPICK only
                    receives the limited information that each applicable social
                    media platform permits to be transferred (as detailed and
                    governed by the applicable terms of each such social media
                    platform).
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 12, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}>E. Usage & Log-In Information.</b> NXTPICK collects
                    various usage information when you access the Site,
                    including your computer’s IP address, operating system,
                    browser, address of a referring website or website to which
                    you exit, date and time of Site access, interactions with
                    links available on the Site, pages you view, and time spent
                    on particular pages.
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 12, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}>F. Data Storage.</b> NXTPICK may use third party vendors
                    and hosting services to provide the necessary hardware,
                    software, networking, storage and related technology
                    required to run the Site, and such third parties may collect
                    and store your data on behalf of NXTPICK. NXTPICK owns the
                    code and all other rights therein (excluding the NCAA
                    database, of which NXTPICK has a non-exclusive right to
                    use). NXTPICK incorporates reasonable safeguards to help
                    protect and secure your Personal Information. However, no
                    data transmission over the Internet, wireless transmission,
                    or electronic storage of information can be guaranteed to be
                    100% secure. As such, NXTPICK cannot ensure and does not
                    warrant the security of any information you transmit via the
                    Site and you hereby agree to do so only at your own risk.
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 12, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}>G. Cookies.</b> This Site uses Cookies.{" "}
                    <span style={{ textDecorationLine: "underline" }}>
                      “Cookies”,
                    </span>{" "}
                    are small data files that are sent from the Site to your
                    browser, which is then stored on your computer or device to
                    enable the Site to recognize previous visitors and retain
                    information such as user-specific preferences and history.
                    Cookies enable NXTPICK to tailor the Site to your interests
                    (for the purposes of targeted advertising) and to provide an
                    overall better user experience. When visiting portions of
                    the Site containing embedded content from social media, you
                    may be presented with cookies from such websites that may
                    track your use of the Site NXTPICK does not have any control
                    over nor does it take responsibility for the dissemination
                    of these cookies and you should check the relevant third
                    party's website for more information
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}>
                      IV. DISCLOSURE AND RETENTION OF YOUR PERSONAL INFORMATION.
                    </b>{" "}
                    Under special circumstances, NXTPICK may wish to disclose or
                    are compelled to disclose your personal data to third
                    parties. This section details how NXTPICK may make
                    disclosures to third parties:
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 12, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}>A. Affiliates.</b> NXTPICK does not currently have a
                    parent company, any subsidiaries, joint ventures, or other
                    companies under common control (collectively,
                    <span style={{ textDecorationLine: "underline" }}>
                      “Affiliates”,
                    </span>{" "}
                    but may in the future. You hereby authorize NXTPICK to share
                    some or all of your Personal Information with its
                    then-current Affiliates, subject to applicable law and
                    solely for the following purposes:
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 8, marginLeft: 35 }}
                >
                  <span className={classes.label}>
                    (i) As necessary to provide services you have requested;
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 8, marginLeft: 35 }}
                >
                  <span className={classes.label}>
                    (ii) To provide sponsor-related feature enhancements on the
                    Site;
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 8, marginLeft: 35 }}
                >
                  <span className={classes.label}>
                    (iii) To investigate, prevent, or take action regarding
                    illegal activities, suspected fraud, situations involving
                    potential threats to the physical safety of any person,
                    violations of the Site’s Terms of Use, or as may be
                    otherwise required by law;
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 8, marginLeft: 35 }}
                >
                  <span className={classes.label}>
                    (iv) On a temporary or permanent basis for the purposes of a
                    joint venture, collaboration, financing, sale, merger,
                    reorganization, change of legal form, dissolution or similar
                    event (including merger and/or acquisition, under which
                    circumstances you will be notified);
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 8, marginLeft: 35 }}
                >
                  <span className={classes.label}>
                    (v) To protect the security and/or integrity of the Site,
                    including our databases and systems;
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 8, marginLeft: 35 }}
                >
                  <span className={classes.label}>
                    (vi) To proper legal authorities for the purposes of
                    managing or litigating a legal cause of action or claim; and
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 8, marginLeft: 35 }}
                >
                  <span className={classes.label}>
                    (vii) Any other purpose, for which NXTPICK has your
                    permission to do so.
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 12, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}>B. Retention. </b> Personal Information will be retained
                    by NXTPICK for as long as necessary to comply with the
                    purposes contained in this Privacy Policy and/or to comply
                    with applicable law. NXTPICK will not retain any Personal
                    Information beyond this point and the retention of all such
                    Personal Information will be subject to periodic review.
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}>V. MESSAGING. </b> NXTPICK’s messaging feature (the “
                    <span style={{ textDecorationLine: "underline" }}>
                      Message System
                    </span>
                    ”) is currently available to both Athletes and Agents
                    registered on the Site but may eventually be extended to
                    include university administrators. Messages sent through the
                    Message System may be between one sender and one or more
                    recipients. The Message System enables Athletes and Agents
                    to interact and have conversations with one another, with
                    the intent to connect these individuals to create
                    relationships and share resources.{" "}
                    <b>
                      ANY RELATIONSHIP (CONTRACTUAL OR OTHERWISE) FORMED AS A
                      RESULT OF ANY SUCH INTERACTION BETWEEN ATHLETES AND AGENTS
                      ON THE SITE IS BETWEEN THE AGENT AND ATHLETE—NOT BETWEEN
                      NXTPICK AND THE AGENT OR ATHLETE
                    </b>
                    . When a message is sent, the recipient will receive a
                    notification within the Site that they have a new unread
                    message in the Message System. NXTPICK reserves the right,
                    but is not obligated to, disclose any information if, in its
                    sole opinion, NXTPICK suspects or has reason to suspect that
                    the information contained in any message is:
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 8, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b>A. </b> Harassment, in any form, including but not
                    limited to hateful conduct related to race, ethnicity,
                    sexual orientation, gender, religion, caste, etc.;
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 2, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b>B. </b> Illegal activity (i.e., purported violation of
                    any applicable local, state, or federal law);
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 2, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b>C. </b> Violation of any applicable NCAA, athletic
                    conference, or school-specific rule, regulation, or policy;
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 2, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b>D. </b> Acts of threat/violence;
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 2, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b>E. </b> Spam;
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 2, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b>F. </b> Inherently deceptive, fraudulent, or a scam;
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 2, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b>G. </b> Related to sports gambling
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}>VI. CALIFORNIA PRIVACY RIGHTS. </b> Pursuant to the
                    California Consumer Privacy Act of 2018 (“
                    <span style={{ textDecorationLine: "underline" }}>
                      CCPA
                    </span>
                    ”), as of January 1, 2020, residents of California (as
                    defined under CCPA) have the right to:{" "}
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 2, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    A. Know what Personal Information NXTPICK collects about you
                    and from where NXTPICK collects it;
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 2, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    B. Access a copy of the Personal Information NXTPICK holds
                    about you;{" "}
                  </span>
                </div>
              </div>{" "}
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 2, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    C. Understand how NXTPICK processes your Personal
                    Information and how it may be shared;{" "}
                  </span>
                </div>
              </div>{" "}
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 2, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    D. Opt-out of having your Personal Information shared with
                    or sold to certain third parties; and{" "}
                  </span>
                </div>
              </div>{" "}
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 2, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    E. Request that NXTPICK delete your account on the Site and
                    all associated Personal Information.{" "}
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 8, paddingLeft: 40 }}
                >
                  <span className={classes.label}>
                    <b>
                      To make such a request, please send an email to{" "}
                      <a href="#/">support@nxtpick.com</a>.{" "}
                    </b>
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:15}}>VII. PRIVACY POLICY CHANGES. </b> NXTPICK may, from time
                    to time, update this Privacy Policy to comply with
                    applicable law. The most current version of this Privacy
                    Policy contained on the Site will govern NXTPICK’s use of
                    your information. By using the Site, you acknowledge and
                    agree to the terms contained in this Privacy Policy. NXTPICK
                    may notify you about material changes in the way we treat
                    Personal Information by sending notice to the email address
                    associated with your account and/or the applicable
                    university administrator(s).
                  </span>
                </div>
              </div>

              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    <b style={{fontSize:12}}>“EFFECTIVE DATE” OF THIS PRIVACY POLICY: JULY 19, 2021 </b> 
                  </span>
                </div>
              </div>
            </form>
          </div>
        </Container>
        <Footer />
      </div>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(withStore(Privacy));
