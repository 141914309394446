import React, { Component } from 'react'
import TextInput from 'react-textinput-field';

class index extends Component {     
    render() {
        var { updateInputValue, name, value, label, disabled, type } = this.props;
        var extraStyle = {};
        if(disabled)
        {
            extraStyle = {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            }
        }
        return (
            <div className={disabled ? "disabledInnerInput" : "innerInput"}>
                <TextInput
                    attr={{
                        "name": name,
                        "type": type ? type : "text",
                        "value": value || "",
                        "required": true,
                        "disabled" : disabled,
                        "style" : {
                            background: disabled ? "#373944" : "#51535d",
                            font: "normal normal normal 14px/21px Poppins",
                            color: disabled ? "#6f6e73" : "#fff",
                            height: 50,
                            outline: "none",
                            border: "none",
                            paddingLeft: disabled ? 12 : 15,
                            paddingRight: 15,
                            marginBottom: 0,
                            borderRadius: 5,
                            opacity: 0.7,
                            ...extraStyle
                        }
                    }}
                    updateInputValue={updateInputValue}
                    label={label}                                
                />
            </div>            
        )
    }
}
export default index;