const Styles = theme => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  buttonStyle: {
    backgroundColor: '#1891BD',
    border: '1px solid #1891BD',
    borderRadius: 5,
    color: 'white',
    height: 46,
    padding: '0 30px',
    opacity: 1
  },
  eyePng: {
    width: 15,
    height: 10,
    opacity: 1,
    zIndex: 1,
    position: "absolute",
    right: 0,
    marginTop: 27,
    marginRight: 20,
  },
  pwdstatusPng: {
    width: 15,
    height: 10,
    opacity: 1,
    zIndex: 1,
  }
});
export default Styles;