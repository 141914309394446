import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { CssBaseline, Container } from '@material-ui/core';
import { withStore } from '../../component/hooks/store';
import Config from '../../helper/config';
import Styles from "../../styles/agentValidate";
import NxtPick from "../../images/nxtpick-logo.png";
import Button from "../../component/button";

class AgentValidate extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.sectionStyle}>
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <br />
                        <img src={NxtPick} alt="" className={classes.avatar} />
                        <br />
                        <form className={classes.form} noValidate>
                            <div className={"row"}>
                                <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"} style={{ textAlign: "center" }}>
                                    <span className={classes.emailText}>
                                        EMAIL VALIDATION
                                    </span>
                                </div>
                                <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"} style={{ paddingTop: 10 }}>
                                    <span className={classes.labelText}>
                                        Welcome!
                                    </span>
                                </div>
                                <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"} >
                                    <span className={classes.labelText}>
                                        Thanks for registering on NXP !
                                    </span>
                                </div>
                                <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"} style={{ paddingTop: 15 }}>
                                    <span className={classes.labelText}>
                                        Now you can breathe easy about your agent profile, player search & agreements.
                                    </span>
                                </div>
                                <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"} style={{ paddingTop: 15 }}>
                                    <span className={classes.labelText}>
                                        To get started, please click on the below button to validate your e mail address:
                                    </span>
                                </div>
                                <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"} style={{ paddingTop: 15, textAlign: "center" }}>
                                    <Button buttonStyle={classes.buttonStyle} buttonName={"VALIDATE EMAIL ADDRESS"} onClick={() => this.props.history.push(Config.reactUrl + "/agentdisclaimer")} />
                                </div>
                                <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"} style={{ paddingTop: 15 }}>
                                    <span className={classes.labelText}>
                                        Once you click on the above link, you can experience an all awesome NXP Portal will player profile, agent details & much more.
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={"row"}>
                        <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"} style={{ paddingTop: 15, textAlign: "center" }}>
                            <span className={classes.footerLabel}>
                                If you haven't registered with NXP, <span style={{ color: "#1891BD" }}>Click here</span> to raise an incident.
                            </span>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(AgentValidate))