import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withStore } from "../../component/hooks/store";
import { CssBaseline, Container } from "@material-ui/core";
import Styles from "../../styles/agentDisclaimer.js";
import BackArrow from "../../images/backArrow.png";
import NxtLogo from "../../images/nxtpick-logo-header.png";
import Footer from "./footer.js";
import { Link } from "react-router-dom";

class FrequentlyAskedQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.sectionStyle}>
                <nav
                    class="navbar navbar-expand-md fixed-top"
                    style={{
                        borderBottom: "1px solid #3B3E50",
                        background: "#262834"
                    }}
                >
                    <Link to={"/"} variant="button" className={"navbar-brand"} style={{ paddingLeft: 20 }}>
                        <img
                            src={NxtLogo}
                            alt="logopng"
                            style={{ width: 210, height: 22 }}
                        />
                    </Link>
                </nav>

                <Container
                    component="main"
                    maxWidth="xl"
                    style={{ position: "relative", zIndex: 5 }}
                >
                    <CssBaseline />
                    <div className={classes.paper1}>
                        <a
                            className={"col-md-12"}
                            href="#/"
                            onClick={e => {
                                e.preventDefault();
                                this.props.history.goBack();
                            }}
                            style={{ color: "inherit" }}
                        >
                            <img src={BackArrow} style={{ width: 31, height: 31 }} alt="" />
                            <span
                                href="#"
                                style={{
                                    font: "normal normal normal 12px/18px Poppins",
                                    color: "#959595",
                                    paddingLeft: 10,
                                    opacity: 1,
                                }}
                                className={"commonBackClass"}
                            >
                                Back
                            </span>
                        </a>
                        <form className={classes.form} style={{ paddingTop: 0, margin: 0 }} noValidate>
                            <div className={"row"} ref={this.paneDidMount}>
                                <div
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                                    style={{ textAlign: "center" }}
                                >
                                    <span className={classes.disclaimerText} style={{ fontSize: 21 }}>
                                        NXTPICK FAQ
                                    </span>
                                </div>
                            </div>
                            <div className={"row"} ref={this.paneDidMount}>
                                <div
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                                    style={{ paddingTop: 30 }}
                                >
                                    <span className={classes.disclaimerText} style={{ fontSize: 19 }}>1. Am I guaranteed to sign with an agency/sign a player using your services?
                                    </span>
                                </div>
                                <div
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                                    style={{ paddingTop: 10, paddingLeft: 50 }}
                                >
                                    <span className={classes.label}>
                                        We offer an expansive database for users to find the best business partner for their career and business goal. However,we do not guarantee any forms of agreements between users.
                                    </span>
                                </div>
                            </div>
                            <div className={"row"} ref={this.paneDidMount}>
                                <div
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                                    style={{ paddingTop: 30 }}
                                >
                                    <span className={classes.disclaimerText} style={{ fontSize: 19 }}>2. Can I upload my own workout performance?</span>
                                </div>
                                <div
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                                    style={{ paddingTop: 10, paddingLeft: 50 }}
                                >
                                    <span className={classes.label}>
                                        Integrity is at the core of what we do. To provide a high level of integrity throughout or site and app, only certified university administrators and upload player workout data. If your workout performance does not auto populate on your profile page, your university may not have enrolled with NXTPICK as a certified university with admins that upload this data. We encourage players to speak to their coaches and/or athletic directors to contact us at <a href="mailto:support@nxtpick.com"> support@nxtpick.com </a>to ensure teams are represented and are found in search algorithms.
                                    </span>
                                </div>
                            </div>
                            <div className={"row"} ref={this.paneDidMount}>
                                <div
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                                    style={{ paddingTop: 30 }}
                                >
                                    <span className={classes.disclaimerText} style={{ fontSize: 19 }}>
                                        3. What happens after I graduate and are no longer playing for my university but want to utilize NXTPICK’s services as a player?
                                    </span>
                                </div>
                                <div
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                                    style={{ paddingTop: 10, paddingLeft: 50 }}
                                >
                                    <span className={classes.label}>
                                        Any player can use NXTPICK’s services after graduating. Please contact us at <a href="mailto:support@nxtpick.com"> support@nxtpick.com </a> for instructions on creating and/or maintaining an account when you are no longer enrolled as a student.
                                    </span>
                                </div>
                            </div>
                            <div className={"row"} ref={this.paneDidMount}>
                                <div
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                                    style={{ paddingTop: 30 }}
                                >
                                    <span className={classes.disclaimerText} style={{ fontSize: 19 }}>
                                        4. Where do my stats come from?
                                    </span>
                                </div>
                                <div
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                                    style={{ paddingTop: 10, paddingLeft: 50 }}
                                >
                                    <span className={classes.label}>
                                        Stats are generated from Genius Sports’ stats database. Genius Sports (GS) is the official stats managers of the NCAA. NXTPICK is honored to work with GS to provide accurate and reliable statistics that gives users a peace of mind.
                                    </span>
                                </div>
                            </div>
                            <div className={"row"} ref={this.paneDidMount}>
                                <div
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                                    style={{ paddingTop: 30 }}
                                >
                                    <span className={classes.disclaimerText} style={{ fontSize: 19 }}>
                                        5. I’m an agent, do I have to be certified to use NXTPICK’s services?
                                    </span>
                                </div>
                                <div
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                                    style={{ paddingTop: 10, paddingLeft: 50 }}
                                >
                                    <span className={classes.label}>
                                        Yes, all agents must be certified with their respective sports’ players association. Proof of this must be uploaded and approved by the NXTPICK team before an agent’s account can be activated.
                                    </span>
                                </div>
                            </div>
                            <div className={"row"} ref={this.paneDidMount}>
                                <div
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                                    style={{ paddingTop: 30 }}
                                >
                                    <span className={classes.disclaimerText} style={{ fontSize: 19 }}>
                                        6. Do the rules and regulations of the NCAA apply to users of this site?
                                    </span>
                                </div>
                                <div
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                                    style={{ paddingTop: 10, paddingLeft: 50 }}
                                >
                                    <span className={classes.label}>
                                        Yes. User must abide by rules and regulations as stated in our Terms of Use and Player & Agent disclaimers. These rules include but not limited to: (i) federal (including the Sports Agent Responsibility and Trust Act (SPARTA)); (ii) state (including the Uniform Athlete Agents Act (UAAA)); (iii) National Collegiate Athletic Association (NCAA),National Association of Intercollegiate Athletics, or any other applicable amateur athletic association(s); and any rules/policies of the Site.
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </Container>

                <Footer />
            </div>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(FrequentlyAskedQuestions));
