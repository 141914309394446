import React, { Component } from "react";
import {
  // BrowserRouter as Router,
  // Route, Link,
  Switch,
} from "react-router-dom";
// import { createBrowserHistory } from 'history';
import AdminDashboard from "./dashboard";
import Players from "./players";
import Agents from "./agents";
import UniversityAdmins from "./universityAdmins";
import UniversityAdminWorkoutPerformance from "./universityAdminWorkoutPerformance";

import changePassword from "./changePassword";
import Config from "../../helper/config";
import PrivateRoute from "./privateRoute";
import ViewAgentDetails from "./viewAgentDetails";
import SubAdmin from "./subAdmin";
import CoachUsers from "./coachUsers";
import Notifications from "./notifications";
import PlayerParentComponent from "../../component/playerDetail";
import AgentParentComponent from "../../component/agentDetails";

const reactUrl = Config.reactUrl + "/admin";
// const history = createBrowserHistory();

class AdminRouter extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute
          exact
          path={reactUrl + "/subadmin"}
          component={SubAdmin}
        />
        <PrivateRoute
          exact
          path={reactUrl + "/coachusers"}
          component={CoachUsers}
        />
        <PrivateRoute
          exact
          path={reactUrl + "/notifications"}
          component={Notifications}
        />
        <PrivateRoute
          exact
          path={reactUrl + "/dashboard"}
          component={AdminDashboard}
        />
        <PrivateRoute
          exact
          path={reactUrl + "/players/:id?"}
          component={Players}
        />
        <PrivateRoute
          exact
          path={reactUrl + "/agents/:id?"}
          component={Agents}
        />
        <PrivateRoute
          exact
          path={reactUrl + "/universityAdmins"}
          component={UniversityAdmins}
        />
        <PrivateRoute
          exact
          path={reactUrl + "/universityAdmins/uploadWorkoutPerformance/:id"}
          component={UniversityAdminWorkoutPerformance}
        />
        <PrivateRoute
          exact
          path={reactUrl + "/changepassword"}
          component={changePassword}
        />
        <PrivateRoute
          exact
          path={reactUrl + "/viewagentdetail/:id"}
          component={ViewAgentDetails}
        />
        <PrivateRoute
          exact
          path={reactUrl + "/adminplayerview/:type/:userId"}
          component={PlayerParentComponent}
        />
        <PrivateRoute
          exact
          path={reactUrl + "/adminagentview/:type/:userId"}
          component={AgentParentComponent}
        />
      </Switch>
    );
  }
}
export default AdminRouter;
