import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline, Container } from "@material-ui/core";
import { withStore } from "../../component/hooks/store";
// import Config from '../../helper/config';
import Styles from "../../styles/agentDisclaimer.js";
import Footer from "./footer.js";
// import Header from "../agent/header";
// import Button from "../../component/button";
// import ReactPlayer from 'react-player/youtube'
// import { checkNullAndReturnString } from '../../helper/common';
// import { Modal } from 'react-responsive-modal';
import BackArrow from "../../images/backArrow.png";
import NxtLogo from "../../images/nxtpick-logo-header.png";

class TermsOfUse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sectionStyle}>
        <nav class="navbar navbar-expand-md fixed-top" style={{
          borderBottom: "1px solid #3B3E50",
          background: "#262834",
        }}>
          <a href="#/" class="navbar-brand" style={{ paddingLeft: 20 }} onClick={e => e.preventDefault()}>
              <img src={NxtLogo} alt="logopng" style={{ width: 210, height: 22 }} />
          </a>
        </nav>
        <Container
          component="main"
          maxWidth="xl"
          style={{ position: "relative", zIndex: 5 }}
        >
          <CssBaseline />

          <div className={classes.paper1}>
            <a
              className={"col-md-12"}
              href="#/"
              onClick={(e) => {
                e.preventDefault();
                this.props.history.goBack();
              }}
              style={{ color: "inherit" }}
            >
              <img src={BackArrow} style={{ width: 31, height: 31 }} alt="" />
              <span
                href="#/"
                style={{
                  font: "normal normal normal 12px/18px Poppins",
                  color: "#959595",
                  paddingLeft: 10,
                  opacity: 1,
                }}
                className={"commonBackClass"}
              >
                Back
              </span>
            </a>

            <form className={classes.form} noValidate>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ textAlign: "center" }}
                >
                  <span className={classes.disclaimerText}>
                    PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY
                    BEFOREACCESSING OR USING THE SITE. BY ACCESSING OR USING THE
                    SITE,YOU ARE AGREEING TO ABIDE BY THESE TERMS OF USE.
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    I. Ownership of Site; Agreement to Terms of Use
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    Terms of Use (the <b>"Terms of Use"</b>) apply to
                    <a href="#/"> www.NXTPICK.com</a> and any associated digital
                    platforms, including mobile or tablet apps downloaded
                    through the App Store or Google Play Store, pages,
                    sub-domains, and sub-sites (collectively,the <b>"Site"</b>).
                    The Site is the property of GLADIATOR ATHLETICS, LLC, a
                    Missouri limited liability company (hereinafter{" "}
                    <b>“NXTPICK”</b>).
                    <b>
                      <u>
                        BY USING THE SITE, YOU AGREE TO THESE TERMS OF USE, AS
                        WELL AS NXTPICK’S PRIVACY POLICY; IF YOU DO NOT AGREE,
                        DO NOT USE THE SITE. IF YOU HAVE ANY QUESTIONS, PLEASE
                        CONTACT US.
                      </u>
                    </b>
                    {/* NXTPICK reserves the right, at its sole discretion, to
                    change, modify, add, or removeportions of these Terms of Use
                    at any time, and you agree to be bound by suchchanges or
                    modifications. Although NXTPICK may notify you of certain
                    changes(either by email or otherwise) in accordance with
                    applicable law, other changes maybe made without notice. It
                    is your responsibility to check these Terms of
                    Useperiodically for changes, and you hereby agree to do so.
                    Note that your continueduse of the Site following any
                    changes will mean that you accept and agree to thosechanges.
                    As long as you comply with these Terms of Use, NXTPICK
                    hereby grantsyou a personal, limited, non-exclusive,
                    non-transferable, revocable right and licenseto enter and
                    use the Site for your own personal purposes. */}
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    NXTPICK reserves the right, at its sole discretion, to
                    change, modify, add, or remove portions of these Terms of
                    Use at any time, and you agree to be bound by such changes
                    or modifications. Although NXTPICK may notify you of certain
                    changes (either by email or otherwise) in accordance with
                    applicable law, other changes maybe made without notice. It
                    is your responsibility to check these Terms of Use
                    periodically for changes, and you hereby agree to do so.
                    Note that your continued use of the Site following any
                    changes will mean that you accept and agree to those
                    changes. As long as you comply with these Terms of Use,
                    NXTPICK hereby grants you a personal, limited,
                    non-exclusive, non-transferable, revocable right and license
                    to enter and use the Site for your own personal purposes.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    II. Eligibility
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    By accessing or using the Site, you represent and warrant
                    that (a) all registration and other information you submit,
                    if any, is truthful, accurate, and complete; (b) you will
                    maintain the accuracy of any information you provide; (c)
                    you will not submitany personal information if you are under
                    18 years of age without your parent’s or legal guardian’s
                    permission; and (d) your access or use of the Site does not
                    violate any applicable law or regulation, including the
                    rules of the National Collegiate Athletic Association
                    (NCAA), National Association of Intercollegiate Athletics,
                    and/orany other applicable amateur athletic association(s).
                    NXTPICK reserves the right, in its sole discretion, to
                    terminate or suspend your access to, and/or use of, the Site
                    or any portion thereof, at any time, with or without notice
                    and for any reason.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>III. Privacy</span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    NXTPICK respects the privacy of your personal information.
                    Please read our Privacy Policy (the <b>“Privacy Policy”</b>
                    ), as it details how we handle the information you provide
                    to us when you use the Site, and its terms are made a part
                    of these Terms of Use by this reference. By accessing or
                    using the Site, you accept and agree to allow us to use your
                    information as set forth therein.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    IV. Your Account, Password, Security, and Account
                    Termination
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    The features or services offered on or through the Site
                    require you to register an account as either an athlete,
                    school administrator, or registered agent. You are entirely
                    responsible for maintaining the security and confidentiality
                    of the information you hold on your account, including your
                    password(s), and for any and all activity that occurs on
                    your account as a result of your failure to keep this
                    information secure and confidential. Additionally, you agree
                    not to share or transfer your account login information (if
                    any) with or to any third party. NXTPICK may, in its sole
                    discretion and at any time, terminate your account, refuse
                    your registration,and remove or edit your posted
                    User-Submitted Content (as such term is defined below).
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    V. Acceptance, Applicability, and Other Agreements
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    These Terms of Use apply to and govern your access or use of
                    the Site and you accept them by accessing or using the Site
                    or creating an account. Each time you access or use the Site
                    or create an account, you are confirming your acceptance of
                    these Terms of Use, as they exist on that date.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    VI. Postings; User-Submitted Content
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    During your use of the Site, you may be provided the
                    opportunity to post photos,video footage, or other
                    information that may then be shared publicly with others
                    through the Site, or otherwise by NXTPICK (
                    <b>“User-Submitted Content”</b> or <b>“USC”</b>).You are
                    solely responsible for the USC that you post on or through
                    the Site and you agree that you may only do so at your own
                    risk. By submitting USC or information of any type, you
                    automatically grant NXTPICK a royalty-free, perpetual,
                    irrevocable,non-exclusive right and license to use,
                    reproduce, modify, publish, translate, create derivative
                    works from, distribute, transmit, perform, and display such
                    USC or information (in whole or in part) worldwide and/or to
                    incorporate into other works in any form, media, or
                    technology now known or later developed for the full term of
                    any rights that may exist in such USC. This license
                    authorizes us to make your USC available to others on the
                    Site. NXTPICK may sublicense all or part of its rights under
                    this license or assign them to third parties. You also
                    acknowledge that such submissions are non-confidential for
                    all purposes.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    You will not post or transmit any of the following materials
                    (each, <b>“Prohibited USC”</b>): (a) anything that
                    interferes with or disrupts the Site or the operation of the
                    Site or any product or service made available by NXTPICK;
                    (b) statements or material that defames, harasses, abuses,
                    stalks, threatens, or in any way infringeson the rights of
                    others; (c) unauthorized copyrighted materials or any other
                    material that infringes on the intellectual property rights,
                    trade secrets, or privacy ofanother; (d) statements or
                    material that violates other contractual or fiduciary
                    rights, duties, or agreements; (e) statements or material
                    that encourages criminal conduct or that would give rise to
                    civil liability or otherwise violates any law or regulation
                    in any jurisdiction; (f) statements or material that
                    contains vulgar,obscene, profane, or otherwise objectionable
                    language or images that typicallywould not be considered
                    socially or professionally responsible or appropriate in
                    person; (g) statements or material that impersonates any
                    other person or entity,whether actual or fictitious,
                    including employees and representatives of NXTPICK, or its
                    subsidiaries; (h) anything that violates the privacy or
                    publicity rights of any otherperson; (i) statements or
                    material that constitute junk mail, spam, or
                    unauthorizedadvertising or promotional materials; or (j)
                    files that contain malicious code, viruses,corrupted files,
                    or any other similar software or programs that may damage
                    theoperation of another’s computer, network, or the Site.
                    Furthermore, by posting USC,you represent and warrant that
                    all USC posted by you on or through the Site orotherwise is
                    not Prohibited USC and that you own or have all the rights
                    necessary togrant NXTPICK the license set forth in this
                    section.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    NXTPICK reserves the right to and may delete any USC for any
                    reason, including content that, in NXTPICK’s sole judgment,
                    is offensive, morally reprehensible, illegal,or violates the
                    rights or threatens the safety of any person. NXTPICK does
                    not monitor the Site for inappropriate Prohibited USC;
                    however, if NXTPICK becomes aware of any such content,
                    NXTPICK may (but is not required to, except to the extent
                    required by applicable law) remove it.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    VII. Trademarks & Copyright; Notice for Claims of Copyright
                    Infringement
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    NXTPICK® is a federally registered trademark of NXTPICK. Any
                    use of any mark(s) of NXTPICK without the express written
                    consent of NXTPICK is prohibited. All other trademarks
                    appearing on the Site are the property of their respective
                    owners. Reference to any products, services, processes, or
                    other information by retailer, manufacturer, supplier, or
                    otherwise does not constitute or imply the endorsement,
                    sponsorship, or recommendation by NXTPICK.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    You acknowledge that the content included on the Site,
                    including but not limited to, text, graphics, logos, button
                    icons, images, audio clips, video clips, software, and the
                    selection and arrangements thereof, is and will remain the
                    sole and exclusive property of NXTPICK and is protected by
                    U.S. copyright laws. Any other use, including the
                    reproduction, modification, distribution, republication or
                    display of any of the content on the Site is strictly
                    prohibited and an infringement of copyright or proprietary
                    rights in the information, and of these Terms of Use.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    NXTPICK follows the requirements set forth in the Digital
                    Millennium Copyright Act(<b>“DMCA”</b>) and responds to
                    clear notices of claimed copyright infringement in full
                    compliance thereof. If you are a copyright owner or agent
                    thereof and believe that USC posted by any user on the Site
                    infringes upon your copyright, please submit anotice either
                    by mail or e-mail (legal@nxtpick.com) containing the
                    following information: (a) an electronic or physical
                    signature of the person authorized to acton behalf of the
                    owner of the copyright; (b) a description of the copyrighted
                    work that you claim has been infringed; (c) the URL of the
                    location on our Site containing the material that you claim
                    is infringing; (d) your address, telephone number, and email
                    address; (e) a statement by you that you have a good faith
                    belief that the disputed use is not authorized by the
                    copyright owner, its agent, or the law; and (f) a statement
                    by you, made under penalty of perjury, that the above
                    information in your notice is accurate and that you are the
                    copyright owner or authorized to act on the copyright
                    owner’s behalf. For DMCA notices provided by mail, please
                    send notice to:
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    <b>Gladiator Athletics, LLC</b>
                    <br />
                    224 N Highway 67, Suite 112Florissant, Missouri 63031
                    <br />
                    Attn: Legal
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    Note that under the DMCA, any person who knowingly
                    materially misrepresents thatmaterial or activity is
                    infringing, or that material or activity was removed or
                    disabled by mistake or misidentification, may be subject to
                    liability.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    VIII. Links; Third-Party Websites
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    The Site may contain or provide links or references to other
                    websites, resources, and advertisers. Your use of any
                    third-party site is at your own risk, and you acknowledge
                    that NXTPICK has no control or responsibility, nor makes any
                    representations of any kind with respect to such third-party
                    sites or transactions that may occur there. Links or
                    references are provided on the Site for convenience only and
                    NXTPICK will not be liable for any damages or injury arising
                    from such third-party sites. By using a third-party site,
                    you may be agreeing to their terms and conditions, which may
                    differ significantly from these Terms of Use. Make certain
                    you understand the risks and any applicable policies of
                    third-party websites before retrieving, using, relying upon,
                    before purchasing or otherwise transacting, via the
                    Internet.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    IX. Linking, Crawling, and Framing
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    You may link only to the Site’s home page, but not to any
                    other page or portion of the Site. You agree not to, and
                    warrant and represent that you will not assist any
                    third-party in engaging in any activity or attempting in any
                    way, to reproduce, inwhole or in part, any content provided
                    by the Site through any method (including,without
                    limitation, through crawling, copying, caching, or framing),
                    unless such access is expressly permitted in a written
                    agreement executed by NXTPICK. You may not, for instance,
                    (a) incorporate the information, content, or other material
                    in any database, compilation, archive, API or cache; (b)
                    deep-link to the Site for any purpose; (c) access the Site
                    manually or with any robot, spider, web crawler,extraction
                    software, automated process, device, program, or algorithm
                    to scrape, copy, or monitor any portion of the Site; (d)
                    take any action that imposes an unreasonable or
                    disproportionately large load on the infrastructure of the
                    Site; or (e) attempt to gain unauthorized access to any
                    portion or feature of the Site by hacking, password mining,
                    or any other illegitimate means.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>X. Disclaimer</span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    YOU ARE USING THE SITE AT YOUR OWN RISK AND EXPRESSLY
                    ACKNOWLEDGETHAT THE INFORMATION CONTAINED ON THE SITE
                    CONTAINS THIRD PARTY DATA AND CONTRIBUTIONS. YOU UNDERSTAND
                    AND AGREE THAT THERE ARE CERTAIN INHERENT LIMITATIONS TO THE
                    ACCURACY OR CURRENCY OF SUCH INFORMATION(I.E., THAT THE
                    INFORMATION MAY CONTAIN INACCURACIES OR BE INCOMPLETE).{" "}
                    <b>
                      NXTPICK DOES ITS BEST TO SCREEN SUCH INFORMATION FOR
                      ACCURACYOR RELIABILITY BUT CANNOT MAKE ANY GUARANTEE. TO
                      THE FULLEST EXTENT PERMITTED BY LAW, NXTPICK MAKES NO
                      WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SITE. THE
                      SITE IS "AS IS”. NXTPICKDOES NOT WARRANT THAT THE SITE
                      WILL BE UNITERUPTED OR ERROR-FREE, THAT DEFECTS WILL BE
                      CORRECTED, OR THAT THE SITE IS FREE OF VIRUSES OR OTHER
                      HARMFUL COMPONENTS. NXTPICK ALSO DISCLAIMS ANY WARRANTIES
                      OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                      TITLE, NON-INFRINGEMENT, OR YOUR SATISFACTION.
                    </b>
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    XI. Limitation of Liability
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    IN NO EVENT WILL NXTPICK BE LIABLE FOR ANY DIRECT, INDIRECT,
                    SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY, OR CONSEQUENTIAL
                    DAMAGES, OR ANY DAMAGES WHATSOEVER, EVEN IF NXTPICK HAS BEEN
                    PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES
                    (WHETHER IN AN ACTION UNDER CONTRACT,NEGLIGENCE, TORT, OR
                    ANY OTHER THEORY) ARISING OUT OF OR IN CONNECTION WITH THE
                    USE, INABILITY TO USE, OR PERFORMANCE OF THE INFORMATION,
                    SERVICES, CONTENT, PRODUCTS, AND MATERIALS AVAILABLE FROM
                    THE SITE ORTHE INTERNET GENERALLY. NXTPICK SHALL HAVE NO
                    LIABILITY FOR ANY DAMAGES OR INJURY CAUSED, IN WHOLE OR IN
                    PART, BY CONTINGENCIES OR ISSUES BEYONDITS REASONABLE
                    CONTROL, INCLUDING BUT NOT LIMITED TO, ACTS OF THIRD
                    PARTIES, ERRORS ON THE SITE, NETWORK FAILURES, INTERNET
                    FAILURES, SOFTWARE AND HARDWARE FAILURES, VIRUSES OR OTHER
                    SYSTEM ATTACKS, LABOR STOPPAGES, RIOTS, ACTS OF GOVERNMENT
                    OR GOD (INCLUDING PANDEMIC), NATURAL DISASTERS, ACTS OF
                    TERRORISM, COMMUNICATION LINEFAILURE, OR THEFT, DESTRUCTION
                    OF, UNAUTHORIZED ACCESS TO, ALTERATIONOF OR USE OF ANY
                    INFORMATION ON THE SITE.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    Because some jurisdictions do not allow the limitation or
                    exclusion of liability for incidental or consequential
                    damages, some of the above limitations may not apply to you.
                    In such jurisdictions, NXTPICK’s liability is limited to the
                    maximum extent permitted by law.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    XII. Indemnification
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    You hereby agree to indemnify and hold NXTPICK, its parent
                    and affiliates, and each of their respective officers and
                    employees harmless from each claim or demand,including
                    reasonable attorneys’ fees, made by any third party due to
                    or arising out of or relating to: (a) your use of the Site,
                    including but not limited to communications with others
                    using the messaging platform; (b) your alleged violation of
                    these Terms; and (c) your infringement of any intellectual
                    property, privacy, or other right of any person or entity.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    XIII. Dispute Resolution
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    If you believe that NXTPICK has violated these Terms of Use,
                    its Privacy Policy or your rights, you agree to notify
                    NXTPICK in writing and provide us with no less than thirty
                    (30) days to resolve any dispute
                    <b>
                      <u> before</u>
                    </b>{" "}
                    bringing formal legal action. If you feel your intellectual
                    property, privacy, or safety is at risk, the foregoing will
                    not serve to prevent you from seeking immediate injunctive
                    or other relief from a court of competent jurisdiction. For
                    all other disputes, and all claims for damages, you agree to
                    provide a reasonable cure period of no less than thirty (30)
                    days
                    <b>
                      <u> before</u>
                    </b>{" "}
                    bringing formal legal action. The foregoing is intended to
                    be applicable only injurisdictions where it is not forbidden
                    by law. You agree that any cause of action that you may
                    desire to bring arising out of or related to these Terms of
                    Use and/or the Site must commence within one (1) year after
                    such cause of action arises; otherwise, such cause of action
                    will be permanently barred. In any formal legal action
                    interpreting these Terms of Use, or alleging a breach of
                    them, the prevailing party will be entitled to recover its
                    reasonable attorneys’ fees in addition to anyother damages
                    or relief awarded by a court of competent jurisdiction.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    XIV. Governing Law
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    You agree that these Terms of Use (and your agreement to
                    them) and your accessor use of the Site are governed
                    exclusively by the laws of the State of Missouri, without
                    regard to its conflict of laws provision. You hereby consent
                    to the exclusive jurisdiction and venue of the state and
                    federal courts in St. Louis, Missouri, in alldisputes (a)
                    arising out of, relating to, or concerning the Site and/or
                    this agreement and/or these Terms of Use, (b) in which the
                    Site and/or this agreement and/or these Terms of Use is an
                    issue or a material fact, or (c) in which the Site and/or
                    this agreement and/or these Terms of Use is referenced in a
                    paper filed in a court,tribunal, agency or other dispute
                    resolution organization. You agree to waive any and all
                    objections to jurisdiction or venue in St. Louis, Missouri.
                    Access or use of the Site is unauthorized in any
                    jurisdiction that does not give full effect to all
                    provisions of this agreement, including without limitation
                    this paragraph and the warranty disclaimers and liability
                    exclusions above. NXTPICK has endeavored to comply withall
                    legal requirements known to it in creating and maintaining
                    the Site but makes no representation that materials on the
                    Site are appropriate or available for use in any particular
                    jurisdiction. Access or use of the Site is unauthorized in
                    any jurisdiction where all or any portion of the Site may
                    violate any legal requirements and you agree not to access
                    the Site in any such jurisdiction. You are responsible for
                    compliance with applicable laws. Any use in contravention of
                    this provision or any provision of this agreement is at your
                    own risk and, if any part of these Terms of Use is invalid
                    or unenforceable under applicable law, the invalid or
                    unenforceable provision will be deemed superseded by a
                    valid, enforceable provision that most closely matches the
                    intent of the original provision and the remainder of these
                    Terms of Use will govern such use.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>XV. General</span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    You agree that no joint venture, partnership, employment, or
                    agency relationship exists between you and NXTPICK as a
                    result of this agreement or your use of the Site. You
                    understand that the Site is a platform where registered
                    agents and eligible athletes unaffiliated with NXTPICK or
                    the Site have the opportunity to interact with one another;
                    any relationship (contractual or otherwise) formed as a
                    result of any such interaction between athletes and agents
                    on the Site is between the agent and athlete—not between
                    NXTPICK and the agent or athlete. NXTPICK’s performance of
                    this agreement is subject to existing laws and legal
                    process, and nothing contained in this agreement is in
                    derogation of NXTPICK’s right to comply with law enforcement
                    requests or requirements relating to your use of the Site or
                    information provided to or gathered by NXTPICK with respect
                    to such use.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    XVI. Miscellaneous
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    These Terms of Use (including, without limitation, and any
                    other terms and conditions that may appear on the Site from
                    time-to-time) contain the full understanding with respect to
                    your use and access of the Site. If any portion of these
                    Terms of Use is held to be invalid or unenforceable, the
                    invalid or unenforceable portion will be modified in
                    accordance with the applicable law as nearly as possible to
                    reflect the original intention of the applicable provision,
                    and the remainder of these Terms of Use will remain in full
                    force and effect. The failure of NXTPICK to insist upon or
                    enforce strict performance by you of any provision of these
                    Terms of Use will not be construed as a waiver of any
                    provision or right.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    We may provide notice to you relating to the Site and/or
                    these Terms of Use by sending an email to your last known
                    email address, which you agree to provide tous automatically
                    when it changes from the one you used to first register on
                    the Site,and any such notice will be deemed given and
                    received on the day it issent.Providing an email address
                    constitutes your having opted-in to receive email
                    communications from us in terms of compliance with, as may
                    be applicable, the United States CAN-SPAM Act and other
                    similar U.S. laws.
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    <i>
                      For any additional questions or feedback related to these
                      Terms of Use, please email
                      <a href="#/"> legal@nxtpick.com</a>
                    </i>
                  </span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText}>
                    Last Updated: July 6, 2021
                    <br /> Copyright © Gladiator Athletics, LLC. All Rights
                    Reserved.
                  </span>
                </div>
              </div>
            </form>
          </div>
        </Container>
        <Footer />
      </div>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(withStore(TermsOfUse));
