import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline, Container } from "@material-ui/core";
import { withStore } from "../../component/hooks/store";
import {
  /*ValidateEmail,*/ checkNullAndReturnString,
  doGetConnect,
  doPostConnect,
} from "../../helper/common";
import Config from "../../helper/config";
import eyePng from "../../images/eye.png";
import tickPng from "../../images/tick.png";
import Styles from "../../styles/agentCreatePassword.js";
import Footer from "./footer.js";
import TextInput from "react-textinput-field";
import Button from "../../component/button";
// import Header from "../agent/header";
import { toast } from "react-toastify";
import Checkbox from "@material-ui/core/Checkbox";

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eye: true,
      password: "",
      confirmPassword: "",
      email: "",

      passwordStrength: 0,
      confirmPasswordStrength: 0,

      pwdstrength: {
        uppercase: false,
        lowercase: false,
        number: false,
        symbols: false,
        length: false,
      },
    };
  }

  componentDidMount = async () => {
    // var url = new URL(window.location.href);
    // var hashEmail = url.searchParams.get("hash");
    const queryString = window.location.href;
    let paramString = queryString.split("?")[1];
    let params_arr = paramString.split("&");
    for (let i = 0; i < params_arr.length; i++) {
      let pair = params_arr[i].split("=");
      var hashEmail = pair[1];
      if (checkNullAndReturnString(hashEmail)) {
        var responseData = await doGetConnect(
          "password/create?email=" + hashEmail
        );
        if (checkNullAndReturnString(responseData)) {
          if (checkNullAndReturnString(responseData?.email_id)) {
            this.setState({ email: responseData.email_id });
          } else {
            this.props.history.push(Config.reactUrl + "/login");
            toast.error(responseData?.message);
          }
        }
      }
    }
  };

  updateInputValue = (id, value) => {
    this.setState(
      {
        [id]: value,
      },
      this.validateFormField
    );
  };

  validateFormField = () => {
    var { /* confirmPassword, */ password } = this.state;
    let passwordstrength = 0;
    let uppercase = false,
      lowercase = false,
      number = false,
      symbols = false,
      length = false;

    if (/^(?=.*[a-z]).*$/gm.test(password)) {
      lowercase = true;
      passwordstrength = passwordstrength + 1;
    } else {
      lowercase = false;
    }
    if (/^(?=.*[A-Z]).*$/gm.test(password)) {
      uppercase = true;
      passwordstrength = passwordstrength + 1;
    } else {
      uppercase = false;
    }
    if (/^(?=.*[0-9]).*$/gi.test(password)) {
      number = true;
      passwordstrength = passwordstrength + 1;
    } else {
      number = false;
    }
    if (/^(?=.*[!@#$%^&*~|]).*$/gi.test(password)) {
      symbols = true;
      passwordstrength = passwordstrength + 1;
    } else {
      symbols = false;
    }
    if (/^.{8,24}$/gim.test(password)) {
      length = true;
    } else {
      length = false;
    }
    if (!/^.{8,24}$/gim.test(password) && passwordstrength !== 0) {
      passwordstrength = passwordstrength - 1;
    }

    this.setState((pre) => ({
      passwordStrength: passwordstrength,
      pwdstrength: {
        ...pre.pwdstrength,
        uppercase,
        lowercase,
        number,
        symbols,
        length,
      },
    }));
  };

  validateForm = () => {
    var { confirmPassword, password } = this.state;

    const validatepasswordstrength = (value) => {
      let passwordstrength = 0;
      if (/^(?=.*[a-z]).*$/gim.test(value)) {
        passwordstrength = passwordstrength + 1;
      }
      if (/^(?=.*[A-Z])(?=.*[a-z]).*$/gm.test(value)) {
        passwordstrength = passwordstrength + 1;
      }
      if (/^(?=.*[0-9]).*$/gi.test(value)) {
        passwordstrength = passwordstrength + 1;
      }
      if (/^(?=.*[!@#$%^&*~|]).*$/gi.test(value)) {
        passwordstrength = passwordstrength + 1;
      }
      if (!/^.{8,24}$/gim.test(value) && passwordstrength !== 0) {
        passwordstrength = passwordstrength - 1;
      }
      return passwordstrength;
    };

    let error = "";
    let status = true;
    if (!password) {
      error = "Password is required";
      status = false;
    } else {
      // let uppercase = false, lowercase = false, number = false, symbols = false, length = false;

      // if ((/^(?=.*[a-z]).*$/gm).test(password)) { lowercase = true; } else { lowercase = false; }
      // if ((/^(?=.*[A-Z]).*$/gm).test(password)) { uppercase = true } else { uppercase = false }
      // if ((/^(?=.*[0-9]).*$/gi).test(password)) { number = true } else { number = false }
      // if ((/^(?=.*[!@#$%^&*~|]).*$/gi).test(password)) { symbols = true } else { symbols = false }
      // if ((/^.{8,24}$/gmi).test(password)) { length = true } else { length = false }
      // this.setState(pre => ({
      //     pwdstrength: {
      //         ...pre.pwdstrength,
      //         uppercase,
      //         lowercase,
      //         number,
      //         symbols,
      //         length
      //     }
      // }));
      let passwordstrength = validatepasswordstrength(password);
      this.setState({ passwordStrength: passwordstrength });
      if (passwordstrength !== 4) {
        error = "password is not valid";
        status = false;
      } else {
        if (!confirmPassword) {
          error = "Confirm password is required";
          status = false;
        } else {
          let confirmpasswordstrength =
            validatepasswordstrength(confirmPassword);
          this.setState({ confirmPasswordStrength: confirmpasswordstrength });
          if (confirmpasswordstrength !== 4) {
            error = "Confirm password is not valid";
            status = false;
          } else {
            if (!Object.is(password, confirmPassword)) {
              error = "Password and confirm password should be same";
              status = false;
            }
          }
        }
      }
    }
    if (error) {
      toast.error(error);
    }
    return status;
  };

  passwordStrengthName = () => {
    let val = this.state.passwordStrength;

    if (val === 0) {
      return "None";
    } else if (val === 1) {
      return "Weak";
    } else if (val === 2) {
      return "Weak";
    } else if (val === 3) {
      return "Normal";
    } else if (val === 4) {
      return "Strong";
    }
  };

  getStrengthColor = () => {
    let val = this.state.passwordStrength;
    if (val === 0 || val === 1 || val === 2) {
      return "red";
    } else if (val === 3) {
      return "yellow";
    } else if (val === 4) {
      return "#3FCB57";
    }
  };

  submitFunction = async () => {
    var { confirmPassword, password, email } = this.state;
    if (checkNullAndReturnString(password)) {
      if (password === confirmPassword) {
        var postJson = {
          email: email,
          password: password,
          confirmPassword: confirmPassword,
        };
        var responseData = await doPostConnect("password/create", postJson);
        if (checkNullAndReturnString(responseData)) {
          if (checkNullAndReturnString(responseData.message)) {
            if (responseData.message === "success") {
              toast.success("Password has been created successfully");
              setTimeout(
                () => this.props.history.push(Config.reactUrl + "/login"),
                2500
              );
            } else {
              toast.error(responseData.message);
            }
          } else {
            if (checkNullAndReturnString(responseData.error)) {
              if (checkNullAndReturnString(responseData.error.message)) {
                toast.error(responseData.error.message);
              }
            }
          }
        }
      } else {
        toast.error("New password and confirm password does not match");
      }
    } else {
      toast.error("Please enter password");
    }
  };

  render() {
    const { classes } = this.props;
    var { /* userName, */ eye, password, confirmPassword } = this.state;

    return (
      <div className={classes.sectionStyle}>
        {/* <Header transparent={true} /> */}
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={"createPasswordView"}>
            <span className={classes.createPAsswordFont}>CREATE PASSWORD</span>
            <form className={classes.form} noValidate>
              <div
                class={"createPassword"}
                style={{
                  borderRadius: 5,
                }}
              >
                <img
                  src={eyePng}
                  alt="eyepng"
                  className={classes.eyePng}
                  onClick={() => this.setState({ eye: !eye })}
                />
                <TextInput
                  attr={{
                    name: "password",
                    type: eye ? "password" : "text",
                    value: this.state["password"] || "",
                    required: true,
                    style: {
                      background: "rgba(0,0,0,0.40)",
                      font: "normal normal normal 14px/21px Poppins",
                      letterSpacing: 0,
                      color: "#fff",
                      height: 70,
                      outline: "none",
                      border: "none",
                      paddingLeft: 20,
                      paddingRight: 45,
                      marginBottom: 0,
                    },
                  }}
                  updateInputValue={this.updateInputValue}
                  label={"New Password"}
                  labelStyle={{
                    fontSize: 20,
                  }}
                />
              </div>
              <div
                class={"createPassword"}
                style={{
                  borderRadius: 5,
                }}
              >
                {checkNullAndReturnString(password) &&
                password === confirmPassword ? (
                  <img
                    src={tickPng}
                    alt=""
                    className={classes.eyePng}
                    onClick={() => this.setState({ eye: !eye })}
                  />
                ) : (
                  <br />
                )}
                <TextInput
                  attr={{
                    name: "confirmPassword",
                    type: "password",
                    value: this.state["confirmPassword"] || "",
                    required: true,
                    style: {
                      background: "rgba(0,0,0,0.40)",
                      font: "normal normal normal 14px/21px Poppins",
                      letterSpacing: 0,
                      color: "#fff",
                      height: 70,
                      outline: "none",
                      border: "none",
                      paddingLeft: 20,
                      paddingRight: 45,
                      marginBottom: 0,
                    },
                  }}
                  updateInputValue={this.updateInputValue}
                  label={"Confirm Password"}
                  labelStyle={{
                    fontSize: 20,
                  }}
                />
              </div>
              <div style={{ paddingTop: 15 }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
                class="Password"
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  {/* {
                            this.state.pwdstrength.uppercase
                                ? <img src={Greentick} className={this.props.pwdstatusPng} />
                                : <i className="fa" style={{ fontSize: '20px', color: 'red' }}>&#x2715;</i>
                                } */}
                  <Checkbox
                    style={{ padding: "0px" }}
                    checked={this.state.pwdstrength.uppercase}
                    color="primary"
                    onChange={(e) => e.preventDefault()}
                  />

                  <span
                    style={{
                      fontSize: "14px",
                      color: "#fff",
                      marginLeft: "4px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Upper Case
                  </span>
                </span>
                {/* <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px' }}>
                        {
                            this.state.pwdstrength.lowercase
                                ? <img src={Greentick} className={this.props.pwdstatusPng} />
                                : <i className="fa" style={{ fontSize: '20px', color: 'red' }}>&#x2715;</i>
                        }
                        <span style={{ fontSize: '14px', color: '#fff', marginLeft: '4px' }}>Lower Case</span>
                    </span> */}
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  {/* {
                            this.state.pwdstrength.number
                                ? <img src={Greentick} className={this.props.pwdstatusPng} />
                                : <i className="fa" style={{ fontSize: '20px', color: 'red' }}>&#x2715;</i>
                        } */}
                  <Checkbox
                    style={{ padding: "0px" }}
                    checked={this.state.pwdstrength.number}
                    color="primary"
                    onChange={(e) => e.preventDefault()}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#fff",
                      marginLeft: "4px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Numbers
                  </span>
                </span>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  {/* {
                            this.state.pwdstrength.symbols
                                ? <img src={Greentick} className={this.props.pwdstatusPng} />
                                : <i className="fa" style={{ fontSize: '20px', color: 'red' }}>&#x2715;</i>
                        } */}
                  <Checkbox
                    style={{ padding: "0px" }}
                    checked={this.state.pwdstrength.symbols}
                    color="primary"
                    onChange={(e) => e.preventDefault()}
                  />

                  <span
                    style={{
                      fontSize: "14px",
                      color: "#fff",
                      marginLeft: "4px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Special Characters
                  </span>
                </span>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  {/* {
                            this.state.pwdstrength.length
                                ? <img src={Greentick} className={this.props.pwdstatusPng} />
                                : <i className="fa" style={{ fontSize: '20px', color: 'red' }}>&#x2715;</i>
                        } */}
                  <Checkbox
                    style={{ padding: "0px" }}
                    checked={this.state.pwdstrength.length}
                    color="primary"
                    onChange={(e) => e.preventDefault()}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#fff",
                      marginLeft: "4px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Min 8 character length
                  </span>
                </span>
              </div>
              <p className={classes.forgotPassword}>
                <span className={classes.forgotPassword}>
                  Your password strength is{" "}
                </span>
                <Link>
                  <span
                    className={classes.resetLink}
                    style={{
                      color: this.getStrengthColor(),
                      paddingLeft: "5px",
                    }}
                  >
                    {this.passwordStrengthName()}
                  </span>
                </Link>
              </p>
              <br />
              <div style={{ paddingTop: 10 }}></div>
              <Button
                buttonStyle={classes.buttonStyle}
                buttonName={"Submit"}
                fullWidth={true}
                onClick={() => this.validateForm() && this.submitFunction()}
              />
            </form>
          </div>
        </Container>
        <Footer from={"login"} />
      </div>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(withStore(LogIn));
