const Styles = theme => ({        
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  buttonStyle1: {
    border: "1px solid #1891BD",
    borderRadius: 5,
    color: 'white',
    height: 40,
    padding: '0 15px',
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    outline: "none",
    "&:hover": {
      background: "#1891BD"
    },
  },
  serviceDesriptionText: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  serviceDesription: {
    font: "normal normal 600 18px/27px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  serviceBack: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#959595",
    opacity: 1,
  }  
});
export default Styles;