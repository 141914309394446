import React, { Component } from 'react'
import { withStore } from '../../component/hooks/store';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, Container, CssBaseline } from '@material-ui/core';
import Styles from "../../styles/agent/agenrtPlayerSearch";
// import Input from "../../component/input";
import Button from "../../component/button";
import PlayerSearchCard from "../../component/playerSearchCard";
import SelectBox from "../../component/selectBox";
import Config from "../../helper/config";
import Footer from "../manage/footer";
// import BackArrow from "../../images/backArrow.png";
import Header from "./header";
import { toast } from 'react-toastify';
import {
    checkNullAndReturnString, doGetConnectSecurity,
    doPostConnectSecurity, doDeleteConnect, doGetConnect
} from '../../helper/common';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
// import TextInput from 'react-textinput-field';
import BackOption from "../../component/back";

import LabelInput from "../../component/labelInput";
import TablePagination from "@material-ui/core/TablePagination";
import { Multiselect } from 'multiselect-react-dropdown';

class AgenrtPlayerSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            width: 0,
            height: 0,
            playerName: '',
            school: 0,
            position: 0,
            city: '',
            state: '',
            heightMin: '',
            heightMax: '',
            weightMin: '',
            weightMax: '',
            fourtyYdDashMin: '',
            fourtyYdDashMax: '',
            twentyYdDashMin: '',
            twentyYdDashMax: '',
            // sixteenYdDashMin: '',
            sixteenYdDashMax: '',
            coneMin: '',
            coneMax: '',
            broardJumpMin: '',
            broardJumpMax: '',
            verJumpMin: '',
            verJumpMax: '',
            benchPressMin: '',
            benchPressMax: '',
            filter: "normal",
            schoolDetails: [{ label: "school", value: 0 }],
            positionDetails: [{ label: "Position", value: 0 }],
            filterUserDetails: [],
            compareUser: [],
            total: 0,
            selectState: [] ,
            stateList: [],
            sortToggler: false,
            sortBy: "first_name",

            paginations: {
                paginatonpageindex: 0,
                paginationrowsPerPage: 50,
                paginationcountdata: 100
            }
        }
        this.anchorRef = React.createRef();
    }

    handleToggle = () => {
        // setOpen((prevOpen) => !prevOpen);
        this.setState(pre => ({ sortToggler: !pre.sortToggler }))
    };

    handleClose = (event) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }
        this.setState({ sortToggler: false })
    };

    handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            this.setState({ sortToggler: false })
        }
    }

    componentDidMount = () => {
        const dataState = this.props.location.state;
        if (dataState && dataState.routeSearchUserDetail) {
            let dataForPlayerSearch = dataState.routeSearchUserDetail;
            let initialData = { ...this.state };
            if (dataForPlayerSearch.benchPressMax) { initialData.benchPressMax = dataForPlayerSearch.benchPressMax }
            if (dataForPlayerSearch.benchPressMin) { initialData.benchPressMin = dataForPlayerSearch.benchPressMin }
            if (dataForPlayerSearch.broardJumpMax) { initialData.broardJumpMax = dataForPlayerSearch.broardJumpMax }
            if (dataForPlayerSearch.broardJumpMin) { initialData.broardJumpMin = dataForPlayerSearch.broardJumpMin }
            if (dataForPlayerSearch.city) { initialData.city = dataForPlayerSearch.city }
            if (dataForPlayerSearch.compareUser) { initialData.compareUser = dataForPlayerSearch.compareUser }
            if (dataForPlayerSearch.coneMax) { initialData.coneMax = dataForPlayerSearch.coneMax }
            if (dataForPlayerSearch.coneMin) { initialData.coneMin = dataForPlayerSearch.coneMin }
            if (dataForPlayerSearch.filter) { initialData.filter = dataForPlayerSearch.filter }
            if (dataForPlayerSearch.fourtyYdDashMax) { initialData.fourtyYdDashMax = dataForPlayerSearch.fourtyYdDashMax }
            if (dataForPlayerSearch.fourtyYdDashMin) { initialData.fourtyYdDashMin = dataForPlayerSearch.fourtyYdDashMin }
            if (dataForPlayerSearch.heightMax) { initialData.heightMax = dataForPlayerSearch.heightMax }
            if (dataForPlayerSearch.heightMin) { initialData.heightMin = dataForPlayerSearch.heightMin }
            if (dataForPlayerSearch.playerName) { initialData.playerName = dataForPlayerSearch.playerName }
            if (dataForPlayerSearch.position) { initialData.position = dataForPlayerSearch.position }
            if (dataForPlayerSearch.school) { initialData.school = dataForPlayerSearch.school }
            if (dataForPlayerSearch.sixteenYdDashMax) { initialData.sixteenYdDashMax = dataForPlayerSearch.sixteenYdDashMax }
            // if (dataForPlayerSearch.sixteenYdDashMin) { initialData.sixteenYdDashMin = dataForPlayerSearch.sixteenYdDashMin }
            if (dataForPlayerSearch.sortBy) { initialData.sortBy = dataForPlayerSearch.sortBy };
            if (dataForPlayerSearch.sortToggler) { initialData.sortToggler = dataForPlayerSearch.sortToggler };
            if (dataForPlayerSearch.state) { initialData.state = dataForPlayerSearch.state };
            if (dataForPlayerSearch.twentyYdDashMax) { initialData.twentyYdDashMax = dataForPlayerSearch.twentyYdDashMax };
            if (dataForPlayerSearch.twentyYdDashMin) { initialData.twentyYdDashMin = dataForPlayerSearch.twentyYdDashMin };
            if (dataForPlayerSearch.verJumpMax) { initialData.verJumpMax = dataForPlayerSearch.verJumpMax };
            if (dataForPlayerSearch.verJumpMin) { initialData.verJumpMin = dataForPlayerSearch.verJumpMin };
            if (dataForPlayerSearch.weightMax) { initialData.weightMax = dataForPlayerSearch.weightMax };
            if (dataForPlayerSearch.weightMin) { initialData.weightMin = dataForPlayerSearch.weightMin };
            if (dataForPlayerSearch.paginations) { initialData.paginations = dataForPlayerSearch.paginations };
            this.setState({
                ...dataForPlayerSearch
            }, this.filterPlayerDetails);
        } else {
            this.filterPlayerDetails();
        }
        this.getSchoolDetails();
        this.getPositionDetails();

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    async getSchoolDetails() {
        var responseData = await doGetConnect("school")
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data)) {
                var schoolData = [{ label: "School", value: 0 }];
                var responseSchoollData = JSON.parse(JSON.stringify(responseData.data).split('"id":').join('"value":'));
                responseSchoollData = JSON.parse(JSON.stringify(responseSchoollData).split('"school_name":').join('"label":'));
                var schoolList = schoolData.concat(responseSchoollData);
                this.setState({ schoolDetails: schoolList })
            }
        }
        var responseData4 = await doGetConnect("master-data/states")
        if (checkNullAndReturnString(responseData4)) {
            var responseStateData = JSON.parse(JSON.stringify(responseData4).split('"id":').join('"id":'));
            responseStateData = JSON.parse(JSON.stringify(responseStateData).split('"state":').join('"name":'));
            var stateList = responseStateData;
            this.setState({ stateList })
        }
    }

    async getPositionDetails() {
        try {
            var accessToken = localStorage.getItem("accessToken");
            var { data: responseData } = await doGetConnectSecurity("school/position", accessToken);
            var positionList = [{ label: "Position", value: 0 }];
            Object.keys(responseData).forEach(key => {
                positionList.push({
                value: responseData[key].position,
                label: responseData[key].name })
            });
            this.setState({ positionDetails: positionList });
        } catch (err) {
            this.setState({ positionDetails: [] });
        }
    }

    isFloat(val) {
        var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
        if (!floatRegex.test(val))
            return false;

        val = parseFloat(val);
        if (isNaN(val))
            return false;
        return true;
    }

    isInt(val) {
        var intRegex = /^-?\d+$/;
        if (!intRegex.test(val))
            return false;

        var intVal = parseInt(val, 10);
        return parseFloat(val) === intVal && !isNaN(intVal);
    }

    handleChange = (key, value, regex = false) => {
        if (regex) {
            if (value === "") {
                this.setState({ [key]: value }, () => (key === 'sortBy' ?
                    this.filterPlayerDetails() :
                    {}));
            }
            if (key === "weightMax" || key === "weightMin") {
                if (this.isInt(value)) {
                    this.setState({ [key]: value }, () => (key === 'sortBy' ?
                        this.filterPlayerDetails() :
                        {}));
                }
            }
            else {
                if (this.isFloat(value) || this.isInt(value)) {
                    this.setState({ [key]: value }, () => (key === 'sortBy' ?
                        this.filterPlayerDetails() :
                        {}));
                }
            }
            return false;
        }

        this.setState({ [key]: value }, () => (key === 'sortBy' ?
            this.filterPlayerDetails() :
            {}));
    }

    clearStateValue = () => {
        this.setState({
            playerName: '', school: 0, position: 0, city: '', state: '', heightMin: '', heightMax: '',
            weightMin: '', weightMax: '', fourtyYdDashMin: '', fourtyYdDashMax: '', twentyYdDashMin: '',
            twentyYdDashMax: '',  sixteenYdDashMax: '', coneMin: '', coneMax: '',
            broardJumpMin: '', broardJumpMax: '', verJumpMin: '', verJumpMax: '', benchPressMin: '',
            benchPressMax: '', filter: "normal", selectState: [],
        }, () => {
            this.filterPlayerDetails();
        })
    }

    filterPlayerDetails = async () => {
        try {
            var position = this.state.position;
            var school = this.state.school;
            if (Number(school) === 0) {
                school = ""
            }
            if (Number(position) === 0) {
                position = ""
            }
            var accessToken = localStorage.getItem("accessToken");
            const { paginatonpageindex, paginationrowsPerPage } = this.state.paginations;
            const pagefrom = paginationrowsPerPage * paginatonpageindex;
            const pageto = paginationrowsPerPage;

            var userFilterList = await doGetConnectSecurity(`player?playerName=${this.state.playerName}&school=${school}&position=${position}&city=${this.state.city}&state=${this.state.state}&heightMin=${this.state.heightMin}&heightMax=${this.state.heightMax}&weightMin=${this.state.weightMin}&weightMax=${this.state.weightMax}&fourtyYardDashMin=${this.state.fourtyYdDashMin}&fourtyYardDashMax=${this.state.fourtyYdDashMax}&twentyYardShuttleMin=${this.state.twentyYdDashMin}&twentyYardShuttleMax=${this.state.twentyYdDashMax}&sixtYardShuttleMax=${this.state.sixteenYdDashMax}&threeCoreDrillMin=${this.state.coneMin}&threeCoreDrillMax=${this.state.coneMax}&broadJumpMin=${this.state.broardJumpMin}&broadJumpMax=${this.state.broardJumpMax}&verticalJumpMin=${this.state.verJumpMin}&verticalJumpMax=${this.state.verJumpMax}&benchPressMin=${this.state.benchPressMin}&benchPressMax=${this.state.benchPressMax}&academicYear=&sortby=${this.state.sortBy}&order=asc&skip=${pagefrom}&take=${pageto}`, accessToken);
            let paginationssetdata = {
                paginatonpageindex,
                paginationrowsPerPage,
                paginationcountdata: Number(userFilterList.total)
            };

            console.log({
                userFilterList
            })

            this.setState({
                filterUserDetails: (userFilterList.data || []),
                total: userFilterList.total,
                paginations: paginationssetdata
            });
        } catch (err) {
            toast.error('Error while getting position details')
            this.setState({ filterUserDetails: [], total: 0 });
        }
    }


    makeUserFavorite = async (id) => {
        try {
            var accessToken = localStorage.getItem("accessToken");
            var req = await doPostConnectSecurity(
                `player-favorite/${id}`,
                {},
                accessToken);
            if (req.status === 200) {
                this.filterPlayerDetails();
            }
        } catch (err) {
            toast.error(err && err.message ? err.message : 'Error while updating favorite detail')
        }
    }

    makeUserUnFavorite = async (id) => {
        if (!id) {
            return
        }
        try {
            var accessToken = localStorage.getItem("accessToken");
            var res = await doDeleteConnect(`player-favorite/${id}`, accessToken);
            if (res.status === 200) {
                this.filterPlayerDetails()
            }
        } catch (err) {
            toast.error(err && err.message ? err.message : 'Error while removing favorite player')
            // this.setState({ favoriteLists: [] });
        }
    }

    makeCompareUser = async (user) => {
        if (!this.state.compareUser[0]) {
            this.setState((pre) => {
                return {
                    ...pre,
                    compareUser: [...pre.compareUser, user]
                }
            })
        } else if (!this.state.compareUser[1]) {
            const user1data = { ...this.state.compareUser[0] };
            const user2data = { ...user };

            if (user1data.id === user2data.id) {
                toast.error("Player already added to compare tab. Please select another player");
                return
            }
            if (!Object.is(user1data.player_position, user2data.player_position)) {
                return toast.error(`Select another player in ${user1data.player_position} position`)
            }
            this.setState((pre) => {
                return {
                    ...pre,
                    compareUser: [...pre.compareUser, user]
                }
            })
        }
    }


    makeComparisonOfSelectedUser = () => {
        const compareUsers = [...this.state.compareUser];
        if (compareUsers.length !== 2) {
            toast.error('Select another player for comparison');
            return
        }

        const details = { ...this.state };
        if (details.width) { delete details.width };
        if (details.height) { delete details.height };
        if (details.positionDetails) { delete details.positionDetails };
        if (details.schoolDetails) { delete details.schoolDetails };
        if (details.filterUserDetails) { delete details.filterUserDetails };

        this.props.history.push({
            pathname: Config.reactUrl + `/agent/agentcompareplayer/${compareUsers[0].id}/${compareUsers[1].id}`,
            state: { playerSearchDetail: details }
        })
    }

    sortByName(sortBy) {
        let sortByName = 'Overall Rating';
        if (sortBy === 'id') {
            sortByName = 'Overall Rating'
        } else if (sortBy === 'first_name') {
            sortByName = 'Name'
        } else if (sortBy === 'city') {
            sortByName = 'Location'
        } else if (sortBy === 'height_feet') {
            sortByName = 'Height'
        } else if (sortBy === 'weight') {
            sortByName = 'Weight'
        };
        return sortByName;
    }


    clearUsers = async (user) => {
        this.setState((pre) => ({
            compareUser: (user instanceof Array ? [] : pre.compareUser.filter(e => e.id !== user.id))
        }))
    }

    handleChangePage = async (event, newPage) => {
        const paginationvalue = { ...this.state.paginations, paginatonpageindex: newPage };
        this.setState({
            paginations: { ...paginationvalue }
        }, this.filterPlayerDetails)
    }

    handleChangeRowsPerPage = async (event) => {
        const paginationvalue = { ...this.state.paginations, paginationrowsPerPage: event.target.value, paginatonpageindex: 0 };
        this.setState({
            paginations: { ...paginationvalue }
        }, this.filterPlayerDetails)
    }

    render() {
        const { classes } = this.props;
        var { filter, schoolDetails, /* compareUser, */ sortBy, playerName, city,
            /* state */ } = this.state;

        return (
            <>
                <CssBaseline />
                <Header
                    compareUserDetails={this.state.compareUser || []}
                    clearUsers={(user) => this.clearUsers(user)}
                    makeComparisonOfSelectedUser={this.makeComparisonOfSelectedUser} />
                <Container maxWidth="xl" component="main" className={classes.heroContent}>
                    <div className="container-fluid" style={{ paddingBottom: 120, paddingTop: 50 }}>
                        <div class="row rowView" >
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding" >
                                <BackOption history={this.props.history} linkTo={Config.reactUrl + "/agent/dashboard"} />
                            </div>
                        </div>
                        <div class="row rowView" >
                            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 noPadding" >
                                <div style={{ paddingTop: 20 }}></div>
                                <span className={classes.searchPlayerText}>Search Players</span>
                                <div style={{ paddingTop: 20 }}></div>
                                <div class={filter === "normal" ? "" : "heightScrollSearchPlater"} >
                                    {/* <Input
                                            onChange={this.handleChange}
                                            value={this.state.playerName}
                                            keyName={"playerName"}
                                            placeHolder={"Player Name"}
                                            extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                        /> */}
                                    <LabelInput disabled={false}
                                        updateInputValue={this.handleChange}
                                        name={"playerName"} label={"Player Name"}
                                        value={playerName} />
                                    <div style={{ paddingTop: 10 }}></div>
                                    <SelectBox onChange={this.handleChange}
                                        keyName={"school"}
                                        options={schoolDetails}
                                        value={this.state.school} />
                                    <div style={{ paddingTop: 10 }}></div>
                                    <SelectBox onChange={this.handleChange} keyName={"position"}
                                        options={this.state.positionDetails}
                                        value={this.state.position} />
                                    <div style={{ width: "100%", display: "inline-flex", paddingTop: 10 }}>
                                        <div style={{ width: "48%" }}>
                                            {/* <Input onChange={this.handleChange}
                                                    value={this.state.city}
                                                    keyName={"city"}
                                                    extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                    placeHolder={"City"} /> */}
                                            <LabelInput disabled={false}
                                                updateInputValue={this.handleChange}
                                                name={"city"} label={"City"}
                                                value={city} />
                                        </div>
                                        <div style={{ width: "4%" }}></div>
                                        <div className={this.state.state ? "singleSelectAgentSerachColorSelect singleSelectAgentSerachColor" : "singleSelectAgentSerachColor"} style={{ width: "48%" }}>
                                            {/* <Input onChange={this.handleChange}
                                                    value={this.state.state}
                                                    keyName={"state"}
                                                    extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                    placeHolder={"State"} /> */}
                                            {/* <LabelInput disabled={false}
                                                updateInputValue={this.handleChange}
                                                name={"state"} label={"State"}
                                                value={this.state.state} /> */}
                                            <Multiselect
                                                style={{display: "flex"}}
                                                // singleSelect={true}                                                
                                                options={this.state.stateList} // Options to display in the dropdown
                                                selectedValues={this.state.selectState} // Preselected value to persist in dropdown
                                                onSelect={(e) => {
                                                    this.setState({ selectState : [e[e.length - 1]], state: e[e.length - 1].name })
                                                }} // Function will trigger on select event
                                                placeholder={this.state.state ? "" : "State"}
                                                onRemove={(e) => {
                                                    this.setState({ selectState : [], state: '' })
                                                }} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: "100%", display: "inline-flex", paddingTop: 10 }}>
                                        <div style={{ width: "48%" }}>
                                            {/* <Input onChange=
                                                    {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                    value={this.state.heightMin}
                                                    keyName={"heightMin"}
                                                    extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                    placeHolder={"Height(Min)"} /> */}
                                            <LabelInput disabled={false}
                                                updateInputValue=
                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                name={"heightMin"} label={"Height(Min)"}
                                                value={this.state.heightMin} />

                                        </div>
                                        <div style={{ width: "4%" }}></div>
                                        <div style={{ width: "48%" }}>
                                            {/* <Input onChange=
                                                    {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                    value={this.state.heightMax}
                                                    keyName={"heightMax"}
                                                    extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                    placeHolder={"Height(Max)"} /> */}
                                            <LabelInput disabled={false}
                                                updateInputValue=
                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                name={"heightMax"} label={"Height(Max)"}
                                                value={this.state.heightMax} />
                                        </div>
                                    </div>
                                    <div style={{ width: "100%", display: "inline-flex", paddingTop: 10 }}>
                                        <div style={{ width: "48%" }}>
                                            {/* <Input onChange=
                                                    {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                    value={this.state.weightMin}
                                                    keyName={"weightMin"}
                                                    extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                    placeHolder={"Weight(Min)"} /> */}
                                            <LabelInput disabled={false}
                                                updateInputValue=
                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                name={"weightMin"} label={"Weight(Min)"}
                                                value={this.state.weightMin} />
                                        </div>
                                        <div style={{ width: "4%" }}></div>
                                        <div style={{ width: "48%" }}>
                                            {/* <Input onChange=
                                                    {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                    value={this.state.weightMax}
                                                    keyName={"weightMax"}
                                                    extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                    placeHolder={"Weight(Max)"} /> */}
                                            <LabelInput disabled={false}
                                                updateInputValue=
                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                name={"weightMax"} label={"Weight(Max)"}
                                                value={this.state.weightMax} />
                                        </div>
                                    </div>
                                    {
                                        filter !== "normal" ?
                                            <span>
                                                <div style={{ width: "100%", display: "inline-flex", paddingTop: 10 }}>
                                                    <div style={{ width: "48%" }}>
                                                        {/* <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.fourtyYdDashMin}
                                                                keyName={"fourtyYdDashMin"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"40YD Dash(Min)"} /> */}
                                                        <LabelInput disabled={false}
                                                            updateInputValue=
                                                            {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"fourtyYdDashMin"} label={"40YD Dash(Min)"}
                                                            value={this.state.fourtyYdDashMin} />
                                                    </div>
                                                    <div style={{ width: "4%" }}></div>
                                                    <div style={{ width: "48%" }}>
                                                        {/* <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.fourtyYdDashMax}
                                                                keyName={"fourtyYdDashMax"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"40YD Dash(Max)"} /> */}
                                                        <LabelInput disabled={false}
                                                            updateInputValue=
                                                            {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"fourtyYdDashMax"} label={"40YD Dash(Max)"}
                                                            value={this.state.fourtyYdDashMax} />
                                                    </div>
                                                </div>
                                                <div style={{ width: "100%", display: "inline-flex", paddingTop: 10 }}>
                                                    <div style={{ width: "48%" }}>
                                                        {/* <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.twentyYdDashMin}
                                                                keyName={"twentyYdDashMin"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"20YD Dash(Min)"} /> */}
                                                        <LabelInput disabled={false}
                                                            updateInputValue=
                                                            {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"twentyYdDashMin"} label={"20YD Dash(Min)"}
                                                            value={this.state.twentyYdDashMin} />
                                                    </div>
                                                    <div style={{ width: "4%" }}></div>
                                                    <div style={{ width: "48%" }}>
                                                        {/* <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.twentyYdDashMax}
                                                                keyName={"twentyYdDashMax"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"20YD Dash(Max)"} /> */}
                                                        <LabelInput disabled={false}
                                                            updateInputValue=
                                                            {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"twentyYdDashMax"} label={"20YD Dash(Max)"}
                                                            value={this.state.twentyYdDashMax} />
                                                    </div>
                                                </div>
                                                {/* <div style={{ width: "100%", display: "inline-flex", paddingTop: 10 }}>
                                                    <div style={{ width: "48%" }}>
                                                        <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.sixteenYdDashMin}
                                                                keyName={"sixteenYdDashMin"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"60YD Dash(Min)"} />
                                                        <LabelInput disabled={false}
                                                            updateInputValue=
                                                            {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"sixteenYdDashMin"} label={"60YD Dash(Min)"}
                                                            value={this.state.sixteenYdDashMin} />
                                                    </div>
                                                    <div style={{ width: "4%" }}></div>
                                                    <div style={{ width: "48%" }}>
                                                        <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.sixteenYdDashMax}
                                                                keyName={"sixteenYdDashMax"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"60YD Dash(Max)"} />
                                                        <LabelInput disabled={false}
                                                            updateInputValue=
                                                            {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"sixteenYdDashMax"} label={"60YD Dash(Max)"}
                                                            value={this.state.sixteenYdDashMax} />

                                                    </div>
                                                </div> */}
                                                <div style={{ width: "100%", display: "inline-flex", paddingTop: 10 }}>
                                                    <div style={{ width: "48%" }}>
                                                        {/* <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.coneMin}
                                                                keyName={"coneMin"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"3 Cone(Min)"} /> */}
                                                        <LabelInput disabled={false}
                                                            updateInputValue=
                                                            {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"coneMin"} label={"3 Cone(Min)"}
                                                            value={this.state.coneMin} />
                                                    </div>
                                                    <div style={{ width: "4%" }}></div>
                                                    <div style={{ width: "48%" }}>
                                                        {/* <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.coneMax}
                                                                keyName={"coneMax"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"3 Cone(Max)"} /> */}
                                                        <LabelInput disabled={false}
                                                            updateInputValue=
                                                            {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"coneMax"} label={"3 Cone(Max)"}
                                                            value={this.state.coneMax} />
                                                    </div>
                                                </div>
                                                <div style={{ width: "100%", display: "inline-flex", paddingTop: 10 }}>
                                                    <div style={{ width: "48%" }}>
                                                        {/* <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.broardJumpMin}
                                                                keyName={"broardJumpMin"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"Broad Jump(Min)"} /> */}
                                                        <LabelInput disabled={false}
                                                            updateInputValue=
                                                            {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"broardJumpMin"} label={"Broad Jump(Min)"}
                                                            value={this.state.broardJumpMin} />

                                                    </div>
                                                    <div style={{ width: "4%" }}></div>
                                                    <div style={{ width: "48%" }}>
                                                        {/* <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.broardJumpMax}
                                                                keyName={"broardJumpMax"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"Broad Jump(Max)"} /> */}
                                                        <LabelInput disabled={false}
                                                            updateInputValue=
                                                            {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"broardJumpMax"} label={"Broad Jump(Max)"}
                                                            value={this.state.broardJumpMax} />
                                                    </div>
                                                </div>
                                                <div style={{ width: "100%", display: "inline-flex", paddingTop: 10 }}>
                                                    <div style={{ width: "48%" }}>
                                                        {/* <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.verJumpMin}
                                                                keyName={"verJumpMin"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"Ver. Jump(Min)"} /> */}
                                                        <LabelInput disabled={false}
                                                            updateInputValue=
                                                            {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"verJumpMin"} label={"Ver. Jump(Min)"}
                                                            value={this.state.verJumpMin} />
                                                    </div>
                                                    <div style={{ width: "4%" }}></div>
                                                    <div style={{ width: "48%" }}>
                                                        {/* <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.verJumpMax}
                                                                keyName={"verJumpMax"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"Ver. Jump(Max)"} /> */}
                                                        <LabelInput disabled={false}
                                                            updateInputValue=
                                                            {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"verJumpMax"} label={"Ver. Jump(Max)"}
                                                            value={this.state.verJumpMax} />

                                                    </div>
                                                </div>
                                                <div style={{ width: "100%", display: "inline-flex", paddingTop: 10 }}>
                                                    <div style={{ width: "48%" }}>
                                                        {/* <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.benchPressMin}
                                                                keyName={"benchPressMin"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"Bench Press(Min)"} /> */}
                                                        <LabelInput disabled={false}
                                                            updateInputValue={(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"benchPressMin"} label={"Bench Press(Min)"}
                                                            value={this.state.benchPressMin} />
                                                    </div>
                                                    <div style={{ width: "4%" }}></div>
                                                    <div style={{ width: "48%" }}>
                                                        {/* <Input onChange=
                                                                {(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                                value={this.state.benchPressMax}
                                                                keyName={"benchPressMax"}
                                                                extraStyle={{ font: "italic normal 300 12px/18px Poppins" }}
                                                                placeHolder={"Bench Press(Max)"} /> */}
                                                        <LabelInput disabled={false}
                                                            updateInputValue={(keyname, value) => this.handleChange(keyname, value, /[^\d]+/gi)}
                                                            name={"benchPressMax"} label={"Bench Press(Max)"}
                                                            value={this.state.benchPressMax} />
                                                    </div>
                                                </div>
                                            </span>
                                            :
                                            null
                                    }
                                    {/* <div style={{ width: "100%", display: "inline-flex", paddingTop: 10 }}>
                                        <span className={classes.mediaText}>Media Requirement</span>
                                    </div> */}
                                </div>
                                <div style={{ textAlign: "center", paddingTop: 20 }}>
                                    <a href="#/" className={classes.filterText} onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ filter: filter === "normal" ? "advanced" : "normal" })
                                    }}>
                                        {
                                            filter === "normal" ? "ADVANCED FILTER" : "BASIC FILTER"
                                        }
                                    </a>
                                    <div style={{ paddingTop: 20 }}>
                                        <Button buttonStyle={classes.buttonStyle1} buttonName={"Clear"}
                                            fullWidth={false} onClick={() => {
                                                this.clearStateValue();
                                            }} />
                                        <span style={{ paddingRight: 20 }}></span>
                                        <Button buttonStyle={classes.buttonStyle} buttonName={"Search"}
                                            fullWidth={false} onClick={() => {
                                                this.filterPlayerDetails();
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 " style={{ padding: 10, paddingTop: 30 }}>
                                {
                                    this.state.width > 992 ?
                                        <span style={{ position: "absolute", left: 15, marginTop: 30, width: 3, height: this.state.height / 1.3, background: "#3B3E50 0% 0% no-repeat padding-box" }}> </span>
                                        :
                                        ""
                                }
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={"searchPlayerResponseClass1"}>
                                        <span className={classes.resultText}>Showing {this.state.total} Results</span>
                                        <span className={classes.sortByText}>
                                            Sort by
                                            <span
                                                ref={this.anchorRef}
                                                onClick={this.handleToggle}
                                                style={{ color: "#1891BD", paddingLeft: 10, cursor: 'pointer' }}>
                                                {this.sortByName(sortBy)}
                                            </span>
                                            <Popper open={this.state.sortToggler}
                                                anchorEl={this.anchorRef.current}
                                                className={classes.Popper}
                                                role={undefined} transition disablePortal>
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{
                                                            transformOrigin: placement === 'bottom' ?
                                                                'center top' : 'center bottom'
                                                        }}
                                                    >
                                                        <Paper>
                                                            <ClickAwayListener
                                                                onClickAway={(e) => this.handleClose(e)}>
                                                                <MenuList
                                                                    autoFocusItem={this.state.sortToggler}
                                                                    className={classes.PopColor}
                                                                    id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                                                    <MenuItem onClick={(e) => {
                                                                        this.handleChange('sortBy', 'id', false);
                                                                        this.handleClose(e);
                                                                    }}>Overall Rating</MenuItem>
                                                                    <MenuItem onClick={(e) => {
                                                                        this.handleChange('sortBy', 'first_name', false);
                                                                        this.handleClose(e);
                                                                    }}>Name</MenuItem>
                                                                    <MenuItem onClick={(e) => {
                                                                        this.handleChange('sortBy', 'city', false);
                                                                        this.handleClose(e)
                                                                    }}>Location</MenuItem>
                                                                    <MenuItem onClick={(e) => {
                                                                        this.handleChange('sortBy', 'height_feet', false);
                                                                        this.handleClose(e);
                                                                    }}>Height</MenuItem>
                                                                    <MenuItem onClick={(e) => {
                                                                        this.handleChange('sortBy', 'weight', false);
                                                                        this.handleClose(e)
                                                                    }}>Weight</MenuItem>
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        </span>
                                    </div>

                                    <div className="row searchPlayerResponseClass1" style={{ paddingTop: 10, height: Object.keys(this.state.filterUserDetails).length > 9 ? this.state.height / 1.3 : "", overflow: "hidden", overflowY: "scroll" }}>
                                        {
                                            this.state.filterUserDetails.length
                                                ?
                                                (
                                                    checkNullAndReturnString(this.state.filterUserDetails) ?
                                                        this.state.filterUserDetails.map((ele, ind) => (
                                                            <React.Fragment key={ind}>
                                                                <PlayerSearchCard {...ele}
                                                                    percentage={"78%"} colorCode={2}
                                                                    makeUserFavorite={this.makeUserFavorite}
                                                                    makeUserUnFavorite={this.makeUserUnFavorite}
                                                                    makeCompareUser={(user) => {
                                                                        this.makeCompareUser(user)
                                                                    }}
                                                                    compareUserPageDetail={{
                                                                        ...this.state
                                                                    }}
                                                                    {...this.props}
                                                                />
                                                            </React.Fragment>
                                                        ))
                                                        :
                                                        ""
                                                )
                                                :
                                                (
                                                    <div class="norecordfound">
                                                        <div>No records found</div>
                                                    </div>
                                                )
                                        }
                                    </div>
                                    <div>
                                        <TablePagination
                                            style={{ color: 'white' }}
                                            component="div"
                                            count={this.state.paginations.paginationcountdata}
                                            page={this.state.paginations.paginatonpageindex}
                                            onChangePage={this.handleChangePage}
                                            rowsPerPage={this.state.paginations.paginationrowsPerPage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            rowsPerPageOptions={[50, 100, 150, 200]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <Footer />
            </>
        )
    }
};


export default withStyles(Styles, { withTheme: true })(withStore(AgenrtPlayerSearch));