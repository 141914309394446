import React, { Component } from 'react'
import { withStore } from '../../component/hooks/store';
// import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Footer from "../manage/footer";
import Header from "./header";
import Styles from "../../styles/uaAdmin/dashboard";
import { Container, CssBaseline } from "@material-ui/core";
import Tab from "../../component/tab";
import EnrolledPlayers from "./enrolledPlayers";
import UploadWorkoutPerformence from "./uploadWorkoutPerformence";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      tableData: [],
    };
  }

  tabOnClick = (index) => {
    this.setState({ selected: index });
  };

  render() {
    var { classes } = this.props;
    var { selected } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <Header history={this.props.history} />
        <Container maxWidth="xl" component="main" className={classes.heroContent}>
          <div className="container-fluid" style={{ paddingBottom: 120, paddingTop: 30 }}>
            <div className={"row rowView"}>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                <Tab json={["Enrolled Players", "Drill Performance"]} selected={selected} tabOnClick={this.tabOnClick} />
              </div>
            </div>
            {
              Number(selected) === 0 ?
                <EnrolledPlayers />
                :
                Number(selected) === 1 ?
                  <UploadWorkoutPerformence />
                  :
                  ""
            }
          </div>
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}
export default withStyles(Styles, { withTheme: true })(withStore(Dashboard));
