import React from "react";
import Swiper from "react-id-swiper";
import {
  checkNullAndReturnString,
  doDeleteConnect,
  doPutConnectSecurity,
} from "../helper/common";
import Config from "../helper/config";
import LeftArrow from "../images/left-arrow.png";
import RightArrow from "../images/right-arrow.png";
import SearchPng from "../images/search.png";
import ClosePng from "../images/close.png";
import Vimeo from "@u-wave/react-vimeo";
import { Modal } from "react-responsive-modal";
import Videoplayer from "../images/videoplayer.png";
import Button from "../component/button";
import { toast } from "react-toastify";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverId: "",
      modal: false,
      modalType: "",
      modalId: "",
      modalKey: "",
      confirmModal: false,
      confirmType: "",
    };
    this.swiperRef = React.createRef();
    this.thumpnailRef = React.createRef();
    // call it again if items count changes
  }

  removeProfilePsge = async () => {
    var accessToken = localStorage.getItem("accessToken");
    var postHightLightData = {
      highlight: false,
    };
    var responseData = await doPutConnectSecurity(
      "gallery/highlights/" + this.state.modalId,
      postHightLightData,
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (
        checkNullAndReturnString(responseData.message) &&
        responseData.message === "Success"
      ) {
        toast.success(
          "This media has been removed from highlights successfully"
        );
      } else {
        if (checkNullAndReturnString(responseData.error)) {
          if (checkNullAndReturnString(responseData.error.message)) {
            toast.error(responseData.error.message);
          }
        }
      }
      this.props.getHighlightsFile();
      this.onCloseModal();
    }
  };

  deleteMedia = async () => {
    var accessToken = localStorage.getItem("accessToken");
    var responseData = await doDeleteConnect(
      "aws-files/remove/" + this.state.modalId,
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.message)) {
        toast.success(responseData.message);
        this.props.getHighlightsFile();
        this.onCloseModal();
      } else {
        if (checkNullAndReturnString(responseData.error)) {
          if (checkNullAndReturnString(responseData.error.message)) {
            toast.error(responseData.error.message);
          }
        }
      }
    }
  };

  onOpenModal = () => {
    this.setState({ modal: true });
  };

  onCloseModal = () => {
    this.setState({ modal: false });
  };

  goNext = () => {
    if (this.swiperRef.current && this.swiperRef.current.swiper) {
      this.swiperRef.current.swiper.slideNext();
    }
  };

  onMouseover = (e, galleryType) => {
    this.setState({ hoverId: e });
  };

  onMouseout = (e) => {
    this.setState({ hoverId: "" });
  };

  goPrev = () => {
    if (this.swiperRef.current && this.swiperRef.current.swiper) {
      this.swiperRef.current.swiper.slidePrev();
    }
  };

  render() {
    var highlightsData = this.props.highlightsData;
    var data = [];
    if (checkNullAndReturnString(highlightsData)) {
      Object.keys(highlightsData).forEach((key) => {
        var item = highlightsData[key];
        if (item.type_of_file === "video") {
          data.push(
            <div style={{ padding: 3 }}>
              <div style={{ width: "100%", height: 200 }}>
                <img
                  draggable="false"
                  src={
                    item.video_thumbnail
                      ? item.video_thumbnail
                      : "https://i.vimeocdn.com/video/default_1920x1080?r=pad"
                  }
                  alt=""
                  style={{
                    width: "100%",
                    height: 200,
                    borderRadius: 10,
                    objectFit: "fill",
                  }}
                  className={
                    item.status === "approved"
                      ? "videoPlayerHover"
                      : "videoPlayerHover1"
                  }
                />
              </div>
              {item?.title && (
                <div
                  style={{
                    color: "white",
                    position: "absolute",
                    top: "3%",
                    left: "3%",
                    fontSize: 12,
                    opacity: 1,
                  }}
                >
                  {item?.title}
                </div>
              )}
              {item.status === "approved" ? (
                <a
                  href="#/"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      modalType: item.type_of_file,
                      modalId: item.id,
                      modalKey: item.key,
                    });
                    this.onOpenModal();
                  }}
                >
                  <img
                    src={Videoplayer}
                    alt=""
                    style={{ width: 50, height: 50, position: "relative" }}
                  />
                </a>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    color: "#1891BD",
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <div
                      className={"videoPlayerwaitingHover"}
                      style={{
                        width: 30,
                        display: "flex",
                        height: 30,
                        border: "1px solid #1891BD",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        borderRadius: 20,
                      }}
                    >
                      !
                    </div>
                  </div>
                  <div
                    className={"videoPlayerwaitingHoverText"}
                    style={{ paddingTop: 8 }}
                  >
                    Waiting for Approval
                  </div>
                </div>
              )}
            </div>
          );
        } else {
          data.push(
            <div style={{ padding: 3 }}>
              <div
                style={{
                  width: 250,
                  height: 200,
                  opacity:
                    (this.state.hoverId && this.state.hoverId.toString()) ===
                    (item.id && item.id.toString())
                      ? 0.2
                      : 1,
                }}
                onMouseEnter={() =>
                  item.status === "approved" &&
                  this.onMouseover(item.id, "highlight")
                }
                onMouseLeave={() =>
                  item.status === "approved" && this.onMouseout(item.id)
                }
              >
                <img
                  draggable="false"
                  src={Config.dataUrl + "aws-files/" + item.id}
                  alt=""
                  style={{
                    width: "100%",
                    height: 200,
                    borderRadius: 10,
                    objectFit: "fill",
                  }}
                  className={
                    item.status === "approved"
                      ? "videoPlayerHover"
                      : "videoPlayerHover1"
                  }
                />
              </div>
              {item?.title && (
                <div
                  style={{
                    color: "white",
                    position: "absolute",
                    top: "3%",
                    left: "3%",
                    fontSize: 12,
                    opacity: 1,
                  }}
                >
                  {item?.title}
                </div>
              )}
              {item.status !== "approved" && (
                <div
                  style={{
                    textAlign: "center",
                    color: "#1891BD",
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <div
                      className={"videoPlayerwaitingHover"}
                      style={{
                        width: 30,
                        display: "flex",
                        height: 30,
                        border: "1px solid #1891BD",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        borderRadius: 20,
                      }}
                    >
                      !
                    </div>
                  </div>
                  <div
                    className={"videoPlayerwaitingHoverText"}
                    style={{ paddingTop: 8 }}
                  >
                    Waiting for Approval
                  </div>
                </div>
              )}
              {(this.state.hoverId && this.state.hoverId.toString()) ===
              (item.id && item.id.toString()) ? (
                <a
                  href="#/"
                  style={{ position: "absolute", bottom: "50%", left: "45%" }}
                  onMouseEnter={() => this.onMouseover(item.id, "highlight")}
                  onMouseLeave={() => this.onMouseout(item.id)}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      modalType: item.type_of_file,
                      modalId: item.id,
                      modalKey: "",
                    });
                    this.onOpenModal();
                  }}
                >
                  <img
                    src={SearchPng}
                    style={{ width: 22, height: 21 }}
                    alt=""
                  />
                </a>
              ) : (
                ""
              )}
            </div>
          );
        }
      });
    }

    const params = {
      ContainerEl: "section",
      WrapperEl: "section",
      navigation: {
        pagination: ".swiper-pagination",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: (index, className) => {
          return (
            '<span class="' +
            className +
            '" style="color:red">' +
            (index + 1) +
            "</span>"
          );
        },
      },
      breakpoints: {
        1500: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        420: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    };

    return (
      <div>
        <Swiper {...params} ref={this.swiperRef}>
          {data}
        </Swiper>
        <span
          onClick={this.goPrev}
          className="swiper-button-prev"
          style={{
            backgroundImage: "url(" + LeftArrow + ")",
            backgroundSize: 25,
          }}
        ></span>
        <span
          onClick={this.goNext}
          className="swiper-button-next"
          style={{
            backgroundImage: "url(" + RightArrow + ")",
            backgroundSize: 25,
          }}
        ></span>
        <Modal
          open={this.state.modal}
          onClose={() => this.onCloseModal()}
          center
          showCloseIcon={false}
        >
          <div style={{ padding: 10 }}>
            <a
              href="#/"
              style={{
                outline: "none",
                width: 22,
                height: 22,
                textAlign: "center",
                position: "absolute",
                right: 15,
                top: 15,
                background: "#fff",
                borderRadius: 22 / 2,
              }}
              onClick={(e) => {
                e.preventDefault();
                this.onCloseModal();
              }}
            >
              <img src={ClosePng} style={{ width: 10, height: 10 }} alt="" />
            </a>
            {this.state.modalType === "image" ? (
              <img
                src={Config.dataUrl + "aws-files/" + this.state.modalId}
                alt=""
                className={"img-responsive"}
                style={{ width: 580, height: 387 }}
              />
            ) : (
              <Vimeo
                video={this.state.modalKey}
                // autoplay
              />
            )}
          </div>
          {!this.props.removeStatus && (
            <p style={{ float: "right", paddingRight: 10, paddingLeft: 10 }}>
              {!this.state.highlightModal ? (
                <Button
                  buttonStyle={this.props.classes.removeButtonStyle}
                  className
                  buttonName={"Permanently Delete"}
                  fullWidth={false}
                  onClick={() => {
                    this.setState({
                      confirmModal: true,
                      confirmType: "delete",
                    });
                  }}
                />
              ) : (
                ""
              )}
              &nbsp;&nbsp;&nbsp;
              <Button
                buttonStyle={this.props.classes.deleteButtonStyle}
                buttonName={"Remove From Profile Page"}
                fullWidth={false}
                onClick={() => {
                  this.setState({ confirmModal: true, confirmType: "remove" });
                }}
              />
            </p>
          )}
        </Modal>
        <Modal
          open={this.state.confirmModal}
          onClose={() => {
            this.setState({ confirmModal: false, confirmType: "" });
          }}
          center
          showCloseIcon={false}
        >
          <div style={{ padding: 20 }}>
            {this.state.confirmType === "delete" ? (
              <p
                style={{
                  font: "normal normal 600 18px/27px Poppins",
                  opacity: 1,
                }}
              >
                Permanently Delete
              </p>
            ) : (
              <p
                style={{
                  font: "normal normal 600 18px/27px Poppins",
                  opacity: 1,
                }}
              >
                Remove From Profile Page
              </p>
            )}
            <p
              style={{
                font: "normal normal normal 14px/21px Poppins",
                opacity: 1,
              }}
            >
              {this.state.confirmType === "delete"
                ? "Are you sure you want to permanently delete this media from your account?"
                : "Media removed from your profile page will still be available under profile editing for future use. Are you sure you want to remove this media from your main profile page?"}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <Button
                  buttonStyle={this.props.classes.removeButtonStyle}
                  buttonName={"No"}
                  fullWidth={false}
                  onClick={() => {
                    this.setState({ confirmModal: false, confirmType: "" });
                  }}
                />
                <span style={{ paddingRight: 20 }}></span>
                <Button
                  buttonStyle={this.props.classes.deleteButtonStyle}
                  buttonName={"Yes"}
                  fullWidth={false}
                  onClick={() => {
                    if (this.state.confirmType === "delete") {
                      this.deleteMedia();
                    } else {
                      this.removeProfilePsge();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default Gallery;
