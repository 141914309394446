import React, { Component } from 'react'
import { withStore } from '../../component/hooks/store';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Styles from "../../styles/admin/players";
import Button from "../../component/button";
import Tables from "../../component/table";
import { checkNullAndReturnString, doGetConnectSecurity, doGetConnect, doPutConnectSecurity } from '../../helper/common';
import SelectBox from "../../component/selectBox";
// import Input from "../../component/input";
import { Multiselect } from 'multiselect-react-dropdown';
import { toast } from 'react-toastify';
import Config from '../../helper/config';
import LabelInput from "../../component/labelInput";
// import Dialog from '@material-ui/core/Dialog';
import { Modal } from 'react-responsive-modal';
import TablePagination from "@material-ui/core/TablePagination";
import PhoneInput from 'react-phone-input-2';

class ViewPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            list: [],
            total: 0,
            school: 0,
            position: 0,
            university: 0,
            year: 0,
            playerName: "",
            selectSchool: [],
            schoolList: [{ label: "School", value: 0 }],
            positionList: [{ label: "Position", value: 0 }],
            academicYear: [{ label: "Academic Year", value: 0 }],
            rejectedTotal: 0,
            approveTotal: 0,
            dialogdata: {
                open: false,
                data: null
            },
            sortByKey: 'full_name',
            sortByOrder: true,
            paginations: {
                paginatonpageindex: 0,
                paginationrowsPerPage: 50,
                paginationcountdata: 0
            }
        }
    }

    componentDidMount = async () => {
        this.getPlayerList()
        this.getAcademicYear();
        this.getSchoolAndPosition();
        this.updateWindowDimensions();
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    getAcademicYear = async () => {
        var academicYear = [{ label: "Academic Year", value: 0 }]
        var responseData = await doGetConnect("academic-year")
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data)) {
                var academicYearData = responseData.data
                Object.keys(academicYearData).forEach(key => {
                    academicYear.push({ label: academicYearData[key].year, value: academicYearData[key].year })
                })
                this.setState({ academicYear })
            }
        }
    }

    getSchoolAndPosition = async () => {
        var responseData = await doGetConnect("school")
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data)) {
                // var schoolData = [{ label: "School", value: 0 }];
                var responseSchoollData = JSON.parse(JSON.stringify(responseData.data).split('"id":').join('"id":'));
                responseSchoollData = JSON.parse(JSON.stringify(responseSchoollData).split('"school_name":').join('"name":'));
                var schoolList = responseSchoollData;
                this.setState({ schoolList })
            }
        }
        var responseData1 = await doGetConnect("school/position")
        if (checkNullAndReturnString(responseData1)) {
            if (checkNullAndReturnString(responseData1.data)) {
                var positionList = [{ label: "Position", value: 0 }];
                Object.keys(responseData1.data).forEach(key => {
                    positionList.push({
                        label: responseData1.data[key].name,
                        value: responseData1.data[key].position })
                })
                this.setState({ positionList })
            }
        }
    }

    changeStatus = async (id, val) => {
        var accessToken = localStorage.getItem("accessToken");
        var postJson = {
            status: val
        }
        var responseData = await doPutConnectSecurity("users/status/" + id, postJson, accessToken)
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.message)) {
                toast.success(responseData.message)
                this.getPlayerList();
                this.handleClose();
            }
        }
    }

    getPlayerList = async () => {
        var { playerName, school, year, position,
            paginations: {
                paginatonpageindex,
                paginationrowsPerPage },
            sortByKey,
            sortByOrder
        } = this.state;
        this.setState({ total: 0, rejectedTotal: 0, approveTotal: 0, list: [] })
        if (Number(school) === 0) {
            school = "";
        }
        if (Number(year) === 0) {
            year = "";
        }
        if (Number(position) === 0) {
            position = "";
        }
        const pagefrom = paginationrowsPerPage * paginatonpageindex;
        const pageto = paginationrowsPerPage;

        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doGetConnectSecurity("player/adminviewplayers?sortby=" + sortByKey + "&order=" + (sortByOrder ? "ASC" : 'DESC') + "&skip=" + pagefrom + "&take=" + pageto + "&playerName=" + playerName + "&school=" + school + "&position=" + position + "&academicYear=" + year, accessToken)
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.UserStatusCount)) {
                this.setState({
                    total: responseData.UserStatusCount.total_count,
                    rejectedTotal: responseData.UserStatusCount.unenrolled_count,
                    approveTotal: responseData.UserStatusCount.enrolled_count
                })
            }
            if (checkNullAndReturnString(responseData.data)) {
                let paginatevalues = {
                    paginatonpageindex,
                    paginationrowsPerPage,
                    paginationcountdata: Number(responseData.total)
                }
                // var list = sort_by_key(responseData.data, 'full_name')
                this.setState({ list: responseData.data, paginations: paginatevalues })
            }
        }
    }

    clearFunction = () => {
        this.setState({
            school: 0,
            position: 0,
            university: 0,
            year: 0,
            playerName: "",
            selectSchool: []
        })
        setTimeout(() => this.getPlayerList(), 500)
    }

    handleChange = (id, value) => {
        this.setState({
            [id]: value
        })
    }


    handleClose = () => {
        this.setState(pre => ({
            ...pre,
            dialogdata: {
                data: null,
                open: false
            }
        }))
    }

    handleChangePageModal = async (event, newPage) => {
        const paginationvalue = { ...this.state.paginations, paginatonpageindex: newPage };
        this.setState({
            paginations: { ...paginationvalue }
        }, this.getPlayerList);
    }

    handleChangeRowsPerPageModal = async (event) => {
        const paginationvalue = { ...this.state.paginations, paginationrowsPerPage: event.target.value, paginatonpageindex: 0 };
        this.setState({
            paginations: { ...paginationvalue }
        }, this.getPlayerList);
    }

    onSortData = (column) => {
        let key = 'full_name';
        if (column === "Player") { key = 'full_name' } else
            if (column === "Email") { key = 'email_id' } else
            if (column === "Phone Number") { key = 'phoneNumber' } else
            if (column === "School") { key = 'school' } else
            if (column === "POS") { key = 'position' } else
                if (column === "CLS") { key = 'class' } else
                    if (column === "HT") { key = 'height_feet' } else
                        if (column === "WT") { key = 'weight' } else
                            if (column === "Status") { key = 'status' }
        this.setState((pre) => ({
            sortByKey: key,
            sortByOrder: (pre.sortByKey === key ? !pre.sortByOrder : true)
        }), this.getPlayerList);
        // this.getPlayerList
    }


    render() {
        const { classes } = this.props;
        var { list, total, schoolList, academicYear, positionList, height, rejectedTotal,
            approveTotal, dialogdata: { data, /* open */ }, paginations: {
                paginatonpageindex,
                paginationrowsPerPage,
                paginationcountdata
            } } = this.state;
        var rows = []
        if (checkNullAndReturnString(list)) {
            Object.keys(list).forEach(key => {
                var item = list[key];
                if (this.props.from === "mapping") {
                    rows.push({
                        player: item.last_name + ", "+ item.first_name,
                        school: item.school_name,
                        pos: item.position, cls: item.class,
                        status: <a href="#/" onClick={(e) => { e.preventDefault(); this.props.setPlayer(item) }} style={{
                            color: "#1891BD",
                            font: "normal normal normal 14px/21px Poppins",
                            opacity: 1,
                        }}>
                            Map
                        </a>
                    });
                }
                else {
                    rows.push({
                        player: item.last_name + ", "+ item.first_name, school: item.school_name, pos: item.position, cls: item.class,
                        email_id: item.email_id,
                        phoneNumber: item.phoneNumber ? <PhoneInput
                        inputClass={"labelPhone"}
                        country={'us'}
                        inputStyle={{ width: "100%",
                        background: "transparent",
                        border: "transparent",
                        color: "white",
                     }}
                     containerStyle={{
                        background: "transparent",
                        border: "transparent",

                     }}
                     buttonStyle={{
                         display: "none"
                     }}
                     disabled
                        value={item.phoneNumber}
                        /> : "",                        
                        status: <span style={{
                            color: item.status ? "#73B86F" : "red",
                            font: "normal normal normal 14px/21px Poppins",
                            opacity: 1,
                        }}>
                            {
                                item.status ? "Registered" : "Not Registered"
                            }
                        </span>,
                        view: !checkNullAndReturnString(item.user_id) ? "" : <Link to={Config.reactUrl + "/admin/adminplayerview/admin/" + item.user_id}><span style={{
                            color: "#1891BD",
                            font: "normal normal normal 14px/21px Poppins",
                            opacity: 1,
                        }}>View Profile</span></Link>,
                        disable: !checkNullAndReturnString(item.user_id) ? "" : <a href="#/" style={{
                            color: "#1891BD",
                            font: "normal normal normal 14px/21px Poppins",
                            opacity: 1,
                        }}
                            // onClick={() => this.changeStatus(item.user_id, !item.status)}
                            onClick={(e) => { e.preventDefault(); this.setState(pre => ({ ...pre, dialogdata: { open: true, data: item } })) }}
                        >
                            {
                                item.status ? "Disable Account" : "Enable Account"
                            }
                        </a>
                    });
                }
            })
        }
        let mapClassnameFrom = "col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12";
        let mapClassnameEnd = "col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12";
        if (this.props.from === "mapping") {
            mapClassnameFrom = "col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12";
            mapClassnameEnd = "col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12";
        }
        return (
            <React.Fragment>
                <div class={this.props.from === "mapping" ? "row" : "row rowView"} style={this.props.from === "mapping" ? { padding: 10 } : {}}>
                    <div class={mapClassnameFrom} style={{ paddingTop: 20 }}>
                        <span className={classes.searchText}>Search Player</span>
                        <br /><br />
                        <LabelInput label={"Player Name"} name={"playerName"} value={this.state.playerName} updateInputValue={this.handleChange} />
                        <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                        <div className='selectsingleselectadmin'>
                            <Multiselect
                                style={{display: "flex"}}
                                // singleSelect={true}
                                options={schoolList} // Options to display in the dropdown
                                selectedValues={this.state.selectSchool} // Preselected value to persist in dropdown
                                onSelect={(e) => {
                                    this.setState({ selectSchool : [e[e.length - 1]], school: e[e.length - 1].id })
                                }} // Function will trigger on select event
                                placeholder={this.state.school ? "" : "School"}
                                onRemove={(e) => {
                                    this.setState({ selectSchool : [], school: '' })
                                }} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                            />
                        </div>               
                        {/* <SelectBox onChange={this.handleChange} keyName={"school"} options={schoolList} value={this.state.school} /> */}
                        <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                        <SelectBox onChange={this.handleChange} keyName={"position"} options={positionList} value={this.state.position} />
                        <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                        <SelectBox onChange={this.handleChange} keyName={"year"} options={academicYear} value={this.state.year} />
                        {
                            this.props.from === "mapping" ?
                                <div>
                                    <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                                    <Button buttonStyle={classes.buttonStyle1} buttonName={"Clear"} fullWidth={true} onClick={this.clearFunction} />
                                    <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                                    <Button buttonStyle={classes.buttonStyle} buttonName={"Search"} fullWidth={true} onClick={this.getPlayerList} />
                                </div>
                                :
                                <div style={{ float: "right", paddingTop: 20 }}>
                                    <Button buttonStyle={classes.buttonStyle1} buttonName={"Clear"} fullWidth={false} onClick={this.clearFunction} />
                                    <span style={{ paddingRight: 10 }}></span>
                                    <Button buttonStyle={classes.buttonStyle} buttonName={"Search"} fullWidth={false} onClick={this.getPlayerList} />
                                </div>
                        }
                    </div>
                    <div class={mapClassnameEnd} style={{ paddingTop: 20 }}>
                        <span style={{ position: "absolute", border: "1px solid #3B3E50", width: 0, height: height / 1.8 }}></span>
                        <div style={{ padding: 10, paddingTop: 0, paddingLeft: 20 }}>
                            <span className={classes.studentCountText}>
                                {total} Students Listed | {approveTotal} Students Enrolled | {rejectedTotal} Students Not Enrolled
                            </span>
                            <div style={{ paddingTop: 15 }}></div>
                            {
                                this.props.from === "mapping" ?
                                    <Tables fixed={true} extraStyle={{ height: height / 1.6 }}
                                        thJson={["Player", "School", "POS", "CLS", "Map"]}
                                        tdJson={rows}
                                        sortHeader={true} onSortData={this.onSortData}
                                    // paginationFooter={paginations}
                                    // handleChangePage={this.handleChangePageModal}
                                    // handleChangeRowsPerPage={this.handleChangeRowsPerPageModal}
                                    />
                                    :
                                    <Tables fixed={true} extraStyle={{ height: height / 1.6 }}
                                        thJson={["Player", "School", "POS", "CLS", "Email", "Phone Number", "Status", "", ""]}
                                        tdJson={rows}
                                        sortHeader={true} onSortData={this.onSortData}
                                    // paginationFooter={paginations}
                                    // handleChangePage={this.handleChangePageModal}
                                    // handleChangeRowsPerPage={this.handleChangeRowsPerPageModal}
                                    />
                            }
                        </div>
                        <TablePagination
                            style={{ color: 'white' }}
                            component="div"
                            count={paginationcountdata}
                            page={paginatonpageindex}
                            rowsPerPage={paginationrowsPerPage}
                            onChangePage={this.handleChangePageModal}
                            onChangeRowsPerPage={this.handleChangeRowsPerPageModal}
                            rowsPerPageOptions={[50, 100, 150, 200]}
                        />
                    </div>
                </div>

                <Modal open={this.state.dialogdata.open} onClose={this.handleClose} center showCloseIcon={false}>
                    {
                        data &&
                        <div style={{ padding: 20 }}>
                            {
                                data.status ?
                                    <p style={{ font: "normal normal 600 18px/27px Poppins", opacity: 1 }}>Disable Account</p>
                                    :
                                    <p style={{ font: "normal normal 600 18px/27px Poppins", opacity: 1 }}>Enable Account</p>
                            }
                            <p style={{ font: "normal normal normal 14px/21px Poppins", opacity: 1 }}>
                                Are you sure want to {data.status ? 'disable' : 'enable'} {data.last_name + ", "+ data.first_name}'s account ? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </p>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    <Button buttonStyle={classes.buttonStyle2} buttonName={"Cancel"} fullWidth={false}
                                        onClick={this.handleClose} />
                                    <span style={{ paddingRight: 20 }}></span>
                                    <Button buttonStyle={classes.buttonStyle3} buttonName={"Confirm"} fullWidth={false}
                                        onClick={() => this.changeStatus(data.user_id, !data.status)} />
                                </div>
                            </div>
                        </div>
                    }
                </Modal>

            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(ViewPlayer));