import React, { Component } from 'react'
import Footer from "../manage/footer";
import { withStore } from '../../component/hooks/store';
import { withStyles } from '@material-ui/core/styles';
import Header from "./header";
import Config /* { dataUrl  } */ from "../../helper/config";
import Styles from "../../styles/player/editProfile";
import { checkNullAndReturnString, doGetConnectSecurity, ValidateEmail, doPostFormDataSecurity, doPutConnectFormDataSecurity, humanFileSize } from '../../helper/common';
import { Container, CssBaseline } from '@material-ui/core';
import LabelInput from "../../component/labelInput";
import ProgressBar from "../../component/progressBar";
import Button from "../../component/button";
import SelectBox from "../../component/selectBox";
import { CKEditor } from 'ckeditor4-react';
import { toast } from 'react-toastify';
import ReactFileReader from 'react-file-reader';
import { Modal } from 'react-responsive-modal';
import BackOption from "../../component/back";
import Loading from "../../component/loading";
import DatalistInput from 'react-datalist-input';
// import 'react-datalist-input/dist/styles.css';

class CoachUserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            position: "",
            agencyName: "",
            experience: "",
            primaryEmailId: "",
            game: "",
            dob: "",
            heightFeet: "",
            weight: "",
            content: "",
            heightInchess: "",
            city: "",
            state: "",
            alternateEmailId: "",
            facebook: "",
            twitter: "",
            youtube: "",
            vimo: "",
            ticToc: "",
            inText: "",
            instaText: "",
            userDetails: {},
            profile: null,
            profileImage: "",
            uploadImage: "",
            uploadFileName: "",
            modal: false,
            mediaType: "video",
            gallery: null,
            galleryFileName: "",
            galleryData: [],
            hoverId: '',
            modal1: false,
            modalType: "",
            modalId: "",
            modalKey: "",
            highlightsData: [],
            highlightsAdd: false,
            highlightsId: "",
            galleryType: "",
            emptyHightLights: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            modalAddAndRemoveType: true,
            userStorage: 0,
            servicesList: [],
            serviceOffered: [],
            modal2: false,
            highlightModal: false,
            loading: false,
            description: "",
            phone: "",
            countryCode: "",
            categoryName: "",
            catList: [],
            show: false,
        }
        this.dragstartval = null;
        this.dragoverval = null;
    }

    componentDidMount = async () => {
        this.getPlayerProfile();
        this.getGallery();
    }

    getGallery = async () => {
        var accessToken = localStorage.getItem("accessToken");
        var apiUrl = "gallery/category";
        var responseData = await doGetConnectSecurity(apiUrl, accessToken);
        if (checkNullAndReturnString(responseData)) {
          if (responseData.length) {
            const catL = [];
            responseData.map((item, number) => {
                catL.push({ key: item.categoryName, label: item.categoryName });
                return true;
            })
            this.setState({ catList: catL });
          }
        }
      };

    onOpenModal2 = () => {
        this.setState({ modal2: true })
    };

    onCloseModal2 = () => {
        this.setState({ modal2: false })
    };

    onOpenModal1 = () => {
        this.setState({ modal1: true })
    };

    onCloseModal1 = () => {
        this.setState({ modal1: false })
    };

    updateUserDetails = async () => {
        var accessToken = localStorage.getItem("accessToken");
        var id = localStorage.getItem("id");
        var { description, serviceOffered, agencyName, experience, firstName, lastName, profile, position, primaryEmailId, /* game, */ dob, heightFeet, heightInchess, weight,
            profileImage, userDetails, content, city, state, alternateEmailId, facebook, twitter, youtube, vimo, ticToc, inText, instaText,
            phone, countryCode
        } = this.state;
        if (checkNullAndReturnString(alternateEmailId)) {
            if (!ValidateEmail(alternateEmailId)) {
                toast.error("Please enter valid alternate email address");
                return false
            }
        }
        var month = new Array(12);
        month[0] = "01";
        month[1] = "02";
        month[2] = "03";
        month[3] = "04";
        month[4] = "05";
        month[5] = "06";
        month[6] = "07";
        month[7] = "08";
        month[8] = "09";
        month[9] = "10";
        month[10] = "11";
        month[11] = "12";
        var date = "";
        if (checkNullAndReturnString(dob)) {
            date = month[dob.getMonth()] + "/" + dob.getDate() + "/" + dob.getFullYear();
        }
        var postData = new FormData();
        postData.append("firstName", firstName)
        postData.append("lastName", lastName)
        postData.append("gender", this.refillJSonData(userDetails, "gender"))
        postData.append("roleId", "5")
        postData.append("email", primaryEmailId)
        postData.append("adminPrivileges", this.refillJSonData(userDetails, "admin_privileges"))
        postData.append("agencyName", agencyName)
        postData.append("education", this.refillJSonData(userDetails, "education"))
        postData.append("experience", experience)
        postData.append("serviceOffered", serviceOffered)
        postData.append("dateOfBirth", date)
        postData.append("secondaryEmail", alternateEmailId)
        postData.append("playerPosition", position)
        postData.append("heightFeet", heightFeet)
        postData.append("heightInches", heightInchess)
        postData.append("phoneNumber", phone)
        postData.append("countryCode", countryCode)
        postData.append("class", this.refillJSonData(userDetails, "class"))
        postData.append("schoolId", this.refillJSonData(userDetails, "school_id"))
        postData.append("weight", weight)
        postData.append("playerProfileId", "")
        postData.append("city", city)
        postData.append("description", description)
        postData.append("state", state)
        postData.append("status", this.refillJSonData(userDetails, "status"))
        postData.append("biography", content)
        postData.append("socialmedia", JSON.stringify([{ socialMediaType: "facebook", socialMediaUserName: facebook }, { socialMediaType: "instagram", socialMediaUserName: instaText }, { socialMediaType: "twitter", socialMediaUserName: twitter },
        { socialMediaType: "v", socialMediaUserName: vimo }, { socialMediaType: "in", socialMediaUserName: inText }, { socialMediaType: "youtube", socialMediaUserName: youtube }, { socialMediaType: "ticToc", socialMediaUserName: ticToc }]))
        if (checkNullAndReturnString(profile)) {
        }
        else {
            postData.append("profileImageHash", profileImage)
        }
        postData.append("profile", profile)
        var responseData = await doPutConnectFormDataSecurity("users/profile/" + id, postData, accessToken);
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.message)) {
                // this.emptyData()                
                toast.success("Profile details has been saved successfully");
                setTimeout(() => this.getPlayerProfile(), 5000)
                // this.getPlayerProfile()
            }
            else {
                if (checkNullAndReturnString(responseData.error)) {
                    if (checkNullAndReturnString(responseData.error.message)) {
                        toast.error(responseData.error.message);
                    }
                }
            }
        }
    }

    onOpenModal = () => {
        this.setState({ modal: true })
    };

    onCloseModal = () => {
        this.setState({ modal: false })
    };

    refillJSonData = (userDetails, key) => {
        if (checkNullAndReturnString(userDetails)) {
            if (checkNullAndReturnString(userDetails[key])) {
                return userDetails[key]
            }
        }
        return "";
    }

    getPlayerProfile = async () => {
        var accessToken = localStorage.getItem("accessToken");
        var id = localStorage.getItem("id");
        var responseData = await doGetConnectSecurity("users?id=" + id + "&status=&roleId=5&userAccessStatus&name=&city&state&skip=&take=&order=&sortby=", accessToken)
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data)) {
                if (checkNullAndReturnString(responseData.data[0])) {
                    var json = responseData.data[0];
                    this.setDetails(json)
                }
            }
        }
    }

    setDetails = (json) => {
        var facebook = "";
        var instaText = "";
        var twitter = "";
        var vimo = "";
        var ticToc = "";
        var inText = "";
        var youtube = "";
        if (checkNullAndReturnString(json.socialmedia)) {
            var mediaJson = json.socialmedia
            facebook = JSON.parse(mediaJson[0]).socialMediaUserName
            instaText = JSON.parse(mediaJson[1]).socialMediaUserName
            twitter = JSON.parse(mediaJson[2]).socialMediaUserName
            vimo = JSON.parse(mediaJson[3]).socialMediaUserName
            inText = JSON.parse(mediaJson[4]).socialMediaUserName
            youtube = JSON.parse(mediaJson[5]).socialMediaUserName
            ticToc = mediaJson[6] ? JSON.parse(mediaJson[6]).socialMediaUserName : ""
        }
        this.setState({
            serviceOffered: this.refillJSonData(json, "service_offered"),
            firstName: this.refillJSonData(json, "first_name"),
            lastName: this.refillJSonData(json, "last_name"),
            school: this.refillJSonData(json, "school_id"),
            schoolName: this.refillJSonData(json, "school_name"),
            position: this.refillJSonData(json, "player_position"),
            agencyName: this.refillJSonData(json, "agency_name"),
            experience: this.refillJSonData(json, "experience"),
            primaryEmailId: this.refillJSonData(json, "email"),
            dob: checkNullAndReturnString(this.refillJSonData(json, "date_of_birth")) ? new Date(this.refillJSonData(json, "date_of_birth")) : "",
            heightFeet: this.refillJSonData(json, "height_feet"),
            heightInchess: this.refillJSonData(json, "height_inches"),
            weight: this.refillJSonData(json, "weight"),
            content: this.refillJSonData(json, "biography"),
            city: this.refillJSonData(json, "city"),
            state: this.refillJSonData(json, "state"),
            phone: this.refillJSonData(json, "phoneNumber"),
            countryCode: this.refillJSonData(json, "countryCode"),
            alternateEmailId: this.refillJSonData(json, "secondary_email"),
            facebook: facebook,
            twitter: twitter,
            youtube: youtube,
            vimo: vimo,
            ticToc: ticToc,
            inText: inText,
            instaText: instaText,
            userDetails: json,
            profileImage: this.refillJSonData(json, "profile_image_hash"),
            description: this.refillJSonData(json, "description"),
            uploadImage: "",
            uploadFileName: "",
            profile: null,
            highlightsAdd: false,
            highlightsId: "",
            userStorage: checkNullAndReturnString(this.refillJSonData(json, "coach_used_storage")) ? this.refillJSonData(json, "coach_used_storage") : 0,
            emptyHightLights: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
        })
        setTimeout(() => this.setState({ show: true }),500)
    }

    submitGallery = async () => {
        var { mediaType, gallery, categoryName } = this.state;
        if (categoryName) {
            if (checkNullAndReturnString(mediaType) && mediaType !== 0) {
                if (checkNullAndReturnString(gallery)) {
                    var successText = "Image has been uploaded successfully";
                    if (mediaType === "video") {
                        successText = "Video has been uploaded successfully";
                    }
                    this.setState({ loading: true })
                    var accessToken = localStorage.getItem("accessToken");
                    var postFormData = new FormData();
                    postFormData.append("typeOfFile", mediaType);
                    postFormData.append("file", gallery);
                    postFormData.append("categoryName", categoryName);
                    postFormData.append("roleId", '5');
                    var responseData = await doPostFormDataSecurity("gallery", postFormData, accessToken);
                    if (checkNullAndReturnString(responseData)) {
                        if (checkNullAndReturnString(responseData.message) && responseData.message === "Success") {
                            toast.success(successText);
                            this.setState({ gallery: null, galleryFileName: "", categoryName: '' })
                            this.onCloseModal();
                            this.setState({ loading: false })
                            setTimeout(() => this.getPlayerProfile(), 5000)
                            setTimeout(() => this.getGallery(), 5000)
                        }
                        else {
                            if (checkNullAndReturnString(responseData.error)) {
                                if (checkNullAndReturnString(responseData.error.message)) {
                                    toast.error(responseData.error.message);
                                    this.setState({ loading: false })
                                }
                            }
                        }
                    }
                    else {
                        this.setState({ loading: false })
                    }
                }
                else {
                    toast.error("Please upload image")
                }
            }
            else {
                toast.error("Please select media type")
            }
        }
        else {
            toast.error("Please enter category name")
        }
    }

    onMouseover = (e, galleryType) => {
        this.setState({ hoverId: e, galleryType })
    }

    onMouseout = (e) => {
        this.setState({ hoverId: "", galleryType: "" })
    }

    updateInputValue = (id, value) => {
        this.setState({
            [id]: value
        })
    }

    onChange = (evt) => {
        var newContent = evt.editor.getData();
        console.log({ newContent });
        this.setState({
            content: newContent
        })
    }

    handleFiles1 = (gallery) => {
        this.setState({ gallery: gallery[0], galleryFileName: gallery[0].name })
    }

    handleFiles = (profile) => {
        const fsize = profile[0].size;
        const file = Math.round((fsize / 1024));
        // The size of the file. 
        if (file >= 5120) {
            toast.error("File size is too big, Please upload less than 5mb")
        }
        else {
            this.setState({ profile: profile[0] })
            this.setState({ uploadImage: URL.createObjectURL(profile[0]), uploadFileName: profile[0].name })
        }
    }

    setServices = (id, status) => {
        var { serviceOffered } = this.state;
        if (status) {
            serviceOffered.push(id)
        }
        else {
            serviceOffered = serviceOffered.filter(function (e) { return e !== id })
        }
        this.setState({ serviceOffered })
    }

    render() {
        var { classes } = this.props;
        var { userStorage, uploadFileName, uploadImage, firstName, lastName, primaryEmailId,
            categoryName, profileImage, alternateEmailId } = this.state;
        var availbleSpace = parseInt(userStorage / 10737418240 * 100);
        return (
            <React.Fragment>
                <CssBaseline />
                {
                    this.state.loading ?
                        <Loading />
                        :
                        ""
                }
                <Header from={"Players"} />
                <Container maxWidth="xl" component="main" className={classes.heroContent}>
                    <div className="container-fluid" style={{ paddingBottom: 120, paddingTop: 30 }}>
                        <div class="row rowView" >
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding">
                                <BackOption history={this.props.history} linkTo={Config.reactUrl + "/coachuser/dashboard"}/>
                            </div>
                        </div>
                        <div class="row rowView" style={{ paddingTop: 20 }}>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-5 noPadding">
                                <span class={classes.editProfileText}>Edit Profile</span>
                            </div>
                            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-7 noPadding">
                                <div style={{ float: "right" }}>
                                    <Button buttonStyle={classes.buttonStyle1} buttonName={"Cancel"} onClick={() => this.onOpenModal2()} />
                                    <span style={{ paddingRight: 20 }}></span>
                                    <Button buttonStyle={classes.buttonStyle} buttonName={"Save"} onClick={() => this.updateUserDetails()} />
                                </div>
                            </div>
                        </div>
                        <div class="row rowView" >
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 noPadding" style={{ paddingTop: 20 }}>
                                <span class={classes.basicInfoText}>Basic Information</span>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 " style={{ paddingLeft: 20, paddingTop: 20 }}>
                                        <LabelInput disabled={true} updateInputValue={this.updateInputValue} name={"firstName"} label={"First Name"} value={firstName} />
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 " style={{ paddingLeft: 20, paddingTop: 20 }}>
                                        <LabelInput disabled={true} updateInputValue={this.updateInputValue} name={"lastName"} label={"Last Name"} value={lastName} />
                                    </div>
                                </div>
                                <div class="row" >
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 " style={{ paddingLeft: 20, paddingTop: 20 }}>
                                        <LabelInput disabled={true} updateInputValue={this.updateInputValue} name={"primaryEmailId"} label={"Primary Email Address"} value={primaryEmailId} />
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 " style={{ paddingLeft: 20, paddingTop: 20 }}>
                                        <LabelInput updateInputValue={this.updateInputValue} name={"alternateEmailId"} label={"Alternate Email Address"} value={alternateEmailId} />
                                    </div>
                                </div>
                                <div class="row" >
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " style={{ paddingLeft: 20, paddingTop: 20 }}>
                                        <span style={{ font: "normal normal normal 10px/16px Poppins", color: "#959595" }}>Biography</span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        {
                                            this.state.show &&
                                                <CKEditor
                                                    initData={this.state.content}
                                                    style={{ height: 500 }}
                                                    onChange={this.onChange}
                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 rowView" style={{ paddingTop: 20 }}>
                                <span class={classes.basicInfoText}>Profile Picture</span>
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " style={{ paddingLeft: 20, paddingTop: 20 }}>
                                        <img alt="" className={classes.agentEditImage} src={
                                            checkNullAndReturnString(profileImage) && !checkNullAndReturnString(uploadImage) ?
                                                Config.dataUrl + "aws-files/" + profileImage
                                                :
                                                checkNullAndReturnString(uploadImage) ?
                                                    uploadImage
                                                    :
                                                    Config.emptyUser
                                        } />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {
                                            checkNullAndReturnString(uploadFileName) ?
                                                <span className={classes.fileText}>
                                                    {uploadFileName}
                                                </span>
                                                :
                                                ""
                                        }
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {
                                            checkNullAndReturnString(uploadFileName) ?
                                                <a href="#/" className={classes.changeText} onClick={(e) => { e.preventDefault(); this.setState({ uploadImage: "", uploadFileName: "" }) }}>
                                                    Change
                                                </a>
                                                :
                                                ""
                                        }
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " style={{ paddingLeft: 20, paddingTop: 20 }}>
                                        <ReactFileReader handleFiles={this.handleFiles} fileTypes={['.png', '.jpg', '.tiff']} >
                                            <Button buttonStyle={classes.buttonStyle1} buttonName={"Browse"} extraStyle={{ paddingLeft: 20, paddingRight: 20 }} fullWidth={false} onClick={() => console.log("browse")} />
                                        </ReactFileReader>
                                        <p className={classes.fileSizeText}>
                                            Max. file size 5 MB, Only *JPG, *PNG, *TIFF File Format
                                        </p>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " style={{ paddingTop: 0 }}>
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingLeft: 20, paddingTop: 70 }}>
                                                <Button buttonStyle={classes.buttonStyle1} buttonName={"Add media"} onClick={() => this.onOpenModal()} />
                                            </div>
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingLeft: 20, paddingTop: 40, alignSelf: "center", display: "inline-flex" }}>
                                                <div style={{ width: "40%" }}>
                                                    <span class={classes.basicInfoText} style={{ paddingLeft: 0 }}>Available Space</span>
                                                </div>
                                                <div style={{ width: "60%" }}>
                                                    <ProgressBar width={availbleSpace} background={"transparent linear-gradient(90deg, #3B3E50 0%, #43CDFF 100%) 0% 0% no-repeat padding-box"} />
                                                    <p style={{ margin: 0, float: "right", paddingTop: 5, color: "#1891BD", font: "normal normal normal 10px/16px Poppins" }}>
                                                        {humanFileSize(userStorage)} <span style={{ color: "#fff" }}>/ 10.00 GB</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal open={this.state.modal} onClose={() => this.onCloseModal()} center showCloseIcon={false}>
                        <div class="row" style={{ padding: 50, background: "#2d303a", width: 500 }}>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <span className={classes.basicInfoText} style={{ paddingLeft: 0 }}> Category Name  <span style={{ color: "#1891BD" }}>*</span> </span>
                                <div style={{ paddingTop: 10 }}></div>
                                
                                <DatalistInput
                                    value={categoryName}
                                    placeholder="Category Name"
                                    label=""
                                    onInput={(e) => this.updateInputValue('categoryName', e)}
                                    onSelect={(item) => this.updateInputValue('categoryName', item.label)}
                                    items={this.state.catList}
                                />
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <span className={classes.basicInfoText} style={{ paddingLeft: 0 }}> Media type  <span style={{ color: "#1891BD" }}>*</span> </span>
                                <div style={{ paddingTop: 10 }}></div>
                                <SelectBox onChange={this.updateInputValue} keyName={"mediaType"} options={[{ label: "Video", value: "video" }]} value={this.state.mediaType} />
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " style={{ paddingLeft: 20, paddingTop: 20 }}>
                                <ReactFileReader handleFiles={this.handleFiles1} fileTypes={this.state.mediaType === "image" ? ['.png', '.jpg', 'jpeg'] : ['.mp4', '.mov', '.flv', '.avi']} >
                                    <Button buttonStyle={classes.buttonStyle1} buttonName={"Browse"} extraStyle={{ paddingLeft: 20, paddingRight: 20 }} fullWidth={false} onClick={() => console.log("browse")} />
                                </ReactFileReader>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {
                                    checkNullAndReturnString(this.state.galleryFileName) ?
                                        <span className={classes.fileText}>
                                            {this.state.galleryFileName}
                                        </span>
                                        :
                                        ""
                                }
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {
                                    checkNullAndReturnString(this.state.galleryFileName) ?
                                        <a href="#/" className={classes.changeText} onClick={(e) => { e.preventDefault(); this.setState({ gallery: "", galleryFileName: "" }) }}>
                                            Remove
                                        </a>
                                        :
                                        ""
                                }
                                <p className={classes.fileSizeText}>
                                    Max. file size 5 MB, Only *JPG, *PNG, *TIFF, *FLV, *MOV, *MP4 File Format
                                </p>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" style={{ paddingTop: 30 }}>
                                <Button buttonStyle={classes.buttonStyle1} buttonName={"Cancel"} fullWidth={true} onClick={() => this.onCloseModal()} />
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" style={{ paddingTop: 30 }}>
                                <Button buttonStyle={classes.buttonStyle} buttonName={"Submit"} fullWidth={true} onClick={() => this.submitGallery()} />
                            </div>
                        </div>
                    </Modal>

                    <Modal open={this.state.modal2} onClose={() => this.onCloseModal2()} center showCloseIcon={false}>
                        <div style={{ padding: 20 }}>
                            <p style={{ font: "normal normal 600 18px/27px Poppins", opacity: 1 }}>Discard Changes</p>
                            <p style={{ font: "normal normal normal 14px/21px Poppins", opacity: 1 }}>
                                Unsaved changes will be lost, are you sure to proceed?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </p>
                            <p style={{ float: "right" }}>
                                <Button buttonStyle={classes.buttonStyle2} buttonName={"Close"} fullWidth={false} onClick={() => {
                                    this.onCloseModal2();
                                }} />
                                <span style={{ paddingRight: 20 }}></span>
                                <Button buttonStyle={classes.buttonStyle3} buttonName={"Proceed"} fullWidth={false} onClick={() => {
                                    this.onCloseModal2();
                                    this.props.history.push(Config.reactUrl + "/coachuser/dashboard")
                                }} />
                            </p>
                        </div>
                    </Modal>
                </Container>
                <Footer />
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(CoachUserProfile));
