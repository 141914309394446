import React, { Component } from "react";
import { withStore } from "../../component/hooks/store";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Footer from "../manage/footer";
import Header from "./header";
import Styles from "../../styles/uaAdmin/dashboard";
import { Container, CssBaseline } from "@material-ui/core";
import {
  checkNullAndReturnString,
  doGetConnectSecurity,
  doGetConnect,
} from "../../helper/common";
import Config from "../../helper/config";
import { Menu, MenuItem } from "@szhsin/react-menu";
import MenuPng from "../../images/menu.png";
import EditPng from "../../images/edit.png";
import ShowMoreText from "react-show-more-text";
import parse from "html-react-parser";
import PlayerGallery from "../../component/gallery";
import EmptyGallery from "../../component/emptyGallery";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {},
      highlightsData: [],
      galleryData: [],
    };
  }

  refillJSonData = (key) => {
    var { userDetails = [] } = this.state;
    if (checkNullAndReturnString(userDetails)) {
      if (checkNullAndReturnString(userDetails[key])) {
        return userDetails[key];
      }
    }
    return "";
  };

  componentDidMount = async () => {
    this.getPlayerProfile();
    this.getGallery();
  };

  getPlayerProfile = async () => {
    var accessToken = localStorage.getItem("accessToken");
    var id = localStorage.getItem("id");
    var apiUrl =
      "users?id=" +
      id +
      "&status=&roleId=5&userAccessStatus&name=&city&state&skip=&take=&order=&sortby=";
    var responseData = await doGetConnectSecurity(apiUrl, accessToken);
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.data)) {
        if (checkNullAndReturnString(responseData.data[0])) {
          this.setState({ userDetails: responseData.data[0] });
        }
      }
    }
  };

  getGallery = async () => {
    this.setState({ highlightsData: [] });
    var accessToken = localStorage.getItem("accessToken");
    var apiUrl = "gallery/category";
    var responseData = await doGetConnectSecurity(apiUrl, accessToken);
    if (checkNullAndReturnString(responseData)) {
      if (responseData.length) {
        const filterData = responseData.map((item) => {
          item.filesData.map(async (item1) => {
            if (
              (item1.video_thumbnail === "" ||
                item1.video_thumbnail === null ||
                item1.video_thumbnail === "null") &&
              item1.type_of_file === "video"
            ) {
              var responseData1 = await doGetConnect(
                "gallery/thumbnail?id=" + item1.id
              );
              if (checkNullAndReturnString(responseData1)) {
                if (checkNullAndReturnString(responseData1.video_thumbnail)) {
                  item1.video_thumbnail = responseData1.video_thumbnail;
                }
              }
            }
          });
          return item;
        });
        this.setState({ galleryData: filterData });
        let highlightsData = [];
        if (filterData.length) {
          filterData.map((item) => {
            highlightsData = highlightsData.concat(item.filesData);
            return true;
          });
          highlightsData.sort(function (a, b) {
            return new Date(b.uploaded_at) - new Date(a.uploaded_at);
          });
          this.setState({ highlightsData });
        }
      }
    }
  };

  defaultImage(img) {
    img.target.src = Config.emptyUser;
  }

  render() {
    var { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <Header history={this.props.history} />{" "}
        <Container
          maxWidth="xl"
          component="main"
          className={classes.heroContent}
        >
          <div className="container-fluid" style={{ paddingBottom: 110 }}>
            <div class="row" style={{ padding: 10 }}>
              <div
                class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                style={{ paddingTop: 30 }}
              >
                <div className={classes.playerCard}>
                  <div style={{ display: "inline-flex" }}>
                    <div className={classes.agentImage}>
                      <img
                        className={classes.agentImage}
                        alt="playerImageaws"
                        src={
                          Config.dataUrl +
                          "aws-files/" +
                          this.state.userDetails.profile_image_hash
                        }
                        onError={this.defaultImage}
                      />
                    </div>
                    <span className={classes.playerName}>
                      {this.refillJSonData("first_name") +
                        " " +
                        this.refillJSonData("last_name")}
                      <br />
                      <span className={classes.playerGame}>Coach</span>
                      <br />
                      <br />
                      <span className={classes.leagueText}>
                        {this.refillJSonData("description")
                          ? this.refillJSonData("description")
                          : "Update Status"}
                      </span>
                    </span>
                    {
                      <div
                        style={{
                          position: "absolute",
                          right: 30,
                          top: 50,
                        }}
                      >
                        <Menu
                          styles={{
                            background: "#3B3E50 0% 0% no-repeat padding-box",
                            padding: 10,
                            borderRadius: 10,
                          }}
                          menuButton={
                            <a href="#/" onClick={(e) => e.preventDefault()}>
                              <img
                                className={classes.menu}
                                alt="MenuPng"
                                src={MenuPng}
                              />
                            </a>
                          }
                        >
                          <MenuItem>
                            <Link
                              to={Config.reactUrl + "/coachuser/editprofile"}
                            >
                              <img
                                src={EditPng}
                                alt="editspng"
                                style={{ width: 12, height: 12 }}
                              />
                              <span className={classes.menuItemText}>
                                <span className={"headerMenuHover"}>
                                  Edit Profile
                                </span>
                              </span>
                            </Link>
                          </MenuItem>
                        </Menu>
                      </div>
                    }
                  </div>
                  <div style={{ paddingTop: 15 }}></div>
                  <div className={classes.underLine}></div>
                  <div style={{ paddingTop: 10 }}></div>
                  <span className={classes.biograpyText}>Short Biography</span>
                  <div style={{ paddingTop: 10 }}></div>
                  <span className={classes.biograpyDescription}>
                    <ShowMoreText
                      lines={4}
                      more="Show more"
                      less="Show less"
                      className="content-css"
                      anchorClass="my-anchor-css-class"
                      // onClick={this.executeOnClick}
                      expanded={false}
                    >
                      {parse(this.refillJSonData("biography"))}
                    </ShowMoreText>
                  </span>
                </div>
              </div>
              <div
                class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                style={{ paddingTop: 10 }}
              >
                <div className={"row"}>
                  <div
                    class="col-12"
                    style={{
                      paddingTop: 20,
                      paddingRight: 0,
                      paddingLeft: 0,
                      position: "relative",
                    }}
                  >
                    {Object.keys(this.state.highlightsData).length > 0 ? (
                      <PlayerGallery
                        highlightsData={this.state.highlightsData}
                      />
                    ) : (
                      <EmptyGallery />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {this.state.galleryData && this.state.galleryData.length ? (
              this.state.galleryData.map((item, index) => {
                return (
                  <div class="row" style={{ padding: 10 }}>
                    <div
                      class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                      style={{
                        paddingTop: 10,
                        alignSelf: "center",
                        textAlign: "center",
                      }}
                    >
                      <p className={classes.categoryName} style={{ margin: 0 }}>
                        {item.categoryName}
                      </p>
                      <p
                        className={classes.categoryName1}
                        style={{ margin: 0 }}
                      >
                        {item.filesData.length} Videos
                      </p>
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                      <div className={"row"}>
                        <div
                          class="col-12"
                          style={{
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingLeft: 0,
                            position: "relative",
                          }}
                        >
                          {Object.keys(item.filesData).length > 0 ? (
                            <PlayerGallery highlightsData={item.filesData} />
                          ) : (
                            <EmptyGallery />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}
export default withStyles(Styles, {
  withTheme: true,
})(withStore(Dashboard));
