const Styles = theme => ({        
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  backOption: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#959595",
    paddingLeft: 10, 
    opacity: 1,
    "&:hover": {
      color: "#1891BD"
    },
  },
  backArrow: {
    width: 31,
    height: 31
  },
  viewAgentText: {
    font: "normal normal 600 18px/27px Poppins",
    color: "#FFFFFF",
    opacity: 1,
    paddingLeft: 10, 
  }  
});
export default Styles;