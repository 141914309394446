import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withStore } from '../../component/hooks/store';
import Header from './header';
import { Container, CssBaseline } from '@material-ui/core';
import Styles from "../../styles/agent/message";
import MessageComponent from "../../component/message";
import Footer from "../manage/footer";

class Message extends Component {
    constructor(props) {
        super(props);
        this.state = {     
        }
    }
    
    childCount = () => {
        this.child.getMessageCount()
    }

    render() {  
        var fromUserId = "";
        if (this.props.match && this.props.match.params && this.props.match.params.id) {
            fromUserId = this.props.match.params.id;
        }
        var { classes } = this.props;          
        return (
            <React.Fragment>
                <CssBaseline />                            
                <Header onRef={ref => (this.child = ref)} from={"message"}/>
                <Container maxWidth="xl" component="main" className={classes.heroContent}>                   
                    <div className="container-fluid" style={{ paddingTop: 50 }}>
                        <MessageComponent from={"agent"} history={this.props.history} childCount={this.childCount} fromUserId={fromUserId} roleId={4}/>
                    </div>
                </Container>
                <Footer />
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(Message))