import React, { Component } from 'react'
import { /*BrowserRouter as Router, Route,  Switch, */ Link } from "react-router-dom";
import { withStore } from '../../component/hooks/store';
import { withStyles } from '@material-ui/core/styles';
import {
    Menu,
    MenuItem,
    // MenuButton,
    // SubMenu
} from '@szhsin/react-menu';
import { checkNullAndReturnString, doDeleteConnect, doGetConnectSecurity, doPutConnectSecurity } from '../../helper/common';
import '@szhsin/react-menu/dist/index.css';
import Styles from "../../styles/player/header";
import NxtLogo from "../../images/nxtpick-logo-header.png";
import Notification from "../../images/notification.png";
import Message from "../../images/message.png";
import MenuPng from "../../images/menu.png";
// import Star from "../../images/star.png";
import config from '../../helper/config';
import Button from '../../component/button';
import { Modal } from 'react-responsive-modal';
import LockPng from "../../images/lock.png";
import ExitPng from "../../images/exit.png";
import InformationPng from "../../images/information.png";
// import VideoCamerImage from "../../images/video.png";
// import GalleryImage from "../../images/gallery.png";
// import Config from '../../helper/config';
import ComparePlayerCard from '../../component/comparePlayerCard';
import { toast } from 'react-toastify';
// import CancelIcon from '@material-ui/icons/Cancel';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            fullName: "",
            modal: false,
            count: 0,
            notificationCount: 0,
            notificationList: [],
            versiondetails: null
        }
    }

    componentDidMount = async () => {
        var firstName = localStorage.getItem("firstName");
        var lastName = localStorage.getItem("lastName");
        var fullName = firstName + " " + lastName;
        var name = "";
        if (checkNullAndReturnString(firstName)) {
            if (checkNullAndReturnString(lastName)) {
                name = firstName[0] + lastName[0]
            }
            else {
                name = firstName[0]
            }
        }
        this.setState({ fullName, name })
        if (!this.props.transparent) {
            this.getMessageCount();
            this.notificationGet()
            if (this.props.from === "message") {
                this.props.onRef(this)
            }
        }
        this.getVersionDetail();
    }

    // getVersionDetail = async () => {
    //     try {
    //         var accessToken = localStorage.getItem("accessToken");
    //         var responseData1 = await doGetConnectSecurity(
    //             `version-history?skip=0&take=1&sortby=id&order=DESC`
    //             , accessToken)
    //         if (checkNullAndReturnString(responseData1.data)) {
    //             const { data: [dataone] } = responseData1;
    //             if (checkNullAndReturnString(dataone)) {
    //                 this.setState({
    //                     versiondetails: dataone
    //                 })
    //             } else {
    //                 throw Error({ message: 'Error while getting version details' });
    //             }
    //         } else {
    //             throw Error({ message: 'Error while getting version details' });
    //         }
    //     } catch (err) {
    //         toast.error(err.message ? err.message : 'Error while getting version details')
    //     }
    // }

    getVersionDetail = async () => {
        try {
            var accessToken = localStorage.getItem("accessToken");
            var responseData1 = await doGetConnectSecurity(
              `version-history?skip=0&take=1&sortby=id&order=DESC&type=web`,
              accessToken
            );

            if (responseData1 && responseData1.data && responseData1.data.length) {
                const { data: [dataone] } = responseData1;
                this.setState({
                    versiondetails: dataone
                })
            }
        } catch (err) {
            toast.error(err.message ? err.message : 'Error while getting version details')
        }
    }

    componentWillUnmount() {
        if (this.props.from === "message") {
            this.props.onRef(undefined)
        }
    }

    notificationGet = async () => {
        this.setState({ notificationList: [] })
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doGetConnectSecurity("notifications", accessToken)
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data)) {
                this.setState({ notificationList: responseData.data })
            }
        }
    }

    getMessageCount = async () => {
        this.setState({ count: 0, notificationCount: 0 })
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doGetConnectSecurity("messages/count", accessToken)
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.count)) {
                this.setState({ count: responseData.count })
            }
        }
        var responseData1 = await doGetConnectSecurity("notifications/count", accessToken)
        if (checkNullAndReturnString(responseData1)) {
            if (checkNullAndReturnString(responseData1.count)) {
                this.setState({ notificationCount: responseData1.count })
            }
        }
    }

    onOpenModal = () => {
        this.setState({ modal: true })
    };

    onCloseModal = () => {
        this.setState({ modal: false })
    };

    logoutFunction = async () => {
        var refreshToken = localStorage.getItem("refreshToken")
        var responseData = await doDeleteConnect("refresh-token?token=" + refreshToken)
        if (checkNullAndReturnString(responseData)) {
            localStorage.clear();
            window.location.href = config.reactUrl + "/logout";
        }
    }

    clearNotification = async () => {
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doDeleteConnect("notifications/clear?role_id=4", accessToken)
        if (responseData) {
            this.getMessageCount()
            this.notificationGet()
        }
    }

    onClickCountRemove = async (id) => {
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doPutConnectSecurity("notifications/flag/" + id, {}, accessToken);
        if (checkNullAndReturnString(responseData)) {
            this.getMessageCount()
        }
    }

    render() {
        const { classes } = this.props;
        const { versiondetails } = this.state;

        var notificationList = this.state.notificationList;
        var notificationData = [];
        if (checkNullAndReturnString(notificationList)) {
            Object.keys(notificationList).forEach(key => {
                var item = notificationList[key];
                notificationData.push(
                    <MenuItem >
                        <a onClick={(e) => { e.preventDefault(); this.onClickCountRemove(item.id) }} href="#/" style={{ width: "100%", padding: 5, font: "normal normal normal 12px/25px Poppins", color: "#FFFFFF", opacity: 1, borderBottom: "1px solid #939393" }}>
                            {item.notification}
                        </a>
                    </MenuItem>
                )
            })
        }

        return (
            <div>
                <nav class="navbar navbar-expand-md fixed-top" style={{
                    borderBottom: this.props.transparent ? "" : "1px solid #3B3E50",
                    background: this.props.transparent ? "" : "#262834",
                }}>
                    <Link to={"/agent/dashboard"} variant="button" className={"navbar-brand"} style={{ paddingLeft: 20 }}>
                        <img src={NxtLogo} alt="logopng" style={{ width: 210, height: 22 }} />
                    </Link>
                    {
                        (versiondetails && versiondetails.version_id &&
                            <a href="/version-history/details" target="_blank" class="navbar-brand"
                                className={classes.vname}
                            >
                                {'v'.concat(versiondetails.version_id)}
                            </a>)
                    }
                    <div class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <img className={classes.menu} alt="menupngs" src={MenuPng} />
                    </div>
                    <div class="collapse navbar-collapse" id="navbarCollapse">
                        <div class="navbar-nav ml-auto" style={{ paddingRight: 40 }}>

                            {
                                (this.props && this.props.compareUserDetails && this.props.compareUserDetails.length) ?
                                    <div class={classes.sidepanel}>
                                        {
                                            this.props.compareUserDetails.map(ele => (
                                                <ComparePlayerCard
                                                    userDetail={ele} clearUsers={this.props.clearUsers} />
                                            ))
                                        }
                                        <div style={{
                                            display: 'flex', flexDirection: 'column',
                                            justifyContent: 'center', alignItems: 'center',
                                            margin: '0px 15px'
                                        }}>
                                            <div style={{ marginBottom: '12px' }}>
                                                <Button buttonStyle={classes.buttonStyle2}
                                                    buttonName={"Clear"} fullWidth={false}
                                                    onClick={() => this.props.clearUsers([])} />
                                            </div>
                                            <div>
                                                <Button buttonStyle={classes.buttonStyle2}
                                                    buttonName={"Compare"} fullWidth={false}
                                                    onClick={this.props.makeComparisonOfSelectedUser} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""
                            }

                            {
                                this.props.transparent ? null :
                                    <div class="nav-item nav-link" style={{ paddingRight: 15 }}>
                                        <Link to={config.reactUrl + "/agent/message/"}>
                                            {
                                                Number(this.state.count) !== 0 ?
                                                    <span class={classes.badge}>
                                                        {this.state.count}
                                                    </span>
                                                    :
                                                    ""
                                            }
                                            <a href="#/" className={classes.circle}>
                                                <img src={Message} alt="messages" className={classes.message} />
                                            </a>
                                        </Link>
                                    </div>
                            }
                            {
                                this.props.transparent ? null :
                                    <div class="nav-item nav-link" style={{ paddingRight: 15 }}>
                                        {
                                            Number(this.state.notificationCount) !== 0 ?
                                                <span class={classes.badge}>
                                                    {this.state.notificationCount}
                                                </span>
                                                :
                                                ""
                                        }
                                        {
                                            <Menu styles={{
                                                background: "#3B3E50 0% 0% no-repeat padding-box",
                                                padding: 5,
                                                borderRadius: 10,
                                                width: 250,
                                                maxHeight: 250,
                                                overflowY: "scroll"
                                            }} menuButton={
                                                <a href="#/" className={classes.circle} onClick={e => e.preventDefault()}>
                                                    <img src={Notification} alt="notificationers" className={classes.notification} />
                                                </a>
                                            }>
                                                <MenuItem >
                                                    <a href="#/" style={{ width: "100%", padding: 5, font: "normal normal normal 14px/25px Poppins", color: "#FFFFFF", opacity: 1, borderBottom: "1px solid #939393" }} onClick={e => e.preventDefault()}>
                                                        Notifications
                                                    </a>
                                                    <a href="#/" style={{ width: "40%", padding: 5, font: "normal normal normal 12px/25px Poppins", color: "#1891BD", opacity: 1, borderBottom: "1px solid #939393", float: "right" }}
                                                        onClick={(e) => { e.preventDefault(); this.clearNotification() }}
                                                    >
                                                        Clear All
                                                    </a>
                                                </MenuItem>
                                                {notificationData}
                                            </Menu>
                                        }
                                    </div>
                            }
                            {
                                this.props.transparent ? null :
                                    <div class="nav-item nav-link" style={{ paddingRight: 20 }}>
                                        <Menu styles={{
                                            background: "#3B3E50 0% 0% no-repeat padding-box",
                                            padding: 10
                                        }} menuButton={
                                            <a href="#/" onClick={e => e.preventDefault()} className={"headerNameHover"} style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                <div style={{ padding: 3, width: 200, display: "inline-flex" }}>
                                                    <span className={classes.nameCircle} style={{ position: "absolute" }}>
                                                        <span className={classes.circleText}> {this.state.name} </span>
                                                    </span>
                                                    <span style={{ paddingLeft: 10 }}></span>
                                                    <span href="#/" onClick={e => e.preventDefault()} style={{ position: "relative", left: 40, width: 160, display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                        <span className={classes.fullNameText}>{this.state.fullName}</span>
                                                        <p className={classes.userTypeText}>Agent</p>
                                                    </span>
                                                </div>
                                            </a>
                                        }>
                                            <MenuItem >
                                                <Link to={config.reactUrl + "/agent/changepassword"}>
                                                    <img src={LockPng} alt="lockpng" style={{ width: 12, height: 12 }} />
                                                    <span className={classes.menuItemText} >
                                                        <span className={"headerMenuHover"}>
                                                            Change Password
                                                        </span>
                                                    </span>
                                                </Link>
                                            </MenuItem>
                                            <MenuItem >
                                                <img src={InformationPng} alt="informationpng" style={{ width: 12, height: 12 }} />
                                                <span className={classes.menuItemText}>
                                                    <span className={"headerMenuHover"}>
                                                        FAQ
                                                    </span>
                                                </span>
                                            </MenuItem>
                                            <MenuItem >
                                                <img src={ExitPng} alt="exitpng" style={{ width: 12, height: 12 }} />
                                                <span className={classes.menuItemText}>
                                                    <span className={"headerMenuHover"} onClick={() => this.onOpenModal()}>
                                                        Logout
                                                    </span>
                                                </span>
                                            </MenuItem>
                                        </Menu>
                                    </div>
                            }
                        </div>
                    </div>
                </nav>
                <Modal open={this.state.modal} onClose={() => this.onCloseModal()} center showCloseIcon={false}>
                    <div style={{ padding: 20 }}>
                        <p style={{ font: "normal normal 600 18px/27px Poppins", opacity: 1 }}>Logout</p>
                        <p style={{ font: "normal normal normal 14px/21px Poppins", opacity: 1 }}>
                            Are you sure to logout from current session?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                        <p style={{ float: "right" }}>
                            <Button buttonStyle={classes.buttonStyle1} buttonName={"Cancel"} fullWidth={false} onClick={() => {
                                this.onCloseModal();
                            }} />
                            <span style={{ paddingRight: 20 }}></span>
                            <Button buttonStyle={classes.buttonStyle} buttonName={"Logout"} fullWidth={false} onClick={() => {
                                this.logoutFunction();
                            }} />
                        </p>
                    </div>
                </Modal>
            </div >
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(Header));