import React, { Component } from 'react'

class index extends Component {
    render() {
        var { keyName, value, onChange, placeHolder, from, divide, divideText, extraStyle
            , onKeydown = (e) => { }, type } = this.props;
        if (from === "signUp") {
            return (
                <div>
                    <input type={type ? type : "text"}
                        value={value}
                        style={{
                            background: "rgb(75 76 92 / 60%)", color: "#FFFFFF",
                            font: "normal 300 14px/18px Poppins",
                            height: 44,
                            border: "none",
                            width: "100%",
                            paddingLeft: 13,
                            paddingRight: divide ? 50 : 13,
                            outline: "none",
                            "&:hover": {
                                outline: "none"
                            },
                            "&:focus": {
                                outline: "none"
                            },
                            borderRadius: 5
                        }}
                        onChange={(e) => onChange(keyName, e.target.value)}
                        placeholder={placeHolder}
                    />
                    {
                        divide ?
                            <span style={{ height: 44, position: "absolute", right: 55, color: "white", borderLeft: "1px solid rgb(75 76 92 / 60%)" }}>
                                <span style={{ position: "absolute", top: 10, left: 10, font: "italic normal 300 14px/21px Poppins" }}>{
                                    divideText
                                }</span>
                            </span>
                            :
                            null
                    }
                </div>
            )
        }
        else {
            return (
                <div style={{ background: "#51535d", height: 44, borderRadius: 5 }}>
                    <input type={"text"}
                        value={value}
                        style={{
                            background: "transparent", color: "#FFFFFF",
                            font: "italic normal 300 14px/18px Poppins",
                            height: 44,
                            opacity: 0.7,
                            border: "none",
                            width: "100%",
                            paddingLeft: 13,
                            paddingRight: 13,
                            outline: "none",
                            "&:hover": {
                                outline: "none"
                            },
                            "&:focus": {
                                outline: "none"
                            },
                            ...extraStyle
                        }}
                        onChange={(e) => onChange(keyName, e.target.value)}
                        placeholder={placeHolder}
                        onKeyUpCapture={(e) => onKeydown(e)}
                    />
                </div>
            )
        }
    }
}
export default index;