import React, { Component } from 'react'

class Loading extends Component {     
    render() {        
        return (
            <div id="overlay" >
                <div class="spinner"></div>
                <br/>
                Loading...
            </div>
        )
    }
}
export default Loading;