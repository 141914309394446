import React, { Component } from "react";
import Contract from "../images/contract.png";
import Legal from "../images/legal.png";
import Financial from "../images/financial.png";
import Performance from "../images/performance.png";
import Career from "../images/career.png";
import { checkNullAndReturnString, doGetConnect } from "../helper/common";
import Config from "../helper/config";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicesList: [],
    };
  }

  componentDidMount = async () => {
    var responseData = await doGetConnect("master-data/agent/services");
    if (checkNullAndReturnString(responseData)) {
      this.setState({ servicesList: responseData });
    }
  };

  refillJsonData = (key) => {
    var { item } = this.props;
    if (checkNullAndReturnString(item)) {
      if (checkNullAndReturnString(item[key])) {
        return item[key];
      }
    }
    return "";
  };

  render() {
    var service = this.refillJsonData("service_offered");
    var servicesData = [];
    var servicesList = this.state.servicesList;
    if (checkNullAndReturnString(servicesList)) {
      if (checkNullAndReturnString(service)) {
        Object.keys(service).forEach((key) => {
          var serviceArray = servicesList.filter(
            (servicesList) => (servicesList.id && servicesList.id.toString()) === (service[key] && service[key].toString())
          );
          if (checkNullAndReturnString(serviceArray)) {
            if (checkNullAndReturnString(serviceArray[0])) {
              if (serviceArray[0].service_name === "Legal Representations") {
                servicesData.push(
                  <div style={{ width: "20%" }}>
                    <img src={Legal} alt="" style={{ width: 16, height: 15 }} />
                  </div>
                );
              } else if (
                serviceArray[0].service_name === "Contract Negotiations"
              ) {
                servicesData.push(
                  <div style={{ width: "20%" }}>
                    <img
                      src={Contract}
                      alt=""
                      style={{ width: 16, height: 15 }}
                    />
                  </div>
                );
              } else if (
                serviceArray[0].service_name === "Financial Advisement"
              ) {
                servicesData.push(
                  <div style={{ width: "20%" }}>
                    <img
                      src={Financial}
                      alt=""
                      style={{ width: 16, height: 16 }}
                    />
                  </div>
                );
              } else if (
                serviceArray[0].service_name === "Performance Training"
              ) {
                servicesData.push(
                  <div style={{ width: "20%" }}>
                    <img
                      src={Performance}
                      alt=""
                      style={{ width: 16, height: 11 }}
                    />
                  </div>
                );
              } else {
                servicesData.push(
                  <div style={{ width: "20%" }}>
                    <img
                      src={Career}
                      alt=""
                      style={{ width: 16, height: 16 }}
                    />
                  </div>
                );
              }
            }
          }
        });
      }
    }

    var smallText = {
      font: "normal normal normal 10px/16px Poppins",
      color: "#959595",
      opacity: 1,
    };
    var boldText = {
      font: "normal normal normal 12px/18px Poppins",
      color: "#FFFFFF",
      opacity: 1,
    };
    // var compareText = {
    //   font: "normal normal normal 12px/18px Poppins",
    //   color: "#1891BD",
    //   opacity: 1,
    //   float: "right",
    // };
    var imageStyle = {
      width: 40,
      height: 40,
      borderRadius: 40 / 2,
    };
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "#3B3E50 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: 10,
          border: "1px solid #3B3E50",
          opacity: 1,
        }}
      >
        <div className="row" style={{ padding: 15 }}>
          <div className="col-2" style={{ justifyContent: "center" }}>
            {this.refillJsonData("profile_image_hash") ? (
              <img
                style={imageStyle}
                alt=""
                src={
                  Config.dataUrl +
                  "aws-files/" +
                  this.refillJsonData("profile_image_hash")
                }
              />
            ) : (
              <img style={imageStyle} alt="" src={Config.emptyUser} />
            )}
          </div>
          <div className="col-10">
            <span style={boldText}>
              {this.refillJsonData("first_name") +
                " " +
                this.refillJsonData("last_name")}
            </span>
            <br />
            <span style={smallText}>{this.refillJsonData("agency_name")}</span>
            <br />
            {/* <div style={{ display: "inline-flex", paddingTop: 5 }}>
              <span style={numberText}>
                {" "}
                &nbsp;&nbsp;
                <img src={Video} alt="" style={{ width: 8, height: 8 }} />{" "}
                &nbsp;
                {this.refillJsonData("total_videos")} &nbsp;{" "}
              </span>
              &nbsp;&nbsp;
              <span style={numberText}>
                {" "}
                &nbsp;&nbsp;
                <img
                  src={Gallery}
                  alt=""
                  style={{ width: 8, height: 8 }}
                />{" "}
                &nbsp;
                {this.refillJsonData("total_images")} &nbsp;{" "}
              </span>
              &nbsp;&nbsp;
            </div> */}
          </div>
        </div>
        <div
          className="row"
          style={{ paddingRight: 10, paddingLeft: 10, paddingBottom: 10 }}
        >
          <div className="col-5">
            <span style={smallText}>Services Offered</span>
          </div>
          <div
            className="col-7"
            style={{ display: "inline-flex", width: "100%" }}
          >
            {servicesData}
          </div>
        </div>
        <div
          className="row"
          style={{ paddingRight: 10, paddingLeft: 10, paddingBottom: 10 }}
        >
          <div className="col-6">
            <span style={smallText}>Location</span>
            <br />
            <span style={boldText}>
              {this.refillJsonData("city") +
                ", " +
                this.refillJsonData("state")}
            </span>
          </div>
          <div className="col-6">
            <span style={smallText}>Years Active</span>
            <br />
            <span style={boldText}>
              {this.refillJsonData("experience")} Years
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default index;
