import React, { Component } from 'react'
import { Switch } from "react-router-dom";
// import { createBrowserHistory } from 'history';
import Dashboard from "./dashboard";
import ChangePassword from "./changePassword";
import PrivateRoute from "./privateRoute";
import ManageUsers from './manageUsers';
import Config from "../../helper/config";

const reactUrl = Config.reactUrl + "/uaadmin";
// const history = createBrowserHistory();

class AgentRouter extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={reactUrl + "/dashboard"} component={Dashboard} />
                <PrivateRoute exact path={reactUrl + "/changepassword"} component={ChangePassword} />
                <PrivateRoute exact path={reactUrl + '/manageusers'} component={ManageUsers} />
            </Switch>
        )
    }
}
export default AgentRouter;