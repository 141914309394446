import React, { Component } from "react";
import Footer from "../manage/footer";
import { withStore } from "../../component/hooks/store";
import { withStyles } from "@material-ui/core/styles";
import Header from "./header";
import { Link } from "react-router-dom";
import Config from "../../helper/config";
import Styles from "../../styles/player/viewAgents";
import {
  checkNullAndReturnString,
  doGetConnectSecurity /*, getAge*/,
} from "../../helper/common";
import {
  //   InputBase,
  //   Select,
  //   MenuItem,
  Container,
  CssBaseline,
} from "@material-ui/core";
import BackArrow from "../../images/backArrow.png";
import AgentCard from "../../component/agentCard";

class ViewAgents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentDetails: [],
    };
  }

  componentDidMount = () => {
    this.getAgentList();
  };

  getAgentList = async () => {
    var accessToken = localStorage.getItem("accessToken");
    var responseData = await doGetConnectSecurity(
      "users?id=&status=&roleId=3&name=&skip=&take=&order=&sortby=",
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.data)) {
        this.setState({ agentDetails: responseData.data });
      }
    }
  };

  render() {
    var { classes } = this.props;
    var { agentDetails } = this.state;
    var agentData = [];
    if (checkNullAndReturnString(agentDetails)) {
      Object.keys(agentDetails).forEach((key) => {
        var item = agentDetails[key];
        agentData.push(
          <div
            className={"col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12"}
            style={{ paddingTop: 15, paddingRight: 10, paddingLeft: 10 }}
          >
            <Link to={Config.reactUrl + "/player/playeragentview"}>
              <AgentCard from={1} item={item} />
            </Link>
          </div>
        );
      });
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <Header from={"Players"} />
        <Container
          maxWidth="xl"
          component="main"
          className={classes.heroContent}
        >
          <div
            className="container-fluid"
            style={{ paddingBottom: 120, paddingTop: 30 }}
          >
            <div class="row rowView">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding">
                <Link to={Config.reactUrl + "/player/dashboard"}>
                  <img src={BackArrow} alt="" className={classes.backArrow} />
                  <span className={classes.backOption}>Back to Results</span>
                </Link>
              </div>
            </div>
            <div class="row rowView" style={{ paddingTop: 20 }}>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding">
                <span class={classes.viewAgentText}>View Agents</span>
              </div>
            </div>
            <div class="row rowView" style={{ paddingTop: 20 }}>
              {agentData}
            </div>
          </div>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(withStore(ViewAgents));
