import React, { Component } from "react";
import { checkNullAndReturnString } from "../helper/common";
import BackArrow from "../images/backArrow.png";

class BackOption extends Component {
    render() {
        var { linkTo } = this.props;
        return (
            <a
                href="#/"
                onClick={(e) => {
                    e.preventDefault();
                    if (checkNullAndReturnString(linkTo)) {
                        this.props.history.push(linkTo);
                    } else {
                        this.props.history.goBack();
                    }
                }}
                style={{ color: "inherit" }}
            >
                <img src={BackArrow} alt="" style={{ width: 31, height: 31 }} />
                <span
                    href="#/"
                    style={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#959595",
                        paddingLeft: 10,
                        opacity: 1,
                    }}
                    className={"commonBackClass"}
                    onClick={e => e.preventDefault()}>
                    Back
                </span>
            </a>
        );
    }
}
export default BackOption;
