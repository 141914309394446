import React, { Component } from "react";
import { withStore } from "../../component/hooks/store";
import { withStyles } from "@material-ui/core/styles";
import { Container /*, CssBaseline */ } from "@material-ui/core";
import Styles from "../../styles/player/dashboard";
// import Footer from "../manage/footer";
import {
  checkNullAndReturnString,
  doGetConnectSecurity,
  getAge,
  positionData,
  positionAverageData,
  positionAverageData1,
  positionAverageData2,
  positionAverageData3,
  doPostFormDataSecurity,
} from "../../helper/common";
import {
  Menu,
  MenuItem,
  // MenuButton,
  // SubMenu
} from "@szhsin/react-menu";
import { Link } from "react-router-dom";
import Config from "../../helper/config";
// import Header from "./header";
import PlayerProgressBar from "./playerProgressBar";
import PlayerCircle from "../../component/circle";
import PlayerGallery from "../../component/gallery";
import EmptyGallery from "../../component/emptyGallery";
// import Button from "../../component/button";
import Facebook from "../../images/facebook.png";
import InfoPng from "../../images/info.png";
import Twitter from "../../images/twitter.png";
import Youtube from "../../images/youtube.png";
import TiktokPng from "../../images/tiktok.png";
import Instagram from "../../images/instagram.png";
import Linkedin from "../../images/linkedin.png";
import EditPng from "../../images/edit.png";
// import UsersPng from "../../images/users.png";
import MenuPng from "../../images/menu.png";
import School from "../../images/school.png";
import Position from "../../images/position.png";
import Home from "../../images/home.png";
import Calender from "../../images/calender.png";
import HeightPng from "../../images/height.png";
import WeighttPng from "../../images/weight.png";
import Star from "../../images/star.png";
import LabelInput from "../../component/labelInput";
import Button from "../../component/button";
import SelectBox from "../../component/selectBox";
import ReactFileReader from "react-file-reader";
import { Modal } from "react-responsive-modal";
import Loading from "../../component/loading";
import { toast } from "react-toastify";
import ShowMoreText from "react-show-more-text";
const parse = require("html-react-parser");

class PlayerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {},
      addCompareStatus: false,
      statePlayerDetail: {},
      highlightsData: [],
      compareUserStatus: false,
      cumulative: true,
      cumulativeData: [],
      // upload Gallery
      loading: false,
      modalHighLight: false,
      mediaType: "image",
      mediaTitle: "",
      gallery: null,
      galleryFileName: "",
    };
  }

  submitGallery = async () => {
    var { mediaType, gallery /*, galleryFileName*/, mediaTitle } = this.state;
    if (checkNullAndReturnString(mediaType) && Number(mediaType) !== 0) {
      if (!checkNullAndReturnString(mediaTitle)) {
        toast.error("Please add title");
        return;
      }
      var successText = "Image has been uploaded successfully";
      if (mediaType === "video") {
        successText = "Video has been uploaded successfully";
      }
      if (checkNullAndReturnString(gallery)) {
        this.setState({ loading: true });
        var accessToken = localStorage.getItem("accessToken");
        var postFormData = new FormData();
        postFormData.append("typeOfFile", mediaType);
        postFormData.append("file", gallery);
        postFormData.append("title", mediaTitle);
        postFormData.append("highlight", true);
        postFormData.append("roleId", "4");
        var responseData = await doPostFormDataSecurity(
          "gallery",
          postFormData,
          accessToken
        );
        if (checkNullAndReturnString(responseData)) {
          if (
            checkNullAndReturnString(responseData.message) &&
            responseData.message === "Success"
          ) {
            this.setState({ loading: false });
            toast.success(successText);
            this.setState({ mediaType: 0, gallery: null, galleryFileName: "" });
            this.onCloseModal();
            this.getHighlightsFile();
          } else {
            this.setState({ loading: false });
            if (checkNullAndReturnString(responseData.error)) {
              if (checkNullAndReturnString(responseData.error.message)) {
                toast.error(responseData.error.message);
              }
            }
          }
        } else {
          this.setState({ loading: false });
        }
      } else {
        toast.error("Please upload image");
      }
    } else {
      toast.error("Please select media type");
    }
  };

  handleFiles1 = (gallery) => {
    this.setState({ gallery: gallery[0], galleryFileName: gallery[0].name });
  };

  updateInputValue = (id, value) => {
    this.setState({
      [id]: value ? value : "",
    });
  };

  onOpenModal = () => {
    this.setState({ modalHighLight: true });
  };

  onCloseModal = () => {
    this.setState({ modalHighLight: false });
  };

  getHighlightsFile = async () => {
    this.setState({ highlightsData: [] });
    var accessToken = localStorage.getItem("accessToken");
    var id = localStorage.getItem("id");
    var profileApi = "gallery/highlights?userId=" + id + "&name=&typeOfFile=";
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.userId
    ) {
      profileApi =
        "gallery/highlights?userId=" +
        this.props.match.params.userId +
        "&name=&typeOfFile=";
    }
    var responseData = await doGetConnectSecurity(profileApi, accessToken);
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.data)) {
        this.setState({
          highlightsData: responseData.data,
        });
      }
    }
  };

  componentDidMount = async () => {
    this.getPlayerProfile();
    this.getHighlightsFile();
  };

  refillJSonData = (key) => {
    var { userDetails = [] } = this.state;
    if (checkNullAndReturnString(userDetails)) {
      if (checkNullAndReturnString(userDetails[key])) {
        return userDetails[key];
      }
    }
    return "";
  };

  refillJSonDataZero = (key) => {
    var { userDetails = [] } = this.state;
    if (checkNullAndReturnString(userDetails)) {
      if (checkNullAndReturnString(userDetails[key])) {
        return userDetails[key];
      }
    }
    return 0;
  };

  refillJSonDataCumulative = (key) => {
    var { cumulativeData } = this.state;
    key = "cumulative_" + key;
    if (checkNullAndReturnString(cumulativeData)) {
      if (checkNullAndReturnString(cumulativeData[key])) {
        return cumulativeData[key];
      }
    }
    return 0;
  };

  compareUserState = (state, playerDetail) => {
    if (
      state &&
      playerDetail &&
      state.compareUser &&
      Array.isArray(state.compareUser) &&
      state.compareUser.length === 1 &&
      playerDetail.length === 1
    ) {
      const userdetail = playerDetail[0];
      const statedetail = state.compareUser[0];

      if (
        (userdetail.position || userdetail.user_player_position) &&
        statedetail.player_position &&
        userdetail.id !== statedetail.id &&
        (userdetail.position || userdetail.user_player_position) ===
          statedetail.player_position
      ) {
        this.setState({
          addCompareStatus: true,
          statePlayerDetail: statedetail,
        });
        this.props.handleCompareStrategy({ ...userdetail }, { ...statedetail });
        return true;
      }
    } else if (
      state &&
      Array.isArray(state.compareUser) &&
      state.compareUser.length === 0 &&
      playerDetail.length === 1
    ) {
      const userdetail = playerDetail[0];
      // const statedetail = state.compareUser[0];
      this.setState({
        addCompareStatus: true,
        statePlayerDetail: {},
      });
      this.props.handleCompareStrategy({ ...userdetail }, null);
      return true;
    }
    return false;
  };

  getPlayerProfile = async () => {
    let profileApi = "player/profile";
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.userId
    ) {
      profileApi = `player/agentWithPlayerId?id=${this.props.match.params.userId}`;
    }
    var accessToken = localStorage.getItem("accessToken");
    var responseData = await doGetConnectSecurity(profileApi, accessToken);
    // const state1 = this.props.playerSearchDetail;
    const state = this.props.location.state;
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.data)) {
        if (checkNullAndReturnString(responseData.data[0])) {
          this.setState({ userDetails: responseData.data[0] });
          let kumulativeApi =
            "player/cumulative?personId=" + responseData.data[0].person_id;
          var responseData1 = await doGetConnectSecurity(
            kumulativeApi,
            accessToken
          );
          if (responseData1) {
            if (responseData1[0]) {
              this.setState({ cumulativeData: responseData1[0] });
            }
          }
        }
        this.compareUserState(
          state && state.playerSearchDetail ? state.playerSearchDetail : [],
          responseData.data
        );
      }
    }
  };

  emptyUserLoader = () => {
    this.src = Config.emptyUser;
  };

  static getDerivedStateFromProps = (props, state) => {
    const stateval = {
      ...state,
      compareUserStatus:
        props &&
        props.playerSearchDetail &&
        props.playerSearchDetail.compareUser
          ? props.playerSearchDetail.compareUser.length === 0
          : true,
    };
    return stateval;
  };

  render() {
    const { classes } = this.props;
    var age = null;
    if (checkNullAndReturnString(this.refillJSonData("date_of_birth"))) {
      age = getAge(this.refillJSonData("date_of_birth"));
    }
    var mediaData = [];
    if (checkNullAndReturnString(this.refillJSonData("socialmedia"))) {
      var socialmedia = this.refillJSonData("socialmedia");
      if (Object.keys(socialmedia).length > 0) {
        Object.keys(socialmedia).forEach((key) => {
          var item = JSON.parse(socialmedia[key]);
          if (
            item.socialMediaType === "facebook" &&
            checkNullAndReturnString(item.socialMediaUserName)
          ) {
            mediaData.push(
              <a
                target={"_blank"}
                rel="noreferrer"
                href={"https://www.facebook.com/" + item.socialMediaUserName}
              >
                <img className={classes.social} src={Facebook} alt="" />
                &nbsp;&nbsp;
              </a>
            );
          }
          if (
            item.socialMediaType === "twitter" &&
            checkNullAndReturnString(item.socialMediaUserName)
          ) {
            mediaData.push(
              <a
                target={"_blank"}
                rel="noreferrer"
                href={"https://www.twitter.com/" + item.socialMediaUserName}
              >
                <img
                  className={classes.social}
                  alt="twitterimg"
                  src={Twitter}
                />
                &nbsp;&nbsp;
              </a>
            );
          }
          if (
            item.socialMediaType === "youtube" &&
            checkNullAndReturnString(item.socialMediaUserName)
          ) {
            mediaData.push(
              <a
                target={"_blank"}
                rel="noreferrer"
                href={
                  "https://www.youtube.com/channel/" + item.socialMediaUserName
                }
              >
                <img
                  className={classes.social}
                  alt="youtubeimg"
                  src={Youtube}
                />
                &nbsp;&nbsp;
              </a>
            );
          }
          // if (item.socialMediaType === "v" && checkNullAndReturnString(item.socialMediaUserName)) {
          //     mediaData.push(
          //         <a target={"_blank"} rel="noreferrer" href={"https://vimeo.com/" + item.socialMediaUserName}>
          //             <img className={classes.social} alt="" src={Vpng} />
          //             &nbsp;&nbsp;
          //         </a>
          //     )
          // }
          if (
            item.socialMediaType === "ticToc" &&
            checkNullAndReturnString(item.socialMediaUserName)
          ) {
            mediaData.push(
              <a
                target={"_blank"}
                rel="noreferrer"
                href={"https://www.tiktok.com/@" + item.socialMediaUserName}
              >
                <img className={classes.social} alt="" src={TiktokPng} />
                &nbsp;&nbsp;
              </a>
            );
          }
          if (
            item.socialMediaType === "instagram" &&
            checkNullAndReturnString(item.socialMediaUserName)
          ) {
            mediaData.push(
              <a
                target={"_blank"}
                rel="noreferrer"
                href={"https://www.instagram.com/" + item.socialMediaUserName}
              >
                <img
                  className={classes.social}
                  alt="instaimg"
                  src={Instagram}
                />
                &nbsp;&nbsp;
              </a>
            );
          }
          if (
            item.socialMediaType === "in" &&
            checkNullAndReturnString(item.socialMediaUserName)
          ) {
            mediaData.push(
              <a
                target={"_blank"}
                rel="noreferrer"
                href={"https://www.linkedin.com/in/" + item.socialMediaUserName}
              >
                <img
                  className={classes.social}
                  alt="linkedinimg"
                  src={Linkedin}
                />
                &nbsp;&nbsp;
              </a>
            );
          }
        });
      }
    }

    // var yds_cast = this.refillJSonDataZero("yds_cast");
    // var tackle_yards_cast = this.refillJSonDataZero("tackle_yards_cast");
    // var rushAttYds =
    //   this.refillJSonDataZero("rzatt_cast") +
    //   "/" +
    //   this.refillJSonDataZero("yds_att");
    if (this.state.cumulative) {
      var cumulativeData = this.refillJSonDataZero("cumulative_data");
      if (checkNullAndReturnString(cumulativeData)) {
        // yds_cast = cumulativeData.cumulative_yards || 0;
        // tackle_yards_cast = cumulativeData.cumulative_tackle_yds || 0;
        // rushAttYds =
        //   cumulativeData.cumulative_att ||
        //   0 + "/" + cumulativeData.cumulative_rush_yds ||
        //   0;
      }
    }

    var benchPress = positionAverageData(
      this.refillJSonData("bench_press"),
      this.refillJSonData("bench_press_target")
    );
    var vertical_jump = positionAverageData(
      this.refillJSonData("vertical_jump"),
      this.refillJSonData("vertical_jump_target")
    );
    var three_core_drill = positionAverageData1(
      this.refillJSonData("three_core_drill"),
      this.refillJSonData("three_cone_target")
    );
    var broad_jump = positionAverageData(
      this.refillJSonData("broad_jump"),
      this.refillJSonData("broad_jump_target")
    );
    // var sixty_yard_shuttle = positionAverageData1(
    //   this.refillJSonData("sixty_yard_shuttle"),
    //   this.refillJSonData("sixty_yard_shuttle_target")
    // );
    var twenty_yard_shuttle = positionAverageData1(
      this.refillJSonData("twenty_yard_shuttle"),
      this.refillJSonData("twenty_yard_shuttle_target")
    );
    var fourty_yard_dash = positionAverageData1(
      this.refillJSonData("fourty_yard_dash"),
      this.refillJSonData("fourty_yard_dash_target")
    );

    var overallCount =
      benchPress +
      vertical_jump +
      three_core_drill +
      broad_jump +
      // sixty_yard_shuttle +
      twenty_yard_shuttle +
      fourty_yard_dash;

    var oveallPercentage = 0;
    if (overallCount !== "Infinity") {
      oveallPercentage = parseFloat(overallCount / 6).toFixed(2);
    }

    var newPositionArray = [];
    var positionArray = positionData(this.refillJSonData("position"));
    if (positionArray.length) {
      var positionConvertNumber = 0;
      while (positionArray.length > 0) {
        if (positionConvertNumber === 0) {
          newPositionArray.push(positionArray.splice(0, 6));
        } else {
          newPositionArray.push(positionArray.splice(0, 6));
        }
        positionConvertNumber = 1;
      }
    }
    var postitionDataFirst = [];
    var postitionDataSecond = [];
    var cumulativeDataFirst = [];
    var cumulativeDataSecond = [];
    if (newPositionArray.length) {
      newPositionArray.map((item, index) => {
        var postitionDataInner = [];
        var cumulativeDataInner = [];
        item.map((item1, index1) => {
          if (index === 0) {
            postitionDataFirst.push(
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <PlayerCircle
                  name={item1.name}
                  description={this.refillJSonDataZero(item1.value)}
                  img={<img className={classes.startImage} alt="" src={Star} />}
                />
              </div>
            );
            cumulativeDataFirst.push(
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <PlayerCircle
                  name={item1.name}
                  description={this.refillJSonDataCumulative(item1.value)}
                  img={<img className={classes.startImage} alt="" src={Star} />}
                />
              </div>
            );
          } else {
            postitionDataInner.push(
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <PlayerCircle
                  name={item1.name}
                  description={this.refillJSonDataZero(item1.value)}
                  img={<img className={classes.startImage} alt="" src={Star} />}
                />
              </div>
            );
            cumulativeDataInner.push(
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <PlayerCircle
                  name={item1.name}
                  description={this.refillJSonDataCumulative(item1.value)}
                  img={<img className={classes.startImage} alt="" src={Star} />}
                />
              </div>
            );
          }
          return true;
        });
        postitionDataSecond.push(
          <div className={"row"}>{postitionDataInner}</div>
        );
        cumulativeDataSecond.push(
          <div className={"row"}>{cumulativeDataInner}</div>
        );
        return true;
      });
    }
    return (
      <Container maxWidth="xl" component="main" className={classes.heroContent}>
        {this.state.loading ? <Loading /> : ""}
        <div
          className="container-fluid"
          style={{
            paddingBottom: 100,
            marginTop: this.state.compareUserStatus ? "0px" : "55px",
          }}
        >
          <div class="row" style={{ padding: 10 }}>
            <div
              class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
              style={{ paddingTop: 30 }}
            >
              <div className={classes.playerCard}>
                <div style={{ display: "flex" }}>
                  <div className={classes.playerNewImage}>
                    {this.state.userDetails.profile_image_hash ? (
                      <img
                        className={classes.playerNewImage}
                        alt=""
                        src={`${Config.dataUrl}aws-files/${this.state.userDetails.profile_image_hash}`}
                        onError={this.emptyUserLoader}
                      />
                    ) : (
                      <img
                        className={classes.playerNewImage}
                        alt=""
                        src={Config.emptyUser}
                      />
                    )}
                  </div>
                  <span className={classes.playerName}>
                    {this.refillJSonData("first_name") +
                      " " +
                      this.refillJSonData("last_name")}
                    <br />
                    <span className={classes.playerGame}>Football Player</span>
                    <br />
                    <br />
                    <span className={classes.leagueText}>
                      {this.refillJSonData("description")
                        ? this.refillJSonData("description")
                        : "Update Status"}
                    </span>
                  </span>
                  {this.props.match &&
                  this.props.match.params &&
                  !this.props.match.params.userId ? (
                    <div
                      style={{
                        position: "absolute",
                        right: 30,
                        top: 50,
                      }}
                    >
                      <Menu
                        styles={{
                          background: "#3B3E50 0% 0% no-repeat padding-box",
                          padding: 10,
                          borderRadius: 10,
                        }}
                        menuButton={
                          <a href={"#/"} onClick={(e) => e.preventDefault()}>
                            <img
                              className={classes.menu}
                              alt=""
                              src={MenuPng}
                            />
                          </a>
                        }
                      >
                        <MenuItem>
                          <Link to={Config.reactUrl + "/player/editprofile"}>
                            <img
                              src={EditPng}
                              alt=""
                              style={{ width: 12, height: 12 }}
                            />
                            <span className={classes.menuItemText}>
                              <span className={"headerMenuHover"}>
                                Edit Profile
                              </span>
                            </span>
                          </Link>
                        </MenuItem>
                        {/* <MenuItem >
                                                        <Link to={Config.reactUrl + "/player/playeragentsearch"}>
                                                            <img src={UsersPng} style={{ width: 12, height: 12 }} />
                                                            <span className={classes.menuItemText}>
                                                                <span className={"headerMenuHover"}>
                                                                    Search Players
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </MenuItem> */}
                      </Menu>
                    </div>
                  ) : null}
                </div>
                <div style={{ paddingTop: 15 }}></div>
                <div className={classes.underLine}></div>
                <div style={{ paddingTop: 10 }}></div>
                <span className={classes.biograpyText}>Biography</span>
                <div style={{ paddingTop: 10 }}></div>
                <span className={classes.biograpyDescription}>
                  <ShowMoreText
                    lines={4}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="my-anchor-css-class"
                    onClick={this.executeOnClick}
                    expanded={false}
                  >
                    {parse(this.refillJSonData("biography"))}
                  </ShowMoreText>
                </span>
                <div style={{ paddingTop: 25 }}></div>
                <div style={{ textAlign: "center" }}>{mediaData}</div>
              </div>
            </div>
            {this.props.match &&
            this.props.match.params &&
            this.props.match.params.userId ? (
              ""
            ) : (
              <div
                class="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2"
                style={{ paddingTop: 120, paddingRight: 25 }}
              >
                <div className={"row"}>
                  <div
                    onClick={() => this.onOpenModal()}
                    class="col-12"
                    style={{
                      // position: "relative",
                      height: "200px",
                      // width: '50px',
                      border: "1px solid rgb(59, 62, 80)",
                      borderRadius: "10px",
                      cursor: "pointer",
                      justifyContent: "center",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        paddingTop: 40,
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className={"editProfilePlusHover"}
                        style={{
                          textAlign: "center",
                          width: 46,
                          height: 46,
                          border: "1px solid #3B3E50",
                          borderRadius: 46 / 2,
                        }}
                      >
                        <span
                          className={"editProfilePlusTextHover"}
                          style={{
                            top: 7,
                            position: "relative",
                            fontSize: 20,
                            color: "rgb(59, 62, 80)",
                          }}
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div style={{ paddingTop: 20 }}>Add Highlight</div>
                  </div>
                </div>
              </div>
            )}
            <div
              class={
                this.props.match &&
                this.props.match.params &&
                this.props.match.params.userId
                  ? "col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                  : "col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12"
              }
              style={{ paddingTop: 30 }}
            >
              {/* <div className={"row"} >
                                <div class="col-12">
                                    <Button buttonStyle={classes.buttonStyle1} buttonName={"Search Agents"} fullWidth={false} onClick={this.onClick} />
                                </div>
                            </div> */}
              <div className={"row"}>
                <div class="col-12">
                  {this.props.children(this.state.addCompareStatus)}
                </div>
              </div>
              <div className={"row"}>
                <div
                  class="col-12"
                  style={{
                    paddingTop: 20,
                    paddingRight: 0,
                    paddingLeft: 0,
                    position: "relative",
                  }}
                >
                  {Object.keys(this.state.highlightsData).length > 0 ? (
                    <PlayerGallery
                      getHighlightsFile={this.getHighlightsFile}
                      classes={classes}
                      highlightsData={this.state.highlightsData}
                      removeStatus={
                        this.props.match &&
                        this.props.match.params &&
                        this.props.match.params.userId
                          ? true
                          : false
                      }
                    />
                  ) : (
                    <EmptyGallery />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="row" style={{ padding: 10 }}>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <span className={classes.playerProfileText}>Player Profile</span>
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <PlayerCircle
                    name={"School"}
                    description={this.refillJSonData("school")}
                    img={
                      <img
                        className={classes.schoolImage}
                        alt=""
                        src={School}
                      />
                    }
                  />
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <PlayerCircle
                    name={"Position"}
                    description={this.refillJSonData("position")}
                    img={
                      <img
                        className={classes.positionImage}
                        alt=""
                        src={Position}
                      />
                    }
                  />
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <PlayerCircle
                    name={"Residence"}
                    description={
                      this.refillJSonData("city") +
                      ", " +
                      this.refillJSonData("state")
                    }
                    img={
                      <img className={classes.homeImage} alt="" src={Home} />
                    }
                  />
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <PlayerCircle
                    name={"Age"}
                    description={age + " Yrs"}
                    img={
                      <img
                        className={classes.calenderImage}
                        src={Calender}
                        alt=""
                      />
                    }
                  />
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <PlayerCircle
                    name={"Height"}
                    description={
                      this.refillJSonData("height_feet") +
                      " Ft. " +
                      this.refillJSonData("height_inches") +
                      " In."
                    }
                    img={
                      <img
                        alt="heightimgs"
                        className={classes.heightImage}
                        src={HeightPng}
                      />
                    }
                  />
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <PlayerCircle
                    name={"Weight"}
                    description={this.refillJSonData("weight") + " LBS"}
                    img={
                      <img
                        className={classes.weightImage}
                        alt="weightimgs"
                        src={WeighttPng}
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
              <div class="row">
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                  style={{ padding: 10 }}
                >
                  <div className={"row"}>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      <span className={classes.playerProfileText}>
                        Player Statistics
                      </span>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      <a
                        href="#/"
                        className={classes.showYearlyText}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            cumulative: !this.state.cumulative,
                          });
                        }}
                      >
                        Show {this.state.cumulative ? "Career" : "Year"} Stats
                      </a>
                    </div>
                  </div>
                  <div
                    className={"scrollViewData"}
                    style={{
                      height: 250,
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <div className={"row"}>
                      {!this.state.cumulative ? (
                        <>{cumulativeDataFirst}</>
                      ) : (
                        <>{postitionDataFirst}</>
                      )}
                    </div>
                    {this.state.cumulative && postitionDataSecond}
                    {!this.state.cumulative && cumulativeDataSecond}
                  </div>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                  style={{ padding: 10 }}
                >
                  <div className={"row"}>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7">
                      <span className={classes.playerProfileText}>
                        Drill Performance
                      </span>
                      &nbsp;&nbsp;
                      <Link to={Config.reactUrl + "/player/playerperformence"}>
                        <img className={classes.social} src={InfoPng} alt="" />
                      </Link>
                    </div>
                    {/* <div
                      class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3"
                      style={{ alignSelf: "center", padding: 0 }}
                    >
                      <span
                        className={classes.overAllText}
                        style={{ float: "right" }}
                      >
                        Overall
                      </span>
                    </div>
                    <div
                      class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2"
                      style={{ alignSelf: "center" }}
                    >
                      <span className={classes.playerProfileText}>
                        {oveallPercentage ? oveallPercentage : 0}%
                      </span>
                    </div> */}
                  </div>
                  <div
                    className={"row"}
                    style={{ paddingTop: 10, alignItems: "center" }}
                  >
                    <div
                      class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-1"
                      style={{ padding: 0 }}
                    />
                    <div
                      class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8"
                      style={{ textAlign: "center" }}
                    >
                      <span className={classes.overAllText}>
                        Rating&nbsp;&nbsp;&nbsp;
                      </span>
                      <span className={classes.playerProfileText}>
                        {oveallPercentage ? oveallPercentage : 0}%
                      </span>
                    </div>
                    <div
                      class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3"
                      style={{ padding: 0 }}
                    >
                      <span className={classes.overAllText}>
                        NFL Combined Position Avg.
                      </span>
                    </div>
                  </div>
                  <div className={"row"} style={{ paddingTop: 10 }}>
                    <div
                      class="col-xl-2 col-lg-3 col-md-2 col-sm-2 col-3"
                      style={{ padding: 0, paddingLeft: 15, paddingRight: 15 }}
                    >
                      <span className={classes.performenceNameText}>
                        {" "}
                        40 YD Dash{" "}
                      </span>
                    </div>
                    <div
                      class="col-xl-8 col-lg-7 col-md-8 col-sm-8 col-7"
                      style={{ alignSelf: "center", padding: 0 }}
                    >
                      <PlayerProgressBar
                        width={positionAverageData1(
                          this.refillJSonData("fourty_yard_dash"),
                          this.refillJSonData("fourty_yard_dash_target")
                        )}
                        addWidth={
                          this.refillJSonData("fourty_yard_dash_target")
                            ? parseFloat(
                                this.refillJSonData("fourty_yard_dash_target")
                              )
                            : "0"
                        }
                        toolTipName={
                          this.refillJSonData("fourty_yard_dash_target")
                            ? this.refillJSonData("fourty_yard_dash_target")
                            : "0"
                        }
                        name={`${
                          this.refillJSonData("fourty_yard_dash")
                            ? this.refillJSonData("fourty_yard_dash")
                            : 0
                        }s / ${positionAverageData2(
                          this.refillJSonData("fourty_yard_dash"),
                          this.refillJSonData("fourty_yard_dash_target")
                        )}%`}
                      />
                    </div>
                    <div
                      class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2"
                      style={{ alignSelf: "center" }}
                    >
                      <span className={classes.performencePercentageText}>
                        {" "}
                        {this.refillJSonData("fourty_yard_dash_target")
                          ? this.refillJSonData("fourty_yard_dash_target")
                          : "0"}
                        s{" "}
                      </span>
                      {/* <span className={classes.performencePercentageText}> 7s </span> */}
                    </div>
                  </div>
                  <div className={"row"} style={{ paddingTop: 10 }}>
                    <div
                      class="col-xl-2 col-lg-3 col-md-2 col-sm-2 col-3"
                      style={{ padding: 0, paddingLeft: 15, paddingRight: 15 }}
                    >
                      <span className={classes.performenceNameText}>
                        {" "}
                        20 YD Shuttle{" "}
                      </span>
                    </div>
                    <div
                      class="col-xl-8 col-lg-7 col-md-8 col-sm-8 col-7"
                      style={{ alignSelf: "center", padding: 0 }}
                    >
                      <PlayerProgressBar
                        width={positionAverageData1(
                          this.refillJSonData("twenty_yard_shuttle"),
                          this.refillJSonData("twenty_yard_shuttle_target")
                        )}
                        addWidth={
                          this.refillJSonData("twenty_yard_shuttle_target")
                            ? this.refillJSonData("twenty_yard_shuttle_target")
                            : "0"
                        }
                        toolTipName={
                          this.refillJSonData("twenty_yard_shuttle_target")
                            ? this.refillJSonData("twenty_yard_shuttle_target")
                            : "0"
                        }
                        name={`${
                          this.refillJSonData("twenty_yard_shuttle")
                            ? this.refillJSonData("twenty_yard_shuttle")
                            : 0
                        }s / ${positionAverageData2(
                          this.refillJSonData("twenty_yard_shuttle"),
                          this.refillJSonData("twenty_yard_shuttle_target")
                        )}%`}
                      />
                    </div>
                    <div
                      class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2"
                      style={{ alignSelf: "center" }}
                    >
                      <span className={classes.performencePercentageText}>
                        {" "}
                        {this.refillJSonData("twenty_yard_shuttle_target")
                          ? this.refillJSonData("twenty_yard_shuttle_target")
                          : "0"}
                        s{" "}
                      </span>
                      {/* <span className={classes.performencePercentageText}>6s </span> */}
                    </div>
                  </div>
                  {/* <div className={"row"} style={{ paddingTop: 10 }}>
                    <div
                      class="col-xl-2 col-lg-3 col-md-2 col-sm-2 col-3"
                      style={{ padding: 0, paddingLeft: 15, paddingRight: 15 }}
                    >
                      <span className={classes.performenceNameText}>
                        {" "}
                        60 YD Shuttle{" "}
                      </span>
                    </div>
                    <div
                      class="col-xl-8 col-lg-7 col-md-8 col-sm-8 col-7"
                      style={{ alignSelf: "center", padding: 0 }}
                    >
                      <PlayerProgressBar
                        width={positionAverageData1(
                          this.refillJSonData("sixty_yard_shuttle"),
                          this.refillJSonData("sixty_yard_shuttle_target")
                        )}
                        addWidth={
                          this.refillJSonData("sixty_yard_shuttle_target")
                            ? this.refillJSonData("sixty_yard_shuttle_target")
                            : "0"
                        }
                        toolTipName={
                          this.refillJSonData("sixty_yard_shuttle_target")
                            ? this.refillJSonData("sixty_yard_shuttle_target")
                            : "0"
                        }
                      />
                    </div>
                    <div
                      class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2"
                      style={{ alignSelf: "center" }}
                    >
                      <span className={classes.performencePercentageText}>
                        {" "}
                        {this.refillJSonData("sixty_yard_shuttle")
                          ? this.refillJSonData("sixty_yard_shuttle")
                          : 0}
                        s{" "}
                      </span>
                    </div>
                  </div> */}
                  <div className={"row"} style={{ paddingTop: 10 }}>
                    <div
                      class="col-xl-2 col-lg-3 col-md-2 col-sm-2 col-3"
                      style={{ padding: 0, paddingLeft: 15, paddingRight: 15 }}
                    >
                      <span className={classes.performenceNameText}>
                        {" "}
                        Broad Jump{" "}
                      </span>
                    </div>
                    <div
                      class="col-xl-8 col-lg-7 col-md-8 col-sm-8 col-7"
                      style={{ alignSelf: "center", padding: 0 }}
                    >
                      <PlayerProgressBar
                        width={positionAverageData(
                          this.refillJSonData("broad_jump"),
                          this.refillJSonData("broad_jump_target")
                        )}
                        addWidth={
                          this.refillJSonData("broad_jump_target")
                            ? this.refillJSonData("broad_jump_target")
                            : "0"
                        }
                        toolTipName={
                          this.refillJSonData("broad_jump_target")
                            ? this.refillJSonData("broad_jump_target")
                            : "0"
                        }
                        name={`${
                          this.refillJSonData("broad_jump")
                            ? this.state.userDetails.broad_jump
                                .toString()
                                .replace(/(\d*)\.(\d*)/gi, `$1'$2"`)
                            : 0
                        } / ${positionAverageData3(
                          this.refillJSonData("broad_jump"),
                          this.refillJSonData("broad_jump_target")
                        )}%`}
                      />
                    </div>
                    <div
                      class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2"
                      style={{ alignSelf: "center" }}
                    >
                      <span className={classes.performencePercentageText}>
                        {" "}
                        {this.refillJSonData("broad_jump_target")
                          ? this.state.userDetails.broad_jump_target
                              .toString()
                              .replace(/(\d*)\.(\d*)/gi, `$1'$2"`)
                          : 0}{" "}
                      </span>
                      {/* <span className={classes.performencePercentageText}> 50 </span> */}
                    </div>
                  </div>
                  <div className={"row"} style={{ paddingTop: 10 }}>
                    <div
                      class="col-xl-2 col-lg-3 col-md-2 col-sm-2 col-3"
                      style={{ padding: 0, paddingLeft: 15, paddingRight: 15 }}
                    >
                      <span className={classes.performenceNameText}>
                        {" "}
                        3 Cone Drill{" "}
                      </span>
                    </div>
                    <div
                      class="col-xl-8 col-lg-7 col-md-8 col-sm-8 col-7"
                      style={{ alignSelf: "center", padding: 0 }}
                    >
                      <PlayerProgressBar
                        width={positionAverageData1(
                          this.refillJSonData("three_core_drill"),
                          this.refillJSonData("three_cone_target")
                        )}
                        addWidth={
                          this.refillJSonData("three_cone_target")
                            ? this.refillJSonData("three_cone_target")
                            : "0"
                        }
                        toolTipName={
                          this.refillJSonData("three_cone_target")
                            ? this.refillJSonData("three_cone_target")
                            : "0"
                        }
                        name={`${
                          this.refillJSonData("three_core_drill")
                            ? this.state.userDetails.three_core_drill
                            : 0
                        }s / ${positionAverageData2(
                          this.refillJSonData("three_core_drill"),
                          this.refillJSonData("three_cone_target")
                        )}%`}
                      />
                    </div>
                    <div
                      class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2"
                      style={{ alignSelf: "center" }}
                    >
                      <span className={classes.performencePercentageText}>
                        {" "}
                        {this.refillJSonData("three_cone_target")
                          ? this.refillJSonData("three_cone_target")
                          : "0"}
                        s{" "}
                      </span>
                      {/* <span className={classes.performencePercentageText}> 9s </span> */}
                    </div>
                  </div>
                  <div className={"row"} style={{ paddingTop: 10 }}>
                    <div
                      class="col-xl-2 col-lg-3 col-md-2 col-sm-2 col-3"
                      style={{ padding: 0, paddingLeft: 15, paddingRight: 15 }}
                    >
                      <span className={classes.performenceNameText}>
                        {" "}
                        Vertical Jump{" "}
                      </span>
                    </div>
                    <div
                      class="col-xl-8 col-lg-7 col-md-8 col-sm-8 col-7"
                      style={{ alignSelf: "center", padding: 0 }}
                    >
                      <PlayerProgressBar
                        width={positionAverageData(
                          this.refillJSonData("vertical_jump"),
                          this.refillJSonData("vertical_jump_target")
                        )}
                        addWidth={
                          this.refillJSonData("vertical_jump_target")
                            ? this.refillJSonData("vertical_jump_target")
                            : "0"
                        }
                        toolTipName={
                          this.refillJSonData("vertical_jump_target")
                            ? this.refillJSonData("vertical_jump_target")
                            : "0"
                        }
                        name={`${
                          this.refillJSonData("vertical_jump")
                            ? this.state.userDetails.vertical_jump
                            : 0
                        }" / ${positionAverageData3(
                          this.refillJSonData("vertical_jump"),
                          this.refillJSonData("vertical_jump_target")
                        )}%`}
                      />
                    </div>
                    <div
                      class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2"
                      style={{ alignSelf: "center" }}
                    >
                      <span className={classes.performencePercentageText}>
                        {" "}
                        {this.refillJSonData("vertical_jump_target")
                          ? this.refillJSonData("vertical_jump_target")
                          : "0"}
                        "{" "}
                      </span>
                      {/* <span className={classes.performencePercentageText}> 50" </span> */}
                    </div>
                  </div>
                  <div className={"row"} style={{ paddingTop: 10 }}>
                    <div
                      class="col-xl-2 col-lg-3 col-md-2 col-sm-2 col-3"
                      style={{ padding: 0, paddingLeft: 15, paddingRight: 15 }}
                    >
                      <span className={classes.performenceNameText}>
                        {" "}
                        Bench Press{" "}
                      </span>
                    </div>
                    <div
                      class="col-xl-8 col-lg-7 col-md-8 col-sm-8 col-7"
                      style={{ alignSelf: "center", padding: 0 }}
                    >
                      <PlayerProgressBar
                        width={positionAverageData(
                          this.refillJSonData("bench_press"),
                          this.refillJSonData("bench_press_target")
                        )}
                        addWidth={
                          this.refillJSonData("bench_press_target")
                            ? this.refillJSonData("bench_press_target")
                            : "0"
                        }
                        toolTipName={
                          this.refillJSonData("bench_press_target")
                            ? this.refillJSonData("bench_press_target")
                            : "0"
                        }
                        name={`${
                          this.refillJSonData("bench_press")
                            ? this.state.userDetails.bench_press
                            : 0
                        } reps / ${positionAverageData3(
                          this.refillJSonData("bench_press"),
                          this.refillJSonData("bench_press_target")
                        )}%`}
                      />
                    </div>
                    <div
                      class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2"
                      style={{ alignSelf: "center" }}
                    >
                      <span className={classes.performencePercentageText}>
                        {" "}
                        {this.refillJSonData("bench_press_target")
                          ? this.refillJSonData("bench_press_target")
                          : "0"}{" "}
                        reps{" "}
                      </span>
                      {/* <span className={classes.performencePercentageText}> 50reps </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.modalHighLight}
          onClose={() => this.onCloseModal()}
          center
          showCloseIcon={false}
        >
          <div
            class=""
            style={{ padding: 50, background: "#2d303a", width: 500 }}
          >
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <span
                className={classes.basicInfoText}
                style={{ paddingLeft: 0 }}
              >
                {" "}
                Title <span style={{ color: "#1891BD" }}>*</span>{" "}
              </span>
              <div style={{ paddingTop: 10 }}></div>
              <LabelInput
                updateInputValue={this.updateInputValue}
                name={"mediaTitle"}
                label={"Title"}
                value={this.state.mediaTitle}
              />
            </div>
            <div style={{ paddingTop: 10 }}></div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <span
                className={classes.basicInfoText}
                style={{ paddingLeft: 0 }}
              >
                {" "}
                Media type <span style={{ color: "#1891BD" }}>*</span>{" "}
              </span>
              <div style={{ paddingTop: 10 }}></div>
              <SelectBox
                onChange={this.updateInputValue}
                keyName={"mediaType"}
                options={[
                  { label: "Select media type", value: 0 },
                  { label: "Image", value: "image" },
                  { label: "Video", value: "video" },
                ]}
                value={this.state.mediaType}
              />
            </div>
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 "
              style={{ paddingLeft: 20, paddingTop: 20 }}
            >
              <ReactFileReader
                handleFiles={this.handleFiles1}
                fileTypes={
                  this.state.mediaType === "image"
                    ? [".png", ".jpg", "jpeg"]
                    : [".mp4", ".mov", ".flv", ".avi"]
                }
              >
                <Button
                  buttonStyle={classes.buttonStyle2}
                  buttonName={"Browse"}
                  extraStyle={{ paddingLeft: 20, paddingRight: 20 }}
                  fullWidth={false}
                  onClick={() => console.log("browse")}
                />
              </ReactFileReader>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {checkNullAndReturnString(this.state.galleryFileName) ? (
                <span className={classes.fileText}>
                  {this.state.galleryFileName}
                </span>
              ) : (
                ""
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {checkNullAndReturnString(this.state.galleryFileName) ? (
                <a
                  href="#/"
                  className={classes.changeText}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ gallery: "", galleryFileName: "" });
                  }}
                >
                  Remove
                </a>
              ) : (
                ""
              )}
              <p className={classes.fileSizeText}>
                Max. file size 5 MB, Only *JPG, *PNG, *TIFF, *FLV, *MOV, *MP4
                File Format
              </p>
            </div>
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
              style={{ paddingTop: 20, textAlign: "center" }}
            >
              <Button
                buttonStyle={classes.buttonStyle3}
                buttonName={"Cancel"}
                onClick={() => this.onCloseModal()}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                buttonStyle={classes.buttonStyle4}
                buttonName={"Submit"}
                onClick={() => this.submitGallery()}
              />
            </div>
          </div>
        </Modal>
      </Container>
      //     <Footer />
      // </React.Fragment>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(
  withStore(PlayerDashboard)
);
