import React, { Component } from "react";
import { withStore } from "../../component/hooks/store";
import { withStyles } from "@material-ui/core/styles";
import {
  checkNullAndReturnString /*, doGetConnectSecurity*/,
} from "../../helper/common";
import {
  //   InputBase,
  //   Select,
  //   MenuItem,
  Container,
  CssBaseline,
} from "@material-ui/core";
import Styles from "../../styles/admin/players";
import Footer from "../manage/footer";
import Config from "../../helper/config";
import Header from "./header";
import Tab from "../../component/tab";
import ImportStatistics from "./importStatistics";
import ReviewMedia from "./reviewMedia";
import PlayerMapping from "./playerMapping";
import ViewPlayer from "./viewPlayer";
import ImportAverage from "./importAverage";

class Players extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      tableData: [],
      fileName: "",
      school: 0,
      userId: "",
      hashEmail: "",
    };
  }

  componentDidMount = async () => {
    var params = this.props.match.params;
    if (checkNullAndReturnString(params)) {
      if (checkNullAndReturnString(params.id)) {
        this.setState({ selected: params.id });
      }
    }
    const queryString = window.location.href;
    let paramString = queryString.split('?')[1];
    if (paramString) {
      let pair = paramString.split('=');
      if (pair) {
        const hashEmail = pair[1];
        this.setState({ hashEmail });
      }      
    }
  };

  tabOnClick = (index) => {
    this.setState({ selected: index });
    this.props.history.push(Config.reactUrl + "/admin/players/" + index);
  };

  render() {
    const { classes } = this.props;
    var { selected } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <Header from={"Players"} />
        <Container
          maxWidth="xl"
          component="main"
          className={classes.heroContent}
        >
          <div className="container-fluid" style={{ paddingBottom: 100 }}>
            <div className={"row rowView"} style={{ paddingTop: 30 }}>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                <Tab
                  json={[
                    "View Players",
                    "Player Mapping",
                    "Import Statistics",
                    "Import Drill Results",
                    "Review Media",
                  ]}
                  selected={selected}
                  tabOnClick={this.tabOnClick}
                />
              </div>
            </div>
            {Number(selected) === 0 ? (
              <ViewPlayer />
            ) : Number(selected) === 2 ? (
              <ImportStatistics hashEmail={this.state.hashEmail}/>
            ) : Number(selected) === 1 ? (
              <PlayerMapping />
            ) : Number(selected) === 4 ? (
              <ReviewMedia />
            ) : Number(selected) === 3 ? (
              <ImportAverage />
            ) : null}
          </div>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(withStore(Players));
