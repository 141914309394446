import LoginBg from "../images/login-bg.png";
import HomeLogo2 from "../images/HomeLogo2.png";
import HomeLogo1 from "../images/HomeLogo1.png";

const styles = theme => ({
    sectionStyle: {
        // position: "absolute",
        // top: 0,
        // left: 0,
        overflow: "hidden",
        width: "100%",
        backgroundImage: "url(" + LoginBg + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    sectionStyle1: {
        // position: "absolute",
        // top: 0,
        // left: 0,
        overflow: "hidden",
        width: "100%",
        backgroundImage: "url(" + HomeLogo1 + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    sectionStyle2: {
        // position: "absolute",
        // top: 0,
        // left: 0,
        overflow: "hidden",
        width: "100%",
        backgroundImage: "url(" + HomeLogo2 + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    paper: {
        marginTop: 160,
        marginBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        zIndex: 4,
        justifyContent: "center",
        // alignItems: 'center',
        alignSelf: "center",
        textAlign: "center"
    },
    appBar: {
        borderBottom: "1px solid #3B3E50",
        background: "transparent",
        paddingBottom: 5,
        paddingTop: 5
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    menu: {
        width: 35,
        height: 35,
    },
    menuItemText: {
        font: "normal normal normal 12px/25px Poppins",
        color: "#FFFFFF",
        opacity: 1,
        paddingLeft: 10
    },
    buttonStyle: {
        background: '#1891BD',
        borderRadius: 5,
        color: 'white',
        height: 40,
        padding: '0 15px',
        opacity: 1,
        textTransform: "uppercase",
        "&:hover": {
            border: "1px solid #1891BD",
            color: '#fff',
        },
        font: "normal normal normal 14px/26px Poppins"
    },
    buttonStyle1: {
        border: "1px solid #FFFFFF",
        // width: '77.38px',
        borderRadius: 5,
        color: '#FFFFFF',
        height: 40,
        padding: '0 15px',
        opacity: 1,
        fontSize: 14,
        textTransform: "uppercase",
        outline: "none",
        "&:hover": {
            background: "#1891BD",
            color: '#fff',
            border: "none"
        },
        font: "normal normal normal 14px/26px Poppins"
    },
    bigFont: {
        font: "normal normal bold 40px/52px Helvetica",
        color: "#FFFFFF",
        opacity: 0.9,
        textTransform: "capitalize",
    },
    bigSmallFont: {
        font: "normal normal bold 30px/38px Helvetica",
        color: "#FFFFFF",
        opacity: 0.9,
        textTransform: "capitalize",
    },
    bigNormalText: {
        font: "normal normal bold 20px/25px Helvetica",
        color: "#FFFFFF",
        opacity: 0.9,
        textTransform: "capitalize",
    },
    smallFont: {
        font: "normal normal normal 15px/25px Poppins",
        color: "#FFFFFF",
        opacity: 0.6,
        paddingTop: 20
    },
    normalSmallFont: {
        font: "normal normal normal 14px/25px Poppins",
        color: "#FFFFFF",
        opacity: 0.6,
        paddingTop: 20
    },
    bigCircle: {
        width: 13,
        height: 13,
        background: "#424268 0% 0% no-repeat padding-box",
        opacity: 1,
        borderRadius: 10
    },
    bigCircleGreen: {
        width: 13,
        height: 13,
        background: "#1891BD 0% 0% no-repeat padding-box",
        opacity: 1,
        borderRadius: 10
    },
    smallCircle: {
        width: 8,
        height: 8,
        background: "#424268 0% 0% no-repeat padding-box",
        opacity: 1,
        borderRadius: 10
    },
    smallCircleGreen: {
        width: 8,
        height: 8,
        background: "#1891BD 0% 0% no-repeat padding-box",
        opacity: 1,
        borderRadius: 10
    },
    aboutNxtText: {
        font: "normal normal normal 18px/23px Helvetica",
        color: "#1891BD",
        opacity: 0.9,
        textTransform: "uppercase",
        paddingTop: 67
    },
    linkFont: {
        font: "normal normal normal 15px/23px Poppins",
        color: "#FFFFFF",
        opacity: 0.6,
        textTransform: "uppercase",
        paddingTop: 20,
        // opacity: 0.8
    },
});
export default styles;