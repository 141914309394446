import React, { Component } from 'react';
import Contract from '../images/contract.png';
import Legal from '../images/legal.png';
import Financial from '../images/financial.png';
import Performance from '../images/performance.png';
import Career from '../images/career.png';
import TickGreen from '../images/tickGreen.png';

class Services extends Component {
    render() {

        const { name, from, status, onClick, id } = this.props;
        return (
            <div class="row" style={{ paddingTop: 10 }}>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ justifyContent: "center", alignSelf: "center" }}>
                    <div class={"hoverServices"} style={{ width: "100%", height: 152, boxShadow: "0px 3px 6px #00000029", border: "1px solid #3B3E50", borderRadius: 10 }}>
                        {
                            from === "admin" ?
                                <img src={TickGreen} alt="TickGreen" style={{ width: 16, height: 16, position: "absolute", top: 10, right: 25 }} />
                                :
                                ""
                        }
                        {
                            from === "editprofile" ?
                                status ?
                                    <a href="#/" style={{ position: "absolute", top: 10, right: 25 }} onClick={() => { onClick(id, false) }}>
                                        <img src={TickGreen} alt="ticks" style={{ width: 16, height: 16 }} />
                                    </a>
                                    :
                                    <a href="#/" style={{ position: "absolute", top: 10, right: 25, width: 16, height: 16, borderRadius: 16 / 2, border: "1px solid rgb(59, 62, 80)" }} onClick={() => { onClick(id, true) }}>
                                    </a>
                                :
                                ""
                        }
                        <div style={{ paddingTop: 37, textAlign: "center" }}>
                            {
                                name === "Legal Representations" ?
                                    <img src={Legal} alt="Legal" style={{ width: 38, height: 38 }} />
                                    :
                                    name === "Contract Negotiations" ?
                                        <img src={Contract} alt="Contract" style={{ width: 40, height: 39 }} />
                                        :
                                        name === "Financial Advisement" ?
                                            <img src={Financial} alt="Financial" style={{ width: 40, height: 40 }} />
                                            :
                                            name === "Performance Training" ?
                                                <img src={Performance} alt="Performance" style={{ width: 35, height: 30 }} />
                                                :
                                                <img src={Career} alt="Career" style={{ width: 40, height: 40 }} />
                            }
                        </div>
                        <div style={{ paddingTop: 20, textAlign: "center" }}>
                            <span style={{
                                font: "normal normal normal 12px/18px Poppins",
                                color: "#FFFFFF",
                                opacity: 1,
                            }}>
                                {name}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Services;