import React, { Component } from 'react'
import { withStore } from './hooks/store';
import { withStyles } from '@material-ui/core/styles';
import Styles from "../styles/player/dashboard";
class Circle extends Component {
    render() {
        const { classes, name, img, description } = this.props;
        return (
            <div class="row" style={{ paddingTop: 10 }}>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                    <div style={{ paddingTop: 25 }}></div>
                    <span className={classes.circleCommon}>
                        {img}
                    </span>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <div style={{ paddingTop: 10 }}></div>
                    <span className={classes.commonCircleName}>
                        {name}
                    </span>
                    <br />
                    <span className={classes.commonCircleDescription}>
                        {description}
                    </span>
                    {/* {
                        type && type == "position" ?
                        <div className={classes.commonCircleDescription} style={{
                            margin: 0,
                            padding: 0,
                            overflowX: "auto",
                            overflowY: "hidden",
                            whiteSpace: "nowrap",
                        }}>
                            {description}
                        </div>
                        :
                        <span className={classes.commonCircleDescription}>
                            {description}
                        </span>
                    }                     */}
                </div>
            </div>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(Circle));