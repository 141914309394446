import React, { Component } from "react";
import {
    /* BrowserRouter as Router, Route, Link*/
    Switch,
} from "react-router-dom";
// // import { createBrowserHistory } from "history";
// import PlayerDashboard from "./dashboard";
import ViewAgents from "./viewAgents";
// import PlayerAgentView from "./playerAgentView";
import PlayerAgentSearch from "./playerAgentSearch";
import ChangePassword from "./changePassword";
import EditProfile from "./editProfile";
import Config from "../../helper/config";
import PrivateRoute from "./privateRoute";
import Message from "./message";
import PlayerParentComponent from "../../component/playerDetail";
import AgentParentComponent from "../../component/agentDetails";
const reactUrl = Config.reactUrl + "/player";
// // const history = createBrowserHistory();

class PlayerRouter extends Component {
    render() {
        return (
            <Switch>
                {/* <PrivateRoute exact path={reactUrl+"/dashboard"}  component={PlayerDashboard}/> */}
                <PrivateRoute
                    exact
                    path={reactUrl + "/dashboard/"}
                    component={PlayerParentComponent}
                />
                <PrivateRoute
                    exact
                    path={reactUrl + "/viewagents"}
                    component={ViewAgents}
                />
                <PrivateRoute
                    exact
                    path={reactUrl + "/playeragentsearch"}
                    component={PlayerAgentSearch}
                />
                <PrivateRoute
                    exact
                    path={reactUrl + "/changepassword"}
                    component={ChangePassword}
                />
                <PrivateRoute
                    exact
                    path={reactUrl + "/editprofile"}
                    component={EditProfile}
                />
                <PrivateRoute
                    exact
                    path={reactUrl + "/playeragentview/:type/:userId"}
                    component={AgentParentComponent}
                />
                <PrivateRoute
                    exact
                    path={reactUrl + "/message/:id?"}
                    component={Message}
                />
            </Switch>
        );
    }
}
export default PlayerRouter;
