import React, { Component } from 'react'

class TextArea extends Component {
    render() {
        var { keyName, value, onChange, placeHolder, type } = this.props;
        return (
            <div>
                <textarea type={type ? type : "text"}
                    value={value}
                    style={{
                        background: "rgb(75 76 92 / 60%)", color: "#FFFFFF",
                        font: "normal 300 14px/18px Poppins",
                        height: 250,
                        border: "none",
                        width: "100%",
                        padding: 13,
                        outline: "none",
                        "&:hover": {
                            outline: "none"
                        },
                        "&:focus": {
                            outline: "none"
                        },
                        borderRadius: 5
                    }}
                    onChange={(e) => onChange(keyName, e.target.value)}
                    placeholder={placeHolder}
                />
            </div>
        )
    }
};

export default TextArea;