const Styles = theme => ({        
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  backOption: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#959595",
    opacity: 1,
    "&:hover": {
      color: "#1891BD"
    },
  },
  backArrow: {
    width: 31,
    height: 31
  },
  viewAgentText: {
    font: "normal normal 600 18px/27px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  viewAgentInnerText :{ 
    font: "normal normal normal 16px/25px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  buttonStyle:{
    background: '#1891BD',
    borderRadius: 5,
    color: 'white',
    height: 40,
    padding: '0 15px',
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    "&:hover": {
      border: "1px solid #1891BD",
    }
  },
  buttonStyle1: {
    border: "1px solid #1891BD",
    borderRadius: 5,
    color: 'white',
    height: 40,
    padding: '0 15px',
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    outline: "none",
    "&:hover": {
      background: "#1891BD"
    },
    float: "right"
  },
});
export default Styles;