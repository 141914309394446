import React, { Component } from "react";
import { Link } from "react-router-dom";
import Config from "../../helper/config";
import { withStore } from "../../component/hooks/store";
import { withStyles } from "@material-ui/core/styles";
import { Container /* CssBaseline */ } from "@material-ui/core";
import Styles from "../../styles/agent/dashboard";
// import Footer from "../manage/footer";
import {
  checkNullAndReturnString,
  doGetConnectSecurity,
  doGetConnect,
  doDeleteConnect,
} from "../../helper/common";
import {
  Menu,
  MenuItem,
  // MenuButton,
  // SubMenu
} from "@szhsin/react-menu";
// import Header from "./header";
import PlayerCircle from "../../component/circle";
import PlayerGallery from "../../component/gallery";
import EmptyGallery from "../../component/emptyGallery";
import Services from "../../component/services";
import FavoritesList from "../../component/favoritesList";
// import Button from "../../component/button";
import Facebook from "../../images/facebook.png";
import Twitter from "../../images/twitter.png";
import Youtube from "../../images/youtube.png";
import TiktokPng from "../../images/tiktok.png";
import Instagram from "../../images/instagram.png";
import Linkedin from "../../images/linkedin.png";
import MenuPng from "../../images/menu.png";
// import School from "../../images/school.png";
// import Position from "../../images/position.png";
import FootballPng from "../../images/football.png";
import AgentPng from "../../images/agent.png";
import ExperiencePng from "../../images/experience.png";
import phoneCallPng from "../../images/phone-call.png";
import LocationPng from "../../images/location.png";
import EditPng from "../../images/edit.png";
// import UsersPng from "../../images/users.png";
// import Home from "../../images/home.png";
// import Calender from "../../images/calender.png";
// import HeightPng from "../../images/height.png";
import { toast } from "react-toastify";
import ShowMoreText from "react-show-more-text";
import PhoneInput from "react-phone-input-2";

const parse = require("html-react-parser");

class AgentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {},
      favoriteLists: [],
      servicesList: [],
      type: "agent",
      highlightsData: [],
    };
  }

  getHighlightsFile = async () => {
    this.setState({ highlightsData: [] });
    var accessToken = localStorage.getItem("accessToken");
    var id = localStorage.getItem("id");
    var profileApi = "gallery/highlights?userId=" + id + "&name=&typeOfFile=";
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.userId
    ) {
      profileApi =
        "gallery/highlights?userId=" +
        this.props.match.params.userId +
        "&name=&typeOfFile=";
    }
    var responseData = await doGetConnectSecurity(profileApi, accessToken);
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.data)) {
        this.setState({
          highlightsData: responseData.data,
        });
      }
    }
  };

  componentDidMount = async () => {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.type
    ) {
      this.setState({ type: this.props.match.params.type });
    }
    this.getPlayerProfile();
    this.getFavoriteList();
    this.getServices();
    this.getHighlightsFile();
  };

  getServices = async () => {
    var responseData = await doGetConnect("master-data/agent/services");
    if (checkNullAndReturnString(responseData)) {
      this.setState({ servicesList: responseData });
    }
  };

  refillJSonData = (key) => {
    var { userDetails = [] } = this.state;
    if (checkNullAndReturnString(userDetails)) {
      if (checkNullAndReturnString(userDetails[key])) {
        return userDetails[key];
      }
    }
    return "";
  };

  getPlayerProfile = async () => {
    var accessToken = localStorage.getItem("accessToken");
    var id = localStorage.getItem("id");
    var apiUrl =
      "users?id=" +
      id +
      "&status=&roleId=3&userAccessStatus&name=&city&state&skip=&take=&order=&sortby=";
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.userId
    ) {
      apiUrl =
        "users?id=" +
        this.props.match.params.userId +
        "&status=&roleId=3&userAccessStatus&name=&city&state&skip=&take=&order=&sortby=";
    }
    var responseData = await doGetConnectSecurity(apiUrl, accessToken);
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.data)) {
        if (checkNullAndReturnString(responseData.data[0])) {
          this.setState({ userDetails: responseData.data[0] });
        }
      }
    }
  };

  getFavoriteList = async () => {
    try {
      var accessToken = localStorage.getItem("accessToken");
      var id = localStorage.getItem("id");
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.userId
      ) {
        id = this.props.match.params.userId;
      }
      var responseData = await doGetConnectSecurity(
        "player-favorite/" + id,
        accessToken
      );
      if (responseData && responseData.data && responseData.data.length > 0) {
        this.setState({ favoriteLists: responseData.data });
      } else {
        this.setState({ favoriteLists: [] });
      }
    } catch (err) {
      this.setState({ favoriteLists: [] });
    }
  };

  defaultImage(img) {
    img.target.src = Config.emptyUser;
  }

  searchPlayer() {
    this.props.history.push("agentplayersearch");
  }

  makingPlayerUnfavorite = async (id) => {
    if (!id) {
      return;
    }
    try {
      var accessToken = localStorage.getItem("accessToken");
      var res = await doDeleteConnect(`player-favorite/${id}`, accessToken);
      if (res.status && Number(res.status) === 200) {
        this.getFavoriteList();
      }
    } catch (err) {
      toast.error(
        err && err.message
          ? err.message
          : "Error while removing favorite player"
      );
    }
  };
  render() {
    const { classes } = this.props;
    var contentColServiceOffred =
      "col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12";
    var contentColServiceOffred1 = "col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6";
    if (this.state.type !== "agent") {
      contentColServiceOffred1 = "col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6";
      contentColServiceOffred =
        "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12";
    }
    var { servicesList } = this.state;
    var serviceData = [];
    if (checkNullAndReturnString(servicesList)) {
      var service = this.refillJSonData("service_offered");
      if (checkNullAndReturnString(service)) {
        Object.keys(service).forEach((key) => {
          var serviceArray = servicesList.filter(
            (servicesList) =>
              (servicesList.id && servicesList.id.toString()) ===
              (service[key] && service[key].toString())
          );
          if (checkNullAndReturnString(serviceArray)) {
            if (checkNullAndReturnString(serviceArray[0])) {
              serviceData.push(
                <div style={{ padding: 5 }} class={contentColServiceOffred1}>
                  {this.state.type === "agent" ? (
                    <Services name={serviceArray[0].service_name} />
                  ) : (
                    <Services name={serviceArray[0].service_name} />
                  )}
                </div>
              );
            }
          }
        });
      }
    }
    var mediaData = [];
    if (checkNullAndReturnString(this.refillJSonData("socialmedia"))) {
      var socialmedia = this.refillJSonData("socialmedia");
      if (Object.keys(socialmedia).length > 0) {
        Object.keys(socialmedia).forEach((key) => {
          var item = JSON.parse(socialmedia[key]);
          if (
            item.socialMediaType === "facebook" &&
            checkNullAndReturnString(item.socialMediaUserName)
          ) {
            mediaData.push(
              <a
                target={"_blank"}
                rel="noreferrer"
                href={"https://www.facebook.com/" + item.socialMediaUserName}
              >
                <img
                  className={classes.social}
                  alt="facebookicons"
                  src={Facebook}
                />
                &nbsp;&nbsp;
              </a>
            );
          }
          if (
            item.socialMediaType === "twitter" &&
            checkNullAndReturnString(item.socialMediaUserName)
          ) {
            mediaData.push(
              <a
                target={"_blank"}
                rel="noreferrer"
                href={"https://www.twitter.com/" + item.socialMediaUserName}
              >
                <img
                  className={classes.social}
                  alt="twittericons"
                  src={Twitter}
                />
                &nbsp;&nbsp;
              </a>
            );
          }
          if (
            item.socialMediaType === "youtube" &&
            checkNullAndReturnString(item.socialMediaUserName)
          ) {
            mediaData.push(
              <a
                target={"_blank"}
                rel="noreferrer"
                href={
                  "https://www.youtube.com/channel/" + item.socialMediaUserName
                }
              >
                <img className={classes.social} alt="social" src={Youtube} />
                &nbsp;&nbsp;
              </a>
            );
          }
          // if (item.socialMediaType === "v" && checkNullAndReturnString(item.socialMediaUserName)) {
          //     mediaData.push(
          //         <a target={"_blank"} rel="noreferrer" href={"https://vimeo.com/" + item.socialMediaUserName}>
          //             <img className={classes.social} alt="vpng" src={Vpng} />
          //             &nbsp;&nbsp;
          //         </a>
          //     )
          // }
          if (
            item.socialMediaType === "ticToc" &&
            checkNullAndReturnString(item.socialMediaUserName)
          ) {
            mediaData.push(
              <a
                target={"_blank"}
                rel="noreferrer"
                href={"https://www.tiktok.com/@" + item.socialMediaUserName}
              >
                <img className={classes.social} alt="" src={TiktokPng} />
                &nbsp;&nbsp;
              </a>
            );
          }
          if (
            item.socialMediaType === "instagram" &&
            checkNullAndReturnString(item.socialMediaUserName)
          ) {
            mediaData.push(
              <a
                target={"_blank"}
                rel="noreferrer"
                href={"https://www.instagram.com/" + item.socialMediaUserName}
              >
                <img
                  className={classes.social}
                  alt="instagram"
                  src={Instagram}
                />
                &nbsp;&nbsp;
              </a>
            );
          }
          if (
            item.socialMediaType === "in" &&
            checkNullAndReturnString(item.socialMediaUserName)
          ) {
            mediaData.push(
              <a
                target={"_blank"}
                rel="noreferrer"
                href={"https://www.linkedin.com/in/" + item.socialMediaUserName}
              >
                <img className={classes.social} alt="linedin" src={Linkedin} />
                &nbsp;&nbsp;
              </a>
            );
          }
        });
      }
    }
    return (
      // <React.Fragment>
      //     <CssBaseline />
      //     <Header />
      <Container maxWidth="xl" component="main" className={classes.heroContent}>
        <div className="container-fluid" style={{ paddingBottom: 110 }}>
          <div class="row" style={{ padding: 10 }}>
            <div
              class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
              style={{ paddingTop: 30 }}
            >
              <div className={classes.playerCard}>
                <div style={{ display: "inline-flex" }}>
                  <div className={classes.agentImage}>
                    <img
                      className={classes.agentImage}
                      alt="playerImageaws"
                      src={
                        Config.dataUrl +
                        "aws-files/" +
                        this.state.userDetails.profile_image_hash
                      }
                      onError={this.defaultImage}
                    />
                  </div>
                  <span className={classes.playerName}>
                    {this.refillJSonData("first_name") +
                      " " +
                      this.refillJSonData("last_name")}
                    <br />
                    <span className={classes.playerGame}>Agent</span>
                    <br />
                    <br />
                    <span className={classes.leagueText}>
                      {this.refillJSonData("description")
                        ? this.refillJSonData("description")
                        : "Update Status"}
                    </span>
                  </span>
                  {this.props.match &&
                  this.props.match.params &&
                  !this.props.match.params.userId ? (
                    <div
                      style={{
                        position: "absolute",
                        right: 30,
                        top: 50,
                      }}
                    >
                      <Menu
                        styles={{
                          background: "#3B3E50 0% 0% no-repeat padding-box",
                          padding: 10,
                          borderRadius: 10,
                        }}
                        menuButton={
                          <a href="#/" onClick={(e) => e.preventDefault()}>
                            <img
                              className={classes.menu}
                              alt="MenuPng"
                              src={MenuPng}
                            />
                          </a>
                        }
                      >
                        <MenuItem>
                          <Link to={Config.reactUrl + "/agent/editprofile"}>
                            <img
                              src={EditPng}
                              alt="editspng"
                              style={{ width: 12, height: 12 }}
                            />
                            <span className={classes.menuItemText}>
                              <span className={"headerMenuHover"}>
                                Edit Profile
                              </span>
                            </span>
                          </Link>
                        </MenuItem>
                        {/* <MenuItem >
                                                        <Link to={Config.reactUrl + "/agent/agentplayersearch"}>
                                                            <img src={UsersPng} style={{ width: 12, height: 12 }} />
                                                            <span className={classes.menuItemText}>
                                                                <span className={"headerMenuHover"}>
                                                                    Search Players
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </MenuItem> */}
                      </Menu>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div style={{ paddingTop: 15 }}></div>
                <div className={classes.underLine}></div>
                <div style={{ paddingTop: 10 }}></div>
                <span className={classes.biograpyText}>Short Biography</span>
                <div style={{ paddingTop: 10 }}></div>
                <span className={classes.biograpyDescription}>
                  <ShowMoreText
                    lines={4}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="my-anchor-css-class"
                    onClick={this.executeOnClick}
                    expanded={false}
                  >
                    {parse(this.refillJSonData("biography"))}
                  </ShowMoreText>
                </span>
                <div style={{ paddingTop: 25 }}></div>
                <div style={{ textAlign: "center" }}>{mediaData}</div>
              </div>
            </div>
            <div
              class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
              style={{ paddingTop: 30 }}
            >
              {/* <div className={"row"} >
                                    <div class="col-12">
                                        <Button buttonStyle={classes.buttonStyle1} buttonName={"Search Players"}
                                            fullWidth={false} onClick={() => this.searchPlayer()} />
                                    </div>
                                </div> */}
              <div className={"row"}>
                <div class="col-12">
                  {this.props.children(this.state.addCompareStatus)}
                </div>
              </div>
              <div className={"row"}>
                <div
                  class="col-12"
                  style={{
                    paddingTop: 20,
                    paddingRight: 0,
                    paddingLeft: 0,
                    position: "relative",
                  }}
                >
                  {Object.keys(this.state.highlightsData).length > 0 ? (
                    <PlayerGallery
                      getHighlightsFile={this.getHighlightsFile}
                      classes={classes}
                      highlightsData={this.state.highlightsData}
                      removeStatus={
                        this.props.match &&
                        this.props.match.params &&
                        this.props.match.params.userId
                          ? true
                          : false
                      }
                    />
                  ) : (
                    <EmptyGallery />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="row" style={{ padding: 10 }}>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <span className={classes.playerProfileText}>Agent Profile</span>
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <PlayerCircle
                    name={"Agency"}
                    description={this.refillJSonData("agency_name")}
                    img={
                      <img
                        className={classes.schoolImage}
                        alt="agentpng"
                        src={AgentPng}
                      />
                    }
                  />
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <PlayerCircle
                    name={"Phone"}
                    description={
                      this.refillJSonData("phoneNumber") ? (
                        <PhoneInput
                          inputClass={"labelPhone"}
                          country={"us"}
                          inputStyle={{
                            width: "100%",
                            background: "transparent",
                            border: "transparent",
                            color: "white",
                          }}
                          containerStyle={{
                            background: "transparent",
                            border: "transparent",
                          }}
                          buttonStyle={{
                            display: "none",
                          }}
                          disabled
                          value={this.refillJSonData("phoneNumber")}
                        />
                      ) : (
                        ""
                      )
                    }
                    img={
                      <img
                        className={classes.positionImage}
                        alt="EducationPng"
                        src={phoneCallPng}
                      />
                    }
                  />
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <PlayerCircle
                    name={"Years Active"}
                    description={this.refillJSonData("experience") + " Yrs."}
                    img={
                      <img
                        className={classes.homeImage}
                        alt="ExperiencePng"
                        src={ExperiencePng}
                      />
                    }
                  />
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <PlayerCircle
                    name={"Sport"}
                    description={"Football"}
                    img={
                      <img
                        className={classes.calenderImage}
                        alt="FootballPng"
                        src={FootballPng}
                      />
                    }
                  />
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <PlayerCircle
                    name={"Location"}
                    description={
                      this.refillJSonData("city") +
                      ", " +
                      this.refillJSonData("state")
                    }
                    img={
                      <img
                        className={classes.heightImage}
                        alt="FootballPng"
                        src={LocationPng}
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
              <div class="row">
                <div class={contentColServiceOffred} style={{ padding: 10 }}>
                  <span className={classes.playerProfileText}>
                    Services Offered
                  </span>
                  <div className={"row"}>{serviceData}</div>
                </div>
                {this.state.type === "agent" ? (
                  <div
                    class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                    style={{ padding: 10 }}
                  >
                    <div className={"row"}>
                      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7">
                        <span className={classes.playerProfileText}>
                          Favorites List
                        </span>
                      </div>
                    </div>
                    <div style={{ paddingTop: 10 }}></div>
                    <div
                      style={
                        Object.keys(this.state.favoriteLists).length > 5
                          ? {
                              height: 300,
                              overflow: "hidden",
                              overflowY: "scroll",
                            }
                          : {}
                      }
                    >
                      {Object.keys(this.state.favoriteLists).length === 0 ? (
                        <span className={classes.playerGame}>
                          {" "}
                          No Favorites List{" "}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.state.favoriteLists.map((ele) => (
                        <React.Fragment key={ele.id}>
                          <FavoritesList
                            id={ele.id}
                            type={this.state.type}
                            img={ele.profile_image_hash}
                            name={`${ele.first_name} ${ele.last_name}`}
                            description={"Football Player"}
                            makingPlayerUnfavorite={this.makingPlayerUnfavorite}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
      //     <Footer />
      // </React.Fragment>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(
  withStore(AgentDashboard)
);
