import React, { Component } from 'react'
import { withStore } from '../../component/hooks/store';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import {
    Menu,
    MenuItem,
    // MenuButton,
    // SubMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import Styles from "../../styles/player/header";
import NxtLogo from "../../images/nxtpick-logo-header.png";
// import Notification from "../../images/notification.png";
// import Message from "../../images/message.png";
import MenuPng from "../../images/menu.png";
import LockPng from "../../images/lock.png";
import ExitPng from "../../images/exit.png";
import InformationPng from "../../images/information.png";
// import Star from "../../images/star.png";
// import { encrypt, decrypt } from 'react-crypt-gsm';
import { checkNullAndReturnString, doDeleteConnect, doGetConnectSecurity } from '../../helper/common';
import config from '../../helper/config';
import Button from '../../component/button';
import 'react-responsive-modal/styles.css';
import { toast } from 'react-toastify';
import { Modal } from 'react-responsive-modal';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            fullName: "",
            modal: false,
            loginuser: null,
            versiondetails: null
        }
    }

    componentDidMount = async () => {
        var firstName = localStorage.getItem("firstName");
        var lastName = localStorage.getItem("lastName");
        var fullName = firstName + " " + lastName;
        var name = "";
        if (checkNullAndReturnString(firstName)) {
            if (checkNullAndReturnString(lastName)) {
                name = firstName[0] + lastName[0]
            }
            else {
                name = firstName[0]
            }
        }
        this.setState({ fullName, name });
        let userDetail = localStorage.getItem("userDetail");
        if (userDetail) {
            this.setState({
                loginuser: JSON.parse(userDetail)
            });
        } else {
            this.setState({ loginuser: null })
        }
        this.getVersionDetail();
    }

    // getVersionDetail = async () => {
    //     try {
    //         var accessToken = localStorage.getItem("accessToken");
    //         var responseData1 = await doGetConnectSecurity(
    //             `version-history?skip=0&take=1&sortby=id&order=DESC`
    //             , accessToken)
    //         if (checkNullAndReturnString(responseData1.data)) {
    //             const { data: [dataone] } = responseData1;
    //             if (checkNullAndReturnString(dataone)) {
    //                 this.setState({
    //                     versiondetails: dataone
    //                 })
    //             } else {
    //                 throw Error({ message: 'Error while getting version details' });
    //             }
    //         } else {
    //             throw Error({ message: 'Error while getting version details' });
    //         }
    //     } catch (err) {
    //         toast.error(err.message ? err.message : 'Error while getting version details')
    //     }
    // }


    getVersionDetail = async () => {
        try {
            var accessToken = localStorage.getItem("accessToken");
            var responseData1 = await doGetConnectSecurity(
              `version-history?skip=0&take=1&sortby=id&order=DESC&type=web`,
              accessToken
            );

            if (responseData1 && responseData1.data && responseData1.data.length) {
                const { data: [dataone] } = responseData1;
                this.setState({
                    versiondetails: dataone
                })
            }
        } catch (err) {
            toast.error(err.message ? err.message : 'Error while getting version details')
        }
    }

    onOpenModal = () => {
        this.setState({ modal: true })
    };

    onCloseModal = () => {
        this.setState({ modal: false })
    };

    logoutFunction = async () => {
        var refreshToken = localStorage.getItem("refreshToken")
        var responseData = await doDeleteConnect("refresh-token?token=" + refreshToken)
        if (checkNullAndReturnString(responseData)) {
            localStorage.clear();
            window.location.href = config.reactUrl + "/logout";
        }
    };

    menuselected = (name) => {
        if (name === "Change Password") {
            this.props.history.push('/coachuser/changepassword')
        } else if (name === "Logout") {
            this.onOpenModal()
        } else if (name === "FAQ") {

        }
    }

    render() {
        const { classes } = this.props;
        const { versiondetails } = this.state;

        const menuList = [
            { image: LockPng, name: 'Change Password' },
            { image: InformationPng, name: 'FAQ' },
            { image: ExitPng, name: 'Logout' },
        ];

        return (
            <div>
                <nav class="navbar navbar-expand-md fixed-top" style={{
                    borderBottom: "1px solid #3B3E50",
                    background: "#262834",
                }}>
                    <Link to={config.reactUrl + "/uaadmin/dashboard"} variant="button" className={"navbar-brand"} style={{ paddingLeft: 20 }}>
                        <img src={NxtLogo} style={{ width: 210, height: 22 }} alt="logoimage" />
                    </Link>
                    {
                        (versiondetails && versiondetails.version_id &&
                            <a href="/version-history/details" target="_blank" class="navbar-brand"
                                className={classes.vname}
                            >
                                {'v'.concat(versiondetails.version_id)}
                            </a>)
                    }
                    <div class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <img className={classes.menu} src={MenuPng} alt="menuicons" />
                    </div>
                    <div class="collapse navbar-collapse" id="navbarCollapse">
                        <div class="navbar-nav ml-auto" style={{ paddingRight: 40 }}>
                            {/* <div class="nav-item nav-link" style={{ paddingRight: 15 }}>
                                <a href="#/" className={classes.circle} onClick={e => e.preventDefault()}>
                                    <img src={Message} className={classes.message} alt="messageicon" />
                                </a>
                            </div>
                            <div class="nav-item nav-link" style={{ paddingRight: 15 }}>
                                <a href="#/" className={classes.circle} onClick={e => e.preventDefault()}>
                                    <img src={Notification} className={classes.notification} alt="notificationicon" />
                                </a>
                            </div> */}
                            <div class="nav-item nav-link" style={{ paddingRight: 20 }}>
                                <Menu styles={{
                                    background: "#3B3E50 0% 0% no-repeat padding-box",
                                    padding: 10
                                }} menuButton={
                                    <a href="#/" className={"headerNameHover"} style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                        onClick={e => e.preventDefault()}>
                                        <div style={{ padding: 3, width: 200, display: "inline-flex" }}>
                                            <span className={classes.nameCircle} style={{ position: "absolute" }}>
                                                <span className={classes.circleText}> {this.state.name} </span>
                                            </span>
                                            <span style={{ paddingLeft: 10 }}></span>
                                            <span href="#" style={{ position: "relative", left: 40, width: 160, display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                <span className={classes.fullNameText}>{this.state.fullName}</span>
                                                <p className={classes.userTypeText}>Coach</p>
                                            </span>
                                        </div>
                                    </a>
                                }>
                                    {
                                        menuList.map((ele, ind) => (
                                            <MenuItem onClick={() => this.menuselected(ele.name)}>
                                                <img src={ele.image} style={{ width: 12, height: 12 }} alt="images" />
                                                <span className={classes.menuItemText} >
                                                    <span className={"headerMenuHover"}>
                                                        {ele.name}
                                                    </span>
                                                </span>
                                            </MenuItem>
                                        ))
                                    }
                                    {/* <MenuItem >
                                        <Link to={config.reactUrl + "/uaadmin/manageusers"}>
                                            <img src={UsersPng} style={{ width: 12, height: 12 }} />
                                            <span className={classes.menuItemText} >
                                                <span className={"headerMenuHover"}>
                                                    Manage Users
                                                </span>
                                            </span>
                                        </Link>
                                    </MenuItem>

                                    <MenuItem >
                                        <Link to={config.reactUrl + "/uaadmin/changepassword"}>
                                            <img src={LockPng} style={{ width: 12, height: 12 }} />
                                            <span className={classes.menuItemText} >
                                                <span className={"headerMenuHover"}>
                                                    Change Password
                                                </span>
                                            </span>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem >
                                        <img src={InformationPng} style={{ width: 12, height: 12 }} />
                                        <span className={classes.menuItemText}>
                                            <span className={"headerMenuHover"}>
                                                FAQ
                                            </span>
                                        </span>
                                    </MenuItem>
                                    <MenuItem >
                                        <img src={ExitPng} style={{ width: 12, height: 12 }} />
                                        <span className={classes.menuItemText}>
                                            <span className={"headerMenuHover"} onClick={() => this.onOpenModal()}>
                                                Logout
                                            </span>
                                        </span>
                                    </MenuItem> */}
                                </Menu>
                            </div>
                        </div>
                    </div>
                </nav>
                <Modal open={this.state.modal} onClose={() => this.onCloseModal()} center showCloseIcon={false}>
                    <div style={{ padding: 20 }}>
                        <p style={{ font: "normal normal 600 18px/27px Poppins", opacity: 1 }}>Logout</p>
                        <p style={{ font: "normal normal normal 14px/21px Poppins", opacity: 1 }}>
                            Are you sure to logout from current session?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                        <p style={{ float: "right" }}>
                            <Button buttonStyle={classes.buttonStyle1} buttonName={"Cancel"} fullWidth={false} onClick={() => {
                                this.onCloseModal();
                            }} />
                            <span style={{ paddingRight: 20 }}></span>
                            <Button buttonStyle={classes.buttonStyle} buttonName={"Logout"} fullWidth={false} onClick={() => {
                                this.logoutFunction();
                            }} />
                        </p>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(Header));