const Styles = theme => ({        
    heroContent: {
      padding: theme.spacing(8, 0, 6),
    },   
    buttonStyle: {
      border: "1px solid #1891BD",
      borderRadius: 5,
      color: 'white',
      height: 40,
      padding: '0 15px',
      opacity: 1,
      fontSize: 14,
      textTransform: "uppercase",
      outline: "none",
      float: "right",
      background: "#1891BD",    
      margin: 10,
    },
    backOption: {
      font: "normal normal normal 12px/18px Poppins",
      color: "#959595",
      opacity: 1,
      paddingLeft: 10,
      "&:hover": {
        color: "#1891BD"
      },
    },
    playerCompareText: {
        font: "normal normal 600 18px/27px Poppins",
        color: "#FFFFFF",
        opacity: 1,
        paddingLeft: 10,        
    },
    playerName:{ 
        font: "normal normal normal 12px/18px Poppins",
        color: "#FFFFFF",
        opacity: 1,
        paddingRight: 20
    },
    playerName1:{ 
        font: "normal normal normal 12px/18px Poppins",
        color: "#FFFFFF",
        opacity: 1,
        paddingLeft: 20
    },
    playerDescription:{ 
        font: "normal normal normal 10px/16px Poppins",
        color: "#959595",
        opacity: 1,
        float: 'right'
    },
    playerDescription1:{ 
        font: "normal normal normal 10px/16px Poppins",
        color: "#959595",
        opacity: 1,
        float: 'left'
    },
    slash: {
        color: "#3B3E50",
    },
    verses: {
        font: "normal normal normal 10px/16px Poppins",
        color: "#959595",
        opacity: 1,
    },
    startComparitionText:{ 
        font: "normal normal normal 14px/21px Poppins",
        color: "#ffffff",
        opacity: 1,
        paddingTop: 10
    },
    numberText: {
        font: "normal normal normal 14px/21px Poppins",
        color: "#FFFFFF",
        opacity: 1,
    },
    numberTextHiglight: {
        font: "normal normal normal 14px/21px Poppins",
        color: "#FFFFFF",
        opacity: 1,
        background: "#909972",
        borderRadius: 5,
    },
    smallNumberText: {
        font: "normal normal normal 12px/18px Poppins",
        color: "#FFFFFF",
        opacity: 1,
        paddingTop: 5
    },
    smallNumberTextHiglight: {
        font: "normal normal normal 12px/18px Poppins",
        color: "#FFFFFF",
        opacity: 1,
        paddingRight: 5,
        paddingLeft: 5,
        background: "#909972",
        borderRadius: 5,
        marginTop: 6,
    },
    centerText: {
        font: "normal normal normal 12px/18px Poppins",
        color: "#959595",
        opacity: 1
    }
  });
  export default Styles;