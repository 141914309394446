import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { checkNullAndReturnString, doGetConnectSecurity, doPutConnectSecurity } from '../../helper/common';
import { withStore } from '../../component/hooks/store';
import { withStyles } from '@material-ui/core/styles';
import Styles from "../../styles/admin/agents";
import Button from "../../component/button";
import Config from '../../helper/config';
import { toast } from 'react-toastify';
import PopOver from "../../component/popOver";
import PhoneInput from 'react-phone-input-2';

class AgentPending extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingList: [],
            pendingTotal: 0,
            sortBy: "",
            order: "asc"
        }
    }

    componentDidMount = async () => {
        this.getAgentPendingList(this.state.sortBy, this.state.order);
    }

    changeStatus = async (id, val) => {
        var accessToken = localStorage.getItem("accessToken");
        var postJson = {
            status: val,
            roleId: "3",
            playerProfileId: ""
        }
        var responseData = await doPutConnectSecurity("users/access/status/" + id, postJson, accessToken)
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.message)) {
                toast.success(responseData.message);
                this.getAgentPendingList(this.state.sortBy, this.state.order);
            }
            else {
                if (checkNullAndReturnString(responseData.error)) {
                    if (checkNullAndReturnString(responseData.error.message)) {
                        toast.error(responseData.error.message);
                    }
                }
            }
        }
    }

    getAgentPendingList = async (sortBy, order) => {

        this.setState({ pendingTotal: 0, pendingList: [] })
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doGetConnectSecurity("users?id=&status=&roleId=3&name=&skip=&take=&order="+order+"&sortby="+sortBy+"&userAccessStatus=pending", accessToken)
        if (checkNullAndReturnString(responseData)) {
            this.setState({ pendingTotal: responseData.total })
            if (checkNullAndReturnString(responseData.data)) {
                this.setState({ pendingList: responseData.data })
            }
        }
    }

    render() {
        var { pendingList, pendingTotal } = this.state
        const { classes } = this.props;
        var pendingData = [];
        if (checkNullAndReturnString(pendingList)) {
            Object.keys(pendingList).forEach(key => {
                var item = pendingList[key];
                pendingData.push(
                    <div>
                        <div style={{ paddingTop: 10 }}></div>
                        <div className={classes.border} ></div>
                        <div class="row" >
                            <div class="col" style={{ maxWidth: 100, paddingTop: 12, alignSelf: "center", textAlign: "center" }}>
                                {
                                    checkNullAndReturnString(item.profile_image_hash) ?
                                        <img className={classes.personImage} alt="personImageaws" src={Config.dataUrl + "aws-files/" + item.profile_image_hash} />
                                        :
                                        <img className={classes.personImage} alt="personImage" src={Config.emptyUser} />
                                }
                            </div>
                            <div class="col" style={{ paddingTop: 12, alignSelf: "center" }}>
                                <span className={classes.playerName}> {item.first_name} {item.last_name} </span>
                            </div>
                            <div class="col" style={{ paddingTop: 12 }} >
                                <span className={classes.player}> Agency </span>
                                <br />
                                <span className={classes.playerName}> {item.agency_name} </span>
                            </div>
                            <div class="col" style={{ paddingTop: 12 }}>
                                <span className={classes.player}> Years Active </span>
                                <br />
                                <span className={classes.playerName}> {item.experience} Years</span>
                            </div>
                            <div class="col" style={{ paddingTop: 12 }}>
                                <span className={classes.player}> Phone Number </span>
                                <br />
                                <span className={classes.playerName}>
                                    {
                                         item.phoneNumber ? <PhoneInput
                                         inputClass={"labelPhoneAgent"}
                                         country={'us'}
                                         inputStyle={{ width: "100%",
                                         background: "transparent",
                                         border: "transparent",
                                         color: "white",
                                      }}
                                      containerStyle={{
                                         background: "transparent",
                                         border: "transparent",
                 
                                      }}
                                      buttonStyle={{
                                          display: "none"
                                      }}
                                      disabled
                                         value={item.phoneNumber}
                                         /> : ""
                                     }
                                </span>
                            </div>
                            <div class="col" style={{ paddingTop: 12 }}>
                                <span className={classes.player}> Location </span>
                                <br />
                                <span className={classes.playerName}> {item.city}, {item.state} </span>
                            </div>                            
                            <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                                <Link to={Config.reactUrl + "/admin/viewagentdetail/" + item.id}><a href="#/" className={classes.link}> View Details </a></Link>
                            </div>
                            <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                                <Button buttonStyle={classes.buttonStyle1} buttonName={"Approve"} fullWidth={false} onClick={() => this.changeStatus(item.id, "approved")} />
                            </div>
                            <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                                <Button buttonStyle={classes.buttonStyle1} buttonName={"REJECT"} fullWidth={false} onClick={() => this.changeStatus(item.id, "rejected")} />
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return (
            <React.Fragment>
                <div class="col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12" >
                    <span style={{ position: "absolute", border: "1px solid #3B3E50", width: 0, height: window.innerHeight / 1.3, whiteSpace: "nowrap" }}></span>
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" style={{ paddingLeft: 40 }}>
                            <span className={classes.agentCountText}>{pendingTotal} Agents Pending</span>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 " style={{ padding: 10 }}>
                            <PopOver 
                            sortby={"Name"}
                            arrayList={[{ label: 'Name', value: 'first_name' }, { label: 'Agency', value: 'agency_name' }]}
                            onchange={(value)=> {
                                var order = "asc";
                                if (this.state.sortBy === value) {
                                    order = "desc";
                                }
                                this.setState({ sortBy: value, order: order });
                                this.getAgentPendingList(value, order)
                            }}
                            />
                        </div>
                    </div>
                    <div style={{ paddingLeft: 20 }}>
                        {
                            pendingData
                        }
                        <div style={{ paddingTop: 10 }}></div>
                        <div className={classes.border} ></div>
                        {
                            Object.keys(pendingData).length === 0 ?
                                <p className={classes.agentCountText} style={{ textAlign: "center" }}>
                                    <br />
                                    No Records Found
                                </p>
                                :
                                ""
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(AgentPending));