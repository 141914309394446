import React, { Component } from 'react'
import { checkNullAndReturnString, doGetConnectSecurity, doPutConnectSecurity, humanFileSize } from '../../helper/common';
import { withStore } from '../../component/hooks/store';
import { withStyles } from '@material-ui/core/styles';
import Styles from "../../styles/admin/agents";
import Button from "../../component/button";
import Config from '../../helper/config';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { Modal } from 'react-responsive-modal';
import PopOver from "../../component/popOver";
import PhoneInput from 'react-phone-input-2';

class AgentApproved extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approvedList: [],
            approvedTotal: 0,
            dialogdata: {
                open: false,
                data: null
            },
            sortBy: "",
            order: "asc"
        }
    }

    componentDidMount = async () => {
        this.getAgentApprovedList(this.state.sortBy, this.state.order);
    }

    getAgentApprovedList = async (sortBy, order) => {
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doGetConnectSecurity("users?id=&status=&roleId=3&name=&skip=&take=&order="+order+"&sortby="+sortBy+"&userAccessStatus=approved", accessToken)
        if (checkNullAndReturnString(responseData)) {
            this.setState({ approvedTotal: responseData.total })
            if (checkNullAndReturnString(responseData.data)) {
                this.setState({ approvedList: responseData.data })
            }
        }
    }

    changeStatus = async (id, val) => {
        var accessToken = localStorage.getItem("accessToken");
        var postJson = {
            status: val
        }
        var responseData = await doPutConnectSecurity("users/status/" + id, postJson, accessToken)
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.message)) {
                toast.success(responseData.message)
                this.getAgentApprovedList(this.state.sortBy, this.state.order);
                this.handleClose()
            }
        }
    }

    handleClose = () => {
        this.setState({
            dialogdata: {
                data: null,
                open: false
            }
        })
    }

    render() {
        var { approvedList, approvedTotal } = this.state
        const { classes } = this.props;
        var approvedData = [];
        if (checkNullAndReturnString(approvedList)) {
            Object.keys(approvedList).forEach(key => {
                var item = approvedList[key];
                approvedData.push(
                    <div style={{ paddingTop: 10 }}>
                        <div className={classes.border} ></div>
                        <div class="row" style={{ padding: 0, paddingBottom: 5 }}>
                            <div class="col" style={{ maxWidth: 100, paddingTop: 12, alignSelf: "center", textAlign: "center" }}>
                                {
                                    checkNullAndReturnString(item.profile_image_hash) ?
                                        <img className={classes.personImage} alt="imgfirst" src={Config.dataUrl + "aws-files/" + item.profile_image_hash} />
                                        :
                                        <img className={classes.personImage} alt="imgsecond" src={Config.emptyUser} />
                                }
                            </div>
                            <div class="col" style={{ paddingTop: 12, alignSelf: "center" }}>
                                <span className={classes.playerName}> {item.first_name} {item.last_name} </span>
                            </div>
                            <div class="col" style={{ paddingTop: 12 }} >
                                <span className={classes.player}> Agency </span>
                                <br />
                                <span className={classes.playerName}> {item.agency_name} </span>
                            </div>
                            <div class="col" style={{ paddingTop: 12 }} >
                                <span className={classes.player}> Years Active </span>
                                <br />
                                <span className={classes.playerName}> {item.experience} Years</span>
                            </div>
                            <div class="col" style={{ paddingTop: 12 }} >
                                <span className={classes.player}> Phone Number </span>
                                <br />
                                <span className={classes.playerName}>
                                     {
                                         item.phoneNumber ? <PhoneInput
                                         inputClass={"labelPhoneAgent"}
                                         country={'us'}
                                         inputStyle={{ width: "100%",
                                         background: "transparent",
                                         border: "transparent",
                                         color: "white",
                                      }}
                                      containerStyle={{
                                         background: "transparent",
                                         border: "transparent",
                 
                                      }}
                                      buttonStyle={{
                                          display: "none"
                                      }}
                                      disabled
                                         value={item.phoneNumber}
                                         /> : ""
                                     }
                                </span>
                            </div>
                            <div class="col" style={{ paddingTop: 12 }} >
                                <span className={classes.player}> Location </span>
                                <br />
                                <span className={classes.playerName}> {item.city}, {item.state} </span>
                            </div>
                            <div class="col" style={{ paddingTop: 12 }} >
                                <span className={classes.player}> Media Storage </span>
                                <br />
                                <span className={classes.playerName}>
                                    {
                                        checkNullAndReturnString(item.used_storage) ? humanFileSize(item.used_storage) : 0
                                    }
                                </span>
                            </div>
                            <div class="col" style={{ paddingTop: 12 }} >
                                <span className={classes.player}> Status </span>
                                <br />
                                <span className={item.status ? classes.active : classes.inActive}>
                                    {
                                        item.status ?
                                            "Active"
                                            :
                                            "Inactive"
                                    }
                                </span>
                            </div>
                            <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                                <Link to={Config.reactUrl + "/admin/adminagentview/admin/" + item.id} >
                                    <a href="#/" className={classes.link}> View Profile </a>
                                </Link>
                            </div>
                            <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                                <Button buttonStyle={classes.buttonStyle1} buttonName={item.status ? "DISABLE ACCOUNT" : "ENABLE ACCOUNT"} fullWidth={false} onClick={() => {
                                    this.setState({ dialogdata: { open: true, data: item } })
                                }} />
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return (
            <React.Fragment>
                <div class="col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12" >
                    <span style={{ position: "absolute", border: "1px solid #3B3E50", width: 0, height: window.innerHeight / 1.3, whiteSpace: "nowrap" }}></span>
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" style={{ paddingLeft: 40 }}>
                            <span className={classes.agentCountText}>{approvedTotal} Agents Approved</span>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 " style={{ padding: 10 }}>
                            <PopOver 
                            sortby={"Name"}
                            arrayList={[{ label: 'Name', value: 'first_name' }, { label: 'Agency', value: 'agency_name' }]}
                            onchange={(value)=> {
                                var order = "asc";
                                if (this.state.sortBy === value) {
                                    order = "desc";
                                }
                                this.setState({ sortBy: value, order: order });
                                this.getAgentApprovedList(value, order)
                            }}
                            />
                        </div>
                    </div>                                        
                    <div style={{ paddingLeft: 20 }}>
                        {
                            approvedData
                        }
                        <div style={{ paddingTop: 10 }}></div>
                        <div className={classes.border} ></div>
                        {
                            Object.keys(approvedData).length === 0 ?
                                <p className={classes.agentCountText} style={{ textAlign: "center" }}>
                                    <br />
                                    No Records Found
                                </p>
                                :
                                ""
                        }
                    </div>
                </div>
                <Modal open={this.state.dialogdata.open} onClose={this.handleClose} center showCloseIcon={false}>
                    {
                        this.state.dialogdata.data &&
                        <div style={{ padding: 20 }}>
                            {
                                this.state.dialogdata.data.status ?
                                    <p style={{ font: "normal normal 600 18px/27px Poppins", opacity: 1 }}>Disable Account</p>
                                    :
                                    <p style={{ font: "normal normal 600 18px/27px Poppins", opacity: 1 }}>Enable Account</p>
                            }
                            <p style={{ font: "normal normal normal 14px/21px Poppins", opacity: 1 }}>
                                Are you sure want to {this.state.dialogdata.data.status ? 'disable' : 'enable'} {this.state.dialogdata.data.first_name} {this.state.dialogdata.data.last_name}'s account ? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </p>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    <Button buttonStyle={classes.buttonStyle2} buttonName={"Cancel"} fullWidth={false}
                                        onClick={this.handleClose} />
                                    <span style={{ paddingRight: 20 }}></span>
                                    <Button buttonStyle={classes.buttonStyle3} buttonName={"Confirm"} fullWidth={false}
                                        onClick={() => this.changeStatus(this.state.dialogdata.data.id, !this.state.dialogdata.data.status)} />
                                </div>
                            </div>
                        </div>
                    }
                </Modal>
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(AgentApproved));