import React, { useEffect, useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Container, CssBaseline } from '@material-ui/core';
import Styles from '../../styles/uaAdmin/managerUsers';
import { withStore } from '../../component/hooks/store';
import Header from './header';
import BackOption from "../../component/back";
import Input from "../../component/input";
import TextArea from "../../component/textArea";
import SelectBox from "../../component/selectBoxDefaut";
import Checkbox from 'rc-checkbox';
import { Multiselect } from 'multiselect-react-dropdown';
import { checkNullAndReturnString, doGetConnectSecurity, doPostConnectSecurity } from '../../helper/common';
import Button from "../../component/button";
import { toast } from 'react-toastify';

const userTypeOption = [{ label: "Player", value: 4 }, { label: "Agent", value: 3 }, { label: "University Admin", value: 2 }, { label: "NXTPICK Admin", value: 1 }];

const Notifications = (props) => {
    const { classes } = props;
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [overAllNotification, setOverAllNotification] = useState(false);
    const [userType, setUserType] = useState(0);
    const [userTypeAll, setUserTypeAll] = useState(false);
    const [userTypeLable, setUserTypeLable] = useState("");
    const [subAdminList, setSubAdminList] = useState([]);
    const [uAdminList, setUAdminList] = useState([]);
    const [agentList, setAagentList] = useState([]);
    const [playerList, setPlayerList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);

    const emptyData = () => {
        setTitle("");
        setMessage("");
        setOverAllNotification(false);
        setUserType(0);
        setUserTypeAll(false);
        setUserTypeLable("");
        setSelectedList([]);
    }

    useEffect(() => {
        async function fetchData () {
            var accessToken = localStorage.getItem("accessToken");
            var responseSubAdmin = await doGetConnectSecurity(`users?id=&status=&roleId=1&name=&skip=&take=&order=&sortby=&userAccessStatus=`, accessToken);
            if (checkNullAndReturnString(responseSubAdmin.data)) {
                setSubAdminList(responseSubAdmin.data);
            }
            var responseUAdmin = await doGetConnectSecurity(`users?id=&status=&roleId=2&name=&skip=&take=&order=&sortby=&userAccessStatus=`, accessToken);
            if (checkNullAndReturnString(responseUAdmin.data)) {
                setUAdminList(responseUAdmin.data);
            }
            var responseAgent = await doGetConnectSecurity(`users?id=&status=&roleId=3&name=&skip=&take=&order=&sortby=&userAccessStatus=`, accessToken);
            if (checkNullAndReturnString(responseAgent.data)) {
                setAagentList(responseAgent.data);
            }
            var responsePlayer = await doGetConnectSecurity(`users?id=&status=&roleId=4&name=&skip=&take=&order=&sortby=&userAccessStatus=`, accessToken);
            if (checkNullAndReturnString(responsePlayer.data)) {
                setPlayerList(responsePlayer.data);
            }
        }
        fetchData();
    }, []);

    const handleChange = (key, value) => {
        if (key === "title") {
            setTitle(value);
        } else if (key === "userType") {
            setUserType(value);
            const getFilterJson = userTypeOption.filter(item => item.value === value);
            if (getFilterJson && getFilterJson[0]) {
                setUserTypeLable(getFilterJson[0].label)
            }
            setUserTypeAll(false);
            setSelectedList([]);
        } else {
            setMessage(value);
        }
    }

    const sendNotification = async () => {
        if (!title) {
            toast.error("Please enter title");
            return true;
        } else if (!message) {
            toast.error("Please enter message");
            return true;
        } else if (!overAllNotification) {
            if (userType === 0 || !userType) {
                toast.error("Please select user type");
                return true;
            } else if (!userTypeAll && selectedList.length === 0) {
                toast.error("Please select email");
                return true;
            }
        }
        var accessToken = localStorage.getItem("accessToken");
        var emailList = [];
        if (selectedList.length) {
            selectedList.map(item => emailList.push(item.email));
        }
        var userTypes = [];
        if (!overAllNotification) {
            userTypes.push(userType)
        }
        var subAdmins = false;
        if (userType === 1) {
            subAdmins = true;
        }        
        var postJson = {
            subject: title,
            mailContent: message,
            all: overAllNotification,
            roles: userTypes,
            email: emailList,
            sub_admin: subAdmins
        }
        var response = await doPostConnectSecurity(`notifications/mail`, postJson,  accessToken);
        if (response) {
            if (checkNullAndReturnString(response.error)) {
                if (checkNullAndReturnString(response.error.message)) {
                    toast.error(response.error.message);
                } else {
                    toast.error("Something went wrong");
                }
            } else {
                toast.success("Email has been sent successfully")
                emptyData();
            }
        }
    }

    return (
        <React.Fragment>
            <CssBaseline>
                <Header history={props.history} />
                <Container maxWidth="xl" component="main" className={classes.heroContent}>
                    <div className="container-fluid" style={{ paddingBottom: 120, paddingTop: 30 }}>
                        <div class="row rowView" >
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding" style={{ paddingTop: 20 }}>
                                <BackOption history={props.history} />
                            </div>
                        </div>
                        <div style={{ paddingTop: 20 }}></div>
                        <div class="row rowView" >                            
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 " style={{ padding: 10 }}>
                                <span className={classes.title}> All Users </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Checkbox
                                    name="my-checkbox"
                                    onChange={(e) => {
                                        setOverAllNotification(e.target.checked)
                                        setUserType(0);
                                        setUserTypeAll(false);
                                        setSelectedList([]);
                                    }}
                                    checked={overAllNotification}
                                />
                                {
                                    !overAllNotification &&
                                    <>
                                        <div class="row" >
                                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 " style={{ padding: 10 }}>
                                                <div style={{ paddingTop: 30 }}></div>
                                                <span className={classes.title}> User Type </span>
                                                <div style={{ paddingTop: 10 }}></div>
                                                <SelectBox
                                                    onChange={handleChange}
                                                    placeholder="User Type"
                                                    keyName={"userType"}
                                                    options={userTypeOption}
                                                    value={userType}
                                                />
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" style={{ 
                                                alignItems: "center",
                                                justifyContent: "center",
                                                alignSelf: "center",
                                                textAlign: "center" }}
                                            >
                                                {
                                                    userType !== 0 &&
                                                    <>
                                                        <div style={{ paddingTop: 30 }}></div>
                                                        <span className={classes.title}> All {userTypeLable}s </span>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <Checkbox
                                                            name="my-checkbox"
                                                            onChange={(e) => {
                                                                setSelectedList([]);
                                                                setUserTypeAll(e.target.checked)
                                                            }}
                                                            checked={userTypeAll}
                                                        />
                                                    </>
                                                }
                                            </div>
                                        </div>                                        
                                    </>
                                }                                                                
                                {
                                    !overAllNotification && userType !== 0 && !userTypeAll &&
                                    <>
                                        <div style={{ paddingTop: 30 }}></div>
                                        <span className={classes.title}> Search {userTypeLable} </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Multiselect
                                            style={{ width: "80%" }}
                                            options={userType === 1 ? subAdminList : userType === 2 ? uAdminList : userType === 3 ? agentList : userType === 4 ? playerList : []} // Options to display in the dropdown
                                            selectedValues={selectedList} // Preselected value to persist in dropdown
                                            onSelect={(data) => setSelectedList(data)} // Function will trigger on select event
                                            placeholder="Select email"
                                            onRemove={(data) => setSelectedList(data)} // Function will trigger on remove event
                                            displayValue="email" // Property name to display in the dropdown options
                                        />
                                    </>
                                }                                
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" >
                                <span className={classes.title}> Subject </span>
                                <div style={{ paddingTop: 10 }}></div>
                                <Input from={"signUp"} keyName={"title"} value={title} onChange={handleChange} />
                                <div style={{ paddingTop: 30 }}></div>
                                <span className={classes.title}> Content </span>
                                <div style={{ paddingTop: 10 }}></div>
                                <TextArea from={"signUp"} keyName={"message"} value={message} onChange={handleChange} />
                            </div>
                        </div>
                        <div class="row rowView" >
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 " style={{ padding: 10 }}>
                                <div class="col" style={{ float: "right" }}>
                                    <Button buttonStyle={classes.buttonStyleNotification} buttonName={"SEND EMAIL"} fullWidth={false} onClick={sendNotification} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </CssBaseline>            
        </React.Fragment>
    )
};

export default withStyles(Styles, { withTheme: true })(withStore(Notifications))