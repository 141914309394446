import { Component } from "react";
import logoImg from "../../images/nxtpick-logo.png";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Styles from "../../styles/versionHistory/versionHistoryDetails";
import { withStyles } from '@material-ui/core/styles';
import { withStore } from '../../component/hooks/store';
import { doGetConnectSecurity } from "../../helper/common";
import { toast } from "react-toastify";
import './version-history-details.css';
const moment = require("moment");

class VersionHistoryDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataobj: {
                total: 0,
                data: []
            }
        }
    }

    componentDidMount() {
        this.getVersionHistoryDetail()
    }

    getVersionHistoryDetail = async () => {
        try {
            var accessToken = localStorage.getItem("accessToken");
            var responseData = await doGetConnectSecurity(
                `version-history?skip=&take=&sortby=version_id&order=DESC&type=web`,
                accessToken);


            if (responseData.data.length) {
                responseData.data = responseData.data.map((ele) => {
                    const modifyreleqasenotes =
                        ele.release_notes
                            .replace(/<li/gm, '<li class=lilists')
                            .replace(/<ul/gm, '<ul class=ulist')
                            .replace(/<ol/gm, '<ol class=olist');

                    return {
                        ...ele,
                        release_notes: modifyreleqasenotes
                    }
                })
            }

            this.setState({
                dataobj: {
                    ...responseData
                }
            })

        } catch (err) {
            toast.error(err && err.message ? err.message : 'Error while receiving school details');
        }
    };

    createMarkup = (elem) => {
        if (!elem) {
            return ''
        }
        return { __html: elem };
    }


    render() {
        const { classes } = this.props;
        return (
            <>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="v-history">
                                <img src={logoImg} class="image mb-2" alt="theversionicon" sizes="" srcset="" />
                                <div class="info-box">
                                    <div class="info-title">Version History Details</div>
                                    <div class="info-detail">
                                        Please find below the version history of the application. It only includes final releases and
                                        not pre-release or beta software.
                                    </div>
                                </div>

                                <div class="w-100">
                                    {
                                        this.state.dataobj.data.map((ele, index) => (
                                            <div class="cp-card" key={index}>
                                                <Accordion defaultExpanded={index === 0}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}> Version {ele.version_id}</Typography>
                                                        {/* <Typography className={classes.secondaryHeading}>
                                                            {(ele.created_on) ? moment(ele.created_on).format('DD MMM YYYY') : ''}
                                                        </Typography> */}
                                                        <div class="release">
                                                            {(ele.created_on) ? moment(ele.created_on).format('DD MMM YYYY') : ''}
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails class="htmlcontents">
                                                        <Typography dangerouslySetInnerHTML={this.createMarkup(ele.release_notes)}></Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        ))
                                    }
                                    {/* <div class="release"></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withStyles(Styles, { withTheme: true })(withStore(VersionHistoryDetail));
