import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline, Container } from "@material-ui/core";
import { withStore } from "../../component/hooks/store";
import Config from "../../helper/config";
import NxtPick from "../../images/nxtpick-logo.png";
import Styles from "../../styles/playerSuccess.js";
import Footer from "./footer.js";
import TickGreen from "../../images/tickGreen.png";

class PlayerMatchingSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sectionStyle}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <img src={NxtPick} alt="" className={classes.avatar} />
            <form className={classes.form} noValidate>
              <br />
              <img src={TickGreen} alt="" style={{ width: 42, height: 42 }} />
              <br />
              <br />
              <span className={classes.successText}>
                Your Account Created Successfully!
              </span>
              <br />
              <br />
              <span className={classes.successDescription}>
                We have sent a email with a verification link to validate your
                account. Click on to the validation link to proceed further.
              </span>
              <br />
              <br />
              <Link to={Config.reactUrl + "/login"}>
                <span className={classes.backText}>{"< Back to Home"}</span>
              </Link>
            </form>
          </div>
        </Container>
        <Footer from={"extra"} />
      </div>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(
  withStore(PlayerMatchingSuccess)
);
