import React, { Component } from 'react'
// import { Link } from "react-router-dom";
import { withStore } from '../../component/hooks/store';
import { withStyles } from '@material-ui/core/styles';
import { Container, CssBaseline } from '@material-ui/core';
import Footer from "../manage/footer";
import Header from "./header";
import Styles from "../../styles/player/playerPerformence";
import BackOption from "../../component/back";

class AgentPlayerPerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { classes } = this.props;        
        return (
            <React.Fragment>
                <CssBaseline />
                <Header />
                <Container maxWidth="xl" component="main" className={classes.heroContent}>
                    <div className="container-fluid" style={{ paddingBottom: 110 }}>
                        <div class="row rowView">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 40 }}>
                            <BackOption history={this.props.history} linkTo={""} />
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <span className={classes.playerCompareText}>Overview</span>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <span className={classes.playerName}>Player Comparisons allows agents to quickly stack 2 players’ stats and drill performance against each other. The player with the better performance in each metric is highlighted for simple and efficient use.</span>
                            </div>
                                                    
                        </div>
                    </div>
                </Container>
                <Footer />
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(AgentPlayerPerformance));