import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline, Container } from "@material-ui/core";
import { withStore } from "../../component/hooks/store";
import {
  ValidateEmail,
  checkNullAndReturnString,
  doPostConnect,
} from "../../helper/common";
import Config from "../../helper/config";
import NxtPick from "../../images/nxtpick-logo.png";
import UserPng from "../../images/user.png";
import PasswordPng from "../../images/password.png";
import eyePng from "../../images/eye.png";
import tickPng from "../../images/tick.png";
import Styles from "../../styles/login.js";
import Footer from "./footer.js";
import TextInput from "react-textinput-field";
import Button from "../../component/button";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
// import { encrypt, decrypt } from 'react-crypt-gsm';

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eye: true,
      userName: "",
      password: "",
      existeduserid: null,
      historylength: 0,
    };
  }

  componentDidMount() {
    let search = this.props.location.search;
    const historylength = this.props.history.length;
    if (search) {
      let data = {};
      search
        .slice(1)
        .split("&")
        .map((e) => {
          const samp = e.split("=");
          data = { ...data, [samp[0]]: samp[1] ? samp[1] : "" };
          return e;
        });

      if (data.userid) {
        toast.error("Your session has expired");
        this.setState({
          existeduserid: data.userid,
          historylength: historylength,
        });
      } else {
        this.setState({
          existeduserid: null,
          historylength: 0,
        });
      }
    }
  }

  updateInputValue = (id, value) => {
    this.setState({
      [id]: value,
    });
  };

  submitFunction = async () => {
    var { userName, password } = this.state;
    if (checkNullAndReturnString(userName)) {
      if (ValidateEmail(userName)) {
        if (checkNullAndReturnString(password)) {
          var postJson = {
            email: userName,
            password: password,
          };
          var responseData = await doPostConnect("login", postJson);
          // var responseData = await axiosPost("login", postJson)
          if (checkNullAndReturnString(responseData.accessToken)) {
            toast.success("Login Successful");
            localStorage.setItem("accessToken", responseData.accessToken);
            localStorage.setItem("refreshToken", responseData.refreshToken);
            var decoded = jwt_decode(responseData.accessToken);
            if (checkNullAndReturnString(decoded.data)) {
              localStorage.setItem("userDetail", JSON.stringify(decoded.data));
              localStorage.setItem("id", decoded.data.id);
              localStorage.setItem("email", decoded.data.email);
              localStorage.setItem("firstName", decoded.data.first_name);
              localStorage.setItem("lastName", decoded.data.last_name);
              localStorage.setItem("roleId", decoded.data.role_id);

              if (
                this.state.historylength > 3 &&
                (this.state.existeduserid && this.state.existeduserid.toString()) === (decoded.data.id && decoded.data.id.toString())
              ) {
                return this.props.history.goBack();
              }

              // localStorage.setItem("lastName", cryptr.encrypt(decoded.data.last_name))
              // localStorage.setItem("playerPosition", cryptr.encrypt(decoded.data.player_position))
              // localStorage.setItem("roleId", cryptr.encrypt(decoded.data.role_id))
              // localStorage.setItem("schoolId", cryptr.encrypt(decoded.data.school_id))
              // localStorage.setItem("id", cryptr.encrypt(decoded.data.id))

              if (Number(decoded.data.role_id) === 4) {
                setTimeout(
                  () =>
                    this.props.history.push(
                      Config.reactUrl + "/player/dashboard"
                    ),
                  1500
                );
              }
              if (Number(decoded.data.role_id) === 3) {
                setTimeout(
                  () =>
                    this.props.history.push(
                      Config.reactUrl + "/agent/dashboard"
                    ),
                  1500
                );
              }
              if (Number(decoded.data.role_id) === 1) {
                setTimeout(
                  () =>
                    this.props.history.push(
                      Config.reactUrl + "/admin/dashboard"
                    ),
                  1500
                );
              }
              if (Number(decoded.data.role_id) === 5) {
                setTimeout(
                  () =>
                    this.props.history.push(
                      Config.reactUrl + "/coachuser/dashboard"
                    ),
                  1500
                );
              }
              if (Number(decoded.data.role_id) === 2) {
                localStorage.setItem("schoolId", decoded.data.school_id);
                setTimeout(
                  () =>
                    this.props.history.push(
                      Config.reactUrl + "/uaadmin/dashboard"
                    ),
                  1500
                );
              }
            }
          } else {
            if (checkNullAndReturnString(responseData.error)) {
              toast.error(responseData.error.message);
            }
          }
        } else {
          toast.error("Please enter password");
        }
      } else {
        toast.error("Please enter valid email");
      }
    } else {
      toast.error("Please enter username");
    }
  };

  onKeyUp = (event) => {
    if (event.charCode === 13 && event.target.localName !== "button") {
      this.submitFunction();
    }
  };

  render() {
    const { classes } = this.props;
    var { userName, eye } = this.state;
    var userNameValid = false;
    if (ValidateEmail(userName)) {
      userNameValid = true;
    }

    return (
      <div className={classes.sectionStyle}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Link to={"/"} variant="button">
              <img src={NxtPick} alt="logo" className={classes.avatar} />
            </Link>
            <span className={classes.loginFont}>Login</span>
            <form className={classes.form} noValidate onKeyPress={this.onKeyUp}>
              <div
                class={"loginInput"}
                style={{
                  borderRadius: 5,
                }}
              >
                <img src={UserPng} alt="user" className={classes.userPng} />
                {userNameValid ? (
                  <img src={tickPng} alt="tick" className={classes.tickPng} />
                ) : null}
                <TextInput
                  attr={{
                    name: "userName",
                    type: "text",
                    value: this.state["userName"] || "",
                    required: true,
                    autoComplete: "off",
                    style: {
                      background: "rgba(0,0,0,0.40)",
                      font: "normal normal normal 14px/21px Poppins",
                      letterSpacing: 0,
                      color: "#fff",
                      height: 70,
                      outline: "none",
                      border: "none",
                      paddingLeft: 50,
                      paddingRight: 45,
                      marginBottom: 0,
                    },
                  }}
                  updateInputValue={this.updateInputValue}
                  label={"User Name"}
                />
              </div>
              <div
                class={"loginInput"}
                style={{
                  borderRadius: 5,
                }}
              >
                <img src={PasswordPng} alt="pwd" className={classes.passwordPng} />
                <img alt="eyepng"
                  src={eyePng}
                  className={classes.eyePng}
                  onClick={() => this.setState({ eye: !eye })}
                />
                <TextInput
                  attr={{
                    name: "password",
                    type: eye ? "password" : "text",
                    value: this.state["password"] || "",
                    required: true,
                    style: {
                      background: "rgba(0,0,0,0.40)",
                      font: "normal normal normal 14px/21px Poppins",
                      letterSpacing: 0,
                      color: "#fff",
                      height: 70,
                      outline: "none",
                      border: "none",
                      paddingLeft: 50,
                      paddingRight: 45,
                      marginBottom: 0,
                    },
                  }}
                  updateInputValue={this.updateInputValue}
                  label={"Password"}
                />
              </div>
              <br />
              <br />
              <Button
                buttonStyle={classes.buttonStyle}
                buttonName={"Login to NxtPick"}
                fullWidth={true}
                onClick={this.submitFunction}
              />
              <p className={classes.forgotPassword}>
                <span className={classes.forgotPassword}>
                  Yo! Forgot your Password ?{" "}
                </span>
                <Link to={Config.reactUrl + "/forgotpassword"}>
                  <span className={classes.resetLink}>
                    {"Send me a reset link."}
                  </span>
                </Link>
              </p>
              <p className={classes.forgotPassword} style={{ paddingTop: 10 }}>
                <span className={classes.forgotPassword}>
                  Not a member yet?{" "}
                </span>
                <Link to={Config.reactUrl + "/signup"}>
                  <span className={classes.resetLink}>{"Sign up now."}</span>
                </Link>
              </p>
            </form>
          </div>
        </Container>
        <Footer from={"login"} />
      </div>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(withStore(LogIn));
