import React, { Component } from 'react'
import { /* BrowserRouter as Router, Route, Link, */ Switch } from "react-router-dom";
// import { createBrowserHistory } from 'history';
// import AgentDashboard from "./dashboard";
import ServicesList from "./servicesList";
// import AgentPlayerView from "./agentPlayerView";
import AgentPlayerSearch from "./agentPlayerSearch";
import AgentComparePlayer from "./agentComparePlayer";
import Config from "../../helper/config";
import PrivateRoute from "./privateRoute";
import ChangePassword from './changePassword';
import EditProfile from './editProfile';
import Message from './message';
import PlayerParentComponent from '../../component/playerDetail';
import AgentParentComponent from '../../component/agentDetails';

const reactUrl = Config.reactUrl + "/agent";
// const history = createBrowserHistory();

class AgentRouter extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={reactUrl + "/dashboard"} component={AgentParentComponent} />
                <PrivateRoute exact path={reactUrl + "/serviceslist"} component={ServicesList} />
                {/* <PrivateRoute exact path={reactUrl + "/agentplayerview"} component={AgentPlayerView} /> */}
                <PrivateRoute exact path={reactUrl + "/agentplayerview/:type/:userId"} component={PlayerParentComponent} />
                <PrivateRoute exact path={reactUrl + "/agentcompareplayer/:user1id/:user2id"} component={AgentComparePlayer} />
                <PrivateRoute exact path={reactUrl + "/agentplayersearch"} component={AgentPlayerSearch} />
                <PrivateRoute exact path={reactUrl + "/changepassword"} component={ChangePassword} />
                <PrivateRoute exact path={reactUrl + "/editprofile"} component={EditProfile} />
                <PrivateRoute exact path={reactUrl + "/message/:id?"} component={Message} />
            </Switch>
        )
    }
}
export default AgentRouter;