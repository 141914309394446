import React, { Component } from 'react'
// import TextInput from 'react-textinput-field';

class index extends Component {
    render() {
        var { updateInputValue, name, value, label, image, imageStyle } = this.props;
        return (
            <div className={"divideInnerInputGroup"}>
                <div style={{
                    position: "absolute", width: 40, height: 48, background: "0% 0% no-repeat padding-box padding-box rgb(125 123 130)",
                    zIndex: 1, borderRadius: 3, textAlign: "center"
                }}>
                    <img src={image} alt="images" style={imageStyle} />
                </div>
                <input
                    name={name}
                    type="text"
                    value={value}
                    style={{
                        background: "#51535d",
                        font: "normal normal normal 14px/21px Poppins",
                        color: "#fff",
                        height: 48,
                        outline: "none",
                        border: "none",
                        paddingLeft: 50,
                        paddingRight: 15,
                        marginBottom: 0,
                        borderRadius: 5,
                        width: "100%"
                    }}
                    onChange={(e) => updateInputValue(name, e.target.value)}
                    placeholder={label}
                />
            </div>
        )
    }
}
export default index;