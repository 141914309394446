import React, { Component } from "react";
import { withStore } from "../../component/hooks/store";
import { withStyles } from "@material-ui/core/styles";
import Styles from "../../styles/admin/players";
import Button from "../../component/button";
// import Tables from "../../component/table";
import {
  checkNullAndReturnString,
  doGetConnectSecurity,
  doPutConnectSecurity,
  doGetConnect,
  //   formatAMPM,
} from "../../helper/common";
// import SelectBox from "../../component/selectBox";
// import Input from "../../component/input";
import { toast } from "react-toastify";
import Config from "../../helper/config";
import { Modal } from "react-responsive-modal";
import ViewPlayer from "./viewPlayer";
import CancelPng from "../../images/cancel.png";
import PhoneInput from 'react-phone-input-2';

class PlayerMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      // total : 0,
      playerList: [],
      total: 0,
      school: 0,
      position: 0,
      year: 0,
      playerName: "",
      schoolList: [{ label: "School", value: 0 }],
      positionList: [{ label: "Position", value: 0 }],
      academicYear: [{ label: "Academic Year", value: 0 }],
      selectPleyerJson: {},
      userId: "",
      height: 0,
    };
  }

  onOpenModal = () => {
    this.setState({ modal: true });
  };

  onCloseModal = () => {
    this.setState({ modal: false });
  };

  componentDidMount = async () => {
    this.updateWindowDimensions();
    this.getUserList();
    this.getAcademicYear();
    this.getSchoolAndPosition();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions);
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  getAcademicYear = async () => {
    var academicYear = [{ label: "Academic Year", value: 0 }];
    var responseData = await doGetConnect("academic-year");
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.data)) {
        var academicYearData = responseData.data;
        Object.keys(academicYearData).forEach((key) => {
          academicYear.push({
            label: academicYearData[key].year,
            value: academicYearData[key].year,
          });
        });
        this.setState({ academicYear });
      }
    }
  };

  getSchoolAndPosition = async () => {
    var responseData = await doGetConnect("school");
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.data)) {
        var schoolData = [{ label: "School", value: 0 }];
        Object.keys(responseData.data).forEach((key) => {
          schoolData.push({
            label: responseData.data[key].school_name,
            value: responseData.data[key].school_name,
          });
        });
        this.setState({ schoolList: schoolData });
      }
    }
    var responseData1 = await doGetConnect("school/position");
    if (checkNullAndReturnString(responseData1)) {
      if (checkNullAndReturnString(responseData1.data)) {
        var positionList = [{ label: "Position", value: 0 }];
        Object.keys(responseData1.data).forEach((key) => {
          positionList.push({
            label: responseData1.data[key].name,
            value: responseData1.data[key].position,
          });
        });
        this.setState({ positionList });
      }
    }
  };

  changeStatus = async (id, val) => {
    var { userId, selectPleyerJson } = this.state;
    var accessToken = localStorage.getItem("accessToken");
    if (val === "approved") {
      if ((userId && userId.toString()) === (id && id.toString())) {
        if (checkNullAndReturnString(selectPleyerJson.statistics_id)) {
          console.log(selectPleyerJson);
          let postJson = {
            status: val,
            roleId: "4",
            playerProfileId: selectPleyerJson.statistics_id,
            playerPersonId: selectPleyerJson.person_id,
            firstName: selectPleyerJson.first_name,
            lastName: selectPleyerJson.last_name,
            class: selectPleyerJson.class,
            position: selectPleyerJson.position,
          };
          let responseData = await doPutConnectSecurity(
            "users/access/status/" + userId,
            postJson,
            accessToken
          );
          if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.message)) {
              toast.success(responseData.message);
              this.getUserList();
            } else {
              if (checkNullAndReturnString(responseData.error)) {
                if (checkNullAndReturnString(responseData.error.message)) {
                  toast.error(responseData.error.message);
                }
              }
            }
          }
        } else {
          this.setState({ userId: id, selectPleyerJson: {} });
          this.onOpenModal();
        }
      } else {
        this.setState({ userId: id, selectPleyerJson: {} });
        this.onOpenModal();
      }
    } else {
      let postJson = {
        status: val,
        roleId: "4",
        playerProfileId: "",
      };
      let responseData = await doPutConnectSecurity(
        "users/access/status/" + id,
        postJson,
        accessToken
      );
      if (checkNullAndReturnString(responseData)) {
        if (checkNullAndReturnString(responseData.message)) {
          toast.success(responseData.message);
          this.getUserList();
        } else {
          if (checkNullAndReturnString(responseData.error)) {
            if (checkNullAndReturnString(responseData.error.message)) {
              toast.error(responseData.error.message);
            }
          }
        }
      }
    }
  };

  clearFunction = () => {
    this.setState({
      school: 0,
      position: 0,
      university: 0,
      year: 0,
      playerName: "",
      playerList: [],
    });
  };

  getPlayerList = async () => {
    var { playerName, school, year, position } = this.state;
    this.setState({ playerList: [] });
    if (Number(school) === 0) {
      school = "";
    }
    if (Number(year) === 0) {
      year = "";
    }
    if (Number(position) === 0) {
      position = "";
    }
    var accessToken = localStorage.getItem("accessToken");
    var responseData = await doGetConnectSecurity(
      "player-statistics?skip=&take=&sortby=id&order=asc&name=" +
      playerName +
      "&position=" +
      position +
      "&team=" +
      school +
      "&class=&academicYear=" +
      year,
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.data)) {
        this.setState({ playerList: responseData.data });
      }
    }
  };

  handleChange = (id, value) => {
    this.setState({
      [id]: value,
    });
  };

  getUserList = async () => {
    this.setState({ total: 0, list: [] });
    var accessToken = localStorage.getItem("accessToken");
    var responseData = await doGetConnectSecurity(
      "users?id=&status=&roleId=4&name=&skip=&take=&order=&sortby=&userAccessStatus=pending",
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      this.setState({ total: responseData.total });
      if (checkNullAndReturnString(responseData.data)) {
        this.setState({ list: responseData.data });
      }
    }
  };

  setPlayer = (item) => {
    this.setState({ selectPleyerJson: item });
    this.onCloseModal();
  };

  render() {
    const { classes } = this.props;
    var {
      height,
      list,
      total,
      // playerList, schoolList, academicYear, positionList,
      userId,
      selectPleyerJson,
    } = this.state;
    // var rows = [];
    // if(checkNullAndReturnString(playerList))
    // {
    //     Object.keys(playerList).forEach(key=>{
    //         var item = playerList[key];
    //         rows.push({player: item.first_name + " " + item.last_name, pos: item.team, cls: item.position, ht: item.class, wt: item.academic_year,
    //             status : <a href={"javascript:void(0)"} style={{
    //                 color: "#73B86F",
    //                 font: "normal normal normal 14px/21px Poppins",
    //                 opacity: 1,
    //             }} onClick={()=> {
    //                 this.setState({ selectPleyerJson: item });
    //                 this.onCloseModal();
    //             }}>Map</a>
    //         });
    //     })
    // }
    var content = [];
    if (checkNullAndReturnString(list)) {
      Object.keys(list).forEach((key) => {
        var item = list[key];
        content.push(
          <div style={{ paddingTop: 10 }}>
            <div className={classes.border}></div>
            <div class="row" style={{ padding: 0, paddingBottom: 0 }}>
              <div
                class="col"
                style={{
                  maxWidth: 100,
                  paddingTop: 12,
                  alignSelf: "center",
                  textAlign: "center",
                }}
              >
                {checkNullAndReturnString(item.profile_image_hash) ? (
                  <img alt="awsfilesimage"
                    className={classes.personImage}
                    src={
                      Config.dataUrl + "aws-files/" + item.profile_image_hash
                    }
                  />
                ) : (
                  <img className={classes.personImage} src={Config.emptyUser} alt="emptyUser" />
                )}
              </div>
              <div class="col" style={{ paddingTop: 12 }}>
                <span className={classes.reviewPlayerName}>
                  {" "}
                  {item.first_name + " " + item.last_name}{" "}
                </span>
                <br />
                <span className={classes.reviewPlayer}> Football Player </span>
              </div>
              <div class="col" style={{ paddingTop: 12 }}>
                <span className={classes.reviewPlayer}> Location </span>
                <br />
                <span className={classes.reviewPlayerName}>
                  {" "}
                  {item.city + ", " + item.state}{" "}
                </span>
              </div>
              <div class="col" style={{ paddingTop: 12 }}>
                <span className={classes.reviewPlayer}> Email </span>
                <br />
                <span className={classes.reviewPlayerName}>
                  {" "}
                  {item.email}
                </span>
              </div>
              <div class="col" style={{ paddingTop: 12 }}>
                <span className={classes.reviewPlayer}> Phone Number </span>
                <br />
                <span className={classes.reviewPlayerName}>
                  {item.phoneNumber ? <PhoneInput
                        inputClass={"labelPhone"}
                        country={'us'}
                        inputStyle={{ width: "100%",
                        background: "transparent",
                        border: "transparent",
                        color: "white",
                     }}
                     containerStyle={{
                        background: "transparent",
                        border: "transparent",

                     }}
                     buttonStyle={{
                         display: "none"
                     }}
                     disabled
                        value={item.phoneNumber}
                        /> : ""}
                </span>
              </div>
              <div class="col" style={{ paddingTop: 12 }}>
                <span className={classes.reviewPlayer}> Position </span>
                <br />
                <span className={classes.reviewPlayerName}>
                  {" "}
                  {item.player_position}{" "}
                </span>
              </div>
              {/* <div class="col" style={{ alignSelf: "center", display: "flex", paddingTop: 12 }}>
                                <p className={classes.recordMatchRound} >
                                    i
                                </p>
                                <span className={classes.recordMatchText} style={{ paddingLeft: 10 }}> Record Matching </span>
                            </div>   */}
              <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"Map Player"}
                  fullWidth={false}
                  onClick={() => {
                    this.setState({ userId: item.id, selectPleyerJson: {} });
                    this.onOpenModal();
                  }}
                />
              </div>
              <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"Approve"}
                  fullWidth={false}
                  onClick={() => this.changeStatus(item.id, "approved")}
                />
              </div>
              <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"REJECT"}
                  fullWidth={false}
                  onClick={() => this.changeStatus(item.id, "rejected")}
                />
              </div>
            </div>
            {(userId && userId.toString()) === (item.id && item.id.toString()) && Object.keys(selectPleyerJson).length > 0 ? (
              <div>
                <div class="row" style={{ padding: 0, paddingBottom: 0 }}>
                  <div class="col" style={{ paddingTop: 12 }}>
                    <span className={classes.reviewPlayerName}>
                      {" "}
                      Matching Record {" "}
                    </span>
                  </div>
                </div>
                <div class="row" style={{ padding: 0, paddingBottom: 0 }}>
                  <div class="col" style={{ paddingTop: 12 }}>
                    <span className={classes.reviewPlayer}> Games </span>
                    <br />
                    <span className={classes.reviewPlayerName}>
                      {" "}
                      {selectPleyerJson.games}{" "}
                    </span>
                  </div>
                  <div class="col" style={{ paddingTop: 12 }}>
                    <span className={classes.reviewPlayer}> Rush </span>
                    <br />
                    <span className={classes.reviewPlayerName}>
                      {" "}
                      {selectPleyerJson.rush}{" "}
                    </span>
                  </div>
                  <div class="col" style={{ paddingTop: 12 }}>
                    <span className={classes.reviewPlayer}> RushYds </span>
                    <br />
                    <span className={classes.reviewPlayerName}>
                      {" "}
                      {selectPleyerJson.rush_yds}{" "}
                    </span>
                  </div>
                  <div class="col" style={{ paddingTop: 12 }}>
                    <span className={classes.reviewPlayer}>
                      {" "}
                      Pass Attempts{" "}
                    </span>
                    <br />
                    <span className={classes.reviewPlayerName}>
                      {" "}
                      {selectPleyerJson.pass_att}{" "}
                    </span>
                  </div>
                  <div class="col" style={{ paddingTop: 12 }}>
                    <span className={classes.reviewPlayer}>
                      {" "}
                      Pass Complete{" "}
                    </span>
                    <br />
                    <span className={classes.reviewPlayerName}>
                      {" "}
                      {selectPleyerJson.pass_com}{" "}
                    </span>
                  </div>
                  <div class="col" style={{ paddingTop: 12 }}>
                    <span className={classes.reviewPlayer}> Pass Yds </span>
                    <br />
                    <span className={classes.reviewPlayerName}>
                      {" "}
                      {selectPleyerJson.pass_yds}{" "}
                    </span>
                  </div>
                  <div class="col" style={{ paddingTop: 12 }}>
                    <span className={classes.reviewPlayer}> Pass TD </span>
                    <br />
                    <span className={classes.reviewPlayerName}>
                      {" "}
                      {selectPleyerJson.pass_td}{" "}
                    </span>
                  </div>
                  <div class="col" style={{ paddingTop: 12 }}>
                    <span className={classes.reviewPlayer}>
                      {" "}
                      Pass Efficiency{" "}
                    </span>
                    <br />
                    <span className={classes.reviewPlayerName}>
                      {" "}
                      {selectPleyerJson.pass_eff}{" "}
                    </span>
                  </div>
                  <div class="col"></div>
                  <div class="col"></div>
                  <div class="col"></div>
                </div>
              </div>
            ) : null}
          </div>
        );
      });
    }
    return (
      <React.Fragment>
        <div class="row rowView" style={{ paddingTop: 20 }}>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
            <span className={classes.studentCountText}>
              {total} Players Listed
            </span>
          </div>
          <div
            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
            style={{
              height: height / 1.6,
              overflowY: "scroll",
              whiteSpace: "nowrap",
            }}
          >
            {content}
            <div style={{ paddingTop: 10 }}></div>
            <div className={classes.border}></div>
            {Object.keys(content).length === 0 ? (
              <p
                className={classes.studentCountText}
                style={{ textAlign: "center" }}
              >
                <br />
                No Records Found
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <Modal
          open={this.state.modal}
          onClose={() => this.onCloseModal()}
          closeIcon={
            <img src={CancelPng} alt="" style={{ height: 15, width: 15 }} />
          }
          center
          showCloseIcon={true}  
        >
          {/* <div class="row" style={{ padding: 20, background: "#2d303a" }}>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                            <span className={classes.searchText}>Search Player</span>
                            <br/><br/>
                            <Input placeHolder={"Player Name"} keyName={"playerName"} value={this.state.playerName} onChange={this.handleChange}/>
                            <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                            <SelectBox onChange={this.handleChange} keyName={"school"} options={schoolList} value={this.state.school} />
                            <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                            <SelectBox onChange={this.handleChange} keyName={"position"} options={positionList} value={this.state.position} />
                            <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                            <SelectBox onChange={this.handleChange} keyName={"year"} options={academicYear} value={this.state.year} />
                            <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                            <Button buttonStyle={classes.buttonStyle1} buttonName={"Clear"} fullWidth={true} onClick={this.clearFunction}/>
                            <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                            <Button buttonStyle={classes.buttonStyle} buttonName={"Search"} fullWidth={true} onClick={this.getPlayerList}/>
                        </div>
                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12" >
                            <div style={{ padding: 10, paddingTop: 0, paddingLeft: 20 }}>
                                <p style={{ margin: 0 }}>
                                    <span className={classes.searchText}>
                                        Search Result
                                    </span>
                                    <a href="javascript:void(0)" onClick={()=> this.onCloseModal()} className={classes.searchText} style={{ float: "right", color: "#1891BD" }} >
                                        Cancel
                                    </a>
                                </p>
                                <div style={{ paddingTop: 15 }}></div>
                                <Tables fixed={true} extraStyle={{ height: 400 }} thJson={["Player","School", "POS", "CLS", "Year", "" ]} tdJson={rows}/>
                            </div>
                        </div>
                    </div> */}
          <div class="" style={{ padding: 15, background: "#2d303a" }}>
            <ViewPlayer from={"mapping"} setPlayer={this.setPlayer} />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(
  withStore(PlayerMapping)
);
