import LoginBg from "../images/login-bg.png";
const styles = theme => ({
    sectionStyle: {
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "hidden",
        minWidth: "100%",
        minHeight: "100%",
        backgroundImage: "url(" + LoginBg + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    paper: {
        marginTop: 160,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: "rgba(0,0,0,0.40)",
        position: 'relative',
        zIndex: 4
    },
    paper1: {
        marginTop: 100,
        marginBottom: 80,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 0
    },
    avatar: {
        opacity: 1,
        width: 175,
        height: 81,
        // opacity: 1
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        padding: 30
    },
    // form: {
    //     width: '100%', // Fix IE 11 issue.
    //     marginTop: theme.spacing(1),
    //     padding: 30
    // },  
    buttonStyle1: {
        border: "1px solid #1891BD",
        borderRadius: 5,
        color: 'white',
        height: 46,
        padding: '0 15px',
        fontSize: 14,
        textTransform: "uppercase",
        outline: "none",
        "&:hover": {
            background: "#1891BD"
        },
    },
    buttonStyle: {
        background: '#1891BD 0% 0% no-repeat padding-box',
        borderRadius: 5,
        border: 0,
        color: 'white',
        height: 46,
        padding: '0 30px',
        "&:hover": {
            border: "1px solid #1891BD",
            background: "transparent"
        },
    },
    buttonStyle2: {
        border: "1px solid #1891BD",
        borderRadius: 5,
        color: '#000',
        height: 46,
        padding: '0 15px',
        fontSize: 14,
        textTransform: "uppercase",
        outline: "none",
        "&:hover": {
            background: "#1891BD",
            color: "white"
        },
    },
    buttonStyle3: {
        background: '#1891BD 0% 0% no-repeat padding-box',
        borderRadius: 5,
        border: 0,
        color: 'white',
        height: 46,
        padding: '0 30px',
        "&:hover": {
            border: "1px solid #1891BD",
            background: "transparent",
            color: '#000',
        },
    },
    disclaimerText: {
        font: "normal normal normal 18px/19px Poppins",
        color: "#FFFFFF",
        opacity: 1
    },
    legalText: {
        font: "normal normal normal 14px/19px Poppins",
        color: "#FFFFFF",
        opacity: 1
    },
    label: {
        font: "normal normal normal 12px/19px Poppins",
        color: "#FFFFFF",
    },
    smallLabel: {
        font: "italic normal 300 10px/19px Poppins",
        color: "#959595",
        opacity: 1
    },
});
export default styles;
