import React, { Component } from "react";
// import { Link } from "react-router-dom";
import {
  // InputBase, Select, MenuItem,
  Container,
  CssBaseline,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withStore } from "../../component/hooks/store";
import Styles from "../../styles/admin/universityAdmins.js";
import Footer from "../manage/footer";
import Header from "./header";
// import Tab from "../../component/tab";
// import CreateUsers from "./createUsers";
import AllUsers from "./allUsers";
// import Request from "./request";
// import Tables from "../../component/table";
// import Button from "../../component/button";

class UniversityAdmins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      selectedSchool: null,
    };
  }

  tabOnClick = (index) => {
    this.setState({ selected: index });
  };

  resetselectedSchool = (state) => {
    this.setState((pre) => ({
      selectedSchool: null,
      selected: state ? 0 : pre.selected,
    }));
  };

  render() {
    const { classes } = this.props;
    // var { selected } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <Header from={"universityAdmins"} />
        <Container
          maxWidth="xl"
          component="main"
          className={classes.heroContent}
        >
          <div className="container-fluid" style={{ paddingBottom: 100 }}>
            {/* <div class="row rowView" style={{ paddingTop: 40 }}>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Tab json={["All Users", "Requests"]} selected={selected} tabOnClick={this.tabOnClick} />
                            </div>
                        </div> */}
            <div style={{ paddingTop: 40 }}></div>
            <AllUsers {...this.props} />
            {/* {
                            selected == 0 ?
                                <AllUsers {...this.props} />
                                :
                                <Request />
                        } */}
          </div>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(
  withStore(UniversityAdmins)
);
