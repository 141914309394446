import React, { Component } from "react";
import { withStore } from "../../component/hooks/store";
// import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Footer from "../manage/footer";
import Header from "./header";
import Styles from "../../styles/uaAdmin/dashboard";
import { Container, CssBaseline } from "@material-ui/core";
import Tab from "../../component/tab";
import EnrolledPlayers from "../uaAdmin/enrolledPlayers";
import UploadWorkoutPerformence from "../uaAdmin/uploadWorkoutPerformence";
import BackArrow from "../../images/backArrow.png";

class UniversityAdminWorkoutPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      tableData: [],
    };
  }

  tabOnClick = (index) => {
    this.setState({ selected: index });
  };

  render() {
    var { classes } = this.props;
    var { selected } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <Header history={this.props.history} from={"universityAdmins"} />
        <Container
          maxWidth="xl"
          component="main"
          className={classes.heroContent}
        >
          <div
            className="container-fluid"
            style={{ paddingBottom: 120, paddingTop: 30 }}
          >
            <div className={"row rowView"}>
              <a
                href="#/"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.goBack();
                }}
                style={{ color: "inherit" }}
              >
                <img
                  src={BackArrow}
                  alt="BackArrow"
                  style={{ width: 31, height: 31 }}
                />
                <span
                  href="#/"
                  style={{
                    font: "normal normal normal 12px/18px Poppins",
                    color: "#959595",
                    paddingLeft: 10,
                    opacity: 1,
                  }}
                  className={"commonBackClass"}
                  onClick={(e) => e.preventDefault()}
                >
                  Back
                </span>
              </a>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                <Tab
                  json={["Enrolled Players", "Drill Performance"]}
                  selected={selected}
                  tabOnClick={this.tabOnClick}
                />
              </div>
            </div>
            {Number(selected) === 0 ? (
              <EnrolledPlayers
                from={"admin"}
                id={this?.props?.match?.params?.id}
              />
            ) : Number(selected) === 1 ? (
              <UploadWorkoutPerformence
                from={"admin"}
                id={this?.props?.match?.params?.id}
              />
            ) : (
              ""
            )}
          </div>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(
  withStore(UniversityAdminWorkoutPerformance)
);
