const Styles = theme => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  statistics: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#959595",
    opacity: 1,
    paddingLeft: 5
  },
  seeDetails: {
    cursor: 'pointer',
    font: "normal normal normal 12px/18px Poppins",
    color: "#1891BD",
    opacity: 1,
    float: "right",
    paddingLeft: 5
  },
  count: {
    font: "normal normal normal 24px/35px Poppins",
    color: "#FFFFFF",
    opacity: 1,
    margin: 0,
    textAlign: "center",
    paddingTop: 20
  },
  countText: {
    font: "normal normal normal 10px/16px Poppins",
    color: "#FFFFFF",
    opacity: 1,
    margin: 0,
    textAlign: "center",
    paddingTop: 3
  },
  notification: {
    font: "normal normal normal 14px/21px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  notificationText: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  circle: {
    width: 31,
    height: 31,
    border: "1px solid #3B3E50",
    opacity: 1,
    borderRadius: 31 / 2,
    textAlign: "center",
    display: "flex",
    // textAlign: "center",
    justifyContent: "center",
    alignItems: "center"
  },
  notificationImage: {
    width: 9,
    height: 11,
    background: "transparent url('img/Path 302.png') 0% 0% no-repeat padding-box",
    opacity: 1
  },
  label: {
    font: "normal normal normal 14px/21px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  buttonStyle: {
    background: '#1891BD',
    borderRadius: 5,
    color: 'white',
    height: 40,
    padding: '0 15px',
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    "&:hover": {
      border: "1px solid #1891BD",
    },
  }
});
export default Styles;