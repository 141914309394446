import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { /* CssBaseline,*/ Container } from "@material-ui/core";
import { withStore } from "../../component/hooks/store";
import Styles from "../../styles/home.js";
import Button from "../../component/button";
import NxtLogo from "../../images/nxtpick-logo-header.png";
import MenuPng from "../../images/menu.png";
import UpArrow from "../../images/home-up-arrow.png";
import UpArrow1 from "../../images/home-up-arrow1.png";
import Medal from "../../images/medal.png";
import Portfolio from "../../images/portfolio.png";
import Server from "../../images/server.png";
import Transparency from "../../images/transparency.png";
import NxtLogoHome from "../../images/nxtpick-logo-home.png";
import AgentLandingPageFinal from "../../images/AgentLandingPageFinal.mp4";
import PlayerLandingPageFinal from "../../images/PlayerLandingPageFinal.mp4";
import ReactPlayer from "react-player";
import Config from "../../helper/config";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dot: 1
    };
    this.myRef = React.createRef();
    this.myRef1 = React.createRef();
  }

  render() {
    const date = new Date()
    var textStyle = {
      textAlign: "left",
      font: "normal normal normal 14px/21px Poppins",
      //   letterSpacing: 0,
      opacity: 1,
      paddingLeft: 15,
      paddingRight: 15,
      letterSpacing: 0.3
    };
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.sectionStyle}>
          <nav class="navbar navbar-expand-xl ">
            <a
              href="#/"
              class="navbar-brand homeLogo"
              style={{ paddingLeft: 130, paddingTop: 40 }}
              onClick={e => e.preventDefault()}
            >
              <img
                src={NxtLogo}
                alt="nxt[icklogo"
                style={{ width: 210, height: 22 }}
              />
            </a>
            <div
              class="navbar-toggler homeLogoRestrict"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              style={{ paddingTop: 40 }}
            >
              <img className={classes.menu} alt="Menupng" src={MenuPng} />
            </div>
            <div
              class="collapse navbar-collapse"
              id="navbarCollapse"
              style={{ paddingRight: 130 }}
            >
              <div
                class="navbar-nav ml-auto"
                style={{ paddingTop: 40, alignItems: "center" }}
              >
                <div class="nav-item nav-link">
                  <span style={{ ...textStyle, color: "#FFFFFF", cursor: "pointer" }} onClick={() =>
                    this.props.history.push(
                      Config.reactUrl + "/aboutnxtpick"
                    )
                  }>
                    About NXTPICK
                  </span>
                </div>
                <div class="nav-item nav-link">
                  <span style={{ ...textStyle, color: "#FFFFFF", cursor: "pointer" }} onClick={() =>
                    this.props.history.push(
                      Config.reactUrl + "/support"
                    )
                  }>
                    Support
                  </span>
                </div>
                <div class="nav-item nav-link">
                  <span style={{ ...textStyle, color: "#FFFFFF", cursor: "pointer" }} onClick={() =>
                    this.props.history.push(
                      Config.reactUrl + "/frequently-asked-questions"
                    )
                  }>FAQ</span>
                </div>
                <div
                  class="nav-item nav-link"
                  style={{ paddingRight: 10, paddingLeft: 10 }}
                >
                  <Button
                    buttonStyle={classes.buttonStyle}
                    buttonName={"Log in"}
                    onClick={() =>
                      this.props.history.push(Config.reactUrl + "/login")
                    }
                  />
                </div>
                <div
                  class="nav-item nav-link"
                  style={{ paddingRight: 10, paddingLeft: 25 }}
                >
                  <Button
                    buttonStyle={classes.buttonStyle1}
                    buttonName={"Sign Up"}
                    onClick={() =>
                      this.props.history.push(Config.reactUrl + "/signup")
                    }
                  />
                </div>
              </div>
            </div>
          </nav>
          <Container
            component="main"
            maxWidth="md"
            style={{ position: "relative", zIndex: 5 }}
          >
            <div className={classes.paper}>
              <span className={classes.bigFont}>
              The ultimate platform for players, agents and universities
              </span>
              <span className={classes.smallFont}>
                {
                  this.state.dot === 1 ?
                    "There is nothing that compares to the capabilities that NXTPICK enables for players, agents, and universities. We grant access to a vast network of players and agents like no other! Step into the modernized and simplified world of talent discovery."
                    :
                    this.state.dot === 2 ?
                    "Know that you are viewing accurate data and interacting with the right people. Stats are powered by Genius Sports, an official data manager for the NCAA and workout performance is provided by each player’s respective university. Sports agents are required to upload their certification from their sports’ players associations before gaining access to our application- yes, we check!"
                    :
                    "Universities can support their players and expand their brand as players gain more exposure. Student athletes and agents will have peace of mind knowing that universities are providing data with a high level of integrity."
                }                
              </span>
              <br />
              <div style={{ display: "inline-flex" }}>
                <a href={"javacript:void(0)"} onClick={()=> this.setState({dot: 1})} className={this.state.dot === 1 ? classes.bigCircleGreen : classes.bigCircle}>                  
                </a>                
                &nbsp;&nbsp;
                <a href={"javacript:void(0)"} onClick={()=> this.setState({dot: 2})} className={this.state.dot === 2 ? classes.bigCircleGreen : classes.bigCircle}>
                </a>                
                &nbsp;&nbsp;
                <a href={"javacript:void(0)"} onClick={()=> this.setState({dot: 3})} className={this.state.dot === 3 ? classes.bigCircleGreen : classes.bigCircle}>
                </a>                
              </div>
              <br />
              <br />
              <div style={{ display: "inline-flex" }}>
                <Button
                  buttonStyle={classes.buttonStyle}
                  buttonName={"Get started >"}
                  onClick={() =>
                    this.myRef1.current.scrollIntoView({
                      behavior: "smooth"
                    })
                  }
                />                                
              </div>
              <br />
              <br />
              <br />
              <a
                href="#/"
                onClick={e => {
                  e.preventDefault();
                  this.myRef.current.scrollIntoView({
                    behavior: "smooth"
                  });
                }}
              >
                <p style={{ padding: 0, margin: 0 }}>
                  <img
                    src={UpArrow1}
                    alt="uparrow"
                    style={{ width: 30, height: 25 }}
                  />
                </p>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    position: "relative",
                    top: -10
                  }}
                >
                  <img
                    src={UpArrow}
                    alt="upsarrow"
                    style={{ width: 30, height: 25 }}
                  />
                </p>
              </a>
            </div>
          </Container>
        </div>
        <div
          ref={this.myRef}
          style={{
            backgroundColor: "#1C1D29",
            justifyContent: "center",
            textAlign: "center",
            alignSelf: "center",
            alignItems: "center"
          }}
        >
          <Container
            component="main"
            maxWidth="lg"
            style={{
              position: "relative",
              zIndex: 5,
              paddingTop: 67,
              paddingBottom: 67
            }}
          >
            <span className={classes.aboutNxtText}>About NXTPICK</span>
            <p
              className={classes.bigSmallFont}
              style={{ padding: 0, margin: 0, paddingTop: 15 }}
            >
              Take control and break barriers
            </p>
            <p
              className={classes.smallFont}
              style={{ padding: 0, margin: 0, paddingTop: 15 }}
            >
              Streamline the process of connecting with players and agents across the professional sports world. Instantly erase constraints of distance and time while focusing on what matters to you in your journey as an elite player or agent. View stats, workout performance, and even highlight film all in one place. Take control, be selective, lower your risks by vetting and filtering the players and agents you want to speak with.
            </p>
            <br />            
          </Container>
        </div>
        <div className={classes.sectionStyle1}>
          <Container
            component="main"
            maxWidth="lg"
            style={{
              position: "relative",
              zIndex: 5,
              paddingBottom: 67,
              paddingTop: 67
            }}
          >
            <div
              className={"row noPadding"}
              style={{
                justifyContent: "center",
                textAlign: "center",
                alignSelf: "center",
                alignItems: "center"
              }}
            >
              <div
                className={"col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12"}
                style={{ paddingTop: 10 }}
              >
                <img
                  src={Server}
                  alt="servers"
                  style={{ width: 62, height: 62 }}
                />
                <p
                  className={classes.bigNormalText}
                  style={{ padding: 0, margin: 0, paddingTop: 25 }}
                >
                  Discover
                </p>
                <p
                  className={classes.normalSmallFont}
                  style={{ padding: 0, margin: 0, paddingTop: 10 }}
                >
                  Search an extensive database of player and agent profiles with refinable search parameters that fit your individual needs
                </p>
              </div>
              <div
                className={"col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12"}
                style={{ paddingTop: 10 }}
              >
                <img
                  src={Medal}
                  alt="medals"
                  style={{ width: 62, height: 62 }}
                />
                <p
                  className={classes.bigNormalText}
                  style={{ padding: 0, margin: 0, paddingTop: 25 }}
                >
                  Manage
                </p>
                <p
                  className={classes.normalSmallFont}
                  style={{ padding: 0, margin: 0, paddingTop: 10 }}
                >
                  Build personalized profile pages that display the most important information for players or agents to view
                </p>
              </div>
              <div
                className={"col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12"}
                style={{ paddingTop: 10 }}
              >
                <img
                  src={Transparency}
                  alt="tranparency"
                  style={{ width: 55, height: 55 }}
                />
                <p
                  className={classes.bigNormalText}
                  style={{ padding: 0, margin: 0, paddingTop: 32 }}
                >
                  Connect
                </p>
                <p
                  className={classes.normalSmallFont}
                  style={{ padding: 0, margin: 0, paddingTop: 10 }}
                >
                  Message players or agents within the app without having to provide personal contact information
                </p>
              </div>
              <div
                className={"col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12"}
                style={{ paddingTop: 10 }}
              >
                <img src={Portfolio} alt="" style={{ width: 62, height: 62 }} />
                <p
                  className={classes.bigNormalText}
                  style={{ padding: 0, margin: 0, paddingTop: 25 }}
                >
                  Compare
                </p>
                <p
                  className={classes.normalSmallFont}
                  style={{ padding: 0, margin: 0, paddingTop: 10 }}
                >
                  Agents can see how players stack up to top talent in their sport
                </p>
              </div>
            </div>
          </Container>
        </div>
        <div
          ref={this.myRef1}
          style={{
            backgroundColor: "#1C1D29",
            justifyContent: "center",
            textAlign: "center",
            alignSelf: "center",
            alignItems: "center"
          }}
        >
          <Container
            component="main"
            maxWidth="lg"
            style={{
              position: "relative",
              zIndex: 5,
              paddingTop: 67,
              paddingBottom: 67
            }}
          >
            <div
              className={"row noPadding"}
              style={{
                justifyContent: "center",
                textAlign: "center",
                alignSelf: "center",
                alignItems: "center"
              }}
            >
              <div
                className={"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"}
                style={{ paddingTop: 10 }}
              >
                <span className={classes.aboutNxtText}>Players</span>
                <br /><br />
                <div
                  className="player-wrapper"
                  style={{
                    width: '100%',
                    height: 265,
                    boxShadow: "0px 3px 6px #00000029",
                    position: "relative",
                    textAlign: "center"
                  }}
                >
                  <ReactPlayer
                    className="react-player"
                    url={PlayerLandingPageFinal}
                    width="100%"
                    height="100%"
                    controls={true}
                  />
                </div>
                <p
                  className={classes.bigSmallFont}
                  style={{
                    padding: 0,
                    margin: 0,
                    paddingRight: 30,
                    paddingLeft: 30,
                    paddingTop: 20
                  }}
                >
                  Build your page and connect with agents
                </p>
                <p
                  className={classes.smallFont}
                  style={{
                    padding: 0,
                    margin: 0,
                    paddingRight: 30,
                    paddingLeft: 30,
                    paddingTop: 20
                  }}
                >
                  Sign up with your @edu email address and launch your profile with highlight film and photos. Connect agents and manage your page.
                </p>
                <br />
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"Sign up as player"}
                  onClick={() =>
                    this.props.history.push(Config.reactUrl + "/signup/player")
                  }
                />
              </div>
              <div
                className={"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"}
                style={{ marginBottom: 18 }}
              >
                <span className={classes.aboutNxtText}>Agents</span>
                <br /><br />
                <div
                  className="player-wrapper"
                  style={{
                    width: '100%',
                    height: 265,
                    boxShadow: "0px 3px 6px #00000029",
                    position: "relative",
                    textAlign: "center"
                  }}
                >
                  <ReactPlayer
                    className="react-player"
                    url={AgentLandingPageFinal}
                    width="100%"
                    height="100%"
                    controls={true}
                  />
                </div>
                <p
                  className={classes.bigSmallFont}
                  style={{
                    padding: 0,
                    margin: 0,
                    paddingRight: 30,
                    paddingLeft: 30,
                    paddingTop: 30
                  }}
                >
                  Find the players you need
                </p>
                <p
                  className={classes.smallFont}
                  style={{
                    padding: 0,
                    margin: 0,
                    paddingRight: 30,
                    paddingLeft: 30,
                    paddingTop: 20
                  }}
                >
                  Sign up and verify your certification! Search, find, and connect with the players you can take to the next level.
                </p>
                <br />
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"Sign up as agent"}
                  onClick={() =>
                    this.props.history.push(Config.reactUrl + "/signup/agent")
                  }
                />
              </div>
            </div>
          </Container>
        </div>
        <div
          className={classes.sectionStyle2}
          style={{
            justifyContent: "center",
            textAlign: "center",
            alignSelf: "center",
            alignItems: "center"
          }}
        >
          <Container
            component="main"
            maxWidth="md"
            style={{
              position: "relative",
              zIndex: 5,
              paddingBottom: 67,
              paddingTop: 67
            }}
          >
            <p
              className={classes.smallFont}
              style={{ padding: 0, margin: 0, paddingTop: 20 }}
            >
              “This is what the game needs. Too often players are overlooked because they don’t have the resources or access to the right people to further their career. The fact that this is for all Title IX sports is huge!”
            </p>
            <div
              style={{
                textAlign: "center",
                paddingTop: 20,
                display: "inline-flex"
              }}
            >
              <p
                style={{
                  width: 33,
                  height: 0,
                  border: "2px solid #FFFFFF",
                  borderRadius: 5,
                  opacity: 0.52
                }}
              ></p>
            </div>
            <p
              style={{font: "normal normal normal 18px/23px Helvetica",
              color: "#1891BD",
              opacity: 0.9,
              paddingTop: 67, padding: 0, margin: 0 }}
            >
              Roger Cador
            </p>
            <p
              style={{font: "normal normal normal 18px/23px Helvetica",
              color: "#1891BD",
              opacity: 0.9,
              paddingTop: 67, padding: 0, margin: 0 }}
            >
              Legendary American Baseball Coaches Association Hall of Famer
            </p>
            <div style={{ display: "inline-flex", paddingTop: 20 }}>
              <span className={classes.smallCircleGreen}></span>
              &nbsp;&nbsp;
              <span className={classes.smallCircle}></span>
              &nbsp;&nbsp;
              <span className={classes.smallCircle}></span>
            </div>
          </Container>
        </div>
        <div
          style={{
            backgroundColor: "#1C1D29",
            justifyContent: "center",
            textAlign: "center",
            alignSelf: "center",
            alignItems: "center",
            borderBottom: "0.1px solid #5d5d65"
          }}
        >
          <Container
            component="main"
            maxWidth="lg"
            style={{
              position: "relative",
              zIndex: 5,
              paddingTop: 77,
              paddingBottom: 67
            }}
          >
            <img src={NxtLogoHome} alt="" style={{ width: 300, height: 31 }} />
            <div style={{ paddingTop: 30 }}>
              <span className={classes.linkFont}>
                <span style={{ cursor: "pointer" }} onClick={() =>
                  this.props.history.push(Config.reactUrl + "/frequently-asked-questions")
                }>FAQ</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{ cursor: "pointer" }} onClick={() =>
                  this.props.history.push(Config.reactUrl + "/aboutnxtpick")
                }>
                  About NXTPICK
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{ cursor: "pointer" }} onClick={() =>
                  this.props.history.push(Config.reactUrl + "/support")
                }>Support</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.location.href = "/login";
                  }}
                >Log in
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.location.href = "/signup";
                  }}
                >Sign up
                </span>
              </span>
            </div>
          </Container>
        </div>
        <div style={{ backgroundColor: "#1C1D29" }}>
          <Container
            component="main"
            maxWidth="lg"
            style={{
              position: "relative",
              zIndex: 5,
              paddingTop: 18,
              paddingBottom: 28
            }}
          >
            <div className={"row noPadding"}>
              <div
                className={"col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12"}
                style={{ paddingTop: 10 }}
              >
                <span className={classes.smallFont}>
                  © {date.getFullYear()} NXTPICK, All rights reserved.
                </span>
              </div>
              <div
                className={"col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12"}
                style={{ paddingTop: 10 }}
              >
                <span className={classes.smallFont}>Powered by iTech</span>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(withStore(Home));
