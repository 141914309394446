import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withStore } from "../../component/hooks/store";
import Styles from "../../styles/admin/universityAdmins.js";
import Button from "../../component/button";
import Tables from "../../component/table";
import CancelPng from "../../images/cancel.png";
import {
  checkNullAndReturnString,
  doGetConnectSecurity,
  doPutConnectSecurity,
} from "../../helper/common";
import SelectBox from "../../component/selectBoxDefaut";
import { toast } from "react-toastify";
import { Modal } from "react-responsive-modal";
import Checkbox from "@material-ui/core/Checkbox";
import CreateUsers from "./createUsers";
// import Request from "./request";
import UserPng from "../../images/userCircle.png";
import UploadPng from "../../images/upload.png";
import EditPng from "../../images/edit.png";
import BlueMarkTick from "../../images/blueMarkTick.png";
import { Multiselect } from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import Config from "../../helper/config";
import Tooltip from "@material-ui/core/Tooltip";

class AllUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UsersSchoolLists: [],
      UsersStateLists: [],
      UsersCityLists: [],
      school: null,
      city: null,
      state: null,
      modal: false,
      modalSelectedItem: {},

      createUsermodal: false,

      modelSelectedSchoolList: [],
      modelSortByKey: "first_name",
      modelSortByOrder: true,
      modalpaginations: {
        paginatonpageindex: 0,
        paginationrowsPerPage: 50,
        paginationcountdata: 100,
      },

      UserSchoolCountLists: [],
      sortByKey: "school_name",
      sortByOrder: true,
      paginations: {
        paginatonpageindex: 0,
        paginationrowsPerPage: 50,
        paginationcountdata: 100,
      },
      editJson: {},
      modalV1: false,
      modalV1Data: {},
      selectSchool: [],
    };
  }

  handleChangePage = async (event, newPage) => {
    const paginationvalue = {
      ...this.state.paginations,
      paginatonpageindex: newPage,
    };
    this.setState(
      {
        paginations: { ...paginationvalue },
      },
      this.getSchoolUserCountData
    );
  };

  handleChangeRowsPerPage = async (event) => {
    const paginationvalue = {
      ...this.state.paginations,
      paginationrowsPerPage: event.target.value,
      paginatonpageindex: 0,
    };
    this.setState(
      {
        paginations: { ...paginationvalue },
      },
      this.getSchoolUserCountData
    );
  };

  handleChangePageModal = async (event, newPage) => {
    const paginationvalue = {
      ...this.state.modalpaginations,
      paginatonpageindex: newPage,
    };
    this.setState(
      {
        modalpaginations: { ...paginationvalue },
      },
      () => this.openDialogueTeam(this.state.modalSelectedItem)
    );
  };

  handleChangeRowsPerPageModal = async (event) => {
    const paginationvalue = {
      ...this.state.modalpaginations,
      paginationrowsPerPage: event.target.value,
      paginatonpageindex: 0,
    };
    this.setState(
      {
        modalpaginations: { ...paginationvalue },
      },
      () => this.openDialogueTeam(this.state.modalSelectedItem)
    );
  };

  getSchoolUserCountData = async () => {
    let {
      city = "",
      school = "",
      state = "",
      sortByKey = "id",
      sortByOrder = false,
      paginations,
    } = this.state;
    if (!city) {
      city = "";
    }
    if (!school) {
      school = "";
    }
    if (!state) {
      state = "";
    }
    const { paginatonpageindex, paginationrowsPerPage } = paginations;
    const pagefrom = paginationrowsPerPage * paginatonpageindex;
    const pageto = paginationrowsPerPage;
    try {
      var accessToken = localStorage.getItem("accessToken");
      var responseData = await doGetConnectSecurity(
        `school/uaAllUsersList?city=${city}&state=${state}&school=${school}&sortbyKey=${sortByKey}&sortbyValue=${
          sortByOrder ? "asc" : "desc"
        }&skip=${pagefrom}&take=${pageto}`,
        accessToken
      );
      let paginationssetdata = {
        paginatonpageindex,
        paginationrowsPerPage,
        paginationcountdata: Number(responseData?.total)
          ? responseData?.total
          : 0,
      };

      this.setState({
        UserSchoolCountLists: responseData.data,
        paginations: paginationssetdata,
      });
    } catch (err) {
      //toast.error(err && err.message ? err.message : 'Error while receiving school details');
      this.setState({ UserSchoolCountLists: [] });
    }
  };

  emptyData = () => {
    this.setState(
      {
        school: null,
        city: null,
        state: null,
        sortByKey: "id",
        sortByOrder: false,
        selectSchool: [],
      },
      this.getSchoolUserCountData
    );
  };

  openDialogueTeam = async (item) => {
    try {
      const { modelSortByKey, modelSortByOrder, modalpaginations } = this.state;
      const { paginatonpageindex, paginationrowsPerPage } = modalpaginations;
      const pagefrom = paginationrowsPerPage * paginatonpageindex;
      const pageto = paginationrowsPerPage;
      var accessToken = localStorage.getItem("accessToken");
      var responseData = await doGetConnectSecurity(
        `player/uausercountviewprofile?schoolId=${item.id}&city=${
          item.city ? item.city : ""
        }&state=${
          item.state ? item.state : ""
        }&sortby=${modelSortByKey}&order=${
          modelSortByOrder ? "asc" : "desc"
        }&skip=${pagefrom}&take=${pageto}`,
        accessToken
      );
      let paginationssetdata = {
        paginatonpageindex,
        paginationrowsPerPage,
        paginationcountdata: Number(responseData.total),
      };

      if (responseData.status === 200) {
        this.setState({
          modal: true,
          modalSelectedItem: item,
          modelSelectedSchoolList: responseData.data,
          modalpaginations: paginationssetdata,
        });
      }
    } catch (err) {
      this.setState({
        modal: false,
        modalSelectedItem: {},
        modelSelectedSchoolList: [],
      });
    }
  };

  onCloseModal = () => {
    this.setState({
      modal: false,
      modalSelectedItem: {},
      modelSelectedSchoolList: [],
    });
  };

  onCloseCreateUserModal = () => {
    this.setState({ createUsermodal: false });
  };

  componentDidMount = async () => {
    this.getSchoolData();
    this.getStateData();
    this.getCityData();
    this.getSchoolUserCountData();
  };

  // getUserSchoolData = async () => {
  //     try {
  //         var accessToken = localStorage.getItem("accessToken");
  //         var responseData = await doGetConnectSecurity(
  //             `school/uaAllUsersList?city=&state=&school_name=`,
  //             accessToken);
  //         if (checkNullAndReturnString(responseData)) {
  //             if (checkNullAndReturnString(responseData.data)) {
  //                 console.log('responseData.data schoolssl');
  //                 console.log(responseData.data);
  //                 // responseData.data.forEach((ele) => {
  //                 //     UsersCityLists.push({
  //                 //         label: ele.city,
  //                 //         value: ele.city
  //                 //     });
  //                 //     return ele
  //                 // })
  //                 this.setState({ UserSchoolCountLists: responseData.data }, () => {
  //                     console.log('finalise');
  //                     console.log(this.state);
  //                 })
  //             }
  //         }
  //     } catch (err) {
  //         toast.error(err && err.message ? err.message : 'Error while receiving school details');
  //         this.setState({ UserSchoolCountLists: [] });
  //     }
  // }

  getCityData = async () => {
    var UsersCityLists = [];
    this.setState({ UsersCityLists });
    try {
      var accessToken = localStorage.getItem("accessToken");
      var responseData = await doGetConnectSecurity(
        "school/uacitystatelist/city",
        accessToken
      );
      if (checkNullAndReturnString(responseData)) {
        if (checkNullAndReturnString(responseData.data)) {
          responseData.data.forEach((ele) => {
            UsersCityLists.push({
              label: ele.suburb,
              value: ele.suburb,
            });
            return ele;
          });
          this.setState({ UsersCityLists: UsersCityLists });
        }
      }
    } catch (err) {
      toast.error(
        err && err.message
          ? err.message
          : "Error while receiving school details"
      );
      this.setState({ UsersCityLists });
    }
  };

  getStateData = async () => {
    var UsersStateLists = [];
    this.setState({ UsersStateLists });
    try {
      var accessToken = localStorage.getItem("accessToken");
      var responseData = await doGetConnectSecurity(
        "school/uacitystatelist/state",
        accessToken
      );
      if (checkNullAndReturnString(responseData)) {
        if (checkNullAndReturnString(responseData.data)) {
          responseData.data.forEach((ele) => {
            UsersStateLists.push({
              label: ele.state,
              value: ele.state,
            });
            return ele;
          });
          this.setState({ UsersStateLists: UsersStateLists });
        }
      }
    } catch (err) {
      toast.error(
        err && err.message
          ? err.message
          : "Error while receiving school details"
      );
      this.setState({ UsersStateLists });
    }
  };

  getSchoolData = async () => {
    var UsersSchoolLists = [];
    this.setState({ UsersSchoolLists });
    try {
      var accessToken = localStorage.getItem("accessToken");
      // var responseData = await doGetConnectSecurity("school", accessToken);
      var responseData = await doGetConnectSecurity(
        "master-data/ncaa/teams",
        accessToken
      );

      if (checkNullAndReturnString(responseData)) {
        // if (checkNullAndReturnString(responseData.data)) {
        responseData.forEach((ele) => {
          UsersSchoolLists.push({
            name: ele.teamName,
            id: ele.teamId,
          });
          return ele;
        });
        this.setState({ UsersSchoolLists: UsersSchoolLists });
        // }
      }
    } catch (err) {
      toast.error(
        err && err.message
          ? err.message
          : "Error while receiving school details"
      );
      this.setState({ UsersSchoolLists });
    }
  };

  createUAWithSchoolDetail = () => {
    this.setState({ createUsermodal: true });
  };

  handleChange = (id, value) => {
    this.setState({
      [id]: value,
    });
  };

  onSortData = (column) => {
    let key = "school_name";
    if (column === "School Name") {
      key = "school_name";
    } else if (column === "City") {
      key = "city";
    } else if (column === "State") {
      key = "state";
    } else if (column === "Status") {
      key = "status";
    } else if (column === "User Count") {
      key = "usercount";
    }
    this.setState(
      (pre) => ({
        sortByKey: key,
        sortByOrder: pre.sortByKey === key ? !pre.sortByOrder : true,
      }),
      this.getSchoolUserCountData
    );
  };

  onSortModalData = (column) => {
    let key = "school_name";
    if (column === "Name") {
      key = "first_name";
    } else if (column === "Email") {
      key = "email";
    } else if (column === "Status") {
      key = "status";
    } else if (column === "Admin Privilege") {
      key = "admin_privileges";
    }
    this.setState(
      (pre) => ({
        modelSortByKey: key,
        modelSortByOrder:
          pre.modelSortByKey === key ? !pre.modelSortByOrder : true,
      }),
      () => this.openDialogueTeam(this.state.modalSelectedItem)
    );
  };

  saveDetails = async () => {
    var { editJson } = this.state;
    var accessToken = localStorage.getItem("accessToken");
    var postJson = {
      city: editJson.city,
      state: editJson.state,
      emailDomain: editJson.email_domain,
      schoolName: editJson.school_name,
    };

    // console.log({
    //   postJson,
    //   editJson
    // })
    // return
    try {
      var responseData = await doPutConnectSecurity(
        // "school/" + (editJson.id || 0),
        `school/${editJson.id || 0}/${editJson.team_id}`,
        postJson,
        accessToken
      );
      if (checkNullAndReturnString(responseData)) {
        if (checkNullAndReturnString(!responseData.error)) {
          // toast.success("Update successfully")
          // this.emptyData();

          this.getSchoolUserCountData();
          this.setState({ editJson: {} });
        } else {
          if (checkNullAndReturnString(responseData.error)) {
            if (checkNullAndReturnString(responseData.error.message)) {
              toast.error(responseData.error.message);
            }
          }
        }
      }
    } catch (responseData) {
      if (checkNullAndReturnString(responseData.error)) {
        if (checkNullAndReturnString(responseData.error.message)) {
          let message = responseData.error.message;
          if (Array.isArray(message)) {
            message = message[0];
          }
          console.log({
            message,
          });
          toast.error(message);
        }
      }
    }
  };

  handleClose = () => {
    this.setState({ modalV1: false, modalV1Data: {} });
  };

  changeStatus = async (id, val) => {
    var accessToken = localStorage.getItem("accessToken");
    var postJson = {
      status: val,
    };
    var responseData = await doPutConnectSecurity(
      "users/status/" + id,
      postJson,
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.message)) {
        toast.success(responseData.message);
        this.openDialogueTeam(this.state.modalSelectedItem);
        this.handleClose();
      } else {
        if (checkNullAndReturnString(responseData.error)) {
          if (checkNullAndReturnString(responseData.error.message)) {
            toast.error(responseData.error.message);
          }
        }
      }
    }
  };

  render() {
    // const bg = {
    //     overlay: {
    //         background: "red"
    //     }
    // };
    const { classes } = this.props;
    var { UserSchoolCountLists, modelSelectedSchoolList } = this.state;
    var tableInputStyle = {
      background: "transparent",
      font: "normal normal normal 14px/21px Poppins",
      height: 32,
      color: "#fff",
      border: "0.5px solid rgb(75 76 92 / 60%)",
      width: 100,
      paddingLeft: 13,
      paddingRight: 13,
      outline: "none",
      "&:hover": {
        outline: "none",
      },
      "&:focus": {
        outline: "none",
      },
      borderRadius: 5,
    };
    var schoolData = [];
    if (checkNullAndReturnString(UserSchoolCountLists)) {
      Object.keys(UserSchoolCountLists).forEach((key) => {
        var item = UserSchoolCountLists[key];
        schoolData.push({
          schoolName: item.school_name,
          email_domain:
            (this.state.editJson.team_id &&
              this.state.editJson.team_id.toString()) ===
            (item.team_id && item.team_id.toString()) ? (
              <input
                type="text"
                style={tableInputStyle}
                value={this.state.editJson.email_domain}
                onChange={(e) => {
                  // this.state.editJson["email_domain"] = e.target.value;
                  // this.setState({ editJson: this.state.editJson });
                  this.setState((pre) => ({
                    ...pre,
                    editJson: {
                      ...pre.editJson,
                      email_domain: e.target.value,
                    },
                  }));
                }}
              />
            ) : (
              item.email_domain
            ),
          city:
            // (this.state.editJson.id && this.state.editJson.id.toString()) === (item.id && item.id.toString()) ? (
            //   <input
            //     type="text"
            //     style={tableInputStyle}
            //     value={this.state.editJson.city}
            //     onChange={(e) => {
            //       // this.state.editJson["city"] = e.target.value;
            //       // this.setState({ editJson: this.state.editJson });
            //       this.setState((pre) => ({
            //         ...pre,
            //         editJson: {
            //           ...pre.editJson,
            //           city: e.target.value
            //         }
            //       }))
            //     }}
            //   />
            // ) : (
            //   item.city
            // )
            item.city,
          state:
            // (this.state.editJson.id && this.state.editJson.id.toString()) === (item.id && item.id.toString()) ? (
            //   <input
            //     type="text"
            //     style={tableInputStyle}
            //     value={this.state.editJson.state}
            //     onChange={(e) => {
            //       // this.state.editJson["state"] = e.target.value;
            //       // this.setState({ editJson: this.state.editJson });
            //       this.setState((pre) => ({
            //         ...pre,
            //         editJson: {
            //           ...pre.editJson,
            //           state: e.target.value
            //         }
            //       }))
            //     }}
            //   />
            // ) : (
            //   item.state
            // )
            item.state,
          status: item.status ? (
            <span
              style={{
                color: "#73B86F",
                font: "normal normal normal 14px/21px Poppins",
                opacity: 1,
              }}
            >
              Active
            </span>
          ) : (
            <span
              style={{
                color: "orangered",
                font: "normal normal normal 14px/21px Poppins",
                opacity: 1,
              }}
            >
              Inactive
            </span>
          ),
          userCount: item.usercount ? item.usercount : "0",
          view: (
            <div>
              {(this.state.editJson.team_id &&
                this.state.editJson.team_id.toString()) ===
              (item.team_id && item.team_id.toString()) ? (
                <a
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.saveDetails();
                  }}
                >
                  <img
                    src={BlueMarkTick}
                    style={{ width: 20, height: 20 }}
                    alt=""
                  />
                </a>
              ) : (
                <a
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ editJson: item });
                  }}
                >
                  <Tooltip title={"Edit Domain"} placement="top">
                    <img
                      src={EditPng}
                      style={{ width: 20, height: 20 }}
                      alt=""
                    />
                  </Tooltip>
                </a>
              )}
              {item.email_domain && (
                <>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    href="#/"
                    onClick={(e) => {
                      e.preventDefault();
                      this.openDialogueTeam(item);
                    }}
                  >
                    <Tooltip title={"Add User"} placement="top">
                      <img
                        src={UserPng}
                        style={{ width: 20, height: 20 }}
                        alt=""
                      />
                    </Tooltip>
                  </a>
                </>
              )}
            </div>
          ),
          viewUploadWorkoutPerformance: (
            <Link
              to={
                Config.reactUrl +
                "/admin/universityAdmins/uploadWorkoutPerformance/" +
                item.school_id
              }
            >
              <Tooltip title={"Upload Workout Performence"} placement="top">
                <img src={UploadPng} style={{ width: 22, height: 22 }} alt="" />
              </Tooltip>
            </Link>
          ),
        });
      });
    }

    var UALists = [];
    if (checkNullAndReturnString(modelSelectedSchoolList)) {
      Object.keys(modelSelectedSchoolList).forEach((key) => {
        var item = modelSelectedSchoolList[key];
        UALists.push({
          Name: (item.first_name ? item.first_name : "").concat(
            " ",
            item.last_name ? item.last_name : ""
          ),
          Email: item.email,
          Status: item.status ? (
            <span
              style={{
                color: "#73B86F",
                font: "normal normal normal 14px/21px Poppins",
                opacity: 1,
              }}
            >
              Active
            </span>
          ) : (
            <span
              style={{
                color: "orangered",
                font: "normal normal normal 14px/21px Poppins",
                opacity: 1,
              }}
            >
              Inactive
            </span>
          ),
          Admin_Privilege: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <FormControlLabel control={ */}
              <Checkbox
                style={{ padding: "0px" }}
                checked={item.admin_privileges}
                color="primary"
                onChange={(e) => e.preventDefault()}
              />
              {/* } /> */}
              {/* <input type="checkbox" checked={item.admin_privileges} /> */}
            </div>
          ),
          Account: (
            <Button
              buttonStyle={classes.buttonStyle1}
              buttonName={
                item.status ? "De-Activate Account" : "Activate Account"
              }
              fullWidth={false}
              extraStyle={{ whiteSpace: "nowrap" }}
              onClick={() =>
                this.setState({ modalV1: true, modalV1Data: item })
              }
            />
          ),
        });
      });
    }

    return (
      <React.Fragment>
        <div class="row rowView" style={{ paddingTop: 20 }}>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
            <span className={classes.searchText}>Search Schools</span>
            <br />
            <br />
            {/* <SelectBox
              onChange={this.handleChange}
              placeholder="School Name"
              keyName={"school"}
              options={this.state.UsersSchoolLists}
              value={this.state.school}
            /> */}
            <div
              className={
                this.state.school
                  ? "singleSelectAgentSerachColorSelect singleSelectAgentSerachColor"
                  : "singleSelectAgentSerachColor"
              }
            >
              <Multiselect
                style={{ display: "flex" }}
                // singleSelect={true}
                options={
                  this.state.UsersSchoolLists.length
                    ? this.state.UsersSchoolLists
                    : []
                }
                selectedValues={this.state.selectSchool} // Preselected value to persist in dropdown
                onSelect={(e) => {
                  this.setState({
                    selectSchool: [e[e.length - 1]],
                    school: e[e.length - 1].id,
                  });
                }} // Function will trigger on select event
                placeholder={this.state.school ? "" : "School Name"}
                onRemove={(e) => {
                  this.setState({ selectSchool: [], school: "" });
                }} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />
            </div>
            <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
            {/* <Input placeHolder={"City"} keyName={"city"}
                        value={city} onChange={this.handleChange} /> */}
            <SelectBox
              onChange={this.handleChange}
              placeholder="City"
              keyName={"city"}
              options={this.state.UsersCityLists}
              value={this.state.city}
            />
            <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
            {/* <Input placeHolder={"State"} keyName={"state"}
                        value={state} onChange={this.handleChange} /> */}
            <SelectBox
              onChange={this.handleChange}
              placeholder="State"
              keyName={"state"}
              options={this.state.UsersStateLists}
              value={this.state.state}
            />
            <div
              style={{
                paddingTop: "20px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Button
                buttonStyle={classes.buttonStyle1}
                buttonName={"Clear"}
                fullWidth={false}
                onClick={() => this.emptyData()}
              />
              <span style={{ paddingRight: 10 }}></span>
              <Button
                buttonStyle={classes.buttonStyle}
                buttonName={"Search"}
                fullWidth={false}
                onClick={() => this.getSchoolUserCountData()}
              />
            </div>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
            <span
              style={{
                position: "absolute",
                border: "1px solid #3B3E50",
                width: 0,
                height: window.innerHeight / 1.3,
                left: 5,
              }}
            ></span>
            <Tables
              thJson={[
                "School Name",
                "Email Domain",
                "City",
                "State",
                "Status",
                "User Count",
                "",
                "",
              ]}
              tdJson={schoolData}
              sortHeader={true}
              onSortData={this.onSortData}
              paginationFooter={this.state.paginations}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              fixed={true}
              extraStyle={{
                height:
                  checkNullAndReturnString(schoolData) &&
                  Object.keys(schoolData).length > 0
                    ? Object.keys(schoolData).length > 10
                      ? window.innerHeight / 1.3
                      : ""
                    : "",
              }}
            />
          </div>
        </div>
        <Modal
          open={this.state.modalV1}
          onClose={this.handleClose}
          center
          showCloseIcon={false}
        >
          {this.state.modalV1Data && (
            <div style={{ padding: 20 }}>
              {this.state.modalV1Data.status ? (
                <p
                  style={{
                    font: "normal normal 600 18px/27px Poppins",
                    opacity: 1,
                  }}
                >
                  Disable Account
                </p>
              ) : (
                <p
                  style={{
                    font: "normal normal 600 18px/27px Poppins",
                    opacity: 1,
                  }}
                >
                  Enable Account
                </p>
              )}
              <p
                style={{
                  font: "normal normal normal 14px/21px Poppins",
                  opacity: 1,
                }}
              >
                Are you sure want to{" "}
                {this.state.modalV1Data.status ? "disable" : "enable"}{" "}
                {this.state.modalV1Data.first_name}{" "}
                {this.state.modalV1Data.last_name}'s account ?
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <Button
                    buttonStyle={classes.buttonStyle2}
                    buttonName={"Cancel"}
                    fullWidth={false}
                    onClick={this.handleClose}
                  />
                  <span style={{ paddingRight: 20 }}></span>
                  <Button
                    buttonStyle={classes.buttonStyle3}
                    buttonName={"Confirm"}
                    fullWidth={false}
                    onClick={() =>
                      this.changeStatus(
                        this.state.modalV1Data.id,
                        !this.state.modalV1Data.status
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </Modal>

        <Modal
          open={this.state.modal}
          onClose={() => this.onCloseModal()}
          closeIcon={
            <img src={CancelPng} style={{ height: 15, width: 15 }} alt="" />
          }
          center
          showCloseIcon={true}
        >
          <div
            style={{
              padding: 20,
              width: "780px",
              background: "#2d303a",
              minHeight: "250px",
              position: "relative",
            }}
          >
            {/* <p style={{ font: "normal normal 600 18px/27px Poppins", opacity: 1 }}>
                            View Users
                            </p> */}

            {/* <IconButton aria-label="close" className={classes.closeButton}>
                            <CloseIcon />
                        </IconButton> */}

            <div class="pb-2">
              <span
                style={{
                  font: "normal normal 600 18px/27px Poppins",
                  opacity: 1,
                  color: "white",
                }}
              >
                View Users
              </span>
            </div>
            <div class="row">
              <div class="col-md-12">
                <Tables
                  thJson={[
                    "Name",
                    "Email",
                    "Status",
                    "Admin Privilege",
                    "Account",
                  ]}
                  tdJson={UALists}
                  sortHeader={true}
                  onSortData={this.onSortModalData}
                  paginationFooter={this.state.modalpaginations}
                  handleChangePage={this.handleChangePageModal}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPageModal}
                  extraStyle={{
                    height:
                      checkNullAndReturnString(UALists) &&
                      Object.keys(UALists).length > 0
                        ? Object.keys(UALists).length > 10
                          ? 400
                          : ""
                        : "",
                  }}
                  fixed={true}
                />
              </div>
            </div>
            <div
              style={{
                position: "sticky",
                bottom: 0,
                right: 0,
                textAlign: "center",
              }}
            >
              <span>
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"Close"}
                  fullWidth={false}
                  onClick={() => this.onCloseModal()}
                />
              </span>
              <span style={{ marginLeft: "10px" }}>
                <Button
                  buttonStyle={classes.buttonStyle}
                  buttonName={"Create user"}
                  fullWidth={false}
                  style={{ marginLeft: "10px" }}
                  onClick={() => this.createUAWithSchoolDetail()}
                />
              </span>
            </div>
          </div>
        </Modal>

        <Modal
          open={this.state.createUsermodal}
          onClose={() => this.onCloseCreateUserModal()}
          closeIcon={
            <img src={CancelPng} style={{ height: 15, width: 15 }} alt="" />
          }
          center
          showCloseIcon={true}
        >
          <div
            className={""}
            style={{
              background: "#2d303a",
              width: 500,
            }}
          >
            <div className={"col-12"} style={{ padding: 50, paddingTop: 10 }}>
              <CreateUsers
                resetselectedSchool={() => {
                  this.openDialogueTeam(this.state.modalSelectedItem);
                  this.onCloseCreateUserModal();
                  this.getSchoolUserCountData();
                }}
                selectedSchool={this.state.modalSelectedItem}
              />
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    this.setState({
      modal: false,
      modalSelectedItem: {},
      modelSelectedSchoolList: [],
    });
  }
}
export default withStyles(Styles, { withTheme: true })(withStore(AllUsers));
