import React, { Component } from 'react'
import Footer from "../manage/footer";
import { withStore } from '../../component/hooks/store';
import Password from '../../component/changePassword';
import { withStyles } from '@material-ui/core/styles';
import Header from "./header";
// import { Link } from "react-router-dom";
// import Config from "../../helper/config";
import Styles from "../../styles/player/changePassword";
import { Container, CssBaseline } from '@material-ui/core';
import BackOption from "../../component/back";

class ChangePassword extends Component {
    render() {
        var { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
                <Header />
                <Container maxWidth="xl" component="main" className={classes.heroContent}>
                    <div className="container-fluid" style={{ paddingBottom: 120, paddingTop: 50 }}
                        id="createpassword">
                        <div class="row rowView" >
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding" style={{ paddingTop: 30 }}>
                                <BackOption history={this.props.history} />
                            </div>
                        </div>
                        <div class="row rowView" >
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-12 noPadding"></div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 noPadding" >
                                <Password buttonStyle={classes.buttonStyle} eyePng={classes.eyePng} />
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-12 noPadding"></div>
                        </div>
                    </div>
                </Container>
                <Footer />
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(ChangePassword));
