import axios from "axios";
import Config from "./config";
import { history } from "../App";
// import { toast } from 'react-toastify';

export function axiosGet(subUrl) {
  axios({
    method: "get",
    url: Config.dataUrl + subUrl,
    // data: bodyFormData,
    // headers: {'Content-Type': 'multipart/form-data' }
  })
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (response) {
      //handle error
      return response;
    });
  // axios.get(Config.dataUrl + subUrl)
  //     .then(res => {
  //         return res;
  // })
}

export function positionAverageData1(val1, val2) {
  if (val1) {
    val1 = parseFloat(val1);
  } else {
    val1 = 0;
  }
  if (val2 && val1 !== 0) {
    val2 = parseFloat(val2);
  } else {
    val2 = 0;
  }
  var retrunData = (parseFloat(val2) / parseFloat(val1)) * 100;
  if (retrunData > 100) {
    retrunData = 100;
  }
  return retrunData ? retrunData : 0;
}

export function positionAverageData2(val1, val2) {
  if (val1) {
    val1 = parseFloat(val1);
  } else {
    val1 = 0;
  }
  if (val2 && val1 !== 0) {
    val2 = parseFloat(val2);
  } else {
    val2 = 0;
  }
  var retrunData = (parseFloat(val2) / parseFloat(val1)) * 100;
  return retrunData ? Number(retrunData).toFixed(2) : 0;
}

export function positionAverageData(val1, val2) {
  if (val1) {
    val1 = parseFloat(val1);
  } else {
    val1 = 0;
  }
  if (val2 && val1 !== 0) {
    val2 = parseFloat(val2);
  } else {
    val2 = 0;
  }
  var retrunData = (parseFloat(val1) / parseFloat(val2)) * 100;
  if (retrunData > 100) {
    retrunData = 100;
  }

  return retrunData ? retrunData : 0;
}

export function positionAverageData3(val1, val2) {
  if (val1) {
    val1 = parseFloat(val1);
  } else {
    val1 = 0;
  }
  if (val2 && val1 !== 0) {
    val2 = parseFloat(val2);
  } else {
    val2 = 0;
  }
  var retrunData = (parseFloat(val1) / parseFloat(val2)) * 100;
  if (retrunData > 100) {
    retrunData = 100;
  }

  return retrunData ? Number(retrunData).toFixed(2) : 0;
}

export function positionData(position) {
  const positionJson = {
    CB: [
      { name: "G", value: "sGames" },
      { name: "TDs", value: "sTouchdowns" },
      { name: "Saf", value: "sSafetiesTwoPoints" },
      { name: "Fum Rec", value: "sFumblesRecovered" },
      { name: "Solo Tack", value: "sTacklesSolo" },
      { name: "Asst Tack", value: "sTacklesAssisted" },
      { name: "TT", value: "sTacklesTotal" },
      { name: "TPG", value: "sTacklesTotal/sGames" },
      { name: "STPG", value: "sTacklesSolos/sGames" },
      { name: "STFL", value: "sTacklesForLossSolo" },
      { name: "ATFL", value: "sTacklesForLossAssisted" },
      { name: "TTFL", value: "sTacklesForLossTotal" },
      { name: "TFLPG", value: "sTacklesForLossTotal/sGames" },
      { name: "Solo Sack", value: "sSacksUnassisted" },
      { name: "Asst Sack", value: "sSacksAssisted" },
      { name: "Tot Sack", value: "sSacksTotal" },
      { name: "Sacks PG", value: "sSacksTotal/sGames" },
      { name: "FF", value: "sFumblesForced" },
      { name: "FFPG", value: "sFumblesForced/sGames" },
      { name: "PBU", value: "sPassBreakUps" },
      { name: "Punt Ret", value: "sPuntReturns" },
      { name: "KO Ret", value: "sKickoffsReturned" },
      { name: "Def Int Yds", value: "sInterceptionsReturnYards" },
      { name: "Sack Yds", value: "sSacksYards" },
      { name: "Tackle Yds", value: "sTacklesYards" },
      { name: "Punt Ret TDs", value: "sPuntReturnsTouchdown" },
      { name: "Kick Ret TDs", value: "sKickReturnsTouchdowns" },
      { name: "Int Ret TDs", value: "sInterceptionsTouchdowns" },
      { name: "Fum Ret TDs", value: "sFumbleReturnTouchdowns" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "Long Int", value: "sInterceptionsReturnLongest" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
      { name: "Blkd", value: "sPatBlockedKicksBy" },
      { name: "Blkd", value: "sPuntsBlockedBy" },
    ],
    DB: [
      { name: "G", value: "sGames" },
      { name: "TDs", value: "sTouchdowns" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Saf", value: "sSafetiesTwoPoints" },
      { name: "Fum Rec", value: "sFumblesRecovered" },
      { name: "Solo Tack", value: "sTacklesSolo" },
      { name: "Asst Tack", value: "sTacklesAssisted" },
      { name: "TT", value: "sTacklesTotal" },
      { name: "TPG", value: "sTacklesTotal/sGames" },
      { name: "STPG", value: "sTacklesSolos/sGames" },
      { name: "STFL", value: "sTacklesForLossSolo" },
      { name: "ATFL", value: "sTacklesForLossAssisted" },
      { name: "TTFL", value: "sTacklesForLossTotal" },
      { name: "TFLPG", value: "sTacklesForLossTotal/sGames" },
      { name: "Solo Sack", value: "sSacksUnassisted" },
      { name: "Asst Sack", value: "sSacksAssisted" },
      { name: "Tot Sack", value: "sSacksTotal" },
      { name: "Sacks PG", value: "sSacksTotal/sGames" },
      { name: "FF", value: "sFumblesForced" },
      { name: "FFPG", value: "sFumblesForced/sGames" },
      { name: "PBU", value: "sPassBreakUps" },
      { name: "Punt Ret", value: "sPuntReturns" },
      { name: "KO Ret", value: "sKickoffsReturned" },
      { name: "Def Int Yds", value: "sInterceptionsReturnYards" },
      { name: "Sack Yds", value: "sSacksYards" },
      { name: "Tackle Yds", value: "sTacklesYards" },
      { name: "Punt Ret TDs", value: "sPuntReturnsTouchdown" },
      { name: "Kick Ret TDs", value: "sKickReturnsTouchdowns" },
      { name: "Int Ret TDs", value: "sInterceptionsTouchdowns" },
      { name: "Fum Ret TDs", value: "sFumbleReturnTouchdowns" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "Long Int", value: "sInterceptionsReturnLongest" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
      { name: "Blkd", value: "sPatBlockedKicksBy" },
      { name: "Blkd", value: "sPuntsBlockedBy" },
    ],
    DL: [
      { name: "G", value: "sGames" },
      { name: "TDs", value: "sTouchdowns" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Saf", value: "sSafetiesTwoPoints" },
      { name: "Fum Rec", value: "sFumblesRecovered" },
      { name: "Solo Tack", value: "sTacklesSolo" },
      { name: "Asst Tack", value: "sTacklesAssisted" },
      { name: "TT", value: "sTacklesTotal" },
      { name: "TPG", value: "sTacklesTotal/sGames" },
      { name: "STPG", value: "sTacklesSolos/sGames" },
      { name: "STFL", value: "sTacklesForLossSolo" },
      { name: "ATFL", value: "sTacklesForLossAssisted" },
      { name: "TTFL", value: "sTacklesForLossTotal" },
      { name: "TFLPG", value: "sTacklesForLossTotal/sGames" },
      { name: "Solo Sack", value: "sSacksUnassisted" },
      { name: "Asst Sack", value: "sSacksAssisted" },
      { name: "Tot Sack", value: "sSacksTotal" },
      { name: "Sacks PG", value: "sSacksTotal/sGames" },
      { name: "FF", value: "sFumblesForced" },
      { name: "FFPG", value: "sFumblesForced/sGames" },
      { name: "PBU", value: "sPassBreakUps" },
      { name: "Def Int Yds", value: "sInterceptionsReturnYards" },
      { name: "Sack Yds", value: "sSacksYards" },
      { name: "Tackle Yds", value: "sTacklesYards" },
      { name: "Int Ret TDs", value: "sInterceptionsTouchdowns" },
      { name: "Fum Ret TDs", value: "sFumbleReturnTouchdowns" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
      { name: "Blkd", value: "sPatBlockedKicksBy" },
      { name: "Blkd", value: "sPuntsBlockedBy" },
    ],
    DT: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "YPG", value: "sYardsOffensiveTotal/sGames" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Saf", value: "sSafetiesTwoPoints" },
      { name: "Fum Rec", value: "sFumblesRecovered" },
      { name: "Solo Tack", value: "sTacklesSolo" },
      { name: "Asst Tack", value: "sTacklesAssisted" },
      { name: "TT", value: "sTacklesTotal" },
      { name: "TPG", value: "sTacklesTotal/sGames" },
      { name: "STPG", value: "sTacklesSolos/sGames" },
      { name: "STFL", value: "sTacklesForLossSolo" },
      { name: "ATFL", value: "sTacklesForLossAssisted" },
      { name: "TTFL", value: "sTacklesForLossTotal" },
      { name: "TFLPG", value: "sTacklesForLossTotal/sGames" },
      { name: "Solo Sack", value: "sSacksUnassisted" },
      { name: "Asst Sack", value: "sSacksAssisted" },
      { name: "Tot Sack", value: "sSacksTotal" },
      { name: "Sacks PG", value: "sSacksTotal/sGames" },
      { name: "FF", value: "sFumblesForced" },
      { name: "FFPG", value: "sFumblesForced/sGames" },
      { name: "PBU", value: "sPassBreakUps" },
      { name: "Def Int Yds", value: "sInterceptionsReturnYards" },
      { name: "Sack Yds", value: "sSacksYards" },
      { name: "Tackle Yds", value: "sTacklesYards" },
      { name: "Int Ret TDs", value: "sInterceptionsTouchdowns" },
      { name: "Fum Ret TDs", value: "sFumbleReturnTouchdowns" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
      { name: "Blkd", value: "sPatBlockedKicksBy" },
      { name: "Blkd", value: "sPuntsBlockedBy" },
    ],
    FB: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "Rush", value: "sRushingAttempts" },
      { name: "YDS", value: "sYardsOffensiveTotal" },
      { name: "TDs", value: "sTouchdowns" },
      { name: "YPG", value: "sYardsOffensiveTotal/sGames" },
      { name: "Pass Com", value: "sPassesCompleted" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Rec", value: "sReceptions" },
      { name: "Rec PG", value: "sReceptionsAverage" },
      { name: "YdsPg", value: "sYardsOffensiveTotalAverage" },
      { name: "PPG", value: "sPoints/sGames" },
      { name: "Pts", value: "sPoints" },
      { name: "Rush Yds", value: "sRushingYardsNet" },
      { name: "Rec Yds", value: "sReceptionsYards" },
      { name: "Punt Ret Yds", value: "sPuntReturnsYards" },
      { name: "KO Ret Yds", value: "sKickReturnsYards" },
      { name: "Fum Lost", value: "sFumblesLost" },
      { name: "All-Purp Yds", value: "sYardsAllPurposeTotal" },
      { name: "Rush TD", value: "sRushingTouchdowns" },
      { name: "Pass Att", value: "sPasses" },
      { name: "Pass Yds", value: "sPassesYards" },
      { name: "Pass TD", value: "sPassesTouchdowns" },
      { name: "Rec TD", value: "sReceptionsTouchdown" },
      { name: "Punt Yds", value: "sPuntsYards" },
      { name: "PAT Att", value: "sPatAttempts" },
      { name: "Pass 2PT Att", value: "sPatPassAttempts" },
      { name: "Rush 2PT Att", value: "sPatRushAttempts" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "Rush Long", value: "sRushingLongest" },
      { name: "Long Pass", value: "sPassesLongest" },
      { name: "Long Rec", value: "sReceptionsLongest" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
      { name: "Yds/Play", value: "sYardsPerPlayAverage" },
      { name: "Yds/Rush", value: "sRushingYardsNetAverage" },
      { name: "Yds/Rec", value: "sReceptionsYardsAverage" },
    ],
    K: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "YPG", value: "sYardsOffensiveTotal/sGames" },
      { name: "Pass Com", value: "sPassesCompleted" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "YdsPg", value: "sYardsOffensiveTotalAverage" },
      { name: "Punts", value: "sPunts" },
      { name: "FG", value: "sFieldGoalsMade" },
      { name: "FGA", value: "sFieldGoalsAttempted" },
      { name: "FG PG", value: "sFieldGoalsPercentage" },
      { name: "PPG", value: "sPoints/sGames" },
      { name: "Pts", value: "sPoints" },
      { name: "Rush Yds", value: "sRushingTouchdowns" },
      { name: "All-Purp Yds", value: "sYardsAllPurposeTotal" },
      { name: "Rush TD", value: "sRushingTouchdowns" },
      { name: "Pass Att", value: "sPasses" },
      { name: "Pass Yds", value: "sPassesYards" },
      { name: "Pass TD", value: "sPassesTouchdowns" },
      { name: "Rec TD", value: "sReceptionsTouchdown" },
      { name: "Punt Yds", value: "sPatAttempts" },
      { name: "Pass 2PT Att", value: "sPatPassAttempts" },
      { name: "Rush 2PT Att", value: "sPatRushAttempts" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "Long Punt", value: "sPuntsLongest" },
      { name: "Long FGM", value: "sFieldGoalMadeLongest" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
      { name: "Yds/Play", value: "sYardsPerPlayAverage" },
      { name: "Yds/Rush", value: "sRushingYardsNetAverage" },
      { name: "Yds/Rec", value: "sReceptionsYardsAverage" },
    ],
    LB: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "TDs", value: "sTouchdowns" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Saf", value: "sSafetiesTwoPoints" },
      { name: "Fum Rec", value: "sFumblesRecovered" },
      { name: "Solo Tack", value: "sTacklesSolo" },
      { name: "Asst Tack", value: "sTacklesAssisted" },
      { name: "TT", value: "sTacklesTotal" },
      { name: "TPG", value: "sTacklesTotal/sGames" },
      { name: "STPG", value: "sTacklesSolos/sGames" },
      { name: "STFL", value: "sTacklesForLossSolo" },
      { name: "ATFL", value: "sTacklesForLossAssisted" },
      { name: "TTFL", value: "sTacklesForLossTotal" },
      { name: "TFLPG", value: "sTacklesForLossTotal/sGames" },
      { name: "Solo Sack", value: "sSacksUnassisted" },
      { name: "Asst Sack", value: "sSacksAssisted" },
      { name: "Tot Sack", value: "sSacksTotal" },
      { name: "Sacks PG", value: "sSacksTotal/sGames" },
      { name: "FF", value: "sFumblesForced" },
      { name: "FFPG", value: "sFumblesForced/sGames" },
      { name: "PBU", value: "sPassBreakUps" },
      { name: "Def Int Yds", value: "sInterceptionsReturnYards" },
      { name: "Sack Yds", value: "sSacksYards" },
      { name: "Tackle Yds", value: "sTacklesYards" },
      { name: "Int Ret TDs", value: "sInterceptionsTouchdowns" },
      { name: "Fum Ret TDs", value: "sFumbleReturnTouchdowns" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
      { name: "Blkd", value: "sPatBlockedKicksBy" },
      { name: "Blkd", value: "sPuntsBlockedBy" },
    ],
    LS: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Pts", value: "sPoints" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
    ],
    OC: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Pts", value: "sPoints" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
    ],
    OG: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Pts", value: "sPoints" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
    ],
    OT: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Pts", value: "sPoints" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
    ],
    P: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "YPG", value: "sYardsOffensiveTotal/sGames" },
      { name: "Pass Com", value: "sPassesCompleted" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "YdsPg", value: "sYardsOffensiveTotalAverage" },
      { name: "Punts", value: "sPunts" },
      { name: "FG", value: "sFieldGoalsMade" },
      { name: "FGA", value: "sFieldGoalsAttempted" },
      { name: "FG PG", value: "sFieldGoalsPercentage" },
      { name: "PPG", value: "sPoints/sGames" },
      { name: "Pts", value: "sPoints" },
      { name: "Rush Yds", value: "sRushingYardsNet" },
      { name: "All-Purp Yds", value: "sYardsAllPurposeTotal" },
      { name: "Rush TD", value: "sRushingTouchdowns" },
      { name: "Pass Att", value: "sPasses" },
      { name: "Pass Yds", value: "sPassesYards" },
      { name: "Pass TD", value: "sPassesTouchdowns" },
      { name: "Rec TD", value: "sReceptionsTouchdown" },
      { name: "Punt Yds", value: "sPuntsYards" },
      { name: "PAT Att", value: "sPatAttempts" },
      { name: "Pass 2PT Att", value: "sPatPassAttempts" },
      { name: "Rush 2PT Att", value: "sPatRushAttempts" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "Long Punt", value: "sPuntsLongest" },
      { name: "Long FGM", value: "sFieldGoalMadeLongest" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
      { name: "Yds/Play", value: "sYardsPerPlayAverage" },
      { name: "Yds/Rush", value: "sRushingYardsNetAverage" },
      { name: "Yds/Rec", value: "sReceptionsYardsAverage" },
    ],
    QB: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "Rush", value: "sRushingAttempts" },
      { name: "YDS", value: "sYardsOffensiveTotal" },
      { name: "TDs", value: "sTouchdowns" },
      { name: "YPG", value: "sYardsOffensiveTotal/sGames" },
      { name: "Pass Com", value: "sPassesCompleted" },
      { name: "Int", value: "sPassInterceptions" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Rec", value: "sReceptions" },
      { name: "Rec PG", value: "sReceptionsAverage" },
      { name: "YdsPg", value: "sYardsOffensiveTotalAverage" },
      { name: "PPG", value: "sPoints/sGames" },
      { name: "Int PG", value: "sInterceptionsAverage" },
      { name: "Pts", value: "sPoints" },
      { name: "Rush Yds", value: "sRushingYardsNet" },
      { name: "Rec Yds", value: "sReceptionsYards" },
      { name: "Fum Lost", value: "sFumblesLost" },
      { name: "Pass Eff", value: "sPassEfficiency" },
      { name: "All-Purp Yds", value: "sYardsAllPurposeTotal" },
      { name: "Rush TD", value: "sRushingTouchdowns" },
      { name: "Pass Att", value: "sPasses" },
      { name: "Int", value: "sPassesIntercepted" },
      { name: "Pass Yds", value: "sPassesYards" },
      { name: "Pass TD", value: "sPassesTouchdowns" },
      { name: "Rec TD", value: "sReceptionsTouchdown" },
      { name: "PAT Att", value: "sPatAttempts" },
      { name: "Pass 2PT Att", value: "sPatPassAttempts" },
      { name: "Rush 2PT Att", value: "sPatRushAttempts" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "Rush Long", value: "sRushingLongest" },
      { name: "Long Pass", value: "sPassesLongest" },
      { name: "Long Rec", value: "sReceptionsLongest" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
      { name: "Yds/Play", value: "sYardsPerPlayAverage" },
      { name: "Yds/Rush", value: "sRushingYardsNetAverage" },
      { name: "Yds/Comp", value: "sPassesAverageYardsPerCompletion" },
      { name: "Yds/Rec", value: "sReceptionsYardsAverage" },
    ],
    RB: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "Rush", value: "sRushingAttempts" },
      { name: "YDS", value: "sYardsOffensiveTotal" },
      { name: "TDs", value: "sTouchdowns" },
      { name: "YPG", value: "sYardsOffensiveTotal/sGames" },
      { name: "Pass Com", value: "sPassesCompleted" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Rec", value: "sReceptions" },
      { name: "Rec PG", value: "sReceptionsAverage" },
      { name: "YdsPg", value: "sYardsOffensiveTotalAverage" },
      { name: "PPG", value: "sPoints/sGames" },
      { name: "Pts", value: "sPoints" },
      { name: "Rush Yds", value: "sRushingYardsNet" },
      { name: "Rec Yds", value: "sReceptionsYards" },
      { name: "Punt Ret Yds", value: "sPuntReturnsYards" },
      { name: "KO Ret Yds", value: "sKickReturnsYards" },
      { name: "Fum Lost", value: "sFumblesLost" },
      { name: "All-Purp Yds", value: "sYardsAllPurposeTotal" },
      { name: "Rush TD", value: "sRushingTouchdowns" },
      { name: "Pass Att", value: "sPasses" },
      { name: "Pass Yds", value: "sPassesYards" },
      { name: "Pass TD", value: "sPassesTouchdowns" },
      { name: "Rec TD", value: "sReceptionsTouchdown" },
      { name: "Punt Yds", value: "sPuntsYards" },
      { name: "PAT Att", value: "sPatAttempts" },
      { name: "Pass 2PT Att", value: "sPatPassAttempts" },
      { name: "Rush 2PT Att", value: "sPatRushAttempts" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "Rush Long", value: "sRushingLongest" },
      { name: "Long Pass", value: "sPassesLongest" },
      { name: "Long Rec", value: "sReceptionsLongest" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
      { name: "Yds/Play", value: "sYardsPerPlayAverage" },
      { name: "Yds/Rush", value: "sRushingYardsNetAverage" },
      { name: "Yds/Rec", value: "sReceptionsYardsAverage" },
    ],
    S: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "TDs", value: "sTouchdowns" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Saf", value: "sSafetiesTwoPoints" },
      { name: "Fum Rec", value: "sFumblesRecovered" },
      { name: "Solo Tack", value: "sTacklesSolo" },
      { name: "Asst Tack", value: "sTacklesAssisted" },
      { name: "TT", value: "sTacklesTotal" },
      { name: "TPG", value: "sTacklesTotal/sGames" },
      { name: "STPG", value: "sTacklesSolos/sGames" },
      { name: "STFL", value: "sTacklesForLossSolo" },
      { name: "ATFL", value: "sTacklesForLossAssisted" },
      { name: "TTFL", value: "sTacklesForLossTotal" },
      { name: "TFLPG", value: "sTacklesForLossTotal/sGames" },
      { name: "Solo Sack", value: "sSacksUnassisted" },
      { name: "Asst Sack", value: "sSacksAssisted" },
      { name: "Tot Sack", value: "sSacksTotal" },
      { name: "Sacks PG", value: "sSacksTotal/sGames" },
      { name: "FF", value: "sFumblesForced" },
      { name: "FFPG", value: "sFumblesForced/sGames" },
      { name: "PBU", value: "sPassBreakUps" },
      { name: "Punt Ret", value: "sPuntReturns" },
      { name: "KO Ret", value: "sKickoffsReturned" },
      { name: "Def Int Yds", value: "sInterceptionsReturnYards" },
      { name: "Sack Yds", value: "sSacksYards" },
      { name: "Tackle Yds", value: "sTacklesYards" },
      { name: "Punt Ret TDs", value: "sPuntReturnsTouchdown" },
      { name: "Kick Ret TDs", value: "sKickReturnsTouchdowns" },
      { name: "Int Ret TDs", value: "sInterceptionsTouchdowns" },
      { name: "Fum Ret TDs", value: "sFumbleReturnTouchdowns" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "Long Int", value: "sInterceptionsReturnLongest" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
      { name: "Blkd", value: "sPatBlockedKicksBy" },
      { name: "Blkd", value: "sPuntsBlockedBy" },
    ],
    TE: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "Rush", value: "sRushingAttempts" },
      { name: "YDS", value: "sYardsOffensiveTotal" },
      { name: "TDs", value: "sTouchdowns" },
      { name: "YPG", value: "sYardsOffensiveTotal/sGames" },
      { name: "Pass Com", value: "sPassesCompleted" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Rec", value: "sReceptions" },
      { name: "Rec PG", value: "sReceptionsAverage" },
      { name: "YdsPg", value: "sYardsOffensiveTotalAverage" },
      { name: "PPG", value: "sPoints/sGames" },
      { name: "Pts", value: "sPoints" },
      { name: "Rush Yds", value: "sRushingYardsNet" },
      { name: "Rec Yds", value: "sReceptionsYards" },
      { name: "Punt Ret Yds", value: "sPuntReturnsYards" },
      { name: "KO Ret Yds", value: "sKickReturnsYards" },
      { name: "Fum Lost", value: "sFumblesLost" },
      { name: "All-Purp Yds", value: "sYardsAllPurposeTotal" },
      { name: "Rush TD", value: "sRushingTouchdowns" },
      { name: "Pass Att", value: "sPasses" },
      { name: "Pass Yds", value: "sPassesYards" },
      { name: "Pass TD", value: "sPassesTouchdowns" },
      { name: "Rec TD", value: "sReceptionsTouchdown" },
      { name: "Punt Yds", value: "sPuntsYards" },
      { name: "PAT Att", value: "sPatAttempts" },
      { name: "Pass 2PT Att", value: "sPatPassAttempts" },
      { name: "Rush 2PT Att", value: "sPatRushAttempts" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "Rush Long", value: "sRushingLongest" },
      { name: "Long Pass", value: "sPassesLongest" },
      { name: "Long Rec", value: "sReceptionsLongest" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
      { name: "Yds/Play", value: "sYardsPerPlayAverage" },
      { name: "Yds/Rush", value: "sRushingYardsNetAverage" },
      { name: "Yds/Rec", value: "sReceptionsYardsAverage" },
    ],
    WR: [
      { name: "Sport", value: "leagueId" },
      { name: "G", value: "sGames" },
      { name: "Rush", value: "sRushingAttempts" },
      { name: "YDS", value: "sYardsOffensiveTotal" },
      { name: "TDs", value: "sTouchdowns" },
      { name: "YPG", value: "sYardsOffensiveTotal/sGames" },
      { name: "Pass Com", value: "sPassesCompleted" },
      { name: "Plays", value: "sPlaysTotal" },
      { name: "Rec", value: "sReceptions" },
      { name: "Rec PG", value: "sReceptionsAverage" },
      { name: "YdsPg", value: "sYardsOffensiveTotalAverage" },
      { name: "PPG", value: "sPoints/sGames" },
      { name: "Pts", value: "sPoints" },
      { name: "Rush Yds", value: "sRushingYardsNet" },
      { name: "Rec Yds", value: "sReceptionsYards" },
      { name: "Punt Ret Yds", value: "sPuntReturnsYards" },
      { name: "KO Ret Yds", value: "sKickReturnsYards" },
      { name: "Fum Lost", value: "sFumblesLost" },
      { name: "All-Purp Yds", value: "sYardsAllPurposeTotal" },
      { name: "Rush TD", value: "sRushingTouchdowns" },
      { name: "Pass Att", value: "sPasses" },
      { name: "Pass Yds", value: "sPassesYards" },
      { name: "Pass TD", value: "sPassesTouchdowns" },
      { name: "Rec TD", value: "sReceptionsTouchdown" },
      { name: "Punt Yds", value: "sPuntsYards" },
      { name: "PAT Att", value: "sPatAttempts" },
      { name: "Pass 2PT Att", value: "sPatPassAttempts" },
      { name: "Rush 2PT Att", value: "sPatRushAttempts" },
      { name: "Penalties", value: "sPenalty" },
      { name: "PYards", value: "sPenaltyYards" },
      { name: "PenPerGame", value: "sPenalty/sGames" },
      { name: "Rush Long", value: "sRushingLongest" },
      { name: "Long Pass", value: "sPassesLongest" },
      { name: "Long Rec", value: "sReceptionsLongest" },
      { name: "W", value: "sWins" },
      { name: "L", value: "sLosses" },
      { name: "Yds/Play", value: "sYardsPerPlayAverage" },
      { name: "Yds/Rush", value: "sRushingYardsNetAverage" },
      { name: "Yds/Rec", value: "sReceptionsYardsAverage" },
    ],
  };
  return positionJson[position] || [];
}

export async function axiosPost(subUrl, postJson) {
  return await axios({
    method: "post",
    url: Config.dataUrl + subUrl,
    data: postJson,
    headers: { "Content-Type": "application/json" },
  })
    .then(function (response) {
      //handle success
      return response;
    })
    .catch(function (error) {
      console.log(error.toJSON());
    });
  // await axios.post(Config.dataUrl + subUrl, postJson)
  // .then(res => {
  //     console.log(res)
  //     return res;
  // })
}

export function unixTime(unixtime) {
  var u = new Date(unixtime);
  var month = [];
  month[0] = "01";
  month[1] = "02";
  month[2] = "03";
  month[3] = "04";
  month[4] = "05";
  month[5] = "06";
  month[6] = "07";
  month[7] = "08";
  month[8] = "09";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";
  return (
    month[u.getUTCMonth()] +
    "/" +
    ("0" + u.getUTCDate()).slice(-2) +
    "/" +
    u.getUTCFullYear()
  );
}

export function sort_by_key(array, key) {
  return array.sort(function (a, b) {
    var x = checkNullAndReturnString(a[key]) ? a[key].toLowerCase() : "";
    var y = checkNullAndReturnString(b[key]) ? b[key].toLowerCase() : "";
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function numberConverter(data) {
  if (data) {
    return parseFloat(data);
  }
  return 0;
}

export function dateAndTimeReturn(dateVal) {
  var month = [];
  month[0] = "01";
  month[1] = "02";
  month[2] = "03";
  month[3] = "04";
  month[4] = "05";
  month[5] = "06";
  month[6] = "07";
  month[7] = "08";
  month[8] = "09";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";
  var date = new Date(dateVal);
  var hours = date.getUTCHours();
  var minutes = date.getUTCMinutes();
  // hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes;
  return (
    date.getUTCFullYear() +
    "-" +
    month[date.getUTCMonth()] +
    "-" +
    ("0" + date.getUTCDate()).slice(-2) +
    " " +
    strTime
  );
}

export function formatAMPM(val) {
  var date = new Date(val);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function humanFileSize(bytes, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export function checkNullAndReturnString(str) {
  if (str !== null && str !== undefined && str !== "") {
    return true;
  }
  return false;
}

export function getAge(dateString) {
  var birthday = +new Date(dateString);
  return ~~((Date.now() - birthday) / 31557600000);
}

export function ValidateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    ? true
    : false;
}

// export function ValidateEmail(emailField) {
//   var reg = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+).([a-zA-Z]{2,15})$/;
//   if (reg.test(emailField) === false) {
//     return false;
//   }
//   return true;
// }

export async function doPostConnect(subUrl, postJson) {
  return fetch(Config.dataUrl + subUrl, {
    method: "POST",
    mode: "cors",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postJson),
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (dataresponse) {
      return dataresponse;
    });
}

export async function doPostFormData(subUrl, postData) {
  return fetch(Config.dataUrl + subUrl, {
    method: "POST",
    // mode: 'cors',
    // redirect: 'follow',
    // headers: {
    //     'Content-Type': 'multipart/form-data'
    // },
    body: postData,
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (dataresponse) {
      return dataresponse;
    });
}

export async function doPostFormDataSecurity(subUrl, postData, clientToken) {
  return fetch(Config.dataUrl + subUrl, {
    method: "POST",
    // mode: 'cors',
    // redirect: 'follow',
    // headers: {
    //     'Content-Type': 'multipart/form-data'
    // },
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: "Bearer " + clientToken,
    },
    body: postData,
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (dataresponse) {
      if (checkNullAndReturnString(dataresponse.error)) {
        if (checkNullAndReturnString(dataresponse.error.data)) {
          if (
            checkNullAndReturnString(dataresponse.error.data.status) &&
            dataresponse.error.data.status === 401
          ) {
            return refreshTocken(subUrl, postData, "postFormDataSecurity");
            // doGetConnectSecurity(subUrl, clientToken)
          }
        }
      }
      return dataresponse;
    });
}

export async function doPutConnectSecurity(subUrl, postJson, clientToken) {
  return fetch(Config.dataUrl + subUrl, {
    method: "PUT",
    mode: "cors",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + clientToken,
    },
    body: JSON.stringify(postJson),
  })
    .then(async function (response) {
      const jsondata = await response.json();
      if (!response.ok) {
        return Promise.reject(jsondata);
      } else {
        return jsondata;
      }
    })
    .then(function (dataresponse) {
      if (checkNullAndReturnString(dataresponse.error)) {
        if (checkNullAndReturnString(dataresponse.error.data)) {
          if (
            checkNullAndReturnString(dataresponse.error.data.status) &&
            dataresponse.error.data.status === 401
          ) {
            return refreshTocken(subUrl, postJson, "putSecurity");
            // doGetConnectSecurity(subUrl, clientToken)
          }
        }
      }
      return dataresponse;
    })
    .catch(function (dataresponse) {
      if (checkNullAndReturnString(dataresponse.error)) {
        if (checkNullAndReturnString(dataresponse.error.data)) {
          if (
            checkNullAndReturnString(dataresponse.error.data.status) &&
            dataresponse.error.data.status === 401
          ) {
            return refreshTocken(subUrl, postJson, "postSecurity");
            // doGetConnectSecurity(subUrl, clientToken)
          }
        }
      }
      return Promise.resolve(dataresponse);
    });
}

export async function doPutConnectFormDataSecurity(
  subUrl,
  postJson,
  clientToken
) {
  return fetch(Config.dataUrl + subUrl, {
    method: "PUT",
    // mode: 'cors',
    // redirect: 'follow',
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: "Bearer " + clientToken,
    },
    body: postJson,
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (dataresponse) {
      if (checkNullAndReturnString(dataresponse.error)) {
        if (checkNullAndReturnString(dataresponse.error.data)) {
          if (
            checkNullAndReturnString(dataresponse.error.data.status) &&
            dataresponse.error.data.status === 401
          ) {
            return refreshTocken(subUrl, postJson, "putFormData");
            // doGetConnectSecurity(subUrl, clientToken)
          }
        }
      }
      return dataresponse;
    });
}

export async function doPostConnectSecurity(subUrl, postJson, clientToken) {
  return fetch(Config.dataUrl + subUrl, {
    method: "POST",
    mode: "cors",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + clientToken,
    },
    body: JSON.stringify(postJson),
  })
    .then(
      // function (response) {
      //     return response.json();
      // }
      async function (response) {
        const jsondata = await response.json();
        if (!response.ok) {
          return Promise.reject(jsondata);
        } else {
          return jsondata;
        }
      }
    )
    .then(function (dataresponse) {
      if (checkNullAndReturnString(dataresponse.error)) {
        if (checkNullAndReturnString(dataresponse.error.data)) {
          if (
            checkNullAndReturnString(dataresponse.error.data.status) &&
            dataresponse.error.data.status === 401
          ) {
            return refreshTocken(subUrl, postJson, "postSecurity");
            // doGetConnectSecurity(subUrl, clientToken)
          }
        }
      }
      return dataresponse;
    })
    .catch(function (dataresponse) {
      if (checkNullAndReturnString(dataresponse.error)) {
        if (checkNullAndReturnString(dataresponse.error.data)) {
          if (
            checkNullAndReturnString(dataresponse.error.data.status) &&
            dataresponse.error.data.status === 401
          ) {
            return refreshTocken(subUrl, postJson, "postSecurity");
            // doGetConnectSecurity(subUrl, clientToken)
          }
        }
      }
      return Promise.resolve(dataresponse);
    });
}

export function doGetConnect(subUrl) {
  return fetch(Config.dataUrl + subUrl, {
    method: "GET",
    mode: "cors",
    redirect: "follow",
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (dataresponse) {
      return dataresponse;
    });
}

export function doDeleteConnect(subUrl, clientToken) {
  return fetch(Config.dataUrl + subUrl, {
    method: "DELETE",
    mode: "cors",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + clientToken,
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (dataresponse) {
      return dataresponse;
    });
}

export async function refreshTocken(subUrl, postJson, type) {
  var refreshTocken = localStorage.getItem("refreshToken");
  return fetch(Config.dataUrl + "refresh-token?token=" + refreshTocken, {
    method: "GET",
    mode: "cors",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (dataresponse) {
      if (checkNullAndReturnString(dataresponse.accessToken)) {
        localStorage.setItem("accessToken", dataresponse.accessToken);
        localStorage.setItem("refreshToken", dataresponse.refreshToken);
        if (type === "getsecurity") {
          return doGetConnectSecurity(subUrl, dataresponse.accessToken);
        } else if (type === "postSecurity") {
          return doPostConnectSecurity(
            subUrl,
            postJson,
            dataresponse.accessToken
          );
        } else if (type === "putFormData") {
          return doPutConnectFormDataSecurity(
            subUrl,
            postJson,
            dataresponse.accessToken
          );
        } else if (type === "putSecurity") {
          return doPutConnectSecurity(
            subUrl,
            postJson,
            dataresponse.accessToken
          );
        } else if (type === "postFormDataSecurity") {
          return doPostFormDataSecurity(
            subUrl,
            postJson,
            dataresponse.accessToken
          );
        }
      } else if (dataresponse.error && dataresponse.error.data) {
        // const { error: { data } } = dataresponse;
        const userid = localStorage.getItem("id");
        history.push({
          pathname: `/login`,
          search: `?userid=${userid}`,
        });
        window.location.reload(true);
      }
    });
}

export async function doGetConnectSecurity(subUrl, clientToken) {
  return fetch(Config.dataUrl + subUrl, {
    method: "GET",
    mode: "cors",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + clientToken,
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (dataresponse) {
      if (checkNullAndReturnString(dataresponse.error)) {
        if (checkNullAndReturnString(dataresponse.error.data)) {
          if (
            checkNullAndReturnString(dataresponse.error.data.status) &&
            dataresponse.error.data.status === 401
          ) {
            return refreshTocken(subUrl, "", "getsecurity");
            // doGetConnectSecurity(subUrl, clientToken)
          }
        }
      }
      return dataresponse;
    });
}
