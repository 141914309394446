import React, { useCallback, useEffect, useState } from "react";
import Tables from "./table";
// import SelectBox from "./selectBoxDefaut";
import Tab from "./tab";
import { checkNullAndReturnString, doGetConnectSecurity } from '../helper/common';
// import PdfIcon from "../images/pdf.png";
// import ExcelIcon from "../images/xls.png";
import PdfIcon from "../images/pdf_white.png";
import ExcelIcon from "../images/xls_white.png";

import fileDownload from 'js-file-download';
import Config from "../helper/config";
import axios from 'axios';
const moment = require("moment");

const DashBoardSeeDetailComponent = (props) => {
    // const [data, setData] = useState({
    //     approvedList: [],
    //     pendingList: [],
    //     registeredList: [],
    //     rejectedList: []
    // });
    const [serviceOfferList, setServiceOfferedList] = useState([]);
    const [loadData, setLoadData] = useState([]);
    const [selectedId, setSelectedId] = useState("4")
    const [userState, setUserState] = useState("0");

    const [sortfilter, setsortfilter] = useState({
        sortby: 'first_name',
        order: true,
        skip: 0,
        take: 0,
        startdate: '',
        enddate: '',
        filtervalue: ''
    });

    const [countdata, setcountdata] = useState({
        approved_total: 0,
        id: 0,
        pending_total: 0,
        registered_total: 0,
        rejected_total: 0,
        role_name: ''
    })

    const [paginationvalue, setpaginationvalue] = useState({
        paginatonpageindex: 0,
        paginationrowsPerPage: 50,
        paginationcountdata: 0
    });



    useEffect(() => {
        const inputdata = { ...props.data };
        setServiceOfferedList(props.serviceOfferedList);
        const findelem = props.userDetails.find(e => (e.id && e.id.toString()) === (inputdata.id && inputdata.id.toString()));
        if (findelem) {
            setcountdata(findelem);
            setpaginationvalue(pre => {
                const remodel = {
                    ...pre,
                    paginationcountdata: findelem.registered_total
                };
                return { ...remodel }
            });
        }

        setSelectedId(inputdata.id);
        delete inputdata.id;
        delete inputdata.show;
        // setData(inputdata);
        setsortfilter(pre => {
            return {
                ...pre,
                startdate: props.startdate,
                enddate: props.enddate,
                filtervalue: props.filtervalue
            }
        })
    }, [props]);


    const serviceOfferedListModifier = useCallback((ids) => {
        const list = [...serviceOfferList];
        const idmodifier = ids.map(e => {
            const findelem = list.find(e1 => e1.id === e);
            if (findelem) {
                return findelem.service_name
            } else {
                return ""
            }
        });
        // return idmodifier.join('');
        return (
            <div>
                {idmodifier.map((e, i) => (
                    <p id={i}>{e}</p>
                ))}
            </div>
        )
    }, [serviceOfferList])


    useEffect(() => {
        async function fetchData() {
            const { startdate, enddate, filtervalue, sortby, order } = sortfilter;
            const formatstartdate = moment(startdate).format("MM-DD-YYYY");
            const formatenddate = moment(enddate).format("MM-DD-YYYY");

            const { paginatonpageindex, paginationrowsPerPage } = paginationvalue;

            const pagefrom = paginationrowsPerPage * paginatonpageindex;
            const pageto = paginationrowsPerPage;
            if (formatstartdate !== "Invalid date" && formatenddate !== "Invalid date" && Number(selectedId) !== 0) {
                var accessToken = localStorage.getItem("accessToken");
                var responseData = await doGetConnectSecurity(
                    `dashboard/seeDetails?fromdate=${formatstartdate}&enddate=${formatenddate}&filterdata=${filtervalue}&roleid=${selectedId}&sortby=${sortby}&order=${(order ? "asc" : "desc")}&skip=${pagefrom}&take=${pageto}`
                    , accessToken);
                if (checkNullAndReturnString(responseData)) {
                    if (checkNullAndReturnString(responseData.data)) {
                        const { registeredData, approvedData, pendingData, /* rejectedData */ } = responseData.data;
                        // setData({
                        //     approvedList: approvedData,
                        //     pendingList: pendingData,
                        //     registeredList: registeredData,
                        //     rejectedList: rejectedData
                        // });

                        let arrdata = [];
                        if (Number(userState) === 0) {
                            arrdata = [...registeredData];
                        } else if (Number(userState) === 1) {
                            arrdata = [...approvedData];
                        } else if (Number(userState) === 2) {
                            arrdata = [...pendingData];
                        };
                        let loadData = [];
                        if (Number(selectedId) === 4) {
                            loadData = arrdata.map(e => {
                                return {
                                    first_name: (e.first_name.concat(' ', e.last_name)).replace(/^\b\w{1}/gmi, (match) => match.toUpperCase()),
                                    school_name: e.school_name,
                                    player_position: e.player_position,
                                    class: e.class,
                                    sport: 'Football'
                                }
                            })
                        } else if (Number(selectedId) === 3) {
                            loadData = arrdata.map(e => {
                                console.log({
                                    es: e.service_offered
                                })
                                return {
                                    first_name: (e.first_name.concat(' ', e.last_name)).replace(/^\b\w{1}/gmi, (match) => match.toUpperCase()),
                                    agency_name: e.agency_name,
                                    service_offered:
                                        (e.service_offered && Array.isArray(e.service_offered) && e.service_offered.length) ?
                                            serviceOfferedListModifier(e.service_offered) :
                                            "",
                                    city: e.user_city,
                                    state: e.user_state
                                }
                            })
                        } else if (Number(selectedId) === 2) {
                            loadData = arrdata.map(e => {
                                return {
                                    first_name: (e.first_name.concat(' ', e.last_name)).replace(/^\b\w{1}/gmi, (match) => match.toUpperCase()),
                                    school_name: e.school_name,
                                    city: e.city,
                                    state: e.state
                                }
                            })
                        }
                        setLoadData(loadData);
                    }
                }
            }
        }
        fetchData();

    }, [serviceOfferedListModifier, userState, selectedId, serviceOfferList, sortfilter, paginationvalue, countdata]);
    // data



    const tabOnClick = (index) => {
        const findelem = props.userDetails.find(e => (e.id && e.id.toString()) === (selectedId && selectedId.toString()));
        if (findelem) {
            let total_value = 0;
            if (Number(index) === 0) {
                total_value = findelem.registered_total;
            } else if (Number(index) === 1) {
                total_value = findelem.approved_total;
            } else if (Number(index) === 2) {
                total_value = findelem.pending_total;
            }
            setpaginationvalue({
                paginatonpageindex: 0,
                paginationrowsPerPage: 50,
                paginationcountdata: total_value
            })
        }

        setsortfilter(pre => {
            return {
                ...pre,
                sortby: 'first_name',
                order: true,
                skip: 0,
                take: 0
            }
        })
        setUserState(index);
    }

    const thJSON = () => {
        if (Number(selectedId) === 4) {
            return ["Player Name", "School", "Position", "Class", "Sport"]
        } else if (Number(selectedId) === 3) {
            return ["Agent Name", "Agency Name", "Services Offered", "City", "State"]
        } else {
            return ["University admin", "School", "City", "State"]
        }
    }


    const onSortModalData = (column) => {
        if (column === "sport") { return }
        let sortcolumnname = '';
        if (column === "Player Name" || column === "Agent Name" || column === "University admin name") {
            sortcolumnname = 'first_name'
        } else
            if (column === "Player School" || column === "school") { sortcolumnname = 'school_name' } else
                if (column === "position") { sortcolumnname = 'player_position' } else
                    if (column === "class") { sortcolumnname = 'class' } else
                        if (column === "Agency Name") { sortcolumnname = 'agency_name' } else
                            if (column === "Services Offered") { sortcolumnname = 'service_offered' } else
                                if (column === "City") { sortcolumnname = 'city' } else
                                    if (column === "State") { sortcolumnname = 'state' } else { sortcolumnname = 'first_name' }
        setsortfilter(pre => {
            return {
                ...pre,
                sortby: sortcolumnname,
                order: (pre.sortby === sortcolumnname ? !pre.order : true),
            }
        })
    }


    const handleChangePageModal = async (event, newPage) => {
        setpaginationvalue(pre => {
            return {
                ...pre,
                paginatonpageindex: newPage
            }
        })
    }

    const handleChangeRowsPerPageModal = async (event) => {
        setpaginationvalue(pre => {
            return {
                ...pre,
                paginationrowsPerPage: event.target.value,
                paginatonpageindex: 0
            }
        })
    }


    const generatepdf = () => {
        const { startdate, enddate, filtervalue } = sortfilter;
        const formatstartdate = moment(startdate).format("MM-DD-YYYY");
        const formatenddate = moment(enddate).format("MM-DD-YYYY");

        axios({
            method: 'GET',
            url: `${Config.dataUrl}dashboard/getAdminDashboardUsersPdf?fromdate=${formatstartdate}&enddate=${formatenddate}&filterdata=${filtervalue}&roleid=${selectedId}&sortby=first_name&order=asc&skip=0&take=0`,
            data: {},
            responseType: 'blob'
        })
            .then((res) => {
                fileDownload(res.data, `${(Number(selectedId) === 4 ? "PlayerDetails" : (selectedId === "3" ? "AgentDetails" : "UniversityAdminDetails"))}.pdf`)
            })
    }

    const generateexcelsheet = () => {
        const { startdate, enddate, filtervalue } = sortfilter;
        const formatstartdate = moment(startdate).format("MM-DD-YYYY");
        const formatenddate = moment(enddate).format("MM-DD-YYYY");

        axios({
            method: 'GET',
            url: `${Config.dataUrl}dashboard/testexcelsheet?fromdate=${formatstartdate}&enddate=${formatenddate}&filterdata=${filtervalue}&roleid=${selectedId}&sortby=first_name&order=asc&skip=0&take=0`,
            data: {},
            responseType: 'blob'
        })
            .then((res) => {
                // fileDownload(res.data, "UserDetail.xls");
                fileDownload(res.data, `${(Number(selectedId) === 4 ? "PlayerDetails" : (selectedId === "3" ? "AgentDetails" : "UniversityAdminDetails"))}.xls`)
            })
    }

    return (
        <div class="" style={{ padding: 20, background: "#2d303a" }}>
            <div className={"row"}>
                <div style={{ position: "absolute", zIndex: 1, right: 50 }}>
                    <img src={PdfIcon} alt="PdfIcon" style={{ width: 32, height: 32, cursor: 'pointer' }} onClick={generatepdf} />
                    &nbsp;&nbsp;&nbsp;
                    <img src={ExcelIcon} alt="ExcelIcon" style={{ width: 32, height: 32, cursor: 'pointer' }} onClick={generateexcelsheet} />
                </div>
                {/* <button type="button" onClick={() => setsortfilter(pre => { return { ...pre, sortby: 'name' } })}>sdsd</button> */}
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                    <Tab json={["Registered", "Appproved", "Request Pending"]}
                        selected={userState}
                        tabOnClick={tabOnClick} />
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " style={{ paddingTop: 20 }}>
                    <Tables fixed={true} extraStyle={{ height: props.height / 1.6 }}
                        sortHeader={true} onSortData={onSortModalData}
                        thJson={[...thJSON()]}
                        tdJson={[...loadData]}
                        paginationFooter={paginationvalue}
                        handleChangePage={handleChangePageModal}
                        handleChangeRowsPerPage={handleChangeRowsPerPageModal}
                    />
                </div>
            </div>
        </div >
    )
}

export default DashBoardSeeDetailComponent