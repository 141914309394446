import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  // Link, Switch
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Config from "./helper/config";
import LogIn from "./views/manage/logIn";
import ForgotPassword from "./views/manage/forgotPassword";
import PlayerPerformence from "./views/player/playerPerformence";
import LogOut from "./views/manage/logout";
import Home from "./views/manage/home";
import SignUp from "./views/manage/signup";
import PlayerSuccess from "./views/manage/playerSuccess";
import PlayerMatchingSuccess from "./views/manage/playerMatchingSuccess";
import AgentValidate from "./views/manage/agentValidate";
import AgentDisclaimer from "./views/manage/agentDesclaimer";
import TermsOfUse from "./views/manage/termsOfUse";
import AgentCreatePassword from "./views/manage/agentCreatePassword";
import AdminRouter from "./views/admin/router";
import AgentRouter from "./views/agent/router";
import PlayerRouter from "./views/player/router";
import UaAdminRouter from "./views/uaAdmin/router";
import CoachUserRouter from "./views/coachUser/router";
import { VersionHistoryRoute } from "./views/versionhistory/router";
import { createStore } from "./component/hooks/store";
import {
  ToastContainer,
  // toast
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import about from "./views/manage/about";
import privacy from "./views/manage/privacy";
import AthleteDisclaimer from "./views/manage/athleteDisclaimer";
import FrequentlyAskedQuestions from "./views/manage/frequentlyAskedQuestions";
import support from "./views/manage/support";
import agentPlayerPerformance from "./views/agent/agentPlayerPerformance";

export const history = createBrowserHistory();
const reactUrl = Config.reactUrl;

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Route exact path={reactUrl + "/"} component={Home} />
        <Route exact path={reactUrl + "/login"} component={LogIn} />
        <Route exact path={reactUrl + "/logout"} component={LogOut} />
        <Route exact path={reactUrl + "/signup/:type?"} component={SignUp} />
        <Route
          exact
          path={reactUrl + "/matchingsuccess"}
          component={PlayerMatchingSuccess}
        />
        <Route exact path={reactUrl + "/success"} component={PlayerSuccess} />
        <Route
          exact
          path={reactUrl + "/agentvalidate"}
          component={AgentValidate}
        />
        <Route path={reactUrl + "/disclaimer"} component={AgentDisclaimer} />
        <Route path={reactUrl + "/athletedisclaimer"} component={AthleteDisclaimer} />
        <Route path={reactUrl + "/termsofuse"} component={TermsOfUse} />
        <Route path={reactUrl + "/aboutnxtpick"} component={about} />
        <Route path={reactUrl + "/support"} component={support} />
        <Route path={reactUrl + "/frequently-asked-questions"} component={FrequentlyAskedQuestions} />
        <Route path={reactUrl + "/privacy"} component={privacy} />
        <Route path={reactUrl + "/privacy-policy"} component={TermsOfUse} />
        <Route path={reactUrl + "/forgotpassword"} component={ForgotPassword} />
        <Route path={reactUrl + "/player/playerperformence"} component={PlayerPerformence} />
        <Route path={reactUrl + "/agent/playerperformence"} component={agentPlayerPerformance} />
        <Route
          path={reactUrl + "/password"}
          component={AgentCreatePassword}
        />
        <AdminRouter />
        <AgentRouter />
        <PlayerRouter />
        <UaAdminRouter />
        <CoachUserRouter />
        <VersionHistoryRoute />
        <ToastContainer autoClose={1500} style={{ zIndex: 10001 }} />
      </Router>
    );
  }
}

export default createStore(App);
