import React, { Component } from 'react'
import { InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { checkNullAndReturnString } from '../helper/common';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class index extends Component {
    render() {
        var { keyName, options, value, onChange, backgroundColor, fullWidth, extraStyle, disabled = false,
            from,
            placeholder } = this.props;
        if (checkNullAndReturnString(from)) {
            let optionList = [];
            if (checkNullAndReturnString(options)) {
                Object.keys(options).forEach(key => {
                    optionList.push(
                        <MenuItem value={options[key].value}>
                            {options[key].label}
                        </MenuItem>
                    )
                })
            }
            const BootstrapInput = withStyles((theme) => ({
                input: {
                    borderRadius: 4,
                    position: 'relative',
                    opacity: 0.7,
                    backgroundColor: backgroundColor ? backgroundColor : '#51535d',
                    border: 'none',
                    height: 22,
                    // borderRadius: 5,
                    color: "#FFFFFF",
                    padding: '10px 26px 10px 12px',
                    font: "normal normal 300 14px/18px Poppins",
                    letterSpacing: 0,
                    '&:focus': {
                        border: "none",
                    },
                },
                root: {
                    padding: 0
                },
                MuiMenu: {
                    height: 200
                }
            }))(InputBase);
            return (
                <Select disabled={disabled} input={<BootstrapInput />} fullWidth={fullWidth ? false : true} labelId="label" id="select" value={value}
                    onChange={(e) => onChange(keyName, e.target.value)}
                    style={{ ...extraStyle }}
                // style={{ 
                //     borderRadius: 5,
                //     height: 44, 
                //     backgroundColor: backgroundColor ? backgroundColor : '#51535d',
                // }}
                >
                    {/* <MenuItem value="" style={{ height: '20px' }}> </MenuItem> */}
                    {optionList}
                </Select>
            )
        }
        else {
            let optionList = [
                <MenuItem value={''} style={{ fontFamily: 'Poppins' }}>
                    <em>{"None"}</em>
                </MenuItem>
            ];
            var placeHolder = placeholder;
            if (checkNullAndReturnString(options)) {
                Object.keys(options).forEach(key => {
                    if (checkNullAndReturnString(options[key].label)) {
                        optionList.push(
                            <MenuItem value={options[key].value}>
                                {options[key].label}
                            </MenuItem>
                        )
                    }
                })
            }
            return (
                <FormControl fullWidth style={{ ...extraStyle }} >
                    <InputLabel id="demo-simple-select-label" style={{ color: "#fff", fontSize: 13 }}>{placeHolder}</InputLabel>
                    <Select
                        disabled={disabled}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        style={!checkNullAndReturnString(value) || Number(value) === 0 ? { ...extraStyle } : { paddingTop: 5, ...extraStyle }}
                        value={!checkNullAndReturnString(value) || Number(value) === 0 ? "" : value}
                        onChange={(e) => onChange(keyName, e.target.value)}
                    >
                        {optionList}
                    </Select>
                </FormControl>
            )
        }
    }
}
export default index;