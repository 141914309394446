import React, { Component } from 'react'
import { withStore } from '../../component/hooks/store';
// import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { checkNullAndReturnString, doGetConnectSecurity, doPostConnectSecurity, doGetConnect, doDeleteConnect, sort_by_key } from '../../helper/common';
import Styles from "../../styles/uaAdmin/dashboard";
import Tables from "../../component/table";
import ReactFileReader from 'react-file-reader';
import SelectBox from "../../component/selectBox";
import { toast } from 'react-toastify';
import Button from "../../component/button";
import exportFromJSON from 'export-from-json'
// import jsPDF from "jspdf";
import "jspdf-autotable";
import TablePagination from "@material-ui/core/TablePagination";

import axios from 'axios';
import Config from "../../helper/config";
import fileDownload from 'js-file-download';
import ReactExport from 'react-data-export';

// const data = [{ foo: 'foo', bar: "fff" }, { foo: 'foog', bar: "fffg" }]
const fileName = 'workoutperfomence'
const exportType = 'xls';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class UploadWorkoutPerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            approveTotal: 0,
            rejectedTotal: 0,
            height: 0,
            width: 0,
            academicYear: [{ label: "Academic Year", value: 0 }],
            year: 0,
            tableData: [],
            loadData: [],
            fileName: '',
            draftData: false,
            draftId: "",
            exportTemplateArray: [],
            paginations: {
                paginatonpageindex: 0,
                paginationrowsPerPage: 50,
                paginationcountdata: 0
            }
        }
    }

    componentDidMount = () => {
        this.updateWindowDimensions();
        this.getAcademicYear()
        // exportFromJSON({ data, fileName, exportType })
    }

    getDraftData = async () => {
        var accessToken = localStorage.getItem("accessToken");
        var userDetail = JSON.parse(localStorage.getItem("userDetail"));
        var school_id = userDetail?.school_id;
        if (this.props.from === 'admin') {
            school_id = this.props.id
        }
        var responseData = await doGetConnectSecurity("player-performance/draft?school_id=" + school_id, accessToken)
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data)) {
                if (checkNullAndReturnString(responseData.data.performance_data)) {
                    var performance_data = JSON.parse(responseData.data.performance_data)
                    this.setState({ tableData: performance_data, year: responseData.data.academic_year, draftData: true, draftId: responseData.data.id })
                }
                else {
                    this.getWorkoutPerformance(this.state.year);
                }
            }
        }
        // this.getWorkoutPerformance(this.state.year);
    }

    handleChange = (id, value) => {
        if (id === "year") {
            this.getWorkoutPerformance(value);
        }
        this.setState({
            [id]: value
        })
    }

    exportPDFData = () => {
        var postJson = {
            data: this.state.tableData
        }
        axios({
            method: 'POST',
            url: `${Config.dataUrl}player-performance/getworkoutperformancepdf`,
            data: postJson,
            responseType: 'blob'
        })
            .then((res) => {
                fileDownload(res.data, "Uploadworkoutperformance.pdf")
            })


        // const unit = "pt";
        // const size = "A4"; // Use A1, A2, A3 or A4
        // const orientation = "portrait"; // portrait or landscape
        // const marginLeft = 40;
        // const doc = new jsPDF(orientation, unit, size);
        // doc.setFontSize(15);
        // const headers = [["Player", "POS", "CLS", "HT", "WT", "Enrolled", "40 YD Dash", "20 YD Shuttle", "60 YD Shuttle", "3 Core Drill", "Broad Jump", "Verticle Jump", "225 Bench Reps"]];
        // const data = this.state.tableData.map(elt => [elt.first_name + " " + elt.last_name, elt.position, elt.class, elt.height_feet + " Ft. " + elt.height_inches + " In", elt.weight + " Lbs", elt.enrolled ? "Yes" : "No", elt.fourty_yard_dash, elt.twenty_yard_shuttle, elt.sixty_yard_shuttle, elt.three_core_drill, elt.broad_jump, elt.vertical_jump, elt.bench_press]);
        // let content = {
        //     startY: 50,
        //     head: headers,
        //     body: data
        // };
        // //doc.text(title, marginLeft, 40);
        // doc.autoTable(content);
        // doc.save("report.pdf")
    }

    exportToExcel = () => {
        var tableData = this.state.tableData
        exportFromJSON({ tableData, fileName, exportType })
    }

    getWorkoutPerformance = async (year) => {
        this.setState({ tableData: [] }, this.sampledatacreator)
        if (Number(year) === 0) {
            year = "";
        }
        var accessToken = localStorage.getItem("accessToken");
        var userDetail = JSON.parse(localStorage.getItem("userDetail"));
        var school_id = userDetail?.school_id;
        if (this.props.from === 'admin') {
            school_id = this.props.id
        }
        var responseData = await doGetConnectSecurity("player-performance?skip=&take=&order=asc&sortby=full_name&academicYear=" + year + '&school_id=' + school_id, accessToken)
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.UserStatusCount)) {
                if (checkNullAndReturnString(responseData.UserStatusCount)) {
                    this.setState({ total: responseData.UserStatusCount.total_count })
                    this.setState({ approveTotal: responseData.UserStatusCount.enrolled_count })
                    this.setState({ rejectedTotal: parseInt(responseData.UserStatusCount.unenrolled_count) })
                }
            }
            if (checkNullAndReturnString(responseData.data)) {
                if (checkNullAndReturnString(responseData.data)) {
                    var list = sort_by_key(responseData.data, 'full_name')
                    list = list.map((e, i) => {
                        return {
                            ...e,
                            id: i
                        }
                    });
                    this.setState({ tableData: list }, this.sampledatacreator)
                }
            }
        }
    }


    sampledatacreator = () => {
        const { tableData, paginations: {
            paginatonpageindex,
            paginationrowsPerPage,
            // paginationcountdata
        } } = this.state;

        const sampleDataToLoad = [...tableData].map(e => {
            return {
                ...e,
                fourty_yard_dash: (e.fourty_yard_dash ? e.fourty_yard_dash : ""),
                twenty_yard_shuttle: (e.twenty_yard_shuttle ? e.twenty_yard_shuttle : ""),
                // sixty_yard_shuttle: (e.sixty_yard_shuttle ? e.sixty_yard_shuttle : ""),
                three_core_drill: (e.three_core_drill ? e.three_core_drill : ""),
                broad_jump: (e.broad_jump ? e.broad_jump : ""),
                vertical_jump: (e.vertical_jump ? e.vertical_jump : ""),
                bench_press: (e.bench_press ? e.bench_press : "")
            }
        });
        const pagefrom = paginationrowsPerPage * paginatonpageindex;
        const pageto = paginationrowsPerPage;
        const loadslicedata1 = sampleDataToLoad.slice(0, (pagefrom + pageto));
        const loadslicedata = [...loadslicedata1].splice(pagefrom, loadslicedata1.length);

        this.setState(pre => ({
            loadData: loadslicedata,
            paginations: {
                ...pre.paginations,
                paginationcountdata: sampleDataToLoad.length
            }
        })
        )
    }

    getAcademicYear = async () => {
        var academicYear = [{ label: "Academic Year", value: 0 }]
        var responseData = await doGetConnect("academic-year")
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data)) {
                var academicYearData = responseData.data
                var date = new Date();
                var year = date.getFullYear();
                var yearSet = false;
                Object.keys(academicYearData).forEach(key => {
                    if (year === academicYearData[key].year) {
                        yearSet = true
                    }
                    academicYear.push({ label: academicYearData[key].year, value: academicYearData[key].year })
                })
                this.setState({ academicYear })
                if (yearSet) {
                    this.setState({ year: year })
                }
                this.getDraftData();
            }
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    tableHandleChange = (val, key, index, loadDataval) => {
        // if (!(/^[0-9]*$/gmi.test(val))) {
        //     return
        // }
        var { loadData, tableData } = this.state;
        loadData[key][index] = val;
        if (loadDataval) {
            const resetobj = { ...loadDataval, [index]: val }
            tableData[loadDataval.id] = resetobj;
        }
        this.setState({ loadData, tableData })
    }

    handleFiles = async (files) => {
        if (checkNullAndReturnString(this.state.year) && this.state.year !== 0) {
            var that = this;
            var { tableData } = that.state;
            let playersMap = new Map();
            await tableData.map((val, index) => {
                playersMap.set(`person_id_${checkNullAndReturnString(val.person_id) ? val.person_id : val.player_person_id}`, index);
                return val;
            });
            var reader = new FileReader();
            reader.onload = function (e) {
                var allRows = reader.result.split(/\r?\n|\r/);
                for (var singleRow = 0; singleRow < allRows.length; singleRow++) {
                    if (Number(singleRow) === 0) {
                    }
                    else {
                        var splitedData = allRows[singleRow].split(",");
                        // var playerName = splitedData[0];
                        // var player_person_id = splitedData[1]
                        // var position = splitedData[2];
                        // var playerClass = splitedData[3];
                        if (checkNullAndReturnString(tableData)) {
                            const index = playersMap.get(`person_id_${splitedData[1]}`);
                            if (checkNullAndReturnString(index)) {
                                tableData[index].fourty_yard_dash = splitedData[4]
                                tableData[index].twenty_yard_shuttle = splitedData[5]
                                // tableData[index].sixty_yard_shuttle = splitedData[6]
                                tableData[index].three_core_drill = splitedData[6]
                                tableData[index].broad_jump = splitedData[7]
                                tableData[index].vertical_jump = splitedData[8]
                                tableData[index].bench_press = splitedData[9]
                            }
                            that.setState({ tableData, fileName: files[0].name })
                            that.sampledatacreator()
                        }
                    }
                }
            }
            reader.readAsText(files[0]);
        }
        else {
            toast.error("Please select academic year");
        }
    }

    saveDraft = async () => {
        var { year, tableData } = this.state;
        if (year !== 0) {
            if (checkNullAndReturnString(tableData)) {
                // var playerDetails = [];
                // Object.keys(tableData).forEach(key=>{
                //     var item = tableData[key]
                //     playerDetails.push({
                //         id: 0,
                //         team: item.team,
                //         personId : item.personId,
                //         fullName: item.first_name+" "+item.last_name,
                //         Class: item.class,
                //         position: item.position,
                //         fourtyYardDash: item.fourty_yard_dash,
                //         twentyYardShuttle: item.twenty_yard_shuttle,
                //         sixtyYardShuttle: item.sixty_yard_shuttle,
                //         threeCoreDrill: item.three_core_drill,
                //         broadJump: item.broad_jump,
                //         verticalJump: item.vertical_jump,
                //         benchPress: item.bench_press
                //     })
                // })
                var userDetail = JSON.parse(localStorage.getItem("userDetail"));
                var school_id = userDetail?.school_id;
                if (this.props.from === 'admin') {
                    school_id = this.props.id
                }
                var postJson = {
                    performanceData: tableData,
                    academicYear: year,
                    school_id: school_id
                }
                var accessToken = localStorage.getItem("accessToken");
                var responseData = await doPostConnectSecurity("player-performance/draft", postJson, accessToken)
                if (checkNullAndReturnString(responseData)) {
                    if (responseData.message === "Success") {
                        this.getDraftData()
                        toast.success("Workout performance data has been saved successfully");
                    }
                    else {
                        if (checkNullAndReturnString(responseData.error)) {
                            if (checkNullAndReturnString(responseData.error.message)) {
                                toast.error(responseData.error.message);
                            }
                        }
                    }
                }
            }
        }
        else {
            toast.error("Please select academic year")
        }
    }

    cancelDraft = async () => {
        var accessToken = localStorage.getItem("accessToken");
        var userDetail = JSON.parse(localStorage.getItem("userDetail"));
        var school_id = userDetail?.school_id;
        if (this.props.from === 'admin') {
            school_id = this.props.id
        }
        var responseData = await doDeleteConnect("player-performance/draft/" + this.state.draftId + '/' + school_id, accessToken)
        if (checkNullAndReturnString(responseData)) {
            this.setState({
                year: 0,
                importData: false,
                tableData: [],
                fileName: "",
                draftId: "",
                draftData: false
            })
            this.getWorkoutPerformance(this.state.year);
        }
    }


    exportTemplate = async () => {
        try {
            const url = `${Config.dataUrl}player-performance/getPerformanceTemplate`;
            const aelem = document.createElement('a');
            aelem.href = url;
            aelem.download = 'template.xlsx';
            aelem.click()
        } catch (err) {
            toast.error(err.message ? err.message : 'Error while getting template excel');
        }
    }

    submitFunction = async () => {
        var { year, tableData } = this.state;

        if (year !== 0) {
            if (checkNullAndReturnString(tableData)) {
                var schoolId = localStorage.getItem("schoolId");
                var playerDetails = [];
                Object.keys(tableData).forEach(key => {
                    var item = tableData[key]
                    playerDetails.push({
                        id: 0,
                        team: item.team,
                        schoolId: schoolId,
                        personId: checkNullAndReturnString(item.player_person_id) ? item.player_person_id : item.person_id,
                        fullName: item.full_name,
                        Class: item.class,
                        position: item.position,
                        fourtyYardDash: item.fourty_yard_dash,
                        twentyYardShuttle: item.twenty_yard_shuttle,
                        // sixtyYardShuttle: item.sixty_yard_shuttle,
                        threeCoreDrill: item.three_core_drill,
                        broadJump: item.broad_jump,
                        verticalJump: item.vertical_jump,
                        benchPress: item.bench_press
                    })
                })
                var userDetail = JSON.parse(localStorage.getItem("userDetail"));
                var school_id = userDetail?.school_id;
                if (this.props.from === 'admin') {
                    school_id = this.props.id
                }
                var postJson = {
                    playerDetails: playerDetails,
                    academicYear: year,
                    school_id: school_id
                }
                var accessToken = localStorage.getItem("accessToken");
                var responseData = await doPostConnectSecurity("player-performance", postJson, accessToken)
                if (checkNullAndReturnString(responseData)) {
                    if (responseData.message === "Success") {
                        this.setState({ draftData: false, draftId: "" })
                        toast.success("Workout performance data has been saved successfully");
                    }
                    else {
                        if (checkNullAndReturnString(responseData.error)) {
                            if (checkNullAndReturnString(responseData.error.message)) {
                                toast.error(responseData.error.message);
                            }
                        }
                    }
                }
            }
        }
        else {
            toast.error("Please select academic year")
        }
    }


    handleChangePage = async (event, newPage) => {
        const paginationvalue = { ...this.state.paginations, paginatonpageindex: newPage };
        this.setState({
            paginations: { ...paginationvalue }
        }, this.sampledatacreator)
    }

    handleChangeRowsPerPage = async (event) => {
        const paginationvalue = { ...this.state.paginations, paginationrowsPerPage: event.target.value, paginatonpageindex: 0 };
        this.setState({
            paginations: { ...paginationvalue }
        }, this.sampledatacreator);
    }


    render() {
        var { classes } = this.props;
        var { rejectedTotal, approveTotal, total, height, academicYear, year, loadData, fileName,
            paginations: {
                paginatonpageindex,
                paginationrowsPerPage,
                paginationcountdata
            }
        } = this.state;
        var tableInputStyle = {
            background: "transparent",
            font: "normal normal normal 14px/21px Poppins",
            height: 32,
            color: "#fff",
            border: "0.5px solid rgb(75 76 92 / 60%)",
            width: 80,
            paddingLeft: 13,
            paddingRight: 13,
            outline: "none",
            "&:hover": {
                outline: "none"
            },
            "&:focus": {
                outline: "none"
            },
            borderRadius: 5
        }
        var ExportColumns = [
            {title: "Player"}, 
            {title: "Person id"},
            {title: "Pos"},
            {title: "Class"},
            {title: "40 yd dash (sec)"},
            {title: "Shuttle (sec)"},
            // {title: "60 yd shuttle (sec)"},
            {title: "3 Cone Drill (sec)"},
            {title: "Broad jump (in)"},
            {title: "Vertical jump (in)"},
            {title: "Bench reps (reps)"},
        ];

        var ExportRows = [];       

        var rows = []
        if (checkNullAndReturnString(loadData)) {
            Object.keys(loadData).forEach(key => {                
                var item = loadData[key];
                ExportRows.push([
                    {value: item.full_name},
                    {value: checkNullAndReturnString(item.player_person_id) ? item.player_person_id : item.person_id},
                    {value: item.position},
                    {value: item.class},
                    {value: ""},
                    {value: ""},
                    // {value: ""},
                    {value: ""},
                    {value: ""},
                    {value: ""},
                    {value: ""},
                ]);
                rows.push({
                    player: item.full_name,
                    personId: checkNullAndReturnString(item.player_person_id) ? item.player_person_id : item.person_id,
                    position: item.position,
                    class: item.class,
                    height: <span>
                        {checkNullAndReturnString(item.height_feet) ? item.height_feet + " Ft. " : "0 Ft. "}
                        {checkNullAndReturnString(item.height_inches) ? item.height_inches + " In" : "0 In"}
                    </span>,
                    weight: checkNullAndReturnString(item.weight) ? item.weight + " Lbs" : "0 Lbs",
                    enrolled: item.user_status ? <span style={{ padding: 10, paddingTop: 3, paddingBottom: 3, borderRadius: 5, background: "#58BE52" }}>Yes</span> : <span style={{ padding: 10, paddingTop: 3, paddingBottom: 3, borderRadius: 5, background: "#D51212" }}>No</span>,
                    fourty_yard_dash: <input type={"text"}
                        value={item.fourty_yard_dash}
                        style={tableInputStyle}
                        onChange={(e) => this.tableHandleChange(e.target.value, key, "fourty_yard_dash", loadData[key])}
                    />,
                    twenty_yard_shuttle: <input type={"text"}
                        value={item.twenty_yard_shuttle}
                        style={tableInputStyle}
                        onChange={(e) => this.tableHandleChange(e.target.value, key, "twenty_yard_shuttle", loadData[key])}
                    />,
                    three_core_drill: <input type={"text"}
                        value={item.three_core_drill}
                        style={tableInputStyle}
                        onChange={(e) => this.tableHandleChange(e.target.value, key, "three_core_drill", loadData[key])}
                    />,
                    broad_jump: <input type={"text"}
                        value={item.broad_jump}
                        style={tableInputStyle}
                        onChange={(e) => this.tableHandleChange(e.target.value, key, "broad_jump", loadData[key])}
                    />,
                    vertical_jump: <input type={"text"}
                        value={item.vertical_jump}
                        style={tableInputStyle}
                        onChange={(e) => this.tableHandleChange(e.target.value, key, "vertical_jump", loadData[key])}
                    />,
                    bench_press: <input type={"text"}
                        value={item.bench_press}
                        style={tableInputStyle}
                        onChange={(e) => this.tableHandleChange(e.target.value, key, "bench_press", loadData[key])}
                    />,
                })
            })
        }
        return (
            <React.Fragment>
                <div className={"row rowView"}>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className={"row"} >
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20, alignSelf: "center" }}>
                                <span className={classes.studentCountText} style={{color: "#959595"}}>
                                    {total} Students Listed | {approveTotal} Students Enrolled | {rejectedTotal} Students Not Enrolled
                                </span>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20, alignSelf: "center" }}>
                                <SelectBox fullWidth={true} backgroundColor={"rgb(75 76 92 / 60%)"} onChange={this.handleChange} keyName={"year"} options={academicYear} value={year} />
                            </div>
                            {
                                !this.state.draftData ?
                                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20, textAlign: "center", alignSelf: "center" }}>
                                        <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'} >
                                            <Button buttonStyle={classes.buttonStyle1} buttonName={"BROWSE"} fullWidth={false} onClick={() => console.log("file choosen")} />
                                        </ReactFileReader>
                                        &nbsp;&nbsp;&nbsp;
                                        {
                                            !this.state.draftData ?
                                                <span className={classes.fileName}>
                                                    &nbsp;&nbsp;&nbsp;
                                                    {
                                                        checkNullAndReturnString(fileName) ?
                                                            fileName
                                                            :
                                                            ""
                                                    }
                                                </span>
                                                :
                                                ""
                                        }
                                    </div>
                                    :
                                    ""
                            }
                            {
                                this.state.draftData ?
                                    <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20, textAlign: "center", alignSelf: "center" }}>
                                        <Button buttonStyle={classes.buttonStyle1} buttonName={"Cancel"} fullWidth={false} onClick={() => this.cancelDraft()} />
                                    </div>
                                    :
                                    ""
                            }
                            <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12" style={{ display: 'flex', paddingTop: 20, textAlign: "center", alignSelf: "center" }}>
                                {
                                    this.props.from !== 'admin' ? (
<>
<Button buttonStyle={classes.buttonStyle1} buttonName={"Save Draft"} fullWidth={false} onClick={() => this.saveDraft()} />
                                &nbsp;&nbsp;&nbsp;</>
                                    ) : ''
                                    }
                                <Button buttonStyle={classes.buttonStyle1} buttonName={"Submit"} fullWidth={false} onClick={() => this.submitFunction()} />
                                &nbsp;&nbsp;&nbsp;
                                <Button buttonStyle={classes.buttonStyle1} buttonName={"Export"}
                                    fullWidth={false} onClick={() => this.exportPDFData()} />
                                &nbsp;&nbsp;&nbsp;
                                <ExcelFile element={<Button buttonStyle={classes.buttonStyle1} buttonName={"Export Template"}
                                    fullWidth={false} />}>
                                    <ExcelSheet dataSet={[{columns: ExportColumns, data: ExportRows.length ? ExportRows : [[
                    {value: ""},
                    {value: ""},
                    {value: ""},
                    // {value: ""},
                    {value: ""},
                    {value: ""},
                    {value: ""},
                    {value: ""},
                    {value: ""},
                    {value: ""},
                    {value: ""},
                ]]}]} name="Drill Performance"/>
                                </ExcelFile>                                                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row rowView"}>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        {/* <div className={"row"}>
                            <div class="col-xl-4 col-lg-5 col-md-5 col-sm-5 col-12" style={{ paddingTop: 20, alignSelf: "center" }}>
                                <span className={classes.studentCountText}>
                                    {total} Students Listed | {approveTotal} Students Enrolled | {rejectedTotal} Students Not Enrolled
                                </span>
                            </div>
                            {
                                !this.state.draftData ?
                                    <div class="col-xl-3 col-lg-4 col-md-7 col-sm-7 col-12" style={{ paddingTop: 20 }}>
                                        <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'} >
                                            <Button buttonStyle={classes.buttonStyle1} buttonName={"BROWSE"} fullWidth={false} onClick={()=> console.log("file choosen")}/>
                                        </ReactFileReader>
                                        <span className={classes.fileName}>
                                        &nbsp;&nbsp;&nbsp;
                                            {
                                                checkNullAndReturnString(fileName) ?
                                                    fileName
                                                :
                                                ""
                                            }
                                        </span>
                                    </div>
                                    :
                                    ""
                            }
                            <div class="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                {
                                    this.state.draftData ?
                                        <Button buttonStyle={classes.buttonStyle1} buttonName={"Cancel"} fullWidth={false} onClick={()=> this.cancelDraft()}/>
                                    :
                                    ""
                                }
                                {
                                    this.state.draftData ?
                                        <span>&nbsp;&nbsp;</span>
                                    :
                                    ""
                                }
                                <Button buttonStyle={classes.buttonStyle1} buttonName={"Save Draft"} fullWidth={false} onClick={()=> this.saveDraft()}/>
                                &nbsp;&nbsp;
                                <Button buttonStyle={classes.buttonStyle} buttonName={"Submit"} fullWidth={false} onClick={()=> this.submitFunction()}/>
                                &nbsp;&nbsp;
                                <Button buttonStyle={classes.buttonStyle} buttonName={"Export"} fullWidth={false} onClick={()=> this.exportPDFData()}/>
                            </div>
                        </div>                          */}
                        <div className={"row"}>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <Tables fixed={true} extraStyle={{ height: height / 1.6 }} thJson={["Player", "Person ID", "POS", "CLS", "HT", "WT", "Enrolled", "40 YD Dash (sec)", "20 YD Shuttle (sec)", "3 Cone Drill (sec)", "Broad Jump (in)", "Verticle Jump (in)", "225 Bench Reps (reps)"]} tdJson={rows} />
                            </div>
                        </div>
                        <div>
                            <TablePagination
                                style={{ color: 'white' }}
                                component="div"
                                count={paginationcountdata}
                                page={paginatonpageindex}
                                onChangePage={this.handleChangePage}
                                rowsPerPage={paginationrowsPerPage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                rowsPerPageOptions={[50, 100, 150, 200]}
                            />

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(UploadWorkoutPerformance));
