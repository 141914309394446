import { Container, CssBaseline, withStyles } from "@material-ui/core";
import { Component } from "react";
import { withStore } from "../../component/hooks/store";
import Styles from "../../styles/agentDisclaimer.js";
import BackArrow from "../../images/backArrow.png";
import NxtLogo from "../../images/nxtpick-logo-header.png";
import Footer from "./footer.js";
import Input from "../../component/input";
import { Link } from "react-router-dom";
import Button from "../../component/button";
import { checkNullAndReturnString, doPostConnect } from "../../helper/common";
import { toast } from "react-toastify";
class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  emptyData = async () => {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      comment: "",
    });
  };

  handleChange = (id, value) => {
    this.setState({ [id]: value ? value : "" });
  };

  submitFunction = async () => {
    let { firstName, lastName, email, phone, comment } = this.state;

    await this.validateForm();

    const data = { firstName, lastName, email, phone, comment };

    let responseData = await doPostConnect("users/contact-us", data);
    if (checkNullAndReturnString(responseData)) {
      if (
        checkNullAndReturnString(responseData.message) &&
        responseData.message === "Success"
      ) {
        this.emptyData();
      }
    }
  };

  validateForm = async () => {
    let { firstName, email, comment } = this.state;
    if (!checkNullAndReturnString(firstName)) {
      toast.error("Please enter first name");
      return false;
    }

    if (!checkNullAndReturnString(email)) {
      toast.error("Please enter email");
      return false;
    }

    if (!checkNullAndReturnString(comment)) {
      toast.error("Please enter comment or message");
      return false;
    }
  };

  render() {
    const { classes } = this.props;
    const { firstName, lastName, email, phone, comment } = this.state;
    return (
      <div className={classes.sectionStyle}>
        <nav
          class="navbar navbar-expand-md fixed-top"
          style={{
            borderBottom: "1px solid #3B3E50",
            background: "#262834",
          }}
        >
          <Link to={"/"} variant="button" className={"navbar-brand"} style={{ paddingLeft: 20 }}>
            <img
              src={NxtLogo}
              alt="logopng"
              style={{ width: 210, height: 22 }}
            />
          </Link>
        </nav>
        <Container
          component="main"
          maxWidth="xl"
          style={{ position: "relative", zIndex: 5 }}
        >
          <CssBaseline />
          <div className={classes.paper1}>
            <a
              className={"col-md-12"}
              href="#/"
              onClick={(e) => {
                e.preventDefault();
                this.props.history.goBack();
              }}
              style={{ color: "inherit" }}
            >
              <img src={BackArrow} style={{ width: 31, height: 31 }} alt="" />
              <span
                href="#"
                style={{
                  font: "normal normal normal 12px/18px Poppins",
                  color: "#959595",
                  paddingLeft: 10,
                  opacity: 1,
                }}
                className={"commonBackClass"}
              >
                Back
              </span>
            </a>
          </div>
          <div className={"row"} style={{ paddingBottom: 80 }}>
            <div
              class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8"
              style={{ padding: 0, paddingLeft: 15, paddingRight: 15 }}
            >
              <div className={"row"} style={{ justifyContent: "center" }}>
                <span style={{ color: "white", fontSize:"32px" }}>Contact</span>
              </div>
                        <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <span className={classes.label}> First Name <span style={{ color: "#1891BD" }}>*</span> </span>
              <div style={{ paddingTop: 10 }}></div>
              <Input from={"signUp"} keyName={"firstName"} value={firstName} onChange={this.handleChange} />
          </div>
                                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                      <span className={classes.label}> Last Name  </span>
                                      <div style={{ paddingTop: 10 }}></div>
                                      <Input from={"signUp"} keyName={"lastName"} value={lastName} onChange={this.handleChange} />
                                  </div>
                              
                        </div>
                        
                        <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                      <span className={classes.label}> Phone  </span>
                                      <div style={{ paddingTop: 10 }}></div>
                                      <Input from={"signUp"} keyName={"phone"} value={phone} onChange={this.handleChange} />
                                  </div>
                                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                      <span className={classes.label}> Email <span style={{ color: "#1891BD" }}>*</span> </span>
                                      <div style={{ paddingTop: 10 }}></div>
                                      <Input from={"signUp"} keyName={"email"} value={email} onChange={this.handleChange} />
                                  </div>
                              </div>

                              <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                      <span className={classes.label}> Comment or Message <span style={{ color: "#1891BD" }}>*</span> </span>
                                      <div style={{ paddingTop: 10 }}></div>
                                      <textarea className = {classes.textAreaStyle} style = {{minWidth: "100%", minHeight: "100%"}} from={"signUp"} keyName={"comment"} value={comment} onChange={(e) => this.setState({ comment: e.target.value })} />
                                  </div>
                              </div>

                              <div class="row signUpView" style={{ paddingTop: "2.5rem" }}>
                                                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                      <Button fullWidth={true} buttonStyle={classes.buttonStyle} buttonName={"Submit"} onClick={() => this.submitFunction()} />
                                                          </div>
                        </div>
                        
            </div>
                    
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4"
              style={{ padding: 0, paddingLeft: 15, paddingRight: 15 }}
            >
                        <div className={"row"} style={{ justifyContent: "center" }}>
                            <div style={{ color: "white", fontSize: "32px" }}>OUR OFFICE</div>
                            {/* <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ justifyContent: "center" , paddingTop: "20px" }}>
                                <p style={{ color: "white", fontSize: "22px"}}>NXTPICK</p>
                            </div> */}
                        </div>
                        <div className={"row"} style={{ justifyContent: "center" }}>
                            <p style={{ color: "white", fontSize: "20px"}}>NXTPICK</p>
                        </div>
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    );
  }

  //     const { classes } = this.props;
  //     const { firstName,lastName,email,phone,comment} = this.state
  //     return (
  //         <div className={classes.sectionStyle}>
  // <nav
  //     class="navbar navbar-expand-md fixed-top"
  //     style={{
  //         borderBottom: "1px solid #3B3E50",
  //         background: "#262834"
  //     }}
  // >
  //     <a
  //         href="#/"
  //         className="navbar-brand"
  //         style={{ paddingLeft: 20 }}
  //         onClick={e => e.preventDefault()}
  //     >
  //         <img
  //             src={NxtLogo}
  //             alt="logopng"
  //             style={{ width: 210, height: 22 }}
  //         />
  //     </a>
  // </nav>

  //             <Container component="main" maxWidth="xl" style={{ position: "relative", zIndex: 5 }}>
  //                 <CssBaseline />
  //                 <div className={classes.paper1}>
  // <a className={"col-md-12"} href="#/" onClick={e => { e.preventDefault(); this.props.history.goBack(); }} style={{ color: "inherit" }}>
  // <img src={BackArrow} style={{ width: 31, height: 31 }} alt="" />
  //     <span
  //         href="#"
  //         style={{
  //             font: "normal normal normal 12px/18px Poppins",
  //             color: "#959595",
  //             paddingLeft: 10,
  //             opacity: 1,
  //         }}
  //         className={"commonBackClass"}
  //     >
  //         Back
  //     </span>
  // </a>

  //                     <Grid item xs={12} sm={8} md={8} elevation={6} square style={{ height: 0, overflowX: "hidden", }}>
  //                         <div className={classes.paper}>
  //                             <span className ={classes.createAccountText}>Contact</span>
  //                         </div>
  //                         <div>
  // <div class={"row signUpView"} style={{ paddingTop: 20 }}>
        //   <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        //       <span className={classes.label}> First Name <span style={{ color: "#1891BD" }}>*</span> </span>
        //       <div style={{ paddingTop: 10 }}></div>
        //       <Input from={"signUp"} keyName={"firstName"} value={firstName} onChange={this.handleChange} />
        //   </div>
        //                           <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
        //                               <span className={classes.label}> Last Name  </span>
        //                               <div style={{ paddingTop: 10 }}></div>
        //                               <Input from={"signUp"} keyName={"lastName"} value={lastName} onChange={this.handleChange} />
        //                           </div>
        //                       </div>

                            //   <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                            //       <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            //           <span className={classes.label}> Phone  </span>
                            //           <div style={{ paddingTop: 10 }}></div>
                            //           <Input from={"signUp"} keyName={"phone"} value={phone} onChange={this.handleChange} />
                            //       </div>
                            //       <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            //           <span className={classes.label}> email <span style={{ color: "#1891BD" }}>*</span> </span>
                            //           <div style={{ paddingTop: 10 }}></div>
                            //           <Input from={"signUp"} keyName={"email"} value={email} onChange={this.handleChange} />
                            //       </div>
                            //   </div>

                            //   <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                            //   <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            //           <span className={classes.label}> Comment or Message <span style={{ color: "#1891BD" }}>*</span> </span>
                            //           <div style={{ paddingTop: 10 }}></div>
                            //           <textarea from={"signUp"} keyName={"comment"} value={comment} onChange={(e) => this.setState({ comment: e.target.value })} />
                            //       </div>
                            //   </div>

                            //   <div class="row signUpView" style={{ paddingTop: 5 }}>
                            //                               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            //           <Button fullWidth={true} buttonStyle={classes.buttonStyle} buttonName={"Submit"} onClick={() => this.submitFunction()} />
                            //                               </div>
                            //                           </div>
  //                         </div>
  //                     </Grid>

  //                     <Grid item xs={false} sm={4} md={4} className={classes.leftCard} >
  //                         <div className={"h-100 d-flex justify-content-center align-items-center"}>OUR OFFICE</div>
  //                     </Grid>

  //                 </div>

  //             </Container>
  //         </div>

  //     )
  // }
}
export default withStyles(Styles, { withTheme: true })(withStore(Support));
