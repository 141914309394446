import React, { Component } from 'react'
import { checkNullAndReturnString } from '../helper/common';

class index extends Component {
    render() {
        var { selected, json, tabOnClick } = this.props;
        var content = [];
        if (checkNullAndReturnString(json)) {
            Object.keys(json).forEach(key => {
                content.push(
                    <li className={selected === key ? 'leftSelected nav-item' : 'nav-item'} style={{ textAlign: "end" }} onClick={() => tabOnClick(key)}>
                        {json[key]}
                    </li>
                )
            })
        }
        return (
            <ul className="flex-column" style={{ padding: 0 }}>
                {content}
            </ul>
        )
    }
}
export default index;