import React, { Component } from "react";
import VideoCamerImage from "../images/video.png";
import GalleryImage from "../images/gallery.png";
import Config from "../helper/config";
import { positionAverageData, positionAverageData1 } from "../helper/common";
import ProgressBar from "./progressBar";
// import CancelIcon from '@material-ui/icons/Cancel';
// import { UserConsumer } from '../views/agent/agentPlayerSearch';

class index extends Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    const {
      first_name = "",
      last_name = "",
      total_images = "0",
      total_videos = "0",
      // overall_percentage = 0,
      weight = 0,
      height_feet = "",
      height_inches = "",
      player_position = "",
      age,
      bench_press,
      vertical_jump,
      three_core_drill,
      broad_jump,
      // sixty_yard_shuttle,
      twenty_yard_shuttle,
      fourty_yard_dash,
      bench_press_target,
      vertical_jump_target,
      three_cone_target,
      broad_jump_target,
      // sixty_yard_shuttle_target,
      twenty_yard_shuttle_target,
      fourty_yard_dash_target,
      profile_image_hash = "",
    } = this.props.userDetail;

    var benchPress = positionAverageData(bench_press,bench_press_target);
    var verticalJump = positionAverageData(vertical_jump,vertical_jump_target);
    var threeCoreDrill = positionAverageData1(three_core_drill,three_cone_target);
    var broadJump = positionAverageData(broad_jump,broad_jump_target);
    // var sixtyYardShuttle = positionAverageData1(sixty_yard_shuttle,sixty_yard_shuttle_target);
    var twentyYardShuttle = positionAverageData1(twenty_yard_shuttle,twenty_yard_shuttle_target);
    var fourtyYardDash = positionAverageData1(fourty_yard_dash,fourty_yard_dash_target);

    var overallCount =
      benchPress +
      verticalJump +
      threeCoreDrill +
      broadJump +
      // sixtyYardShuttle +
      twentyYardShuttle +
      fourtyYardDash;

    var oveallPercentage = 0;
    if (overallCount !== "Infinity") {
      oveallPercentage = parseFloat(overallCount / 6).toFixed(2);
    }

    // var background = [
    //   "transparent linear-gradient(90deg, #262834 0%, #26D276 100%) 0% 0% no-repeat padding-box",
    //   "transparent linear-gradient(90deg, #262834 0%, #9C51E6 100%) 0% 0% no-repeat padding-box",
    //   "transparent linear-gradient(90deg, #262834 0%, #C6E63E 100%) 0% 0% no-repeat padding-box",
    //   "transparent linear-gradient(90deg, #262834 0%, #E63E3E 100%) 0% 0% no-repeat padding-box",
    // ];
    var smallText = {
      font: "normal normal normal 10px/16px Poppins",
      color: "#959595",
      opacity: 1,
    };
    var boldText = {
      font: "normal normal normal 12px/18px Poppins",
      color: "#FFFFFF",
      opacity: 1,
    };
    // var compareText = {
    //   font: "normal normal normal 12px/18px Poppins",
    //   color: "#1891BD",
    //   opacity: 1,
    //   float: "right",
    // };
    var imageStyle = {
      width: 40,
      height: 40,
      borderRadius: 8,
    };
    var numberText = {
      font: "normal normal normal 8px/12px Poppins",
      color: "#959595",
      opacity: 1,
      float: "right",
      padding: 3,
      background: "#262834 0% 0% no-repeat padding-box",
      borderRadius: 8,
    };

    return (
      <div
        style={{
          width: "350px",
          height: "125px",
          background: "0% 0% no-repeat padding-box padding-box rgb(59, 62, 80)",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px",
          borderRadius: "10px",
          border: "1px solid rgb(59, 62, 80)",
          opacity: 1,
          cursor: "pointer",
          position: "relative",
          margin: "17px 9px 9px 10px ",
          "&:hover": {
            background: "red",
          },
        }}
      >
        <span
          style={{ right: "-7px", position: "absolute", top: "-7px" }}
          onClick={() => this.props.clearUsers(this.props.userDetail)}
        >
          {/* <CancelIcon /> */}
          <span
            style={{
              border: "1px solid lightgrey",
              borderRadius: "50%",
              color: "rgb(255, 255, 255)",
              width: "25px",
              height: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "12px",
              fontWeight: "500",
              cursor: "pointer",
            }}
          >
            x
          </span>
        </span>
        <div className="row" style={{ padding: 10 }}>
          <div className="col-2" style={{ justifyContent: "center" }}>
            {profile_image_hash ? (
              <img
                style={imageStyle}
                alt=""
                src={`${Config.dataUrl}aws-files/${profile_image_hash}`}
                onError={(e) => this.setEmptyUser(e)}
              />
            ) : (
              <img src={Config.emptyUser} alt="" style={imageStyle} />
            )}
          </div>
          <div className="col-5">
            <span style={boldText}>{first_name + " " + last_name}</span>
            <br />
            <span style={smallText}>{player_position}</span>
            <br />
            <div style={{ display: "inline-flex", paddingTop: 5 }}>
              <span style={numberText}>
                <span>
                  <img
                    src={VideoCamerImage}
                    alt=""
                    style={{
                      width: "14px",
                      height: "13px",
                      padding: "2px 0px 0px 2px",
                    }}
                  />
                </span>
                &nbsp; {total_videos} &nbsp;{" "}
              </span>
              &nbsp;&nbsp;
              <span style={numberText}>
                {" "}
                <img
                  src={GalleryImage}
                  alt=""
                  style={{
                    width: "14px",
                    height: "13px",
                    padding: "2px 0px 0px 2px",
                  }}
                />
                &nbsp; {total_images} &nbsp;{" "}
              </span>
            </div>
          </div>
          <div className="col-2">
            <div>
              <div class="flexcolumn">
                <div style={boldText}>
                  Rating
                  <br></br>
                  {oveallPercentage ? oveallPercentage : "0"}%
                </div>
                <div style={boldText}>
                  Weight
                  <br></br>
                  {weight}Lbs
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div>
              <div class="flexcolumn">
                <div style={boldText}>
                  Height
                  <br></br>
                  {height_feet + "' " + height_inches}
                </div>
                <div style={boldText}>
                  Age
                  <br></br>
                  {age && age.years ? age.years : 0} Yrs
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ position: "relative", bottom: "-3px", zIndex: 0 }}>
          <ProgressBar
            width={`${oveallPercentage}`}
            mainBackground={"#262834 0% 0% no-repeat padding-box"}
          />
        </div>
      </div>
    );
  }
}
export default index;
