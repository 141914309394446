import React, { Component } from "react";
import { withStore } from "../../component/hooks/store";
import { withStyles } from "@material-ui/core/styles";
import Styles from "../../styles/player/dashboard";
// import Tooltip from "@material-ui/core/Tooltip";

class PlayerProgressBar extends Component {
  render() {
    var {
      width,
      addWidth,
      //  toolTipName,
      name,
    } = this.props;
    if (!addWidth || !Number(addWidth)) {
      addWidth = 0;
    } else if (Number(addWidth)) {
      addWidth = Number(addWidth);
    }
    var background =
      "transparent linear-gradient(90deg, #3B3E50 0%, #ff0000 100%) 0% 0% no-repeat padding-box";
    if (width < 69) {
      background =
        "transparent linear-gradient(90deg, #3B3E50 0%, #ff0000 100%) 0% 0% no-repeat padding-box";
    } else if (width < 79) {
      background =
        "transparent linear-gradient(90deg, #3B3E50 0%, #ffc000 100%) 0% 0% no-repeat padding-box";
    } else if (width < 89) {
      background =
        "transparent linear-gradient(90deg, #3B3E50 0%, #ffff00 100%) 0% 0% no-repeat padding-box";
    } else if (width < 100) {
      background =
        "transparent linear-gradient(90deg, #3B3E50 0%, #a8d08d 100%) 0% 0% no-repeat padding-box";
    } else {
      background =
        "transparent linear-gradient(90deg, #3B3E50 0%, #5b9bd5 100%) 0% 0% no-repeat padding-box";
    }

    if (addWidth > 99) {
      addWidth = 99;
    }

    return (
      <div
        class="progress"
        style={{
          width: "100%",
          height: 15,
          background: "#3B3E50 0% 0% no-repeat padding-box",
        }}
      >
        <div
          class="progress-bar"
          style={{
            width: width + "%",
            background: background,
            borderRadius: 10,
          }}
        >
          {/* <Tooltip title={`${toolTipName}`} placement="top"> */}
          {/* <span
            style={{
              position: "absolute",
              left: `${addWidth}%`,
              height: 15,
              width: 3,
              backgroundColor: "#fff",
              borderRadius: 10,
            }}
          ></span> */}
          {/* </Tooltip> */}
          <span
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              color: "#fff",
              transform: "translate(-50%, -50%)",
              display: "flex",
              borderRadius: 10,
            }}
          >
            {name ? name : ""}
          </span>
        </div>
      </div>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(
  withStore(PlayerProgressBar)
);
