import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withStore } from "../../component/hooks/store";
import { CssBaseline, Container } from "@material-ui/core";
import Styles from "../../styles/agentDisclaimer.js";
import BackArrow from "../../images/backArrow.png";
import NxtLogo from "../../images/nxtpick-logo-header.png";
import Footer from "./footer.js";
import  coopProfile from "../../images/coopProfile.jpg"
import { Link } from "react-router-dom";
// import  wardellProfile from "../../images/wardellProfile.jpg"
// import  paulProfile from "../../images/paulProfile.jpg"
// import  lanceProfile from "../../images/lanceProfile.jpg"
// import Config from "../../helper/config";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sectionStyle}>
        <nav
          class="navbar navbar-expand-md fixed-top"
          style={{
            borderBottom: "1px solid #3B3E50",
            background: "#262834"
          }}
        >
          <Link to={"/"} variant="button" className={"navbar-brand"} style={{ paddingLeft: 20 }}>
            <img
              src={NxtLogo}
              alt="logopng"
              style={{ width: 210, height: 22 }}
            />
          </Link>
        </nav>

        <Container
          component="main"
          maxWidth="xl"
          style={{ position: "relative", zIndex: 5 }}
        >
          <CssBaseline />
          <div className={classes.paper1}>
            <a
              className={"col-md-12"}
              href="#/"
              onClick={e => {
                e.preventDefault();
                this.props.history.goBack();
              }}
              style={{ color: "inherit" }}
            >
              <img src={BackArrow} style={{ width: 31, height: 31 }} alt="" />
              <span
                href="#"
                style={{
                  font: "normal normal normal 12px/18px Poppins",
                  color: "#959595",
                  paddingLeft: 10,
                  opacity: 1,
                }}
                className={"commonBackClass"}
              >
                Back
              </span>
            </a>
            <form className={classes.form} style={{ paddingTop:0 ,margin:0}} noValidate>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ textAlign: "center" }}
                >
                  <span className={classes.disclaimerText} style={{fontSize:21}}>
                    About Us
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText} style={{fontSize:19}}>What We Do</span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                  NXTPICK offers an expansive database of amateur athlete and sports agent profiles that are created by users who are looking to network and connect with one another to further their career in their respective sport. Players and agents can utilize our refinable search engine to comb through profiles that display the most important information needed to build a partnership. From game and workout video to stats and drill performance, NXTPICK delivers on everything needed to find the right player or agent for you!
                  </span>
                  
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText} style={{fontSize:19}}>Our Mission</span>
                </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    Our mission is to give power back to amateur athletes
                    looking to make the leap into the professional arena. By
                    streamlining the process of agent/player and player/agent
                    acquisitions, we instantly erase constraints of distance and
                    time on the part of sports agents while increasing the
                    likelihood of being viewed and contacted by said agents as a
                    player.
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 30 }}
                >
                  <span className={classes.disclaimerText} style={{fontSize:19}}>
                    Ron Cooper- Founder and Owner
                  </span>
                </div>
                <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 10 }}>
                    <img src={coopProfile} alt="Ron Cooper" style={{ width: "auto", height: 300 }} />
                  </div>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 10 }}
                >
                  <span className={classes.label}>
                    Ron is a former college athlete that played as a Free Safety
                    at Virginia Tech. He founded Gladiator Athletics LLC and
                    created NXTPICK with the intention of helping athletes that
                    experience hardships of searching for an agent, much like he
                    did. He has 10 years of Process Engineering, Project
                    Management, and sales experience. Ron’s vision is to see
                    players take control of their sports careers through
                    knowledge and access.{" "}
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.disclaimerText} style={{fontSize:19}}>
                    Paul Barbour- COO
                  </span>
                </div>
                {/* <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 10 }}>
                    <img src={paulProfile} alt="Ron Cooper" style={{ width: "auto", height: 300 }} />
                  </div> */}
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    Paul was drafted by the Cincinnati Reds and played minor
                    league baseball until he endured a career ending shoulder
                    injury. He went on to help establish 3N2, an athletic
                    apparel company where he was the head of Baseball Operations
                    and signed players such as Ricky Weeks to their team. Paul
                    brings over 15 years of consulting experience and earned a
                    Master’s Degree in Sports Management/Administration from
                    Liberty Univ.
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.disclaimerText} style={{fontSize:19}}>
                    Lance Barbour- CAO
                  </span>
                </div>
                {/* <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 10 }}>
                    <img src={lanceProfile} alt="Ron Cooper" style={{ width: "auto", height: 300 }} />
                  </div> */}
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    As a former Defensive Back at Virginia Tech, Lance
                    understands the world of amateur and professional sports
                    well. Lance continues to help shape NXTPICK in a way that
                    helps players and agents take full advantage of resources
                    available to them. With over 6 years of government lobbying
                    and consulting experience, Lance brings the know how needed
                    to solidify relationships and build the NXTPICK brand.
                  </span>
                </div>
              </div>
              <div className={"row"} ref={this.paneDidMount}>
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.disclaimerText} style={{fontSize:19}}>
                    Wardell Jones- CIO
                  </span>
                </div>
                {/* <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 10 }}>
                    <img src={wardellProfile} alt="Ron Cooper" style={{ width: "auto", height: 300 }} />
                  </div> */}
                <div
                  className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                  style={{ paddingTop: 20 }}
                >
                  <span className={classes.label}>
                    Wardell has been an integral part of all things related to
                    IT for NXTPICK. He possesses over 15 years of IT experience
                    with the Federal Government as a Director. As a graduate
                    from LSU and three children that played collegiate sports,
                    Wardell has a strong grasp of the ins and outs of college
                    sports. He has molded the backend work needed to bring
                    NXTPICK to life and continues to help our team build out our
                    awesome product.
                  </span>
                </div>
              </div>
            </form>
          </div>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(withStore(About));
