import LoginBg from "../images/login-bg.png";
const styles = theme => ({
    sectionStyle: {
        position: "absolute",
        top: 0,
        left: 0,
        overflow: "hidden",
        minWidth: "100%",
        minHeight: "100%",
        backgroundImage: "url(" + LoginBg + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    paper: {
        marginTop: 160,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        opacity: 1,
        width: 175,
        height: 81,
        // opacity: 1
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        textAlign: "center"
    },
    buttonStyle: {
        background: '#1891BD',
        borderRadius: 5,
        border: 0,
        color: 'white',
        height: 46,
        padding: '0 30px',
        opacity: 1
    },
    successText: {
        font: "normal normal normal 20px/30px Poppins",
        color: "#FFFFFF",
        opacity: 1
    },
    successDescription: {
        font: "normal normal normal 12px/24px Poppins",
        color: "#FFFFFF",
        opacity: 0.5,
    },
    backText: {
        font: "normal normal normal 14px/21px Poppins",
        color: "#FFFFFF",
        opacity: 1
    }
});
export default styles;