import { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../admin/privateRoute";
import Config from "../../helper/config"
import VersionHistory from "./version-history";
import VersionHistoryDetail from "./version-history-details";

const reactUrl = Config.reactUrl;

export class VersionHistoryRoute extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact={true} path={reactUrl + '/version-history'} component={VersionHistory} />
                {/* <Route exact={true} path={reactUrl + '/version-history'} component={VersionHistory} /> */}
                <Route path={reactUrl + '/version-history/details'} component={VersionHistoryDetail} />
            </Switch>
        )
    }
}