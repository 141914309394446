import React, { Component } from 'react'
import { Button } from '@material-ui/core';

class index extends Component {
    render() {
        var { buttonName, fullWidth, buttonStyle, onClick = () => { }, extraStyle, type = "button", disabled = false } = this.props;
        return (
            <Button fullWidth={fullWidth} type={type} className={buttonStyle}
                disabled={disabled}
                onClick={() => (
                    (type !== "submit" || type !== "reset")
                        ? onClick()
                        : {})
                }
                style={extraStyle}>
                {buttonName}
            </Button>
        )
    }
}
export default index;