import React, { Component } from 'react'
import { withStore } from './hooks/store';
import { withStyles } from '@material-ui/core/styles';
import Styles from "../styles/player/dashboard";
import ScrollMenu from 'react-horizontal-scrolling-menu';
// import Videoplayer from "../images/videoplayer.png";
// import Config from "../helper/config";

const highlightsData = [{},{},{}]
const MenuItem = () => {
return <div style={{ padding: 3 }} >
    <div style={{ width: 274, height: 200, opacity: 1, boxShadow: "0px 3px 6px #00000029", position: "relative",
    textAlign: "center" }} >        
    </div>
</div>;
};
  
export const Menu = (list, selected) =>
    list.map(el => {
      return <MenuItem />;
});

const selected = 'item1';

class EmptyGallery extends Component {   
    constructor(props) {
        super(props);
        // call it again if items count changes
        this.menuItems = Menu(highlightsData, selected);
    }

    state = {
        selected
    };
    
    onSelect = key => {
        this.setState({ selected: key });
    } 

    render() {
        const menu = this.menuItems;
        const { selected } = this.state;
        return (
            <ScrollMenu
                alignCenter={false}
                data={menu}
                selected={selected}
                onSelect={this.onSelect}
            />
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(EmptyGallery));