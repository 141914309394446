import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline, Container } from "@material-ui/core";
import { withStore } from "../../component/hooks/store";
import Config from "../../helper/config";
// import NxtPick from "../../images/nxtpick-logo.png";
import Styles from "../../styles/agentDisclaimer.js";
import Footer from "./footer.js";
import Header from "../agent/header";
import Button from "../../component/button";
// import Videoplayer from "../../images/videoplayer.png";
import ReactPlayer from "react-player";
// import Vimeo from "@u-wave/react-vimeo";
import AgentDisclaimerMp from "../../images/AgentDisclaimer.mp4";
import PlayerDisclaimerMp from "../../images/PlayerDisclaimer.mp4";
import { checkNullAndReturnString } from "../../helper/common";
import { Modal } from "react-responsive-modal";

class AgentDisclaimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reached: false,
      reached1: false,
      hashEmail: "",
      modal: false,
      type: "",
      video: false,
    };
  }

  componentDidMount = () => {
    var url = new URL(window.location.href);
    var hashEmail = url.searchParams.get("hash");
    var type = url.searchParams.get("role");
    if (checkNullAndReturnString(hashEmail)) {
      this.setState({ hashEmail });
    }
    if (checkNullAndReturnString(type)) {
      this.setState({ type });
    }
  };

  onOpenModal = () => {
    this.setState({ modal: true });
  };

  onCloseModal = () => {
    this.setState({ modal: false });
  };

  // paneDidMount1 = (node) => {
  //   if (node) {
  //     node.addEventListener("scroll", this.handleScroll1.bind(this));
  //   }
  // };

  // paneDidMount = (node) => {
  //   if (node) {
  //     node.addEventListener("scroll", this.handleScroll.bind(this));
  //   }
  // };

  handleScroll = (event) => {
    // var node = event.target;
    // const bottom = node.scrollHeight - node.clientHeight === node.scrollTop;
    // if (bottom) {
    //   this.setState({ reached: true });
    // } else {
    //   if (!this.state.reached) {
    //     this.setState({ reached: false });
    //   }
    // }

    if (event.target) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      const isNearBottom =
        scrollTop + clientHeight >= parseInt(scrollHeight - 10);
      if (isNearBottom) {
        this.setState({ reached: true });
      } else {
        if (!this.state.reached) {
          this.setState({ reached: false });
        }
      }
    }
  };

  handleScroll1 = (event) => {
    // var node = event.target;
    // const bottom = node.scrollHeight - node.clientHeight === node.scrollTop;
    // if (bottom) {
    //   this.setState({ reached: true });
    // } else {
    //   if (!this.state.reached) {
    //     this.setState({ reached: false });
    //   }
    // }

    if (event.target) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      const isNearBottom =
        scrollTop + clientHeight >= parseInt(scrollHeight - 10);
      if (isNearBottom) {
        this.setState({ reached1: true });
      } else {
        if (!this.state.reached1) {
          this.setState({ reached1: false });
        }
      }
    }
  };

  render() {
    const { classes } = this.props;
    var { /* reached, */ hashEmail, type } = this.state;
    if (type === "player") {
      return (
        <div className={classes.sectionStyle}>
          <Header transparent={true} />
          <Container
            component="main"
            maxWidth="md"
            style={{ position: "relative", zIndex: 5 }}
          >
            <CssBaseline />
            <div className={classes.paper}>
              <form className={classes.form} noValidate>
                <div
                  className={"row"}
                  // ref={this.paneDidMount}
                >
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ textAlign: "center" }}
                  >
                    <span className={classes.disclaimerText}>DISCLAIMER</span>
                  </div>
                </div>
                <div
                  className={"row"}
                  // ref={this.paneDidMount}
                  onScroll={this.handleScroll}
                  style={{
                    height: 400,
                    overflowX: "hidden",
                    overflowY: "scroll",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className={
                      "row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    }
                    style={{ justifyContent: "center", paddingTop: 20 }}
                  >
                    <div style={{ padding: 3 }} className={"imageContainer"}>
                      {/* <div style={{ width: 492, height: 265, opacity: 0.5, boxShadow: "0px 3px 6px #00000029", position: "relative",
                                            textAlign: "center" }} >
                                                <img draggable="false" src={"https://www.bu.edu/files/2019/10/resized-GettyImages-623075908.jpg"} style={{ width: 492, height: 265 }} className={"videoPlayerHover"}/>                                       
                                                <div style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)"
                                                }}>
                                                    <img src={Videoplayer} draggable="false" style={{ width: 53, height: 53 }} /> 
                                                </div>
                                            </div> */}
                      <div
                        className="player-wrapper"
                        style={{
                          width: 492,
                          height: 265,
                          boxShadow: "0px 3px 6px #00000029",
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <ReactPlayer
                          className="react-player"
                          url={PlayerDisclaimerMp}
                          width="100%"
                          height="100%"
                          onEnded={() => this.setState({ video: true })}
                          controls={true}
                        />
                      </div>
                      {/* <ReactPlayer
                          className="react-player"
                          url={PlayerDisclaimerMp}
                          width="100%"
                          height="100%"
                          onEnded={() => this.setState({ video: true })}
                          controls={true}
                        /> */}
                      <span
                        className={classes.smallLabel}
                        style={{ paddingTop: 10 }}
                      >
                        * Play video disclaimer fully to proceed further.
                      </span>
                    </div>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.disclaimerText}>
                      ATHLETE DISCLAIMER
                    </span>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      By clicking “I AGREE” down below, you (hereinafter,{" "}
                      <b> “I”, “me”, “myself”, or “my”</b>) acknowledge and
                      agree to the following terms and conditions governing your
                      relationship with Gladiator Athletics, LLC, a Missouri
                      limited liability company (“<b>NXTPICK</b>”), solely as it
                      relates to your use of
                      <a href="#/"> www.NXTPICK.com</a> (the “Site”). I agree as
                      follows:
                    </span>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      • I represent and warrant that I am an eligible athlete
                      (an “<b>Athlete”</b>) who has or is currently playing in
                      either the National Collegiate Athletic Association
                      (NCAA), National Association of Intercollegiate Athletics
                      (NAIA), or another amateur athletic association (the “
                      <b>Associations</b>”). As an Athlete, I understand that my
                      interactions on the Site may have serious consequences
                      related to my amateur eligibility with respect to any
                      Associations. It is solely my responsibility to ensure
                      that any communication I may have with any registered
                      agent (each, a “<b>Contract Advisor”</b>) through the Site
                      fully complies with any and all applicable laws, rules,
                      and regulations as they may relate to agents and
                      amateurism in the applicable Associations, applicable
                      collegiate athletic conference, as well as my
                      university/school (collectively, the “<b>Regulations</b>
                      ”). As I understand these Regulations may be amended from
                      time to time, I agree to periodically review and strictly
                      adhere to the Regulations and any policies related
                      thereto, including but not limited to: (i) federal
                      (including the Sports Agent Responsibility and Trust Act
                      (SPARTA)); (ii) state (including the Uniform Athlete
                      Agents Act (UAAA)); (iii) National Collegiate Athletic
                      Association (NCAA), National Association of
                      Intercollegiate Athletics, or any other applicable amateur
                      athletic association(s); and any rules/policies of the
                      Site.{" "}
                    </span>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      • I understand that the Site is a platform on which
                      Contract Advisors with the National Football League
                      Players Association (“<b>NFLPA</b>”) unaffiliated with
                      NXTPICK or the Site can offer information and interact
                      with Athletes. I agree that NXTPICK cannot guarantee that
                      my use of the site will result in any relationship
                      (contractual or otherwise) with a Contract Advisor.
                      <b>
                        ANY RELATIONSHIP FORMED AS A RESULT OF ANY SUCH
                        INTERACTION BETWEEN A CONTRACT ADVISOR AND AN ATHLETE ON
                        THE SITE IS BETWEEN THE CONTRACT ADVISOR AND THE
                        ATHLETE—NOT BETWEEN NXTPICK AND THE ATHLETE (OR THE
                        CONTRACT ADVISOR)
                      </b>
                      . NXTPICK cannot be held responsible for the quality or
                      accuracy of any information, interactions, or services
                      provided by Contract Advisors to Athletes connected via
                      the Site.
                    </span>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      • As an Athlete, I understand that it is the
                      responsibility of the university administrator(s) to
                      provide NXTPICK with all necessary information and/or
                      documentation to validate my status as an Athlete
                      (collectively, the “<b>Athlete Information</b>”). If I
                      have any issues with the accuracy or quality of
                      information contained on my profile, I understand and
                      agree that I must contact the applicable administrator to
                      resolve any such issue(s), not NXTPICK. Further, I
                      authorize NXTPICK to conduct any investigation it deems
                      appropriate to validate any such Athlete Information
                      provided. Any Athlete Information that is misrepresented,
                      falsified, or omitted (either by myself or a university
                      administrator) may result in immediate termination of my
                      access to the Site and/or suspension/permanent deletion of
                      my account.{" "}
                    </span>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      • I acknowledge that I am using the Site at my own risk
                      and the information contained on the Site contains third
                      party data and contributions. I understand and agree that
                      there are certain inherent limitations to the accuracy or
                      currency of such information (i.e., the information may
                      contain inaccuracies or be incomplete).{" "}
                      <b>
                        NXTPICK DOES ITS BEST TO SCREEN SUCH INFORMATION FOR
                        ACCURACY OR RELIABILITY BUT CANNOT MAKE ANY GUARANTEE.
                        TO THE FULLEST EXTENT PERMITTED BY LAW, NXTPICK MAKES NO
                        WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SITE.
                        THE SITE IS "AS IS”. NXTPICK DOES NOT WARRANT THAT THE
                        SITE WILL BE UNITERUPTED OR ERROR-FREE, THAT DEFECTS
                        WILL BE CORRECTED, OR THAT THE SITE IS FREE OF VIRUSES
                        OR OTHER HARMFUL COMPONENTS. NXTPICK ALSO DISCLAIMS ANY
                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                        PURPOSE, TITLE, NON-INFRINGEMENT, OR YOUR SATISFACTION.
                      </b>{" "}
                    </span>
                  </div>

                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      • To the extent allowed by applicable law, I agree to
                      release and not to sue NXTPICK, its Site sponsors, and
                      each of their respective parent, subsidiary and affiliated
                      companies, assignees, licensees, owners, officers,
                      directors, partners, board members, shareholders, members,
                      supervisors, insurers, agents, employees, contractors, and
                      all other person or entities associated with or involved
                      in the Site (each, a “<b>Released Party</b>” and,
                      collectively, the “<b>Released Parties</b>”), with respect
                      to any and al claims, liabilities, suits or expenses
                      (including reasonable attorneys’ fees and costs that I may
                      have for any damage or other loss in anyway connected to
                      my use of the Site. Additionally, I agree to defend and
                      indemnify (“
                      <b>indemnify</b>” meaning protect by reimbursement or
                      payment) the Released Parties with respect to any and all
                      claim/s brought by me or on my behalf.
                    </span>
                  </div>

                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      • I hereby affirm my acceptance of the{" "}
                      <a
                        style={{ color: "#1891BD" }}
                        href="#/"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(
                            Config.reactUrl + "/termsofuse"
                          );
                        }}
                      >
                        Terms of Use
                      </a>{" "}
                      governing my use of the Site and{" "}
                      <a
                        style={{ color: "#1891BD" }}
                        href="#/"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(Config.reactUrl + "/privacy");
                        }}
                      >
                        Privacy Policy
                      </a>
                      , which such Terms of Use and Privacy Policy are
                      incorporated herein by reference.
                    </span>
                  </div>

                  {/* <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      By clicking{" "}
                      <span style={{ color: "#1891BD" }}>accept & proceed</span>
                      , you agree to the disclaimer fully.
                    </span>
                  </div> */}
                </div>
              </form>
            </div>
            <div className={"row"}>
              <div
                className={
                  "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 paddingBottomfooterData"
                }
                style={{
                  paddingTop: 20,
                  textAlign: "center",
                  paddingBottom: 100,
                }}
              >
                {/* <Button buttonStyle={classes.buttonStyle1} buttonName={"DECLINE"} extraStyle={extraStyle} onClick={()=> {
                                    reached ? this.props.history.push(Config.reactUrl+"/login") : console.log("")
                                }}/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button buttonStyle={classes.buttonStyle} buttonName={"Accept & Proceed"} extraStyle={extraStyle} onClick={()=> {
                                    reached ? this.props.history.push(Config.reactUrl+"/createpassword?hash="+hashEmail) : console.log("")
                                }}/> */}
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"DECLINE"}
                  onClick={() => {
                    this.onOpenModal();
                  }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  disabled={
                    this.state.video && this.state.reached ? false : true
                  }
                  buttonStyle={classes.buttonStyle}
                  buttonName={"Accept & Proceed"}
                  onClick={() => {
                    if (this.state.video && this.state.reached) {
                      this.props.history.push(
                        Config.reactUrl + "/password?hash=" + hashEmail
                      );
                    }
                  }}
                />
              </div>
            </div>
          </Container>
          <Footer from={"login"} />
          <Modal
            open={this.state.modal}
            onClose={() => this.onCloseModal()}
            center
            showCloseIcon={false}
          >
            <div style={{ padding: 20 }}>
              <p
                style={{
                  font: "normal normal 600 18px/27px Poppins",
                  opacity: 1,
                }}
              >
                Decline Disclaimer
              </p>
              <p
                style={{
                  font: "normal normal normal 14px/21px Poppins",
                  opacity: 1,
                }}
              >
                Are you sure to decline
                disclaimer?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p style={{ float: "right" }}>
                <Button
                  buttonStyle={classes.buttonStyle2}
                  buttonName={"Close"}
                  fullWidth={false}
                  onClick={() => {
                    this.onCloseModal();
                  }}
                />
                <span style={{ paddingRight: 20 }}></span>
                <Button
                  buttonStyle={classes.buttonStyle3}
                  buttonName={"Proceed"}
                  fullWidth={false}
                  onClick={() => {
                    this.props.history.push(Config.reactUrl + "/login");
                  }}
                />
              </p>
            </div>
          </Modal>
        </div>
      );
    } else if (type === "agent") {
      return (
        <div className={classes.sectionStyle}>
          <Header transparent={true} />
          <Container
            component="main"
            maxWidth="md"
            style={{ position: "relative", zIndex: 5 }}
          >
            <CssBaseline />
            <div className={classes.paper}>
              <form className={classes.form} noValidate>
                <div className={"row"} ref={this.paneDidMount1}>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ textAlign: "center" }}
                  >
                    <span className={classes.disclaimerText}>
                      AGENT DISCLAIMER
                    </span>
                  </div>
                </div>
                <div
                  className={"row"}
                  onScroll={this.handleScroll1}
                  style={{
                    height: 400,
                    overflowX: "hidden",
                    overflowY: "scroll",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className={
                      "row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    }
                    style={{ justifyContent: "center", paddingTop: 20 }}
                  >
                    <div style={{ padding: 3 }} className={"imageContainer"}>
                      {/* <div style={{ width: 492, height: 265, opacity: 0.5, boxShadow: "0px 3px 6px #00000029", position: "relative",
                                            textAlign: "center" }} >
                                                <img draggable="false" src={"https://www.bu.edu/files/2019/10/resized-GettyImages-623075908.jpg"} style={{ width: 492, height: 265 }} className={"videoPlayerHover"}/>                                       
                                                <div style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)"
                                                }}>
                                                    <img src={Videoplayer} draggable="false" style={{ width: 53, height: 53 }} /> 
                                                </div>
                                            </div> */}
                      <div
                        className="player-wrapper"
                        style={{
                          width: 492,
                          height: 265,
                          boxShadow: "0px 3px 6px #00000029",
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        <ReactPlayer
                          className="react-player"
                          url={AgentDisclaimerMp}
                          width="100%"
                          height="100%"
                          onEnded={() => this.setState({ video: true })}
                          controls={true}
                        />
                      </div>
                      {/* <div
                        className="player-wrapper"
                        style={{
                          width: 492,
                          height: 265,
                          opacity: 0.5,
                          boxShadow: "0px 3px 6px #00000029",
                          position: "relative",
                          textAlign: "center"
                        }}
                      >
                        <ReactPlayer
                          className="react-player"
                          url={AgentDisclaimerMp}
                          width="100%"
                          height="100%"
                          onEnded={() => this.setState({ video: true })}
                          controls={true}
                        />
                      </div> */}
                      <span
                        className={classes.smallLabel}
                        style={{ paddingTop: 10 }}
                      >
                        * Play video disclaimer fully to proceed further.
                      </span>
                    </div>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.disclaimerText}>
                      AGENT DISCLAIMER
                    </span>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      By clicking “I AGREE” down below, you (hereinafter,{" "}
                      <b>“I”</b>) acknowledge and agree to the following terms
                      and conditions governing your relationship with Gladiator
                      Athletics, LLC, a Missouri limited liability company (“
                      <b>NXTPICK</b>”), solely as it relates to your use of
                      www.NXTPICK.com (the “<b>Site</b>”). I agree as follows:
                    </span>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      I represent and warrant that I am registered contract
                      advisor (a “<b>Contractor Advisor</b>”) with the National
                      Football League Players Association (“<b>NFLPA</b>”). I
                      agree to abide by those certain NFLPA Regulations
                      Governing Contract Advisors (the “<b>Regulations</b>”), as
                      such Regulations may be amended by the NFLPA from time to
                      time, at all times during my use of the Site.
                    </span>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      I understand that the Site is a platform where Contract
                      Advisors unaffiliated with <b>NXTPICK</b> or the Site can
                      offer information and interact with eligible athletes
                      (collectively, “<b>Athletes</b>”) and <b>NXTPICK</b>{" "}
                      cannot guarantee that my use of the site will result in
                      any relationship (contractual or otherwise) with an
                      Athlete. Any relationship formed as a result of any such
                      interaction between a Contract Advisor and an Athlete on
                      the Site is between the Contract Advisor and the
                      Athlete—not between <b>NXTPICK</b> and the Athlete (or the
                      Contract Advisor). <b>NXTPICK</b> cannot be held
                      responsible for the quality or accuracy of any
                      information, interactions, or services provided by
                      Contract Advisors to Athletes connected via the Site.
                    </span>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      As a Contract Advisor, I agree to provide <b>NXTPICK</b>{" "}
                      with all necessary information and/or documentation to
                      validate my status as a Contract Advisor (collectively,
                      the “<b>Agent Information</b>”). Accordingly, I hereby
                      certify that any of the information and documentation
                      provided in connection with my access to and use of the
                      Site is true and correct and I authorize <b>NXTPICK</b> to
                      conduct any investigation it deems appropriate to validate
                      any such Agent Information provided. Additionally, I agree
                      that it is my sole responsibility to supplement any such
                      Agent Information, as it may need to be updated or renewed
                      from time to time. Failure to provide any required Agent
                      Information (as deemed necessary and communicated by{" "}
                      <b>NXTPICK</b>), misrepresentation, falsification, or
                      omission may result in immediate termination of my access
                      to the Site and/or suspension/permanent deletion of my
                      account.
                    </span>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      I understand that my interactions on the Site may have
                      serious consequences related to an Athlete’s amateur
                      eligibility. It is solely my responsibility to ensure that
                      any information or any communication I may have with
                      Athletes through the Site fully complies with any and all
                      applicable laws and rules of professional conduct and I
                      agree to periodically review and abide by any applicable
                      laws, regulations, and policies related thereto, including
                      but not limited to: (i) federal (including the Sports
                      Agent Responsibility and Trust Act (SPARTA)); (ii) state
                      (including the Uniform Athlete Agents Act (UAAA)); (iii)
                      National Collegiate Athletic Association (NCAA), National
                      Association of Intercollegiate Athletics, or any other
                      applicable amateur athletic association(s); and any
                      rules/policies of the Site.
                    </span>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      I acknowledge that I am using the Site at my own risk and
                      the information contained on the Site contains third party
                      data and contributions. I understand and agree that there
                      are certain inherent limitations to the accuracy or
                      currency of such information (i.e., the information may
                      contain inaccuracies or be incomplete).{" "}
                      <b>
                        NXTPICK DOES ITS BEST TO SCREEN SUCH INFORMATION FOR
                        ACCURACY OR RELIABILITY BUT CANNOT MAKE ANY GUARANTEE.
                        TO THE FULLEST EXTENT PERMITTED BY LAW, NXTPICK MAKES NO
                        WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SITE.
                        THE SITE IS "AS IS”. NXTPICK DOES NOT WARRANT THAT THE
                        SITE WILL BE UNITERUPTED OR ERROR-FREE, THAT DEFECTS
                        WILL BE CORRECTED, OR THAT THE SITE IS FREE OF VIRUSES
                        OR OTHER HARMFUL COMPONENTS. NXTPICK ALSO DISCLAIMS ANY
                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                        PURPOSE, TITLE, NON-INFRINGEMENT, OR YOUR SATISFACTION.
                      </b>
                    </span>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      To the extent allowed by applicable law, I agree to
                      release and not to sue NXTPICK, its Site sponsors, and
                      each of their respective parent, subsidiary and affiliated
                      companies, assignees, licensees, owners, officers,
                      directors, partners, board members, shareholders, members,
                      supervisors, insurers, agents, employees, contractors, and
                      all other person or entities associated with or involved
                      in the Site (each, a “<b>Released Party</b>” and,
                      collectively, the “<b>Released Parties</b>”), with respect
                      to any and all claims, liabilities, suits or expenses
                      (including reasonable attorneys’ fees and costs that I may
                      have for any damage or other loss in anyway connected to
                      my use of the Site. Additionally, I agree to defend and
                      indemnify (“<b>indemnify</b>” meaning protect by
                      reimbursement or payment) the Released Parties with
                      respect to any and all claim/s brought by me or on my
                      behalf.
                    </span>
                  </div>
                  <div
                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                    style={{ paddingTop: 20 }}
                  >
                    <span className={classes.label}>
                      I hereby affirm my acceptance of the{" "}
                      <a
                        style={{ color: "#1891BD" }}
                        href="#/"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(
                            Config.reactUrl + "/termsofuse"
                          );
                        }}
                      >
                        Terms of Use
                      </a>{" "}
                      governing my use of the Site and Privacy Policy, which
                      such Terms of Use and{" "}
                      <a
                        style={{ color: "#1891BD" }}
                        href="#/"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push(Config.reactUrl + "/privacy");
                        }}
                      >
                        Privacy Policy
                      </a>{" "}
                      are incorporated herein by reference.
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div className={"row"}>
              <div
                className={
                  "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 paddingBottomfooterData"
                }
                style={{
                  paddingTop: 20,
                  textAlign: "center",
                  paddingBottom: 100,
                }}
              >
                {/* <Button buttonStyle={classes.buttonStyle1} buttonName={"DECLINE"} extraStyle={extraStyle} onClick={()=> {
                                    reached ? this.props.history.push(Config.reactUrl+"/login") : console.log("")
                                }}/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button buttonStyle={classes.buttonStyle} buttonName={"Accept & Proceed"} extraStyle={extraStyle} onClick={()=> {
                                    reached ? this.props.history.push(Config.reactUrl+"/createpassword?hash="+hashEmail) : console.log("")
                                }}/> */}
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"DECLINE"}
                  onClick={() => {
                    this.onOpenModal();
                  }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  buttonStyle={classes.buttonStyle}
                  buttonName={"I AGREE"}
                  onClick={() => {
                    if (this.state.video && this.state.reached1) {
                      this.props.history.push(
                        Config.reactUrl + "/password?hash=" + hashEmail
                      );
                    }
                  }}
                  disabled={
                    this.state.video && this.state.reached1 ? false : true
                  }
                />
              </div>
            </div>
          </Container>
          <Footer from={"login"} />
          <Modal
            open={this.state.modal}
            onClose={() => this.onCloseModal()}
            center
            showCloseIcon={false}
          >
            <div style={{ padding: 20 }}>
              <p
                style={{
                  font: "normal normal 600 18px/27px Poppins",
                  opacity: 1,
                }}
              >
                Decline Disclaimer
              </p>
              <p
                style={{
                  font: "normal normal normal 14px/21px Poppins",
                  opacity: 1,
                }}
              >
                Are you sure to decline
                disclaimer?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p style={{ float: "right" }}>
                <Button
                  buttonStyle={classes.buttonStyle2}
                  buttonName={"Close"}
                  fullWidth={false}
                  onClick={() => {
                    this.onCloseModal();
                  }}
                />
                <span style={{ paddingRight: 20 }}></span>
                <Button
                  buttonStyle={classes.buttonStyle3}
                  buttonName={"Proceed"}
                  fullWidth={false}
                  onClick={() => {
                    this.props.history.push(Config.reactUrl + "/login");
                  }}
                />
              </p>
            </div>
          </Modal>
        </div>
      );
    } else {
      return <div />;
    }
  }
}
export default withStyles(Styles, { withTheme: true })(
  withStore(AgentDisclaimer)
);
