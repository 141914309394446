import React from "react";
import AgentDashboard from "../views/agent/dashboard";
import Footer from "../views/manage/footer";
import { CssBaseline } from "@material-ui/core";
import Header from "../views/agent/header";
import AdminHeader from "../views/admin/header";
import PlayerHeader from "../views/player/header";
import Button from "./button";
import Styles from "../styles/agent/agenrtPlayerView";
import { withStore } from "./hooks/store";
// import { Link } from "react-router-dom";
import Config from "../helper/config";
import BackArrow from "../images/backArrow.png";
import { withStyles } from "@material-ui/core/styles";

class AgentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchAgentData: null,
        };
    }

    componentDidMount() {
        const { location } = this.props;
        if (location.state && location.state.paginations) {
            this.setState({ searchAgentData: location.state });
        } else {
            this.setState({ searchAgentData: null });
        }
    }

    getButtonDetail(classes, state) {
        if (
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.userId
        ) {
            if (
                this.props.match &&
                this.props.match.params &&
                this.props.match.params.type &&
                this.props.match.params.type === "admin"
            ) {
                return (
                    <div>
                        <a
                            href="#/"
                            onClick={(e) => { e.preventDefault(); this.props.history.goBack() }}
                            style={{ color: "inherit" }}
                        >
                            <img src={BackArrow} alt="" style={{ width: 31, height: 31 }} />
                            <span
                                href="#/"
                                style={{
                                    font: "normal normal normal 12px/18px Poppins",
                                    color: "#959595",
                                    paddingLeft: 10,
                                    opacity: 1,
                                }}
                                className={"commonBackClass"}
                                onClick={e => e.preventDefault()}>
                                Back
                            </span>
                        </a>
                    </div>
                );
            }
            if (
                this.props.match &&
                this.props.match.params &&
                this.props.match.params.type &&
                this.props.match.params.type === "player"
            ) {
                return (
                    <div>
                        <a
                            href="#/"
                            onClick={(e) => {
                                e.preventDefault();
                                this.state.searchAgentData
                                    ? this.props.history.push({
                                        pathname: `${Config.reactUrl}/player/playeragentsearch`,
                                        state: this.state.searchAgentData,
                                    })
                                    : this.props.history.goBack()
                            }
                            }
                            style={{ color: "inherit" }}
                        >
                            <img src={BackArrow} alt="BackArrow" style={{ width: 31, height: 31 }} />
                            <span
                                href="#/"
                                style={{
                                    font: "normal normal normal 12px/18px Poppins",
                                    color: "#959595",
                                    paddingLeft: 10,
                                    opacity: 1,
                                }}
                                className={"commonBackClass"}
                                onClick={e => e.preventDefault()}>
                                Back
                            </span>
                        </a>
                        <Button
                            buttonStyle={classes.buttonStyle}
                            buttonName={"MEssage Agent"}
                            fullWidth={false}
                            onClick={() =>
                                this.props.history.push(
                                    Config.reactUrl +
                                    "/player/message/" +
                                    this.props.match.params.userId
                                )
                            }
                        />
                    </div>
                );
            }
        } else {
            return (
                <Button
                    buttonStyle={classes.buttonStyle1}
                    buttonName={"Search Players"}
                    fullWidth={false}
                    onClick={() =>
                        this.props.history.push(
                            Config.reactUrl + "/agent/agentplayersearch"
                        )
                    }
                />
            );
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
                {this.props.match &&
                    this.props.match.params &&
                    this.props.match.params.type &&
                    this.props.match.params.type === "admin" ? (
                    <AdminHeader from={"Agents"} />
                ) : this.props.match &&
                    this.props.match.params &&
                    this.props.match.params.type &&
                    this.props.match.params.type === "player" ? (
                    <PlayerHeader />
                ) : (
                    <Header />
                )}
                <AgentDashboard
                    {...this.props}
                    handleCompareStrategy={this.handleCompareStrategy}
                >
                    {(state) => this.getButtonDetail(classes, state)}
                </AgentDashboard>
                <Footer />
            </React.Fragment>
        );
    }
}

export default withStyles(Styles, { withTheme: true })(withStore(AgentDetails));
