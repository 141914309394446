const Styles = theme => ({        
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  backOption: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#959595",
    paddingLeft: 10, 
    opacity: 1,
    "&:hover": {
      color: "#1891BD"
    },
  },
  backArrow: {
    width: 31,
    height: 31
  },
  editProfileText: {
    font: "normal normal 600 18px/27px Poppins",
    color: "#FFFFFF",
    opacity: 1,
    paddingLeft: 10, 
  },
  fileSizeText: {
    font: "italic normal 300 10px/16px Poppins",
    color: "#959595",
    opacity: 1,
    paddingTop: 10, 
  },
  fileText: {
    font: "normal normal normal 14px/21px Poppins",
    color: "#FFFFFF",
    opacity: 1,
    paddingTop: 10, 
  },
  changeText: {
    font: "italic normal 300 14px/21px Poppins",
    color: "#1891BD",
    opacity: 1,
    paddingTop: 10, 
  },
  basicInfoText: {
    font: "normal normal normal 16px/25px Poppins",
    color: "#FFFFFF",
    opacity: 1,
    paddingLeft: 10, 
  },
  buttonStyle:{
    background: '#1891BD',
    borderRadius: 5,
    color: 'white',
    height: 40,
    padding: '0 15px',
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    font : "normal normal normal 14px/26px Poppins",
    "&:hover": {
      border: "1px solid #1891BD",
    },
  },
  buttonStyle1: {
    font : "normal normal normal 14px/26px Poppins",
    border: "1px solid #1891BD",
    borderRadius: 5,
    color: 'white',
    height: 40,
    padding: '0 15px',
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    outline: "none",
    "&:hover": {
      background: "#1891BD"
    },
  },
  buttonStyle2: {
    font : "normal normal normal 14px/26px Poppins",
    border: "1px solid #1891BD",
    borderRadius: 5,
    color: '#000',
    height: 40,
    padding: '0 15px',
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    outline: "none",
    "&:hover": {
      background: "#1891BD",
      color: "#FFFFFF"
    },
  },  
  buttonStyle3:{
    background: '#1891BD',
    borderRadius: 5,
    color: 'white',
    height: 40,
    padding: '0 15px',
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    font : "normal normal normal 14px/26px Poppins",
    "&:hover": {
      border: "1px solid #1891BD",
      color : "#000"
    },
  },
  playerImage: {
    width: 109,
    height: 109,
    background: "transparent",
    border: "1px solid #707070",
    opacity: 1,
    borderRadius: 10
  },
  playerEditImage: {
    width: 109,
    height: 109,
    background: "transparent",
    border: "1px solid #707070",
    opacity: 1,
    borderRadius: 10
  },
  agentEditImage: {
    width: 109,
    height: 109,
    background: "transparent",
    border: "1px solid #707070",
    opacity: 1,
    borderRadius: 10
  },
  imagePlayer: { 
    width: "100%", height: 123, borderRadius: 10, opacity: 1,    
  },
  searchImage: { position: "absolute",
    top: "50%",
    left: "auto",
    right: "auto",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    width: "100%"
  },  
  videoSearchImage: { 
    position: "absolute",
    top: "58%",
    left: "auto",
    right: "auto",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    width: "100%"
  },
  waitingText: {
    padding: 5,
    font: "normal normal normal 10px/16px Poppins",
    color: "#FFFFFF",
    border: "1px solid #FFFFFF", borderRadius: 14
  },
  hoverSerachPng : { width: 22, height: 21, position: "relative" },
  hoverSerachPng1 : { width: 22, height: 21 },
  hoverSerachPng2 : { width: 40, height: 40, position: "relative", bottom: 5 }
});
export default Styles;