const Styles = theme => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  backOption: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#959595",
    opacity: 1,
    "&:hover": {
      color: "#1891BD"
    },
  },
  sidepanel: {
    width: '250px',
    position: 'fixed',
    zIndex: 1,
    height: '250px',
    top: 0,
    left: '40%',
    backgroundColor: '#111',
    overflowX: 'hidden',
    transition: '0.5s',
    paddingTop: '0px'
  },
  searchPlayerText: {
    font: "normal normal 600 18px/27px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  mediaText: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  filterText: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#1891BD",
    opacity: 1,
  },
  buttonStyle: {
    background: '#1891BD',
    borderRadius: 5,
    color: 'white',
    height: 40,
    padding: '0 15px',
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    // "&:hover": {
    //   border: "1px solid #1891BD",
    // },
  },
  buttonStyle1: {
    border: "1px solid #1891BD",
    borderRadius: 5,
    color: 'white',
    height: 40,
    padding: '0 15px',
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    outline: "none",
    "&:hover": {
      background: "#1891BD"
    },
  },
  resultText: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#959595",
    opacity: 1,
  },
  sortByText: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#959595",
    opacity: 1,
    float: "right"
  },
  Popper: {
    zIndex: 1
  },
  PopColor: {
    background: '#262834',
    border: '1px solid rgb(59, 62, 80)'
  }
});
export default Styles;