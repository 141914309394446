import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withStore } from "../../component/hooks/store";
import { CssBaseline } from "@material-ui/core";
import Styles from "../../styles/agentDisclaimer.js";
import BackArrow from "../../images/backArrow.png";
import NxtLogo from "../../images/nxtpick-logo-header.png";
// import Footer from "./footer.js";
import Config from "../../helper/config";

class AthleteDisclaimer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.sectionStyle}>
        <nav
          class="navbar navbar-expand-md fixed-top"
          style={{
            borderBottom: "1px solid #3B3E50",
            background: "#262834",
          }}
        >
          <a
            href="#/"
            className="navbar-brand"
            style={{ paddingLeft: 20 }}
            onClick={(e) => e.preventDefault()}
          >
            <img
              src={NxtLogo}
              alt="logopng"
              style={{ width: 210, height: 22 }}
            />
          </a>
        </nav>
        {/* <Container */}
        component="main" maxWidth="xl" style=
        {{ position: "relative", zIndex: 5 }}
        >
        <CssBaseline />
        <div className={classes.paper1}>
          <a
            className={"col-md-12"}
            href="#/"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.goBack();
            }}
            style={{ color: "inherit" }}
          >
            <img src={BackArrow} style={{ width: 31, height: 31 }} alt="" />
            <span
              href="#"
              style={{
                font: "normal normal normal 12px/18px Poppins",
                color: "#959595",
                paddingLeft: 10,
                opacity: 1,
              }}
              className={"commonBackClass"}
            >
              Back
            </span>
          </a>
          <form
            className={classes.form}
            style={{ marginTop: 0, paddingTop: 0 }}
            noValidate
          >
            <div className={"row"} ref={this.paneDidMount}>
              <div
                className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                style={{ paddingTop: 20, textAlign: "center" }}
              >
                <span className={classes.label} style={{ fontSize: 19 }}>
                  <b>ATHLETE DISCLAIMER</b>
                </span>
              </div>
            </div>
            <div className={"row"} ref={this.paneDidMount}>
              <div
                className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                style={{ paddingTop: 20 }}
              >
                <span className={classes.label}>
                  By clicking “I AGREE” down below, you (hereinafter,{" "}
                  <b> “I”, “me”, “myself”, or “my”</b>) acknowledge and agree to
                  the following terms and conditions governing your relationship
                  with Gladiator Athletics, LLC, a Missouri limited liability
                  company (“<b>NXTPICK</b>”), solely as it relates to your use
                  of
                  <a href="#/"> www.NXTPICK.com</a> (the “Site”). I agree as
                  follows:
                </span>
              </div>
            </div>
            <div className={"row"} ref={this.paneDidMount}>
              <div
                className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                style={{ paddingTop: 10, paddingLeft: 35 }}
              >
                <span className={classes.label}>
                  • I represent and warrant that I am an eligible athlete (an “
                  <b>Athlete”</b>) who has or is currently playing in either the
                  National Collegiate Athletic Association (NCAA), National
                  Association of Intercollegiate Athletics (NAIA), or another
                  amateur athletic association (the “<b>Associations</b>”). As
                  an Athlete, I understand that my interactions on the Site may
                  have serious consequences related to my amateur eligibility
                  with respect to any Associations. It is solely my
                  responsibility to ensure that any communication I may have
                  with any registered agent (each, a “<b>Contract Advisor”</b>)
                  through the Site fully complies with any and all applicable
                  laws, rules, and regulations as they may relate to agents and
                  amateurism in the applicable Associations, applicable
                  collegiate athletic conference, as well as my
                  university/school (collectively, the “<b>Regulations</b>”). As
                  I understand these Regulations may be amended from time to
                  time, I agree to periodically review and strictly adhere to
                  the Regulations and any policies related thereto, including
                  but not limited to: (i) federal (including the Sports Agent
                  Responsibility and Trust Act (SPARTA)); (ii) state (including
                  the Uniform Athlete Agents Act (UAAA)); (iii) National
                  Collegiate Athletic Association (NCAA), National Association
                  of Intercollegiate Athletics, or any other applicable amateur
                  athletic association(s); and any rules/policies of the Site.
                </span>
              </div>
            </div>
            <div className={"row"} ref={this.paneDidMount}>
              <div
                className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                style={{ paddingTop: 10, paddingLeft: 35 }}
              >
                <span className={classes.label}>
                  • I understand that the Site is a platform on which Contract
                  Advisors with the National Football League Players Association
                  (“<b>NFLPA</b>”) unaffiliated with NXTPICK or the Site can
                  offer information and interact with Athletes. I agree that
                  NXTPICK cannot guarantee that my use of the site will result
                  in any relationship (contractual or otherwise) with a Contract
                  Advisor.
                  <b>
                    ANY RELATIONSHIP FORMED AS A RESULT OF ANY SUCH INTERACTION
                    BETWEEN A CONTRACT ADVISOR AND AN ATHLETE ON THE SITE IS
                    BETWEEN THE CONTRACT ADVISOR AND THE ATHLETE—NOT BETWEEN
                    NXTPICK AND THE ATHLETE (OR THE CONTRACT ADVISOR)
                  </b>
                  . NXTPICK cannot be held responsible for the quality or
                  accuracy of any information, interactions, or services
                  provided by Contract Advisors to Athletes connected via the
                  Site.
                </span>
              </div>
            </div>

            <div className={"row"} ref={this.paneDidMount}>
              <div
                className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                style={{ paddingTop: 10, paddingLeft: 35 }}
              >
                <span className={classes.label}>
                  • As an Athlete, I understand that it is the responsibility of
                  the university administrator(s) to provide NXTPICK with all
                  necessary information and/or documentation to validate my
                  status as an Athlete (collectively, the “
                  <b>Athlete Information</b>”). If I have any issues with the
                  accuracy or quality of information contained on my profile, I
                  understand and agree that I must contact the applicable
                  administrator to resolve any such issue(s), not NXTPICK.
                  Further, I authorize NXTPICK to conduct any investigation it
                  deems appropriate to validate any such Athlete Information
                  provided. Any Athlete Information that is misrepresented,
                  falsified, or omitted (either by myself or a university
                  administrator) may result in immediate termination of my
                  access to the Site and/or suspension/permanent deletion of my
                  account.
                </span>
              </div>
            </div>

            <div className={"row"} ref={this.paneDidMount}>
              <div
                className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                style={{ paddingTop: 10, paddingLeft: 35 }}
              >
                <span className={classes.label}>
                  • I acknowledge that I am using the Site at my own risk and
                  the information contained on the Site contains third party
                  data and contributions. I understand and agree that there are
                  certain inherent limitations to the accuracy or currency of
                  such information (i.e., the information may contain
                  inaccuracies or be incomplete).{" "}
                  <b>
                    NXTPICK DOES ITS BEST TO SCREEN SUCH INFORMATION FOR
                    ACCURACY OR RELIABILITY BUT CANNOT MAKE ANY GUARANTEE. TO
                    THE FULLEST EXTENT PERMITTED BY LAW, NXTPICK MAKES NO
                    WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SITE. THE
                    SITE IS "AS IS”. NXTPICK DOES NOT WARRANT THAT THE SITE WILL
                    BE UNITERUPTED OR ERROR-FREE, THAT DEFECTS WILL BE
                    CORRECTED, OR THAT THE SITE IS FREE OF VIRUSES OR OTHER
                    HARMFUL COMPONENTS. NXTPICK ALSO DISCLAIMS ANY WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                    NON-INFRINGEMENT, OR YOUR SATISFACTION.
                  </b>
                </span>
              </div>
            </div>
            <div className={"row"} ref={this.paneDidMount}>
              <div
                className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                style={{ paddingTop: 10, paddingLeft: 35 }}
              >
                <span className={classes.label}>
                  • To the extent allowed by applicable law, I agree to release
                  and not to sue NXTPICK, its Site sponsors, and each of their
                  respective parent, subsidiary and affiliated companies,
                  assignees, licensees, owners, officers, directors, partners,
                  board members, shareholders, members, supervisors, insurers,
                  agents, employees, contractors, and all other person or
                  entities associated with or involved in the Site (each, a “
                  <b>Released Party</b>” and, collectively, the “
                  <b>Released Parties</b>”), with respect to any and al claims,
                  liabilities, suits or expenses (including reasonable
                  attorneys’ fees and costs that I may have for any damage or
                  other loss in anyway connected to my use of the Site.
                  Additionally, I agree to defend and indemnify (“
                  <b>indemnify</b>” meaning protect by reimbursement or payment)
                  the Released Parties with respect to any and all claim/s
                  brought by me or on my behalf.
                </span>
              </div>
            </div>

            <div className={"row"} ref={this.paneDidMount}>
              <div
                className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}
                style={{ paddingTop: 10, paddingLeft: 35 }}
              >
                <span className={classes.label}>
                  • I hereby affirm my acceptance of the{" "}
                  <a
                    style={{ color: "#1891BD" }}
                    href="#/"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(Config.reactUrl + "/termsofuse");
                    }}
                  >
                    Terms of Use
                  </a>{" "}
                  governing my use of the Site and{" "}
                  <a
                    style={{ color: "#1891BD" }}
                    href="#/"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(Config.reactUrl + "/privacy");
                    }}
                  >
                    Privacy Policy
                  </a>
                  , which such Terms of Use and Privacy Policy are incorporated
                  herein by reference.
                </span>
              </div>
            </div>
          </form>
        </div>
        {/* </Container> */}
        {/* <Footer /> */}
      </div>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(
  withStore(AthleteDisclaimer)
);
