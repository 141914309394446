import React, { Component } from 'react'
import { withStore } from '../../component/hooks/store';
import { withStyles } from '@material-ui/core/styles';
import Styles from "../../styles/admin/players";
import Button from "../../component/button";
import Tables from "../../component/table";
import { checkNullAndReturnString, doGetConnectSecurity, doPostConnectSecurity, doGetConnect } from '../../helper/common';
import SelectBox from "../../component/selectBox";
import { toast } from 'react-toastify';
import { Modal } from 'react-responsive-modal';

class ImportAverage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            school: 0,
            importData: false,
            academicYear: [{ label: "Academic Year", value: 0 }],
            modal: false,
            tableData: [],
            positionList: []
        }
    }

    onOpenModal = () => {
        this.setState({ modal: true })
    };

    onCloseModal = () => {
        this.setState({ modal: false })
    };

    componentDidMount = async () => {
        this.getAcademicYear()
        var responseData1 = await doGetConnect("school/position")
        if (checkNullAndReturnString(responseData1)) {
            if (checkNullAndReturnString(responseData1.data)) {                
                this.setState({ positionList: responseData1.data})
            }
        }
    }

    getAcademicYear = async () => {
        var academicYear = [{ label: "Academic Year", value: 0 }]
        var responseData = await doGetConnect("academic-year")
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data)) {
                var academicYearData = responseData.data
                var date = new Date();
                var year = date.getFullYear();
                var yearSet = false;
                Object.keys(academicYearData).forEach(key => {
                    if ((year && year.toString()) === (academicYearData[key].year && academicYearData[key].year.toString())) {
                        yearSet = true
                    }
                    academicYear.push({ label: academicYearData[key].year, value: academicYearData[key].year })
                })
                this.setState({ academicYear })
                if (yearSet) {
                    this.getDraftData(year)
                    this.setState({ school: year })
                }
            }
        }
    }

    getDraftData = async (value) => {
        this.setState({ tableData: [], importData: false, fileName: "" });
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doGetConnectSecurity("player-average-performance?academicYear=" + value, accessToken)
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data) && responseData.data.length) {
                this.setState({ tableData: responseData.data, importData: true })
            } else {
                var tableDataCont = [];
                var positionData = this.state.positionList;
                Object.keys(positionData).forEach(key => {
                    tableDataCont.push({
                        position: positionData[key].name,
                        academicYear: value,
                        fourtyYardDash: "",
                        twentyYardDash: "",
                        benchPress: "",
                        verticalJump: "",
                        broadJump: "",
                        threeCone: "",
                        sixtyYardShuttle: ""
                    })
                })
                this.setState({ tableData : tableDataCont, importData: true });
            }
        }
    }

    emptyData = () => {
        this.setState({ tableData: [], importData: false, fileName: "", school: 0 });
    }

    handleChange = (id, value) => {
        if (id === "school") {
            this.getDraftData(value);
        }
        this.setState({
            [id]: value
        })
    }

    onClick = async () => {
        if (this.state.importData) {
            if (checkNullAndReturnString(this.state.school) && Number(this.state.school) !== 0) {
                var tableData = this.state.tableData;
                var accessToken = localStorage.getItem("accessToken");
                var jsonData = []
                Object.keys(tableData).forEach(key => {
                    jsonData.push({
                        position: tableData[key].position,
                        academicYear: this.state.school,
                        fourtyYardDash: tableData[key].fourty_yard_dash,
                        twentyYardDash: tableData[key].twenty_yard_shuttle,
                        benchPress: tableData[key].bench_press,
                        verticalJump: tableData[key].vertical_jump,
                        broadJump: tableData[key].broad_jump,
                        threeCone: tableData[key].three_cone,
                        // sixtyYardShuttle: tableData[key].sixty_yard_shuttle
                    })
                })
                var postJson = {
                    postJson: jsonData
                }
                var responseData = await doPostConnectSecurity("player-average-performance", postJson, accessToken)
                if (checkNullAndReturnString(responseData)) {
                    if (checkNullAndReturnString(responseData.message) && responseData.message === "Success") {
                        toast.success("Average data has been saved successfully");
                        this.emptyData();
                    }
                    else {
                        if (checkNullAndReturnString(responseData.error)) {
                            if (checkNullAndReturnString(responseData.error.message)) {
                                toast.error(responseData.error.message);
                            }
                        }
                    }
                }
            }
            else {
                toast.error("Please select academic year");
            }
        }
        else {
            toast.error("Please import data");
        }
    }

    onClickCancel = () => {
        this.setState({
            school: 0,
            importData: false,
            tableData: [],
            fileName: ""
        })
    }

    handleFiles = files => {
        if (checkNullAndReturnString(this.state.school) && Number(this.state.school) !== 0) {
            var that = this;
            var reader = new FileReader();
            reader.onload = function (e) {
                var allRows = reader.result.split(/\r?\n|\r/);
                var tableData = [];
                for (var singleRow = 0; singleRow < allRows.length; singleRow++) {
                    if (Number(singleRow) === 0) {
                    }
                    else {
                        var splitedData = allRows[singleRow].split(",");
                        if (checkNullAndReturnString(allRows[singleRow])) {
                            var postJson = {
                                position: splitedData[0],
                                bench_press: splitedData[1],
                                broad_jump: splitedData[2],
                                fourty_yard_dash: splitedData[3],
                                twenty_yard_shuttle: splitedData[4],
                                // sixty_yard_shuttle: splitedData[5],
                                three_cone: splitedData[5],
                                vertical_jump: splitedData[6],
                            }
                            tableData.push(postJson)
                        }
                    }
                }
                that.setState({ tableData, fileName: files[0].name, importData: false })
            }
            reader.readAsText(files[0]);
        }
        else {
            toast.error("Please select academic year");
        }
    }

    tableHandleChange = (val, key, index) => {
        var { tableData } = this.state;
        tableData[key][index] = val;
        this.setState({ tableData })
    }

    render() {
        const { classes } = this.props;
        var { tableData, fileName, importData, academicYear } = this.state;
        var tableInputStyle = {
            background: "transparent",
            font: "normal normal normal 14px/21px Poppins",
            height: 32,
            color: "#fff",
            border: "0.5px solid rgb(75 76 92 / 60%)",
            width: 80,
            paddingLeft: 13,
            paddingRight: 13,
            outline: "none",
            "&:hover": {
                outline: "none"
            },
            "&:focus": {
                outline: "none"
            },
            borderRadius: 5
        }
        if (checkNullAndReturnString(tableData)) {
            var rows2 = []
            Object.keys(tableData).forEach(key => {
                var rowCells = tableData[key];
                if (checkNullAndReturnString(rowCells)) {
                    rows2.push({
                        position: rowCells.position,
                        bench_press: <input type={"text"}
                            value={rowCells.bench_press}
                            style={tableInputStyle}
                            onChange={(e) => this.tableHandleChange(e.target.value, key, "bench_press")}
                        />,
                        broad_jump: <input type={"text"}
                            value={rowCells.broad_jump}
                            style={tableInputStyle}
                            onChange={(e) => this.tableHandleChange(e.target.value, key, "broad_jump")}
                        />,
                        fourty_yard_dash: <input type={"text"}
                            value={rowCells.fourty_yard_dash}
                            style={tableInputStyle}
                            onChange={(e) => this.tableHandleChange(e.target.value, key, "fourty_yard_dash")}
                        />,
                        twenty_yard_shuttle: <input type={"text"}
                            value={rowCells.twenty_yard_shuttle}
                            style={tableInputStyle}
                            onChange={(e) => this.tableHandleChange(e.target.value, key, "twenty_yard_shuttle")}
                        />,
                        // sixty_yard_shuttle: <input type={"text"}
                        //     value={rowCells.sixty_yard_shuttle}
                        //     style={tableInputStyle}
                        //     onChange={(e) => this.tableHandleChange(e.target.value, key, "sixty_yard_shuttle")}
                        // />,
                        three_cone: <input type={"text"}
                            value={rowCells.three_cone}
                            style={tableInputStyle}
                            onChange={(e) => this.tableHandleChange(e.target.value, key, "three_cone")}
                        />,
                        vertical_jump: <input type={"text"}
                            value={rowCells.vertical_jump}
                            style={tableInputStyle}
                            onChange={(e) => this.tableHandleChange(e.target.value, key, "vertical_jump")}
                        />,
                    })
                }
            })
        }
        return (
            <React.Fragment>
                <div class="row rowView" style={{ paddingTop: 20, paddingBottom: window.innerWidth < 500 ? 110 : 50 }}>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                        <span className={classes.searchText}>Import Drill Results</span>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className={"row"}>
                            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12" style={{ paddingTop: 20 }}>
                                <SelectBox fullWidth={true} extraStyle={{ width: "100%" }} backgroundColor={"rgb(75 76 92 / 60%)"} onChange={this.handleChange} keyName={"school"} options={academicYear} value={this.state.school} />
                            </div>
                            {/* <div class="col-xl-1 col-lg-2 col-md-2 col-sm-2 col-12" style={{ paddingTop: 20 }}>
                                <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'} >
                                    <Button buttonStyle={classes.buttonStyle1} buttonName={"BROWSE"} fullWidth={false} onClick={() => console.log("file choosen")} />
                                </ReactFileReader>
                            </div> */}
                            <div class="col-xl-3 col-lg-4 col-md-10 col-sm-10 col-12" style={{ paddingTop: 10, paddingRight: 10, alignSelf: "center" }}>
                                <span className={classes.fileName}>
                                    {
                                        checkNullAndReturnString(fileName) ?
                                            fileName
                                            :
                                            ""
                                    }
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href="#/" className={classes.fileNameRemove} onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ tableData: [], fileName: "" })
                                    }} style={{ paddingTop: 20 }}>
                                        {
                                            checkNullAndReturnString(fileName) ?
                                                "Remove"
                                                :
                                                ""
                                        }
                                    </a>
                                </span>
                            </div>
                            {/* <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12" style={{ paddingTop: 20 }}>
                                <Button buttonStyle={classes.buttonStyle1} buttonName={"UPLOAD"} fullWidth={false} onClick={() => this.setState({ importData: true })} />
                            </div> */}
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 10 }}>
                        <span className={classes.studentCountText}>
                            {/* {
                                checkNullAndReturnString(tableData) && Object.keys(tableData).length > 0 && importData ?
                                    Object.keys(tableData).length + " records imported successfully"
                                    :
                                    null
                            } */}
                        </span>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 10 }}>
                        <Tables fixed={true} extraStyle={{
                            height: checkNullAndReturnString(tableData) && Object.keys(tableData).length > 0 ?
                                Object.keys(tableData).length > 10 ?
                                    400
                                    :
                                    ""
                                :
                                ""
                        }} thJson={["Position", "Bench press", "Broad jump", "40 Yard dash", "20 Yard shuttle", "3 Cone Drill", "Vertical Jump"]} tdJson={importData ? rows2 : []} />
                    </div>
                    {
                        checkNullAndReturnString(tableData) && Object.keys(tableData).length > 0 ?
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                                <div style={{ float: "right", paddingTop: 0 }}>
                                    <Button buttonStyle={classes.buttonStyle1} buttonName={"Cancel"} fullWidth={false} onClick={this.onClickCancel} />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button buttonStyle={classes.buttonStyle} buttonName={"Submit"} fullWidth={false} onClick={this.onOpenModal} />
                                </div>
                            </div>
                            :
                            ""
                    }
                </div>
                <Modal open={this.state.modal} onClose={() => this.onCloseModal()} center showCloseIcon={false}>
                    <div style={{ padding: 20 }}>
                        <p style={{ font: "normal normal 600 18px/27px Poppins", opacity: 1 }}>Import Data Confirmation</p>
                        <p style={{ font: "normal normal normal 14px/21px Poppins", opacity: 1 }}>
                            All previous repeated data will be replaced, are you sure to proceed?
                        </p>
                        <p style={{ float: "right" }}>
                            <Button buttonStyle={classes.buttonStyle2} buttonName={"Cancel"} fullWidth={false} onClick={() => {
                                this.onCloseModal();
                            }} />
                            <span style={{ paddingRight: 20 }}></span>
                            <Button buttonStyle={classes.buttonStyle3} buttonName={"Confirm"} fullWidth={false} onClick={() => {
                                this.onClick();
                                this.onCloseModal();
                            }} />
                        </p>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(ImportAverage));