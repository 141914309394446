import LoginBg from "../images/login-bg.png";
const styles = (theme) => ({
  sectionStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    overflow: "hidden",
    minWidth: "100%",
    minHeight: "100%",
    backgroundImage: "url(" + LoginBg + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  paper: {
    marginTop: 160,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    zIndex: 4,
  },
  avatar: {
    opacity: 1,
    width: 175,
    height: 81,
    // opacity: 1
  },
  createPAsswordFont: {
    letterSpacing: 0,
    color: "#FFFFFF",
    opacity: 1,
    textAlign: "center",
    font: "normal normal medium 18px/19px Poppins",
  },
  loginInput: {
    opacity: 1,
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: 0,
    color: "#f5fd5f",
    height: 50,
    outline: "none",
    border: "none",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgotPassword: {
    paddingTop: 25,
    textAlign: "center",
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: 0,
    color: "#FFFFFF",
    opacity: 1,
    padding: 0,
    margin: 0,
    // opacity: 1
  },
  resetLink: {
    font: "normal normal normal 12px/18px Poppins",
    letterSpacing: 0,
    color: "#3FCB57",
    opacity: 1,
    padding: 0,
    margin: 0,
    // opacity: 1
  },
  footerLinkText: {
    paddingRight: 20,
    paddingLeft: 20,
    color: "#ffffff",
    font: "normal normal normal 12px/18px Poppins",
  },
  buttonStyle: {
    background: "#1891BD",
    borderRadius: 5,
    border: 0,
    color: "white",
    height: 46,
    padding: "0 30px",
    opacity: 1,
    "&:hover": {
      border: "1px solid #1891BD",
    },
  },
  userPng: {
    width: 16,
    height: 16,
    background: "transparent 0% 0% no-repeat padding-box",
    opacity: 1,
    position: "relative",
    zIndex: 1,
    top: 32,
    left: 15,
  },
  passwordPng: {
    width: 14,
    height: 18,
    background: "transparent 0% 0% no-repeat padding-box",
    opacity: 1,
    position: "relative",
    zIndex: 1,
    top: 32,
    left: 15,
  },
  eyePng: {
    width: 15,
    height: 10,
    background: "transparent 0% 0% no-repeat padding-box",
    opacity: 1,
    position: "relative",
    zIndex: 1,
    top: 45,
    left: "89%",
  },
  tickPng: {
    width: 14,
    height: 10,
    background: "transparent 0% 0% no-repeat padding-box",
    opacity: 1,
    position: "relative",
    zIndex: 1,
    top: 33,
    left: "89%",
  },
});
export default styles;
