import React, { Component } from 'react';
import ProgressBar from './progressBar';
// import HeartCircle from '../images/heartCircle.png';
import HeartUnFavoriteCircle from '../images/unfavorite.png';
import HeartFavoriteCircle from '../images/favorite.png';
import Config from "../helper/config";
import { positionAverageData, positionAverageData1 } from "../helper/common";
import VideoCamerImage from "../images/video.png";
import GalleryImage from "../images/gallery.png";

class index extends Component {
    // constructor(props) {
    //     super(props);
    // }
    setEmptyUser(e) {
        e.target.src = Config.emptyUser
    }

    setAgentPlayerDetailPage = (id) => {
        const details = this.props.compareUserPageDetail;
        if (details.width) { delete details.width };
        if (details.height) { delete details.height };
        if (details.positionDetails) { delete details.positionDetails };
        if (details.schoolDetails) { delete details.schoolDetails };
        if (details.filterUserDetails) { delete details.filterUserDetails };
        this.props.history.push({
            pathname: Config.reactUrl + `/agent/agentplayerview/agent/${id}`,
            state: { playerSearchDetail: details }
        })
    }
    render() {
        var {
            makeUserFavorite, makeUserUnFavorite, makeCompareUser, percentage, colorCode, full_name,
            overall_percentage, state, city, height, weight, first_name,
            last_name, favorite_status, id, age, academic_year, bench_press, broad_jump,
            class: classvariable, created_at, created_by, date_of_birth, fourty_yard_dash, id_user,
            performance_id, position, school_id, 
            // sixty_yard_shuttle,
            status, team, three_core_drill,
            twenty_yard_shuttle, email,
            height_feet, height_inches,
            player_position, role_id,
            vertical_jump,
            total_images, total_videos,
            profile_image_hash,
            bench_press_target,
            vertical_jump_target,
            three_cone_target,
            broad_jump_target,
            // sixty_yard_shuttle_target,
            twenty_yard_shuttle_target,
            fourty_yard_dash_target
            //  height
        } = this.props;

        var benchPress = positionAverageData(bench_press,bench_press_target);
        var verticalJump = positionAverageData(vertical_jump,vertical_jump_target);
        var threeCoreDrill = positionAverageData1(three_core_drill,three_cone_target);
        var broadJump = positionAverageData(broad_jump,broad_jump_target);
        // var sixtyYardShuttle = positionAverageData1(sixty_yard_shuttle,sixty_yard_shuttle_target);
        var twentyYardShuttle = positionAverageData1(twenty_yard_shuttle,twenty_yard_shuttle_target);
        var fourtyYardDash = positionAverageData1(fourty_yard_dash,fourty_yard_dash_target);

        var overallCount = benchPress + verticalJump + threeCoreDrill + 
        broadJump +
            // sixtyYardShuttle + 
            twentyYardShuttle + fourtyYardDash;

        var oveallPercentage = 0;
        if (overallCount !== 'Infinity') {
            oveallPercentage = parseFloat(overallCount / 6).toFixed(2)
        }

        // var background = [
        //     "transparent linear-gradient(90deg, #262834 0%, #26D276 100%) 0% 0% no-repeat padding-box",
        //     "transparent linear-gradient(90deg, #262834 0%, #9C51E6 100%) 0% 0% no-repeat padding-box",
        //     "transparent linear-gradient(90deg, #262834 0%, #C6E63E 100%) 0% 0% no-repeat padding-box",
        //     "transparent linear-gradient(90deg, #262834 0%, #E63E3E 100%) 0% 0% no-repeat padding-box"
        // ]
        var smallText = {
            font: "normal normal normal 10px/16px Poppins",
            color: "#959595",
            opacity: 1,
        }
        var boldText = {
            font: "normal normal normal 12px/18px Poppins",
            color: "#FFFFFF",
            opacity: 1,
        }
        var compareText = {
            font: "normal normal normal 12px/18px Poppins",
            color: "#1891BD",
            opacity: 1,
            float: "right"
        }
        var imageStyle = {
            width: 40,
            height: 40,
            borderRadius: 8
        }
        var numberText = {
            font: "normal normal normal 8px/12px Poppins",
            color: "#959595",
            opacity: 1,
            float: "right",
            padding: 3,
            background: "#262834 0% 0% no-repeat padding-box",
            borderRadius: 8
        }
        return (
            <div className={"col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 "} style={{ paddingTop: 5, paddingLeft: 5, paddingBottom: 5, paddingRight: 5 }}>
                <div className={"hoverPlayerSearchCard"} style={{
                    width: "100%",
                    height: "100%",
                    background: "#3B3E50 0% 0% no-repeat padding-box",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: 10,
                    border: "1px solid #3B3E50",
                    opacity: 1,
                    cursor: 'pointer',
                    "&:hover": {
                        background: "red"
                    }
                }} onClick={() => this.setAgentPlayerDetailPage(id)}>
                    <div className="row" style={{ padding: 10 }}>
                        <div className="col-2" style={{ justifyContent: "center" }}>
                            {
                                profile_image_hash
                                    ? <img style={imageStyle} alt="imageStyle" src={`${Config.dataUrl}aws-files/${profile_image_hash}`} onError={(e) => this.setEmptyUser(e)} />
                                    : <img src={Config.emptyUser} alt="emptyUser" style={imageStyle} />
                            }
                        </div>
                        <div className="col-7">
                            <span style={boldText}>{full_name ? full_name : `${first_name} ${last_name}`}</span>
                            <br />
                            <span style={smallText}>{player_position}</span>
                            <br />
                            <div style={{ display: "inline-flex", paddingTop: 5 }}>
                                <span style={numberText}>
                                    <span>
                                        <img src={VideoCamerImage} alt="VideoCamerImage" style={{ width: '14px', height: '13px', padding: '2px 0px 0px 2px' }} />
                                    </span>
                                    &nbsp;  {total_videos} &nbsp; </span>
                                &nbsp;&nbsp;
                                <span style={numberText}> <img src={GalleryImage} alt="GalleryImage" style={{ width: '14px', height: '13px', padding: '2px 0px 0px 2px' }} />
                                    &nbsp;  {total_images} &nbsp; </span>
                                &nbsp;&nbsp;
                                <span >
                                    {
                                        favorite_status
                                            ? <img src={HeartFavoriteCircle} alt="HeartFavoriteCircle"
                                                style={{ width: 12, height: 12 }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    makeUserUnFavorite(id)
                                                }} />
                                            : <img src={HeartUnFavoriteCircle} alt="HeartUnFavoriteCircle"
                                                style={{ width: 12, height: 12 }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    makeUserFavorite(id)
                                                }} />
                                    }
                                </span>

                            </div>
                        </div>
                        <div className="col-3">
                            <span style={compareText}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    makeCompareUser({
                                        percentage, colorCode, full_name,
                                        overall_percentage, state, city, height, weight, first_name,
                                        last_name, favorite_status, id, age, academic_year, bench_press, broad_jump,
                                        classvariable, created_at, created_by, date_of_birth, fourty_yard_dash, id_user,
                                        performance_id, position, school_id, 
                                        // sixty_yard_shuttle, 
                                        status, team, three_core_drill,
                                        twenty_yard_shuttle, email,
                                        height_feet, height_inches,
                                        player_position, role_id,
                                        vertical_jump,
                                        profile_image_hash,
                                        total_images,
                                        total_videos,
                                        bench_press_target,
                                        vertical_jump_target,
                                        three_cone_target,
                                        broad_jump_target,
                                        // sixty_yard_shuttle_target,
                                        twenty_yard_shuttle_target,
                                        fourty_yard_dash_target
                                    })
                                }
                                }
                            >Compare</span>
                        </div>
                    </div>
                    <div className="row" style={{ padding: 5, paddingRight: 10, paddingLeft: 10 }}>
                        <div className="col-12">
                            <span style={smallText}>Overall Rating</span>
                            <span style={{ ...boldText, float: "right" }}>{oveallPercentage ? `${oveallPercentage}%` : '0%'}</span>
                        </div>
                        <div className="col-12" style={{ padding: 5 }}>
                            <ProgressBar width={`${oveallPercentage}`} mainBackground={"#262834 0% 0% no-repeat padding-box"} />
                        </div>
                    </div>
                    <div className="row" style={{ padding: 5, paddingRight: 10, paddingLeft: 10 }}>
                        <div className="col-3">
                            <span style={smallText}>Location</span>
                            <br />
                            <span style={boldText}>{city} {state}</span>
                        </div>
                        <div className="col-3">
                            <span style={smallText}>Height</span>
                            <br />
                            <span style={boldText}>{height_feet + "' " + height_inches + '"'}</span>
                        </div>
                        <div className="col-3">
                            <span style={smallText}>Weight</span>
                            <br />
                            <span style={boldText}>{weight} Lbs</span>
                        </div>
                        <div className="col-3">
                            <span style={smallText}>Age</span>
                            <br />
                            <span style={boldText}>{(age && age.years) ? age.years : 0} Yrs</span>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
export default index;