const Styles = (theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  playerCard: {
    width: "100%",
    background: "#2E303E 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 10,
    opacity: 1,
    padding: 25,
  },
  agentImage: {
    width: 109,
    height: 109,
    background: "transparent",
    border: "1px solid #707070",
    opacity: 1,
    borderRadius: 10,
  },
  playerName: {
    font: "normal normal normal 16px/25px Poppins !important",
    color: "#FFFFFF",
    opacity: 1,
    paddingLeft: 40,
  },
  playerGame: {
    font: "normal normal normal 12px/18px Poppins !important",
    color: "#959595",
    opacity: 1,
  },
  leagueText: {
    font: "italic normal 300 14px/21px Poppins !important",
    color: "#CCCCCC",
    opacity: 1,
  },
  underLine: {
    width: "100%",
    height: 1,
    background: "#262834 0% 0% no-repeat padding-box",
    opacity: 1,
  },
  biograpyText: {
    font: "normal normal normal 12px/18px Poppins !important",
    color: "#959595",
    opacity: 1,
  },
  biograpyDescription: {
    font: "normal normal normal 14px/21px Poppins !important",
    color: "#CCCCCC",
    opacity: 1,
  },
  social: {
    width: 21,
    height: 21,
  },
  menu: {
    width: 32,
    height: 32,
  },
  playerProfileText: {
    font: "normal normal normal 14px/21px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  circleCommon: {
    padding: 10,
    background: "#262834 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #3B3E50",
    opacity: 1,
    borderRadius: 40 / 2,
    textAlign: "center",
  },
  schoolImage: {
    width: 16,
    height: 10,
  },
  commonCircleName: {
    font: "normal normal normal 10px/16px Poppins",
    color: "#959595",
    opacity: 1,
  },
  commonCircleDescription: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  positionImage: {
    width: 16,
    height: 13,
  },
  homeImage: {
    width: 16,
    height: 16,
  },
  calenderImage: {
    width: 17,
    height: 15,
  },
  heightImage: {
    width: 16,
    height: 16,
  },
  weightImage: {
    width: 16,
    height: 16,
  },
  showYearlyText: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#1891BD",
    opacity: 1,
  },
  footBallImage: {
    width: 16,
    height: 16,
  },
  startImage: {
    width: 16,
    height: 16,
  },
  buttonStyle1: {
    border: "1px solid #1891BD",
    borderRadius: 5,
    color: "white",
    height: 40,
    padding: "0 15px",
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    outline: "none",
    float: "right",
    "&:hover": {
      background: "#1891BD",
    },
  },
  performenceNameText: {
    font: "normal normal normal 10px/16px Poppins",
    color: "#959595",
    opacity: 1,
  },
  performencePercentageText: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  overAllText: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#959595",
    opacity: 1,
  },
  menuItemText: {
    font: "normal normal normal 12px/25px Poppins",
    color: "#FFFFFF",
    opacity: 1,
    paddingLeft: 10,
  },
  removeButtonStyle: {
    font: "normal normal normal 14px/26px Poppins",
    border: "1px solid #1891BD",
    borderRadius: 5,
    color: "#000",
    height: 40,
    padding: "0 15px",
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    outline: "none",
    "&:hover": {
      background: "#1891BD",
      color: "#FFFFFF",
    },
  },
  deleteButtonStyle: {
    background: "#1891BD",
    borderRadius: 5,
    color: "white",
    height: 40,
    padding: "0 15px",
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    font: "normal normal normal 14px/26px Poppins",
    "&:hover": {
      border: "1px solid #1891BD",
      color: "#000",
    },
  },
});
export default Styles;
