import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { /*aper,*/ Grid } from '@material-ui/core';
import ReactFileReader from 'react-file-reader';
import { withStore } from '../../component/hooks/store';
import DatePicker from 'react-date-picker';
import { doPostFormData, checkNullAndReturnString, doGetConnect, ValidateEmail/*, doPostConnect*/ } from '../../helper/common';
import { toast } from 'react-toastify';
import Config from '../../helper/config';
import NxtPick from "../../images/nxtpick-logo.png";
import CalenderPng from "../../images/calendar.png";
import Styles from "../../styles/signup.js";
import Button from "../../component/button";
import Input from "../../component/input";
import SelectBox from "../../component/selectBox";
import Checkbox from 'rc-checkbox';
import { Multiselect } from 'multiselect-react-dropdown';
// import MuiPhoneInput from "material-ui-phone-number";
// import PhoneInput from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "../../cssstyle/manage/signup.css";
import 'react-phone-number-input/style.css';
import Loading from "../../component/loading";

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            sport: 0,
            gender: 0,
            from: 1,
            serviceOffered: null,
            dateOfBirth: null,
            matchingProfile: false,
            thatsMe: false,
            matchFound: false,
            firstName: "",
            lastName: "",
            position: 0,
            school: 0,
            selectSchool: [],
            stateList: [],
            selectState: [],
            selectStateAgent: [],
            schoolList: [{ label: "Select your school team", value: 0 }],
            positionList: [{ label: "Select your position for football", value: 0 }],
            certificate: [],
            certificateName: [],
            servicesList: [],
            classList: [{ label: "Select your class", value: 0 }],
            classId: 0,
            checked: false,
            fildPlayerData: [],
            playerProfileId: "",
            playerProfileJson: {},
            personId: "",
            phone: "",
            countryCode: "",
            agentCountryCode: "",
            email: "",
            secondryEmail: "",
            city: "",
            state: "",
            heightInches: "",
            heightFeet: "",
            weight: "",
            agencyFirstName: "",
            agencyLastName: "",
            agencyName: "",
            agencyEmail: "",
            education: "",
            agentPhone: "",
            experience: "",
            agencyCity: "",
            agencyState: "",
            checked1: false,
            loading: false
        }
    }

    onSelect = (selectedList, selectedItem) => {
        this.setState({ serviceOffered: selectedList })
    }

    onRemove = (selectedList, removedItem) => {
        this.setState({ serviceOffered: selectedList })
    }

    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.getSchoolAndPosition();
        var params = this.props.match.params;
        if (checkNullAndReturnString(params)) {
            if (checkNullAndReturnString(params.type)) {
                if (params.type === "agent") {
                    this.setState({ from: 2 })
                }
            }
        }
    }

    getSchoolAndPosition = async () => {
        // var responseData = await doGetConnect("school")
        // if (checkNullAndReturnString(responseData)) {
        //     if (checkNullAndReturnString(responseData.data)) {
        //         var schoolData = [{ label: "Select your school team", value: 0 }];
        //         var responseSchoollData = JSON.parse(JSON.stringify(responseData.data).split('"id":').join('"value":'));
        //         responseSchoollData = JSON.parse(JSON.stringify(responseSchoollData).split('"school_name":').join('"label":'));
        //         var schoolList = schoolData.concat(responseSchoollData);
        //         this.setState({ schoolList })
        //     }
        // }
        var responseData = await doGetConnect("school")
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data)) {
                // var schoolData = [{ label: "Select your school team", value: 0 }];
                var responseSchoollData = JSON.parse(JSON.stringify(responseData.data).split('"id":').join('"id":'));
                responseSchoollData = JSON.parse(JSON.stringify(responseSchoollData).split('"school_name":').join('"name":'));
                var schoolList = responseSchoollData;
                this.setState({ schoolList })
            }
        }
        var responseData4 = await doGetConnect("master-data/states")
        if (checkNullAndReturnString(responseData4)) {
            var responseStateData = JSON.parse(JSON.stringify(responseData4).split('"id":').join('"id":'));
            responseStateData = JSON.parse(JSON.stringify(responseStateData).split('"state":').join('"name":'));
            var stateList = responseStateData;
            this.setState({ stateList })
        }
        var responseData1 = await doGetConnect("school/position")
        if (checkNullAndReturnString(responseData1)) {
            if (checkNullAndReturnString(responseData1.data)) {
                var positionList = [{ label: "Select your position for football", value: 0 }];
                Object.keys(responseData1.data).forEach(key => {
                    positionList.push({
                        label: responseData1.data[key].name,
                        value: responseData1.data[key].position })
                })
                this.setState({ positionList })
            }
        }
        var responseData2 = await doGetConnect("master-data/agent/services")
        if (checkNullAndReturnString(responseData2)) {
            var servicesList = [];
            Object.keys(responseData2).forEach(key => {
                servicesList.push({ id: responseData2[key].id, name: responseData2[key].service_name })
            })
            this.setState({ servicesList })
        }
        var responseData3 = await doGetConnect("school/class")
        if (checkNullAndReturnString(responseData3)) {
            var classList = [{ label: "Select your class", value: 0 }];
            Object.keys(responseData3).forEach(key => {
                classList.push({ label: responseData3[key].class, value: responseData3[key].class })
            })
            this.setState({ classList })
        }
    }

    renameKey(obj, oldKey, newKey) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    handleChange = (id, value) => {
        if (id === "heightInchess") {
            value = parseInt(value) || 0;
            if (value < 12) {
                this.setState({
                    [id]: value
                })
            }
        }
        else if (id === "heightFeet") {
            value = parseInt(value) || 0;
            if (value < 11) {
                this.setState({
                    [id]: value
                })
            }
        }
        else {
            this.setState({
                [id]: (value ? value : '')
            });
        }
    }

    onChangeDatePicker = (dateOfBirth) => {
        this.setState({ dateOfBirth })
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    findMyProfile = async () => {        
        this.setState({ thatsMe: false, matchingProfile: false, matchFound: false, fildPlayerData: [] })
        if (await this.validateSignUp("player")) {
            var {
                // sport, dateOfBirth, phone, email, secondryEmail, city, state, heightFeet,weight, gender, heightInchess, schoolList, positionList,
                classId, firstName, school, lastName, position
            } = this.state;
            firstName = firstName.trim();
            lastName = lastName.trim();
            // const name = firstName.concat("lastname")
            // var responseData = await doGetConnect("player-statistics/findprofile?firstName=" + firstName + "&lastName=" + lastName + "&schoolId=" + school + "&class=" + classId + "&position=" + position + "&academicYear=&skip=&take=&sortby=team&order=desc")
            var responseData = await doGetConnect("player-statistics/findprofile?firstName=" + firstName + "&lastName=" + lastName + "&schoolId=" + school + "&class=" + classId + "&position=" + position + "&academicYear=&skip=&take=&sortby=team&order=desc")
            if (checkNullAndReturnString(responseData)) {
                if (checkNullAndReturnString(responseData.data) && Object.keys(responseData.data).length > 0) {
                    this.setState({ matchingProfile: true, matchFound: true, fildPlayerData: responseData.data })
                }
                else {
                    this.setState({ matchingProfile: true })
                }
            }
            else {
                this.setState({ matchingProfile: true })
            }
            this.scrollToBottom();
        }
    }

    handleFiles = (certificate) => {
        const certificatearray = Array.from(certificate);
        const findres = certificatearray
            .some(e => (Math.round((e.size / 1024)) >= 5120));

        // const fsize = certificate[0].size;
        // const file = Math.round((fsize / 1024));
        // The size of the file.

        const NamesArray = [...certificatearray].map(e => e.name);
        if (findres) {
            toast.error("File size is too big, Please upload less than 5mb")
        }
        else {
            this.setState(pre => ({
                ...pre,
                certificate: [...pre.certificate, ...certificatearray],
                certificateName: [...pre.certificateName, ...NamesArray]
            }))
            // this.setState({ certificate: certificatearray })
            // this.setState({ certificateName: NamesArray })
        }
    }

    submitAgency = async () => {
        var { serviceOffered, certificate, agencyFirstName, agencyLastName, agencyName, agencyEmail, education, experience, agencyCity, agencyState, agentPhone, agentCountryCode } = this.state;
        agencyFirstName = agencyFirstName.trim();
        agencyLastName = agencyLastName.trim();
        if (await this.validateSignUp("agent")) {
            this.setState({ loading: true })
            var service = "";
            Object.keys(serviceOffered).forEach(key => {
                service += serviceOffered[key].id + ","
            })
            service = service.replace(/,\s*$/, "");
            var postData = new FormData();
            postData.append("firstName", agencyFirstName)
            postData.append("lastName", agencyLastName)
            postData.append("gender", "male")
            postData.append("roleId", "3")
            postData.append("email", agencyEmail)
            postData.append("adminPrivileges", "")
            postData.append("agencyName", agencyName)
            postData.append("education", education)
            postData.append("phoneNumber", agentPhone)
            postData.append("countryCode", "+" + agentCountryCode)
            postData.append("experience", experience)
            postData.append("serviceOffered", service)
            postData.append("dateOfBirth", "")
            postData.append("secondaryEmail", "")
            postData.append("schoolId", "")
            postData.append("class", "")
            postData.append("playerPosition", "")
            postData.append("heightFeet", "")
            postData.append("heightInches", "")
            postData.append("weight", "")
            postData.append("playerProfileId", "")
            postData.append("city", agencyCity)
            postData.append("state", agencyState)
            postData.append("status", true)
            // postData.append("certificates", certificate);

            if (certificate.length) {
                for (var i = 0; i < certificate.length; i++) {
                    postData.append("certificates", certificate[i], certificate[i].name);
                }
            } else {
                postData.append("certificates", "");
            }

            var responseData = await doPostFormData("users", postData);
            if (checkNullAndReturnString(responseData)) {
                if (checkNullAndReturnString(responseData.message) && responseData.message === "Success") {
                    this.emptyData()
                    this.props.history.push(Config.reactUrl + "/success")
                }
                else {
                    if (checkNullAndReturnString(responseData.error)) {
                        if (checkNullAndReturnString(responseData.error.message)) {
                            toast.error(responseData.error.message);
                        }
                    }
                }
                this.setState({ loading: false })
            }
            else {
                this.setState({ loading: false })
            }
        }
    }

    validateSignUp = async (type) => {
        if (type === "agent") {
            var { serviceOffered, certificate, agencyFirstName, agencyLastName, agencyName, agencyEmail, experience, agencyCity, agencyState, agentPhone, agentCountryCode } = this.state;
            if (!checkNullAndReturnString(agencyFirstName)) {
                toast.error("Please enter first name");
                return false;
            }
            if (!checkNullAndReturnString(agencyLastName)) {
                toast.error("Please enter last name");
                return false;
            }
            if (!checkNullAndReturnString(agencyName)) {
                toast.error("Please enter agency name");
                return false;
            }
            if (!checkNullAndReturnString(agencyEmail)) {
                toast.error("Please enter preferred email address");
                return false;
            }
            if (!ValidateEmail(agencyEmail)) {
                toast.error("Please enter valid email address");
                return false;
            }
            // if (!checkNullAndReturnString(education)) {
            //     toast.error("Please enter education");
            //     return false;
            // }
            if (checkNullAndReturnString(agentPhone)) {
                if (!checkNullAndReturnString(agentCountryCode)) {
                    toast.error("Please select country code");
                    return false;
                }
            }            
            if (!checkNullAndReturnString(experience)) {
                toast.error("Please enter Years Active");
                return false;
            }
            if (!checkNullAndReturnString(agencyCity)) {
                toast.error("Please enter city");
                return false;
            }
            if (!checkNullAndReturnString(agencyState)) {
                toast.error("Please select state");
                return false;
            }
            if (!checkNullAndReturnString(serviceOffered) || Object.keys(serviceOffered).length === 0) {
                toast.error("Please select service offered");
                return false;
            }
            if (!checkNullAndReturnString(certificate)) {
                toast.error("Please upload copy of certification license");
                return false;
            }
            return true
        }
        if (type === "player") {
            const { sport, classId, dateOfBirth, firstName, school, lastName, phone, countryCode, email, /*secondryEmail,*/ city, state, heightFeet,
                weight, /*gender,*/ heightInchess, /*schoolList ,positionList, */ position
            } = this.state;
            if (!checkNullAndReturnString(firstName)) {
                toast.error("Please enter first name");
                return false;
            }
            if (!checkNullAndReturnString(lastName)) {
                toast.error("Please enter last name");
                return false;
            }
            if (!checkNullAndReturnString(sport) || Number(sport) === 0) {
                toast.error("Please select sport");
                return false;
            }
            // if (!checkNullAndReturnString(gender) || gender == 0) {
            //     toast.error("Please select gender");
            //     return false;
            // }
            if (!checkNullAndReturnString(dateOfBirth)) {
                toast.error("Please select date of birth");
                return false;
            }
            if (checkNullAndReturnString(phone)) {
                if (!checkNullAndReturnString(countryCode)) {
                    toast.error("Please select country code");
                    return false;
                }
            }            
            if (!checkNullAndReturnString(email)) {
                toast.error("Email is required");
                return false;
            }
            if (!ValidateEmail(email)) {
                toast.error("Please enter valid email address");
                return false;
            }
            // if(!checkNullAndReturnString(secondryEmail))
            // {
            //     toast.error("Please enter secondry email id");
            //     return false;
            // }
            // if(!ValidateEmail(secondryEmail))
            // {
            //     toast.error("Please enter valid secondry email id");
            //     return false;
            // }
            if (!checkNullAndReturnString(school) || Number(school) === 0) {
                toast.error("Please select school");
                return false;
            }
            if (!checkNullAndReturnString(position) || Number(position) === 0) {
                toast.error("Please select position");
                return false;
            }
            if (!checkNullAndReturnString(city)) {
                toast.error("Please enter city");
                return false;
            }
            if (!checkNullAndReturnString(state)) {
                toast.error("Please select state");
                return false;
            }
            if (!checkNullAndReturnString(heightFeet)) {
                toast.error("Please enter height");
                return false;
            }
            if (!checkNullAndReturnString(heightInchess)) {
                toast.error("Please enter height");
                return false;
            }
            if (!checkNullAndReturnString(weight)) {
                toast.error("Please enter weight");
                return false;
            }
            if (!checkNullAndReturnString(classId) || Number(classId) === 0) {
                toast.error("Please select class");
                return false;
            }
            return true;
        }
    }

    emptyData = async () => {
        this.setState({
            sport: 0,
            gender: 0,
            from: 1,
            serviceOffered: null,
            dateOfBirth: null,
            matchingProfile: false,
            thatsMe: false,
            matchFound: false,
            firstName: "",
            lastName: "",
            position: 0,
            school: 0,
            certificate: null,
            classId: 0,
            checked: false,
            fildPlayerData: [],
            playerProfileId: "",
            playerProfileJson: {},
            personId: "",
            phone: "",
            email: "",
            secondryEmail: "",
            city: "",
            state: "",
            heightInches: "",
            heightFeet: "",
            weight: "",
            agencyFirstName: "",
            agencyLastName: "",
            agencyName: "",
            agencyEmail: "",
            education: "",
            agentPhone: "",
            experience: "",
            agencyCity: "",
            agencyState: "",
            selectSchool: [],
            selectState: [],
            selectStateAgent: [],
            countryCode: "",
            agentCountryCode: "",
        })
    }

    submitFunction = async () => {
        var { classId, dateOfBirth, firstName, school, lastName, phone, countryCode, email, secondryEmail, city, state, heightFeet,
            weight, /*gender,*/ heightInchess, /*schoolList, positionList,*/ position, playerProfileId, personId, playerProfileJson
        } = this.state;
        if (await this.validateSignUp("player")) {
            this.setState({ loading: true })
            var month = [];
            month[0] = "01";
            month[1] = "02";
            month[2] = "03";
            month[3] = "04";
            month[4] = "05";
            month[5] = "06";
            month[6] = "07";
            month[7] = "08";
            month[8] = "09";
            month[9] = "10";
            month[10] = "11";
            month[11] = "12";
            var date = month[dateOfBirth.getMonth()] + "/" + dateOfBirth.getDate() + "/" + dateOfBirth.getFullYear();
            var fName = firstName
            var lName = lastName
            if (checkNullAndReturnString(playerProfileId)) {
                fName = playerProfileJson.first_name
                lName = playerProfileJson.last_name
            }
            fName = fName.trim();
            lName = lName.trim();
            var postData = new FormData();
            postData.append("firstName", fName)
            postData.append("lastName", lName)
            postData.append("phoneNumber", phone)
            postData.append("countryCode", "+" + countryCode)
            postData.append("gender", "male")
            postData.append("roleId", "4")
            postData.append("email", email)
            postData.append("adminPrivileges", "")
            postData.append("agencyName", "")
            postData.append("education", "")
            postData.append("experience", "")
            postData.append("serviceOffered", "")
            postData.append("dateOfBirth", date)
            postData.append("secondaryEmail", secondryEmail)
            postData.append("schoolId", school)
            postData.append("class", classId)
            postData.append("playerPosition", position)
            postData.append("heightFeet", heightFeet)
            postData.append("heightInches", heightInchess)
            postData.append("weight", weight)
            postData.append("playerProfileId", playerProfileId)
            postData.append("playerPersonId", personId)
            postData.append("city", city)
            postData.append("state", state)
            postData.append("status", true)
            postData.append("certificates", "")
            var responseData = await doPostFormData("users", postData);
            if (checkNullAndReturnString(responseData)) {
                if (checkNullAndReturnString(responseData.message) && responseData.message === "Success") {
                    this.emptyData()
                    if (checkNullAndReturnString(playerProfileId)) {
                        this.props.history.push(Config.reactUrl + "/matchingsuccess")
                    }
                    else {
                        this.props.history.push(Config.reactUrl + "/success")
                    }
                    // toast.success("Save successfully");
                }
                else {
                    if (checkNullAndReturnString(responseData.error)) {
                        if (checkNullAndReturnString(responseData.error.message)) {
                            toast.error(responseData.error.message);
                        }
                    }
                }
                this.setState({ loading: false })
            }
            else {
                this.setState({ loading: false })
            }
        }
    }


    removeFile = (value, index) => {
        this.setState(pre => ({
            ...pre,
            certificate: pre.certificate.filter(e => e.name !== value),
            certificateName: pre.certificateName.filter(e => e !== value)
        }))
    }

    render() {
        const { classes } = this.props;
        const { checked1, fildPlayerData, checked, from, dateOfBirth, height, matchingProfile, thatsMe, matchFound, firstName, weight, /*classId,*/ classList,
            servicesList, agencyFirstName, agencyLastName, agencyName, agencyEmail, experience, agencyCity,
            lastName, email, secondryEmail, city, heightFeet, heightInchess, schoolList, positionList
        } = this.state;
        var findData = [];
        if (checkNullAndReturnString(fildPlayerData)) {
            Object.keys(fildPlayerData).forEach(key => {
                var item = fildPlayerData[key]
                findData.push(
                    <div>
                        <div class="row signUpView align-items-center" style={{ paddingTop: 10 }}>
                            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={classes.mediumLabel}>
                                    Player Name
                                </div>
                                <div className={classes.label}>
                                    {item.full_name}
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={classes.mediumLabel}>
                                    Position
                                </div>
                                <div className={classes.label}>
                                    {item.position}
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className={classes.mediumLabel}>
                                    School
                                </div>
                                <div className={classes.label}>
                                    {item.team}
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <a href="#/" className={classes.label} style={{ color: "#1891BD" }} onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ thatsMe: true, playerProfileId: item.id, personId: item.person_id, playerProfileJson: item })
                                }}>
                                    Yes, that's me
                                </a>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        var date = new Date()

        return (
            <Grid container component="main" className={classes.root}>
                {
                    this.state.loading ?
                        <Loading />
                        :
                        ""
                }
                <Grid item xs={false} sm={4} md={4} className={classes.leftCard} >
                    <div className={"h-100 d-flex justify-content-center align-items-center"}>
                        <div class="row justify-content-center align-items-center" >
                            <form class="col-12">
                                <div class="form-group text-center" >
                                    <Link to={"/"} variant="button">
                                        <img src={NxtPick} className={classes.avatar} alt="" />
                                    </Link>
                                </div>
                                <div class="form-group center-block text-center" style={{ padding: 50, paddingTop: 20, paddingBottom: 20 }}>
                                    {Number(from) === 1 ?
                                        <span className={classes.leftText}>
                                            The NXTPICK database will find you based on accurate information submitted here. Be sure to enter your name as it is displayed on your team roster, your class as of the most recent season, and your @edu email address for your university.
                                        </span>
                                        :
                                        <span className={classes.leftText}>
                                            Uploading a copy of your certification ensures NXTPICK is providing a level of security and integrity that players can find peace of mind with. Your account will be approved pending review.
                                        </span>
                                    }
                                </div>
                                <div class="form-group text-center" >
                                    <Button buttonStyle={classes.buttonStyle} buttonName={"Have an account"} onClick={() => this.props.history.push(Config.reactUrl + "/login")} />
                                </div>
                                <div class="form-group text-center" style={{ paddingTop: 20 }}>
                                    <Button buttonStyle={classes.buttonStyle1} buttonName={Number(from) === 1 ? "Sign up as agent" : "Sign up as Player"} onClick={() => Number(from) === 1 ? this.setState({ from: 2 }) : this.setState({ from: 1 })} />
                                </div>
                            </form>
                            <div style={{ position: "fixed", bottom: 20 }}>
                                <span class={classes.copyRightText}>© {date.getFullYear()} Nxtpick, All rights reserved.</span>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={8} md={8} elevation={6} square style={{ height: height, overflowX: "hidden", }}>
                    <div className={classes.paper}>
                        <span class={classes.createAccountText}>Create an account!</span>
                    </div>
                    {
                        Number(from) === 1 ?
                            <div>
                                <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> First Name <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Input from={"signUp"} keyName={"firstName"} value={firstName} onChange={this.handleChange} />
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Last Name <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Input from={"signUp"} keyName={"lastName"} value={lastName} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Sport <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <SelectBox from={true} backgroundColor={"rgb(75 76 92 / 60%)"} onChange={this.handleChange} keyName={"sport"} options={[{ label: "Select your sport", value: 0 }, { label: "Football", value: 1 }]} value={this.state.sport} />
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Class <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <SelectBox from={true} backgroundColor={"rgb(75 76 92 / 60%)"} onChange={this.handleChange} keyName={"classId"} options={classList} value={this.state.classId} />
                                    </div>
                                    {/* <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Gender <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <SelectBox from={true} backgroundColor={"rgb(75 76 92 / 60%)"}
                                            onChange={this.handleChange} keyName={"gender"} options={[{ label: "Select your gender", value: 0 }, { label: "Male", value: 'male' }, { label: "Female", value: 'female' }, { label: "Others", value: 'others' }]} value={this.state.gender} />
                                    </div> */}
                                </div>
                                <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Date of Birth <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <DatePicker
                                            clearIcon={null}
                                            dayPlaceholder={"dd"}
                                            monthPlaceholder={"mm"}
                                            yearPlaceholder={"yyyy"}
                                            format={"M/d/y"}
                                            calendarClassName={"react-calender"}
                                            onChange={this.onChangeDatePicker}
                                            maxDate={new Date()}
                                            value={dateOfBirth}
                                            calendarIcon={
                                                <img src={CalenderPng} style={{ width: 16, height: 18 }} alt="" />
                                            }
                                        />
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Phone </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        {/* <Input from={"signUp"} keyName={"phone"} value={phone} onChange={this.handleChange} />                             */}
                                        {/* <MuiPhoneInput
                                            defaultCountry={'us'}
                                            value={phone}
                                            onChange={(e) => {
                                                this.handleChange('phone', e)
                                            }} /> */}
                                        {/* <PhoneInput
                                            value={phone}
                                            defaultCountry="US"
                                            onChange={(e) => {
                                                alert(e)
                                                this.handleChange('phone', e)
                                            }} /> */}
                                            <PhoneInput
                                            country={'us'}
                                            inputStyle={{ width: "100%",
                                            height: 45,
                                            background: "rgba(75, 76, 92, 0.6)",
                                            border: "1px rgba(75, 76, 92, 0.6)",
                                            color: "white",
                                            opacity: 0.7,
                                         }}
                                         containerStyle={{
                                            background: "rgba(75, 76, 92, 0.6)",
                                            border: "1px rgba(75, 76, 92, 0.6)",
                                         }}
                                            value={this.state.phone}
                                            onChange={(phone, country) => {
                                                this.setState({ phone, countryCode: country.dialCode })}}
                                            />
                                    </div>
                                </div>
                                <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Email Address  <span style={{ color: "#1891BD" }}> * </span>
                                            <span className={classes.smallLabel}> (Should be a *.edu email address provided by the school)</span>
                                        </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Input from={"signUp"} keyName={"email"} value={email} onChange={this.handleChange} />
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Secondary Email Address
                                            {/*<span style={{ color: "#1891BD" }}>*</span>*/}
                                        </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Input from={"signUp"} keyName={"secondryEmail"} value={secondryEmail} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 selectsingleselect">
                                        <span className={classes.label}> School <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Multiselect
                                            style={{display: "flex"}}
                                            // singleSelect={true}
                                            options={schoolList} // Options to display in the dropdown
                                            selectedValues={this.state.selectSchool} // Preselected value to persist in dropdown
                                            onSelect={(e) => {
                                                this.setState({ selectSchool : [e[e.length - 1]], school: e[e.length - 1].id })
                                            }} // Function will trigger on select event
                                            placeholder={this.state.school ? "" : "School"}
                                            onRemove={(e) => {
                                                this.setState({ selectSchool : [], school: '' })
                                            }} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                        {/* <SelectBox from={true} backgroundColor={"rgb(75 76 92 / 60%)"} onChange={this.handleChange} keyName={"school"} options={schoolList} value={this.state.school} /> */}
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Position <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <SelectBox from={true} backgroundColor={"rgb(75 76 92 / 60%)"} onChange={this.handleChange} keyName={"position"} options={positionList} value={this.state.position} />
                                    </div>
                                </div>
                                <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> City <span style={{ color: "#1891BD" }}> * </span></span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Input from={"signUp"} keyName={"city"} value={city} onChange={this.handleChange} />
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 selectsingleselect">
                                        <span className={classes.label}> State <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Multiselect
                                            style={{display: "flex"}}
                                            // singleSelect={true}
                                            options={this.state.stateList} // Options to display in the dropdown
                                            selectedValues={this.state.selectState} // Preselected value to persist in dropdown
                                            onSelect={(e) => {
                                                this.setState({ selectState : [e[e.length - 1]], state: e[e.length - 1].name })
                                            }} // Function will trigger on select event
                                            placeholder={this.state.state ? "" : "State"}
                                            onRemove={(e) => {
                                                this.setState({ selectState : [], state: '' })
                                            }} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                        {/* <Input from={"signUp"} keyName={"state"} value={state} onChange={this.handleChange} /> */}
                                    </div>
                                </div>
                                <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Height <span style={{ color: "#1891BD" }}> * </span>
                                            <span className={classes.smallLabel}> (In Feet & Inches) </span>
                                        </span>
                                        <div class={"row"} style={{ padding: 0 }}>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                                <div style={{ paddingTop: 10 }}></div>
                                                <Input from={"signUp"} type={"number"} divide={true} divideText={"Ft."} keyName={"heightFeet"} value={heightFeet} onChange={this.handleChange} />
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                                <div style={{ paddingTop: 10 }}></div>
                                                <Input from={"signUp"} type={"number"} divide={true} divideText={"In."} keyName={"heightInchess"} value={heightInchess} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Weight <span style={{ color: "#1891BD" }}>*</span>
                                            <span className={classes.smallLabel}> (In LBS) </span>
                                        </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Input from={"signUp"} keyName={"weight"} value={weight} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                    {/* <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Class <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <SelectBox from={true} backgroundColor={"rgb(75 76 92 / 60%)"} onChange={this.handleChange} keyName={"classId"} options={classList} value={this.state.classId} />
                                    </div> */}
                                </div>
                                <div class="row signUpView justify-content-center" style={{ paddingTop: 30, paddingBottom: 30 }}>
                                    <Button buttonStyle={classes.buttonStyle1}
                                        buttonName={"FIND MY PROFILE"} onClick={() => this.findMyProfile()} />
                                </div>
                                {
                                    matchingProfile ?
                                        <div style={{ paddingBottom: 20 }}>
                                            {
                                                matchFound ?
                                                    <div>
                                                        <div class="row signUpView">
                                                            <a href="#/" className={classes.label} style={{ paddingLeft: 15 }} onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({ matchFound: false, thatsMe: false, playerProfileId: "", personId: "", playerProfileJson: {} })
                                                            }}>
                                                                Matching Player Profile
                                                            </a>
                                                        </div>
                                                        {findData}
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                thatsMe ?
                                                    <div>
                                                        <div class="row signUpView" style={{ paddingTop: 20 }}>
                                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <p>
                                                                    <label>
                                                                        <Checkbox
                                                                            name="my-checkbox"
                                                                            onChange={(e) => this.setState({ checked: e.target.checked })}
                                                                            checked={checked}
                                                                            disabled={this.state.disabled}
                                                                        />
                                                                        &nbsp;&nbsp;
                                                                        <span className={classes.label}>I agree to the <a href={"#/"} onClick={()=> this.props.history.push(Config.reactUrl + "/termsofuse")} className={classes.blueLabel}>Terms & Conditions.</a></span>
                                                                    </label>
                                                                    &nbsp;&nbsp;
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row signUpView" style={{ paddingTop: 5 }}>
                                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <Button extraStyle={{ opacity: checked ? 1 : 0.5 }} fullWidth={true} buttonStyle={classes.buttonStyle} buttonName={"Sign up to NxtPick"} onClick={() => checked ? this.submitFunction() : console.log("")} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                !matchFound ?
                                                    <div>
                                                        <div class="row signUpView">
                                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                                                <span className={classes.label} style={{ color: "#959595" }}>Oops! We cannot find you in the database! Review your information.</span>
                                                            </div>
                                                        </div>
                                                        <div class="row signUpView" style={{ paddingTop: 20 }}>
                                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                                                <span className={classes.label}>Request assistance from the NXTPICK team to get registered</span>
                                                            </div>
                                                        </div>
                                                        <div class="row signUpView" style={{ paddingTop: 20 }}>
                                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <p>
                                                                    <label>
                                                                        <Checkbox
                                                                            name="my-checkbox"
                                                                            onChange={(e) => this.setState({ checked: e.target.checked })}
                                                                            checked={checked}
                                                                            disabled={this.state.disabled}
                                                                        />
                                                                        &nbsp;&nbsp;
                                                                        <span className={classes.label}>I agree to the <a href={"#/"} onClick={()=> this.props.history.push(Config.reactUrl + "/termsofuse")} className={classes.blueLabel}>Terms & Conditions.</a></span>
                                                                    </label>
                                                                    &nbsp;&nbsp;
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="row signUpView" style={{ paddingTop: 5 }}>
                                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <Button extraStyle={{ opacity: checked ? 1 : 0.5 }} fullWidth={true} buttonStyle={classes.buttonStyle} buttonName={"Send A Request"} onClick={() => checked ? this.submitFunction() : console.log("")} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        :
                                        null
                                }
                                <div style={{ float:"left", clear: "both" }}
                                    ref={(el) => { this.messagesEnd = el; }}>
                                </div>
                            </div>
                            :
                            <div>
                                <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> First Name <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Input from={"signUp"} keyName={"agencyFirstName"} value={agencyFirstName} onChange={this.handleChange} />
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Last Name <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Input from={"signUp"} keyName={"agencyLastName"} value={agencyLastName} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Agency Name <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Input from={"signUp"} keyName={"agencyName"} value={agencyName} onChange={this.handleChange} />
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Preferred Email <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Input from={"signUp"} keyName={"agencyEmail"} value={agencyEmail} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        {/* <span className={classes.label}> Education <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Input from={"signUp"} keyName={"education"} value={education} onChange={this.handleChange} /> */}
                                        <span className={classes.label}> Phone </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        
                                            <PhoneInput
                                            country={'us'}
                                            inputStyle={{ width: "100%",
                                            height: 45,
                                            background: "rgba(75, 76, 92, 0.6)",
                                            border: "1px rgba(75, 76, 92, 0.6)",
                                            color: "white",
                                            opacity: 0.7,
                                         }}
                                         containerStyle={{
                                            background: "rgba(75, 76, 92, 0.6)",
                                            border: "1px rgba(75, 76, 92, 0.6)",
                                         }}
                                            value={this.state.phone}
                                            onChange={(phone, country) => {
                                                this.setState({ agentPhone : phone, agentCountryCode: country.dialCode })}}
                                            />
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Years Active <span style={{ color: "#1891BD" }}>*</span> <span className={classes.smallLabel}> (In Years)</span></span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Input from={"signUp"} keyName={"experience"} value={experience} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> City <span style={{ color: "#1891BD" }}> * </span></span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Input from={"signUp"} keyName={"agencyCity"} value={agencyCity} onChange={this.handleChange} />
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 selectsingleselect">
                                        <span className={classes.label}> State <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Multiselect
                                            style={{display: "flex"}}
                                            // singleSelect={true}
                                            options={this.state.stateList} // Options to display in the dropdown
                                            selectedValues={this.state.selectStateAgent} // Preselected value to persist in dropdown
                                            onSelect={(e) => {
                                                this.setState({ selectStateAgent : [e[e.length - 1]], agencyState: e[e.length - 1].name })
                                            }} // Function will trigger on select event
                                            placeholder={this.state.agencyState ? "" : "State"}
                                            onRemove={(e) => {
                                                this.setState({ selectStateAgent : [], agencyState: '' })
                                            }} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                        {/* <Input from={"signUp"} keyName={"agencyState"} value={agencyState} onChange={this.handleChange} /> */}
                                    </div>
                                </div>
                                <div class={"row signUpView"} style={{ paddingTop: 20 }}>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Service Offered <span style={{ color: "#1891BD" }}> * </span></span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <Multiselect
                                            options={servicesList} // Options to display in the dropdown
                                            selectedValues={this.state.serviceOffered} // Preselected value to persist in dropdown
                                            onSelect={this.onSelect} // Function will trigger on select event
                                            placeholder="Select services you offer"
                                            onRemove={this.onRemove} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                        {/* <SelectBox backgroundColor={"rgb(75 76 92 / 60%)"} onChange={this.handleChange} keyName={"serviceOffered"} options={servicesList} value={this.state.serviceOffered} /> */}
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <span className={classes.label}> Upload Copy of Certification License <span style={{ color: "#1891BD" }}>*</span> </span>
                                        <div style={{ paddingTop: 10 }}></div>
                                        <ReactFileReader handleFiles={this.handleFiles}
                                            fileTypes={['.jpg', '.jpeg', '.png', '.pdf']}
                                            multipleFiles={true}>
                                            <Button buttonStyle={classes.buttonStyle1} buttonName={"BROWSE"} fullWidth={false}
                                                onClick={() => console.log("browse")} />
                                        </ReactFileReader>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                            {
                                                // checkNullAndReturnString(this.state.certificateName) ?
                                                //     <span className={classes.label}> &nbsp;&nbsp;{this.state.certificateName} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href={"javascript:void(0)"} class={classes.blueLabel} onClick={() => this.setState({ certificate: null, certificateName: "" })}>Remove</a></span>
                                                //     :
                                                //     ""
                                                this.state.certificateName.map((e, i) => (
                                                    <React.Fragment key={i}>
                                                        <span className={classes.label} style={{ width: '100%' }}> &nbsp;&nbsp;{e} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="#/" class={classes.blueLabel} style={{ float: 'right' }} onClick={(e) => { e.preventDefault(); this.removeFile(e, i) }}>Remove</a></span>
                                                    </React.Fragment>
                                                ))
                                            }
                                        </div>
                                        <div className={classes.smallLabel} style={{ paddingTop: 5 }}>
                                            Max. file size 5 MB, Only *PDF, *JPG, *PNG, *TIFF File Format
                                        </div>
                                    </div>
                                </div>
                                <div class="row signUpView" style={{ paddingTop: 30 }}>
                                    <p style={{ paddingLeft: 20 }}>
                                        <label>
                                            <Checkbox
                                                name="my-checkbox"
                                                onChange={(e) => this.setState({ checked1: e.target.checked })}
                                                checked={checked1}
                                            />
                                            &nbsp;&nbsp;
                                            <span className={classes.label}>I agree to the <a href={"#/"} onClick={()=> this.props.history.push(Config.reactUrl + "/termsofuse")} className={classes.blueLabel}>Terms & Conditions.</a></span>
                                        </label>
                                        &nbsp;&nbsp;
                                    </p>
                                </div>
                                <div class="row signUpView justify-content-center" style={{ paddingTop: 30, paddingBottom: 30 }}>
                                    <Button buttonStyle={classes.buttonStyle2} extraStyle={{ opacity: checked1 ? 1 : 0.5 }} buttonName={"SUBMIT"} onClick={() => checked1 ? this.submitAgency() : console.log()} />
                                </div>
                            </div>
                    }
                </Grid>
            </Grid>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(SignUp))