import React from "react";
import { Route, Redirect } from "react-router-dom";
import config from "../../helper/config";

const PrivateRoute: React.FC<{
    component: React.FC;
    path: string;
    exact: boolean;
}> = (props) => {

    const condition = localStorage.getItem("accessToken");
    const roleId = localStorage.getItem("roleId");

    return condition && Number(roleId) === 3 ? (<Route path={props.path} exact={props.exact} component={props.component} />) :
        (<Redirect to={config.reactUrl + "/"} />);
};
export default PrivateRoute;

// export const PrivateRoute = ({ component: Component, ...rest }) => {
//     <Route 
//         {...rest}
//         render={props=> 
//             localStorage.getItem("accessToken") ?
//                 <Component  {...props} />    
//             :        
//             <Redirect to={{
//                 pathname : config.reactUrl+"/login"
//             }} /> 
//         }     
//     />
// }