const Styles = theme => ({
    appBar: {
        borderBottom: "1px solid #3B3E50",
        background: "#262834",
        paddingBottom: 5,
        paddingTop: 5
    },
    vname: {
        color: '#ffffff',
        paddingTop: '0.51rem',
        fontWeight: '600',
        fontSize: '13px',
        cursor: 'pointer'
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    notification: {
        width: 10,
        height: 12,
        background: "transparent url('img/Path 302.png') 0% 0% no-repeat padding-box",
        opacity: 1
    },
    message: {
        width: 17,
        height: 12,
        background: "transparent url('img/Path 302.png') 0% 0% no-repeat padding-box",
        opacity: 1
    },
    circle: {
        width: 40,
        height: 40,
        border: "1px solid #3B3E50",
        opacity: 1,
        borderRadius: 40 / 2,
        textAlign: "center",
        display: "flex",
        // textAlign: "center",
        justifyContent: "center",
        alignItems: "center"
    },
    nameCircle: {
        width: 40,
        height: 40,
        background: "#1891BD 0% 0% no-repeat padding-box",
        opacity: 1,
        borderRadius: 40 / 2,
        display: "flex",
        textAlign: "center",
        justifyContent: "center"
    },
    circleText: {
        textAlign: "center",
        font: "normal normal normal 14px/19px Poppins",
        letterSpacing: 0,
        color: "#FFFFFF",
        opacity: 1,
        marginTop: 10,
        padding: 0,
        margin: 0
    },
    fullNameText: {
        textAlign: "left",
        font: "normal normal normal 14px/19px Poppins",
        letterSpacing: 0.3,
        color: "#FFFFFF",
        opacity: 0.8,
    },
    userTypeText: {
        textAlign: "left",
        font: "normal normal normal 12px/18px Poppins",
        letterSpacing: 0,
        color: "#959595",
        opacity: 0.8,
        padding: 0,
        margin: 0
    },
    menu: {
        width: 35,
        height: 35,
    },
    menuItemText: {
        font: "normal normal normal 12px/25px Poppins",
        color: "#FFFFFF",
        opacity: 1,
        paddingLeft: 10
    },
    buttonStyle: {
        background: '#1891BD',
        borderRadius: 5,
        color: 'white',
        height: 40,
        padding: '0 15px',
        opacity: 1,
        fontSize: 14,
        textTransform: "uppercase",
        "&:hover": {
            border: "1px solid #1891BD",
            color: '#000',
        },
    },
    buttonStyle1: {
        border: "1px solid #1891BD",
        borderRadius: 5,
        color: '#000',
        height: 40,
        padding: '0 15px',
        opacity: 1,
        fontSize: 14,
        textTransform: "uppercase",
        outline: "none",
        "&:hover": {
            background: "#1891BD",
            color: '#fff',
        },
    },
    badge: {
        position: "absolute",
        background: "#1891bd",
        borderRadius: 10,
        color: "#fff",
        top: 12,
        marginLeft: -2,
        fontSize: 10,
        width: 15,
        height: 15,
        textAlign: "center"
    }
});
export default Styles;