import React, { Component } from "react";
import TextInput from "react-textinput-field";
import Button from "./button";
import eyePng from "../images/eye.png";
// import TickPng from "../images/tick.png";
import {
    checkNullAndReturnString,
    doPostConnectSecurity,
} from "../helper/common";
import { toast } from "react-toastify";
import Bluetick from "../images/blue-tick.png";
import Greentick from "../images/green-tick.png";
import Checkbox from "@material-ui/core/Checkbox";

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eye: true,
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            currentpasswordStrength: 0,
            newpasswordStrength: 0,
            confirmpasswordStrength: 0,
            validateerror: {},
            pwdstrength: {
                uppercase: false,
                lowercase: false,
                number: false,
                symbols: false,
                length: false,
            },
        };
    }

    validate = () => {
        let values = { ...this.state };
        let errors = {};

        const validatepasswordstrength = (value) => {
            let passwordstrength = 0;
            if (/^(?=.*[a-z]).*$/gim.test(value)) {
                passwordstrength = passwordstrength + 1;
            }
            if (/^(?=.*[A-Z])(?=.*[a-z]).*$/gm.test(value)) {
                passwordstrength = passwordstrength + 1;
            }
            if (/^(?=.*[0-9]).*$/gi.test(value)) {
                passwordstrength = passwordstrength + 1;
            }
            if (/^(?=.*[!@#$%^&*~|]).*$/gi.test(value)) {
                passwordstrength = passwordstrength + 1;
            }
            if (!/^.{8,24}$/gim.test(value) && passwordstrength !== 0) {
                passwordstrength = passwordstrength - 1;
            }
            return passwordstrength;
        };

        if (!values.currentPassword) {
            errors["currentPassword"] = "Password is required";
        } else {
            let currentpasswordstrength = validatepasswordstrength(
                values.currentPassword
            );
            this.setState({ currentpasswordStrength: currentpasswordstrength });
            if (currentpasswordstrength !== 4) {
                errors["currentPassword"] = "current password is not valid";
            }
        }

        if (!values.newPassword) {
            errors["newPassword"] = "New password is required";
            this.setState({
                pwdstrength: {
                    uppercase: false,
                    lowercase: false,
                    number: false,
                    symbols: false,
                    length: false,
                },
            });
        } else {
            if (Object.is(values.newPassword, values.currentPassword)) {
                errors["newPassword"] = "New and current password not to be same";
            } else {
                let passwordstrength = validatepasswordstrength(values.newPassword);
                this.setState({ newpasswordStrength: passwordstrength });
                if (passwordstrength !== 4) {
                    errors["newPassword"] = "New password is not valid";
                }

                let uppercase = false,
                    lowercase = false,
                    number = false,
                    symbols = false,
                    length = false;

                if (/^(?=.*[a-z]).*$/gm.test(values.newPassword)) {
                    lowercase = true;
                } else {
                    lowercase = false;
                }
                if (/^(?=.*[A-Z]).*$/gm.test(values.newPassword)) {
                    uppercase = true;
                } else {
                    uppercase = false;
                }
                if (/^(?=.*[0-9]).*$/gi.test(values.newPassword)) {
                    number = true;
                } else {
                    number = false;
                }
                if (/^(?=.*[!@#$%^&*~|]).*$/gi.test(values.newPassword)) {
                    symbols = true;
                } else {
                    symbols = false;
                }
                if (/^.{8,24}$/gim.test(values.newPassword)) {
                    length = true;
                } else {
                    length = false;
                }
                this.setState((pre) => ({
                    pwdstrength: {
                        ...pre.pwdstrength,
                        uppercase,
                        lowercase,
                        number,
                        symbols,
                        length,
                    },
                }));
            }
        }

        if (!values.confirmPassword) {
            errors["confirmPassword"] = "Confirm password is required";
        } else if (values.newPassword && values.confirmPassword) {
            if (!Object.is(values.newPassword, values.confirmPassword)) {
                errors["confirmPassword"] = "New and confirm password should be same";
                this.setState({ confirmpasswordStrength: 0 });
            } else {
                let passwordstrength = validatepasswordstrength(values.confirmPassword);
                this.setState({ confirmpasswordStrength: passwordstrength });
            }
        }
        // return errors;
        this.setState({ validateerror: errors });
    };

    updateInputValue = (id, value) => {
        this.setState(
            {
                [id]: value,
            },
            this.validate
        );
    };

    emptyData = () => {
        this.setState({
            eye: true,
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            currentpasswordStrength: 0,
            newpasswordStrength: 0,
            confirmpasswordStrength: 0,
            pwdstrength: {
                uppercase: false,
                lowercase: false,
                number: false,
                symbols: false,
                length: false,
            },
        });
    };

    toastervalidation = () => {
        let errvalue = { ...this.state.validateerror };
        let returnstate = true;
        var { currentPassword, newPassword, confirmPassword } = this.state;

        if (!currentPassword) {
            errvalue["currentPassword"] = "Current password is required";
        }
        if (!newPassword) {
            errvalue["newPassword"] = "New password is required";
        }
        if (!confirmPassword) {
            errvalue["confirmPassword"] = "Confirm password is required";
        }

        Object.keys(errvalue).map((e, i) => {
            if (i === 0) {
                returnstate = false;
                toast.error(errvalue[e]);
            }
            return e;
        });
        return returnstate;
    };

    submitFunction = async () => {
        var { currentPassword, newPassword, confirmPassword } = this.state;

        if (checkNullAndReturnString(currentPassword)) {
            if (checkNullAndReturnString(newPassword)) {
                if (newPassword === confirmPassword) {
                    var accessToken = localStorage.getItem("accessToken");
                    var postJson = {
                        currentPassword: currentPassword,
                        newPassword: newPassword,
                        confirmPassword: confirmPassword,
                    };
                    var responseData = await doPostConnectSecurity(
                        "password/change",
                        postJson,
                        accessToken
                    );
                    if (checkNullAndReturnString(responseData)) {
                        if (checkNullAndReturnString(responseData.password)) {
                            toast.success("Password has been updated successfully");
                            this.emptyData();
                        } else {
                            if (checkNullAndReturnString(responseData.error)) {
                                if (checkNullAndReturnString(responseData.error.message)) {
                                    toast.error(responseData.error.message);
                                }
                            }
                        }
                    }
                } else {
                    toast.error("New password and confirm password does not match");
                }
            } else {
                toast.error("Please enter new password");
            }
        } else {
            toast.error("Please enter old password");
        }
    };

    passwordStrengthName = () => {
        let val = this.state.newpasswordStrength;
        if (val === 0) {
            return "None";
        } else if (val === 1) {
            return "Weak";
        } else if (val === 2) {
            return "Weak";
        } else if (val === 3) {
            return "Normal";
        } else if (val === 4) {
            return "Strong";
        }
    };

    getStrengthColor = () => {
        let val = this.state.newpasswordStrength;
        if (val === 0 || val === 1 || val === 2) {
            return "red";
        } else if (val === 3) {
            return "yellow";
        } else if (val === 4) {
            return "#3FCB57";
        }
    };

    render() {
        var { eye } = this.state;
        return (
            <div style={{ textAlign: "center" }} class="Password">
                <span
                    style={{
                        color: "#FFFFFF",
                        font: "normal normal medium 18px/19px Poppins",
                    }}
                >
                    CHANGE PASSWORD
                </span>
                <div style={{ paddingTop: 50 }}></div>
                <div class={"changePasswordInput"}>
                    {this.state.currentpasswordStrength === 4 ? (
                        <img
                            src={Greentick}
                            alt=""
                            className={this.props.eyePng}
                            style={{ cursor: "pointer" }}
                        />
                    ) : null}
                    <TextInput
                        attr={{
                            name: "currentPassword",
                            type: "password",
                            value: this.state["currentPassword"] || "",
                            required: true,
                            autoComplete: "off",
                            style: {
                                background: "#32343f",
                                font: "normal normal normal 14px/21px Poppins",
                                letterSpacing: 0,
                                color: "#fff",
                                height: 70,
                                outline: "none",
                                border: "none",
                                paddingLeft: 30,
                                paddingRight: 30,
                                borderRadius: 5,
                            },
                        }}
                        updateInputValue={this.updateInputValue}
                        label={"Current Password"}
                    />
                </div>
                <div style={{ paddingTop: 15 }}></div>
                <div class={"changePasswordInput"}>
                    <img
                        src={eyePng}
                        alt=""
                        className={this.props.eyePng}
                        onClick={() => this.setState({ eye: !eye })}
                    />
                    <TextInput
                        attr={{
                            name: "newPassword",
                            type: eye ? "password" : "text",
                            value: this.state["newPassword"] || "",
                            required: true,
                            autoComplete: "off",
                            style: {
                                background: "#32343f",
                                font: "normal normal normal 14px/21px Poppins",
                                letterSpacing: 0,
                                color: "#fff",
                                height: 70,
                                outline: "none",
                                border: "none",
                                paddingLeft: 30,
                                paddingRight: 50,
                                borderRadius: 5,
                            },
                        }}
                        updateInputValue={this.updateInputValue}
                        label={"New Password"}
                    />
                </div>
                <div style={{ paddingTop: 15 }}></div>
                <div class={"changePasswordInput"}>
                    {this.state.confirmpasswordStrength === 4 ? (
                        <img
                            src={Bluetick}
                            alt=""
                            className={this.props.eyePng}
                            style={{ cursor: "pointer" }}
                        />
                    ) : null}
                    <TextInput
                        attr={{
                            name: "confirmPassword",
                            type: "password",
                            value: this.state["confirmPassword"] || "",
                            required: true,
                            autoComplete: "off",
                            style: {
                                background: "#32343f",
                                font: "normal normal normal 14px/21px Poppins",
                                letterSpacing: 0,
                                color: "#fff",
                                height: 70,
                                outline: "none",
                                border: "none",
                                paddingLeft: 30,
                                paddingRight: 50,
                                borderRadius: 5,
                            },
                        }}
                        updateInputValue={this.updateInputValue}
                        label={"Confirm Password"}
                    />
                </div>
                <div style={{ paddingTop: 15 }}></div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                    }}
                >
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "10px",
                        }}
                    >
                        {/* {
                            this.state.pwdstrength.uppercase
                                ? <img src={Greentick} className={this.props.pwdstatusPng} />
                                : <i className="fa" style={{ fontSize: '20px', color: 'red' }}>&#x2715;</i>
                        } */}
                        <Checkbox
                            style={{ padding: "0px" }}
                            checked={this.state.pwdstrength.uppercase}
                            color="primary"
                            onChange={(e) => e.preventDefault()}
                        />

                        <span
                            style={{
                                fontSize: "14px",
                                color: "#fff",
                                marginLeft: "4px",
                                fontFamily: "Poppins",
                            }}
                        >
                            Upper Case
                        </span>
                    </span>
                    {/* <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px' }}>
                        {
                            this.state.pwdstrength.lowercase
                                ? <img src={Greentick} className={this.props.pwdstatusPng} />
                                : <i className="fa" style={{ fontSize: '20px', color: 'red' }}>&#x2715;</i>
                        }
                        <span style={{ fontSize: '14px', color: '#fff', marginLeft: '4px' }}>Lower Case</span>
                    </span> */}
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "10px",
                        }}
                    >
                        {/* {
                            this.state.pwdstrength.number
                                ? <img src={Greentick} className={this.props.pwdstatusPng} />
                                : <i className="fa" style={{ fontSize: '20px', color: 'red' }}>&#x2715;</i>
                        } */}
                        <Checkbox
                            style={{ padding: "0px" }}
                            checked={this.state.pwdstrength.number}
                            color="primary"
                            onChange={(e) => e.preventDefault()}
                        />
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#fff",
                                marginLeft: "4px",
                                fontFamily: "Poppins",
                            }}
                        >
                            Numbers
                        </span>
                    </span>
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "10px",
                        }}
                    >
                        {/* {
                            this.state.pwdstrength.symbols
                                ? <img src={Greentick} className={this.props.pwdstatusPng} />
                                : <i className="fa" style={{ fontSize: '20px', color: 'red' }}>&#x2715;</i>
                        } */}
                        <Checkbox
                            style={{ padding: "0px" }}
                            checked={this.state.pwdstrength.symbols}
                            color="primary"
                            onChange={(e) => e.preventDefault()}
                        />

                        <span
                            style={{
                                fontSize: "14px",
                                color: "#fff",
                                marginLeft: "4px",
                                fontFamily: "Poppins",
                            }}
                        >
                            Special Characters
                        </span>
                    </span>
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "10px",
                        }}
                    >
                        {/* {
                            this.state.pwdstrength.length
                                ? <img src={Greentick} className={this.props.pwdstatusPng} />
                                : <i className="fa" style={{ fontSize: '20px', color: 'red' }}>&#x2715;</i>
                        } */}
                        <Checkbox
                            style={{ padding: "0px" }}
                            checked={this.state.pwdstrength.length}
                            color="primary"
                            onChange={(e) => e.preventDefault()}
                        />
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#fff",
                                marginLeft: "4px",
                                fontFamily: "Poppins",
                            }}
                        >
                            Min 8 character length
                        </span>
                    </span>
                </div>
                <span
                    style={{
                        color: "#FFFFFF",
                        font: "normal normal normal 12px/18px Poppins",
                    }}
                >
                    Your password strength is
                    <span style={{ color: this.getStrengthColor(), paddingLeft: "5px" }}>
                        {this.passwordStrengthName()}
                    </span>
                </span>
                <div style={{ paddingTop: 30 }}></div>
                <Button
                    buttonStyle={this.props.buttonStyle}
                    buttonName={"UPDATE PASSWORD"}
                    fullWidth={true}
                    onClick={() => {
                        this.toastervalidation() && this.submitFunction();
                    }}
                />
            </div>
        );
    }
}
export default index;
