const Styles = theme => ({        
    heroContent: {
      padding: theme.spacing(8, 0, 6),
    },
    agentCountText: {
      font: "normal normal normal 12px/18px Poppins",
      color: "#959595",
      opacity: 1,
    },
    personImage: {
      width: 40,
      height: 40,
      background: "transparent url('img/Ellipse 37.png') 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 6px #00000029",
      border: "1px solid #3B3E50",
      borderRadius: 10
    },
    border:{ 
      border: "1px solid #3B3E50",
      width: "100%"
    },
    playerName: {
      font: "normal normal normal 12px/18px Poppins",
      color: "#FFFFFF",
    },
    player: {
      font: "normal normal normal 10px/16px Poppins",
      color: "#959595",
    },
    active: {
      font: "normal normal normal 12px/18px Poppins",
      color: "#73B86F",
    },
    inActive: {
      font: "normal normal normal 12px/18px Poppins",
      color: "red",
    },
    link: {
      font: "normal normal normal 14px/21px Poppins",
      color: "#1891BD",
      opacity: 1,
    },
    buttonStyle1: {
      border: "1px solid #1891BD",
      borderRadius: 5,
      color: 'white',
      height: 40,
      padding: '0 15px',
      opacity: 1,
      fontSize: 14,
      textTransform: "uppercase",
      outline: "none"
    },
    buttonStyle2: {
      border: "1px solid #1891BD",
      borderRadius: 5,
      color: '#000',
      height: 46,
      padding: '0 15px',
      fontSize: 14,
      textTransform: "uppercase",
      outline: "none",
      "&:hover": {
        background: "#1891BD",
        color: "white"
      },
    },
    buttonStyle3: {
      background: '#1891BD 0% 0% no-repeat padding-box',
      borderRadius: 5,
      border: 0,
      color: 'white',
      height: 46,
      padding: '0 30px',
      "&:hover": {
        border: "1px solid #1891BD",
        background: "transparent",
        color: '#000',
      },
    },
});
export default Styles;