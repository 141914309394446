import React, { Component } from "react";
import { withStore } from "../../component/hooks/store";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Styles from "../../styles/admin/players";
import Button from "../../component/button";
// import Tables from "../../component/table";
// import ReactFileReader from 'react-file-reader';
import PopOver from "../../component/popOver";

import {
  checkNullAndReturnString,
  doGetConnectSecurity,
  unixTime,
  humanFileSize,
  doPutConnectSecurity,
  formatAMPM,
  dateAndTimeReturn,
} from "../../helper/common";
// import SelectBox from "../../component/selectBox";
import { toast } from "react-toastify";
import Config from "../../helper/config";
import { Modal } from "react-responsive-modal";
import Vimeo from "@u-wave/react-vimeo";
// import { Tooltip } from "@material-ui/core";

class ReviewMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      agentList: [],
      total: 0,
      height: 0,
      userJson: {},
      dataOpen: false,
      innerTotal: 0,
      innerList: [],
      modal: false,
      modalType: "",
      modalId: "",
      sortBy: "",
      order: "asc",
    };
  }

  onOpenModal = () => {
    this.setState({ modal: true });
  };

  onCloseModal = () => {
    this.setState({ modal: false });
  };

  componentDidMount = async () => {
    this.getMediaList(this.state.sortBy, this.state.order);
    this.updateWindowDimensions();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions);
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  changeSatatus1 = async (id, val) => {
    var accessToken = localStorage.getItem("accessToken");
    var postJson = {
      status: val,
    };
    var responseData = await doPutConnectSecurity(
      "gallery/status/" + id,
      postJson,
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.message)) {
        toast.success(responseData.message);
        this.getReviceData(this.state.userJson);
      } else {
        if (checkNullAndReturnString(responseData.error)) {
          if (checkNullAndReturnString(responseData.error.message)) {
            toast.error(responseData.error.message);
          }
        }
      }
    }
  };

  changeStatus = async (id, val, uploadedOn) => {
    var accessToken = localStorage.getItem("accessToken");
    var postJson = {
      status: val,
      datetime: dateAndTimeReturn(uploadedOn),
      userId: id,
    };
    var responseData = await doPutConnectSecurity(
      "gallery/date/status",
      postJson,
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.message)) {
        toast.success(responseData.message);
        this.getMediaList(this.state.sortBy, this.state.order);
      } else {
        if (checkNullAndReturnString(responseData.error)) {
          if (checkNullAndReturnString(responseData.error.message)) {
            toast.error(responseData.error.message);
          }
        }
      }
    }
  };

  getReviceData = async (json) => {
    this.setState({ innerList: [], innerTotal: 0 });
    var accessToken = localStorage.getItem("accessToken");
    var responseData = await doGetConnectSecurity(
      "gallery?id=&userId=" +
      json.user_id +
      "&name=&typeOfFile=&highlight=&skip=&take=&order=&sortby=&datetime=" +
      dateAndTimeReturn(json.uploaded_on),
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      this.setState({ innerTotal: responseData.total });
      if (checkNullAndReturnString(responseData.data)) {
        this.setState({ innerList: responseData.data });
      }
      this.setState({ dataOpen: true });
    }
  };

  getMediaList = async (sortBy, order) => {
    this.setState({ list: [], total: 0 });
    var total = 0;
    var list = [];
    var accessToken = localStorage.getItem("accessToken");
    var responseData = await doGetConnectSecurity(
      "gallery/review?order="+order+"&roleId=4&sortby="+ sortBy,
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      total = checkNullAndReturnString(responseData.total)
        ? parseInt(responseData.total)
        : 0;
      this.setState({ total });
      if (checkNullAndReturnString(responseData.data)) {
        list = responseData.data;
        this.setState({ list });
      }
    }
  };

  render() {
    const { classes } = this.props;
    var {
      //   height,
      list,
      total,
      height,
      dataOpen,
      innerTotal,
      innerList,
    } = this.state;
    var innerContent = [];
    var content = [];
    if (checkNullAndReturnString(list)) {
      Object.keys(list).forEach((key) => {
        var item = list[key];
        content.push(
          <div style={{ paddingTop: 10 }}>
            <div className={classes.border}></div>
            <div class="row" style={{ padding: 0, paddingBottom: 0 }}>
              <div class="col" style={{ maxWidth: 100, paddingTop: 12 }}>
                {checkNullAndReturnString(item.profile_image_hash) ? (
                  <img
                    className={classes.personImage}
                    alt=""
                    src={
                      Config.dataUrl + "aws-files/" + item.profile_image_hash
                    }
                  />
                ) : (
                  <img
                    className={classes.personImage}
                    alt=""
                    src={Config.emptyUser}
                  />
                )}
              </div>
              <div class="col" style={{ paddingTop: 12 }}>
                <span className={classes.reviewPlayerName}>
                  {" "}
                  {item.user_name}{" "}
                </span>
                <br />
                <span className={classes.reviewPlayer}> Football Player </span>
              </div>
              <div class="col" style={{ paddingTop: 12 }}>
                <span className={classes.reviewPlayer}> Photo </span>
                <br />
                <span className={classes.reviewPlayerName}>
                  {" "}
                  {item.total_images} Photos{" "}
                </span>
              </div>
              <div class="col" style={{ paddingTop: 12 }}>
                <span className={classes.reviewPlayer}> Videos </span>
                <br />
                <span className={classes.reviewPlayerName}>
                  {" "}
                  {item.total_videos} Videos{" "}
                </span>
              </div>
              <div class="col" style={{ paddingTop: 12 }}>
                <span className={classes.reviewPlayer}> Total Size </span>
                <br />
                <span className={classes.reviewPlayerName}>
                  {" "}
                  {humanFileSize(item.total_size)}{" "}
                </span>
              </div>
              <div class="col" style={{ paddingTop: 12 }}>
                <span className={classes.reviewPlayer}> Uploaded on </span>
                <br />
                <span className={classes.reviewPlayerName}>
                  {" "}
                  {unixTime(item.uploaded_on)} | {formatAMPM(item.uploaded_on)}
                </span>
              </div>
              <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                <a
                  href="#/"
                  className={classes.reviewLink}
                  onClick={(e) => {
                    e.preventDefault();
                    this.getReviceData(item);
                    this.setState({ userJson: item });
                  }}
                >
                  {" "}
                  View Media{" "}
                </a>
              </div>
              <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                <Link
                  to={
                    Config.reactUrl +
                    "/admin/adminplayerview/admin/" +
                    item.user_id
                  }
                >
                  <a
                    href="#/"
                    className={classes.reviewLink}
                  >
                    {" "}
                    View Profile{" "}
                  </a>
                </Link>
              </div>
              <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"Approve"}
                  fullWidth={false}
                  onClick={() =>
                    this.changeStatus(
                      item.user_id,
                      "approved",
                      item.uploaded_on
                    )
                  }
                />
              </div>
              <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"REJECT"}
                  fullWidth={false}
                  onClick={() =>
                    this.changeStatus(
                      item.user_id,
                      "rejected",
                      item.uploaded_on
                    )
                  }
                />
              </div>
            </div>
          </div>
        );
      });
    }

    if (checkNullAndReturnString(innerList)) {
      Object.keys(innerList).forEach((key) => {
        var item = innerList[key];
        innerContent.push(
          <div style={{ paddingTop: 10 }}>
            <div className={classes.border}></div>
            <div class="row" style={{ padding: 0, paddingBottom: 0 }}>
              <div
                class="col"
                style={{
                  paddingTop: 12,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  color: "#fff",
                }}
              >
                <span className={classes.reviewPlayer}> File Name </span>
                <br />
                <span className={classes.reviewPlayerName}>
                  {" "}
                  {item.file_name} Photos{" "}
                </span>
              </div>
              <div class="col" style={{ paddingTop: 12 }}>
                <span className={classes.reviewPlayer}> File Type </span>
                <br />
                <span className={classes.reviewPlayerName}>
                  {item.type_of_file === "image" ? "Image" : "Video"}
                </span>
              </div>
              <div class="col" style={{ paddingTop: 12 }}>
                <span className={classes.reviewPlayer}> Uploaded on </span>
                <br />
                <span className={classes.reviewPlayerName}>
                  {" "}
                  {unixTime(item.uploaded_at)} | {formatAMPM(item.uploaded_at)}
                </span>
              </div>
              <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                <a
                  href="#/"
                  className={classes.reviewLink}
                  onClick={(e) => {
                    e.preventDefault();
                    this.onOpenModal();
                    this.setState({
                      modalType: item.type_of_file,
                      modalId:
                        item.type_of_file === "image" ? item.id : item.key,
                    });
                  }}
                >
                  {item.type_of_file === "image" ? "View Image" : "View Video"}
                </a>
              </div>
              <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"Approve"}
                  fullWidth={false}
                  onClick={() => this.changeSatatus1(item.id, "approved")}
                />
              </div>
              <div class="col" style={{ alignSelf: "center", paddingTop: 12 }}>
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"REJECT"}
                  fullWidth={false}
                  onClick={() => this.changeSatatus1(item.id, "rejected")}
                />
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <React.Fragment>
        {dataOpen ? (
          <div class="row rowView" style={{ paddingTop: 20 }}>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
              <a
                href="#/"
                className={classes.reviewLink}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    innerTotal: 0,
                    innerList: [],
                    dataOpen: false,
                    userJson: {},
                  });
                  this.getMediaList(this.state.sortBy, this.state.order);
                }}
              >
                Back
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className={classes.studentCountText}>
                {innerTotal} Request Received
              </span>
            </div>
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
              style={{
                height: height / 1.6,
                overflowY: "scroll",
                whiteSpace: "nowrap",
              }}
            >
              {innerContent}
              <div style={{ paddingTop: 10 }}></div>
              <div className={classes.border}></div>
              {Object.keys(innerContent).length === 0 ? (
                <p
                  className={classes.studentCountText}
                  style={{ textAlign: "center" }}
                >
                  <br />
                  No Records Found
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div class="row rowView" style={{ paddingTop: 20 }}>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" >
              <span className={classes.studentCountText}>
                {total} Request Received
              </span>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 " style={{ padding: 10 }}>
                <PopOver 
                  sortby={"Name"}
                  arrayList={[{ label: 'Name', value: 'user_name' }]}
                  onchange={(value)=> {
                    var order = "asc";
                    if (this.state.sortBy === value) {
                        order = "desc";
                    }
                    this.setState({ sortBy: value, order: order });
                    this.getMediaList(value, order)
                  }}
                />
            </div>
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
              style={{
                height: height / 1.6,
                overflowY: "scroll",
                whiteSpace: "nowrap",
              }}
            >
              {content}
              <div style={{ paddingTop: 10 }}></div>
              <div className={classes.border}></div>
              {Object.keys(content).length === 0 ? (
                <p
                  className={classes.studentCountText}
                  style={{ textAlign: "center" }}
                >
                  <br />
                  No Records Found
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        <Modal
          open={this.state.modal}
          onClose={() => this.onCloseModal()}
          center
          showCloseIcon={false}
        >
          <div style={{ padding: 10 }}>
            {this.state.modalType === "image" ? (
              <img
                src={Config.dataUrl + "aws-files/" + this.state.modalId}
                className={"img-responsive"}
                alt=""
                style={{ width: 580, height: 387 }}
              />
            ) : (
              <Vimeo
                video={this.state.modalId}
              // autoplay
              />
            )}
          </div>
          <p style={{ float: "right", paddingRight: 10, paddingLeft: 10 }}>
            <Button
              extraStyle={{ color: "#000" }}
              buttonStyle={classes.buttonStyle1}
              buttonName={"Close"}
              fullWidth={false}
              onClick={() => {
                this.onCloseModal();
              }}
            />
          </p>
        </Modal>
      </React.Fragment>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(withStore(ReviewMedia));
