import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline, Container } from "@material-ui/core";
import { withStore } from "../../component/hooks/store";
import {
  ValidateEmail,
  checkNullAndReturnString,
  doPostConnect,
} from "../../helper/common";
import Config from "../../helper/config";
import NxtPick from "../../images/nxtpick-logo.png";
import UserPng from "../../images/user.png";
// import PasswordPng from "../../images/password.png";
// import eyePng from "../../images/eye.png";
import tickPng from "../../images/tick.png";
import Styles from "../../styles/login.js";
import Footer from "./footer.js";
import TextInput from "react-textinput-field";
import Button from "../../component/button";
import { toast } from "react-toastify";
// import jwt_decode from "jwt-decode";
// import { encrypt, decrypt } from 'react-crypt-gsm';

class FogotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      buttonClick: false,
    };
  }

  updateInputValue = (id, value) => {
    this.setState({
      [id]: value,
    });
  };

  submitFunction = async () => {
    var { userName } = this.state;
    if (checkNullAndReturnString(userName)) {
      if (ValidateEmail(userName)) {
        this.setState({ buttonClick: true });
        var postJson = {
          email: userName,
        };
        var responseData = await doPostConnect("password/forgot", postJson);
        if (
          checkNullAndReturnString(responseData.message) &&
          responseData.message === "success"
        ) {
          toast.success(
            "Reset password link has been send to your registered email"
          );
        } else {
          if (checkNullAndReturnString(responseData.error)) {
            toast.error(responseData.error.message);
          }
        }
        setTimeout(() => this.setState({ buttonClick: false }), 1500);
      } else {
        toast.error("Please enter valid email id");
      }
    } else {
      toast.error("Please enter username");
    }
  };

  render() {
    const { classes } = this.props;
    var { userName /* eye */ } = this.state;
    var userNameValid = false;
    if (ValidateEmail(userName)) {
      userNameValid = true;
    }

    return (
      <div className={classes.sectionStyle}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Link to={"/"} variant="button">
              <img src={NxtPick} alt="nxtpicklogo" className={classes.avatar} />
            </Link>
            <span className={classes.loginFont}>
              Yo! Forgot your Password ?
            </span>
            <span className={classes.smallFont} style={{ paddingTop: 10 }}>
              Enter your email and we'll send you a link to reset your password
            </span>
            <form className={classes.form} noValidate>
              <div
                class={"loginInput"}
                style={{
                  borderRadius: 5,
                }}
              >
                <img src={UserPng} alt="" className={classes.userPng} />
                {userNameValid ? (
                  <img src={tickPng} alt="" className={classes.tickPng} />
                ) : null}
                <TextInput
                  attr={{
                    name: "userName",
                    type: "text",
                    value: this.state["userName"] || "",
                    required: true,
                    autoComplete: "off",
                    style: {
                      background: "rgba(0,0,0,0.40)",
                      font: "normal normal normal 14px/21px Poppins",
                      letterSpacing: 0,
                      color: "#fff",
                      height: 70,
                      outline: "none",
                      border: "none",
                      paddingLeft: 50,
                      paddingRight: 45,
                      marginBottom: 0,
                    },
                  }}
                  updateInputValue={this.updateInputValue}
                  label={"Email Address"}
                />
              </div>
              <br />
              <br />
              <Button
                buttonStyle={classes.buttonStyle}
                buttonName={"Send Reset Link"}
                fullWidth={true}
                onClick={this.submitFunction}
                disabled={this.state.buttonClick}
              />
              <p className={classes.forgotPassword}>
                <Link to={Config.reactUrl + "/login"}>
                  <span className={classes.forgotPassword}>
                    {"< Back to Login"}
                  </span>
                </Link>
              </p>
            </form>
          </div>
        </Container>
        <Footer from={"login"} />
      </div>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(
  withStore(FogotPassword)
);
