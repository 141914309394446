const Styles = theme => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  searchText: {
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: 0,
    color: "#FFFFFF",
    opacity: 1,
  },
  input: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: 5,
    opacity: 0.2,
  },
  inputText: {
    outline: "none",
    border: "none",
    background: "transparent",
    opacity: 0.64,
    color: "#fff",
    height: 40,
    font: "normal 300 12px/18px Poppins",
    letterSpacing: 0,
  },
  buttonStyle: {
    background: '#1891BD',
    borderRadius: 5,
    color: 'white',
    height: 40,
    padding: '0 15px',
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    "&:hover": {
      border: "1px solid #1891BD",
      background: "transparent",
    },
  },
  buttonStyle1: {
    border: "1px solid #1891BD",
    borderRadius: 5,
    color: 'white',
    height: 40,
    padding: '0 15px',
    opacity: 1,
    fontSize: 14,
    textTransform: "uppercase",
    outline: "none",
    "&:hover": {
      background: "#1891BD",
      color: "white"
    },
  },
  buttonStyle2: {
    border: "1px solid #1891BD",
    borderRadius: 5,
    color: '#000',
    height: 46,
    padding: '0 15px',
    fontSize: 14,
    textTransform: "uppercase",
    outline: "none",
    "&:hover": {
      background: "#1891BD",
      color: "white"
    },
  },
  buttonStyle3: {
    background: '#1891BD 0% 0% no-repeat padding-box',
    borderRadius: 5,
    border: 0,
    color: 'white',
    height: 46,
    padding: '0 30px',
    "&:hover": {
      border: "1px solid #1891BD",
      background: "transparent",
      color: '#000',
    },
  },
  studentCountText: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#959595",
    opacity: 1,
  },
  table: {
    minWidth: "100%",
    background: "#262834 0% 0% no-repeat padding-box",
  },
  tableHeading: {
    borderBottom: "1px solid #3B3E50",
    font: "normal normal 600 14px/21px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  tableBodyNormal: {
    borderBottom: "1px solid #3B3E50",
    font: "normal normal normal 14px/21px Poppins",
    color: "#FFFFFF",
    opacity: 1,
  },
  tableActive: {
    color: "#73B86F",
    borderBottom: "1px solid #3B3E50",
    font: "normal normal normal 14px/21px Poppins",
    opacity: 1,
  },
  tableView: {
    color: "#1891BD",
    borderBottom: "1px solid #3B3E50",
    font: "normal normal normal 14px/21px Poppins",
    opacity: 1,
  },
  fileName: {
    font: "normal normal normal 14px/21px Poppins",
    color: "#FFFFFF",
  },
  fileNameRemove: {
    font: "normal normal normal 14px/21px Poppins",
    color: "#1891BD",
  },
  personImage: {
    width: 40,
    height: 40,
    background: "transparent url('img/Ellipse 37.png') 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #3B3E50",
    borderRadius: 10
  },
  border: {
    border: "1px solid #3B3E50",
    width: "100%"
  },
  reviewPlayerName: {
    font: "normal normal normal 12px/18px Poppins",
    color: "#FFFFFF",
  },
  reviewPlayer: {
    font: "normal normal normal 10px/16px Poppins",
    color: "#959595",
  },
  reviewLink: {
    font: "normal normal normal 14px/21px Poppins",
    color: "#1891BD",
    opacity: 1,
  },
  recordMatchText: {
    font: "normal normal normal 14px/21px Poppins",
    color: "#959595",
    opacity: 1,
  },
  recordMatchRound: {
    width: 20,
    height: 20,
    background: "#262834 0% 0% no-repeat padding-box",
    border: "1px solid #3B3E50",
    opacity: 1,
    borderRadius: 20 / 2,
    font: "normal normal 600 10px/16px Poppins",
    color: "#FFFFFF",
    padding: 0,
    margin: 0,
    textAlign: "center",
  }
});
export default Styles;