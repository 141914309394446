import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { withStore } from '../../component/hooks/store';
import { withStyles } from '@material-ui/core/styles';
import { Container, CssBaseline } from '@material-ui/core';
import Styles from "../../styles/agent/servicesList";
import Config from "../../helper/config";
import Footer from "../manage/footer";
import Header from "./header";
import Button from "../../component/button";
import BackArrow from "../../images/backArrow.png";

class ServiceList extends Component {

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
                <Header />
                <Container maxWidth="xl" component="main" className={classes.heroContent}>
                    <div className="container-fluid" style={{ paddingBottom: 110, padding: 30, paddingLeft: 50 }}>
                        <div class="row rowView" style={{ paddingTop: 20 }}>
                            <div class="col-12">
                                <Link to={Config.reactUrl + "/agent/dashboard"}>
                                    <img src={BackArrow} alt="backarrow" style={{ width: 31, height: 31 }} />
                                    <span href="#/" style={{
                                        font: "normal normal normal 12px/18px Poppins",
                                        color: "#959595",
                                        paddingLeft: 10,
                                        opacity: 1
                                    }} className={"commonBackClass"}>
                                        Back to Results
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div class="row rowView" style={{ paddingTop: 30 }}>
                            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                <span className={classes.serviceDesription}>Contract Negotiations</span>
                                <br /><br />
                                <span className={classes.serviceDesriptionText}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    <br /><br />
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    <br /><br />
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    <br /><br />
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.
                                    <br /><br />
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.Duis.
                                </span>
                                <br /><br /><br />
                                <Button buttonStyle={classes.buttonStyle1} buttonName={"CONTACT AGENT"} fullWidth={false} onClick={() => { }} />
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <div className={"row"} style={{ paddingTop: 50, paddingLeft: 10, paddingRight: 10 }}>
                                    <div class="col-12">
                                        <img src="https://3.bp.blogspot.com/-LqW8TIlEi8c/WWlrwgY2ofI/AAAAAAAAAQ8/CV5QmeQy2x0_T-OtGQpnJWxEp-CHxG6wwCLcBGAs/s1600/Contract-Management-2.jpg" alt="Management" style={{ width: "100%", height: 360 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <Footer />
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(ServiceList));