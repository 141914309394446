import React, { Component } from "react";
import Footer from "../manage/footer";
import { withStore } from "../../component/hooks/store";
import { withStyles } from "@material-ui/core/styles";
import Header from "./header";
// import { Link } from "react-router-dom";
import Config /* { dataUrl  } */ from "../../helper/config";
import Styles from "../../styles/player/editProfile";
import {
  doGetConnect,
  checkNullAndReturnString,
  doGetConnectSecurity,
  ValidateEmail,
  doPostFormDataSecurity,
  doPutConnectFormDataSecurity,
  doPutConnectSecurity,
  humanFileSize,
  doDeleteConnect,
} from "../../helper/common";
import { Container, CssBaseline } from "@material-ui/core";
import LabelInput from "../../component/labelInput";
import ProgressBar from "../../component/progressBar";
import LabelInputGroup from "../../component/labelInputGroup";
import Button from "../../component/button";
import SelectBox from "../../component/selectBox";
import FacebookWhite from "../../images/facebookWhite.png";
import InstagramWhite from "../../images/instagramWhite.png";
import InWhite from "../../images/inWhite.png";
import TwitterWhite from "../../images/twitterWhite.png";
import TicTocPng from "../../images/tik-tok.png";
import YoutubeWhite from "../../images/youtubeWhite.png";
import { CKEditor } from "ckeditor4-react";
import { toast } from "react-toastify";
import ReactFileReader from "react-file-reader";
import Videoplayer from "../../images/videoplayer.png";
import SearchPng from "../../images/search.png";
import ClosePng from "../../images/close.png";
import { Modal } from "react-responsive-modal";
import Vimeo from "@u-wave/react-vimeo";
import Services from "../../component/services";
import BackOption from "../../component/back";
import Loading from "../../component/loading";
import PhoneInput from "react-phone-input-2";

// const list = [
//     { name: 'item1', type: "video", url: "https://www.sciencemag.org/sites/default/files/styles/article_main_large/public/cc_zumaamericasseven370188_16x9.jpg?itok=zFAXj8q5" },
//     { name: 'item1', type: "image", url: "https://www.guinnessworldrecords.com/Images/First-NFL-player-to-score-postseason-touchdowns-by-rushing-receiving-and-kick-returning-in-one-game-GETTY-IMAGES_tcm25-497235.png" },
//     { name: 'item1', type: "video", url: "https://www.bu.edu/files/2019/10/resized-GettyImages-623075908.jpg" },
//     { name: 'item1', type: "image", url: "https://www.liveabout.com/thmb/W_H4BIjzCpe8rfn4vwjgGE0Dux0=/1884x1413/smart/filters:no_upscale()/GettyImages-83643147-56a314d93df78cf7727bb796.jpg" },
//     { name: 'item1', type: "video", url: "https://media3.s-nbcnews.com/i/newscms/2017_45/2222356/171110-rigoberto-sanchez-latino-1519_f2911c9601c8ef13ba5da9444bd31ede.jpg" },
//     { name: 'item1', type: "video", url: "https://www.sciencemag.org/sites/default/files/styles/article_main_large/public/cc_zumaamericasseven370188_16x9.jpg?itok=zFAXj8q5" },
//     { name: 'item1', type: "image", url: "https://www.guinnessworldrecords.com/Images/First-NFL-player-to-score-postseason-touchdowns-by-rushing-receiving-and-kick-returning-in-one-game-GETTY-IMAGES_tcm25-497235.png" },
//     { name: 'item1', type: "video", url: "https://www.bu.edu/files/2019/10/resized-GettyImages-623075908.jpg" },
//     { name: 'item1', type: "image", url: "https://www.liveabout.com/thmb/W_H4BIjzCpe8rfn4vwjgGE0Dux0=/1884x1413/smart/filters:no_upscale()/GettyImages-83643147-56a314d93df78cf7727bb796.jpg" },
//     { name: 'item1', type: "video", url: "https://media3.s-nbcnews.com/i/newscms/2017_45/2222356/171110-rigoberto-sanchez-latino-1519_f2911c9601c8ef13ba5da9444bd31ede.jpg" },
// ];

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      position: "",
      agencyName: "",
      experience: "",
      primaryEmailId: "",
      game: "",
      dob: "",
      heightFeet: "",
      weight: "",
      content: "",
      heightInchess: "",
      city: "",
      state: "",
      alternateEmailId: "",
      facebook: "",
      twitter: "",
      youtube: "",
      vimo: "",
      ticToc: "",
      inText: "",
      instaText: "",
      userDetails: {},
      profile: null,
      profileImage: "",
      uploadImage: "",
      uploadFileName: "",
      modal: false,
      mediaType: "image",
      mediaTitle: "",
      gallery: null,
      galleryFileName: "",
      galleryData: [],
      hoverId: "",
      modal1: false,
      modalType: "",
      modalId: "",
      modalKey: "",
      highlightsData: [],
      highlightsAdd: false,
      highlightsId: "",
      galleryType: "",
      emptyHightLights: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      modalAddAndRemoveType: true,
      userStorage: 0,
      servicesList: [],
      serviceOffered: [],
      modal2: false,
      highlightModal: false,
      loading: false,
      description: "",
      phone: "",
      countryCode: "",
      show: false,
      confirmModal: false,
      confirmType: "",
    };

    this.dragstartval = null;
    this.dragoverval = null;
  }

  componentDidMount = async () => {
    this.getPlayerProfile();
    this.getGalleryFiles();
    this.getHighlightsFile();
    var responseData = await doGetConnect("master-data/agent/services");
    if (checkNullAndReturnString(responseData)) {
      this.setState({ servicesList: responseData });
    }
  };

  onOpenModal2 = () => {
    this.setState({ modal2: true });
  };

  onCloseModal2 = () => {
    this.setState({ modal2: false });
  };

  onOpenModal1 = () => {
    this.setState({ modal1: true });
  };

  onCloseModal1 = () => {
    this.setState({ modal1: false });
  };

  addAndRemoveHightLights = async (id, status, type) => {
    var accessToken = localStorage.getItem("accessToken");
    var postHightLightData = {
      highlight: status,
    };
    var highlightsData = this.state.highlightsData;
    if (Object.keys(highlightsData).length > 9 && status) {
      toast.error("Already 10 Highlights added...");
      return false;
    }
    if (checkNullAndReturnString(highlightsData) && status) {
      var newFilter = highlightsData.filter(
        (item) => (item.id && item.id.toString()) === (id && id.toString())
      );
      if (
        checkNullAndReturnString(newFilter) &&
        Object.keys(newFilter).length > 0
      ) {
        if (type === "Image") {
          toast.error("This image has been already added to highlights");
        } else {
          toast.error("This video has been already added to highlights");
        }
        return false;
      }
    }
    var responseData = await doPutConnectSecurity(
      "gallery/highlights/" + id,
      postHightLightData,
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (
        checkNullAndReturnString(responseData.message) &&
        responseData.message === "Success"
      ) {
        if (status) {
          toast.success(type + " has been added to highlights successfully");
        } else {
          toast.success(
            type + " has been removed from highlights successfully"
          );
        }
      } else {
        if (checkNullAndReturnString(responseData.error)) {
          if (checkNullAndReturnString(responseData.error.message)) {
            toast.error(responseData.error.message);
          }
        }
      }
      this.setState({ highlightsAdd: false, highlightsId: "" });
      this.getHighlightsFile();
    }
  };

  updateUserDetails = async () => {
    var accessToken = localStorage.getItem("accessToken");
    var id = localStorage.getItem("id");
    var {
      description,
      serviceOffered,
      agencyName,
      experience,
      firstName,
      lastName,
      profile,
      position,
      primaryEmailId,
      /* game, */ dob,
      heightFeet,
      heightInchess,
      weight,
      profileImage,
      userDetails,
      content,
      city,
      state,
      alternateEmailId,
      facebook,
      twitter,
      youtube,
      vimo,
      ticToc,
      inText,
      instaText,
      phone,
      countryCode,
    } = this.state;
    if (checkNullAndReturnString(alternateEmailId)) {
      if (!ValidateEmail(alternateEmailId)) {
        toast.error("Please enter valid alternate email address");
        return false;
      }
    }
    var month = new Array(12);
    month[0] = "01";
    month[1] = "02";
    month[2] = "03";
    month[3] = "04";
    month[4] = "05";
    month[5] = "06";
    month[6] = "07";
    month[7] = "08";
    month[8] = "09";
    month[9] = "10";
    month[10] = "11";
    month[11] = "12";
    var date = "";
    if (checkNullAndReturnString(dob)) {
      date =
        month[dob.getMonth()] + "/" + dob.getDate() + "/" + dob.getFullYear();
    }
    var postData = new FormData();
    postData.append("firstName", firstName);
    postData.append("lastName", lastName);
    postData.append("gender", this.refillJSonData(userDetails, "gender"));
    postData.append("roleId", "3");
    postData.append("email", primaryEmailId);
    postData.append(
      "adminPrivileges",
      this.refillJSonData(userDetails, "admin_privileges")
    );
    postData.append("agencyName", agencyName);
    postData.append("education", this.refillJSonData(userDetails, "education"));
    postData.append("experience", experience);
    postData.append("serviceOffered", serviceOffered);
    postData.append("dateOfBirth", date);
    postData.append("secondaryEmail", alternateEmailId);
    postData.append("playerPosition", position);
    postData.append("heightFeet", heightFeet);
    postData.append("heightInches", heightInchess);
    postData.append("phoneNumber", phone);
    postData.append("countryCode", countryCode);
    postData.append("class", this.refillJSonData(userDetails, "class"));
    postData.append("schoolId", this.refillJSonData(userDetails, "school_id"));
    postData.append("weight", weight);
    postData.append("playerProfileId", "");
    postData.append("city", city);
    postData.append("description", description);
    postData.append("state", state);
    postData.append("status", this.refillJSonData(userDetails, "status"));
    postData.append("biography", content);
    postData.append(
      "socialmedia",
      JSON.stringify([
        { socialMediaType: "facebook", socialMediaUserName: facebook },
        { socialMediaType: "instagram", socialMediaUserName: instaText },
        { socialMediaType: "twitter", socialMediaUserName: twitter },
        { socialMediaType: "v", socialMediaUserName: vimo },
        { socialMediaType: "in", socialMediaUserName: inText },
        { socialMediaType: "youtube", socialMediaUserName: youtube },
        { socialMediaType: "ticToc", socialMediaUserName: ticToc },
      ])
    );
    if (checkNullAndReturnString(profile)) {
    } else {
      postData.append("profileImageHash", profileImage);
    }
    postData.append("profile", profile);
    var responseData = await doPutConnectFormDataSecurity(
      "users/profile/" + id,
      postData,
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.message)) {
        // this.emptyData()
        toast.success("Profile details has been saved successfully");
        setTimeout(() => this.getPlayerProfile(), 5000);
        // this.getPlayerProfile()
      } else {
        if (checkNullAndReturnString(responseData.error)) {
          if (checkNullAndReturnString(responseData.error.message)) {
            toast.error(responseData.error.message);
          }
        }
      }
    }
  };

  onOpenModal = () => {
    this.setState({ modal: true });
  };

  onCloseModal = () => {
    this.setState({ modal: false });
  };

  refillJSonData = (userDetails, key) => {
    if (checkNullAndReturnString(userDetails)) {
      if (checkNullAndReturnString(userDetails[key])) {
        return userDetails[key];
      }
    }
    return "";
  };

  getPlayerProfile = async () => {
    var accessToken = localStorage.getItem("accessToken");
    var id = localStorage.getItem("id");
    var responseData = await doGetConnectSecurity(
      "users?id=" +
        id +
        "&status=&roleId=3&userAccessStatus&name=&city&state&skip=&take=&order=&sortby=",
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.data)) {
        if (checkNullAndReturnString(responseData.data[0])) {
          var json = responseData.data[0];
          this.setDetails(json);
        }
      }
    }
  };

  setDetails = (json) => {
    var facebook = "";
    var instaText = "";
    var twitter = "";
    var vimo = "";
    var ticToc = "";
    var inText = "";
    var youtube = "";
    if (checkNullAndReturnString(json.socialmedia)) {
      var mediaJson = json.socialmedia;
      facebook = JSON.parse(mediaJson[0]).socialMediaUserName;
      instaText = JSON.parse(mediaJson[1]).socialMediaUserName;
      twitter = JSON.parse(mediaJson[2]).socialMediaUserName;
      vimo = JSON.parse(mediaJson[3]).socialMediaUserName;
      inText = JSON.parse(mediaJson[4]).socialMediaUserName;
      youtube = JSON.parse(mediaJson[5]).socialMediaUserName;
      ticToc = mediaJson[6] ? JSON.parse(mediaJson[6]).socialMediaUserName : "";
    }
    this.setState({
      serviceOffered: this.refillJSonData(json, "service_offered"),
      firstName: this.refillJSonData(json, "first_name"),
      lastName: this.refillJSonData(json, "last_name"),
      school: this.refillJSonData(json, "school_id"),
      schoolName: this.refillJSonData(json, "school_name"),
      position: this.refillJSonData(json, "player_position"),
      agencyName: this.refillJSonData(json, "agency_name"),
      experience: this.refillJSonData(json, "experience"),
      primaryEmailId: this.refillJSonData(json, "email"),
      dob: checkNullAndReturnString(this.refillJSonData(json, "date_of_birth"))
        ? new Date(this.refillJSonData(json, "date_of_birth"))
        : "",
      heightFeet: this.refillJSonData(json, "height_feet"),
      heightInchess: this.refillJSonData(json, "height_inches"),
      weight: this.refillJSonData(json, "weight"),
      content: this.refillJSonData(json, "biography"),
      city: this.refillJSonData(json, "city"),
      state: this.refillJSonData(json, "state"),
      phone: this.refillJSonData(json, "phoneNumber"),
      countryCode: this.refillJSonData(json, "countryCode"),
      alternateEmailId: this.refillJSonData(json, "secondary_email"),
      facebook: facebook,
      twitter: twitter,
      youtube: youtube,
      vimo: vimo,
      ticToc: ticToc,
      inText: inText,
      instaText: instaText,
      userDetails: json,
      profileImage: this.refillJSonData(json, "profile_image_hash"),
      description: this.refillJSonData(json, "description"),
      uploadImage: "",
      uploadFileName: "",
      profile: null,
      highlightsAdd: false,
      highlightsId: "",
      userStorage: checkNullAndReturnString(
        this.refillJSonData(json, "used_storage")
      )
        ? this.refillJSonData(json, "used_storage")
        : 0,
      emptyHightLights: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    });
    setTimeout(() => this.setState({ show: true }), 500);
  };

  submitGallery = async () => {
    var { mediaType, gallery, /* galleryFileName */ mediaTitle } = this.state;
    if (checkNullAndReturnString(mediaType) && mediaType !== 0) {
      if (!checkNullAndReturnString(mediaTitle)) {
        toast.error("Please add title");
        return;
      }
      if (checkNullAndReturnString(gallery)) {
        var successText = "Image has been uploaded successfully";
        if (mediaType === "video") {
          successText = "Video has been uploaded successfully";
        }
        this.setState({ loading: true });
        var accessToken = localStorage.getItem("accessToken");
        var postFormData = new FormData();
        postFormData.append("typeOfFile", mediaType);
        postFormData.append("file", gallery);
        postFormData.append("title", mediaTitle);
        postFormData.append("highlight", false);
        postFormData.append("roleId", "3");
        var responseData = await doPostFormDataSecurity(
          "gallery",
          postFormData,
          accessToken
        );
        if (checkNullAndReturnString(responseData)) {
          if (
            checkNullAndReturnString(responseData.message) &&
            responseData.message === "Success"
          ) {
            toast.success(successText);
            this.setState({ mediaType: 0, gallery: null, galleryFileName: "" });
            this.onCloseModal();
            this.getGalleryFiles();
            this.setState({ loading: false });
          } else {
            if (checkNullAndReturnString(responseData.error)) {
              if (checkNullAndReturnString(responseData.error.message)) {
                toast.error(responseData.error.message);
                this.setState({ loading: false });
              }
            }
          }
        } else {
          this.setState({ loading: false });
        }
      } else {
        toast.error("Please upload image");
      }
    } else {
      toast.error("Please select media type");
    }
  };

  onMouseover = (e, galleryType) => {
    this.setState({ hoverId: e, galleryType });
  };

  onMouseout = (e) => {
    this.setState({ hoverId: "", galleryType: "" });
  };

  getHighlightsFile = async () => {
    var accessToken = localStorage.getItem("accessToken");
    var id = localStorage.getItem("id");
    var responseData = await doGetConnectSecurity(
      "gallery/highlights?userId=" + id + "&name=&typeOfFile=",
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.data)) {
        var filterJson = responseData.data.filter((item, index) => {
          if (
            item.type_of_file === "video" &&
            !checkNullAndReturnString(item.video_thumbnail)
          ) {
            item["extraKey"] = index;
            return true;
          }
          return false;
        });
        if (
          checkNullAndReturnString(filterJson) &&
          Object.keys(filterJson).length > 0
        ) {
          for (var i = 0; i <= Object.keys(filterJson).length; i++) {
            if (checkNullAndReturnString(filterJson[i])) {
              if (checkNullAndReturnString(filterJson[i].id)) {
                // var id = filterJson[i].id;
                var responseData1 = await doGetConnect(
                  "gallery/thumbnail?id=" + filterJson[i].id
                );
                if (checkNullAndReturnString(responseData1)) {
                  if (checkNullAndReturnString(responseData1.video_thumbnail)) {
                    responseData.data[filterJson[i].extraKey].video_thumbnail =
                      responseData1.video_thumbnail;
                  }
                }
              }
            }
          }
        }
        this.setState({
          highlightsData: responseData.data,
        });
      }
    }
  };

  getGalleryFiles = async () => {
    var accessToken = localStorage.getItem("accessToken");
    var id = localStorage.getItem("id");
    var responseData = await doGetConnectSecurity(
      "gallery?id=&userId=" +
        id +
        "&name=&typeOfFile=&highlight=&skip=&take=&order=&sortby=&datetime=",
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.data)) {
        var filterJson = responseData.data.filter((item, index) => {
          if (
            item.type_of_file === "video" &&
            !checkNullAndReturnString(item.video_thumbnail)
          ) {
            item["extraKey"] = index;
            return true;
          }
          return false;
        });
        if (
          checkNullAndReturnString(filterJson) &&
          Object.keys(filterJson).length > 0
        ) {
          for (var i = 0; i <= Object.keys(filterJson).length; i++) {
            if (checkNullAndReturnString(filterJson[i])) {
              if (checkNullAndReturnString(filterJson[i].id)) {
                var gId = filterJson[i].id;
                var responseData1 = await doGetConnect(
                  "gallery/thumbnail?id=" + gId
                );
                if (checkNullAndReturnString(responseData1)) {
                  if (checkNullAndReturnString(responseData1.video_thumbnail)) {
                    responseData.data[filterJson[i].extraKey].video_thumbnail =
                      responseData1.video_thumbnail;
                  }
                }
              }
            }
          }
        }
        this.setState({
          galleryData: responseData.data,
        });
      }
    }
  };

  updateInputValue = (id, value) => {
    this.setState({
      [id]: value,
    });
  };

  onChange = (evt) => {
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent,
    });
  };

  handleFiles1 = (gallery) => {
    this.setState({ gallery: gallery[0], galleryFileName: gallery[0].name });
  };

  handleFiles = (profile) => {
    const fsize = profile[0].size;
    const file = Math.round(fsize / 1024);
    // The size of the file.
    if (file >= 5120) {
      toast.error("File size is too big, Please upload less than 5mb");
    } else {
      this.setState({ profile: profile[0] });
      this.setState({
        uploadImage: URL.createObjectURL(profile[0]),
        uploadFileName: profile[0].name,
      });
    }
  };

  setServices = (id, status) => {
    var { serviceOffered } = this.state;
    if (status) {
      serviceOffered.push(id);
    } else {
      serviceOffered = serviceOffered.filter(function (e) {
        return e !== id;
      });
    }
    this.setState({ serviceOffered });
  };

  changeSatatus = async (id, val) => {
    var accessToken = localStorage.getItem("accessToken");
    // var postJson = {
    //     status: val
    // }
    var responseData = await doDeleteConnect(
      "aws-files/remove/" + id,
      accessToken
    );
    if (checkNullAndReturnString(responseData)) {
      if (checkNullAndReturnString(responseData.message)) {
        toast.success(responseData.message);
        this.getGalleryFiles();
        this.onCloseModal1();
        this.getHighlightsFile();
        this.setState({
          confirmModal: false,
          confirmType: "",
        });
      } else {
        if (checkNullAndReturnString(responseData.error)) {
          if (checkNullAndReturnString(responseData.error.message)) {
            toast.error(responseData.error.message);
          }
        }
      }
    }
  };

  onDragOver = (e) => {
    e.preventDefault();
    this.dragoverval = e.target;
  };

  onDragStart = (e) => {
    this.dragstartval = e.target;
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", this.dragstartval);
  };

  onDragEnd = async (e) => {
    const from = this.dragstartval.id;
    const to = this.dragoverval.id;
    if (from && to) {
      let emptyHightLightscpy = [...this.state.highlightsData];
      const fromdata = emptyHightLightscpy[from];
      emptyHightLightscpy = emptyHightLightscpy.filter(
        (e) => e.id !== emptyHightLightscpy[from].id
      );
      emptyHightLightscpy.splice(to, 0, fromdata);
      let emptyHightLightscpy1 = [...emptyHightLightscpy].map((e, i) => {
        return {
          id: e.id,
          order: i + 1,
        };
      });

      var accessToken = localStorage.getItem("accessToken");
      var responseData = await doPutConnectSecurity(
        "gallery/order",
        { list: emptyHightLightscpy1 },
        accessToken
      );
      if (checkNullAndReturnString(responseData)) {
        if (
          checkNullAndReturnString(responseData.message) &&
          responseData.message === "Success"
        ) {
          toast.success("Highlight order updated successfully");
          this.getHighlightsFile();
          this.setState({ highlightsData: emptyHightLightscpy });
        } else {
          if (checkNullAndReturnString(responseData.error)) {
            if (checkNullAndReturnString(responseData.error.message)) {
              toast.error(responseData.error.message);
            }
          }
        }
      }
    }
  };

  render() {
    var { classes } = this.props;
    var {
      description,
      serviceOffered,
      servicesList,
      experience,
      agencyName,
      userStorage,
      modalAddAndRemoveType,
      galleryType,
      galleryData,
      /* schoolName, */ uploadFileName,
      uploadImage,
      firstName,
      lastName,
      /*school, position,*/ primaryEmailId /*game, dob, heightFeet, heightInchess, weight,*/,
      profileImage,
      city,
      state,
      alternateEmailId,
      facebook,
      twitter,
      youtube,
      inText,
      instaText,
      ticToc,
    } = this.state;
    // var player = <div style={{
    //     position: "absolute",
    //     top: "50%",
    //     left: "45%",
    //     transform: "translate(-50%, -50%)"
    // }}>
    //     <img src={Videoplayer} draggable="false" style={{ width: 53, height: 53 }} />
    // </div>
    var serviceData = [];
    if (checkNullAndReturnString(servicesList)) {
      Object.keys(servicesList).forEach((key) => {
        var status = false;
        if (checkNullAndReturnString(serviceOffered)) {
          Object.keys(serviceOffered).forEach((key1) => {
            if (
              (servicesList[key].id && servicesList[key].id.toString()) ===
              (serviceOffered[key1] && serviceOffered[key1].toString())
            ) {
              status = true;
            }
          });
        }
        serviceData.push(
          <div
            class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12 "
            style={{
              padding: 0,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 20,
            }}
          >
            <Services
              name={servicesList[key].service_name}
              from={"editprofile"}
              status={status}
              onClick={this.setServices}
              id={servicesList[key].id}
            />
          </div>
        );
      });
    }
    var ImageContent = [];
    var videoContent = [];
    if (checkNullAndReturnString(galleryData)) {
      Object.keys(galleryData).forEach((key) => {
        var item = galleryData[key];
        if (item.type_of_file === "image") {
          if (item.status === "pending") {
            ImageContent.push(
              <div
                class="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-12"
                style={{
                  height: 135,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  textAlign: "center",
                }}
              >
                <div style={{ height: 123, width: "100%", opacity: 0.5 }}>
                  <img
                    draggable="false"
                    alt=""
                    src={Config.dataUrl + "aws-files/" + item.id}
                    className={classes.imagePlayer}
                  />
                </div>
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className={classes.searchImage}
                >
                  <span className={classes.waitingText}>
                    Waiting for approval
                  </span>
                </a>
                {item?.title && (
                  <div
                    style={{
                      color: "white",
                      position: "absolute",
                      top: "8%",
                      left: "8%",
                      fontSize: 12,
                      opacity: 1,
                    }}
                  >
                    {item?.title}
                  </div>
                )}
              </div>
            );
          } else {
            if (this.state.highlightsAdd) {
              ImageContent.push(
                <div
                  class="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-12"
                  style={{
                    height: 135,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    textAlign: "center",
                  }}
                >
                  <div style={{ height: 123, width: "100%", opacity: 0.5 }}>
                    <img
                      draggable="false"
                      alt=""
                      src={Config.dataUrl + "aws-files/" + item.id}
                      className={classes.imagePlayer}
                    />
                  </div>
                  <a
                    href="#/"
                    className={classes.searchImage}
                    onClick={(e) => {
                      e.preventDefault();
                      this.addAndRemoveHightLights(item.id, true, "Image");
                    }}
                  >
                    <span className={classes.waitingText}>
                      Add to Highlight
                    </span>
                  </a>
                  {item?.title && (
                    <div
                      style={{
                        color: "white",
                        position: "absolute",
                        top: "8%",
                        left: "8%",
                        fontSize: 12,
                        opacity: 1,
                      }}
                    >
                      {item?.title}
                    </div>
                  )}
                </div>
              );
            } else {
              ImageContent.push(
                <div
                  class="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-12"
                  style={{
                    height: 135,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      height: 123,
                      width: "100%",
                      opacity:
                        this.state.hoverId === item.id &&
                        galleryType === "image"
                          ? 0.2
                          : 1,
                    }}
                    onMouseEnter={() => this.onMouseover(item.id, "image")}
                    onMouseLeave={() => this.onMouseout(item.id)}
                  >
                    <img
                      draggable="false"
                      alt=""
                      src={Config.dataUrl + "aws-files/" + item.id}
                      className={classes.imagePlayer}
                    />
                  </div>
                  {this.state.hoverId === item.id && galleryType === "image" ? (
                    <a
                      href="#/"
                      className={classes.searchImage}
                      onMouseEnter={() => this.onMouseover(item.id, "image")}
                      onMouseLeave={() => this.onMouseout(item.id)}
                      onClick={(e) => {
                        e.preventDefault();
                        this.onOpenModal1();
                        this.setState({
                          modalType: item.type_of_file,
                          modalId: item.id,
                          modalKey: "",
                          highlightModal: false,
                          modalAddAndRemoveType: true,
                        });
                      }}
                    >
                      <img
                        alt=""
                        src={SearchPng}
                        className={classes.hoverSerachPng1}
                      />
                    </a>
                  ) : (
                    ""
                  )}
                  {item?.title && (
                    <div
                      style={{
                        color: "white",
                        position: "absolute",
                        top: "8%",
                        left: "8%",
                        fontSize: 12,
                        opacity: 1,
                      }}
                    >
                      {item?.title}
                    </div>
                  )}
                </div>
              );
            }
          }
        } else {
          if (item.status === "pending") {
            videoContent.push(
              <div
                class="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-12"
                style={{
                  height: 135,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  textAlign: "center",
                }}
              >
                <div style={{ height: 123, width: "100%", opacity: 0.5 }}>
                  <img
                    alt=""
                    draggable="false"
                    src={item.video_thumbnail}
                    className={classes.imagePlayer}
                  />
                </div>
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className={classes.searchImage}
                >
                  <span className={classes.waitingText}>
                    Waiting for approval
                  </span>
                </a>
                {item?.title && (
                  <div
                    style={{
                      color: "white",
                      position: "absolute",
                      top: "8%",
                      left: "8%",
                      fontSize: 12,
                      opacity: 1,
                    }}
                  >
                    {item?.title}
                  </div>
                )}
              </div>
            );
          } else {
            if (this.state.highlightsAdd) {
              videoContent.push(
                <div
                  class="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-12"
                  style={{
                    height: 135,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    textAlign: "center",
                  }}
                >
                  <div style={{ height: 123, width: "100%", opacity: 0.5 }}>
                    <img
                      alt=""
                      draggable="false"
                      src={item.video_thumbnail}
                      className={classes.imagePlayer}
                    />
                  </div>
                  <a
                    href="#/"
                    className={classes.searchImage}
                    onClick={(e) => {
                      e.preventDefault();
                      this.addAndRemoveHightLights(item.id, true, "Video");
                    }}
                  >
                    <span className={classes.waitingText}>
                      Add to Highlight
                    </span>
                  </a>
                  {item?.title && (
                    <div
                      style={{
                        color: "white",
                        position: "absolute",
                        top: "8%",
                        left: "8%",
                        fontSize: 12,
                        opacity: 1,
                      }}
                    >
                      {item?.title}
                    </div>
                  )}
                </div>
              );
            } else {
              videoContent.push(
                <div
                  class="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-12"
                  style={{
                    height: 135,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    textAlign: "center",
                  }}
                >
                  <div style={{ height: 123, width: "100%" }}>
                    <img
                      alt=""
                      draggable="false"
                      src={item.video_thumbnail}
                      className={classes.imagePlayer}
                    />
                  </div>
                  <a
                    href="#/"
                    className={classes.videoSearchImage}
                    onClick={(e) => {
                      e.preventDefault();
                      this.onOpenModal1();
                      this.setState({
                        modalType: item.type_of_file,
                        modalId: item.id,
                        modalKey: item.key,
                        highlightModal: false,
                        modalAddAndRemoveType: true,
                      });
                    }}
                  >
                    <img
                      alt=""
                      src={Videoplayer}
                      className={classes.hoverSerachPng2}
                    />
                  </a>
                  {item?.title && (
                    <div
                      style={{
                        color: "white",
                        position: "absolute",
                        top: "8%",
                        left: "8%",
                        fontSize: 12,
                        opacity: 1,
                      }}
                    >
                      {item?.title}
                    </div>
                  )}
                </div>
              );
            }
          }
        }
      });
    }
    var getHighlightsDataContent = [];
    if (checkNullAndReturnString(this.state.emptyHightLights)) {
      Object.keys(this.state.emptyHightLights).forEach((key) => {
        if (checkNullAndReturnString(this.state.highlightsData[key])) {
          var item = this.state.highlightsData[key];
          if (item.type_of_file === "video") {
            getHighlightsDataContent.push(
              <div
                class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12"
                draggable="true"
                onDragEnd={this.onDragEnd}
                onDragStart={this.onDragStart}
                id={key}
                style={{
                  height: 135,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  textAlign: "center",
                }}
              >
                <div style={{ height: 123, width: "100%" }} id={key}>
                  <img
                    alt=""
                    draggable="false"
                    id={key}
                    src={item.video_thumbnail}
                    style={{ width: "100%", height: 123, borderRadius: 10 }}
                  />
                </div>
                <a
                  href="#/"
                  className={classes.videoSearchImage}
                  onClick={(e) => {
                    e.preventDefault();
                    this.onOpenModal1();
                    this.setState({
                      modalType: item.type_of_file,
                      modalId: item.id,
                      modalKey: item.key,
                      highlightModal: true,
                      modalAddAndRemoveType: false,
                    });
                  }}
                >
                  <img
                    alt=""
                    src={Videoplayer}
                    id={key}
                    className={classes.hoverSerachPng2}
                  />
                </a>
                {item?.title && (
                  <div
                    style={{
                      color: "white",
                      position: "absolute",
                      top: "8%",
                      left: "8%",
                      fontSize: 12,
                      opacity: 1,
                    }}
                  >
                    {item?.title}
                  </div>
                )}
              </div>
            );
          } else {
            getHighlightsDataContent.push(
              <div
                class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12"
                draggable="true"
                onDragEnd={this.onDragEnd}
                onDragStart={this.onDragStart}
                id={key}
                style={{
                  height: 135,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    height: 123,
                    width: "100%",
                    opacity:
                      this.state.hoverId === item.id &&
                      galleryType === "highlight"
                        ? 0.2
                        : 1,
                  }}
                  onMouseEnter={() => this.onMouseover(item.id, "highlight")}
                  onMouseLeave={() => this.onMouseout(item.id)}
                  id={key}
                >
                  <img
                    alt=""
                    draggable="false"
                    id={key}
                    src={Config.dataUrl + "aws-files/" + item.id}
                    style={{ width: "100%", height: 123, borderRadius: 10 }}
                  />
                </div>
                {this.state.hoverId === item.id &&
                galleryType === "highlight" ? (
                  <a
                    href="#/"
                    className={classes.searchImage}
                    onMouseEnter={() => this.onMouseover(item.id, "highlight")}
                    onMouseLeave={() => this.onMouseout(item.id)}
                    onClick={(e) => {
                      e.preventDefault();
                      this.onOpenModal1();
                      this.setState({
                        modalType: item.type_of_file,
                        modalId: item.id,
                        modalKey: "",
                        highlightModal: true,
                        modalAddAndRemoveType: false,
                      });
                    }}
                  >
                    <img
                      alt=""
                      src={SearchPng}
                      id={key}
                      className={classes.hoverSerachPng}
                    />
                  </a>
                ) : (
                  ""
                )}
                {item?.title && (
                  <div
                    style={{
                      color: "white",
                      position: "absolute",
                      top: "8%",
                      left: "8%",
                      fontSize: 12,
                      opacity: 1,
                    }}
                  >
                    {item?.title}
                  </div>
                )}
              </div>
            );
          }
        } else {
          if (
            checkNullAndReturnString(this.state.emptyHightLights[key]) &&
            Object.keys(this.state.emptyHightLights[key]).length > 0
          ) {
            getHighlightsDataContent.push(
              <div
                class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12"
                style={{ height: 135, padding: 10 }}
              >
                <div style={{ height: 123, width: "100%" }}>
                  <img
                    alt=""
                    draggable="false"
                    src={
                      Config.dataUrl +
                      "aws-files/" +
                      this.state.emptyHightLights[key].id
                    }
                    style={{ width: "100%", height: 123, borderRadius: 10 }}
                  />
                </div>
              </div>
            );
          } else {
            getHighlightsDataContent.push(
              <div
                class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12"
                style={{ height: 135, padding: 10 }}
              >
                <div
                  style={{
                    height: 123,
                    width: "100%",
                    border: "1px solid #3B3E50",
                    borderRadius: 10,
                  }}
                >
                  <a
                    href="#/"
                    onClick={(e) => {
                      e.preventDefault();
                      if (this.state.highlightsAdd) {
                        if (
                          (this.state.highlightsId &&
                            this.state.highlightsId.toString()) ===
                          (key && key.toString())
                        ) {
                          this.setState({
                            highlightsAdd: false,
                            highlightsId: "",
                          });
                        }
                      } else {
                        this.setState({
                          highlightsAdd: true,
                          highlightsId: key,
                        });
                      }
                    }}
                    className={"editProfilePlusHover"}
                    style={{
                      textAlign: "center",
                      width: 46,
                      height: 46,
                      border: "1px solid #3B3E50",
                      borderRadius: 46 / 2,
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <span
                      className={"editProfilePlusTextHover"}
                      style={{
                        top: 7,
                        position: "relative",
                        fontSize: 20,
                        color: "rgb(59, 62, 80)",
                      }}
                    >
                      {(this.state.highlightsId &&
                        this.state.highlightsId.toString()) ===
                      (key && key.toString()) ? (
                        <span class="red-x" style={{ fontSize: 15 }}>
                          &#10006;
                        </span>
                      ) : (
                        "+"
                      )}
                    </span>
                  </a>
                </div>
              </div>
            );
          }
        }
      });
    }
    var availbleSpace = parseInt((userStorage / 5368706371) * 100);
    return (
      <React.Fragment>
        <CssBaseline />
        {this.state.loading ? <Loading /> : ""}
        <Header from={"Players"} />
        <Container
          maxWidth="xl"
          component="main"
          className={classes.heroContent}
        >
          <div
            className="container-fluid"
            style={{ paddingBottom: 120, paddingTop: 30 }}
          >
            <div class="row rowView">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding">
                <BackOption history={this.props.history} />
              </div>
            </div>
            <div class="row rowView" style={{ paddingTop: 20 }}>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-5 noPadding">
                <span class={classes.editProfileText}>Edit Profile</span>
              </div>
              <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-7 noPadding">
                <div style={{ float: "right" }}>
                  <Button
                    buttonStyle={classes.buttonStyle1}
                    buttonName={"Cancel"}
                    onClick={() => this.onOpenModal2()}
                  />
                  <span style={{ paddingRight: 20 }}></span>
                  <Button
                    buttonStyle={classes.buttonStyle}
                    buttonName={"Save"}
                    onClick={() => this.updateUserDetails()}
                  />
                </div>
              </div>
            </div>
            <div class="row rowView">
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 noPadding"
                style={{ paddingTop: 20 }}
              >
                <span class={classes.basicInfoText}>Basic Information</span>
                <div class="row">
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInput
                      disabled={true}
                      updateInputValue={this.updateInputValue}
                      name={"firstName"}
                      label={"First Name"}
                      value={firstName}
                    />
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInput
                      updateInputValue={this.updateInputValue}
                      name={"experience"}
                      label={"Years Active"}
                      value={experience}
                    />
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInput
                      disabled={true}
                      updateInputValue={this.updateInputValue}
                      name={"lastName"}
                      label={"Last Name"}
                      value={lastName}
                    />
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInput
                      disabled={true}
                      updateInputValue={this.updateInputValue}
                      name={"primaryEmailId"}
                      label={"Primary Email Address"}
                      value={primaryEmailId}
                    />
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInput
                      disabled={true}
                      updateInputValue={this.updateInputValue}
                      name={"agencyName"}
                      label={"Agency"}
                      value={agencyName}
                    />
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInput
                      updateInputValue={this.updateInputValue}
                      name={"alternateEmailId"}
                      label={"Alternate Email Address"}
                      value={alternateEmailId}
                    />
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInput
                      updateInputValue={this.updateInputValue}
                      name={"city"}
                      label={"City"}
                      value={city}
                    />
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInput
                      updateInputValue={this.updateInputValue}
                      name={"state"}
                      label={"State"}
                      value={state}
                    />
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <PhoneInput
                      country={"us"}
                      inputStyle={{
                        width: "100%",
                        height: 45,
                        background: "rgb(68 70 81)",
                        border: "1px rgb(68 70 81)",
                        color: "white",
                        opacity: 0.7,
                      }}
                      containerStyle={{
                        background: "rgba(75, 76, 92, 0.6)",
                        border: "1px rgba(75, 76, 92, 0.6)",
                        borderRadius: 5,
                      }}
                      value={this.state.phone}
                      onChange={(phone, country) => {
                        this.setState({
                          phone,
                          countryCode: "+" + country.dialCode,
                        });
                      }}
                    />
                  </div>
                </div>
                {/* <div class="row" >
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 " style={{ paddingLeft: 20, paddingTop: 20 }}>
                                        <LabelInput updateInputValue={this.updateInputValue} name={"description"} label={"Description"} value={description} />
                                    </div>
                                </div>  */}
                <div class="row">
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <span
                      style={{
                        font: "normal normal normal 10px/16px Poppins",
                        color: "#959595",
                      }}
                    >
                      Status Update
                    </span>
                    <div style={{ paddingTop: 10 }}></div>
                    <LabelInput
                      updateInputValue={this.updateInputValue}
                      name={"description"}
                      label={"Status Update"}
                      value={description}
                    />
                  </div>
                </div>
                {/* <div class="row" >                                                                                                                                                                 
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 " style={{ paddingLeft: 20, paddingTop: 20 }}>
                                        <LabelInput updateInputValue={this.updateInputValue} name={"state"} label={"State"} value={state}/>
                                    </div> 
                                </div> */}
                <div class="row">
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <span
                      style={{
                        font: "normal normal normal 10px/16px Poppins",
                        color: "#959595",
                      }}
                    >
                      Biography
                    </span>
                    <div style={{ paddingTop: 10 }}></div>
                    {this.state.show && (
                      <CKEditor
                        initData={this.state.content}
                        style={{ height: 500 }}
                        onChange={this.onChange}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 rowView"
                style={{ paddingTop: 20 }}
              >
                <span class={classes.basicInfoText}>Profile Picture</span>
                <div class="row">
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <img
                      alt=""
                      className={classes.agentEditImage}
                      src={
                        checkNullAndReturnString(profileImage) &&
                        !checkNullAndReturnString(uploadImage)
                          ? Config.dataUrl + "aws-files/" + profileImage
                          : checkNullAndReturnString(uploadImage)
                          ? uploadImage
                          : Config.emptyUser
                      }
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {checkNullAndReturnString(uploadFileName) ? (
                      <span className={classes.fileText}>{uploadFileName}</span>
                    ) : (
                      ""
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {checkNullAndReturnString(uploadFileName) ? (
                      <a
                        href="#/"
                        className={classes.changeText}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            uploadImage: "",
                            uploadFileName: "",
                          });
                        }}
                      >
                        Change
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <ReactFileReader
                      handleFiles={this.handleFiles}
                      fileTypes={[".png", ".jpg", ".tiff"]}
                    >
                      <Button
                        buttonStyle={classes.buttonStyle1}
                        buttonName={"Browse"}
                        extraStyle={{ paddingLeft: 20, paddingRight: 20 }}
                        fullWidth={false}
                        onClick={() => console.log("browse")}
                      />
                    </ReactFileReader>
                    <p className={classes.fileSizeText}>
                      Max. file size 5 MB, Only *JPG, *PNG, *TIFF File Format
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row rowView" style={{ paddingTop: 20 }}>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding"
                style={{ paddingTop: 20 }}
              >
                <span class={classes.basicInfoText}>Services Offered</span>
                <div class="row" style={{ paddingLeft: 20 }}>
                  {serviceData}
                </div>
              </div>
            </div>
            <div class="row rowView" style={{ paddingTop: 20 }}>
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 noPadding"
                style={{ paddingTop: 20 }}
              >
                <span class={classes.basicInfoText}>Social Pages</span>
                <div class="row">
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInputGroup
                      image={FacebookWhite}
                      imageStyle={{ width: 24, height: 20, marginTop: 15 }}
                      updateInputValue={this.updateInputValue}
                      name={"facebook"}
                      label={"@username"}
                      value={facebook}
                    />
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInputGroup
                      image={TwitterWhite}
                      imageStyle={{ width: 18, height: 20, marginTop: 15 }}
                      updateInputValue={this.updateInputValue}
                      name={"twitter"}
                      label={"@username"}
                      value={twitter}
                    />
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInputGroup
                      image={InstagramWhite}
                      imageStyle={{ width: 21, height: 21, marginTop: 15 }}
                      updateInputValue={this.updateInputValue}
                      name={"instaText"}
                      label={"@username"}
                      value={instaText}
                    />
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInputGroup
                      image={InWhite}
                      imageStyle={{ width: 16, height: 17, marginTop: 15 }}
                      updateInputValue={this.updateInputValue}
                      name={"inText"}
                      label={"@username"}
                      value={inText}
                    />
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInputGroup
                      image={YoutubeWhite}
                      imageStyle={{ width: 21, height: 25, marginTop: 15 }}
                      updateInputValue={this.updateInputValue}
                      name={"youtube"}
                      label={"@username"}
                      value={youtube}
                    />
                  </div>
                  {/* <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 " style={{ paddingLeft: 20, paddingTop: 20 }}>
                                        <LabelInputGroup image={VimeoWhite} imageStyle={{ width: 18, height: 20, marginTop: 15 }} updateInputValue={this.updateInputValue} name={"vimo"} label={"@username"} value={vimo} />
                                    </div> */}
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 "
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <LabelInputGroup
                      image={TicTocPng}
                      imageStyle={{ width: 18, height: 20, marginTop: 15 }}
                      updateInputValue={this.updateInputValue}
                      name={"ticToc"}
                      label={"@username"}
                      value={ticToc}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row rowView">
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding"
                style={{ paddingTop: 20 }}
              >
                <div class="row">
                  <div
                    class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                    style={{
                      paddingLeft: 20,
                      paddingTop: 20,
                      alignSelf: "center",
                    }}
                  >
                    <span class={classes.basicInfoText}>Media Manager</span>
                  </div>
                  <div
                    class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                    style={{ paddingLeft: 20, paddingTop: 20 }}
                  >
                    <Button
                      buttonStyle={classes.buttonStyle1}
                      extraStyle={{ float: "right" }}
                      buttonName={"Add media"}
                      onClick={() => this.onOpenModal()}
                    />
                  </div>
                  <div
                    class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                    style={{
                      paddingLeft: 20,
                      paddingTop: 20,
                      alignSelf: "center",
                      display: "inline-flex",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <span
                        class={classes.basicInfoText}
                        style={{ paddingLeft: 0 }}
                      >
                        Available Space
                      </span>
                    </div>
                    <div style={{ width: "70%" }}>
                      <ProgressBar
                        width={availbleSpace}
                        background={
                          "transparent linear-gradient(90deg, #3B3E50 0%, #43CDFF 100%) 0% 0% no-repeat padding-box"
                        }
                      />
                      <p
                        style={{
                          margin: 0,
                          float: "right",
                          paddingTop: 5,
                          color: "#1891BD",
                          font: "normal normal normal 10px/16px Poppins",
                        }}
                      >
                        {humanFileSize(userStorage)}{" "}
                        <span style={{ color: "#fff" }}>/ 5.00 GB</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row rowView">
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding"
                style={{ paddingTop: 20 }}
              >
                <div
                  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                  style={{ paddingTop: 20 }}
                >
                  <span
                    class={classes.basicInfoText}
                    style={{ paddingLeft: 0 }}
                  >
                    Highlights
                  </span>
                  <div class={"row"} onDragOver={this.onDragOver}>
                    {Object.keys(getHighlightsDataContent).length > 0
                      ? getHighlightsDataContent
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div class="row rowView">
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding"
                style={{ paddingTop: 20 }}
              >
                <div class="row" style={{ paddingTop: 20 }}>
                  <div
                    class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 editProfileGridStyle"
                    style={{
                      paddingLeft: 20,
                      paddingRight: 80,
                      paddingTop: 20,
                    }}
                  >
                    <div class={"row"}>
                      <div
                        className={
                          "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                        }
                      >
                        <span class={classes.basicInfoText}>Image Gallery</span>
                        <span
                          style={{
                            float: "right",
                            font: "normal normal normal 12px/18px Poppins",
                            color: "#959595",
                          }}
                        >
                          {Object.keys(ImageContent).length} Images Uploaded
                        </span>
                      </div>
                    </div>
                    <div class={"row"} style={{ paddingLeft: 20 }}>
                      {Object.keys(ImageContent).length > 0 ? ImageContent : ""}
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 editProfileGridStyle">
                    <div class={"row"}>
                      <div
                        className={
                          "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                        }
                      >
                        <span class={classes.basicInfoText}>Video Gallery</span>
                        <span
                          style={{
                            float: "right",
                            font: "normal normal normal 12px/18px Poppins",
                            color: "#959595",
                          }}
                        >
                          {Object.keys(videoContent).length} Videos Uploaded
                        </span>
                      </div>
                    </div>
                    <div class={"row"} style={{ paddingLeft: 20 }}>
                      {videoContent}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            open={this.state.modal}
            onClose={() => this.onCloseModal()}
            center
            showCloseIcon={false}
          >
            <div
              class="row"
              style={{ padding: 50, background: "#2d303a", width: 500 }}
            >
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <span
                  className={classes.basicInfoText}
                  style={{ paddingLeft: 0 }}
                >
                  {" "}
                  Title <span style={{ color: "#1891BD" }}>*</span>{" "}
                </span>
                <div style={{ paddingTop: 10 }}></div>
                <LabelInput
                  updateInputValue={this.updateInputValue}
                  name={"mediaTitle"}
                  label={"Title"}
                  value={this.state.mediaTitle}
                />
              </div>
              <div style={{ paddingTop: 10 }}></div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <span
                  className={classes.basicInfoText}
                  style={{ paddingLeft: 0 }}
                >
                  {" "}
                  Media type <span style={{ color: "#1891BD" }}>*</span>{" "}
                </span>
                <div style={{ paddingTop: 10 }}></div>
                <SelectBox
                  onChange={this.updateInputValue}
                  keyName={"mediaType"}
                  options={[
                    { label: "Select media type", value: 0 },
                    { label: "Image", value: "image" },
                    { label: "Video", value: "video" },
                  ]}
                  value={this.state.mediaType}
                />
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 "
                style={{ paddingLeft: 20, paddingTop: 20 }}
              >
                <ReactFileReader
                  handleFiles={this.handleFiles1}
                  fileTypes={
                    this.state.mediaType === "image"
                      ? [".png", ".jpg", "jpeg"]
                      : [".mp4", ".mov", ".flv", ".avi"]
                  }
                >
                  <Button
                    buttonStyle={classes.buttonStyle1}
                    buttonName={"Browse"}
                    extraStyle={{ paddingLeft: 20, paddingRight: 20 }}
                    fullWidth={false}
                    onClick={() => console.log("browse")}
                  />
                </ReactFileReader>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {checkNullAndReturnString(this.state.galleryFileName) ? (
                  <span className={classes.fileText}>
                    {this.state.galleryFileName}
                  </span>
                ) : (
                  ""
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {checkNullAndReturnString(this.state.galleryFileName) ? (
                  <a
                    href="#/"
                    className={classes.changeText}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ gallery: "", galleryFileName: "" });
                    }}
                  >
                    Remove
                  </a>
                ) : (
                  ""
                )}
                <p className={classes.fileSizeText}>
                  Max. file size 5 MB, Only *JPG, *PNG, *TIFF, *FLV, *MOV, *MP4
                  File Format
                </p>
              </div>
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"
                style={{ paddingTop: 30 }}
              >
                <Button
                  buttonStyle={classes.buttonStyle1}
                  buttonName={"Cancel"}
                  fullWidth={true}
                  onClick={() => this.onCloseModal()}
                />
              </div>
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"
                style={{ paddingTop: 30 }}
              >
                <Button
                  buttonStyle={classes.buttonStyle}
                  buttonName={"Submit"}
                  fullWidth={true}
                  onClick={() => this.submitGallery()}
                />
              </div>
            </div>
          </Modal>

          <Modal
            open={this.state.modal1}
            onClose={() => this.onCloseModal1()}
            center
            showCloseIcon={false}
          >
            <div style={{ padding: 10 }}>
              <a
                href="#/"
                style={{
                  width: 22,
                  height: 22,
                  textAlign: "center",
                  position: "absolute",
                  right: 15,
                  top: 15,
                  background: "#fff",
                  borderRadius: 22 / 2,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  this.onCloseModal1();
                }}
              >
                <img alt="" src={ClosePng} style={{ width: 10, height: 10 }} />
              </a>
              {this.state.modalType === "image" ? (
                <img
                  alt=""
                  src={Config.dataUrl + "aws-files/" + this.state.modalId}
                  className={"img-responsive"}
                  style={{ width: 580, height: 387 }}
                />
              ) : (
                <Vimeo
                  video={this.state.modalKey}
                  // autoplay
                />
              )}
            </div>
            <p style={{ float: "right", paddingRight: 10, paddingLeft: 10 }}>
              {!this.state.highlightModal ? (
                <Button
                  buttonStyle={classes.buttonStyle2}
                  buttonName={"Permanently Delete"}
                  fullWidth={false}
                  onClick={() => {
                    this.setState({
                      confirmModal: true,
                      confirmType: "delete",
                    });
                  }}
                />
              ) : (
                ""
              )}
              &nbsp;&nbsp;&nbsp;
              <Button
                buttonStyle={classes.buttonStyle3}
                buttonName={
                  modalAddAndRemoveType
                    ? "Add to highlight"
                    : "Remove from highlight"
                }
                fullWidth={false}
                onClick={() => {
                  if (!modalAddAndRemoveType) {
                    this.setState({
                      confirmModal: true,
                      confirmType: "remove",
                    });
                  } else {
                    this.addAndRemoveHightLights(
                      this.state.modalId,
                      modalAddAndRemoveType,
                      this.state.modalType === "image" ? "Image" : "Video"
                    );
                    this.onCloseModal1();
                  }
                }}
              />
            </p>
          </Modal>

          <Modal
            open={this.state.modal2}
            onClose={() => this.onCloseModal2()}
            center
            showCloseIcon={false}
          >
            <div style={{ padding: 20 }}>
              <p
                style={{
                  font: "normal normal 600 18px/27px Poppins",
                  opacity: 1,
                }}
              >
                Discard Changes
              </p>
              <p
                style={{
                  font: "normal normal normal 14px/21px Poppins",
                  opacity: 1,
                }}
              >
                Unsaved changes will be lost, are you sure to
                proceed?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
              <p style={{ float: "right" }}>
                <Button
                  buttonStyle={classes.buttonStyle2}
                  buttonName={"Close"}
                  fullWidth={false}
                  onClick={() => {
                    this.onCloseModal2();
                  }}
                />
                <span style={{ paddingRight: 20 }}></span>
                <Button
                  buttonStyle={classes.buttonStyle3}
                  buttonName={"Proceed"}
                  fullWidth={false}
                  onClick={() => {
                    this.onCloseModal2();
                    this.props.history.push(
                      Config.reactUrl + "/agent/dashboard"
                    );
                  }}
                />
              </p>
            </div>
          </Modal>

          <Modal
            open={this.state.confirmModal}
            onClose={() => {
              this.setState({ confirmModal: false, confirmType: "" });
            }}
            center
            showCloseIcon={false}
          >
            <div style={{ padding: 20 }}>
              {this.state.confirmType === "delete" ? (
                <p
                  style={{
                    font: "normal normal 600 18px/27px Poppins",
                    opacity: 1,
                  }}
                >
                  Permanently Delete
                </p>
              ) : (
                <p
                  style={{
                    font: "normal normal 600 18px/27px Poppins",
                    opacity: 1,
                  }}
                >
                  Remove From Profile Page
                </p>
              )}
              <p
                style={{
                  font: "normal normal normal 14px/21px Poppins",
                  opacity: 1,
                }}
              >
                {this.state.confirmType === "delete"
                  ? "Are you sure you want to permanently delete this media from your account?"
                  : "Media removed from your profile page will still be available under profile editing for future use. Are you sure you want to remove this media from your main profile page?"}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <Button
                    buttonStyle={classes.buttonStyle2}
                    buttonName={"No"}
                    fullWidth={false}
                    onClick={() => {
                      this.setState({ confirmModal: false, confirmType: "" });
                    }}
                  />
                  <span style={{ paddingRight: 20 }}></span>
                  <Button
                    buttonStyle={classes.buttonStyle3}
                    buttonName={"Yes"}
                    fullWidth={false}
                    onClick={() => {
                      if (this.state.confirmType === "delete") {
                        this.changeSatatus(this.state.modalId, "rejected");
                      } else {
                        this.addAndRemoveHightLights(
                          this.state.modalId,
                          modalAddAndRemoveType,
                          this.state.modalType === "image" ? "Image" : "Video"
                        );
                        this.onCloseModal1();
                        this.setState({ confirmModal: false, confirmType: "" });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(withStore(EditProfile));
