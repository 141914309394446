import React, { Component } from 'react'
import { withStore } from '../../component/hooks/store';
import { checkNullAndReturnString, doGetConnectSecurity } from '../../helper/common';
// import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Styles from "../../styles/uaAdmin/dashboard";
import Tables from "../../component/table";
import Button from "../../component/button";
import TablePagination from "@material-ui/core/TablePagination";

import axios from 'axios';
import Config from "../../helper/config";
import fileDownload from 'js-file-download';


// import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from 'react-toastify';


class EnrolledPlayers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            approveTotal: 0,
            rejectedTotal: 0,
            height: 0,
            width: 0,
            tableData: [],

            sortByKey: 'full_name',
            sortByOrder: true,
            paginations: {
                paginatonpageindex: 0,
                paginationrowsPerPage: 50,
                paginationcountdata: 0
            }
        }
    }

    componentDidMount = () => {
        this.updateWindowDimensions();
        this.getWorkoutPerformance();
    }

    getWorkoutPerformance = async (year) => {
        const { paginations: { paginatonpageindex, paginationrowsPerPage }, sortByKey, sortByOrder } = this.state;
        const pagefrom = paginationrowsPerPage * paginatonpageindex;
        const pageto = paginationrowsPerPage;

        var userDetail = JSON.parse(localStorage.getItem("userDetail"));
        var school_id = userDetail?.school_id;
        if (this.props.from === 'admin') {
            school_id = this.props.id
        }
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doGetConnectSecurity("player-performance?skip=" + pagefrom + "&take=" + pageto + "&order=" + (sortByOrder ? 'asc' : 'desc') + "&sortby=" + sortByKey + '&school_id=' + school_id, accessToken)
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.UserStatusCount)) {
                this.setState({ total: responseData.UserStatusCount.total_count })
                this.setState({ approveTotal: responseData.UserStatusCount.enrolled_count })
                this.setState({ rejectedTotal: parseInt(responseData.UserStatusCount.unenrolled_count) })
            }
            if (checkNullAndReturnString(responseData.data)) {
                if (checkNullAndReturnString(responseData.data)) {
                    let paginationssetdata = { paginatonpageindex, paginationrowsPerPage, paginationcountdata: Number(responseData.total) };
                    // var list = sort_by_key(responseData.data, 'full_name')
                    this.setState({ tableData: responseData.data, paginations: paginationssetdata })
                }
            }
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    exportPDFData = () => {

        // const unit = "pt";
        // const size = "A4"; // Use A1, A2, A3 or A4
        // const orientation = "portrait"; // portrait or landscape
        // const marginLeft = 40;
        // const doc = new jsPDF(orientation, unit, size);
        // doc.setFontSize(15);
        // const headers = [["Player", "POS", "CLS", "HT", "WT", "Enrolled"]];
        // const data = this.state.tableData.map(elt => [elt.full_name, elt.position, elt.class, elt.height_feet + " Ft. " + elt.height_inches + " In", elt.weight + " Lbs", elt.user_status ? "Yes" : "No"]);
        // let content = {
        //     startY: 50,
        //     head: headers,
        //     body: data
        // };
        // //doc.text(title, marginLeft, 40);
        // doc.autoTable(content);
        // doc.save("report.pdf")


        var accessToken = localStorage.getItem("accessToken");
        const headers = {
            authorization: `Bearer ${accessToken}`
        }
        var userDetail = JSON.parse(localStorage.getItem("userDetail"));
        var school_id = userDetail?.school_id;
        if (this.props.from === 'admin') {
            school_id = this.props.id
        }
        axios.get(`${Config.dataUrl}player-performance/getpdf?school_id=${school_id}`, {
            responseType: 'blob',
            headers
        })
            .then((res) => {
                fileDownload(res.data, "Enrollplayers.pdf")
            })
            .catch(err => {
                toast.error(err ? err : (err.message ? err.message : 'Error while processing pdf'))
            })

    }

    handleChangePage = async (event, newPage) => {
        const paginationvalue = { ...this.state.paginations, paginatonpageindex: newPage };
        this.setState({
            paginations: { ...paginationvalue }
        }, this.getWorkoutPerformance)
    }


    handleChangeRowsPerPage = async (event) => {
        const paginationvalue = { ...this.state.paginations, paginationrowsPerPage: event.target.value, paginatonpageindex: 0 };
        this.setState({
            paginations: { ...paginationvalue }
        }, this.getWorkoutPerformance);
    }

    onSortModalData = (column) => {
        let key = 'full_name';
        if (column === "Player") { key = 'full_name' } else
            if (column === "POS") { key = 'position' } else
                if (column === "CLS") { key = 'class' } else
                    if (column === "HT") { key = 'height_feet' } else
                        if (column === "WT") { key = 'weight' } else
                            if (column === "Enrolled") { key = 'user_status' };
        this.setState((pre) => ({
            sortByKey: key,
            sortByOrder: (pre.sortByKey === key ? !pre.sortByOrder : true)
        }), this.getWorkoutPerformance);
    }


    render() {
        var { classes } = this.props;
        var { rejectedTotal, approveTotal, total, height, tableData, paginations: {
            paginatonpageindex,
            paginationrowsPerPage,
            paginationcountdata
        } } = this.state;
        var rows = []
        if (checkNullAndReturnString(tableData)) {
            Object.keys(tableData).forEach(key => {
                var item = tableData[key];
                rows.push({
                    player: item.full_name,
                    position: item.position,
                    class: item.class,
                    height: <span>
                        {checkNullAndReturnString(item.height_feet) ? item.height_feet + " Ft. " : "0 Ft. "}
                        {checkNullAndReturnString(item.height_inches) ? item.height_inches + " In" : "0 In"}
                    </span>,
                    weight: checkNullAndReturnString(item.weight) ? item.weight + " Lbs" : "0 Lbs",
                    enrolled: item.user_status ? <span style={{ padding: 10, paddingTop: 3, paddingBottom: 3, borderRadius: 5, background: "#58BE52" }}>Yes</span> : <span style={{ padding: 10, paddingTop: 3, paddingBottom: 3, borderRadius: 5, background: "#D51212" }}>No</span>
                })
            })
        }

        return (
            <React.Fragment>
                <div className={"row rowView"}>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20 }}>
                        <div className={"row"}>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Button buttonStyle={classes.buttonStyle1}
                                    buttonName={"Export To PDF"} fullWidth={false}
                                    onClick={() => this.exportPDFData()} extraStyle={{ float: "right" }} />
                                <span className={classes.studentCountText} style={{color: "#959595"}}>
                                    {total} Students Listed | {approveTotal} Students Enrolled | {rejectedTotal} Students Not Enrolled
                                </span>
                            </div>
                        </div>
                        <div style={{ paddingTop: 15 }}></div>
                        <Tables fixed={true}
                            extraStyle={{ height: height / 1.6 }}
                            thJson={["Player", "POS", "CLS", "HT", "WT", "Enrolled"]}
                            tdJson={rows}
                            sortHeader={true} onSortData={this.onSortModalData}
                        // paginationFooter={this.state.paginations}
                        // handleChangePage={this.handleChangePage}
                        // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                    <div style={{ float: 'right', width: '100%' }}>
                        <TablePagination
                            style={{ color: 'white' }}
                            component="div"
                            count={paginationcountdata}
                            page={paginatonpageindex}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={paginationrowsPerPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            rowsPerPageOptions={[50, 100, 150, 200]}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(Styles, { withTheme: true })(withStore(EnrolledPlayers));
