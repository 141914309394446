import React, { Component } from 'react'
import { checkNullAndReturnString } from '../../helper/common';
import { Container, CssBaseline } from '@material-ui/core';
import Footer from "../manage/footer";
import Header from "./header";
import { withStore } from '../../component/hooks/store';
import { withStyles } from '@material-ui/core/styles';
import Styles from "../../styles/admin/agents";
import Config from "../../helper/config";
import Tab from "../../component/leftTab";
import AgentApproved from "./agentApproved";
import AgentPending from "./agentPending";
import AgentRejected from "./agentRejected";
import AgentReviewMedia from "./agentReviewMedia";

class Agents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 0,
        }
    }

    componentDidMount = () => {
        var params = this.props.match.params;
        if (checkNullAndReturnString(params)) {
            if (checkNullAndReturnString(params.id)) {
                this.setState({ selected: params.id })
            }
        }
    }

    tabOnClick = (index) => {
        this.setState({ selected: index })
        this.props.history.push(Config.reactUrl + "/admin/agents/" + index)
    }

    render() {
        var { selected } = this.state
        const { classes } = this.props;

        return (
            <React.Fragment>
                <CssBaseline />
                <Header from={"Agents"} />
                <Container maxWidth="xl" component="main" className={classes.heroContent}>
                    <div className="container-fluid" style={{ paddingTop: 40, paddingBottom: 100 }}>
                        <div class="row rowView" >
                            <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12" style={{ padding: 0 }}>
                                <Tab json={["Pending", "Approved", "Rejected", "Media"]} selected={selected} tabOnClick={this.tabOnClick} />
                            </div>
                            {
                                Number(selected) === 0 ?
                                    <AgentPending />
                                    :
                                    Number(selected) === 1 ?
                                        <AgentApproved />
                                        :
                                        Number(selected) === 2 ?
                                            <AgentRejected />
                                            :
                                            Number(selected) === 3 ?
                                                <AgentReviewMedia />
                                                :
                                                null
                            }
                        </div>
                    </div>
                </Container>
                <Footer />
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(Agents));