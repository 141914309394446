import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withStore } from '../../component/hooks/store';
import Styles from "../../styles/admin/universityAdmins.js";
import Button from "../../component/button";
import SelectBox from "../../component/selectBoxDefaut";
// import Input from "../../component/input";
import { doPostFormData, checkNullAndReturnString, doGetConnect, ValidateEmail } from '../../helper/common';
import { toast } from 'react-toastify';
// import TextInput from 'react-textinput-field';
import LabelInput from "../../component/labelInput";
// import Tables from "../../component/table";
// import Checkbox from '@material-ui/core/Checkbox';

class UniversityAdmins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            schoolList: [],
            school: 0,
            adminPrivillage: null,
            defaultStatus: true,
            gender: null,
            city: "",
            state: "",

            createUsers: false
        }
        this.disablebutton = false;
    }

    emptyData = () => {
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            // school: 0,
            adminPrivillage: 0,
            defaultStatus: true,
            gender: 0,
            // city: "",
            // state: ""4
        })
    }

    componentDidMount = async () => {
        this.getSchoolData();
        const selectedSchool = this.props.selectedSchool;
        if (selectedSchool) {
            this.setState({
                city: selectedSchool.city || '',
                state: selectedSchool.state || '',
                school: selectedSchool.id,
                createUsers: true
            })
        } else if (this.props.userFromLogin) {
            let UserDetail = localStorage.getItem("userDetail");
            if (UserDetail) {
                let { city, state, school_id } = JSON.parse(UserDetail);
                this.setState({
                    city: city || '',
                    state: state || '',
                    school: school_id,
                    createUsers: true
                })
            }
        }
    }

    getSchoolData = async () => {
        var responseData = await doGetConnect("school")
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data)) {
                var schoolData = [{ label: "School Name", value: 0 }];
                var responseSchoollData = JSON.parse(JSON.stringify(responseData.data).split('"id":').join('"value":'));
                responseSchoollData = JSON.parse(JSON.stringify(responseSchoollData).split('"school_name":').join('"label":'));
                var schoolList = schoolData.concat(responseSchoollData);
                this.setState({ schoolList })
            }
        }
    }

    validateSignUp = async () => {
        var { firstName, lastName, email, school, defaultStatus, adminPrivillage, /* city, state, */ } = this.state;
        if (!checkNullAndReturnString(firstName)) {
            toast.error("Please enter firstname");
            return false;
        }
        if (!checkNullAndReturnString(lastName)) {
            toast.error("Please enter lastname");
            return false;
        }
        if (!checkNullAndReturnString(email)) {
            toast.error("Email is required");
            return false;
        }
        if (!ValidateEmail(email)) {
            toast.error("Please enter valid email");
            return false;
        }
        // if (!checkNullAndReturnString(city)) {
        //     toast.error("Please enter city");
        //     return false;
        // }
        // if (!checkNullAndReturnString(state)) {
        //     toast.error("Please enter state");
        //     return false;
        // }
        // if (!checkNullAndReturnString(gender) || Number(gender) === 0) {
        //     toast.error("Please select gender");
        //     return false;
        // }
        if (this.props.from === "admin" || this.props.from === "coachUser") {
        } else {
            if (!checkNullAndReturnString(school) || Number(school) === 0) {
                toast.error("Please select school");
                return false;
            }
            if (typeof (defaultStatus) !== "boolean") {
                toast.error("Please select default status");
                return false;
            }
            if (typeof (adminPrivillage) !== "boolean") {
                toast.error("Please select admin privileges");
                return false;
            }
        }
        return true;
    }


    onSortData = (column) => {
        let key = 'first_name';
        if (column === "Name") { key = 'first_name' } else
            if (column === "School") { key = 'school_name' } else
                if (column === "State") { key = 'state' } else
                    if (column === "City") { key = 'city' } else
                        if (column === "Status") { key = 'status' } else
                            if (column === "AdminPrevilege") { key = 'admin_privileges' }
        this.setState((pre) => ({
            sortByKey: key,
            sortByOrder: (pre.sortByKey === key ? !pre.sortByOrder : true)
        }), this.getUAList);
    }

    handleChangePage = async (event, newPage) => {
        const paginationvalue = { ...this.state.paginations, paginatonpageindex: newPage };
        this.setState({
            paginations: { ...paginationvalue }
        }, this.getUAList)
    }


    handleChangeRowsPerPage = async (event) => {
        const paginationvalue = { ...this.state.paginations, paginationrowsPerPage: event.target.value, paginatonpageindex: 0 };
        this.setState({
            paginations: { ...paginationvalue }
        }, this.getUAList)
    }



    addUniversityAdmin = async () => {
        if (await this.validateSignUp()) {
            this.disablebutton = true;
            var { firstName, lastName, email, school, defaultStatus, adminPrivillage, city, state, gender } = this.state;

            var postData = new FormData();
            postData.append("firstName", firstName)
            postData.append("lastName", lastName)
            postData.append("gender", (gender ? gender : ""))
            postData.append("sub_admin", this.props.from === "admin" ? true : false)
            postData.append("roleId", this.props.from === "admin" ? "1" : this.props.from === "coachUser" ? "5" : "2")
            postData.append("email", email)
            postData.append("adminPrivileges", (adminPrivillage ? adminPrivillage : false))
            postData.append("agencyName", "")
            postData.append("education", "")
            postData.append("experience", "")
            postData.append("serviceOffered", "")
            postData.append("dateOfBirth", "")
            postData.append("secondaryEmail", "")
            postData.append("schoolId", school)
            postData.append("class", "")
            postData.append("playerPosition", "")
            postData.append("heightFeet", "")
            postData.append("heightInches", "")
            postData.append("weight", "")
            postData.append("playerProfileId", "")
            postData.append("city", city)
            postData.append("state", state)
            postData.append("status", (defaultStatus ? defaultStatus : false))
            postData.append("certificates", "")
            var responseData = await doPostFormData("users", postData);
            if (checkNullAndReturnString(responseData)) {
                if (checkNullAndReturnString(responseData.message) && responseData.message === "Success") {
                    this.disablebutton = false;
                    if (this.props.from === "admin") {
                        toast.success("NXTPICK Admin details has been added successfully");
                    } else if (this.props.from === 'coachUser') {
                        toast.success("Coach details has been saved successfully");
                    } else {
                        toast.success("University Admin details has been saved successfully");
                    }
                    this.emptyData();
                    this.props.resetselectedSchool(responseData.result)
                }
                else {
                    if (checkNullAndReturnString(responseData.error)) {
                        if (checkNullAndReturnString(responseData.error.message)) {
                            this.disablebutton = false;
                            toast.error(responseData.error.message);
                        }
                    }
                }
            }
        }
    }

    handleChange = (id, value) => {
        this.setState({
            [id]: value
        })
    }

    render() {
        const { classes } = this.props;
        var { /* firstName, lastName, email, city, state,*/ school, defaultStatus, adminPrivillage, schoolList } = this.state;

        return (
            <React.Fragment>
                <div style={{ paddingTop: 20 }}>
                    <div >
                        <span className={classes.searchText}>
                            {
                                this.props.from === "admin" ?
                                    "Create User"
                                    :
                                    this.props.from === "coachUser" ? 
                                    "Create Coach"
                                    :
                                    "Create User"
                            }
                        </span>
                        <br /><br />
                        <div>
                            <div>
                                <LabelInput disabled={false}
                                    updateInputValue={this.handleChange}
                                    name={"firstName"} label={"First Name"}
                                    value={this.state.firstName} />
                                {/* <Input placeHolder={"First Name"} keyName={"firstName"} value={firstName} onChange={this.handleChange} /> */}
                                <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                            </div>
                            <div>
                                <LabelInput disabled={false}
                                    updateInputValue={this.handleChange}
                                    name={"lastName"} label={"Last Name"}
                                    value={this.state.lastName} />
                                {/* <Input placeHolder={"Last Name"} 
                                keyName={"lastName"} value={lastName} onChange={this.handleChange} /> */}
                                <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                            </div>
                        </div>
                        <LabelInput disabled={false}
                            updateInputValue={this.handleChange}
                            name={"email"} label={"Email"}
                            value={this.state.email} />
                        <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                        {
                            !this.state.createUsers &&
                            <>
                                <div class="row mx-0">
                                    <div class="col-md-6 px-0">
                                        <LabelInput disabled={false}
                                            updateInputValue={this.handleChange}
                                            name={"city"} label={"City"}
                                            value={this.state.city} />
                                        <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                                    </div>
                                    <div class="col-md-6 pl-2 pr-0">
                                        <LabelInput disabled={false}
                                            updateInputValue={this.handleChange}
                                            name={"state"} label={"State"}
                                            value={this.state.state} />
                                        <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                                    </div>
                                </div>
                            </>
                        }      
                        {
                            this.props.from === "admin" || this.props.from === "coachUser" ?
                                <></>
                                :
                                <>                  
                                    <SelectBox onChange={this.handleChange} keyName={"adminPrivillage"}
                                        placeholder="Admin Privileges"
                                        options={[
                                            { label: "Yes", value: true },
                                            { label: "No", value: false }]} value={adminPrivillage} />
                                    <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                                </>
                        }        
                        {
                            this.props.from === "admin" || this.props.from === "coachUser" ?
                                <></>
                                :
                                <>
                                    <SelectBox onChange={this.handleChange} keyName={"school"}
                                        placeholder="School Name"
                                        disabled={this.state.createUsers}
                                        options={schoolList} value={school} />
                                    <p style={{ padding: 0, margin: 0, paddingTop: 10 }}></p>
                                </>
                        }                        
                        <SelectBox onChange={this.handleChange} keyName={"defaultStatus"}
                            placeholder="Default Status"
                            options={[{ label: "Active", value: true }, { label: "Inactive", value: false }]} value={defaultStatus} />
                        <div style={{ paddingTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div>
                                <Button buttonStyle={classes.buttonStyle1} buttonName={"Clear"}
                                    fullWidth={false} onClick={() => this.emptyData()} />
                                <span style={{ paddingRight: 10 }}></span>
                                <Button buttonStyle={classes.buttonStyle} buttonName={"Submit"}
                                    disabled={this.disablebutton}
                                    fullWidth={false} onClick={() => this.addUniversityAdmin()} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    // componentWillUnmount() {
    //     this.props.resetselectedSchool(false)
    // }
}
export default withStyles(Styles, { withTheme: true })(withStore(UniversityAdmins))

