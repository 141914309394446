import React, { Component } from 'react';
import { checkNullAndReturnString } from '../helper/common';
import TablePagination from "@material-ui/core/TablePagination";
import UpAndDownArrow from "../images/up-and-down.png";

class index extends Component {
  textchanger = (text) => {
    var values = text.split(' ');
    if (values.length > 1) {
      values = values.map((e, i) => {
        if (i !== 0) {
          return e.toLowerCase()
        }
        return e.toLowerCase().replace(/\b\w/gmi, w => w.toUpperCase())
      });
      return values.join(' ');
    }
    return values[0].toLowerCase().replace(/\b\w/gmi, w => w.toUpperCase())
  }
  render() {
    var tableInputStyle = {
      background: "transparent",
      font: "normal normal normal 14px/21px Poppins",
      height: 32,
      color: "#fff",
      border: "0.5px solid rgb(75 76 92 / 60%)",
      width: 80,
      paddingLeft: 13,
      paddingRight: 13,
      outline: "none",
      "&:hover": {
        outline: "none"
      },
      "&:focus": {
        outline: "none"
      },
      borderRadius: 5
    }
    var { thJson, tdJson, fixed, extraStyle, sortHeader = false, onSortData,
      paginationFooter = false, handleChangePage, handleChangeRowsPerPage, from } = this.props;
    var thContent = [];
    var tableHeading = {
      borderBottom: "1px solid #3B3E50",
      borderTop: "1px solid #3B3E50",
      font: "normal normal 600 14px/21px Poppins",
      color: "#FFFFFF",
      opacity: 1,
    };
    var tableBodyNormal = {
      borderBottom: "1px solid #3B3E50",
      font: "normal normal normal 14px/21px Poppins",
      color: "#FFFFFF",
      opacity: 1,
    }
    if (checkNullAndReturnString(thJson)) {
      Object.keys(thJson).forEach(key => {
        thContent.push(
          <th style={tableHeading}>
            <span>
              {this.textchanger(thJson[key])}
            </span>
            <React.Fragment>
              {
                (thJson[key] && sortHeader) &&
                <a href="#/" style={{ cursor: 'pointer', outline: "none" }} onClick={(e) => { e.preventDefault(); onSortData(thJson[key]) }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img src={UpAndDownArrow} alt="UpAndDownArrow" style={{ width: 15, height: 15 }} />
                </a>
              }
            </React.Fragment>
          </th>
        )
      })
    }
    var tdContent = []
    if (checkNullAndReturnString(tdJson)) {
      Object.keys(tdJson).forEach(key => {
        var background = "transparent"
        var i = 0;
        var item = tdJson[key];
        var tdInnerContent = []
        if (from === "statistics") {
          Object.keys(item).forEach(key1 => {
            i++;
            if (i >= 18) {
              var edited = key1.search("edited");
              if(checkNullAndReturnString(item[key1 + "edited"])){
                // console.log({
                //   set:item[key1 + "edited"],
                //   item,
                //   key1
                // })
                background = "#243a42"
              }
              if (edited === -1) {
                tdInnerContent.push(
                  <td style={tableBodyNormal}>
                    <input type={"text"}
                      value={item[key1]}
                      style={{ ...tableInputStyle, background: checkNullAndReturnString(item[key1 + "edited"]) ? "#515065" : "transparent" }}
                      onChange={(e) => this.props.tableHandleChange(e.target.value, key, key1, item)}
                    />
                  </td>
                )
              }
            }
            else {
              // if (i < 11 && !checkNullAndReturnString(item[key1])) {
              //   background = "#243a42"
              // }
              tdInnerContent.push(
                <td style={tableBodyNormal}>
                  {item[key1]}
                </td>
              )
            }
          })
        }
        else {
          Object.keys(item).forEach(key1 => {
            tdInnerContent.push(
              <td style={tableBodyNormal}>
                {item[key1]}
              </td>
            )
          })
        }

        tdContent.push(
          <tr style={{ background: background }}>
            {tdInnerContent}
          </tr>
        )
      })
    } else {
      tdContent.push(
        <tr>
          <td colspan={thJson.length} style={{ textAlign: 'center' }}>
            <span style={{
              textAlign: 'center', paddingTop: 10, font: "normal normal normal 12px/18px Poppins", color: "#959595",
              opacity: 1
            }} >No Records Found</span>
          </td>
        </tr>
      )
    }
    return (
      <React.Fragment>
        <div class={fixed ? "tableFixHead" : ""} style={{ ...extraStyle }} >
          <table class="table fixed_header table-responsive-sm table-responsive-lg table-responsive-md table-responsive-xl">
            <thead>
              <tr>
                {thContent}
              </tr>
            </thead>
            <tbody>
              {tdContent}
            </tbody>
          </table>
        </div>
        <div style={{ paddingTop: 15 }}></div>
        {
          paginationFooter &&
          <TablePagination
            style={{ color: 'white' }}
            component="div"
            count={paginationFooter.paginationcountdata}
            page={paginationFooter.paginatonpageindex}
            onChangePage={handleChangePage}
            rowsPerPage={paginationFooter.paginationrowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[50, 100, 150, 200]}
          />
        }
      </React.Fragment>
    )
  }
}
export default index;