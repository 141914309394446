import React, { Component } from "react";
import { Link } from "react-router-dom";
import Config from "../helper/config";
import HeartCircle from "../images/heartCircle.png";
import Tooltip from "@material-ui/core/Tooltip";

class FavoritesList extends Component {
  render() {
    const {
      img,
      name,
      description,
      id,
      makingPlayerUnfavorite,
      type,
    } = this.props;
    return (
      <div class="row" style={{ paddingLeft: 20, paddingRight: 20 }}>
        {type === "agent" ? (
          <div
            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 favarotiesBox"
            style={{
              padding: 10,
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <Link
              to={Config.reactUrl + `/agent/agentplayerview/agent/${id}`}
              style={{ display: "inline-flex", width: "100%" }}
            >
              {img ? (
                <img
                  src={`${Config.dataUrl}aws-files/${img}`}
                  alt=""
                  style={{ width: 40, height: 40, borderRadius: 10 / 2 }}
                />
              ) : (
                <img
                  alt=""
                  src={Config.emptyUser}
                  style={{ width: 40, height: 40, borderRadius: 10 / 2 }}
                />
              )}
              <div>
                <span
                  style={{
                    font: "normal normal normal 12px/18px Poppins",
                    color: "#FFFFFF",
                    opacity: 1,
                    paddingLeft: 20,
                  }}
                >
                  {name}
                </span>
                <br />
                <span
                  style={{
                    font: "normal normal normal 10px/16px Poppins",
                    color: "#959595",
                    opacity: 1,
                    paddingLeft: 20,
                  }}
                >
                  {description}
                </span>
              </div>
            </Link>
            <Tooltip title={`to make ${name} unfavorite`} placement="top">
              <img
                alt=""
                src={HeartCircle}
                style={{
                  width: 25,
                  height: 25,
                  float: "right",
                  cursor: "pointer",
                }}
                onClick={() => makingPlayerUnfavorite(id)}
              />
            </Tooltip>
          </div>
        ) : (
          <div
            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 favarotiesBox"
            style={{
              padding: 10,
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <div style={{ display: "inline-flex", width: "100%" }}>
              {img ? (
                <img
                  alt=""
                  src={`${Config.dataUrl}aws-files/${img}`}
                  style={{ width: 40, height: 40, borderRadius: 40 / 2 }}
                />
              ) : (
                <img
                  alt=""
                  src={Config.emptyUser}
                  style={{ width: 40, height: 40, borderRadius: 40 / 2 }}
                />
              )}
              <div>
                <span
                  style={{
                    font: "normal normal normal 12px/18px Poppins",
                    color: "#FFFFFF",
                    opacity: 1,
                    paddingLeft: 20,
                  }}
                >
                  {name}
                </span>
                <br />
                <span
                  style={{
                    font: "normal normal normal 10px/16px Poppins",
                    color: "#959595",
                    opacity: 1,
                    paddingLeft: 20,
                  }}
                >
                  {description}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default FavoritesList;
