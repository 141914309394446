import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { withStore } from './hooks/store';
import Config from '../helper/config';
import Styles from "../styles/message.js";
import BackOption from "./back";
// import Tab from "./tab";
import MenuPng from "../images/menu.png";
import SendPng from "../images/send.png";
import { checkNullAndReturnString, doPostConnectSecurity, doGetConnectSecurity, formatAMPM, doPutConnectSecurity } from "../helper/common";
import { toast } from 'react-toastify';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            seachText: "",
            messageText: "",
            fromUserId: "",
            chatData: [],
            roleId: "",
            chatUsers: [],
            userDetails: {},
            opacitySubmit: true
        }
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView();
    }

    // componentDidUpdate() {
    //     this.scrollToBottom();
    // }

    componentDidMount = async () => {
        this.updateWindowDimensions();
    }

    componentWillMount() {
        if (this.props.fromUserId) {
            this.setState({ fromUserId: this.props.fromUserId })
            this.getMessage(this.props.fromUserId);
        }
        if (this.props.roleId) {
            this.setState({ roleId: this.props.roleId })
        }
        this.getUsers();
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    sendMessage = async () => {
        this.setState({ opacitySubmit: false })
        var { messageText, fromUserId, roleId } = this.state;
        if (checkNullAndReturnString(messageText)) {
            var accessToken = localStorage.getItem("accessToken");
            // var id = localStorage.getItem("id");
            var postJson = {
                message: messageText,
                sendTo: fromUserId,
                receiverRoleId: roleId
            }
            var responseData = await doPostConnectSecurity("messages", postJson, accessToken);
            if (checkNullAndReturnString(responseData)) {
                this.setState({ opacitySubmit: true })
                this.setState({ messageText: "" })
                this.getMessage(fromUserId)
                this.getUsers();
            }
        }
        else {
            this.setState({ opacitySubmit: true })
            toast.error("Please enter message")
        }
    }

    getMessage = async (fromUserId) => {
        // this.setState({ chatData: [] })
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doGetConnectSecurity("messages/chat?messageFrom=" + fromUserId + "&skip=&take=&order=&sortby=", accessToken);
        if (checkNullAndReturnString(responseData)) {
            if (checkNullAndReturnString(responseData.data)) {
                this.setState({ chatData: responseData.data })
                this.scrollToBottom();
            }
            if (checkNullAndReturnString(responseData.userDetails)) {
                if (checkNullAndReturnString(responseData.userDetails[0])) {
                    this.setState({ userDetails: responseData.userDetails[0] })
                }
            }
        }
    }

    updateFlag = async (id) => {
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doPutConnectSecurity("messages/flag/" + id, {}, accessToken);
        if (checkNullAndReturnString(responseData)) {
            console.log(responseData)
        }
    }

    getUsers = async (fromUserId) => {
        this.setState({ chatUsers: [] })
        var accessToken = localStorage.getItem("accessToken");
        var responseData = await doGetConnectSecurity("messages/list", accessToken);
        if (checkNullAndReturnString(responseData)) {
            this.setState({ chatUsers: responseData })
        }
    }

    refillJsonData = (key) => {
        var { userDetails = [] } = this.state;
        if (checkNullAndReturnString(userDetails)) {
            if (checkNullAndReturnString(userDetails[key])) {
                return userDetails[key]
            }
        }
        return "";
    }

    render() {
        var { classes, from } = this.props;
        var { height, seachText, messageText, chatData, fromUserId, chatUsers } = this.state;
        var chatContent = []
        var chatUsert = []
        var messageCount = 0;
        if (checkNullAndReturnString(chatUsers)) {
            Object.keys(chatUsers).forEach(key => {
                var item = chatUsers[key];
                if (checkNullAndReturnString(item.new_message_count)) {
                    messageCount = parseInt(item.new_message_count) + messageCount;
                }
                var viewList = true;
                if (checkNullAndReturnString(seachText)) {
                    if (item.user_name.toLowerCase().search(seachText.toLowerCase()) === -1) {
                        viewList = false
                    }
                }
                if (viewList) {
                    chatUsert.push(
                        <a href="#/" onClick={(e) => {
                            e.preventDefault();
                            this.setState({ roleId: item.role_id, fromUserId: item.user_id, chatData: [] })
                            this.updateFlag(item.user_id);
                            this.getMessage(item.user_id);
                            this.props.childCount()
                        }} class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 favarotiesBox" style={{
                            padding: 10, display: "inline-flex", alignItems: "center", borderRadius: 10,
                            boxShadow: (fromUserId && fromUserId.toString()) === (item.user_id && item.user_id.toString()) ? "0px 6px 6px #00000029" : "", border: (fromUserId && fromUserId.toString()) === (item.user_id && item.user_id.toString()) ? "1px solid #323443" : ""
                        }}>
                            <div style={{ display: "inline-flex", width: "100%" }}>
                                {
                                    checkNullAndReturnString(item.profile_image_hash) ?
                                        <img alt="imgfirst" style={{ width: 40, height: 40, borderRadius: 40 / 2 }} src={Config.dataUrl + "aws-files/" + item.profile_image_hash} />
                                        :
                                        <img alt="imgsecond" style={{ width: 40, height: 40, borderRadius: 40 / 2 }} src={Config.emptyUser} />
                                }
                                <div>
                                    <span class={classes.name}>
                                        {item.user_name} {checkNullAndReturnString(item.new_message_count) && Number(item.new_message_count) !== 0 ? "(" + item.new_message_count + ")" : ""}
                                    </span>
                                    <br />
                                    <span class={classes.player}>
                                        {Number(item.role_id) === 4 ? "Football Player" : Number(item.role_id) === 3 ? "Agent" : Number(item.role_id) === 1 ? "Admin" : Number(item.role_id) === 2 ? "University Admin" : ""}
                                    </span>
                                    {
                                        (fromUserId && fromUserId.toString()) === (item.user_id && item.user_id.toString()) ?
                                            <a href="#/" onClick={e => e.preventDefault()} style={{
                                                position: "absolute",
                                                right: 15,
                                                top: 20,
                                            }}>
                                                <i class="fas fa-greater-than" style={{ color: "#959595", fontSize: 10 }}></i>
                                            </a>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </a>
                    )
                }
            });
        }
        if (checkNullAndReturnString(chatData)) {
            Object.keys(chatData).forEach(key => {
                var item = chatData[key];
                if ((item.receiver_id && item.receiver_id.toString()) === (fromUserId && fromUserId.toString())) {
                    chatContent.push(
                        <div style={{ width: "100%", display: "inline-block" }}>
                            <div className={classes.leftMsgWidth}>
                                <div className={classes.leftMsgBox}>
                                    <p className={classes.leftMsgText}>
                                        {item.message}
                                    </p>
                                    <p className={classes.timeText}>
                                        {
                                            formatAMPM(item.created_at)
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                }
                else {
                    chatContent.push(
                        <div style={{ width: "100%", display: "inline-block" }}>
                            <div className={classes.rightMsgWidth}>
                                <div className={classes.rightMsgBox}>
                                    <p className={classes.rightMsgText}>
                                        {item.message}
                                    </p>
                                    <p className={classes.timeText}>
                                        {
                                            formatAMPM(item.created_at)
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                }
            })
        }
        var profileUrl = Config.reactUrl + "/agent/agentplayerview/agent/" + this.refillJsonData("id")
        if (this.refillJsonData("role_id") && Number(this.refillJsonData("role_id")) === 3) {
            profileUrl = Config.reactUrl + "/player/playeragentview/player/" + this.refillJsonData("id")
        }
        return (
            <React.Fragment>
                <div className={"row rowView"}>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding">
                        <BackOption history={this.props.history} linkTo={from === "agent" ? Config.reactUrl + "/agent/dashboard" : Config.reactUrl + "/player/dashboard"} />
                    </div>
                </div>
                <div className={"row rowView"} style={{ paddingTop: 20 }}>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding">
                        <span className={classes.messageText}>
                            Messages
                        </span>
                    </div>
                </div>
                <div className={"row rowView"}>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 noPadding" >
                        <div class={"row"} style={{ paddingRight: 20 }}>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 10 }}>
                                <ul className="inline">
                                    <li className={classes.allMessageText} style={{ borderBottom: "2px solid #1891BD" }} >
                                        All Messages ({messageCount})
                                    </li>
                                </ul>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ paddingTop: 20, paddingBottom: 20 }}>
                                <input type={"text"}
                                    value={seachText}
                                    class={classes.inputStyle}
                                    onChange={(e) => this.setState({ seachText: e.target.value })}
                                    placeholder={"Search Conversation"}
                                />
                                <a href="#/" style={{ position: "absolute", right: 30, color: "inherit", top: 30 }} onClick={e => e.preventDefault()}>
                                    <i class="fa fa-search" style={{ font: 14, color: "#1891BD" }}></i>
                                </a>
                            </div>
                            <div style={{ height: height / 2, overflow: "hidden", overflowY: "scroll", padding: 10, paddingTop: 0, width: "100%" }}>
                                {chatUsert}
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 noPadding" >
                        <span className={"chatBorder"} style={{ height: height / 1.5 }}></span>
                        {
                            checkNullAndReturnString(this.state.fromUserId) ?
                                <div class={"row innerRowView"}>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 noPadding" style={{ paddingTop: 10, display: "inline-flex" }}>
                                        <div style={{ display: "inline-flex" }}>
                                            {
                                                checkNullAndReturnString(this.refillJsonData("profile_image_hash")) ?
                                                    <img style={{ width: 40, height: 40, borderRadius: 40 / 2 }} alt="awsprofiles" src={Config.dataUrl + "aws-files/" + this.refillJsonData("profile_image_hash")} />
                                                    :
                                                    <img style={{ width: 40, height: 40, borderRadius: 40 / 2 }} alt="emptyusers" src={Config.emptyUser} />
                                            }
                                            <div>
                                                <span class={classes.name}>
                                                    {
                                                        this.refillJsonData("first_name") + " " + this.refillJsonData("last_name")
                                                    }
                                                </span>
                                                <br />
                                                <span class={classes.player}>
                                                    {(this.refillJsonData("role_id") && Number(this.refillJsonData("role_id"))) === 4 ? "Football Player" : (this.refillJsonData("role_id") && Number(this.refillJsonData("role_id"))) === 3 ? "Agent" : (this.refillJsonData("role_id") && Number(this.refillJsonData("role_id"))) === 1 ? "Admin" : (this.refillJsonData("role_id") && Number(this.refillJsonData("role_id"))) === 2 ? "University Admin" : ""}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 noPadding" style={{ alignSelf: "center" }}>
                                        <p class={classes.viewProfile} style={{ margin: 0, float: "right" }}>
                                            <Link to={profileUrl} class={classes.viewProfile}>
                                                View Profile
                                            </Link>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <a href="#/" onClick={e => e.preventDefault()} >
                                                <img className={classes.menu} alt="MenuPng" src={MenuPng} />
                                            </a>
                                        </p>
                                    </div>
                                    <div style={{ padding: 5 }}></div>
                                    <div style={{ border: "1px solid #3B3E50", width: "100%" }}></div>
                                </div>
                                :
                                ""
                        }
                        {
                            checkNullAndReturnString(this.state.fromUserId) ?
                                <div class={"row innerRowView"} style={{ paddingBottom: 50 }}>
                                    <div style={{ height: height / 2, overflow: "hidden", overflowY: "scroll", padding: 0, width: "100%" }}>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 noPadding" style={{ alignSelf: "center" }}>
                                            {chatContent}
                                            <div style={{ float: "left", clear: "both" }}
                                                ref={(el) => { this.messagesEnd = el; }}>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-10 col-lg-9 col-md-9 col-sm-9 col-8" style={{ alignSelf: "center", paddingTop: 15, paddingLeft: 0, paddingRight: 0, paddingBottom: 15 }}>
                                        <textarea
                                            value={messageText}
                                            class={classes.textAreaStyle}
                                            onChange={(e) => this.setState({ messageText: e.target.value })}
                                            placeholder={"Write something"}
                                        />
                                    </div>
                                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-4 noPadding" style={{ alignSelf: "center", textAlign: "center" }}>
                                        <a href="#/" style={{
                                            padding: 10, background: "#1891BD 0% 0% no-repeat padding-box", borderRadius: 5,
                                            opacity: this.state.opacitySubmit ? 1 : 0.5
                                        }} onClick={(e) => {
                                            e.preventDefault();
                                            if (this.state.opacitySubmit) {
                                                this.sendMessage()
                                            }
                                        }}>
                                            <img src={SendPng} alt="Sendpng" style={{ height: 14, width: 16 }} />
                                            &nbsp;&nbsp;
                                            <span class={classes.sendText}>Send</span>
                                        </a>
                                    </div>
                                </div>
                                :
                                ""
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(Footer))