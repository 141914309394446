import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { withStore } from '../../component/hooks/store';
import Config from '../../helper/config';
import { checkNullAndReturnString, doDeleteConnect, doGetConnectSecurity } from '../../helper/common';
import { withStyles } from '@material-ui/core/styles';
// import { AppBar, Typography, Toolbar } from '@material-ui/core';
import Styles from "../../styles/admin/header";
import NxtLogo from "../../images/nxtpick-logo-header.png";
import Notification from "../../images/notification.png";
import MenuPng from "../../images/menu.png";
import LockPng from "../../images/lock.png";
import ExitPng from "../../images/exit.png";
import InformationPng from "../../images/information.png";
// import Star from "../../images/star.png";
import Filter from "../../images/filter.png";
import {
    Menu,
    MenuItem,
    // MenuButton,
    // SubMenu
} from '@szhsin/react-menu';
import config from '../../helper/config';
import Button from '../../component/button';
import { Modal } from 'react-responsive-modal';
import { toast } from 'react-toastify';
const rectUrl = Config.reactUrl;

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            fullName: "",
            modal: false,
            notificationCount: 0,
            versiondetails: null
        }
    }

    componentDidMount = async () => {
        var firstName = localStorage.getItem("firstName");
        var lastName = localStorage.getItem("lastName");
        var fullName = firstName + " " + lastName;
        var name = "";
        if (checkNullAndReturnString(firstName)) {
            if (checkNullAndReturnString(lastName)) {
                name = firstName[0] + lastName[0]
            }
            else {
                name = firstName[0]
            }
        }
        this.setState({ fullName, name })
        this.getMessageCount();
        if (this.props.from === "Dashboard") {
            this.props.onRef(this)
        }

        this.getVersionDetail();
    }


    getVersionDetail = async () => {
        try {
            var accessToken = localStorage.getItem("accessToken");
            var responseData1 = await doGetConnectSecurity(
              `version-history?skip=0&take=1&sortby=id&order=DESC&type=web`,
              accessToken
            );

            if (responseData1 && responseData1.data && responseData1.data.length) {
                const { data: [dataone] } = responseData1;
                this.setState({
                    versiondetails: dataone
                })
            }
        } catch (err) {
            toast.error(err.message ? err.message : 'Error while getting version details')
        }
    }

    componentWillUnmount() {
        if (this.props.from === "Dashboard") {
            this.props.onRef(undefined)
        }
    }

    getMessageCount = async () => {
        this.setState({ notificationCount: 0 })
        var accessToken = localStorage.getItem("accessToken");
        var responseData1 = await doGetConnectSecurity("notifications/count/admin", accessToken)
        if (checkNullAndReturnString(responseData1)) {
            if (checkNullAndReturnString(responseData1.count)) {
                this.setState({ notificationCount: responseData1.count })
            }
        }
    }

    onOpenModal = () => {
        this.setState({ modal: true })
    };

    onCloseModal = () => {
        this.setState({ modal: false })
    };

    logoutFunction = async () => {
        var refreshToken = localStorage.getItem("refreshToken")
        var responseData = await doDeleteConnect("refresh-token?token=" + refreshToken)
        if (checkNullAndReturnString(responseData)) {
            localStorage.clear();
            window.location.href = config.reactUrl + "/logout";
        }
    }

    render() {
        const { classes, from } = this.props;
        const { versiondetails } = this.state;
        var textStyle = {
            textAlign: "left",
            font: "normal normal normal 14px/21px Poppins",
            letterSpacing: 0,
            opacity: 1,
            paddingLeft: 15,
            paddingRight: 15,
            // letterSpacing: 0.3
            // textTransform: "capitialize"
        }
        return (
            <div>
                <nav class="navbar navbar-expand-lg fixed-top" style={{
                    borderBottom: "1px solid #3B3E50",
                    background: "#262834",
                    zIndex: 1
                }}>
                    <Link to={rectUrl + "/admin/dashboard"} variant="button" className={"navbar-brand"} style={{ paddingLeft: 20 }}>
                        <img src={NxtLogo} alt="nxtpicklogo" style={{ width: 210, height: 22 }} />
                    </Link>                    
                    {
                        (versiondetails && versiondetails.version_id &&
                            <a href="/version-history/details" target="_blank" class="navbar-brand"
                                className={classes.vname}
                            >
                                {'v'.concat(versiondetails.version_id)}
                            </a>)
                    }
                    <div class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <img className={classes.menu} alt="menupng" src={MenuPng} />
                    </div>
                    <div class="collapse navbar-collapse" id="navbarCollapse">
                        <div style={{ paddingRight: 60 }}> </div>
                        <div class="nav-item nav-link" style={{ padding: 5 }}>
                            <Link to={rectUrl + "/admin/dashboard"} variant="button" className={classes.link}>
                                <span style={{ ...textStyle, color: from === "Dashboard" ? "#1891BD" : "#FFFFFF" }}>
                                    Dashboard
                                </span>
                            </Link>
                        </div>
                        <div class="nav-item nav-link" style={{ padding: 5 }}>
                            <Link to={rectUrl + "/admin/players"} variant="button" className={classes.link}>
                                <span style={{ ...textStyle, color: from === "Players" ? "#1891BD" : "#FFFFFF" }}>
                                    Players
                                </span>
                            </Link>
                        </div>
                        <div class="nav-item nav-link" style={{ padding: 5 }}>
                            <Link to={rectUrl + "/admin/agents"} variant="button" className={classes.link}>
                                <span style={{ ...textStyle, color: from === "Agents" ? "#1891BD" : "#FFFFFF" }}>
                                    Agents
                                </span>
                            </Link>
                        </div>
                        <div class="nav-item nav-link" style={{ padding: 5 }}>
                            <Link to={rectUrl + "/admin/universityAdmins"} variant="button" className={classes.link}>
                                <span style={{ ...textStyle, color: from === "universityAdmins" ? "#1891BD" : "#FFFFFF" }}>
                                    University Admins
                                </span>
                            </Link>
                        </div>
                        <div class="navbar-nav ml-auto" >
                            {
                                this.props.changeToggleFilter
                                    ?
                                    (<div class="nav-item nav-link" style={{ paddingRight: 15 }}
                                        onClick={this.props.changeToggleFilter}
                                    >
                                        <a href="#/" className={classes.circle} onClick={e => e.preventDefault()}>
                                            <img src={Filter} className={classes.notification} alt="notification" />
                                        </a>
                                    </div>)
                                    : null
                            }
                            <div class="nav-item nav-link" style={{ paddingRight: 15 }}>
                                {
                                    Number(this.state.notificationCount) !== 0 ?
                                        <span class={classes.badge}>
                                            {this.state.notificationCount}
                                        </span>
                                        :
                                        ""
                                }
                                <Link to={config.reactUrl + "/admin/dashboard"} className={classes.circle}>
                                    <img src={Notification} alt="Notifications" className={classes.notification} />
                                </Link>
                            </div>


                            <div class="nav-item nav-link" style={{ paddingRight: 20 }}>
                                <Menu styles={{
                                    background: "#3B3E50 0% 0% no-repeat padding-box",
                                    padding: 10
                                }} menuButton={
                                    <a href="#/" className={"headerNameHover"} style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} onClick={e => e.preventDefault()}>
                                        <div style={{ padding: 3, width: 200, display: "inline-flex" }}>
                                            <span className={classes.nameCircle} style={{ position: "absolute" }}>
                                                <span className={classes.circleText}> {this.state.name} </span>
                                            </span>
                                            <span style={{ paddingLeft: 10 }}></span>
                                            <span href="#/" style={{ position: "relative", left: 40, width: 160, display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                <span className={classes.fullNameText}>{this.state.fullName}</span>
                                                <p className={classes.userTypeText}>NXT Admin</p>
                                            </span>
                                        </div>
                                    </a>
                                }>
                                    <MenuItem >
                                        <Link to={config.reactUrl + "/admin/subadmin"}>
                                            <img src={LockPng} alt="LockPng" style={{ width: 12, height: 12 }} />
                                            <span className={classes.menuItemText} >
                                                <span className={"headerMenuHover"}>
                                                    Manage Users
                                                </span>
                                            </span>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem >
                                        <Link to={config.reactUrl + "/admin/coachusers"}>
                                            <img src={LockPng} alt="LockPng" style={{ width: 12, height: 12 }} />
                                            <span className={classes.menuItemText} >
                                                <span className={"headerMenuHover"}>
                                                    Manage Coach
                                                </span>
                                            </span>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem >
                                        <Link to={config.reactUrl + "/admin/notifications"}>
                                            <img src={LockPng} alt="LockPng" style={{ width: 12, height: 12 }} />
                                            <span className={classes.menuItemText} >
                                                <span className={"headerMenuHover"}>
                                                    Send Email
                                                </span>
                                            </span>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem >
                                        <Link to={config.reactUrl + "/admin/changepassword"}>
                                            <img src={LockPng} alt="LockPng" style={{ width: 12, height: 12 }} />
                                            <span className={classes.menuItemText} >
                                                <span className={"headerMenuHover"}>
                                                    Change Password
                                                </span>
                                            </span>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem >
                                        <Link to={config.reactUrl + "/frequently-asked-questions"}>
                                            <img src={InformationPng} alt="InformationPng" style={{ width: 12, height: 12 }} />
                                            <span className={classes.menuItemText}>
                                                <span className={"headerMenuHover"}>
                                                    FAQ
                                                </span>
                                            </span>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem >
                                        <img src={ExitPng} alt="ExitPng" style={{ width: 12, height: 12 }} />
                                        <span className={classes.menuItemText}>
                                            <span className={"headerMenuHover"} onClick={() => this.onOpenModal()}>
                                                Logout
                                            </span>
                                        </span>
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </nav>
                {/* <Modal open={this.state.modal} onClose={() => this.onCloseModal()} center showCloseIcon={false}>
                    <div style={{ padding: 20 }}>
                        <p style={{ font: "normal normal 600 18px/27px Poppins", opacity: 1 }}>Logout</p>
                        <p style={{ font: "normal normal normal 14px/21px Poppins", opacity: 1 }}>
                            Are you sure to logout from current session?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <Button buttonStyle={classes.buttonStyle1} buttonName={"Cancel"} fullWidth={false} onClick={() => {
                                this.onCloseModal();
                            }} />
                            <span style={{ paddingRight: 20 }}></span>
                            <Button buttonStyle={classes.buttonStyle} buttonName={"Logout"} fullWidth={false} onClick={() => {
                                this.logoutFunction();
                            }} />
                        </p>
                    </div>
                </Modal> */}
                <Modal open={this.state.modal} onClose={() => this.onCloseModal()} center showCloseIcon={false}>
                    <div style={{ padding: 20 }}>
                        <p style={{ font: "normal normal 600 18px/27px Poppins", opacity: 1 }}>Logout</p>
                        <p style={{ font: "normal normal normal 14px/21px Poppins", opacity: 1 }}>
                            Are you sure to logout from current session?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                        <p style={{ float: "right" }}>
                            <Button buttonStyle={classes.buttonStyle1} buttonName={"Cancel"} fullWidth={false} onClick={() => {
                                this.onCloseModal();
                            }} />
                            <span style={{ paddingRight: 20 }}></span>
                            <Button buttonStyle={classes.buttonStyle} buttonName={"Logout"} fullWidth={false} onClick={() => {
                                this.logoutFunction();
                            }} />
                        </p>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default withStyles(Styles, { withTheme: true })(withStore(Header));