import React, { Component } from 'react'
import { InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { checkNullAndReturnString } from '../helper/common';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class index extends Component {
    render() {
        var { keyName, options, value, onChange, backgroundColor, fullWidth, extraStyle, disabled = false, from } = this.props;
        if (checkNullAndReturnString(from)) {
            let optionList = [];
            if (checkNullAndReturnString(options)) {
                Object.keys(options).forEach(key => {
                    optionList.push(
                        <MenuItem value={options[key].value}>
                            {options[key].label}
                        </MenuItem>
                    )
                })
            }
            const BootstrapInput = withStyles((theme) => ({
                input: {
                    borderRadius: 4,
                    position: 'relative',
                    opacity: 0.7,
                    backgroundColor: backgroundColor ? backgroundColor : '#51535d',
                    border: 'none',
                    height: "22px !important",
                    // borderRadius: 5,
                    color: "#FFFFFF",
                    padding: '10px 26px 10px 12px',
                    font: "italic normal 300 14px/18px Poppins",
                    letterSpacing: 0,
                    '&:focus': {
                        border: "none",
                    },
                },
                root: {
                    padding: 0
                },
                icon: {
                    top: "10px !important"
                },
                MuiMenu: {
                    height: 200
                }
            }))(InputBase);
            return (
                <Select disabled={disabled} input={<BootstrapInput />} fullWidth={fullWidth ? false : true} labelId="label" id="select" value={value}
                    onChange={(e) => onChange(keyName, e.target.value)}
                    style={{ ...extraStyle }}
                // style={{ 
                //     borderRadius: 5,
                //     height: 44, 
                //     backgroundColor: backgroundColor ? backgroundColor : '#51535d',
                // }}
                >
                    {/* <MenuItem value="" style={{ height: '20px' }}> </MenuItem> */}
                    {optionList}
                </Select>
            )
        }
        else {
            let optionList = [];
            var placeHolder = "";
            if (checkNullAndReturnString(options)) {
                Object.keys(options).forEach(key => {
                    if (options[key].value && Number(options[key].value) === 0) {
                        placeHolder = options[key].label
                    }
                    else {
                        optionList.push(
                            <MenuItem value={options[key].value}>
                                {options[key].label}
                            </MenuItem>
                        )
                    }
                })
            }
            return (
                <FormControl fullWidth style={{ ...extraStyle }} >
                    <InputLabel id="demo-simple-select-label" style={{ color: "#fff" }}>{placeHolder}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        style={Number(value) === 0 ? { ...extraStyle } : { paddingTop: 5, ...extraStyle }}
                        value={value ? value : 0}
                        onChange={(e) => onChange(keyName, e.target.value)}
                    >
                        {optionList}
                    </Select>
                </FormControl>
            )
        }
    }
}
export default index;