import React, { Component } from 'react'

class index extends Component {
    render() {
        const { width, mainBackground, background, left } = this.props;
        var background1 = "transparent linear-gradient(90deg, #3B3E50 0%, #ff0000 100%) 0% 0% no-repeat padding-box"
        if (width < 69) {
            background1 = "transparent linear-gradient(90deg, #3B3E50 0%, #ff0000 100%) 0% 0% no-repeat padding-box"
        } else if (width < 79) {
            background1 = "transparent linear-gradient(90deg, #3B3E50 0%, #ffc000 100%) 0% 0% no-repeat padding-box"
        }
        else if (width < 89) {
            background1 = "transparent linear-gradient(90deg, #3B3E50 0%, #ffff00 100%) 0% 0% no-repeat padding-box"
        }
        else if (width < 99) {
            background1 = "transparent linear-gradient(90deg, #3B3E50 0%, #a8d08d 100%) 0% 0% no-repeat padding-box"
        }
        else {
            background1 = "transparent linear-gradient(90deg, #3B3E50 0%, #5b9bd5 100%) 0% 0% no-repeat padding-box"
        }
        return (
            <div class="progress" style={{ transform: left ? "rotate(180deg)" : "rotate(360deg)", width: "100%", height: 8, background: mainBackground ? mainBackground : "#3B3E50 0% 0% no-repeat padding-box" }}>
                <div class="progress-bar" style={{ width: width + "%", background: background ? background : background1, borderRadius: 10 }}></div>
            </div>
        )
    }
}
export default index;